
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList,getPageAPIs } from "@/common/util";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import {AbnormalPayOrderAPI} from "@/common/api/financeManage/abnormalPayOrderList";

import ZlSelect from "@/components/third-custom-components/select";

import ZlOption from "@/components/third-custom-components/option";


import { excuteDownloadExport2Excel } from "@/common/xlsx";
export const pageApiList = getPageAPIs(AbnormalPayOrderAPI);

// import {
//   transferUserLevel2ch
// } from '@/common/config'

const abnormalOrderStatus2ch: string[] = [
  '未知',
  '成功',
  '失败'
]

const defaultForm = {
  order_no: "", // 原支付单号

  is_status: -1, // 标记状态 0未标记(未知) 1成功 2失败（-1为全部）
  // phone: "", // 手机号

  // create_time: [], // 时间

};

// 业务类型 1台球订单  2棋牌订单  3优惠券 4单店卡  5球杆柜 6教练订单 7全国会员 8比赛报名 9台球排队订单
const allBizType2ch: string[] = [
  '',
  '台球订单',
  '棋牌订单',
  '优惠券',
  '单店卡',
  '球杆柜',
  '教练订单',
  '全国会员',
  '比赛报名',
  '台球排队订单'
]


// const defaultForms = {
//   phone: "",
//   venue_id: "",
//   venue: {
//     id: "",
//     name: "",
//   },
//   decr_price: '', // 扣款金额
//   remark: '', // 备注
// }

// const defaultSearchVenuePageOption = {
//   page: 1,
//   limit: 10,
//   isRearchBottom: false,
// };

// const transfromGenders: any = [
//   '',
//   '女',
//   '男'
// ]

@Component({
  filters: {
    // showUserMemberTypeName: function(type: number) {
    //   return transferUserLevel2ch[type] || ''
    // }
    showBizTypeName(biz_type: number) {
      return allBizType2ch[biz_type] || '未知'
    }
  },
  components: {
    ZlSelect,
    ZlOption,
  },
})
export default class MerchantWithdrawList extends Vue {
  private abnormalPayOrderListApi: any;

  private deepLoopUtil: any;

  private utilIns: any;

  // private debounceRefreshSingleCardInfoFunc: any

  private abnormalOrderStatusList: any[] = [
    {
      label: '全部',
      value: -1
    },
    {
      label: '未知',
      value: 0
    },
    {
      label: '成功',
      value: 1
    },
    {
      label: '失败',
      value: 2
    }
  ]

  constructor() {
    super();
    this.abnormalPayOrderListApi = new AbnormalPayOrderAPI();

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };

    // this.forms = {
    //   ...this.deepLoopUtil.deepCopy(defaultForms),
    // }

    this.utilIns = new Util()

    // this.debounceRefreshSingleCardInfoFunc = this.utilIns.debounce.call(
    //   this,
    //   this.refreshSingleCardAccountInfo,
    //   800
    // )
  }

  private form: any;

  private forms: any;

  private firstLoading: boolean = true;
  // private chooseTechnicianItem : any = null;

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };


  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    this.abnormalPayOrderListApi.getAbnormalPayOrderList(
      this.getAbnormalPayOrderListParams(),
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        const { list, count, page, prepage } = data || {};

        const newTableList = Array.isArray(list)
          ? list.map(this.handleTableData)
          : [];

        this.$set(this.tablePage, "totalCount", count);
        this.$set(this.tablePage, "pageNo", page);
        this.$set(this.tablePage, "pageSize", prepage);

        this.tableData = newTableList;

        this.firstLoading = false;
      },
      () => {
        this.firstLoading = false;
      },
      false
    );
  }

  private getAbnormalPayOrderListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const firstLoading = this.firstLoading;

    const {
      order_no,
      is_status,
    } = form || {};


    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};
    if (order_no) {
      outerParams.order_no = order_no;
    }

    if (typeof is_status == 'number') {
      outerParams.is_status = is_status
    }

    // if (Array.isArray(create_time) && create_time.length === 2) {
    //   const [startDate, endDate] = create_time;

    //   const startTime = Util.dateTimeUtilnohms(startDate);
    //   const endTime = Util.dateTimeUtilnohms(endDate);

    //   outerParams.start_date = startTime;

    //   outerParams.end_date = endTime;
    // }

    if (
      mode == "exportExcel" &&
      !(outerParams.start_date && outerParams.end_date)
    ) {
      this.$message.error("请选择日期导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      outerParams.__isUnique = !firstLoading;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

   // 后台返回的字段 转化为前端定义的字段
  private handleTableData(data: any): any {
    // const { gender } = data;
    const newData = {
      ...data,
      // device_name: orderDeviceType2ch[device],
      // pay_type_name: payType2ch[pay_type],
      // user_level_type_name: userLevel2ch[user_type],
      // gender_sex: transfromGenders[gender]
    };

    return newData;
  }

  private dialogFormVisible: boolean = false

  private curAbnormalOrderItem: any = {}

  private abnormalOrderQueryInfo: any = {}

  private abnormalOrderManualSetLoading: boolean = false

  private withdrawManualState:number = 0

   // 异常订单状态查询
  private handleWakeQueryMoneyFlow(abnormalOrderItem: any) {
    console.log(abnormalOrderItem, 'abnormalOrderItem')

    this.curAbnormalOrderItem = abnormalOrderItem
    set(
    this,
    this.tableData,
    abnormalOrderItem,
    "__moneyTransferQueryLoading",
      true
    );


    this.abnormalPayOrderListApi.getAbnormalOrderQueryInfo({
      id: abnormalOrderItem.id
    }, (res: any) => {
      set(
        this,
        this.tableData,
        abnormalOrderItem,
        "__moneyTransferQueryLoading",
        false
      );

      const {
        list: abnormalOrderQueryInfo 
      } = res.data || {}

      console.log(abnormalOrderQueryInfo, 'abnormalOrderQueryInfo')

      this.abnormalOrderQueryInfo = abnormalOrderQueryInfo

      this.dialogFormVisible = true
    }, () => {
      set(
        this,
        this.tableData,
        abnormalOrderItem,
        "__moneyTransferQueryLoading",
          false
      );
    })

    function set<T>(vm: any, list: T[], item: T, key: string, val: any) {
      const  existIndex = list.findIndex((eachItem) => eachItem == item);

      if (existIndex > -1) {
        if (item && typeof item == "object" && !Array.isArray(item))
          vm.$set(list[existIndex], key, val);
      }
    }
  }

  private handleWithdrawSetSuccess(queryInfo: any, state: number) {
    this.withdrawManualState = state
    const params: any = {
      id: queryInfo.id,
      // state
    }

    this.withdrawManualSet(params)
  }

  private handleWithdrawSetFail(queryInfo: any, state: number) {
    this.withdrawManualState = state
    const params: any = {
      id: queryInfo.id,
      // state
    }

    this.withdrawManualSet(params)
  }

  private withdrawManualSet(params: any) {
    this.abnormalOrderManualSetLoading = true
    this.abnormalPayOrderListApi.abnormalOrderManualSetStatus(params, () => {
      this.$message.success('标记状态完成')

      this.abnormalOrderManualSetLoading = false

      this.dialogFormVisible = false

      this.getTable()
    }, () => {
      this.abnormalOrderManualSetLoading = false
    })
  }

  /** 远程搜索店铺 */
  // private venueList: any[] = [
  //   // {
  //   //   id: 1,
  //   //   label: '测试'
  //   // },
  //   // {
  //   //   id: 2,
  //   //   label: '测试2'
  //   // }
  // ];

  // private searchVenueLoading: boolean = false;

  // private remoteGetVenueQueryString: string = "";

  // private remoteSearchVenueTimer: any = null;

  // private searchVenuePageOption: any = {};

  // private initRemoteGetVenuePageOption() {
  //   this.searchVenuePageOption = {
  //     ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
  //   };
  // }

  // private getSearchVenueParams() {
  //   const searchVenuePageOption = this.searchVenuePageOption;

  //   const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

  //   const { page, limit } = searchVenuePageOption;

  //   const params: any = {
  //     page,
  //     limit,
  //   };

  //   if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
  //     params.name = remoteGetVenueQueryString.trim();
  //   }

  //   return params;
  // }

  // private getMoreVenueList(refresh: boolean = true) {
  //   return new Promise((resolve: Function, reject: Function) => {
  //     if (refresh) {
  //       this.initRemoteGetVenuePageOption();
  //     }

  //     const params = this.getSearchVenueParams();

  //     this.abnormalPayOrderListApi.searchVenueList(
  //       params,
  //       (res: any) => {
  //         console.log(res, "res");

  //         const venueList: any[] = res.data || [];

  //         this.venueList = refresh
  //           ? venueList
  //           : this.venueList.concat(venueList);

  //         if (Array.isArray(res.data) && res.data.length == 0) {
  //           this.searchVenuePageOption.isRearchBottom = true;
  //         }
  //         this.searchVenueLoading = false;

  //         this.addVenueListLoading = false;

  //         resolve();
  //       },
  //       () => {
  //         this.searchVenueLoading = false;

  //         this.addVenueListLoading = false;

  //         reject();
  //       }
  //     );
  //   });
  // }

  // // 第一次搜索 无关键字
  // private handleFirstGetVenueList() {
  //   this.searchVenueLoading = true;

  //   this.getMoreVenueList(true);
  // }

  // // 清空搜索
  // private handleClearGetVenueList() {
  //   this.remoteGetVenueQueryString = "";

  //   this.searchVenueLoading = true;

  //   this.getMoreVenueList(true);
  // }

  // // 远程搜索
  // private remoteGetVenueMethod(query: string) {
  //   query = query.trim ? query.trim() : "";

  //   if (query !== "") {
  //     this.remoteGetVenueQueryString = query;
  //     clearTimeout(this.remoteSearchVenueTimer);

  //     this.remoteSearchVenueTimer = setTimeout(() => {
  //       this.searchVenueLoading = true;

  //       this.getMoreVenueList();
  //       // searchStu((res) => {
  //       //   const {
  //       //     students,
  //       //     total_count
  //       //   } = res || {}

  //       //   this.remoteAllStudents = [
  //       //     ...(students || [])
  //       //   ]

  //       //   this.remoteStudentTotalCount = total_count || 0

  //       //   this.remoteGetStudentLoading = false
  //       // }, {
  //       //   kw: query,
  //       //   ...this.getRemoteGetStudentPageOptionParams()
  //       // }, () => {
  //       //   this.remoteGetStudentLoading = false
  //       // })
  //     }, 300);
  //     // setTimeout(() => {
  //     //   this.remoteGetStudentLoading = false;
  //     //   this.options = this.list
  //     // }, 200);
  //   } else {
  //     this.remoteGetVenueQueryString = "";
  //     this.venueList = [];
  //   }
  // }

  // private addVenueListLoading: boolean = false;

  // private remoteAddVenueMethod() {
  //   if (this.addVenueListLoading) return;
  //   const searchVenuePageOption = this.searchVenuePageOption;

  //   const { isRearchBottom } = searchVenuePageOption;

  //   if (isRearchBottom) return;

  //   this.searchVenuePageOption.page++;

  //   this.addVenueListLoading = true;

  //   this.getMoreVenueList(false);
  // }
  /** end 远程搜索店铺 */
  

  /** 导出excel */
  // private downloadExcelLoading: boolean = false;

  // // 订单字段名 文案对应关系
  // private orderExcelList2ch: any = {
  //   "phone": "手机号",
  //   "user_name": "用户昵称",
  //   "shop_name": "店铺",
  //   "decr_price": "扣款金额",
  //   "remark": "扣款原因",
  //   "admin_name": "操作人",
  //   "created_at": "操作时间"
  // };
  // // 订单Excel 表头的先后顺序
  // private orderExcelSortList: string[] = [
  //   "phone",
  //   "user_name",
  //   "shop_name",
  //   "decr_price",
  //   "remark",
  //   "admin_name",
  //   "created_at"
  // ];

  // // 点击导出excel 表格
  // private handleDownloadExcel() {
  //   const params = this.getAbnormalPayOrderListParams("exportExcel");

  //   if (params === null) {
  //     return false;
  //   }

  //   this.downloadExcelLoading = true;
  //   this.abnormalPayOrderListApi.exportExcelSingleCardAccountCheckList(
  //     params,
  //     (res: any) => {
  //       const {
  //         start_date,
  //         end_date,
  //         // over_start_time,
  //         // over_end_time,
  //       } = params || {};

  //       const create_time_duration_name =
  //         start_date && end_date
  //           ? `时间${start_date}到${end_date}`
  //           : "";

  //       // const finish_time_duration_name =
  //       //   over_start_time && over_end_time
  //       //     ? `完成时间${over_start_time}到${over_end_time}`
  //       //     : "";
  //       const filename = `单店会员余额扣款记录列表 ${create_time_duration_name}.xlsx`;
  //       const { data } = res || {};

  //       const { list: originList } = data || {};

  //       const newList = Array.isArray(originList)
  //         ? originList.map((item) => {
  //             const {
  //               // type,
           
  //             } = item || {};

  //             const newItem = {
  //               ...(item || {}),
              
  //               // memberTypeName: transferUserLevel2ch[type]
  //             };

  //             return newItem;
  //           })
  //         : [];

  //       if (newList.length > 0) {
  //         const orderExcelSortList = this.orderExcelSortList;

  //         const orderExcelList2ch = this.orderExcelList2ch;

  //         const excelJSONData = getExcelJSONDataByList(
  //           newList,
  //           orderExcelSortList,
  //           orderExcelList2ch
  //         );


  //         excuteDownloadExport2Excel(excelJSONData, filename);
  //       } else {
  //         this.$message.info("选中时段没有可导出的信息");
  //       }

  //       this.downloadExcelLoading = false;
  //     },
  //     () => {
  //       this.downloadExcelLoading = false;
  //     }
  //   );
  // }

  /** end 导出excel */

  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}

import {
  // dataStatisticRoutes,
  dataWholeBoardRoutes,
  merchantManageRoutes,
  // accountManangeRoutes,
  userManageRoutes,
  competitionManageRoutes,
  customerServiceRoutes,
  operationManageRoutes,
  financeManageRoutes,
  auditManageRoutes,
  officialWebManageRoutes,
  marketManageRoutes,
  couponManageRoutes,
  systemManageRoutes
} from '@/router'

function getFilterRouters<T extends any>(keys: string[], list: T[]): T[] {
  return list.filter(({ meta }: any) => keys.includes(meta.key))
}


export function getMobileSpecialAsideMenus() {
  const list = []

  // 仅仅店铺信息 （商家管理）
  const merchantKeys: string[] = [
    'venue-info-list',
    'ball-table-info-list',
    'sign-board-light-list',
    'cage-info-list',
    'other-device-info-list',
    'air-config-info-list',
    'air-config-set-info-list',
  ]
  list.push(
    getFilterRouters(merchantKeys, merchantManageRoutes)
  )

  // 赛事管理 
  const competitionKeys: string[] = [
    'setting-competition-assistant',
    'all-competition-partner-list',
    'merchant-apply-competition',
    'merchant-create-competition-audit'
  ]

  list.push(
    getFilterRouters(competitionKeys, competitionManageRoutes)
  )


  // 包含会员统计、会员开通记录（运营管理）
  const memberKeys = ['chinese-eight-ball-activity-pay-record', 'league-courser-ist', 'league-courser-detail-list', 'member-open-record', 'member-statistic-list']
  list.push(
    getFilterRouters(memberKeys, operationManageRoutes)
  )

  // 包含 技术服务费汇总（财务管理）
  const financeKeys = [
    'finance-lease-list',
    'finance-lease-take-rate-list',
    'technical-service-fee-list',
    'technical-service-shop-fee-list',
    'technical-service-shop-income-list',
    'cashier-order-list'
  ]
  list.push(
    getFilterRouters(financeKeys, financeManageRoutes)
  )

  return list
}

import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import PandaMobileTable from "@/components/mobile-custom/panda-table.vue";

import PandaMobileTWFContainer from "@/components/mobile-custom/panda-table-with-filter-container.vue";

import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import MemberCouponUsedRecordAPI from "@/common/api/operationManage/memberCouponUsedRecord";
// import { CommonApi } from "@/common/api/common";

import { excuteDownloadExport2Excel } from "@/common/xlsx";

import {
  shareConfirmOptions,
  userLevel2ch
} from '@/common/config'

export const pageApiList = getPageAPIs(MemberCouponUsedRecordAPI)



@Component({
  filters: {
    getMemberLevelName: function(level: number): string {
      return userLevel2ch[level] || ''
    }
  },
  components: {
    PandaMobileTable,
    PandaMobileTWFContainer,
  },
})
export default class MemberStatistic extends Vue {
  /** 移动端相关属性 方法 */
  private get allMobileChooseFields(): any[] {
    const fields = [...(this.allChooseFields || [])];


    return fields;
  }
  /** end 移动端相关属性 方法 */



  private allChooseFields: any[] = [
    {
      field: "phone",
      label: "手机号",
    },
    {
      field: "user_name",
      label: "昵称",
    },
    {
      field: "shop_name",
      label: "店铺",
    },
    {
      field: "money",
      label: "金额",
    },
    {
      field: "use_time",
      label: "兑换时间",
    },
  ];
  private utilInstance: any;
  private memberCouponUsedRecordApi: any;

  // private commonApi: any;

  constructor() {
    super();
    this.memberCouponUsedRecordApi = new MemberCouponUsedRecordAPI();

    // this.commonApi = new CommonApi();

    this.utilInstance = new Util();

    // 封禁时间列表
    // this.all_forbidden_time_list = Util.getFoibiddenTimeList();
  }

  private firstLoading: boolean = true;

  // private memberTypeList: any[] = [];
  private form: any = {
    phone: "", // 手机号码
    // time: [], // 时间选择
  };


  // private showtimecard: boolean = false;
  private activated(): void {
    this.getTable(true);

    // this.refreshMemberTypeList()

  }
  private getTable(noUnique = false): void {
    this.memberCouponUsedRecordApi.getMemberCouponUsedList(
      this.getMemberCouponUsedListParams("all", noUnique),
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        const { list, count, page, prepage } = data || {};

        const newTableList = Array.isArray(list)
          ? list.map(this.handleTableData)
          : [];

        // totalCount: 0,
        // pageNo: 1,
        // pageSize: 10,
        this.$set(this.tablePage, "totalCount", count);
        this.$set(this.tablePage, "pageNo", page);
        this.$set(this.tablePage, "pageSize", prepage);


        this.tableData = newTableList;

        this.firstLoading = false;
      },
      () => {
        this.firstLoading = false;
      }
    );
  }

  private getMemberCouponUsedListParams(mode = "all", noUnique = false) {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const firstLoading = this.firstLoading;

    const {
      phone, // 手机号码
      // nickname, // 用户昵称
      time // 时间
    } = form || {};

    // console.log(technicianItem, "technicianItem");

    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};

    if (phone) {
      outerParams.phone = phone;
    }

    // if (Array.isArray(time) && time.length === 2) {
    //   const [startDate, endDate] = time;

    //   const startTime = Util.dateTimeUtilnohms(startDate);
    //   const endTime = Util.dateTimeUtilnohms(endDate);

    //   outerParams.start_date = startTime;

    //   outerParams.end_date = endTime;
    // }

    if (
      mode == "exportExcel" &&
      !(outerParams.start_date && outerParams.end_date)
    ) {
      this.$message.error("请选择时段导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      outerParams.__isUnique = !firstLoading && !noUnique;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  // 后台返回的字段 转化为前端定义的字段
  private handleTableData(data: any): any {
    const {
     
    } = data;
    const newData = {
      ...data,
      
    };


    return newData;
  }

  /** 导出excel */
  // private downloadExcelLoading: boolean = false;

  // // 用户字段名 文案对应关系
  // private userExcelList2ch: any = {
  //   user_name: "用户昵称",
  //   user_id: "用户ID",
  //   phone: "手机号码",
  //   birthday: "出生日期",
  //   user_level_type_name: "会员等级",
  //   user_money: "会员余额（元）",
  //   total_money: "累计消费（元）",
  //   // inviter: "邀请人",
  // };
  // // 用户Excel 表头的先后顺序
  // private userExcelSortList: string[] = [
  //   "user_name",
  //   "user_id",
  //   "phone",
  //   "birthday",
  //   "user_level_type_name",
  //   // "inviter",
  //   "user_money",
  //   "total_money",
  // ];

  // // 点击导出excel 表格
  // private handleDownloadExcel() {
  //   const params = this.getMemberCouponUsedListParams("exportExcel");

  //   if (params === null) {
  //     return false;
  //   }

  //   this.downloadExcelLoading = true;
  //   this.memberCouponUsedRecordApi.exportMemberStatisticList(
  //     params,
  //     (res: any) => {
  //       const { start_date, end_date } = params || {};

  //       const filename = `会员统计列表 ${start_date}到${end_date}.xlsx`;
  //       const { data } = res || {};

  //       const { list: originList } = data || {};

  //       const newList = Array.isArray(originList)
  //         ? originList.map((item) => {
  //             const { type } = item;
  //             const newItem = {
  //               ...(item || {}),
  //             };

  //             newItem.user_level_type_name = userLevel2ch[type] || '未知'

  //             return newItem;
  //           })
  //         : [];

  //       if (newList.length > 0) {
  //         const userExcelSortList = this.userExcelSortList;

  //         const userExcelList2ch = this.userExcelList2ch;

  //         const excelJSONData = getExcelJSONDataByList(
  //           newList,
  //           userExcelSortList,
  //           userExcelList2ch
  //         );

  //         excuteDownloadExport2Excel(excelJSONData, filename);
  //       } else {
  //         this.$message.info("选中时段没有可导出的信息");
  //       }


  //       this.downloadExcelLoading = false;
  //     },
  //     () => {
  //       this.downloadExcelLoading = false;
  //     }
  //   );
  // }

  /** end 导出excel */

  private restForm(): void {
    this.form = this.utilInstance.clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}

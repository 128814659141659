import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class GuessingGamePersonalDetailAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getGuessingGamePersonalDetailList', {
        url: '/admin/guess/record',
        name: 'list',
        label: '列表'
    }],
  //   ['exportExcelGuessingGamePersonalDetailTableList', {
  //     url: '/admin/operatePartner/exportLists',
  //     name: 'export-excel',
  //     label: '导出'
  // }],
])

  // 有奖竞猜个人参与记录列表
  public getGuessingGamePersonalDetailList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getGuessingGamePersonalDetailList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 导出有奖竞猜记录列表
  // public exportExcelGuessingGamePersonalDetailTableList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
  //   const apiUrls = this.apiUrls

  //   const url = apiUrls.get('exportExcelGuessingGamePersonalDetailTableList').url

  //   this.BasicPost(url, {
  //       ...params
  //   }, false, false, false, successCb, failCb, showLoading)
  // }

}
import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class DeviceAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['device', {
            url: '/admin/aqara/device',
            name: 'device-list',
            label: '授权设备列表',
            // hidden: true
        }],
        ['resource', {
            url: '/admin/aqara/resource',
            name: 'device-resource',
            label: '设备开关资源',
            // hidden: true
        }],
        ['getdevice', {
            url: '/admin/device/list',
            name: 'list',
            label: '设备列表'
        }],
        ['deviceC', {
            url: '/admin/device/create',
            name: 'add',
            label: '新增设备'
        }],
        ['deviceE', {
            url: '/admin/device/edit',
            name: 'edit',
            label: '编辑设备'
        }],
        ['deviceS', {
            url: '/admin/device/show',
            name: 'detail',
            label: '设备详情'
        }],
        ['deviceD', {
            url: '/admin/device/del',
            name: 'delete',
            label: '删除设备'
        }]
    ])

    // 设备列表
    public device(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('device').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 设备资源名
    public resource(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('resource').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    // 设备列表
    public getdevice(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getdevice').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 新增设备
    public deviceC(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('deviceC').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    // 编辑设备
    public deviceE(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('deviceE').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    // 设备详情
    public deviceS(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('deviceS').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    // 删除设备
    public deviceD(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('deviceD').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

}
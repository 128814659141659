import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class ServicedRecordAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getServicedRecordList', {
      url: '/admin/service/order',
      name: 'list',
      label: '列表'
    }],
    ['exportExcelServicedRecordList', {
      url: '/admin/service/order/export',
      name: 'export-excel',
      label: '导出'
    }],

    ['getServicedReceiverList', {
      url: '/admin/service/docking',
      name: 'receiver-list',
      label: '对接人列表'
    }],

    ['searchVenueList', {
      url: '/admin/coupons/shop/list',
      name: 'shop-list',
      label: '店铺列表',
  }],
  ])
  // 客服记录列表
  public getServicedRecordList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getServicedRecordList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 导出客服记录列表
  public exportExcelServicedRecordList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('exportExcelServicedRecordList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 对接人列表
  public getServicedReceiverList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getServicedReceiverList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 搜索店铺列表
  public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('searchVenueList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
}
}

import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import CoachwithdrawDepositApi from "@/common/api/financeManage/coachWithdrawDeposit";
// import { CommonApi } from "@/common/api/common";

import { excuteDownloadExport2Excel } from "@/common/xlsx";

import {
  shareConfirmOptions
} from '@/common/config'

export const pageApiList = getPageAPIs(CoachwithdrawDepositApi)

const defaultAuditStatus2ch: any = {
  1: "审核中",
  2: "审核通过",
  3: "驳回",
};

@Component({})
export default class WithdrawDeposit extends Vue {
  private utilInstance: any;
  private withdrawDepositApi: any;

  // private commonApi: any;

  constructor() {
    super();
    this.withdrawDepositApi = new CoachwithdrawDepositApi();

    // this.commonApi = new CommonApi();

    this.utilInstance = new Util();

    // 封禁时间列表
    // this.all_forbidden_time_list = Util.getFoibiddenTimeList();
  }

  private firstLoading: boolean = true;

  private form: any = {
    // searchKw: "", // 搜索用户昵称/用户ID/手机号
    coach_id: "", // 助教ID
    mobile: "", // 助教手机号
    time: [], // 时间选择
    auditStatus: 1, // 审核状态
  };

  private checkboxConfig: any = {
    // 只有审核中的 才能选中
    checkMethod: ({ row }: any) => {
      return row.is_status == 1
    }
  }

  private curChooseRows: any[] = []

  private onSelectAllCheckBox({ selection }: any) {
    console.log(selection, 'selection')

    if (Array.isArray(selection) && selection.length) {
      this.curChooseRows = selection
    } else {
      this.curChooseRows = []
    }

  }

  private onSelectCheckBox({ selection }: any) {
    console.log(selection, 'selection')
    if (Array.isArray(selection) && selection.length) {
      this.curChooseRows = selection
    } else {
      this.curChooseRows = []
    }
  }

  private allAuditPassLoading: boolean = false

  private handleAllAuditPass() {
    const curChooseRows = this.curChooseRows

    console.log(curChooseRows, 'curChooseRows')

    if (Array.isArray(curChooseRows) && curChooseRows.length) {
      this.$confirm(`确定选中全部通过审核?`, "", {
        ...shareConfirmOptions,
      }).then(() => {
        this.allAuditPassLoading = true

        const params = {
          ids: curChooseRows.map((row: any) => row.id).join(',')
        }

        this.withdrawDepositApi.batchAuditAgree(params, () => {
          this.allAuditPassLoading = false
          this.getTable()
        }, () => {
          this.allAuditPassLoading = false
        })
      })
    }
  }

  // private show: boolean = false;
  private auditStatusList: any[] = [
    {
      value: 0,
      label: "全部",
    },
    {
      value: 1,
      label: "审核中",
    },
    {
      value: 2,
      label: "审核通过",
    },
    {
      value: 3,
      label: "驳回",
    },
  ];

  // private showtimecard: boolean = false;
  private activated(): void {
    this.getTable();
  }
  private getTable(): void {
    this.withdrawDepositApi.getWithdrawTableList(
      this.getUserTableListParams(),
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        const { list, count, page, prepage } = data || {};

        const newTableList = Array.isArray(list)
          ? list.map(this.handleTableData)
          : [];

        // totalCount: 0,
        // pageNo: 1,
        // pageSize: 10,
        this.$set(this.tablePage, "totalCount", count);
        this.$set(this.tablePage, "pageNo", page);
        this.$set(this.tablePage, "pageSize", prepage);

        this.tableData = newTableList;

        this.firstLoading = false;
      },
      () => {
        this.firstLoading = false;
      }
    );
  }

  private getUserTableListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const firstLoading = this.firstLoading;

    const {
      coach_id, // 助教ID
      mobile: coach_mobile, // 助教手机号

      time, // 时间选择
      auditStatus, // 审核状态
    } = form || {};

    // console.log(technicianItem, "technicianItem");

    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};

    if (coach_id) {
      outerParams.coach_id = coach_id;
    }

    if (coach_mobile) {
      outerParams.mobile = coach_mobile;
    }

    if (auditStatus) {
      outerParams.is_status = auditStatus;
    }

    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      outerParams.start_date = startTime;

      outerParams.end_date = endTime;
    }

    if (
      mode == "exportExcel" &&
      !(outerParams.start_date && outerParams.end_date)
    ) {
      this.$message.error("请选择时段导出数据");
      return null;
    }
    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      outerParams.__isUnique = !firstLoading;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  // 后台返回的字段 转化为前端定义的字段
  private handleTableData(data: any): any {
    const {
      // userId,
      // gender,
      identity,
      money,
      actual_money: actualMoney,
      surplus_money: surplusMoney,
      withdrawal_account: withdrawalAccount,
      realname,
      created_at: ctime,
      examine_time: examineTime,
      examine_man: examineUser,
      is_status: isStatus,
      // activeTime,
      // nickname,
      // avatarUrl,
      // mobile,
      // forbidTime,
    } = data;
    const auditStatus = defaultAuditStatus2ch[isStatus] || "未知审核状态";
    const newData = {
      ...data,
      identify: identity,
      withdrawMoney: money,
      actualMoney,
      restWithdrawMoney: surplusMoney,
      withDrawAccount: withdrawalAccount,
      alipayActualName: realname,
      withdrawTime: ctime,
      auditTime: examineTime,
      auditOpUser: examineUser,
      auditStatus,
    };

    //     id: '12321',
    //     nickname: "用户昵称24432",
    //     identify: '超级推广员',
    //     mobile: '23492384手机号码',
    //     withdrawMoney: '129.0',
    //     restWithdrawMoney: '32.34',
    //     withDrawAccount: '提现账号12313',
    //     alipayActualName: '支付宝实名sdfsdf',
    //     withdrawTime: '提现时间3204324',
    //     auditTime: '审核时间324709',
    //     auditOpUser: '审核人水电费',
    //     auditStatus: '状态'

    return newData;
  }

  private isShowAgreeBtn(row: any) {
    const { is_status: isStatus } = row || {};

    return isStatus == 1;
  }

  private isShowDisagreeBtn(row: any) {
    const { is_status: isStatus } = row || {};

    return isStatus == 1;
  }

  // 修改审核状态
  private handleChangeAuditStatus(withdrawItem: any, mode: string) {
    const mode2MessageTip: any = {
      agree: "通过",
      disagree: "驳回",
    };
    this.$confirm(`是否确定${mode2MessageTip[mode]}?`, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      // type: "warning",
    }).then(() => {
      switch (mode) {
        case "agree":
          set(
            this,
            this.tableData,
            withdrawItem,
            "__changeAuditStatusAgreeLoading",
            true
          );

          this.withdrawDepositApi.getAuditAgree(
            {
              id: withdrawItem.id,
            },
            () => {
              set(
                this,
                this.tableData,
                withdrawItem,
                "__changeAuditStatusAgreeLoading",
                false
              );
              this.getTable();
            },
            () => {
              set(
                this,
                this.tableData,
                withdrawItem,
                "__changeAuditStatusAgreeLoading",
                false
              );
            }
          );
          break;
        case "disagree":
          set(
            this,
            this.tableData,
            withdrawItem,
            "__changeAuditStatusDisagreeLoading",
            true
          );

          this.withdrawDepositApi.getAuditDisagree(
            {
              id: withdrawItem.id,
            },
            () => {
              set(
                this,
                this.tableData,
                withdrawItem,
                "__changeAuditStatusDisagreeLoading",
                false
              );
              this.getTable();
            },
            () => {
              set(
                this,
                this.tableData,
                withdrawItem,
                "__changeAuditStatusDisagreeLoading",
                false
              );
            }
          );
          break;
      }
    });

    function set<T>(vm: any, list: T[], item: T, key: string, val: any) {
      const existIndex = list.findIndex((eachItem) => eachItem == item);

      if (existIndex > -1) {
        if (item && typeof item == "object" && !Array.isArray(item))
          vm.$set(list[existIndex], key, val);
      }
    }
  }


  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 用户字段名 文案对应关系
  private userExcelList2ch: any = {
    user_id: "助教ID",
    nickname: "助教昵称",
    mobile: "手机号码",
    money: "提现金额（元）",
    surplus_money: "剩余可提现金额（元）",
    actual_money: "实际到账金额（元）",
    // inviter: "邀请人",
    withdrawal_account: "提现账号",
    realname: "支付宝实名",
    identity_card: "身份证号码",
    created_at: "提现时间",
    examine_time: "审核时间",
    examine_man: "审核人",
    auditStatus: "状态",
  };
  // 用户Excel 表头的先后顺序
  private userExcelSortList: string[] = [
    "user_id",
    "nickname",
    "mobile",
    "money",
    "surplus_money",
    "actual_money",
    // "inviter",
    "withdrawal_account",
    "realname",
    "identity_card",
    "created_at",
    "examine_time",
    "examine_man",
    "auditStatus",
  ];

  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getUserTableListParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.withdrawDepositApi.exportExcelWithdrawList(
      params,
      (res: any) => {
        const { start_date, end_date } = params || {};

        const filename = `助教提现列表 ${start_date}到${end_date}.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const { is_status: isStatus, mobile } = item;
              const newItem = {
                ...(item || {}),
              };

              if (isStatus) {
                newItem.auditStatus =
                  defaultAuditStatus2ch[isStatus] || "未知审核状态";
              }

              if (typeof mobile == "number") {
                newItem.mobile = mobile.toString();
              }

              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const userExcelSortList = this.userExcelSortList;

          const userExcelList2ch = this.userExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            userExcelSortList,
            userExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        // const testData = [
        //   { 主页: "test1223424", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
        //   { 主页: "433", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
        //   { 名称: "22", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
        //   { 名称: "43", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
        //   { 店家: "43", 价格: "6800", 数量: "6800", 昵称: "广告主网", }
        // ]

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */

  private restForm(): void {
    this.form = this.utilInstance.clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}

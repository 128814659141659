import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class RecommanderDetailAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getRecommanderList', {
        url: '/admin/operatePartner/detail',
        name: 'list',
        label: '列表'
    }],
    ['exportExcelRecommanderTableList', {
      url: '/admin/operatePartner/exportDetail',
      name: 'export-excel',
      label: '导出'
  }],
])

  // 运营合伙人列表
  public getRecommanderList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getRecommanderList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 导出运营合伙人列表
  public exportExcelRecommanderTableList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('exportExcelRecommanderTableList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

}

import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Util, getExcelJSONDataByList, getPageAPIs} from "@/common/util";
import {PandaCoinRecordAPIClass} from "@/common/api/operationManage/pandaCoinRecord";
import gallery from "@/components/gallery.vue";
import {DeepLoop} from "@/common/deepLoop/deepLoop";

import {
  chooseShopTagList, shareConfirmOptions
} from '@/common/config';

import amap from "@/components/amap.vue";

export const pageApiList = getPageAPIs(PandaCoinRecordAPIClass);
import { excuteDownloadExport2Excel } from "@/common/xlsx";
@Component({
  components: {
    gallery,
  },
})
export default class PandaCoinRecordList extends Vue {
  private pandaCoinApi: any;
  private statusArray = [
    {value:0,label:'全部'},
    {value:2,label:'支付成功'},
    {value:4,label:'已退款'},
  ]
  statusObject = {
    2:'支付成功',
    4:'已退款',
    5:'退款中',
  }
  private time = []

  private form: any = {
    phone:'',
    status:'',
    start_date:null,
    end_date:null,
  };
  private downloadExcelLoading: boolean = false;
  // 用户字段名 文案对应关系
  private userExcelList2ch: any = {
    'order_no': '订单号',
    'phone':'手机号',
    'user_name': '昵称',
    'price': '充值金额',
    'panda_coin': '熊猫币数量',
    'pay_time': '支付时间',
    'status':'支付类型',
    'opt_name': '操作人',
    'opt_time':'操作时间'
  };

  // 用户Excel 表头的先后顺序
  private userExcelSortList: string[] = [
    'order_no', // 订单号
    'phone', // 手机号
    'user_name', // 昵称
    'price',
    'panda_coin', // 熊猫币数量
    'pay_time', // 支付时间
    'status', // 状态
    'opt_name', // 操作人
    'opt_time', // 操作时间
  ];
  constructor() {
    super();
    this.pandaCoinApi = new PandaCoinRecordAPIClass();
  }

  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    console.log('33333333333',this.form)
    this.pandaCoinApi.getPandaCoinRecordTableList(
        {
          ...this.form,
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
        }
    );
  }

  private restForm(): void {

    this.form = {
      phone:'',
      status:'',
      start_date:null,
      end_date:null,
    }
    this.time = []

  }

  private clickRefundPandaCoin(item: any) {
    this.$confirm(`确定对${item.user_name}退款吗？`, '', {
      ...shareConfirmOptions
    }).then(() => {
      this.pandaCoinApi.pandaCoinRefund({
            id: item.id
          },
          () => {
            Util.showToast("退款成功");
            this.search();
          }
      )
    })
  }



  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    if (Array.isArray(this.time) && this.time.length === 2) {
      const [startDate, endDate] = this.time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      this.form.start_date = startTime;
      this.form.end_date = endTime;
    }
    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }
  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getUserTableListParams("exportExcel");
    if (params === null) {
      return false;
    }
    this.downloadExcelLoading = true;
    this.pandaCoinApi.exportRechargeList(params, (res: any) => {
          const { start_date, end_date } = params || {};
          const filename = `熊猫币充值记录 ${start_date}到${end_date}.xlsx`;
          const { data } = res || {};

          const { list: originList } = data || {};
          console.log('2222222',originList)
          const newList = Array.isArray(originList)
              ? originList.map((item) => {
                 item.status =  this.statusObject[item.status]
                return item;
              })
              : [];

          if (newList.length > 0) {
            const userExcelSortList = this.userExcelSortList;

            const userExcelList2ch = this.userExcelList2ch;

            const excelJSONData = getExcelJSONDataByList(
                newList,
                userExcelSortList,
                userExcelList2ch
            );

            excuteDownloadExport2Excel(excelJSONData, filename);
          } else {
            this.$message.info("选中时段没有可导出的信息");
          }

          this.downloadExcelLoading = false;
        },
        () => {
          this.downloadExcelLoading = false;
        }
    );
  }
  private getUserTableListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const firstLoading = this.firstLoading;

    const {
      phone, // 手机号
      status, // 状态
      start_date, // 开始时间
      end_date, // 结束时间
    } = form || {};

    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};

    if (start_date) {
      outerParams.start_date = start_date;
    }

    if (end_date) {
      outerParams.end_date = end_date;
    }

    if (phone) {
      outerParams.phone = phone;
    }

    if (status) {
      outerParams.status = status;
    }


    if (Array.isArray(this.time) && this.time.length === 2) {
      const [startDate, endDate] = this.time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      outerParams.start_date = startTime;

      outerParams.end_date = endTime;
    }

    if (mode == "exportExcel" && !(outerParams.start_date && outerParams.end_date)) {
      this.$message.error("请选择时段导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      outerParams.__isUnique = !firstLoading;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }
}

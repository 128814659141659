import {
  mobile
} from '@/common/env'

import {
  // dataStatisticRoutes,
  dataWholeBoardRoutes,
  merchantManageRoutes,
  // accountManangeRoutes,
  userManageRoutes,
  competitionManageRoutes,
  customerServiceRoutes,
  operationManageRoutes,
  financeManageRoutes,
  auditManageRoutes,
  officialWebManageRoutes,
  marketManageRoutes,
  couponManageRoutes,
  systemManageRoutes
} from '@/router'

// import router from '@/router';

import { Message } from 'element-ui';

import RightMenuAPI from './api/systemManage/rightMenuList'

import {
  getMobileSpecialAsideMenus
} from './asideMenuMobile'

export function getRightMenuConfig(mode: string = 'config'): Promise<any> {
  const rightMenuApi = new RightMenuAPI()

  return new Promise((resolve, reject) => {
    switch (mode) {
      case 'config':
        rightMenuApi.getRightMenuTreeConfigList({}, (res: any) => {
          const {
            list
          } = res.data || {}

          console.log(list, 'list getRightMenuTreeConfigList')

          if (Array.isArray(list) && list.length) {
            const newList = handleConfigMenuList(list)

            // console.log(newList, 'newList')

            resolve({
              list: newList
            })
          } else {
            Message.error('该账户暂无任何权限，请联系管理员处理')

            // router.replace({
            //   path: '/login'
            // })
            resolve({
              list: []
            })
          }
        }, (err: any) => {
          reject(err)
        })
        break;
      case 'batch':
        rightMenuApi.getRightMenuTreeBatchList({}, (res: any) => {
          resolve(res.data)
        }, (err: any) => {
          reject(err)
        })
        break;
    }

  })

}

function handleConfigMenuList(list: any[]) {
  const newList: any[] = []

  for (const item of list) {
    const {
      id,
      menu_name,
      menu_url,
      is_record,
      is_show,
      menu_key,
      child
    } = item || {}
    const newItem: any = {
      id,
      menu_name,
      menu_url,
      is_record,
      is_show,
      menu_key,
    }


    if (Array.isArray(child)) {
      newItem.children = [
        ...child
      ]
    }


    newList.push(newItem)
  }

  return newList
}

export function getConfigMapList(configlist: any[], mode = 'custom-key', parent_ids: any[] = []) {
  const newConfigMapList: any = new Map()

  for (const item of configlist) {
    const {
      id,
      menu_name,
      menu_url,
      is_record,
      is_show,
      menu_key,
      children,
      child
    } = item || {}
    const newItem: any = {
      id,
      menu_name,
      menu_url,
      is_record,
      is_show,
      menu_key,
    }


    newItem.__parent_ids = parent_ids


    if (children && Array.isArray(children)) {
      newItem.children = getConfigMapList(children, mode, [
        ...(parent_ids || []),
        newItem.id
      ])
    } else if (child && Array.isArray(child)) {

      newItem.children = getConfigMapList(child, mode, [
        ...(parent_ids || []),
        newItem.id
      ])

    }

    switch (mode) {
      case 'custom-key':
        newConfigMapList.set(menu_key, newItem)
        break;
      case 'exist-id':
        newConfigMapList.set(id, newItem)
        break;
    }
  }

  return newConfigMapList
}

function handleDefaultRightMenuList(list: any[], mapList: Map<string, any>, level: number = 1) {
  function handleMenuCategory(list: any[]) {
    const newList: any[] = []
    for (const item of list) {
      // console.log(item, 'item menuCategory')

      // console.log(mapList.has(item.key), 'mapList.has(item.key)')

      const itemKey = item.key

      // console.log(itemKey, 'itemKey')
      if (mapList.has(itemKey)) {
        const configItem = mapList.get(itemKey)

        const {
          menu_name,
          children,
          child
          // menu_url,
          // is_record
        } = configItem || {}
        const newCategoryItem: any = {
          ...item,
          name: menu_name,
          // menu_url,
        }

        if (item.children && Array.isArray(item.children)) {
          newCategoryItem.children = handleDefaultRightMenuList(item.children, child || children, level + 1)
        }

        // console.log(newCategoryItem, 'newCategoryItem')


        newList.push(newCategoryItem)
      }
    }

    // console.log(newList, 'newList handleMenuCategory')

    return newList
  }

  function handlePageMenu(list: any[]) {
    const newList: any[] = []

    for (const item of list) {
      // console.log(mapList, 'mapList')
      // console.log(item, 'item')
      const configItem = mapList.get(item.meta.key)

      const {
        menu_name,
        menu_url,
        // is_show
      } = configItem || {}

      if (mapList.has(item.meta.key)) {
        const newPageItem: any = {
          ...item,
          path: menu_url
        }

        if (menu_name) {
          newPageItem.meta.title = menu_name
        }



        newList.push(newPageItem)
      }
    }

    // console.log(newList, 'newList handlePageMenu')


    return newList
  }
  switch (level) {
    case 1:

      return handleMenuCategory(list)
    case 2:

      return transformRoute2Children(handlePageMenu(list))
  }
}


async function filterStrategy(defaultList: any[], targetList: any[] = [], getConfigFunc: Function = () => Promise.resolve()) {
  const { list } = await getConfigFunc('config')

  console.log(list, 'list getConfigFunc')

  if (Array.isArray(list)) {
    if (list.length == 0) {
      // targetList.push(
      //   ...(handleDefaultRightMenuList(defaultList, new Map([])) || [])
      // )

      return
    }

    const mapList = getConfigMapList(list)

    // console.log(mapList, 'mapList')

    targetList.push(
      ...(handleDefaultRightMenuList(defaultList, mapList) || [])
    )
  }
}

function transformRoute2Children(routes: any[]): any[] {
  const children = routes.filter((route: any) => {
    const {
      meta
    } = route

    const {
      asideHidden
    } = meta

    return asideHidden != true
  }).map((route) => {
    const {
      meta
    } = route || {}

    const {
      title
    } = meta
    const child = {
      id: route.name,
      name: title || '',
      url: route.path
    }

    return child
  })

  return children
}

export const categorys2routeList = mobile ? [
  dataWholeBoardRoutes,
  ...getMobileSpecialAsideMenus()
] : [
  dataWholeBoardRoutes,
  userManageRoutes,
  merchantManageRoutes,
  couponManageRoutes,
  competitionManageRoutes,
  customerServiceRoutes,
  operationManageRoutes,
  financeManageRoutes,
  auditManageRoutes,
  officialWebManageRoutes,
  marketManageRoutes,
  systemManageRoutes
]

export function getOriginMenuList(isMobile: boolean = false): any[] {
  const list = [
    {
      id: 1,
      icon: require("@/assets/home/pingtaiguanli@2x.png"),
      name: '数据总览',
      key: 'data-board',
      children: []
    },
    {
      id: 2,
      icon: require("@/assets/home/pingtaiguanli@2x.png"),
      name: '用户管理',
      key: 'user-manage',
      children: []
    },

    {
      id: 3,
      icon: require("@/assets/home/pingtaiguanli@2x.png"),
      name: '商家管理',
      key: 'merchant-manage',
      children: []
    },
    {
      id: 4,
      icon: require("@/assets/home/pingtaiguanli@2x.png"),
      name: '卡券管理',
      key: 'coupon-manage',
      children: []
    },
    {
      id: 5,
      icon: require("@/assets/home/yunying@2x.png"),
      name: '赛事管理',
      key: 'competition-manage',
      children: []
    },
    {
      id: 6,
      icon: require("@/assets/home/yunying@2x.png"),
      name: '熊猫客服',
      key: 'customer-service',
      children: []
    },
    {
      id: 7,
      icon: require("@/assets/home/yunying@2x.png"),
      name: '运营管理',
      key: 'operate-manage',
      children: []
    },
    {
      id: 8,
      icon: require("@/assets/home/xitongshezhi@2x.png"),
      name: '财务管理',
      key: 'finance-manage',
      children: []
    },
    {
      id: 9,
      icon: require("@/assets/home/xitongshezhi@2x.png"),
      name: '审核管理',
      key: 'audit-manage',
      children: []
    },
    {
      id: 10,
      icon: require("@/assets/home/xitongshezhi@2x.png"),
      name: '招商加盟',
      key: 'official-website-manage',
      children: []
    },
    {
      id: 11,
      icon: require("@/assets/home/xitongshezhi@2x.png"),
      name: '市场部',
      key: 'market-manage',
      children: []
    },
    {
      id: 12,
      icon: require("@/assets/home/xitongshezhi@2x.png"),
      name: '系统管理',
      key: 'system-manage',
      children: []
    }

  ]

  const mobileList = [
    {
      id: 1,
      icon: require("@/assets/home/pingtaiguanli@2x.png"),
      name: '数据总览',
      key: 'data-board',
      children: []
    },
    {
      id: 3,
      icon: require("@/assets/home/pingtaiguanli@2x.png"),
      name: '商家管理',
      key: 'merchant-manage',
      children: []
    },
    {
      id: 5,
      icon: require("@/assets/home/yunying@2x.png"),
      name: '赛事管理',
      key: 'competition-manage',
      children: []
    },
    {
      id: 6,
      icon: require("@/assets/home/yunying@2x.png"),
      name: '运营管理',
      key: 'operate-manage',
      children: []
    },
    {
      id: 7,
      icon: require("@/assets/home/xitongshezhi@2x.png"),
      name: '财务管理',
      key: 'finance-manage',
      children: []
    },
  ]



  return isMobile ? mobileList : list
}

const categoryList = getOriginMenuList(mobile)


const defaultRightMenuList = categoryList.map((item, index) => {
  item.children.push(
    ...(categorys2routeList[index] || [])
  )

  return item
})

export function filterShowRouteStrategy(targetList: any[] = [], getConfigFunc: Function = () => Promise.resolve()) {
  return filterStrategy(defaultRightMenuList, targetList, getConfigFunc)
}

export default async function getMenuList(): Promise<any[]> {
  const token = localStorage.getItem('token')

  if (!token) return Promise.reject('未登录')

  const filterRightMenuList: any[] = []

  await filterStrategy(defaultRightMenuList, filterRightMenuList, getRightMenuConfig)

  console.log(filterRightMenuList, 'filterRightMenuList')

  return filterRightMenuList

  // return defaultRightMenuList
}


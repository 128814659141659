import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class SingleCardChargeApi extends BasicsClass {
  public apiUrls: any = new Map([
    ['getSingleCardChargeList', {
      url: '/admin/shopMember/list',
      name: 'list',
      label: '列表'
    }],
    ['refundSingleCardCharge', {
      url: '/admin/shopMember/refund',
      name: 'refund',
      label: '退款'
    }],
    ['exportExcelSingleCardChargeList', {
      url: '/admin/shopMember/exportList',
      name: 'export-excel',
      label: '导出'
  }],
    ['searchVenueList', {
      url: '/admin/coupons/shop/list',
      name: 'shop-list',
      label: '店铺列表',
      // hidden: true
    }],
  ])

  // 单店卡充值列表
  public getSingleCardChargeList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getSingleCardChargeList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }


  // 退款 该充值
  public refundSingleCardCharge(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('refundSingleCardCharge').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

   // 导出单店卡充值列表
   public exportExcelSingleCardChargeList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('exportExcelSingleCardChargeList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 搜索店铺列表
  public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('searchVenueList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}
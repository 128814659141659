import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class CoachActualPersonInfoAuditAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getList', {
            url: '/admin/coachCheck/index',
            name: 'list',
            label: '列表'
        }],
        ['updateCoachRegisterInfoAuditPassOrRejectStatus',  {
            url: '/admin/coachCheck/review',
            name: 'pass-or-reject-status',
            label: '审核通过/拒绝'
        }]
    ])
    
    // momo达到家-助教基本信息审核列表
    public getList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 问题类型
    // public getProblemTypes (params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    //     this.BasicPost('/admin/feedback/lists', {
    //         ...params
    //     }, false, false, false, successCb, failCb, showLoading);
    // }

    // 处理问题状态更新
    public updateCoachRegisterInfoAuditPassOrRejectStatus(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        
        // const urlModes: any = {
        //     'agree': apiUrls.get('updateCoachRegisterInfoAuditPassStatus').url,
        //     'disagree': apiUrls.get('updateCoachRegisterInfoAuditRejectStatus').url,
        // }

        // 状态 2通过 3拒绝
        const mode2Type: any = {
            'agree': 2,
            'disagree': 3
        }

        const url = apiUrls.get('updateCoachRegisterInfoAuditPassOrRejectStatus').url

        params.status = mode2Type[params.mode]


        delete params.mode

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading);
    }

    // // momo达到家-渠道列表
    // public getofficialChannel(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    //     this.BasicPost('/channel/channel', {
    //         ...params
    //     }, false, false, false, successCb, failCb, showLoading);
    // }

    // // momo达到家-导出渠道统计
    // public exportExcelChannelList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    //     this.BasicPost('/channel/export', {
    //         ...params
    //     }, false, false, false, successCb, failCb, showLoading);
    // }
}
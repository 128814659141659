import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class PoolCategoryAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['poolTableCategory', {
            url: '/admin/poolTableCategory/list',
            name: 'list',
            label: '列表'
        }],
        ['addtype',  {
            url: '/admin/poolTableCategory/add',
            name: 'add',
            label: '添加'
        }],
        ['editortype',  {
            url: '/admin/poolTableCategory/edit',
            name: 'edit',
            label: '编辑'
        }],
        ['deletetype', {
            url: '/admin/poolTableCategory/delete',
            name: 'delete',
            label: '删除'
        }]
    ])
    //球桌分类列表
    public poolTableCategory(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('poolTableCategory').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    public addtype(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('addtype').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    public editortype(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('editortype').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    public deletetype(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('deletetype').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

  }

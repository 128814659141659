
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
// import { CouponsManageAPI } from "@/common/api/couponList";
import VantPointLoanRecordAPI from "@/common/api/operationManage/vantPointLoanRecord";
import { excuteDownloadExport2Excel } from "@/common/xlsx";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import ZlSelect from "@/components/third-custom-components/select";

import ZlOption from "@/components/third-custom-components/option";

import { shareConfirmOptions } from "@/common/config";

export const pageApiList = getPageAPIs(VantPointLoanRecordAPI);

const defaultForms = {
  // operation: 1, // 操作方向 1增加 2减少
  loan_integral: '',
  // remark: "", // 原因
  venue_id: "",
  venue: {
    id: '',
    name: ''
  },
  // singleMaxCountType: '0',
  // shop_id: "",
  // maxLimitCount: ''
};

const defaultSearchVenuePageOption = {
  page: 1,
  limit: 10,
  isRearchBottom: false,
};

const businessReminderDialogTitle2ch: any = {
  "new-vant-point": "新增",
  "edit-vant-point": "编辑",
};

// 最大有效期
// const maxExpiredDays = 9999;

// 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;

const maxTotalMoney = 9999999;


// 操作方向 0未知 1增加 2减少
// const vantPointType2signal: string[] = ['', '+', '-']

@Component({
  components: {
    ZlSelect,
    ZlOption,
  },
  filters: {
    

    // showGetVantPoint(row: any) {
    //   const {
    //     loan_integral,
    //     operation
    //   } = row || {}

    //   return `${vantPointType2signal[operation]}${loan_integral}` 
    // }
  },
})
export default class VantPointList extends Vue {
  private form: any = {
    // storageLockerId: "",
    // phone: "",
    // shop_name: "",
    // time: []
    // shop_id: '',
    // time: [],
    // startDate: "",
    // endDate: "",
  };


  private vantChangeTypeList: any[] = [
    {
      label: '增加',
      value: 1
    },
    {
      label: '减少',
      value: 2
    }
  ]
  private forms: any = {
    // maxType: "0",
    // ...defaultForms
  };

  private deepLoopUtil: any = {};

  // private merchantApi: any;

  private vantPointLoanRecordApi = new VantPointLoanRecordAPI();

  // private storageLockerTimeDurationAPI = new VantPointTimeDurationManageAPI();
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    // this.searchVenuePageOption = {
    //   ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    // };

    // this.merchantApi = new MerchantApi();
  }

  private created(): void {
    this.getVantPointRecordList();

    // this.refreshVantPointTimeDurations();

    // this.refreshTableClass();
  }
  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getVantPointRecordList();
  }
  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getVantPointRecordList();
  }

  private tableData: any[] = [];

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";

  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getVantPointRecordList();
  }

  private addOrUpdateVantPointTitle: string = "新增";

  private dialogFormVisible: boolean = false;

  // 拉起运营提醒弹框
  private handleWakeVantPointDialog(mode: string) {
    // if (mode == 'new-vant-point') { // 新增运营提醒

    // }
    this.storageLockerDialogMode = mode;
    // this.addOrUpdateVantPointTitle =
    //   businessReminderDialogTitle2ch[mode] || "运营提醒";

    this.initVantPointForm(mode);

    this.dialogFormVisible = true;
  }

  private currentVantPointItem: any = null;

  private storageLockerDialogMode: string = "";

  private async initVantPointForm(mode: string) {
    const forms: any = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    // if (mode == "edit-vant-point") {
    //   // 编辑
    //   // await this.getMoreVenueList(true);

    //   // await this.refreshVantPointTimeDurations();

    //   // await this.refreshTableClass();

    //   const currentVantPointItem = this.currentVantPointItem;

    //   const {
    //     id,
    //     total_money,
    //     remark,
    //     operate_name,
    
    //   } = currentVantPointItem || {};

    //   if (total_money) {
    //     forms.total_money = total_money;
    //   }

    //   if (remark) {
    //     forms.remark = remark;
    //   }

    //   if (operate_name) {
    //     forms.operate_name = operate_name;
    //   }

    //   const venues: any[] = [];

    //   // const handleAddExtraVenue = (venues: any[], id: any, name: any) => {
    //   //   if (name && typeof id == "number") {
    //   //     let existVenue = this.venueList.find((venue) => venue.id == id);

    //   //     if (!existVenue) {
    //   //       existVenue = {
    //   //         id,
    //   //         name,
    //   //       };
    //   //       this.venueList.push(existVenue);
    //   //     }

    //   //     venues.push(existVenue);
    //   //   }
    //   // };

    //   // handleAddExtraVenue(venues, shop_id, shop_name);
    //   // handleAddExtraVenue(venues, assistant_shop_id, assistant_shop_name);

    //   // if (shop_name && typeof shop_id == "number") {
    //   //   console.log(shop_id, "shop_id");

    //   //   console.log(this.venueList, "this.venueList");
    //   //   let existVenue = this.venueList.find((venue) => venue.id == shop_id);

    //   //   if (!existVenue) {
    //   //     existVenue = {
    //   //       id: shop_id,
    //   //       name: shop_name,
    //   //     };
    //   //     this.venueList.push(existVenue);
    //   //   }

    //   //   forms.venue = existVenue;
    //   // }

    //   forms.venues = venues;

    //   forms.id = id;
    // }

    console.log(forms, "forms");

    // console.log(this.venueList, "this.venueList");

    this.forms = forms;
  }

  // private handleWakeEditVantPointDialog(vantPointLoanItem: any) {
  //   this.currentVantPointItem = vantPointLoanItem;

  //   this.handleWakeVantPointDialog("edit-vant-point");
  // }

  private handleDelteVantPointDialog(vantPointLoanItem: any) {
    this.$confirm("确定删除该积分贷款?", "", {
      ...shareConfirmOptions,
    }).then(() => {
      this.vantPointLoanRecordApi.deleteVantPointLoan(
        {
          id: vantPointLoanItem.id,
        },
        () => {
          this.getVantPointRecordList();
        },
        () => {}
      );
    });
  }

  /** 店铺搜索 */
  private venueList: any[] = [
    // {
    //   id: 1,
    //   label: '测试'
    // },
    // {
    //   id: 2,
    //   label: '测试2'
    // }
  ];

  private searchVenueLoading: boolean = false;

  private remoteGetVenueQueryString: string = "";

  private remoteSearchVenueTimer: any = null;

  private searchVenuePageOption: any = {};

  private initRemoteGetVenuePageOption() {
    this.searchVenuePageOption = {
      ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    };
  }

  private getSearchVenueParams() {
    const searchVenuePageOption = this.searchVenuePageOption;

    const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

    const { page, limit } = searchVenuePageOption;

    const params: any = {
      page,
      limit,
    };

    if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
      params.name = remoteGetVenueQueryString.trim();
    }

    return params;
  }

  private getMoreVenueList(refresh: boolean = true) {
    return new Promise((resolve: Function, reject: Function) => {
      if (refresh) {
        this.initRemoteGetVenuePageOption();
      }

      const params = this.getSearchVenueParams();

      this.vantPointLoanRecordApi.searchVenueList(
        params,
        (res: any) => {
          console.log(res, "res");

          const venueList: any[] = res.data || [];

          this.venueList = refresh
            ? venueList
            : this.venueList.concat(venueList);

          if (Array.isArray(res.data) && res.data.length == 0) {
            this.searchVenuePageOption.isRearchBottom = true;
          }
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          resolve();
        },
        () => {
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          reject();
        }
      );
    });
  }

  // 第一次搜索 无关键字
  private handleFirstGetVenueList() {
    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 清空搜索
  private handleClearGetVenueList() {
    this.remoteGetVenueQueryString = "";

    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 远程搜索
  private remoteGetVenueMethod(query: string) {
    query = query.trim ? query.trim() : "";

    if (query !== "") {
      this.remoteGetVenueQueryString = query;
      clearTimeout(this.remoteSearchVenueTimer);

      this.remoteSearchVenueTimer = setTimeout(() => {
        this.searchVenueLoading = true;

        this.getMoreVenueList();
        
      }, 300);
      
    } else {
      this.remoteGetVenueQueryString = "";
      this.venueList = [];
    }
  }

  private addVenueListLoading: boolean = false;

  private remoteAddVenueMethod() {
    if (this.addVenueListLoading) return;
    const searchVenuePageOption = this.searchVenuePageOption;

    const { isRearchBottom } = searchVenuePageOption;

    if (isRearchBottom) return;

    this.searchVenuePageOption.page++;

    this.addVenueListLoading = true;

    this.getMoreVenueList(false);
  }

  /** end 店铺搜索 */

  // 流水
  // private handleLimitTotalMoney(val: string) {
  //   Util.limitIntegetInput(
  //     val,
  //     (new_val: string) => {
  //       const limit_val = Math.min(Number(new_val), maxTotalMoney).toString();

  //       if (maxTotalMoney == Number(limit_val)) {
  //         this.$set(this.forms, "total_money", limit_val);
  //       } else {
  //         this.$set(this.forms, "total_money", new_val);
  //       }
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "total_money", new_val);
  //     }
  //   );
  // }

  // private handleLimitSingleMaxCount(val: string) {
  //   Util.limitIntegetInput(
  //     val,
  //     (new_val: string) => {
  //       this.$set(this.forms, "maxLimitCount", new_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "maxLimitCount", new_val);
  //     }
  //   );
  // }

  // 积分
  private hanleLimitVantPoint(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        this.$set(this.forms, "loan_integral", new_val);
      },
      (new_val: string) => {
        this.$set(this.forms, "loan_integral", new_val);
      }
    );
  }

  private sumbitVantPointLoading: boolean = false;

  // 提交运营提醒配置
  private handleSumbitVantPoint() {
    const storageLockerDialogMode = this.storageLockerDialogMode;

    const params = this.getVantPointFormsParams(storageLockerDialogMode);

    console.log(params, "params");
    if (params === false) return;

    this.sumbitVantPointLoading = true;
    switch (storageLockerDialogMode) {
      case "new-vant-point":
        this.vantPointLoanRecordApi.addVantPointLoan(
          params,
          () => {
            this.getVantPointRecordList();
            this.dialogFormVisible = false;
            this.sumbitVantPointLoading = false;
          },
          () => {
            this.sumbitVantPointLoading = false;
          }
        );
        break;
      case "edit-vant-point":
        // this.vantPointLoanRecordApi.updateVantPoint(
        //   params,
        //   () => {
        //     this.getVantPointRecordList();
        //     this.dialogFormVisible = false;
        //     this.sumbitVantPointLoading = false;
        //   },
        //   () => {
        //     this.sumbitVantPointLoading = false;
        //   }
        // );
        break;
    }
  }

  private getVantPointFormsParams(mode: string) {
    const showErrorMessage = (msg: string) => {
      if (!!msg) {
        this.$message.error(msg);
      }
    };

    const forms = this.forms;

    const {
      id,
      // name,
      // expired_days,
      // meituanId,
      // timeDuration
      // machine_number,
      // operation,
      // remark,
      // operate_name,
      // venues,
      venue
    } = forms || {};

     const params: any = {
      // operation
    };

    // console.log(venues, 'venues')

    if (!(venue && venue.id)) {
      showErrorMessage('请选择店铺');
      return false
    } else {
      params.shop_id = venue.id
    }


    const keys = ["loan_integral"];

    const key2EndKey: any = {
      loan_integral: "loan_integral",
    };

    const key2ErrMsg: any = {
      loan_integral: "积分不可为空",
    };

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (forms[key]) {
        const errMsg = key2ErrMsg[`${key}_${forms[key]}`];
        if (errMsg) {
          showErrorMessage(errMsg);

          return false;
        }
        params[key2EndKey[key] || key] = forms[key];
      } else {
        showErrorMessage(key2ErrMsg[key]);

        return false;
      }
    }

    if (mode == "edit-vant-point") {
      params.id = id;
    }

    return params;
  }

  //获取运营提醒列表
  private getVantPointRecordList(): void {
    this.vantPointLoanRecordApi.getVantPointRecordList(
      { ...this.getVantPointParams() },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page;
        this.tablePage.prepage = res.data.prepage;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private getVantPointParams(mode = "all"): any {
    const params: any = {};

    // const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    // const { shop_name } = form;

    // if (shop_name) {
    //   params.shop_name = shop_name
    // }

    // if (Array.isArray(time) && time.length === 2) {
    //   const [startDate, endDate] = time;

    //   const startTime = Util.dateTimeUtilnohms(startDate);
    //   const endTime = Util.dateTimeUtilnohms(endDate);

    //   params.start_date = startTime;

    //   params.end_date = endTime;
    // }

    if (mode == "exportExcel" && !(params.start_date && params.end_date)) {
      this.$message.error("请选择日期导出数据");
      return null;
    }

    if (mode === "all") {
      params.page = page;

      params.limit = prepage;

      // outerParams.__isUnique = !firstLoading;
    }

    return params;
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  /** 导出excel */
  // private downloadExcelLoading: boolean = false;

  // // 字段名 文案对应关系
  // private merchantExcelList2ch: any = {
  //   name: "店铺名称",
  //   shop_id: " 店铺ID",
  //   cabinet_id: "绑定设备ID",
  //   admin_name: "操作人",
  //   updated_at: "操作时间"
  // };
  // // 商户Excel 表头的先后顺序
  // private merchantExcelSortList: string[] = [
  //   "name",
  //   "shop_id",
  //   "cabinet_id",
  //   "admin_name",
  //   "updated_at"
  // ];

  // // 点击导出excel 表格
  // private handleExportExcelVantPointList() {
  //   const params = this.getVantPointParams('exportExcel')

  //   if (params === null) {
  //     return false;
  //   }

  //   this.downloadExcelLoading = true;
  //   this.vantPointLoanRecordApi.exportExcelStoragelockerList(
  //     params,
  //     (res: any) => {
  //       const { start_date, end_date } = params || {};

  //       const filename = `运营提醒管理列表 ${start_date}到${end_date}.xlsx`;
  //       const { data } = res || {};

  //       const {
  //         list: originList
  //       } = data || {}

  //       const newList = Array.isArray(originList)
  //         ? originList.map((item) => {
  //             const {
  //               // status,
  //               // back_goods_detail
  //              } = item || {};
  //             const newItem = {
  //               ...(item || {}),
  //               // __status_text: withdrawAccountAudit2Text[status],
  //               // __back_good_details: back_goods_detail.map((goods: any, index: number) => `${ goods.name }x${ goods.num }  ¥${ goods.price }`).join('; \t')
  //             };

  //             for (let key in item) {
  //               if (typeof item[key] == 'number') {
  //                 newItem[key] = item[key].toString()
  //               }
  //             }

  //             return newItem;
  //           })
  //         : [];

  //       if (newList.length > 0) {
  //         const merchantExcelSortList = this.merchantExcelSortList;

  //         const merchantExcelList2ch = this.merchantExcelList2ch;

  //         const excelJSONData = getExcelJSONDataByList(
  //           newList,
  //           merchantExcelSortList,
  //           merchantExcelList2ch
  //         );

  //         excuteDownloadExport2Excel(excelJSONData, filename);
  //       } else {
  //         this.$message.info("选中时段没有可导出的信息");
  //       }

  //       this.downloadExcelLoading = false;
  //     },
  //     () => {
  //       this.downloadExcelLoading = false;
  //     }
  //   );
  // }

  /** end 导出excel */

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };
}

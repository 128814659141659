import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class VantPointChangeRecordAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getVantPointRecordList', {
      url: '/admin/shopYouzan/integral',
      name: 'list',
      label: '列表'
    }],
    ['changeVantPoint', {
      url: '/admin/shopYouzan/integralOperate',
      name: 'change-point',
      label: '修改积分'
    }],

    ['searchVenueList', {
      url: '/admin/coupons/shop/list',
      name: 'shop-list',
      label: '店铺列表',
      // hidden: true
    }],
   
  ])

  // 有赞商城积分列表
  public getVantPointRecordList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getVantPointRecordList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }


  // 添加有赞商城积分
  public changeVantPoint(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('changeVantPoint').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 搜索店铺列表
  public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('searchVenueList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}
import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class DoorBlackListAPI extends BasicsClass {
    public apiUrls: any = new Map([
       
        ['shopAir', {
            url: '/admin/woBlacklist/index',
            name: 'list',
            label: '列表'
        }],
        ['shopAiradd', {
            url: '/admin/woBlacklist/add',
            name: 'add',
            label: '新增门禁'
        }],
        ['shopAiredit', {
            url: '/admin/woBlacklist/edit',
            name: 'edit',
            label: '编辑门禁'
        }],
        
        ['shopAirdel', {
            url: '/admin/woBlacklist/del',
            name: 'delete',
            label: '删除门禁'
        }],

    ])

    // 门禁列表
    public shopAir(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('shopAir').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 新增门禁
    public shopAiradd(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('shopAiradd').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 编辑门禁
    public shopAiredit(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('shopAiredit').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 删除门禁
    public shopAirdel(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('shopAirdel').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

}
import { BasicsClass } from '@/common/BasicsClass';
export class CouponVertifyCodeRecordAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getCouponVertifyCodeRecords', {
            url: '/admin/coupons/verifyList',
            name: 'list',
            label: '列表'
        }],
        ['exportCouponVertifyCodeRecords',  {
            url: '/admin/coupons/exportVerifyList',
            name: 'export-excel',
            label: '导出'
        }],
       
    ])

    // 团购券核销记录列表
    public getCouponVertifyCodeRecords(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getCouponVertifyCodeRecords').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 导出团购券核销记录列表
    public exportCouponVertifyCodeRecords(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('exportCouponVertifyCodeRecords').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}
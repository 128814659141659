
import dayjs from "@/plugins/dayjs";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";

import PandaMobileTable from "@/components/mobile-custom/panda-table.vue";

import PandaMobileTWFContainer from "@/components/mobile-custom/panda-table-with-filter-container.vue";

import { MonthIncomeApi } from "@/common/api/dataWholeBoard/monthIncome";
import { excuteDownloadExport2Excel } from "@/common/xlsx";

function getCurrentMonthDate() {
  const currentDay = (dayjs() as any).format("YYYY-MM");

  const curMonthStartDay = (dayjs().startOf("month") as any).format("YYYY-MM");

  return [curMonthStartDay, currentDay];
}

function getLastMonthDate() {
  const lastEndDay = (dayjs().startOf("month") as any).format("YYYY-MM");

  const lastStartDay = (
    dayjs().subtract(1, "month").endOf("month") as any
  ).format("YYYY-MM");

  return [lastStartDay, lastEndDay];
}

// console.log(getLastMonthDate(), 'getLastMonthDate')
export const pageApiList = getPageAPIs(MonthIncomeApi);

@Component({
  components: {
    PandaMobileTable,
    PandaMobileTWFContainer,
  },
  filters: {
    // getTodayTotalIncome(row: any) {
    // }
  },
})
export default class MerchantsAudit extends Vue {
  /** 移动端相关属性 方法 */
  private allMobileChooseFields: any[] = [
    {
      label: "日期",
      field: "date",
    },
    {
      label: "GMV",
      field: "gmv",
    },
    {
      label: "会员充值金额",
      field: "member_recharge",
    },
    {
      label: "会员消费金额",
      field: "member_consume",
    },
    {
      label: "单店卡充值",
      field: "shop_member_price",
    },
    {
      label: "单店卡消费",
      field: "shop_member_consume",
    },
    {
      label: "会员账户余额",
      field: "member_money",
    },
    {
      label: "会员充值分佣金额",
      field: "member_recharge_income",
    },
    {
      label: "商家最终收益",
      field: "merchant_income",
    },
    {
      label: "报名费",
      field: "entry_fee",
    },
    {
      label: "商品销售",
      field: "product_sales_price",
    },
    {
      label: "商家账户余额",
      field: "merchant_account_balance",
    },
    {
      label: "商家提现金额",
      field: "merchant_widthdrawal_money",
    },
    {
      label: "优惠券销售",
      field: "voucher_income",
    },
    {
      label: "美团券核销金额",
      field: "meituan_price",
    },
    {
      label: "抖音券核销金额",
      field: "douyin_price",
    },
    {
      label: "快手券核销金额",
      field: "kuaishou_price",
    },
    {
      label: "体育券核销金额",
      field: "sport_price",
    },
    {
      label: "平台券核销金额",
      field: "platform_subsidy_price",
    },
    {
      label: '美团单店卡',
      field: 'meituan_shop_member_price'
    },
    {
      label: "本月教练收益",
      field: "coach_income",
    },
    {
      label: "线下助教收益",
      field: "assistant_income",
    },
    {
      label: "微信充值",
      field: "wechat_price",
    },
    {
      label: "支付宝充值",
      field: "alipay_price",
    },
    {
      label: "台球消费",
      field: "billiards_consume_price",
    },
    {
      label: "棋牌消费",
      field: "chess_consume_price",
    },
    {
      label: "助教消费",
      field: "assistant_consume_price",
    },
  ];
  /** end 移动端相关属性 方法 */

  private monthIncomeApi: any;

  constructor() {
    super();
    this.monthIncomeApi = new MonthIncomeApi();
  }

  private form: any = {
    // shop_name: '',
    time: [...getLastMonthDate()],
  };

  private pickerOptions: any = {
    shortcuts: [
      // {
      //   text: '本月',
      //   onClick(picker: any) {

      //     picker.$emit('pick', getCurrentMonthDate());
      //   }
      // },
      {
        text: "上月",
        onClick(picker: any) {
          picker.$emit("pick", getLastMonthDate());
        },
      },
    ],
  };

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private activated(): void {
    // if (this.$route.query.name) {
    //   this.form.shop_name = this.$route.query.name;
    // }
    this.search();
  }

  private getTable(): void {
    this.monthIncomeApi.monthBusiness(
      {
        ...this.getMonthIncomeAuditTableListParams(),
      },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.totalCount = res.data.count;
      }
    );
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 商户字段名 文案对应关系
  private monthIncomeExcelList2ch: any = {
    date: "日期",
    member_recharge: "会员充值金额",
    member_consume: "会员消费金额",
    shop_member_price: "单店卡充值",
    shop_member_consume: "单店卡消费",
    member_money: "会员账户余额",
    member_recharge_income: "会员充值分佣金额",
    merchant_income: "商家最终收益",
    entry_fee: "报名费",
    product_sales_price: "商品销售",
    merchant_account_balance: "商家账户余额",
    merchant_withdrawal_money: "商家提现金额",
    voucher_income: "优惠券销售",
    meituan_price: "美团券核销金额",
    douyin_price: "抖音券核销金额",
    kuaishou_price: "快手券核销金额",
    sport_price: "体育券核销金额",
    platform_subsidy_price: "平台券核销金额",
    meituan_shop_member_price: "美团单店卡",
    coach_income: "本月教练收益",
    assistant_income: "线下助教收益",
    wechat_price: "微信充值",
    alipay_price: "支付宝充值",
    billiards_consume_price: "台球消费",
    chess_consume_price: "棋牌消费",
    assistant_consume_price: "助教消费",
  };
  // 商户Excel 表头的先后顺序
  private monthIncomeExcelSortList: string[] = [
    "date",
    "member_recharge",
    "member_consume",
    "shop_member_price",
    "shop_member_consume",
    "member_money",
    "member_recharge_income",
    "merchant_income",
    "entry_fee",
    "product_sales_price",
    "merchant_account_balance",
    "merchant_withdrawal_money",
    "voucher_income",
    "meituan_price",
    "douyin_price",
    "kuaishou_price",
    "sport_price",
    "platform_subsidy_price",
    "meituan_shop_member_price",
    "coach_income",
    "assistant_income",
    "wechat_price",
    "alipay_price",
    "billiards_consume_price",
    "chess_consume_price",
    "assistant_consume_price",
  ];

  private getMonthIncomeAuditTableListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const {
      // shop_name, // 店铺名称

      time,
    } = form || {};

    // console.log(technicianItem, "technicianItem");

    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};

    // if (shop_name) {
    //   outerParams.shop_name = shop_name;
    // }

    if (Array.isArray(time) && time.length === 2) {
      const [start_month, end_month] = time;

      outerParams.start_month = start_month;

      outerParams.end_month = end_month;
    }

    if (
      mode == "exportExcel" &&
      !(outerParams.start_month && outerParams.end_month)
    ) {
      this.$message.error("请选择创建时段导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;
    } else if (mode == "exportExcel") {
      // outerParams.is_export = 1
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  // 点击导出excel 表格
  private handleExportExcelMonthIncomeAuditList() {
    this.getTable();
    const params: any = this.getMonthIncomeAuditTableListParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.monthIncomeApi.exportExcelMonthIncomeAuditList(
      params,
      (res: any) => {
        const { start_month, end_month } = params || {};

        const filename = `每月营收列表 ${start_month}到${end_month}.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const { orderNum } = item || {};
              const newItem = {
                ...(item || {}),
              };

              newItem.orderNum =
                typeof orderNum == "number"
                  ? orderNum.toString()
                  : orderNum || "";

              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const monthIncomeExcelSortList = this.monthIncomeExcelSortList;

          const monthIncomeExcelList2ch = this.monthIncomeExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            monthIncomeExcelSortList,
            monthIncomeExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        // const testData = [
        //   { 主页: "test1223424", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
        //   { 主页: "433", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
        //   { 名称: "22", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
        //   { 名称: "43", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
        //   { 店家: "43", 价格: "6800", 数量: "6800", 昵称: "广告主网", }
        // ]

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */

  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}

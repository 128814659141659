
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
// import { CouponsManageAPI } from "@/common/api/couponList";
import CoachLeaderManageAPI from '@/common/api/operationManage/coachLeaderManage';
import { excuteDownloadExport2Excel } from "@/common/xlsx";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import ZlSelect from "@/components/third-custom-components/select";

import ZlOption from "@/components/third-custom-components/option";

import {
  shareConfirmOptions
} from '@/common/config'

export const pageApiList = getPageAPIs(CoachLeaderManageAPI)

const defaultForms = {
  phone: '', // 手机号
  gift_rate: '', // 平台礼物抽成
  order_rate: '', // 平台订单分成
  status: 1 // 状态 0下架 1上架
 
  // singleMaxCountType: '0',
  // shop_id: "",
  // maxLimitCount: ''
};

const coachLeaderStatus2Ch: string[] = ['下架', '上架']

// const defaultSearchVenuePageOption = {
//   page: 1,
//   limit: 10,
//   isRearchBottom: false,
// };

const coachLeaderManageDialogTitle2ch: any = {
  "new-coach-leader-manage": "新增",
  "edit-coach-leader-manage": "编辑",
};

// 最大有效期
// const maxExpiredDays = 9999;

// 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;

const maxTotalMoney = 9999999

@Component({
  components: {
    ZlSelect,
    ZlOption,
  },
  filters: {
    getCoachLeaderName(status: number) {
      return coachLeaderStatus2Ch[status] || ''
    }
  }
})
export default class CoachLeaderManageList extends Vue {
  private form: any = {
    // storageLockerId: "",
    phone: "",
    // time: []
    // shop_id: '',
    // time: [],
    // startDate: "",
    // endDate: "",
  };
  private forms: any = {
    // maxType: "0",
    // ...defaultForms
  };

  private coachLeaderStatusList: any[] = [
    {
      label: '下架',
      value: 0
    },
    {
      label: '上架',
      value: 1
    }
  ]

  private deepLoopUtil: any = {};

  // private merchantApi: any;

  private coachLeaderManageApi = new CoachLeaderManageAPI();

  // private storageLockerTimeDurationAPI = new CoachLeaderManageTimeDurationManageAPI();
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    // this.searchVenuePageOption = {
    //   ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    // };

    // this.merchantApi = new MerchantApi();
  }

  
  private created(): void {
    this.getCoachLeaderManageList();

    // this.refreshCoachLeaderManageTimeDurations();

    // this.refreshTableClass();
  }
  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getCoachLeaderManageList();
  }
  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getCoachLeaderManageList();
  }

  private tableData: any[] = [];


  // 跳转助教队长管理的 助教列表
  private handleGoSubCoachManage(coachLeaderItem: any) {
    this.$router.push({
      path: '/coachLeaderSubordinateList',
      query: {
        coachLeaderId: coachLeaderItem.id || ''
      }
    })
  }

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";
  
  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getCoachLeaderManageList();
  }

  private addOrUpdateCoachLeaderManageTitle: string = "助教队长";

  private dialogFormVisible: boolean = false;

  // 拉起助教队长弹框
  private handleWakeCoachLeaderManageDialog(mode: string) {
    // if (mode == 'new-coach-leader-manage') { // 新增助教队长

    // }
    this.storageLockerDialogMode = mode;
    this.addOrUpdateCoachLeaderManageTitle = coachLeaderManageDialogTitle2ch[mode] || "助教队长";

    this.initCoachLeaderManageForm(mode);

    this.dialogFormVisible = true;
  }

  private currentCoachLeaderManageItem: any = null;

  private storageLockerDialogMode: string = "";

  private async initCoachLeaderManageForm(mode: string) {
    const forms: any = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    if (mode == "edit-coach-leader-manage") {
      // 编辑
      // await this.getMoreVenueList(true);

      // await this.refreshCoachLeaderManageTimeDurations();

      // await this.refreshTableClass();

      const currentCoachLeaderManageItem = this.currentCoachLeaderManageItem;

      const {
        id,
        phone,
        gift_rate,
        order_rate,
        status
      } = currentCoachLeaderManageItem || {};

      if (phone) {
        forms.phone = phone;
      }

      if (typeof gift_rate == 'number') {
        forms.gift_rate = gift_rate.toString()
      }

      if (typeof order_rate == 'number') {
        forms.order_rate = order_rate.toString()
      }

      if (typeof status ==  'number') {
        forms.status = status
      }

      // const venues: any[] = []

      // const handleAddExtraVenue = (venues: any[], id: any, name: any) => {
      //   if (name && typeof id == 'number') {
      //     let existVenue = this.venueList.find((venue) => venue.id == id);

      //     if (!existVenue) {
      //       existVenue = {
      //         id,
      //         name,
      //       };
      //       this.venueList.push(existVenue);
      //     }

      //     venues.push(existVenue)
      //   }
      // }

      // handleAddExtraVenue(venues, shop_id, shop_name)
      // handleAddExtraVenue(venues, assistant_shop_id, assistant_shop_name)

      // if (shop_name && typeof shop_id == "number") {
      //   console.log(shop_id, "shop_id");

      //   console.log(this.venueList, "this.venueList");
      //   let existVenue = this.venueList.find((venue) => venue.id == shop_id);

      //   if (!existVenue) {
      //     existVenue = {
      //       id: shop_id,
      //       name: shop_name,
      //     };
      //     this.venueList.push(existVenue);
      //   }

      //   forms.venue = existVenue;
      // }

      // forms.venues = venues;

      forms.id = id;
    }

    console.log(forms, "forms");

    // console.log(this.venueList, "this.venueList");

    this.forms = forms;
  }

  private handleWakeEditCoachLeaderManageDialog(storageLockerItem: any) {
    this.currentCoachLeaderManageItem = storageLockerItem;

    this.handleWakeCoachLeaderManageDialog("edit-coach-leader-manage");
  }

  private handleDelteCoachLeaderManageDialog(storageLockerItem: any) {
    this.$confirm("确定删除该助教队长?", "", {
      ...shareConfirmOptions,
    }).then(() => {
      this.coachLeaderManageApi.deleteCoachLeaderManage({
        id: storageLockerItem.id
      }, () => {
        this.getCoachLeaderManageList()
      }, () => {

      })
    });
  }


  

  // 流水
  private handleLimitTotalMoney(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        const limit_val = Math.min(Number(new_val), maxTotalMoney).toString();

        if (maxTotalMoney == Number(limit_val)) {
          this.$set(this.forms, "total_money", limit_val);
        } else {
          this.$set(this.forms, "total_money", new_val);
        }
      },
      (new_val: string) => {
        this.$set(this.forms, "total_money", new_val);
      }
    );
  }

  // private handleLimitSingleMaxCount(val: string) {
  //   Util.limitIntegetInput(
  //     val,
  //     (new_val: string) => {
  //       this.$set(this.forms, "maxLimitCount", new_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "maxLimitCount", new_val);
  //     }
  //   );
  // }

  private sumbitCoachLeaderManageLoading: boolean = false;

  // 提交助教队长配置
  private handleSumbitCoachLeaderManage() {
    const storageLockerDialogMode = this.storageLockerDialogMode;

    const params = this.getCoachLeaderManageFormsParams(storageLockerDialogMode);

    console.log(params, "params");
    if (params === false) return;

    this.sumbitCoachLeaderManageLoading = true;
    switch (storageLockerDialogMode) {
      case "new-coach-leader-manage":
        this.coachLeaderManageApi.addCoachLeaderManage(
          params,
          () => {
            this.getCoachLeaderManageList();
            this.dialogFormVisible = false;
            this.sumbitCoachLeaderManageLoading = false;
          },
          () => {
            this.sumbitCoachLeaderManageLoading = false;
          }
        );
        break;
      case "edit-coach-leader-manage":
        this.coachLeaderManageApi.updateCoachLeaderManage(
          params,
          () => {
            this.getCoachLeaderManageList();
            this.dialogFormVisible = false;
            this.sumbitCoachLeaderManageLoading = false;
          },
          () => {
            this.sumbitCoachLeaderManageLoading = false;
          }
        );
        break;
    }
  }

  private getCoachLeaderManageFormsParams(mode: string) {
    const params: any = {};

    const showErrorMessage = (msg: string) => {
      if (!!msg) {
        this.$message.error(msg);
      }
    };

    const forms = this.forms;

    const {
      id,
      // name,
      // expired_days,
      // meituanId,
      // timeDuration
      // machine_number,
      // total_money,
      // remark,
      // operate_name,
      status
    } = forms || {};

    // console.log(venues, 'venues')

    Object.assign(params, {
      status
    })

   

    const keys = ["phone", "gift_rate", "order_rate"];

    const key2EndKey: any = {
      phone: "phone",
      gift_rate: "gift_rate",
      order_rate: "order_rate"
    };

    const key2ErrMsg: any = {
      phone: "手机号不能为空",
      gift_rate: "平台礼物抽成不可为空",
      order_rate: "平台订单分成不可为空"
    };

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (forms[key]) {
        const errMsg = key2ErrMsg[`${key}_${forms[key]}`];
        if (errMsg) {
          showErrorMessage(errMsg);

          return false;
        }
        params[key2EndKey[key] || key] = forms[key];
      } else {
        showErrorMessage(key2ErrMsg[key]);

        return false;
      }
    }
    

    if (mode == "edit-coach-leader-manage") {
      params.id = id;
    }

    return params;

  }

  //获取助教队长列表
  private getCoachLeaderManageList(): void {
    this.coachLeaderManageApi.getCoachLeaderManageList(
      { ...this.getCoachLeaderManageParams() },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page;
        this.tablePage.prepage = res.data.prepage;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private getCoachLeaderManageParams(mode = "all"): any {
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    const { phone } = form;

    if (phone) {
      params.phone = phone;
    }

    // if (Array.isArray(time) && time.length === 2) {
    //   const [startDate, endDate] = time;

    //   const startTime = Util.dateTimeUtilnohms(startDate);
    //   const endTime = Util.dateTimeUtilnohms(endDate);

    //   params.start_date = startTime;

    //   params.end_date = endTime;
    // }

     if (
      mode == "exportExcel" &&
      !(params.start_date && params.end_date)
    ) {
      this.$message.error("请选择日期导出数据");
      return null;
    }



    if (mode === "all") {
      params.page = page;

      params.limit = prepage;

      // outerParams.__isUnique = !firstLoading;
    }

    return params;
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  /** 导出excel */
  // private downloadExcelLoading: boolean = false;

  // // 字段名 文案对应关系
  // private merchantExcelList2ch: any = {
  //   name: "店铺名称",
  //   shop_id: " 店铺ID",
  //   cabinet_id: "绑定设备ID",
  //   admin_name: "操作人",
  //   updated_at: "操作时间"
  // };
  // // 商户Excel 表头的先后顺序
  // private merchantExcelSortList: string[] = [
  //   "name",
  //   "shop_id",
  //   "cabinet_id",
  //   "admin_name",
  //   "updated_at"
  // ];

  // // 点击导出excel 表格
  // private handleExportExcelCoachLeaderManageList() {
  //   const params = this.getCoachLeaderManageParams('exportExcel')

  //   if (params === null) {
  //     return false;
  //   }

  //   this.downloadExcelLoading = true;
  //   this.coachLeaderManageApi.exportExcelStoragelockerList(
  //     params,
  //     (res: any) => {
  //       const { start_date, end_date } = params || {};

  //       const filename = `助教队长管理列表 ${start_date}到${end_date}.xlsx`;
  //       const { data } = res || {};

  //       const {
  //         list: originList
  //       } = data || {}

  //       const newList = Array.isArray(originList)
  //         ? originList.map((item) => {
  //             const { 
  //               // status,
  //               // back_goods_detail
  //              } = item || {};
  //             const newItem = {
  //               ...(item || {}),
  //               // __status_text: withdrawAccountAudit2Text[status],
  //               // __back_good_details: back_goods_detail.map((goods: any, index: number) => `${ goods.name }x${ goods.num }  ¥${ goods.price }`).join('; \t')
  //             };

  //             for (let key in item) {
  //               if (typeof item[key] == 'number') {
  //                 newItem[key] = item[key].toString()
  //               }
  //             }

             
  //             return newItem;
  //           })
  //         : [];

  //       if (newList.length > 0) {
  //         const merchantExcelSortList = this.merchantExcelSortList;

  //         const merchantExcelList2ch = this.merchantExcelList2ch;

  //         const excelJSONData = getExcelJSONDataByList(
  //           newList,
  //           merchantExcelSortList,
  //           merchantExcelList2ch
  //         );

  //         excuteDownloadExport2Excel(excelJSONData, filename);
  //       } else {
  //         this.$message.info("选中时段没有可导出的信息");
  //       }

  //       this.downloadExcelLoading = false;
  //     },
  //     () => {
  //       this.downloadExcelLoading = false;
  //     }
  //   );
  // }

  /** end 导出excel */

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };
}

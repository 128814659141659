
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList,getPageAPIs } from "@/common/util";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import {PandaFanGroupQrcodeAPI} from "@/common/api/operationManage/pandaFanGroupQrcode";

import { CommonApi } from "@/common/api/common";

import gallery from "@/components/gallery.vue";

// import ZlSelect from "@/components/third-custom-components/select";

// import ZlOption from "@/components/third-custom-components/option";


import { excuteDownloadExport2Excel } from "@/common/xlsx";
export const pageApiList = getPageAPIs(PandaFanGroupQrcodeAPI);

import {
  // transferUserLevel2ch,
  leagueLevelName2Text,
} from '@/common/config'



const defaultForm = {
  // left: {
  //   ...defaultUserInfoForm
  // },
  // right: {
  //   ...defaultUserInfoForm
  // },
  // title: '', // 总标题
  
  // end_time: '', // 结束时间

  // // 赛前剩余总奖金
  // surplus_total_reward: '',

  // surplus_total_reward_show: 1, // 是否展示赛前剩余总奖金：0不展示1展示



  // total_reward_str: '', // 共计奖池
  // total_reward_str_show: 1, // 是否展示共计奖池字符串：0不展示1展示

  // surplus_score_str: '', // 剩余积分
  // surplus_score_str_show: 1, // 是否展示剩余积分字符串：0不展示1展示

  // score_str: '', // 比分
  // score_str_show: 1, // 是否展示比分字符串：0不展示1展示
  // venue_id: "",
  // venue: {
  //   id: "",
  //   name: "",
  // },
}

const defaultForms = {
  qrcode1: [],
  qrcode2: [],
  coachQrcode: [],
  // name: '', // 昵称
  // image_id: '', // 头像id
  // image_url: '', // 头像链接
}

// const defaultSearchVenuePageOption = {
//   page: 1,
//   limit: 10,
//   isRearchBottom: false,
// };

// const transfromGenders: any = [
//   '',
//   '女',
//   '男'
// ]


// function getFormUser(options: any) {
//   const keys: string[] = Object.keys(options)

//   const left: any = {
//     ...defaultUserInfoForm
//   }

//   const right: any = {
//     ...defaultUserInfoForm
//   }

//   keys.forEach((key) => {
//     if (key.startsWith('left_')) {
//       const restKey = key.slice(5)

//       left[restKey] = options[key]
//     }

//     if (key.startsWith('right_')) {
//       const restKey = key.slice(6)

//       right[restKey] = options[key]
//     }
//   })

//   return {
//     left,
//     right
//   }
// }

// 状态：0等待导入 1成功导入 2导入失败
// const importFileStatus2text: string[] = [
//   '等待导入',
//   '成功导入',
//   '导入失败'
// ]

@Component({
  filters: {
    // showImportFileStatus(status: number) {
    //   return importFileStatus2text[status] || '未知'
    // },

    // showLeaguePointTypeName(league_level: number) {
    //   return leagueLevelName2Text[league_level] || '未知'
    // }
    // showUserMemberTypeName: function(type: number) {
    //   return transferUserLevel2ch[type] || ''
    // }
  },
  components: {
    // ZlSelect,
    // ZlOption,
    gallery,
  },
})
export default class SingleCardTransferRecord extends Vue {
  private pandaFanGroupQrcodeApi: any;

  private deepLoopUtil: any;


  private utilIns: any;

  private qrcodeInfo: any = {};


  // private debounceRefreshSingleCardInfoFunc: any

  private commonApi: any;
  constructor() {
    super();
    this.pandaFanGroupQrcodeApi = new PandaFanGroupQrcodeAPI();

    this.deepLoopUtil = new DeepLoop();

    this.commonApi = new CommonApi();

    // this.form = {
    //   ...this.deepLoopUtil.deepCopy(defaultForm),
    // };

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    }

    this.utilIns = new Util()

    // this.debounceRefreshSingleCardInfoFunc = this.utilIns.debounce.call(
    //   this,
    //   this.refreshSingleCardAccountInfo,
    //   800
    // )
  }

  private form: any = {};

  private forms: any = {};

  private firstLoading: boolean = true;
  // private chooseTechnicianItem : any = null;

  // private tablePage: any = {
  //   totalCount: 0,
  //   pageNo: 1,
  //   pageSize: 10,
  //   pageSizes: [10, 20, 50, 100, 200, 500],
  // };


  private activated(): void {
    this.getTable();
  }


  private getTable(): void {
    this.pandaFanGroupQrcodeApi.getPandaFanGroupQrcodeOptionInfo(
      {},
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data: qrcodeInfo } = res;


        this.qrcodeInfo = qrcodeInfo

        this.firstLoading = false;
      },
      () => {
        this.firstLoading = false;
      },
      false
    );
  }


  private dialogFormVisible: boolean = false


  private handleSetUserAvatarInfo() {
    const params: any = {}
    // const mode = this.dialogFormMode

    const forms = this.forms

    const {
      qrcode1 = [],
      qrcode2 = [],
      coachQrcode = []
      // image_id,
      // image_url
    } = forms || {}

    // if (!name) {
    //   this.$message.error('昵称不可为空')
    //   return false
    // }

    // if (images.length == 0) {
    //   this.$message.error('头像不可为空')
    //   return false
    // }

    const {
      fileId: image1_id,
      // url: image1_url
    } = qrcode1[0] || {}

    const {
      fileId: image2_id,
      // url: image2_url
    } = qrcode2[0] || {}

    if (image1_id && image2_id) {
      params.image_id = [image1_id, image2_id].join(',')
    } else {
      this.$message.error('二维码图片不可为空')
      return false
    }

    const {
      fileId: coach_qrcode_id
    } = coachQrcode[0] || {}

    if (coach_qrcode_id) {
      params.coach_img_id = coach_qrcode_id
    }

    this.pandaFanGroupQrcodeApi.setPandaFanGroupQrcodeOption(params, () => {
      this.$message.success('提交成功')
      this.getTable()

      this.dialogFormVisible = false
    }, () => {})

    // const form = this.form || {}

    // const userAvatarInfo = form[mode] || {}

    // Object.assign(userAvatarInfo, {
    //   name,
    //   image_id,
    //   image_url
    // })

    // this.$set(this.form, mode, userAvatarInfo)

    // console.log(this.form, 'this.form')

    // this.dialogFormVisible = false
  }

  private curUploadQrcodeKey: string = 'qrcode1'

  // 上传媒体文件
  private handleUploadQRCode1(file: any, fileList: any[]) {
    this.curUploadQrcodeKey = 'qrcode1'

    this.handleUploadUserPhoto(file, fileList)
  }

  private handleUploadQRCode2(file: any, fileList: any[]) {
    this.curUploadQrcodeKey = 'qrcode2'

    this.handleUploadUserPhoto(file, fileList)
  }

  private handleUploadCoachQRCode(file: any, fileList: any[]) {
    this.curUploadQrcodeKey = 'coachQrcode'

    this.handleUploadUserPhoto(file, fileList)
  }

  private handleUploadUserPhoto(file: any, fileList: any[]) {
    this.handleUploadUserMedia(file, fileList, "image");
  }

  private async handleUploadUserMedia(
    file: any,
    fileList: any[],
    mode: string
  ) {
    console.log(file, "file");

    const qrcodeField = this.curUploadQrcodeKey

    function getVideoDuration(url: string): any {
      return new Promise((resolve, reject) => {
        var audio = new Audio(url);
        audio.muted = true;
        audio.play().then(() => audio.pause());
        // 元数据已加载

        const handleLoadedmetadata = function (e: any) {
          // let duration = audio.duration;
          console.log(e, "e audio");
          resolve({
            url,
            duration: audio.duration,
          });

          hanleClearListeners();
        };

        const hanleClearListeners = function () {
          audio.removeEventListener("loadedmetadata", handleLoadedmetadata);
          audio.onerror = null;
        };
        audio.addEventListener("loadedmetadata", handleLoadedmetadata);

        audio.onerror = function (err) {
          reject(err);
        };

        audio.muted = false;
      });
    }

    const editForm: any = this.forms;

    const optionList = mode == "image" ? editForm[qrcodeField] : editForm.videos;

    const sendMediaApi =
      mode == "image" ? this.commonApi.getOss : this.commonApi.getOssvideo;

    if (mode == "video" && !file.duration) {
      const { duration } = await getVideoDuration(file.url).catch(
        (err: any) => {
          this.$message("获取时长失败");
        }
      );

      file.duration = duration;
    }

    optionList.push(file);

    console.log(editForm, "editForm");

    const key = mode == "image" ? qrcodeField : `${mode}s`;

    this.$set(this.forms, key, optionList);

    this.changeFilePropByList(file, mode, { uploading: true });
    sendMediaApi.call(
      this.commonApi,
      file instanceof File ? file : file.raw,
      (res: any) => {
        console.log(res, "res 图片上传成功");

        const { data } = res;

        const { data: uploadFileObj } = data || {};

        const { item: uploadFile } = uploadFileObj || {};

        const { id, url } = uploadFile;

        // this.$set(this.forms, 'avatar_id', id)
        // this.$set(this.forms, 'avatar', url)
        const changeProps: any = {
          uploading: false,
          fileId: id,
          url
        };

        if (file.duration) {
          changeProps.duration = file.duration.toString();
        }

        this.changeFilePropByList(file, mode, changeProps);
      },
      () => {
        this.$message.error("图片上传失败");

        this.changeFilePropByList(file, mode, {
          uploading: false,
          failed: true,
        });
      }
    );
  }

  private changeFilePropByList(file: any, mode: string, changes: any = {}) {
    const editForm: any = this.forms;

    const qrcodeField = this.curUploadQrcodeKey

    const setList: any[] = mode == "image" ? editForm[qrcodeField] : editForm.videos;
    const existFileIndex = setList.findIndex(
      (each_file: any) => each_file.uid == file.uid
    );

    if (existFileIndex > -1) {
      const originFile = setList[existFileIndex];

      if (Array.isArray(setList)) {
        setList.splice(existFileIndex, 1, Object.assign(originFile, changes));
      }

      const key = mode == "image" ? qrcodeField : `${mode}s`;

      this.$set(this.forms, key, setList);
    }
  }

  // 删除媒体文件

  private handleFileRemoveQrcode1(file: any, mode: string) {
    this.curUploadQrcodeKey = 'qrcode1'

    this.handleFileRemove(file, mode)
  }

  private handleFileRemoveQrcode2(file: any, mode: string) {
    this.curUploadQrcodeKey = 'qrcode2'

    this.handleFileRemove(file, mode)
  }

  private handleFileRemoveCoachQrcode(file: any, mode: string) {
    this.curUploadQrcodeKey = 'coachQrcode'

    this.handleFileRemove(file, mode)
  }

  private handleFileRemove(file: any, mode: string) {
    const forms = this.forms;

    const qrcodeField = this.curUploadQrcodeKey

    const images = mode == "image" ? forms[qrcodeField] : [];

    const videos = mode == "video" ? forms.videos : [];

    console.log(file, "file");

    const optionList = images.length > 0 ? images : videos;

    const existRemoveFileIndex = optionList.findIndex(
      (each_file: any) => each_file.uid == file.uid
    );

    if (existRemoveFileIndex > -1) {
      optionList.splice(existRemoveFileIndex, 1);

      const key = mode == "image" ? qrcodeField : `${mode}s`;

      this.$set(this.forms, key, optionList);
    }
  }

  // 预览媒体文件
  private previewImages: any[] = [];

  private handlePictureCardPreviewQrcode1(file: any, mode: string) {
    this.curUploadQrcodeKey = 'qrcode1'

    this.handlePictureCardPreview(file, mode)
  }

  private handlePictureCardPreviewQrcode2(file: any, mode: string) {
    this.curUploadQrcodeKey = 'qrcode2'

    this.handlePictureCardPreview(file, mode)
  }

  private handlePictureCardPreviewCoachQrcode(file: any, mode: string) {
    this.curUploadQrcodeKey = 'coachQrcode'

    this.handlePictureCardPreview(file, mode)
  }

  private handlePictureCardPreview(file: any, mode: string) {
    const forms = this.forms;

    const qrcodeField = this.curUploadQrcodeKey

    const images = mode == "image" ? forms[qrcodeField] : [];

    const videos = mode == "video" ? forms.videos : [];

    console.log(file, "file");

    const urls = [
      ...images.map((img: any) => {
        return {
          title: "",
          type: "image/jpeg",
          href: img.url,
          thumbnail: img,
        };
      }),
      ...videos.map((video: any) => {
        return {
          title: "",
          type: "video/mp4",
          href: video.url,
          poster: `${video.url}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`,
        };
      }),
    ];

    this.previewImages = urls;
  }

  private onWakeEditPandaFanGroupQrcode() {
    const qrcodeInfo = this.qrcodeInfo

    const {
      image_arr,
      coach_img_id,
      coach_img_url
    } = qrcodeInfo || {}

    const [
      firstQrcode = null,
      secondQrcode = null
    ] = image_arr || []


    const forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
      // name,
      // image_id,
      // image_url
    }

    if (firstQrcode) {
      forms.qrcode1 = [
        firstQrcode
      ].map((item) => {
        const {
          id,
          // url
        } = item || {}
        const newItem = {
          ...item,
          fileId: id
        }

        return newItem
      })
    }

    if (secondQrcode) {
      forms.qrcode2 = [
        secondQrcode
      ].map((item) => {
        const {
          id,
          // url
        } = item || {}
        const newItem = {
          ...item,
          fileId: id
        }

        return newItem
      })
    }

    if (coach_img_url) {
      forms.coachQrcode = [
        {
          id: coach_img_id,
          url: coach_img_url
        }
      ].map((item) => {
        const {
          id,
          // url
        } = item || {}
        const newItem = {
          ...item,
          fileId: id
        }

        return newItem
      })
    }

    this.forms = forms

    this.dialogFormVisible = true
  }


  // private curSingleCardInfo: any = {}

  // private singleCardInfoLoading: boolean = false


  // 后台返回的字段 转化为前端定义的字段
  // private handleTableData(data: any): any {
  //   // const { gender } = data;
  //   const newData = {
  //     ...data,
  //     // device_name: orderDeviceType2ch[device],
  //     // pay_type_name: payType2ch[pay_type],
  //     // user_level_type_name: userLevel2ch[user_type],
  //     // gender_sex: transfromGenders[gender]
  //   };

  //   return newData;
  // }


  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };

    this.getTable();
  }

  private search(): void {
    // this.tablePage.pageNo = 1;
    // this.tableData = [];
    // this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private handlePageChange(val: any) {
    // this.tablePage.pageNo = val.currentPage;
    // this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}

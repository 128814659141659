import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class MemberChargeApi extends BasicsClass {
    public apiUrls: any = new Map([
        ['getMemberOpenList', {
            url: '/admin/member/adminRecord',
            name: 'list',
            label: '列表'
        }],
        ['getMemberTypeList', {
            url: '/admin/member/config',
            name: 'member-config',
            label: '会员配置',
            // hidden: true,
        }],
        ['memberOpenApply', {
            url: '/admin/member/openMember',
            name: 'open-member',
            label: '开通会员'
        }],
        ['memberChargeApply', {
            url: '/admin/member/rechargeMember',
            name: 'charge-member',
            label: '充值会员'
        }],
        ['exportMemberOpenList', {
            url: '/admin/member/exportAdminRecord',
            name: 'export-excel',
            label: '导出'
        }]
    ])

    // 会员管理列表 后台充值列表
    public getMemberOpenList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getMemberOpenList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // // 会员配置
    public getMemberTypeList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getMemberTypeList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }


    // // 导出会员管理列表 后台充值列表
    public exportMemberOpenList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('exportMemberOpenList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 开通会员
    public memberOpenApply(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('memberOpenApply').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 会员充值
    public memberChargeApply(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('memberChargeApply').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

}

import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
// import { screen } from "@/common/screen/screen";
import GoodsRefundAuditApi from "@/common/api/auditManage/goodsRefundAudit";
import { CommonApi } from "@/common/api/common";
import { excuteDownloadExport2Excel } from "@/common/xlsx";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import { utils } from "xlsx";

import gallery from "@/components/gallery.vue";

import {
  shareConfirmOptions
} from '@/common/config'


export const pageApiList = getPageAPIs(GoodsRefundAuditApi)


const transformGender = ["", "女", "男"];

const goodsRefundAudit2Text: any = ["", "未审核", "已撤销", "已通过", "不通过"];


const defaultForm: any = {
  order_no: '',
  phone: '',
  is_status: 1, // 状态 1未审核 2已撤销 3已通过 4不通过
  time: []
}

const getImgUrlArray = (img_url: string): string[] => {
  return img_url.split(',') || []
}

@Component({
  components: {
    gallery,
  },
  filters: {
    getGoodsImgUrls(back_goods_detail: any[]) {
      if (!Array.isArray(back_goods_detail)) return []

      return back_goods_detail.map(({ img_url }: { img_url: string }) => img_url)
    },
    getGoodsRefundFirstUrl(img_url: string) {
      if (!img_url) return []

      return getImgUrlArray(img_url)[0] || ''
    },
    getGoodsRefundImgUrls(img_url: string) {
      if (!img_url) return []

      return getImgUrlArray(img_url)
    },
    getGoodsRefundImgUrlLength(img_url: string) {
      if (!img_url) return 0

      return getImgUrlArray(img_url).length
    },
    getGenderText(gender: number): string {
      return transformGender[gender] || "未知";
    },
    // feedBackTypeText(type: number): string {
    //   if (typeof type != 'number') return ''

    //   return TypeText[type]
    // },
    refundAuditStatusText(statusNum: any): any {
      return goodsRefundAudit2Text[statusNum] || "未知状态";
    },
  },
})
export default class GoodsRefundAudit extends Vue {
  private form: any = {
    // // type:  0, // 问题类型
    // user_id: "", // 用户ID
    // nickname: "", // 助教昵称
    // is_status: 1, // 状态
    // time: [], // 时段选择
  };
  private deepLoopUtil: any;

  constructor() {
    super()

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm, false),
    }
  }
  private goodsRefundAuditApi: any = new GoodsRefundAuditApi();
  private created(): void {
    // this.getProblemTypes();
    this.search();
  }
  private getTable(): void {
    this.goodsRefundAuditApi.getList(
      {
        ...this.getGoodsRefundAuditParams()
      },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.pageNo = res.data.page || this.tablePage.pageNo;
        this.tablePage.pageSize = res.data.prepage || this.tablePage.pageSize;
        this.tablePage.totalCount = res.data.count;
      }
    );
  }

  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }
  // private problemList: any[] = [
  //   ...problemList
  // ];

  private statusList: any[] = [
    {
      label: "未审核",
      value: 1,
    },
    {
      label: "已撤销",
      value: 2,
    },
    {
      label: "已通过",
      value: 3,
    },
    {
      label: '不通过',
      value: 4
    }
  ];

  private systemTypaName: any[] = ["未知", "安卓", "IOS", "小程序"];

  // 更新状态
  private handleUpdateGoodsRefundAuditStatus(row: any, mode: string) {
    const modeMsgs: any = {
      agree: "通过",
      disagree: "不通过",
    };
    this.$confirm(`确定${modeMsgs[mode]}该项申请?`, "", {
      ...shareConfirmOptions,
    }).then(() => {
      const tableData = this.tableData;

      const existRowIndex = tableData.indexOf(row);

      if (existRowIndex > -1) {
        row.__updateFeedbackStatusLoading = true;

        tableData.splice(existRowIndex, 1, row);

        this.goodsRefundAuditApi.updateGoodsRefundAuditStatus(
          {
            shop_id: row.shop_id,
            back_id: row.id,
            mode,
            // status: 1
          },
          () => {
            row.__updateFeedbackStatusLoading = false;

            tableData.splice(existRowIndex, 1, row);

            this.getTable();
          },
          () => {
            row.__updateFeedbackStatusLoading = false;

            tableData.splice(existRowIndex, 1, row);
          }
        );
      }
    });
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

 
  private images: any = [];
 

  private getGoodsRefundAuditParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;


    const {
      order_no,
      phone,
      is_status,
      time, // 
      // finish_time, // 完成时间
    } = form || {};


    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};

    if (order_no) {
      outerParams.order_no = order_no;
    }

    if (phone) {
      outerParams.phone = phone;
    }

    if (typeof is_status == 'number' && is_status !== -1) {
      outerParams.status = is_status
    }

    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      outerParams.start_date = startTime;

      outerParams.end_date = endTime;
    }

    if (
      mode == "exportExcel" &&
      !(outerParams.start_date && outerParams.end_date)
    ) {
      this.$message.error("请选择日期导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      // outerParams.__isUnique = !firstLoading;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 字段名 文案对应关系
  private merchantExcelList2ch: any = {
    order_no: "订单编号",
    phone: "用户手机号",
    shop_name: "店铺名",
    __back_good_details: "商品信息",
    price: "退款金额",
    reason: "退款原因",
    req_time: "申请时间",
    status: "状态",
    audit_time: "审核时间",
    audit: "审核人"
  };
  // 商户Excel 表头的先后顺序
  private merchantExcelSortList: string[] = [
    "order_no",
    "phone",
    "shop_name",
    "__back_good_details",
    "price",
    "reason",
    "req_time",
    "status",
    "audit_time",
    "audit"
  ];

  // 点击导出excel 表格
  private handleExportExcelChannelList() {
    const params = this.getGoodsRefundAuditParams('exportExcel')

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.goodsRefundAuditApi.exportExcelRefundAuditList(
      params,
      (res: any) => {
        const { start_date, end_date } = params || {};

        const filename = `商品退款审核列表 ${start_date}到${end_date}.xlsx`;
        const { data: originList } = res || {};

        // const {
        //   list: originList
        // } = data || {}

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const { 
                status,
                back_goods_detail
               } = item || {};
              const newItem = {
                ...(item || {}),
                __status_text: goodsRefundAudit2Text[status],
                __back_good_details: back_goods_detail.map((goods: any, index: number) => `${ goods.name }x${ goods.num }  ¥${ goods.price }`).join('; \t')
              };

              for (let key in item) {
                if (typeof item[key] == 'number') {
                  newItem[key] = item[key].toString()
                }
              }

             
              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const merchantExcelSortList = this.merchantExcelSortList;

          const merchantExcelList2ch = this.merchantExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            merchantExcelSortList,
            merchantExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */
}

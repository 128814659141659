import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class PandaCoinRecordAPIClass extends BasicsClass {

    public apiUrls: any = new Map([
        ['getPandaCoinRecordTableList', {
            url: '/admin/currency/rechargeList',
            name: 'list',
            label: '熊猫币充值记录'
        }],
        ['pandaCoinRefund', {
            url: '/admin/currency/refund',
            name: 'refund',
            label: '熊猫币退款'
        }],
        ['export', {
            url: '/admin/currency/exportRechargeList',
            name: 'export',
            label: '导出'
        }],

    ])
    // 熊猫币充值记录
    public getPandaCoinRecordTableList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {

        params = {
            ...(params || {})
        }

        const apiUrls = this.apiUrls

        const url = apiUrls.get('getPandaCoinRecordTableList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 熊猫币退款
    public pandaCoinRefund(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('pandaCoinRefund').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    // 导出熊猫币充值记录
    public exportRechargeList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('export').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
// import { CouponsManageAPI } from "@/common/api/couponList";
import RecommanderManageAPI from '@/common/api/operationManage/recommanderManage';
 
import { excuteDownloadExport2Excel } from "@/common/xlsx";

import { DeepLoop } from "@/common/deepLoop/deepLoop";

import {
  shareConfirmOptions
} from '@/common/config'

export const pageApiList = getPageAPIs(RecommanderManageAPI)



// const defaultSearchVenuePageOption = {
//   page: 1,
//   limit: 10,
//   isRearchBottom: false,
// };

// const gloryShopQRChargeDialogTitle2ch: any = {
//   "new-sale-machine": "新增售卖机",
//   "edit-sale-machine": "编辑售卖机",
// };

// 最大有效期
// const maxExpiredDays = 9999;

// 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;
// 状态：1提交订单 2已付款 3已取消 4已退款 5退款中
const orderStatus2Ch = [
  '',
  '提交订单',
  '已付款',
  '已取消',
  '已退款',
  '退款中'
]

const maxBenefitRate = 100

@Component({
  components: {},
  filters: {
    getOrderStatusText(status: number) {
      return orderStatus2Ch[status] || ''
    }
  }
})
export default class RecommanderManageChargesManage extends Vue {
  private form: any = {
    mobile: '',
    name: ''
    
  };

  

  private deepLoopUtil: any = {};


  private recommanderManageApi = new RecommanderManageAPI();

  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

  }

  private created(): void {
    this.getRecommanderManageList();
  }
  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getRecommanderManageList();
  }
  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getRecommanderManageList();
  }

  private tableData: any[] = [];

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";
  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getRecommanderManageList();
  }
  
  //获取推广人列表
  private getRecommanderManageList(): void {
    const params: any = this.getRecommanderManageParams()
    this.recommanderManageApi.getRecommanderList(
      { ...params },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page || params.page;
        this.tablePage.prepage = res.data.prepage || params.limit;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private getRecommanderManageParams(): any {
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    const { name, mobile } = form;

    if (name) {
      params.name = name;
    }

    if (mobile) {
      params.mobile = mobile
    }

    if (page) {
      params.page = page;
    }

    if (prepage) {
      params.limit = prepage;
    }

    return params;
  }

  // 查看详情
  handleGoRecommandDetail(recommandItem: any) {
    console.log(recommandItem, 'recommandItem')

    this.$router.push({
      path: '/recommanderDetailList',
      query: {
        shop_id: recommandItem.shop_id,
        user_id: recommandItem.user_id
      }
    })
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 用户字段名 文案对应关系
  private userExcelList2ch: any = {
    nickname: "用户昵称",
    phone: "手机号码",
    name: "店铺",
    total_gmv: "总成交额（元）",
    income: "总收益",
    money: "账户可提现余额（元）",
    not_settlement: "未结算金额（元）",
    shop_member_scan_num: "单店卡扫码",
    shop_member_deal_num: "单店卡成交量",
    shop_member_gmv: "单店卡成交金额（元）",
    coupon_scan_num: "畅打券扫码",
    coupon_deal_num: "畅打券成交量",
    coupon_gmv: "畅打券成交金额（元）"
  };
  // 用户Excel 表头的先后顺序
  private userExcelSortList: string[] = [
    "nickname",
    "phone",
    "name",
    "total_gmv",
    "income",
    "money",
    "not_settlement",
    "shop_member_scan_num",
    "shop_member_deal_num",
    "shop_member_gmv",
    "coupon_scan_num",
    "coupon_deal_num",
    "coupon_gmv"
  ];

  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getRecommanderManageParams();

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.recommanderManageApi.exportExcelRecommanderTableList(
      params,
      (res: any) => {
        // const { start_date, end_date } = params || {};

        const filename = `推广人记录列表.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const {  } = item;
              const newItem = {
                ...(item || {}),
              };


              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const userExcelSortList = this.userExcelSortList;

          const userExcelList2ch = this.userExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            userExcelSortList,
            userExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */
}

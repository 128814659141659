
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import PandaMobileTable from "@/components/mobile-custom/panda-table.vue";

import PandaMobileTWFContainer from "@/components/mobile-custom/panda-table-with-filter-container.vue";

import { AxiosData } from "@/common/http/http";
import { Util, getPageAPIs } from "@/common/util";
import { AirConfigSetAPI } from "@/common/api/merchantManage/airConfigSet";
export const pageApiList = getPageAPIs(AirConfigSetAPI);

@Component({
  components: {
    PandaMobileTable,
    PandaMobileTWFContainer,
  },
})
export default class MerchantsAudit extends Vue {
  /** 移动端相关属性 方法 */
  private allMobileChooseFields: any[] = [
    {
      label: '空调名称',
      field: 'air_name'
    },
    {
      label: '球桌名称',
      field: 'pool_name'
    }
  ];
  /** end 移动端相关属性 方法 */
  private merchantApi: any;

  constructor() {
    super();
    this.merchantApi = new AirConfigSetAPI();
  }
  private dialogFormVisible: boolean = false;

  private form: any = {
    shop_id: "",
  };
  private title: string = "";
  private forms: any = {
    shop_id: "",
  };

  private dList: any = [];
  private rList: any = [];
  private device(): void {
    this.merchantApi.getRoomAndPoolList(
      {
        ...this.form,
        page: 1,
        limit: 200,
      },
      (res: any) => {
        if (res.data.list) {
          this.dList = res.data.list;
        } else {
          this.dList = [];
        }
      }
    );
    this.merchantApi.shopAir(
      {
        ...this.form,
        page: 1,
        limit: 200,
      },
      (res: any) => {
        this.rList = res.data.list;
      }
    );
  }
  private onclose(): void {
    this.forms = new Util().clearObject(this.forms);
    this.dialogFormVisible = false;
  }
  private submit(): void {
    this.forms.shop_id = this.$route.query.id;
    if (this.title == "新增") {
      this.merchantApi.shopAiraddBind(this.forms, (res: any) => {
        Util.showToast("新增成功");
        this.dialogFormVisible = false;
        this.forms = new Util().clearObject(this.forms);

        this.search();
      });
    } else {
      this.merchantApi.shopAireditBind(this.forms, (res: any) => {
        Util.showToast("修改成功");
        this.dialogFormVisible = false;
        this.forms = new Util().clearObject(this.forms);
        this.search();
      });
    }
  }
  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private activated(): void {
    if (this.$route.query.id) {
      this.form.shop_id = this.$route.query.id;
    }
    this.search();
    this.device();
  }

  private getTable(): void {
    this.merchantApi.shopAirpool(
      {
        ...this.form,
        page: this.tablePage.pageNo,
        limit: this.tablePage.pageSize,
      },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.totalCount = res.data.total;
      }
    );
  }

  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private view(row: any): void {
    this.merchantApi.shopAirbindShow(
      { shop_pool_air_id: row.id },
      (res: any) => {
        this.forms = res.data;
        this.device();
        this.dialogFormVisible = true;
      }
    );
  }

  private deviceDel(id: string): void {
    this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        this.merchantApi.shopAirdelBind(
          {
            shop_pool_air_id: id,
          },
          () => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.search();
          }
        );
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
  }
  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
  private deviceAdd(type: any): void {
    if (type == "add") {
      this.title = "新增";
      this.dialogFormVisible = true;
    } else {
      this.title = "编辑";
      this.view(type);
    }
  }
}

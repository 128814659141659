/** 注意： meta  key 一旦确定不可修改 且 同级唯一。 是作为识别这个对象的唯一标识！！！   */

// 卡券管理
export const couponManageRoutes = [
  // {
  //   path: '/couponTimeDuration',
  //   name: 'couponTimeDuration',
  //   meta: {
  //     title: "卡券时间段设置",
  //     key: 'coupon-time-duration',
  //     pageApisPrm: import("@/views/couponManage/couponTimeDuration.vue").then(({ pageApiList }: any) => pageApiList)
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "couponManage" */ "@/views/couponManage/couponTimeDuration.vue"
  //     ),
  // },

  {
    path: "/cashierCouponVertifyCodeRecord",
    name: "cashierCouponVertifyCodeRecord",
    meta: {
      title: "收银台团购券核销记录",
      // asideHidden: true, // 侧边栏不显示
      key: 'cashier-coupon-vertify-code-record',
      pageApisPrm: import("@/views/couponManage/cashierCouponVertifyCodeRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "couponManage" */ "@/views/couponManage/cashierCouponVertifyCodeRecord.vue"),
  },
  {
    path: "/couponVertifyCodeRecord",
    name: "couponVertifyCodeRecord",
    meta: {
      title: "团购券核销记录",
      // asideHidden: true, // 侧边栏不显示
      key: 'coupon-vertify-code-record',
      pageApisPrm: import("@/views/couponManage/couponVertifyCodeRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "couponManage" */ "@/views/couponManage/couponVertifyCodeRecord.vue"),
  },
  {
    path: "/couponList",
    name: "couponList",
    meta: {
      title: "美团卡券",
      key: 'coupon-list',
      pageApisPrm: import("@/views/couponManage/couponList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "couponManage" */ "@/views/couponManage/couponList.vue"),
  },
  {
    path: "/couponList_dy",
    name: "couponList_dy",
    meta: {
      title: "抖音卡券",
      // asideHidden: true, // 侧边栏不显示
      key: 'dy-coupon-list',
      pageApisPrm: import("@/views/couponManage/couponList_dy.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "couponManage" */ "@/views/couponManage/couponList_dy.vue"),
  },
  // couponList_ks
  {
    path: "/couponList_ks",
    name: "couponList_ks",
    meta: {
      title: "快手卡券",
      // asideHidden: true, // 侧边栏不显示
      key: 'ks-coupon-list',
      pageApisPrm: import("@/views/couponManage/couponList_ks.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "couponManage" */ "@/views/couponManage/couponList_ks.vue"),
  },
  // platformCouponGivenList
  {
    path: "/platformCouponGivenList",
    name: "platformCouponGivenList",
    meta: {
      title: "平台券赠送",
      // asideHidden: true, // 侧边栏不显示
      key: 'platofrm-coupon-given-list',
      pageApisPrm: import("@/views/couponManage/platformCouponGivenList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "couponManage" */ "@/views/couponManage/platformCouponGivenList.vue"),
  },
  {
    path: "/couponList_hour",
    name: "couponList_hour",
    meta: {
      title: "平台小时券",
      // asideHidden: true, // 侧边栏不显示
      key: 'hour-coupon-list',
      pageApisPrm: import("@/views/couponManage/couponList_hour.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "couponManage" */ "@/views/couponManage/couponList_hour.vue"),
  },
  {
    path: "/platform_coupons_record",
    name: "platform_coupons_record",
    meta: {
      title: "平台券使用记录",
      // asideHidden: true, // 侧边栏不显示
      key: 'platform-coupons-record',
      pageApisPrm: import("@/views/couponManage/platformCouponsRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "couponManage" */ "@/views/couponManage/platformCouponsRecord.vue"),
  },

]

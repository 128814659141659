
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import {
  showChatTimeFormat
} from '@/utils/index'

@Component({
  filters: {
    showTime(ts: number): string {
      return showChatTimeFormat(ts);
    },
  },
})
export default class PandaChatMsgItem extends Vue {
  // 消息类型 1 普通文字消息 2 图片消息 3 视频消息 4 消息模板 5 问题库消息 6 接入消息 7 退出消息 8 选择机器人选项回复

  /*
    type=1  content = {type:1，content:=kkk,"send_time":0000}

    type=2  content = {type:2，url:"https://wwwww","send_time":0000}

    type=3  content = {type:3，url:"https://wwwww","send_time":0000}

    type=4  content = {type:4，{"content":"如何设置美团团购","images":[],"video":[]},"send_time":0000}

    type=5  content = {type:5，"before_content":"以下内容是否能帮助到您？您可以点击或者回复数字选择：","after_content":"您也可以参照以上描述说说您的问题哦！",list:[{"id":1,"title":"如何设置美团团购"},{"id":2,"title":"如何设置抖音团购"}],"send_time":0000}

    type=6  content = {type:6，aid:1,nickname:"客服小七","send_time":0000}

    type=7  content = {type:7，over_id:1,over_user_type:1,"send_time":0000} 

    type=8 机器人设置id
    type=8  content = {type:8，id=1,"send_time":0000,content="如何设置美团团购"}
  */
  @Prop({
    type: Number,
    default: 0, //
  })
  private type: any;

  @Prop({
    type: String,
    default: "",
  })
  private avatar: any;

  @Prop({
    type: Object,
    default: null,
  })
  private prevContent: any;

  @Prop({
    type: Object,
    default: (): any => {},
  })
  private content: any;

  @Prop({
    type: Object,
    default: (): any => {},
  })
  private chat: any;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isSelf: any;

  // @Prop({
  //   type: String,
  //   default: '你好，测试'
  // })
  // private nextMsg: any;

  private get isTimeTip() {
    const type = this.type;

    return type === 6;
  }

  private get isEndTip() {
    const type = this.type

    return type == 7
  }

  private get isMsgTalk() {
    const type = this.type;

    const allowTypes: number[] = [1, 2, 3, 4, 5, 8];

    return allowTypes.includes(type);
  }

  private get showGapTime() {
    const prevContent = this.prevContent;

    if (!prevContent) return "";

    const { type, send_time: prev_send_ts } = prevContent || {};

    const allowTextTypes: number[] = [1, 2, 3, 4, 6, 5, 8];

    // 上一条记录 非发送信息 （在某些特定消息下一个 才显示时间）
    if (!allowTextTypes.includes(type)) return;

    const content = this.content;

    const { send_time: cur_send_ts } = content || {};

    const limit_gap_ts = 5 * 60; // 5分钟 单位秒

    const diff_ts = cur_send_ts - prev_send_ts;

    if (diff_ts >= limit_gap_ts) {
      return cur_send_ts;
    }
  }

  private handlePreviewMedia(url: string, mode: string) {
    this.$emit("preview-media", {
      url,
      mode,
    });
  }
}

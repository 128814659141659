
import {Component, Vue} from "vue-property-decorator";
import {Util, getPageAPIs} from "@/common/util";
import OperationsPersonnelAPI from "@/common/api/operationManage/operationsPersonnel";
import {shareConfirmOptions} from '@/common/config';
interface InterfaceAddForms  {
  user_name: string,
  department_id: string | null,
  id?:string
}
const default_add_forms:InterfaceAddForms = {
  user_name: '',
  department_id: null,
}
export const pageApiList = getPageAPIs(OperationsPersonnelAPI)

@Component({})
export default class operationsPersonnel extends Vue {
  private OperationsPersonnelAPI: any;
  private title: string = "";
  private is_show: boolean = false;
  private tableData: any[] = [];
  private operateType:string = 'add'
  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };
  private departmentList:any = []
  private add_forms: any = {
   ...default_add_forms
  };
  constructor() {
    super();
    this.OperationsPersonnelAPI = new OperationsPersonnelAPI();
  }

  private addDialog(type:string='add',row:any): void {
    this.operateType = type
    this.is_show = true;
    this.$nextTick(()=>{
      if(this.operateType==='edit'){
        this.add_forms = {
          user_name: row.user_name,
          department_id: row.department_id,
          id:row.id
        }
      }else{
        this.add_forms = {
          ...default_add_forms
        }
      }
    })


  }

  private submit(): void {
    console.log('this.add_forms', this.add_forms)
    if(this.operateType==='edit'){
      this.OperationsPersonnelAPI.edit(this.add_forms, () => {
        this.is_show = false;
        Util.showToast("编辑成功");
        this.search();
      });
    }else{
      this.OperationsPersonnelAPI.add(this.add_forms, () => {
        this.is_show = false;
        Util.showToast("新增成功");
        this.search();
      });
    }

  }

  private secure(row: any) {
    this.$confirm(`确认删除${row.user_name}吗？`, '', {
      ...(shareConfirmOptions || {})
    }).then(() => {
      this.OperationsPersonnelAPI.delete({id: row.id},
          () => {
            this.is_show = false;
            Util.showToast("删除成功");
            this.search();
          }
      );
    })
  }

  private activated(): void {
    this.search();
    this.getDepartmentList()
  }


  private getTable(): void {
    this.OperationsPersonnelAPI.getList(
        {
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
        }
    );
  }

  private getDepartmentList(): void {
    this.OperationsPersonnelAPI.getDepartmentList(
        {
          page: 1,
          limit: 200,
        },
        (res: any) => {
          this.departmentList = res.data.list;
        }
    );
  }


  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getTable();
  }

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;
    this.getTable();
  }

}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import DiamondRecordApi from "@/common/api/operationManage/diamondTakeRecord";
// import { CommonApi } from "@/common/api/common";

import { excuteDownloadExport2Excel } from "@/common/xlsx";

import {
  shareConfirmOptions
} from '@/common/config'

export const pageApiList = getPageAPIs(DiamondRecordApi)

@Component({})
export default class CommentManage extends Vue {
  private utilInstance: any;
  private diamondRecordApi: any;

  // private commonApi: any;

  constructor() {
    super();
    this.diamondRecordApi = new DiamondRecordApi();

    // this.commonApi = new CommonApi();

    this.utilInstance = new Util();

    // 封禁时间列表
    // this.all_forbidden_time_list = Util.getFoibiddenTimeList();
  }

  private firstLoading: boolean = true;

  // private memberTypeList: any[] = [];
  private form: any = {
   
    user_id: "", // 用户ID
    nickname: "", // 用户昵称
    time: [], // 时间选择
  };

  // private showtimecard: boolean = false;
  private activated(): void {
    this.getTable(true);

  }
  private getTable(noUnique = false): void {
    this.diamondRecordApi.getDiamondTakeRecordTableList(
      this.getDiamondTakeRecordTableListParams("all", noUnique),
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        const { list, count, page, prepage } = data || {};

        const newTableList = Array.isArray(list)
          ? list.map(this.handleTableData)
          : [];

        // totalCount: 0,
        // pageNo: 1,
        // pageSize: 10,
        this.$set(this.tablePage, "totalCount", count);
        this.$set(this.tablePage, "pageNo", page);
        this.$set(this.tablePage, "pageSize", prepage);


        this.tableData = newTableList;

        this.firstLoading = false;
      },
      () => {
        this.firstLoading = false;
      }
    );
  }

  private getDiamondTakeRecordTableListParams(mode = "all", noUnique = false) {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const firstLoading = this.firstLoading;

    const {
      user_id, // 用户ID
      nickname, // 用户昵称
      time // 时间
    } = form || {};

    // console.log(technicianItem, "technicianItem");

    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};

    if (user_id) {
      outerParams.user_id = user_id;
    }

    if (nickname) {
      outerParams.nickname = nickname
    }


    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      outerParams.start_date = startTime;

      outerParams.end_date = endTime;
    }

    if (
      mode == "exportExcel" &&
      !(outerParams.start_date && outerParams.end_date)
    ) {
      this.$message.error("请选择时段导出数据");
      return null;
    }


    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      outerParams.__isUnique = !firstLoading && !noUnique;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  // 后台返回的字段 转化为前端定义的字段
  private handleTableData(data: any): any {
    const {
     
    } = data;
    const newData = {
      ...data,
      
    };


    return newData;
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 用户字段名 文案对应关系
  private userExcelList2ch: any = {
    user_id: "用户ID",
    user_name: "用户昵称",
    // inviter: "邀请人",
    detail: "获取方式",
    icon: "钻石数量",
    created_at: "时间",
  };
  // 用户Excel 表头的先后顺序
  private userExcelSortList: string[] = [
    "user_id",
    "user_name",
    // "inviter",
    "detail",
    "icon",
    "created_at",
  ];

  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getDiamondTakeRecordTableListParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.diamondRecordApi.exportExcelDiamondTakeList(
      params,
      (res: any) => {
        const { start_date, end_date } = params || {};

        const filename = `钻石获取记录列表 ${start_date}到${end_date}.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const { inviteTotal } = item;
              const newItem = {
                ...(item || {}),
              };

              if (typeof inviteTotal == "number") {
                newItem.inviteTotal = inviteTotal.toString();
              }

              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const userExcelSortList = this.userExcelSortList;

          const userExcelList2ch = this.userExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            userExcelSortList,
            userExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        // const testData = [
        //   { 主页: "test1223424", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
        //   { 主页: "433", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
        //   { 名称: "22", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
        //   { 名称: "43", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
        //   { 店家: "43", 价格: "6800", 数量: "6800", 昵称: "广告主网", }
        // ]

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */

  private restForm(): void {
    this.form = this.utilInstance.clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}

import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class MerchantsListAPI extends BasicsClass {

    public apiUrls: any = new Map([
        ['getMerchantTableList', {
            url: '/admin/merchant/lists',
            name: 'list',
            label: '列表'
        }],
        ['exportExcelMerchantList', {
            url: '/admin/merchant/export',
            name: 'export-excel',
            label: '导出'
        }],
        ['getMerchantDetailInfo', {
            url: '/admin/merchant/view',
            name: 'info',
            label: '商户详情',
            // hidden: true
        }],
        ['updateMerchantDetailInfo', {
            url: '/admin/merchant/save',
            name: 'edit',
            label: '修改商户信息'
        }],
        ['addNewMerchantInfo', {
            url: '/admin/merchant/add',
            name: 'add-merchant',
            label: '添加商户'
        }],
        ['addStore', {
            url: '/admin/shop/add',
            name: 'add-shop',
            label: '添加店铺'
        }],
    ])
    // 商户列表
    public getMerchantTableList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const start_date = Util.handleStartTimeAndEndTime(params.time).startTime;
        const end_date = Util.handleStartTimeAndEndTime(params.time).endTime;

        params = {
            ...(params || {}),
            start_date: start_date,
            end_date: end_date
        }

        const apiUrls = this.apiUrls

        const url = apiUrls.get('getMerchantTableList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 商户详情
    public getMerchantDetailInfo(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getMerchantDetailInfo').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 导出商户列表
    public exportExcelMerchantList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('exportExcelMerchantList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    // 修改商户信息
    public updateMerchantDetailInfo(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('updateMerchantDetailInfo').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 添加商户
    public addNewMerchantInfo(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('addNewMerchantInfo').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 添加店铺
    public addStore(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('addStore').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}
export default function saveAs(url, fileName) {
    if  (!(typeof url != 'string' || url.length <= 0)) {
        var downFileDom = document.createElement("a");

        downFileDom.id = `download-${Date.parse(new Date())}`

        downFileDom.href = url

        downFileDom.download = fileName || ''

        downFileDom.style = `opacity: 0;height: 1px;width: 1px;overflow: hidden;position:fixed;top: -1;left: -1;z-index: -1;`

        const doc = document.body || document.documentElement

        doc.appendChild(downFileDom)

        downFileDom.click()

        doc.removeChild(downFileDom)
    }
}

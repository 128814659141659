
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import PandaMobileTable from "@/components/mobile-custom/panda-table.vue";

import PandaMobileTWFContainer from "@/components/mobile-custom/panda-table-with-filter-container.vue";

import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
// import { screen } from "@/common/screen/screen";
import MerchantApplyCompetitionAPI from "@/common/api/competitionManage/merchantApplyCompetition";
import { CommonApi } from "@/common/api/common";
import { excuteDownloadExport2Excel } from "@/common/xlsx";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import { utils } from "xlsx";

import gallery from "@/components/gallery.vue";

import {
  brandType2Ch,
  shareConfirmOptions,
  leagueLevelName2Text
} from '@/common/config'


export const pageApiList = getPageAPIs(MerchantApplyCompetitionAPI)


const merchantApplyCompetition2Text: any = ["", "待审核", "已通过", "已拒绝"];


const defaultForm: any = {
  phone: '',
  shop_name: '',
  audit_status: 1, // 审核状态：1待审核 2已通过 3已拒绝
  time: []
}

const getImgUrlArray = (img_url: string): string[] => {
  return img_url.split(',') || []
}

@Component({
  components: {
    gallery,
    PandaMobileTable,
    PandaMobileTWFContainer
  },
  filters: {
    getLeagueLevelName(level: number) {
      return leagueLevelName2Text[level] || ''
    },
    refundAuditStatusText(statusNum: any): any {
      return merchantApplyCompetition2Text[statusNum] || "未知状态";
    },

    showBrandTypeName(brand_type: number) {
      return brandType2Ch[brand_type] || '未知'
    }
  },
})
export default class MerchantApplyCompetition extends Vue {
  /** 移动端相关属性 方法 */
  private allMobileChooseFields: any[] = [
    {
      label: "注册手机号",
      field: "mobile",
    },
    {
      label: "店铺",
      field: "shop_name",
    },
    {
      label: "联系手机号",
      field: "phone",
    },
    {
      label: '类型',
      field: 'table-brand-type',
    },
    {
      label: "赛事等级",
      field: "table-league-level",
    },
    {
      label: "提交时间",
      field: "created_at",
    },
    {
      label: "状态",
      field: "table-audit-status",
    },
    {
      label: '审核时间',
      field: 'audit_time'
    },
    {
      label: "审核人",
      field: "admin_name",
    },
    
  ];
  /** end 移动端相关属性 方法 */
  private form: any = {
    // // type:  0, // 问题类型
    // user_id: "", // 用户ID
    // nickname: "", // 助教昵称
    // is_status: 1, // 状态
    // time: [], // 时段选择
  };
  private deepLoopUtil: any;

  constructor() {
    super()

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm, false),
    }
  }
  private merchantApplyCompetitionApi: any = new MerchantApplyCompetitionAPI();
  private created(): void {
    // this.getProblemTypes();
    this.search();
  }
  private getTable(): void {
    this.merchantApplyCompetitionApi.getList(
      {
        ...this.getMerchantApplyCompetitionParams()
      },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.pageNo = res.data.page || this.tablePage.pageNo;
        this.tablePage.pageSize = res.data.prepage || this.tablePage.pageSize;
        this.tablePage.totalCount = res.data.count;
      }
    );
  }

  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }
  // private problemList: any[] = [
  //   ...problemList
  // ];

  private statusList: any[] = [
    {
      label: "待审核",
      value: 1,
    },
    {
      label: "已通过",
      value: 2,
    },
    {
      label: "已拒绝",
      value: 3,
    },
  ];


  // 更新状态
  private handleUpdateMerchantApplyCompetitionStatus(row: any, mode: string) {
    const modeMsgs: any = {
      agree: "通过",
      disagree: "不通过",
    };
    this.$confirm(`确定${modeMsgs[mode]}该项申请?`, "", {
      ...shareConfirmOptions,
    }).then(() => {
      const tableData = this.tableData;

      const existRowIndex = tableData.indexOf(row);

      if (existRowIndex > -1) {
        row.__updateFeedbackStatusLoading = true;

        tableData.splice(existRowIndex, 1, row);

        this.merchantApplyCompetitionApi.updateMerchantApplyCompetitionStatus(
          {
            id: row.id,
            mode,
            // status: 1
          },
          () => {
            row.__updateFeedbackStatusLoading = false;

            tableData.splice(existRowIndex, 1, row);

            this.getTable();
          },
          () => {
            row.__updateFeedbackStatusLoading = false;

            tableData.splice(existRowIndex, 1, row);
          }
        );
      }
    });
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

 
  private images: any = [];
 

  private getMerchantApplyCompetitionParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;


    const {
      phone,
      shop_name,
      audit_status,
      time, // 
      // finish_time, // 完成时间
    } = form || {};


    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};

    if (shop_name) {
      outerParams.shop_name = shop_name;
    }

    if (phone) {
      outerParams.phone = phone;
    }

    if (typeof audit_status == 'number' && audit_status !== -1) {
      outerParams.audit_status = audit_status
    }

    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      outerParams.start_time = startTime;

      outerParams.end_time = endTime;
    }

    if (
      mode == "exportExcel" &&
      !(outerParams.start_time && outerParams.end_time)
    ) {
      this.$message.error("请选择日期导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      // outerParams.__isUnique = !firstLoading;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  /** 导出excel */
  // private downloadExcelLoading: boolean = false;

  // // 字段名 文案对应关系
  // private merchantExcelList2ch: any = {
  //   order_no: "订单编号",
  //   phone: "用户手机号",
  //   shop_name: "店铺名",
  //   __back_good_details: "商品信息",
  //   price: "退款金额",
  //   reason: "退款原因",
  //   req_time: "申请时间",
  //   status: "状态",
  //   audit_time: "审核时间",
  //   audit: "审核人"
  // };
  // // 商户Excel 表头的先后顺序
  // private merchantExcelSortList: string[] = [
  //   "order_no",
  //   "phone",
  //   "shop_name",
  //   "__back_good_details",
  //   "price",
  //   "reason",
  //   "req_time",
  //   "status",
  //   "audit_time",
  //   "audit"
  // ];

  // // 点击导出excel 表格
  // private handleExportExcelChannelList() {
  //   const params = this.getMerchantApplyCompetitionParams('exportExcel')

  //   if (params === null) {
  //     return false;
  //   }

  //   this.downloadExcelLoading = true;
  //   this.merchantApplyCompetitionApi.exportExcelRefundAuditList(
  //     params,
  //     (res: any) => {
  //       const { start_date, end_date } = params || {};

  //       const filename = `商品退款审核列表 ${start_date}到${end_date}.xlsx`;
  //       const { data: originList } = res || {};

  //       // const {
  //       //   list: originList
  //       // } = data || {}

  //       const newList = Array.isArray(originList)
  //         ? originList.map((item) => {
  //             const { 
  //               status,
  //               back_goods_detail
  //              } = item || {};
  //             const newItem = {
  //               ...(item || {}),
  //               __status_text: merchantApplyCompetition2Text[status],
  //               __back_good_details: back_goods_detail.map((goods: any, index: number) => `${ goods.name }x${ goods.num }  ¥${ goods.price }`).join('; \t')
  //             };

  //             for (let key in item) {
  //               if (typeof item[key] == 'number') {
  //                 newItem[key] = item[key].toString()
  //               }
  //             }

             
  //             return newItem;
  //           })
  //         : [];

  //       if (newList.length > 0) {
  //         const merchantExcelSortList = this.merchantExcelSortList;

  //         const merchantExcelList2ch = this.merchantExcelList2ch;

  //         const excelJSONData = getExcelJSONDataByList(
  //           newList,
  //           merchantExcelSortList,
  //           merchantExcelList2ch
  //         );

  //         excuteDownloadExport2Excel(excelJSONData, filename);
  //       } else {
  //         this.$message.info("选中时段没有可导出的信息");
  //       }

  //       this.downloadExcelLoading = false;
  //     },
  //     () => {
  //       this.downloadExcelLoading = false;
  //     }
  //   );
  // }

  /** end 导出excel */
}

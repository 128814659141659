
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import { ChessorderApi } from "@/common/api/userManage/chessOrderList";

import { excuteDownloadExport2Excel } from "@/common/xlsx";
export const pageApiList = getPageAPIs(ChessorderApi);

const defaultForm = {
  order_no: "", // 订单编号
  phone: "", // 用户手机号
  shop_name: "", // 店铺名称
 
  listSortType: -1, // 顾客订单状态

  create_time: [], // 创建时间

};


// 状态：1提交订单/待付款 2已付款 3已取消 4已开始/进行中 5已完成
const payStatus2ch: any = [
  "未知",
  "待付款",
  "已付款",
  "已取消",
  "进行中",
  "已完成",
];

// 支付方式：1微信小程序 2=会员支付,3单店卡支付 4卡券
const payType2ch: any = ["未知", "微信小程序", "全国会员", "单店会员", "卡券"];


@Component({
  filters: {
    getPayTypeName(pay_type: number) {
      return payType2ch[pay_type] || "";
    },
  },
})
export default class OrderList extends Vue {
  private chessorderApi: any;

  private deepLoopUtil: any;

  constructor() {
    super();
    this.chessorderApi = new ChessorderApi();

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
  }

  private form: any;

  private firstLoading: boolean = true;
  // private chooseTechnicianItem : any = null;

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  // private show: boolean = false;
  // 状态：1 提交订单 2 已付款 3 已取消 4 已开始 5 已完成 6 部分退款
  private sortTypeList: any[] = [
    {
      value: -1,
      label: "全部",
    },
    {
      label: "待付款",
      value: 1,
    },
    {
      label: "已付款",
      value: 2,
    },
    {
      label: "已取消",
      value: 3,
    },
    {
      label: "已开始",
      value: 4,
    },
    {
      label: "已完成",
      value: 5,
    },
    // {
    //   label: "申请退款成功",
    //   value: 7,
    // },
    // {
    //   label: "退款失败",
    //   value: 8,
    // },
    // {
    //   label: "申请退款",
    //   value: 9,
    // },
    // {
    //   label: "申请取消订单",
    //   value: 10,
    // },
  ];

  // 所有可选技师
  private technicianList: any[] = [];

  // private showtimecard: boolean = false;
  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    this.chessorderApi.getOrderTableList(
      this.getOrderTableListParams(),
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        const { list, count, page, prepage } = data || {};

        const newTableList = Array.isArray(list)
          ? list.map(this.handleTableData)
          : [];

        this.$set(this.tablePage, "totalCount", count);
        this.$set(this.tablePage, "pageNo", page);
        this.$set(this.tablePage, "pageSize", prepage);

        this.tableData = newTableList;

        this.firstLoading = false;
      },
      () => {
        this.firstLoading = false;
      },
      false
    );
  }


  private getOrderTableListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const firstLoading = this.firstLoading;

    const {
      order_no, // 订单编号
      phone, // 用户手机号
      shop_name, // 店铺名称

      listSortType, // 订单状态

      create_time, // 创建时间
    } = form || {};

    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};

    if (order_no) {
      outerParams.order_no = order_no;
    }

    if (shop_name) {
      outerParams.shop_name = shop_name;
    }

    if (phone) {
      outerParams.phone = phone;
    }

    if (listSortType != -1 && typeof listSortType == "number") {
      outerParams.status = listSortType;
    }

    if (Array.isArray(create_time) && create_time.length === 2) {
      const [startDate, endDate] = create_time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      outerParams.created_start_time = startTime;

      outerParams.created_end_time = endTime;
    }

    if (
      mode == "exportExcel" &&
      !(outerParams.created_start_time && outerParams.created_end_time)
    ) {
      this.$message.error("请选择创建时段导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      outerParams.__isUnique = !firstLoading;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }



  private getOrderSpendMoney(row: any) {
    const { price, refunded_price } = row;

    return (price - refunded_price).toFixed(2);
  }

  // 后台返回的字段 转化为前端定义的字段
  private handleTableData(data: any): any {
    const { status, pay_type, user_type } = data;
    const newData = {
      ...data,
      // device_name: orderDeviceType2ch[device],
      // pay_type_name: payType2ch[custom_pay_type],
      status_name: payStatus2ch[status],
      pay_type_name: payType2ch[pay_type],
      // user_level_type_name: userLevel2ch[user_type],
    };

    return newData;
  }

  

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 订单字段名 文案对应关系
  private orderExcelList2ch: any = {
    id: "订单ID",
    order_no: "订单编号",
    created_at: "创建时间",
    pay_time: "付款时间",
    cancel_time: "取消时间",
    over_time: "结束时间",
    reserve_start: "预约开始时间",
    reserve_end: "预约结束时间",
    user_name: "用户昵称",
    user_id: "用户ID",
    phone: "用户手机号",
    shop_id: "店铺ID",
    shop_name: "店铺名称",
    pool_name: "包厢名",
    unit_price: "包厢单价（元/时）",
    status_name: "订单状态",
    minute: "时长（分钟）",
    price: "费用",
    pay_type_name: "支付渠道",
    __renew_order_list_str: "续费",
    coupons_name: "卡券名称",
    fencheng_rate: "商家分成比例",
    income: "商家最终收益",
  };
  // 订单Excel 表头的先后顺序
  private orderExcelSortList: string[] = [
    "id",
    "order_no",
    "created_at",
    "pay_time",
    "cancel_time",
    "over_time",
    "reserve_start",
    "reserve_end",
    "user_name",
    "user_id",
    "phone",
    "shop_id",
    "shop_name",
    "pool_name",
    "unit_price",
    "status_name",
    "minute",
    "price",
    "pay_type_name",
    "__renew_order_list_str",
    "coupons_name",
    "fencheng_rate",
    "income",
  ];

  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getOrderTableListParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.chessorderApi.exportExcelOrderList(
      params,
      (res: any) => {
        const {
          created_start_time,
          created_end_time,
          // over_start_time,
          // over_end_time,
        } = params || {};

        const create_time_duration_name =
          created_start_time && created_end_time
            ? `创建时间${created_start_time}到${created_end_time}`
            : "";

        const filename = `订单列表 ${create_time_duration_name}.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const {
              
                pay_type,
                status,
                renew_list,
            
              } = item || {};

              const newItem = {
                ...(item || {}),
                status_name: payStatus2ch[status],
                pay_type_name: payType2ch[pay_type],
              };

              if (Array.isArray(renew_list) && renew_list.length) {
                newItem.__renew_order_list_str = renew_list.reduce(
                  (pre, cur) => {
                    const { minute, price, pay_type, coupon_name } = cur || {};
                    return (pre += `时长${minute}分钟、金额：￥${price}、支付类型：${
                      payType2ch[pay_type]
                    }${pay_type == 4 ? "、卡券名称：" + coupon_name : ""}\n`);
                  },
                  ""
                );
              }

              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const orderExcelSortList = this.orderExcelSortList;

          const orderExcelList2ch = this.orderExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            orderExcelSortList,
            orderExcelList2ch
          );

          // console.log(newList, "newList");

          // console.log(excelJSONData, "excelJSONData");

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }


        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */

  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
    // this.form = new Util().clearObject(this.form);
    // this.$router.replace("/orderList");
    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}

import { BasicsClass } from '@/common/BasicsClass';
export class SpringFActRewardPoolAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getSpringFActRewardPools', {
            url: '/admin/newYearActivity/activityRewardList',
            name: 'list',
            label: '列表'
        }],
        
        ['updateSpringFActRewardPools',  {
            url: '/admin/newYearActivity/activityRewardEdit',
            name: 'edit',
            label: '编辑'
        }],
        ['editActivityRewardExchangeCoupon', {
            url: '/admin/newYearActivity/activityRewardDefault',
            name: 'edit-exchange-coupon',
            label: '修改兑换券奖励'
        }],
        ['activityRewardExchangeCouponInfo', {
            url: '/admin/newYearActivity/activityRewardDefaultInfo',
            name: 'act-exchange-coupon-info',
            label: '兑换券奖励详情'
        }],
        ['couponsPlatformList', {
            url: '/admin/couponsPlatform/list',
            name: 'coupons-platform-list',
            label: '平台小时券列表'
        }]
    ])


   
    //修改奖池
    public updateSpringFActRewardPools(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('updateSpringFActRewardPools').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }


    //优惠券列表
    public getSpringFActRewardPools(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getSpringFActRewardPools').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    //默认奖励
    public setDefaultReward(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('editActivityRewardExchangeCoupon').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }


    //兑换券奖励详情
    public getExchangeCouponRewardInfo(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('activityRewardExchangeCouponInfo').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }


    //平台小时券
    public couponsPlatformList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('couponsPlatformList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }


}
import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

import { CommonApi } from "../common";

export class PandaFanGroupQrcodeAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getPandaFanGroupQrcodeOptionInfo', {
      url: '/admin/pandaFans/show',
      name: 'panda-fan-qrcode-info',
      label: '熊猫粉丝群二维码'
    }],

    // ['searchVenueList', {
    //   url: '/admin/coupons/shop/list',
    //   name: 'shop-list',
    //   label: '店铺列表',
    //   // hidden: true
    // }],

    ['setPandaFanGroupQrcodeOption', {
      url: '/admin/pandaFans/setInfo',
      name: 'panda-fan-qrcode',
      label: '二维码配置',
      // hidden: true
    }],
    
  ])
  // 熊猫粉丝群二维码
  public getPandaFanGroupQrcodeOptionInfo(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getPandaFanGroupQrcodeOptionInfo').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 搜索店铺列表
  // public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
  //   const apiUrls = this.apiUrls

  //   const url = apiUrls.get('searchVenueList').url

  //   this.BasicPost(url, {
  //       ...params
  //   }, false, false, false, successCb, failCb, showLoading)
  // }

  // 二维码配置
  public setPandaFanGroupQrcodeOption(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('setPandaFanGroupQrcodeOption').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import { AccountListAPI } from "@/common/api/marketManage/accountList";

// import ZlSelect from "@/components/third-custom-components/select";

// import ZlOption from "@/components/third-custom-components/option";

import { excuteDownloadExport2Excel } from "@/common/xlsx";
export const pageApiList = getPageAPIs(AccountListAPI);

import { shareConfirmOptions } from "@/common/config";

// const defaultForm = {
//   name: "", // 来源名称

//   // create_time: [], // 时间
// };

const defaultForms = {
  id: "",
  // venue_id: "",
  // venue: {
  //   id: "",
  //   name: "",
  // },
  // decr_price: '', // 扣款金额
  account: "", // 账号
  // sale_price: "", // 球桌销售单价
};

// const defaultSearchVenuePageOption = {
//   page: 1,
//   limit: 10,
//   isRearchBottom: false,
// };

// const transfromGenders: any = [
//   '',
//   '女',
//   '男'
// ]

@Component({
  filters: {
    // showUserMemberTypeName: function(type: number) {
    //   return transferUserLevel2ch[type] || ''
    // }
  },
  components: {
    // ZlSelect,
    // ZlOption,
  },
})
export default class MarketAccountList extends Vue {
  private accountListApi: any;

  private deepLoopUtil: any;

  // private utilIns: any;

  // private debounceRefreshSingleCardInfoFunc: any

  constructor() {
    super();
    this.accountListApi = new AccountListAPI();

    this.deepLoopUtil = new DeepLoop();

    // this.form = {
    //   ...this.deepLoopUtil.deepCopy(defaultForm),
    // };

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms),
    };

    // this.utilIns = new Util()

    // this.debounceRefreshSingleCardInfoFunc = this.utilIns.debounce.call(
    //   this,
    //   this.refreshSingleCardAccountInfo,
    //   800
    // )
  }

  private form: any;

  private forms: any;

  private firstLoading: boolean = true;
  // private chooseTechnicianItem : any = null;

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    this.accountListApi.getAccountList(
      this.getAccountListParams(),
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        const { list, count, page, prepage } = data || {};

        const newTableList = Array.isArray(list)
          ? list.map(this.handleTableData)
          : [];

        this.$set(this.tablePage, "totalCount", count);
        this.$set(this.tablePage, "pageNo", page);
        this.$set(this.tablePage, "pageSize", prepage);

        this.tableData = newTableList;

        this.firstLoading = false;
      },
      () => {
        this.firstLoading = false;
      },
      false
    );
  }

  private getAccountListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    // const form = this.form;

    const tablePage = this.tablePage;

    const firstLoading = this.firstLoading;

    // const {
    //   // shop_name,
    //   name,
    //   // create_time, // 消费时间
    //   // finish_time, // 完成时间
    // } = form || {};

    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};
    // if (shop_name) {
    //   outerParams.shop_name = shop_name;
    // }

    // if (name) {
    //   outerParams.name = name;
    // }

    // if (Array.isArray(create_time) && create_time.length === 2) {
    //   const [startDate, endDate] = create_time;

    //   const startTime = Util.dateTimeUtilnohms(startDate);
    //   const endTime = Util.dateTimeUtilnohms(endDate);

    //   outerParams.start_date = startTime;

    //   outerParams.end_date = endTime;
    // }

    if (
      mode == "exportExcel" &&
      !(outerParams.start_date && outerParams.end_date)
    ) {
      this.$message.error("请选择日期导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      outerParams.__isUnique = !firstLoading;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  private dialogFormVisible: boolean = false;

  private dialogTitle: string = "添加账号";

  private dialogMode: string = "add-account-list";

  private sumbitAddAccountListLoading: boolean = false;
  // // 唤起添加账号弹框
  private handleWakeAddAccountListDialog() {
    this.dialogMode = "add-account-list";

    this.dialogTitle = "添加账号";

    this.initAccountListForm();

    this.dialogFormVisible = true;
  }

  private handleWakeEditAccountListDialog(poolBrandItem: any) {
    this.dialogMode = "edit-account-list";

    this.dialogTitle = "编辑账号";

    this.initAccountListForm(poolBrandItem);

    this.dialogFormVisible = true;
  }

  private initAccountListForm(poolBrandItem: any = null) {
    const mode = this.dialogMode;

    const forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms),
    };

    if (mode == "edit-account-list") {
      const { id, account } = poolBrandItem || {};

      Object.assign(forms, {
        id,
        account,
        // sale_price,
      });
    }

    this.forms = forms;
  }

  private getAccountInfoParams() {
    const mode = this.dialogMode;

    const fomrs = this.forms;

    const { id, account } = fomrs || {};

    const params: any = {};

    if (!account) {
      this.$message.error("请输入账号");

      return false;
    }

    params.account = account;

    // if (!sale_price) {
    //   this.$message.error("请输入球桌销售单价");

    //   return false
    // }

    // params.sale_price = sale_price;

    if (mode == "edit-account-list") {
      params.id = id;
    }

    return params;
  }

  // // 提交 账号
  private handleSumbitChangeAccountList() {
    const params = this.getAccountInfoParams();

    if (typeof params != "object") return;

    const mode = this.dialogMode;

    this.sumbitAddAccountListLoading = true;

    switch (mode) {
      case "add-account-list":
        this.accountListApi.addAccountList(
          {
            ...params,
          },
          () => {
            this.$message.success("添加账号成功");

            this.sumbitAddAccountListLoading = false;
            this.dialogFormVisible = false;
            this.getTable();
          },
          () => {
            this.sumbitAddAccountListLoading = false;
          }
        );
        break;
      case "edit-account-list":
        this.accountListApi.editAccountList(
          {
            ...params,
          },
          () => {
            this.$message.success("修改账号成功");

            this.sumbitAddAccountListLoading = false;
            this.dialogFormVisible = false;
            this.getTable();
          },
          () => {
            this.sumbitAddAccountListLoading = false;
          }
        );
        break;
      default:
        this.sumbitAddAccountListLoading = false;
    }
  }

  // private curSingleCardInfo: any = {}

  // private singleCardInfoLoading: boolean = false

  // // 获取单店会员余额
  // private refreshSingleCardAccountInfo() {
  //   const forms = this.forms

  //   const {
  //     phone,
  //     venue
  //   } = forms || {}

  //   if (phone && venue && venue.id) {
  //     this.singleCardInfoLoading = true

  //     this.accountListApi.getSingleCardAccountInfo({
  //       shop_id: venue.id,
  //       phone
  //     }, (res: any) => {
  //       const {
  //         shop_money
  //       } = res.data || {}

  //       this.singleCardInfoLoading = false

  //       this.$set(this.curSingleCardInfo, 'shop_money', shop_money || '')
  //       this.$set(this.curSingleCardInfo, 'errMsg', '')
  //     }, (err: any) => {

  //       console.log(err, 'err')

  //       this.singleCardInfoLoading = false

  //       const {
  //         message
  //       } = err || {}

  //       if (message) {
  //         this.$set(this.curSingleCardInfo, 'shop_money', '')
  //         this.$set(this.curSingleCardInfo, 'errMsg', message)
  //       }
  //     })
  //   }
  // }

  // 后台返回的字段 转化为前端定义的字段
  private handleTableData(data: any): any {
    // const { gender } = data;
    const newData = {
      ...data,
      // device_name: orderDeviceType2ch[device],
      // pay_type_name: payType2ch[pay_type],
      // user_level_type_name: userLevel2ch[user_type],
      // gender_sex: transfromGenders[gender]
    };

    return newData;
  }

  private handleDeleteAccountList(blackUserItem: any, rowIndex: number) {
    const tableData = this.tableData;
    const rowUserItem = tableData[rowIndex];

    function setUserProp(target: any, item: any, prop: string, val: any) {
      if (!rowUserItem) return;
      if (item.id == rowUserItem.id) {
        target.$set(target.tableData[rowIndex], prop, val);
      }
    }

    this.$confirm("", "是否删除该账号", {
      ...shareConfirmOptions,
    }).then(() => {
      setUserProp(this, blackUserItem, "__deleteBlackStatusLoading", true);
      this.accountListApi.deleteAccountList(
        {
          id: blackUserItem.id,
        },
        () => {
          setUserProp(this, blackUserItem, "__deleteBlackStatusLoading", false);

          this.getTable();
        },
        () => {
          setUserProp(this, blackUserItem, "__deleteBlackStatusLoading", false);
        }
      );
    });
  }

  /** 远程搜索店铺 */
  // private venueList: any[] = [
  //   // {
  //   //   id: 1,
  //   //   label: '测试'
  //   // },
  //   // {
  //   //   id: 2,
  //   //   label: '测试2'
  //   // }
  // ];

  // private searchVenueLoading: boolean = false;

  // private remoteGetVenueQueryString: string = "";

  // private remoteSearchVenueTimer: any = null;

  // private searchVenuePageOption: any = {};

  // private initRemoteGetVenuePageOption() {
  //   this.searchVenuePageOption = {
  //     ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
  //   };
  // }

  // private getSearchVenueParams() {
  //   const searchVenuePageOption = this.searchVenuePageOption;

  //   const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

  //   const { page, limit } = searchVenuePageOption;

  //   const params: any = {
  //     page,
  //     limit,
  //   };

  //   if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
  //     params.name = remoteGetVenueQueryString.trim();
  //   }

  //   return params;
  // }

  // private getMoreVenueList(refresh: boolean = true) {
  //   return new Promise((resolve: Function, reject: Function) => {
  //     if (refresh) {
  //       this.initRemoteGetVenuePageOption();
  //     }

  //     const params = this.getSearchVenueParams();

  //     this.accountListApi.searchVenueList(
  //       params,
  //       (res: any) => {
  //         console.log(res, "res");

  //         const venueList: any[] = res.data || [];

  //         this.venueList = refresh
  //           ? venueList
  //           : this.venueList.concat(venueList);

  //         if (Array.isArray(res.data) && res.data.length == 0) {
  //           this.searchVenuePageOption.isRearchBottom = true;
  //         }
  //         this.searchVenueLoading = false;

  //         this.addVenueListLoading = false;

  //         resolve();
  //       },
  //       () => {
  //         this.searchVenueLoading = false;

  //         this.addVenueListLoading = false;

  //         reject();
  //       }
  //     );
  //   });
  // }

  // // 第一次搜索 无关键字
  // private handleFirstGetVenueList() {
  //   this.searchVenueLoading = true;

  //   this.getMoreVenueList(true);
  // }

  // // 清空搜索
  // private handleClearGetVenueList() {
  //   this.remoteGetVenueQueryString = "";

  //   this.searchVenueLoading = true;

  //   this.getMoreVenueList(true);
  // }

  // // 远程搜索
  // private remoteGetVenueMethod(query: string) {
  //   query = query.trim ? query.trim() : "";

  //   if (query !== "") {
  //     this.remoteGetVenueQueryString = query;
  //     clearTimeout(this.remoteSearchVenueTimer);

  //     this.remoteSearchVenueTimer = setTimeout(() => {
  //       this.searchVenueLoading = true;

  //       this.getMoreVenueList();
  //       // searchStu((res) => {
  //       //   const {
  //       //     students,
  //       //     total_count
  //       //   } = res || {}

  //       //   this.remoteAllStudents = [
  //       //     ...(students || [])
  //       //   ]

  //       //   this.remoteStudentTotalCount = total_count || 0

  //       //   this.remoteGetStudentLoading = false
  //       // }, {
  //       //   kw: query,
  //       //   ...this.getRemoteGetStudentPageOptionParams()
  //       // }, () => {
  //       //   this.remoteGetStudentLoading = false
  //       // })
  //     }, 300);
  //     // setTimeout(() => {
  //     //   this.remoteGetStudentLoading = false;
  //     //   this.options = this.list
  //     // }, 200);
  //   } else {
  //     this.remoteGetVenueQueryString = "";
  //     this.venueList = [];
  //   }
  // }

  // private addVenueListLoading: boolean = false;

  // private remoteAddVenueMethod() {
  //   if (this.addVenueListLoading) return;
  //   const searchVenuePageOption = this.searchVenuePageOption;

  //   const { isRearchBottom } = searchVenuePageOption;

  //   if (isRearchBottom) return;

  //   this.searchVenuePageOption.page++;

  //   this.addVenueListLoading = true;

  //   this.getMoreVenueList(false);
  // }
  /** end 远程搜索店铺 */

  /** 导出excel */
  // private downloadExcelLoading: boolean = false;

  // // 订单字段名 文案对应关系
  // private orderExcelList2ch: any = {
  //   "phone": "手机号",
  //   "user_name": "用户昵称",
  //   "reason": "原因",
  //   "opt_name": "操作人",
  //   "opt_time": "操作时间"
  // };
  // // 订单Excel 表头的先后顺序
  // private orderExcelSortList: string[] = [
  //   "phone",
  //   "user_name",
  //   "reason",
  //   "opt_name",
  //   "opt_time"
  // ];

  // // 点击导出excel 表格
  // private handleDownloadExcel() {
  //   const params = this.getAccountListParams("exportExcel");

  //   if (params === null) {
  //     return false;
  //   }

  //   this.downloadExcelLoading = true;
  //   this.accountListApi.exportExcelSAccountListList(
  //     params,
  //     (res: any) => {
  //       const {
  //         start_date,
  //         end_date,
  //         // over_start_time,
  //         // over_end_time,
  //       } = params || {};

  //       const create_time_duration_name =
  //         start_date && end_date
  //           ? `时间${start_date}到${end_date}`
  //           : "";

  //       // const finish_time_duration_name =
  //       //   over_start_time && over_end_time
  //       //     ? `完成时间${over_start_time}到${over_end_time}`
  //       //     : "";
  //       const filename = `账号列表 ${create_time_duration_name}.xlsx`;
  //       const { data } = res || {};

  //       const { list: originList } = data || {};

  //       const newList = Array.isArray(originList)
  //         ? originList.map((item) => {
  //             const {
  //               // type,

  //             } = item || {};

  //             const newItem = {
  //               ...(item || {}),

  //               // memberTypeName: transferUserLevel2ch[type]
  //             };

  //             return newItem;
  //           })
  //         : [];

  //       if (newList.length > 0) {
  //         const orderExcelSortList = this.orderExcelSortList;

  //         const orderExcelList2ch = this.orderExcelList2ch;

  //         const excelJSONData = getExcelJSONDataByList(
  //           newList,
  //           orderExcelSortList,
  //           orderExcelList2ch
  //         );

  //         excuteDownloadExport2Excel(excelJSONData, filename);
  //       } else {
  //         this.$message.info("选中时段没有可导出的信息");
  //       }

  //       this.downloadExcelLoading = false;
  //     },
  //     () => {
  //       this.downloadExcelLoading = false;
  //     }
  //   );
  // }

  /** end 导出excel */

  private restForm(): void {
    // this.form = {
    //   ...this.deepLoopUtil.deepCopy(defaultForm),
    // };

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}

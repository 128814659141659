/** 注意： meta  key 一旦确定不可修改 且 同级唯一。 是作为识别这个对象的唯一标识！！！   */

// 财务管理
export const financeManageRoutes = [
  {
    path: "/financeLeaseList",
    name: "financeLeaseList",
    meta: {
      title: "融资租赁",
      key: 'finance-lease-list',
      pageApisPrm: import("@/views/financeManage/financeLeaseList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "@/views/financeManage/financeLeaseList.vue"
      ),
  },
  // financeLeaseTakeRateList
  {
    path: "/financeLeaseTakeRateList",
    name: "financeLeaseTakeRateList",
    meta: {
      title: "抽成管理",
      key: 'finance-lease-take-rate-list',
      asideHidden: true, // 侧边栏不显示
      pageApisPrm: import("@/views/financeManage/financeLeaseTakeRateList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "@/views/financeManage/financeLeaseTakeRateList.vue"
      ),
  },
  {
    path: "/abnormalPayOrderList",
    name: "abnormalPayOrderList",
    meta: {
      title: "异常订单查询",
      key: 'abnormal-pay-order-list',
      pageApisPrm: import("@/views/financeManage/abnormalPayOrderList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "@/views/financeManage/abnormalPayOrderList.vue"
      ),
  },

  // accountMixCheckRecord
  {
    path: "/accountMixCheckRecord",
    name: "accountMixCheckRecord",
    meta: {
      title: "扣款记录",
      key: 'account-mix-check-record',
      pageApisPrm: import("@/views/financeManage/accountMixCheckRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "@/views/financeManage/accountMixCheckRecord.vue"
      ),
  },
  // {
  //   path: "/coachLeaderWithdrawAccountAudit",
  //   name: "coachLeaderWithdrawAccountAudit",
  //   meta: {
  //     title: "助教队长提现账户审核",
  //     key: 'coach-leader-withdraw-account-audit',
  //     pageApisPrm: import("@/views/financeManage/coachLeaderWithdrawAccountAudit.vue").then(({ pageApiList }: any) => pageApiList)
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "financeManage" */ "@/views/financeManage/coachLeaderWithdrawAccountAudit.vue"
  //     ),
  // },
  {
    path: "/coachLeaderWithdrawDepositAudit",
    name: "coachLeaderWithdrawDepositAudit",
    meta: {
      title: "助教队长提现审核",
      key: 'coach-leader-withdraw-deposit-audit',
      pageApisPrm: import("@/views/financeManage/coachLeaderWithdrawDepositAudit.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "@/views/financeManage/coachLeaderWithdrawDepositAudit.vue"
      ),
  },

  {
    path: "/singleCardTransferRecord",
    name: "singleCardTransferRecord",
    meta: {
      title: "单店会员移植记录",
      key: 'single-card-transfer-record',
      pageApisPrm: import("@/views/financeManage/singleCardTransferRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "@/views/financeManage/singleCardTransferRecord.vue"
      ),
  },
  {
    path: "/singleCardConsumeRecord",
    name: "singleCardConsumeRecord",
    meta: {
      title: "美团单店卡核销记录",
      key: 'single-card-consume-record',
      pageApisPrm: import("@/views/financeManage/singleCardConsumeRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "@/views/financeManage/singleCardConsumeRecord.vue"
      ),
  },
  {
    path: "/technicalServiceFeeList",
    name: "technicalServiceFeeList",
    meta: {
      title: "技术服务费汇总",
      key: 'technical-service-fee-list',
      pageApisPrm: import("@/views/financeManage/technicalServiceFeeList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "@/views/financeManage/technicalServiceFeeList.vue"
      ),
  },
  {
    path: '/technicalServiceShopFeeList',
    name: "technicalServiceShopFeeList",
    meta: {
      title: "技术服务费店铺详情",
      key: 'technical-service-shop-fee-list',
      asideHidden: true, // 侧边栏不显示
      pageApisPrm: import("@/views/financeManage/technicalServiceShopFeeList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "@/views/financeManage/technicalServiceShopFeeList.vue"
      ),
  },
  // technicalServiceShopIncomeList
  {
    path: '/technicalServiceShopIncomeList',
    name: "technicalServiceShopIncomeList",
    meta: {
      title: "技术服务费店铺收益明细",
      key: 'technical-service-shop-income-list',
      asideHidden: true, // 侧边栏不显示
      pageApisPrm: import("@/views/financeManage/technicalServiceShopIncomeList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "@/views/financeManage/technicalServiceShopIncomeList.vue"
      ),
  },
  {
    path: "/singleCardAccountCheckRecord",
    name: "singleCardAccountCheckRecord",
    meta: {
      title: "单店卡余额扣款",
      key: 'single-card-account-check-record-list',
      pageApisPrm: import("@/views/financeManage/singleCardAccountCheckRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "@/views/financeManage/singleCardAccountCheckRecord.vue"
      ),
  },
  {
    path: "/singleCardUseRecord",
    name: "singleCardUseRecord",
    meta: {
      title: "单店卡消费记录",
      key: 'single-card-use-record',
      pageApisPrm: import("@/views/financeManage/singleCardUseRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "@/views/financeManage/singleCardUseRecord.vue"
      ),
  },
  {
    path: "/cashierOrderList",
    name: "cashierOrderList",
    meta: {
      title: "收银台订单",
      key: 'cashier-order-list',
      pageApisPrm: import("@/views/financeManage/cashierOrderList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "@/views/financeManage/cashierOrderList.vue"
      ),
  },
  // cashierGoodsOrderList
  {
    path: "/cashierGoodsOrderList",
    name: "cashierGoodsOrderList",
    meta: {
      title: "收银台商品订单",
      key: 'cashier-goods-order-list',
      pageApisPrm: import("@/views/financeManage/cashierGoodsOrderList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "@/views/financeManage/cashierGoodsOrderList.vue"
      ),
  },
  {
    path: "/recommanderPartnerWithdrawAccountAudit",
    name: "recommanderPartnerWithdrawAccountAudit",
    meta: {
      title: "运营合伙人提现管理",
      key: 'recommander-partner-withdraw-account-audit-list',
      pageApisPrm: import("@/views/financeManage/recommanderPartnerWithdrawAccountAudit.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "@/views/financeManage/recommanderPartnerWithdrawAccountAudit.vue"
      ),
  },
  {
    path: "/merchantCouponSaleRecord",
    name: "merchantCouponSaleRecord",
    meta: {
      title: "畅打券销售记录",
      key: 'merchant-coupon-sale-record',
      pageApisPrm: import("@/views/financeManage/merchantCouponSaleRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "@/views/financeManage/merchantCouponSaleRecord.vue"
      ),
  },
  {
    path: "/merchantWithdrawConsumeRecord",
    name: "merchantWithdrawConsumeRecord",
    meta: {
      title: "商家提现余额扣款记录",
      key: 'merchant-withdraw-remain-amount-consume-record',
      pageApisPrm: import("@/views/financeManage/merchantWithdrawConsumeRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "@/views/financeManage/merchantWithdrawConsumeRecord.vue"
      ),
  },
  {
    path: "/merchantWithdrawAddRecord",
    name: "merchantWithdrawAddRecord",
    meta: {
      title: "商家提现余额增加记录",
      key: 'merchant-withdraw-remain-amount-add-record',
      pageApisPrm: import("@/views/financeManage/merchantWithdrawAddRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
            /* webpackChunkName: "financeManage" */ "@/views/financeManage/merchantWithdrawAddRecord.vue"
      ),
  },
  {
    path: "/allMemberConsumeRecord",
    name: "allMemberConsumeRecord",
    meta: {
      title: "全国会员扣款记录",
      key: 'universal-member-consume-record',
      pageApisPrm: import("@/views/financeManage/allMemberConsumeRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "@/views/financeManage/allMemberConsumeRecord.vue"
      ),
  },
  {
    // withdrawAccountAudit
    path: "/withdrawAccountAudit",
    name: "withdrawAccountAudit",
    meta: {
      title: "商家端提现账户审核",
      key: 'merchant-withdraw-account-audit',
      pageApisPrm: import("@/views/financeManage/withdrawAccountAudit.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "@/views/financeManage/withdrawAccountAudit.vue"
      ),
  },
  {
    path: "/coachWithdrawDeposit",
    name: "coachWithdrawDeposit",
    meta: {
      title: "助教提现管理",
      key: 'coach-withdraw-audit',
      pageApisPrm: import("@/views/financeManage/coachWithdrawDeposit.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "@/views/financeManage/coachWithdrawDeposit.vue"
      ),
  },
  {
    path: "/withdrawDeposit",
    name: "withdrawDeposit",
    meta: {
      title: "提现管理",
      key: 'withdraw-deposit-manage',
      pageApisPrm: import("@/views/financeManage/withdrawDeposit.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "financeManage" */ "@/views/financeManage/withdrawDeposit.vue"
      ),
  },
  {
    path: "/cueLockerIncome",
    name: "cueLockerIncome",
    meta: {
      title: "球杆柜收益",
      key: 'cue-locker-income',
      pageApisPrm: import("@/views/financeManage/cueLockerIncome.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
            /* webpackChunkName: "financeManage" */ "@/views/financeManage/cueLockerIncome.vue"
      ),
  },
  {
    path: "/matchEntryFeeMange",
    name: "matchEntryFeeMange",
    meta: {
      title: "比赛报名费管理",
      key: 'match-entry-fee-mange',
      pageApisPrm: import("@/views/financeManage/matchEntryFeeMange.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
            /* webpackChunkName: "financeManage" */ "@/views/financeManage/matchEntryFeeMange.vue"
      ),
  },
]

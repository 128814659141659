
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
// import { screen } from "@/common/screen/screen";
import WithdrawAccountAuditApi from "@/common/api/financeManage/withdrawAccountAudit";
import { CommonApi } from "@/common/api/common";
import { excuteDownloadExport2Excel } from "@/common/xlsx";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import { utils } from "xlsx";

import gallery from "@/components/gallery.vue";

import {
  shareConfirmOptions
} from '@/common/config'


export const pageApiList = getPageAPIs(WithdrawAccountAuditApi)


const transformGender = ["", "女", "男"];

// 审核状态 1待审核 2审核通过 3驳回
const withdrawAccountAudit2Text: any = ["", "审核中", "审核通过", "驳回"];


const defaultForm: any = {
  name: "",
  is_status: 1, // 状态 1未审核 2已撤销 3已通过 4不通过
  time: []
}

@Component({
  components: {
    gallery,
  },
  filters: {
    getGoodsImgUrls(back_goods_detail: any[]) {
      if (!Array.isArray(back_goods_detail)) return []

      return back_goods_detail.map(({ img_url }: { img_url: string }) => img_url)
    },
    getGoodsRefundImgUrls(img_url: string) {
      if (!img_url) return []

      return img_url.split(',')
    },
    getGoodsRefundImgUrlLength(img_url: string) {
      if (!img_url) return 0

      return img_url.split(',').length
    },
    getGenderText(gender: number): string {
      return transformGender[gender] || "未知";
    },
    // feedBackTypeText(type: number): string {
    //   if (typeof type != 'number') return ''

    //   return TypeText[type]
    // },
    refundAuditStatusText(statusNum: any): any {
      return withdrawAccountAudit2Text[statusNum] || "未知状态";
    },
  },
})
export default class WithdrawAccountAudit extends Vue {
  private form: any = {
    // // type:  0, // 问题类型
    // user_id: "", // 用户ID
    // nickname: "", // 助教昵称
    // is_status: 1, // 状态
    // time: [], // 时段选择
  };
  private deepLoopUtil: any;

  constructor() {
    super()

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm, false),
    }
  }
  private withdrawAccountAuditApi: any = new WithdrawAccountAuditApi();
  private created(): void {
    // this.getProblemTypes();
    this.search();
  }
  private getTable(): void {
   
    this.withdrawAccountAuditApi.getList(
      {
        ...this.getWithdrawAccountAuditParams()
      },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.pageNo = res.data.page || this.tablePage.pageNo;
        this.tablePage.pageSize = res.data.prepage || this.tablePage.pageSize;
        this.tablePage.totalCount = res.data.count;
      }
    );
  }

  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }
  // private problemList: any[] = [
  //   ...problemList
  // ];

  // 审核状态 1待审核 2审核通过 3驳回
  private statusList: any[] = [
    {
      label: "待审核",
      value: 1,
    },
    {
      label: "审核通过",
      value: 2,
    },
    {
      label: "驳回",
      value: 3,
    },
  ];

  private systemTypaName: any[] = ["未知", "安卓", "IOS", "小程序"];

  // 更新状态
  private handleUpdateWithdrawAccountAuditStatus(row: any, mode: string) {
    const modeMsgs: any = {
      agree: "通过",
      disagree: "不通过",
    };
    this.$confirm(`确定${modeMsgs[mode]}该项申请?`, "", {
      ...shareConfirmOptions,
    }).then(() => {
      const tableData = this.tableData;

      const existRowIndex = tableData.indexOf(row);

      if (existRowIndex > -1) {
        row.__updateFeedbackStatusLoading = true;

        tableData.splice(existRowIndex, 1, row);

        this.withdrawAccountAuditApi.updateWithdrawAccountAuditStatus(
          {
            id: row.id,
            mode,
            // status: 1
          },
          () => {
            row.__updateFeedbackStatusLoading = false;

            tableData.splice(existRowIndex, 1, row);

            this.getTable();
          },
          () => {
            row.__updateFeedbackStatusLoading = false;

            tableData.splice(existRowIndex, 1, row);
          }
        );
      }
    });
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

 
  private images: any = [];
 

  private getWithdrawAccountAuditParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;


    const {
      name,
      is_status,
      time, // 
      // finish_time, // 完成时间
    } = form || {};


    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};

    if (name) {
      outerParams.name = name;
    }

    if (typeof is_status == 'number' && is_status !== -1) {
      outerParams.is_status = is_status
    }

    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      outerParams.start_date = startTime;

      outerParams.end_date = endTime;
    }

    if (
      mode == "exportExcel" &&
      !(outerParams.start_date && outerParams.end_date)
    ) {
      this.$message.error("请选择日期导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      // outerParams.__isUnique = !firstLoading;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  /** 导出excel */
  // private downloadExcelLoading: boolean = false;

  // // 字段名 文案对应关系
  // private merchantExcelList2ch: any = {
  //   back_no: "订单编号",
  //   phone: "用户手机号",
  //   shop_name: "店铺名",
  //   __back_good_details: "商品信息",
  //   price: "退款金额",
  //   reason: "退款原因",
  //   req_time: "申请时间",
  //   status: "状态",
  //   audit_time: "审核时间",
  //   audit: "审核人"
  // };
  // // 商户Excel 表头的先后顺序
  // private merchantExcelSortList: string[] = [
  //   "back_no",
  //   "phone",
  //   "shop_name",
  //   "__back_good_details",
  //   "price",
  //   "reason",
  //   "req_time",
  //   "status",
  //   "audit_time",
  //   "audit"
  // ];

  // // 点击导出excel 表格
  // private handleExportExcelChannelList() {
  //   const params = this.getWithdrawAccountAuditParams('exportExcel')

  //   if (params === null) {
  //     return false;
  //   }

  //   this.downloadExcelLoading = true;
  //   this.withdrawAccountAuditApi.exportExcelRefundAuditList(
  //     params,
  //     (res: any) => {
  //       const { start_date, end_date } = params || {};

  //       const filename = `商品退款审核列表 ${start_date}到${end_date}.xlsx`;
  //       const { data: originList } = res || {};

  //       // const {
  //       //   list: originList
  //       // } = data || {}

  //       const newList = Array.isArray(originList)
  //         ? originList.map((item) => {
  //             const { 
  //               status,
  //               back_goods_detail
  //              } = item || {};
  //             const newItem = {
  //               ...(item || {}),
  //               __status_text: withdrawAccountAudit2Text[status],
  //               __back_good_details: back_goods_detail.map((goods: any, index: number) => `${ goods.name }x${ goods.num }  ¥${ goods.price }`).join('; \t')
  //             };

  //             for (let key in item) {
  //               if (typeof item[key] == 'number') {
  //                 newItem[key] = item[key].toString()
  //               }
  //             }

             
  //             return newItem;
  //           })
  //         : [];

  //       if (newList.length > 0) {
  //         const merchantExcelSortList = this.merchantExcelSortList;

  //         const merchantExcelList2ch = this.merchantExcelList2ch;

  //         const excelJSONData = getExcelJSONDataByList(
  //           newList,
  //           merchantExcelSortList,
  //           merchantExcelList2ch
  //         );

  //         excuteDownloadExport2Excel(excelJSONData, filename);
  //       } else {
  //         this.$message.info("选中时段没有可导出的信息");
  //       }

  //       this.downloadExcelLoading = false;
  //     },
  //     () => {
  //       this.downloadExcelLoading = false;
  //     }
  //   );
  // }

  /** end 导出excel */
}

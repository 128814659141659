import { BasicsClass } from '@/common/BasicsClass';
export class MolixLaunchListAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getMolixLaunchList', {
            url: '/admin/marketPool/launchIndex',
            name: 'list',
            label: '列表'
        }],
        ['addMolixLaunchList', {
            url: '/admin/marketPool/addLaunch',
            name: 'add-molix-launch',
            label: '添加'
        }],
        ['updateMolixLaunchList', {
            url: '/admin/marketPool/editLaunch',
            name: 'edit-molix-launch',
            label: '编辑'
        }],
      
        ['deleteMolixLaunchListItem', {
            url: '/admin/marketPool/delLaunch',
            name: 'delete-molix-launch',
            label: '删除'
        }],

        ['getAccountInfoList', {
            url: '/admin/marketPool/allAccount',
            name: 'merket-account-list',
            label: '市场部账号下拉列表'
        }]
    ])


    //新增麦力士投放
    public addMolixLaunchList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('addMolixLaunchList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    //修改麦力士投放
    public updateMolixLaunchList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('updateMolixLaunchList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    //麦力士投放列表
    public getMolixLaunchList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getMolixLaunchList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 删除 麦力士投放
    public deleteMolixLaunchListItem(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('deleteMolixLaunchListItem').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 市场部账号下拉列表
    public getAccountInfoList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getAccountInfoList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}

import dayjs from "@/plugins/dayjs";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import gallery from "@/components/gallery.vue";

import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import { ShopInformationCollectAPI } from "@/common/api/officialWebManage/shopInformationCollect";

import { excuteDownloadExport2Excel } from "@/common/xlsx";


// import { util } from "vue/types/umd";
import { DeepLoop } from "@/common/deepLoop/deepLoop";

import {
  shareConfirmOptions,
  shareShopType2ch
} from '@/common/config'


// 暴露的API 列表
export const pageApiList = getPageAPIs(ShopInformationCollectAPI);

console.log(pageApiList, 'pageApiList')

// function getCurrentWeekDate() {
//   const currentDay = (dayjs() as any).$d;

//   const curWeekStartDay = (dayjs().startOf("week") as any).$d;

//   return [curWeekStartDay, currentDay];
// }

// function getLastWeekDate() {
//   const lastWeekEndDay = (dayjs().subtract(1, 'week').endOf("week") as any).$d;

//   const lastWeekStartDay = (dayjs().subtract(1, 'week').startOf("week") as any).$d;

//   return [lastWeekStartDay, lastWeekEndDay];
// }

// function getCurrentMonthDate() {
//   const currentDay = (dayjs() as any).$d;

//   const curMonthStartDay = (dayjs().startOf("month") as any).$d;

//   return [curMonthStartDay, currentDay];
// }

// function getLastMonthDate() {
//   const lastEndDay = (dayjs().subtract(1, "month").endOf("month") as any).$d;

//   const lastStartDay = (dayjs().subtract(1, "month").startOf("month") as any)
//     .$d;

//   return [lastStartDay, lastEndDay];
// }

// const defaultChooseRangeModes = [
  
//   {
//     label: '上周',
//     name: 'last-week',
//     rangeFunc: getLastWeekDate
//   },
//   {
//     label: '本周',
//     name: 'cur-week',
//     rangeFunc: getCurrentWeekDate
//   },
//   // {
//   //   label: '本月',
//   //   name: 'cur-month',
//   //   rangeFunc: getCurrentMonthDate
//   // },
//   {
//     label: '上月',
//     name: 'last-month',
//     rangeFunc: getLastMonthDate
//   }
// ]




const defaultChooseRangeModeName: string = 'cur-week'

const defaultForm = {
  // order_no: '',
  phone: '',
  // shop_name: '',
  // venue_id: "",
  // venue: {
  //   id: "",
  //   name: "",
  // },

  time: [
    
  ], // 日期
}


// const defaultSearchVenuePageOption = {
//   page: 1,
//   limit: 10,
//   isRearchBottom: false,
// };


// // 最大有效期
// const maxExpiredDays = 9999;

// // 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;

// coupon 卡券分属类别
// const couponShopType2ch: any = [
//   ...shareShopType2ch
// ]


// type ShopIncomeDayItem = {
//   date: string,
//   total_money: string
// }

@Component({
  components: {
    gallery,
  },
  filters: {

    showVideoPoster(mediaList: any[]): string {
      const media: any = mediaList[0] || {};

      const {
        type,
        url
      } = media

      if (type == 2) {
        return `${url}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`;
      }

      return url;
    },
  },
 
})
export default class ShopInformationCollect extends Vue {
  private form: any = {
    // couponId: "",
    // time: [],
    // startDate: "",
    // endDate: "",
  };
  // private forms: any = {
  //   // maxType: "0",
  //   // ...defaultForms
  // };

  private deepLoopUtil: any = {};

  // private timeRangeModes: any[] = [
  //   ...(defaultChooseRangeModes || [])
  // ]

  private curRangeMode: string = defaultChooseRangeModeName


  private shopInformationCollectApi = new ShopInformationCollectAPI();
  // private couponTimeDurationAPI = new CouponTimeDurationManageAPI();
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
      time: [
        // ...getCurrentWeekDate()
      ]
    };

    // this.forms = {
    //   ...this.deepLoopUtil.deepCopy(defaultForms, false),
    // };

    // this.searchVenuePageOption = {
    //   ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    // };

    // this.merchantApi = new MerchantApi();
  }

  private created(): void {
    console.log(this.$route)
  }

  private activated() {
    this.refrshTableDataList();
  }

  private mount():void {
    this.refrshTableDataList();
  }
  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
    // this.form = new Util().clearObject(this.form);
    // this.$router.replace("/orderList");
    this.refrshTableDataList();
  }

  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.refrshTableDataList();
  }

  private tableData: any[] = [];

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";
  // private options: any = [];

  // private clearObject(): void {
  //   this.dialogFormVisible = false;
  //   this.forms = new Util().clearObject(this.forms);
  // }
  // private tablePage: any = {
  //   count: 0,
  //   page: 1,
  //   prepage: 10,
  //   pageSizes: [10, 20, 50, 100, 200, 500],
  //   totalPage: 0,
  // };
  // //翻页
  // private handlePageChange(val: any) {
  //   this.tablePage.page = val.currentPage;
  //   this.tablePage.prepage = val.pageSize;
  //   this.refrshTableDataList();
  // }
  
 

  // private handleChangeTimeRange(range: any) {
  //   console.log(range, 'range')
  //   this.curRangeMode = range.name

  //   const chooseTimeRanges = range.rangeFunc()

  //   this.$set(this.form, 'time', chooseTimeRanges)
  // }
  private previewImages: any[] = [];
  private handlePreviewMedia(row: any) {
    const medias: any[] = [];

    const handleMediaItem = (media: any) => {
      switch (media.type) {
        case 1:

          return {
            title: "",
            type: "image/jpeg",
            href: media.url,
            thumbnail: media.url,
          }
        case 2:
          return {
            title: "",
            type: "video/mp4",
            href: media.url,
            poster: `${media.url}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`,
          }
      }
    }

    if (row.mediums && Array.isArray(row.mediums)) {
      medias.push(
        ...row.mediums.map(handleMediaItem)
      )
    }

    this.previewImages = medias;
  }


  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    console.log(val, 'val')
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.refrshTableDataList();
  }


  private refrshTableDataList() {
    const params = this.getShopInformationCollectParams()

    if (typeof params != 'object') return

    this.shopInformationCollectApi.getShopInformationCollectTableList(
      {
        ...params
      },
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        const { list, count, page, prepage } = data || {};

        const newTableList = Array.isArray(list)
          ? list.map(this.handleTableData)
          : [];

        // totalCount: 0,
        // page: 1,
        // pageSize: 10,
        this.$set(this.tablePage, "totalCount", count);
        this.$set(this.tablePage, "page", page);
        this.$set(this.tablePage, "pageSize", prepage);

        this.tableData = newTableList;

      },
      () => {

      }
    )

  }

  private getShopInformationCollectParams(mode = "all"): any {
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const { phone, time } = form;

    if (phone) {
      params.phone = phone;
    }

 

    const { page, prepage: pageSize } = tablePage || {};

    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      params.start_date = startTime;

      params.end_date = endTime;
    }


    if (
      mode == "exportExcel" &&
      !(params.start_date && params.end_date)
    ) {
      this.$message.error("请选择时段导出数据");
      return null;
    }

    if (mode === "all") {
      params.page = page;

      params.limit = pageSize;

    }

    console.log(params, "params");

  
    return params;
  }

   // 后台返回的字段 转化为前端定义的字段
  private handleTableData(data: any): any {
    const { 

    } = data;
    const newData = {
      ...data,
   
    };

    return newData;
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 用户字段名 文案对应关系
  private userExcelList2ch: any = {
    merchant_mobile: "提交人手机号",
    nickname: "角色名",
    name: "店铺名称",
    boss_name: "老板姓名",
    mobile: "老板手机号",
    address: "地址",
    desk_num: "桌数",
    brand_name: "台球品牌",
    remark: "其他信息",
    locate_address: "提交人当前位置",
    created_at: "提交时间"
  };
  // 用户Excel 表头的先后顺序
  private userExcelSortList: string[] = [
    "merchant_mobile",
    "nickname",
    "name",
    "boss_name",
    "mobile",
    "address",
    "desk_num",
    "brand_name",
    "remark",
    "locate_address",
    "created_at"
  ];

  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getShopInformationCollectParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.shopInformationCollectApi.exportExcelShopInformationCollectList(
      params,
      (res: any) => {
        const { start_date, end_date } = params || {};

        const filename = `店铺信息收集列表 ${start_date}到${end_date}.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const {
                
              } = item;
              const newItem = {
                ...(item || {}),
               
              };


              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const userExcelSortList = this.userExcelSortList;

          const userExcelList2ch = this.userExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            userExcelSortList,
            userExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */

}

import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

import { CommonApi } from "../common";

export class CompetePointBlackUserAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getCompetePointBlackUserist', {
      url: '/admin/userPoolRanking/blackList',
      name: 'list',
      label: '列表'
    }],
   
    ['addCompetePointBlackUser', {
      url: '/admin/userPoolRanking/addBlack',
      name: 'add',
      label: '添加黑名单'
    }],
    // ['editCompetePointBlackUser', {
    //   url: '/admin/seller_blacklist/edit',
    //   name: 'edit',
    //   label: '编辑黑名单'
    // }],
    ['deleteCompetePointBlackUser', {
      url: '/admin/userPoolRanking/deleteBlack',
      name: 'delete',
      label: '删除黑名单'
    }],


  ])
  // api 方法
  // 段位榜黑名单列表
  public getCompetePointBlackUserist(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getCompetePointBlackUserist').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

 

  // 添加段位榜黑名单
  public addCompetePointBlackUser(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('addCompetePointBlackUser').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 编辑段位榜黑名单
  // public editCompetePointBlackUser(
  //   params: any,
  //   successCb: Function,
  //   failCb: Function,
  //   showLoading: boolean = false
  // ) {
  //   const apiUrls = this.apiUrls

  //   const url = apiUrls.get('editCompetePointBlackUser').url

  //   this.BasicPost(url, {
  //     ...params
  //   }, false, false, false, successCb, failCb, showLoading)
  // }

  // 删除段位榜黑名单
  public deleteCompetePointBlackUser(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('deleteCompetePointBlackUser').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
  
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import PandaMobileTable from "@/components/mobile-custom/panda-table.vue";

import PandaMobileTWFContainer from "@/components/mobile-custom/panda-table-with-filter-container.vue";

import { AxiosData } from "@/common/http/http";
import { Util, getPageAPIs } from "@/common/util";
import { MerchantOperationerAPI } from "@/common/api/merchantManage/merchantOperationerList";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import { shareConfirmOptions } from "@/common/config";
export const pageApiList = getPageAPIs(MerchantOperationerAPI);


const defaultForms: any = {
  id: "",
  shop_id: "",
  praise_user_id: ""
}


@Component({
  components: {
    PandaMobileTable,
    PandaMobileTWFContainer,
  },
})
export default class MerchantsAudit extends Vue {
  /** 移动端相关属性 方法 */
  private allMobileChooseFields: any[] = [
    {
      label: '名称',
      field: 'name'
    },
  ];
  /** end 移动端相关属性 方法 */
  private merchantOperationerApi: any;

  private deepLoopUtil: any;

  constructor() {
    super();
    this.merchantOperationerApi = new MerchantOperationerAPI();

    this.deepLoopUtil = new DeepLoop();

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms)
    }
  }
  private dialogFormVisible: boolean = false;

  private form: any = {
    shop_id: "",
  };
  private title: string = "";
  private forms: any = {
    
  };

  // private get getAqaraId() {
  //   return this.$route.query.aqara_id;
  // }
  private get getShopId() {
    const route = this.$route

    const {
      query
    } = route || {}

    const {
      id
    } = query || {}

    return id;
  }
  // private dList: any = [];
  // private device(): void {
  //   this.merchantOperationerApi.device(
  //     {
  //       aqara_id: this.getAqaraId,
  //     },
  //     (res: any) => {
  //       if (res.data.list) {
  //         this.dList = res.data.list;
  //       } else {
  //         this.dList = [];
  //       }
  //     }
  //   );
  // }
  private availableOperatorList: any = [];
  private refreshChooseOperatorList(): void {
    this.merchantOperationerApi.getChooseOperationer(
      {
        // aqara_id: this.getAqaraId,
      },
      (res: any) => {
        const {
          list
        } = res.data || {}

        if (Array.isArray(list)) {
          this.availableOperatorList = list;
        } else {
          this.availableOperatorList = [];
        }
      }
    );
  }
  private onclose(): void {
    this.forms = new Util().clearObject(this.forms);
    this.dialogFormVisible = false;
  }
  private submit(): void {
    const mode = this.operateMode

    this.forms.shop_id = this.getShopId;

    switch (mode) {
      case 'add':
        this.merchantOperationerApi.merchantOperationerAdd(this.forms, (res: any) => {
          this.$message.success('新增成功')
          this.dialogFormVisible = false;

          this.search();
        });
        break;
      case 'edit':
        this.merchantOperationerApi.merchantOperationerEdit(this.forms, (res: any) => {
          this.$message.success('修改成功')
          this.dialogFormVisible = false;
          this.search();
        });
        break;
    }
  }
  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private activated(): void {
    if (this.getShopId) {
      this.form.shop_id = this.getShopId;
    }
    this.search();
    // this.device();
  }

  private getTable(): void {
    const params = {
      // ...this.form,
      shop_id: this.getShopId,
      page: this.tablePage.pageNo,
      limit: this.tablePage.pageSize,
    }
    this.merchantOperationerApi.merchantOperationerList(
      {
        ...params
      },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.totalCount = res.data.count;
        this.tablePage.pageNo = res.data.page || params.page;
        this.tablePage.pageSize = res.data.prepage || params.limit;
      }
    );
  }

  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private initOperateForm(): void {
    const mode = this.operateMode

    const forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms),
      shop_id: this.getShopId || ''
    }

    if (mode == 'edit') {
      const curOperateItem = this.curOperateItem
      
      const {
        id,
        shop_id,
        praise_user_id
      } = curOperateItem || {}

      Object.assign(forms, {
        id,
        shop_id: shop_id || this.getShopId || '',
        praise_user_id
      })
    }


    this.forms = forms
  }

  private merchantOperationerDel(id: string): void {
    this.$confirm("确认删除该运营人员？", "", {
      ...shareConfirmOptions,
    }).then(() => {
      this.merchantOperationerApi.merchantOperationerDel(
        {
          shop_id: this.getShopId,
          id: id,
        },
        () => {
          this.$message.success("删除成功");
          this.search();
        }
      );
    });
  }
  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  private operateMode: string = 'add'

  private curOperateItem: any;

  private operationAdd() {
    this.title = "新增";
    this.operateMode = 'add'

    this.initOperateForm()

    this.refreshChooseOperatorList()

    this.dialogFormVisible = true;
  }

  private operationChange(row: any): void {
    this.title = "编辑";
    this.operateMode = 'edit'
    this.curOperateItem = row

    this.initOperateForm()

    this.refreshChooseOperatorList()

    this.dialogFormVisible = true;
  }
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
// import { CouponsManageAPI } from "@/common/api/couponList";
import CoachGiftManageAPI from "@/common/api/operationManage/coachGiftManage";
import { excuteDownloadExport2Excel } from "@/common/xlsx";
import { CommonApi } from "@/common/api/common";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import gallery from "@/components/gallery.vue";

import pandaSvga from "@/components/pandaSvga.vue";
import pandaSvgaPreview from "@/components/pandaSvgaPreview.vue";
import ZlSelect from "@/components/third-custom-components/select";

import ZlOption from "@/components/third-custom-components/option";

import { shareConfirmOptions } from "@/common/config";

export const pageApiList = getPageAPIs(CoachGiftManageAPI);

const defaultForms = {
  name: "", // 礼物名称
  panda_coin: "", // 礼物价格
  images: [],
  effects: [],
  sort: "", // 排序
  type: 1, // 类型：1普通 2稀有
  status: 1, // 状态 0下架 1上架

  // singleMaxCountType: '0',
  // shop_id: "",
  // maxLimitCount: ''
};

// 类型：1普通 2稀有
const coachGiftType2ch: string[] = ["", "普通", "稀有"];

const coachGiftStatus2Ch: string[] = ["下架", "上架"];

// const defaultSearchVenuePageOption = {
//   page: 1,
//   limit: 10,
//   isRearchBottom: false,
// };

const coachGiftManageDialogTitle2ch: any = {
  "new-coach-gift-manage": "新增",
  "edit-coach-gift-manage": "编辑",
};

// 最大有效期
// const maxExpiredDays = 9999;

// 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;

const maxTotalMoney = 9999999;

@Component({
  components: {
    ZlSelect,
    ZlOption,
    gallery,
    "panda-svga": pandaSvga,
    "panda-svga-preview": pandaSvgaPreview,
  },
  filters: {
    getCoachGiftType(type: number) {
      return coachGiftType2ch[type] || "";
    },
    getCoachGiftName(status: number) {
      return coachGiftStatus2Ch[status] || "";
    },
  },
})
export default class CoachGiftManageList extends Vue {
  private form: any = {
    // storageLockerId: "",
    name: "",
    status: -1, // 0下架 1上架
    // time: []
    // shop_id: '',
    // time: [],
    // startDate: "",
    // endDate: "",
  };
  private forms: any = {
    // maxType: "0",
    // ...defaultForms
  };

  // private allCoachGiftStatusList: any[] = [
  //   {
  //     label: '全部',
  //     value: -1
  //   },
  //   {
  //     label: '下架',
  //     value: 0
  //   },
  //   {
  //     label: '上架',
  //     value: 1
  //   }
  // ]

  private coachGiftStatusList: any[] = [
    {
      label: "下架",
      value: 0,
    },
    {
      label: "上架",
      value: 1,
    },
  ];

  private coachGiftTypeList: any[] = [
    {
      label: "普通",
      value: 1,
    },
    {
      label: "稀有",
      value: 2,
    },
  ];

  private deepLoopUtil: any = {};

  private commonApi: any;

  // private merchantApi: any;

  private coachGiftManageApi = new CoachGiftManageAPI();

  // private storageLockerTimeDurationAPI = new CoachGiftManageTimeDurationManageAPI();
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    this.commonApi = new CommonApi();

    // this.searchVenuePageOption = {
    //   ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    // };

    // this.merchantApi = new MerchantApi();
  }

  private created(): void {
    this.getCoachGiftManageList();

    // this.refreshCoachGiftManageTimeDurations();

    // this.refreshTableClass();
  }
  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getCoachGiftManageList();
  }
  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getCoachGiftManageList();
  }

  private tableData: any[] = [];

  // // 跳转助教礼物管理的 助教列表
  // private handleGoSubCoachManage(coachGiftItem: any) {
  //   this.$router.push({
  //     path: '/coachGiftSubordinateList',
  //     query: {
  //       coachGiftId: coachGiftItem.id || ''
  //     }
  //   })
  // }

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";

  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getCoachGiftManageList();
  }

  private addOrUpdateCoachGiftManageTitle: string = "助教礼物";

  private dialogFormVisible: boolean = false;

  // 拉起助教礼物弹框
  private handleWakeCoachGiftManageDialog(mode: string) {
    // if (mode == 'new-coach-gift-manage') { // 新增助教礼物

    // }
    this.storageLockerDialogMode = mode;
    this.addOrUpdateCoachGiftManageTitle =
      coachGiftManageDialogTitle2ch[mode] || "助教礼物";

    this.initCoachGiftManageForm(mode);

    this.dialogFormVisible = true;
  }

  private currentCoachGiftManageItem: any = null;

  private storageLockerDialogMode: string = "";

  // 获取礼物详情
  private getCoachGiftDetail(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.coachGiftManageApi.getCoachGiftDetail(
        {
          id,
        },
        (res: any) => resolve((res.data || {}) as any),
        (err: any) => reject(err)
      );
    });
  }

  private async initCoachGiftManageForm(mode: string) {
    const forms: any = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    if (mode == "edit-coach-gift-manage") {
      // 编辑
      // await this.getMoreVenueList(true);

      // await this.refreshCoachGiftManageTimeDurations();

      // await this.refreshTableClass();

      const currentCoachGiftManageItem = this.currentCoachGiftManageItem;

      const { id } = currentCoachGiftManageItem || {};

      const {
        name,
        panda_coin,
        image,
        image_url,
        effect,
        effect_url,
        sort,
        type,
        status,
      } = await this.getCoachGiftDetail(id);

      Object.assign(forms, {
        name,
        panda_coin,
        sort,
        type,
        status,
      });

      const images = [
        {
          fileId: image,
          url: image_url,
        },
      ];

      forms.images = images;

      if (effect && effect_url) {
        const effects = [
          {
            fileId: effect,
            url: effect_url,
          },
        ];

        forms.effects = effects;
      }

      // const venues: any[] = []

      // const handleAddExtraVenue = (venues: any[], id: any, name: any) => {
      //   if (name && typeof id == 'number') {
      //     let existVenue = this.venueList.find((venue) => venue.id == id);

      //     if (!existVenue) {
      //       existVenue = {
      //         id,
      //         name,
      //       };
      //       this.venueList.push(existVenue);
      //     }

      //     venues.push(existVenue)
      //   }
      // }

      // handleAddExtraVenue(venues, shop_id, shop_name)
      // handleAddExtraVenue(venues, assistant_shop_id, assistant_shop_name)

      // if (shop_name && typeof shop_id == "number") {
      //   console.log(shop_id, "shop_id");

      //   console.log(this.venueList, "this.venueList");
      //   let existVenue = this.venueList.find((venue) => venue.id == shop_id);

      //   if (!existVenue) {
      //     existVenue = {
      //       id: shop_id,
      //       name: shop_name,
      //     };
      //     this.venueList.push(existVenue);
      //   }

      //   forms.venue = existVenue;
      // }

      // forms.venues = venues;

      forms.id = id;
    }

    console.log(forms, "forms");

    // console.log(this.venueList, "this.venueList");

    this.forms = forms;
  }

  private handleWakeEditCoachGiftManageDialog(storageLockerItem: any) {
    this.currentCoachGiftManageItem = storageLockerItem;

    this.handleWakeCoachGiftManageDialog("edit-coach-gift-manage");
  }

  private handleDelteCoachGiftManageDialog(storageLockerItem: any) {
    this.$confirm("确定删除该礼物?", "", {
      ...shareConfirmOptions,
    }).then(() => {
      this.coachGiftManageApi.deleteCoachGiftManage(
        {
          id: storageLockerItem.id,
        },
        () => {
          this.getCoachGiftManageList();
        },
        () => {}
      );
    });
  }

  /** 上传图片方法 */
  // 上传媒体文件
  private handleUploadUserImages(file: any, fileList: any[]) {
    this.handleUploadUserMedia(file, fileList, "images", "image");
  }

  private handleUplodUserEffects(file: any, fileList: any[]) {
    this.handleUploadUserMedia(file, fileList, "effects", "image");
  }

  private async handleUploadUserMedia(
    file: any,
    fileList: any[],
    field: string,
    mode: string
  ) {
    console.log(file, "file");

    function getVideoDuration(url: string): any {
      return new Promise((resolve, reject) => {
        var audio = new Audio(url);
        audio.muted = true;
        audio.play().then(() => audio.pause());
        // 元数据已加载

        const handleLoadedmetadata = function (e: any) {
          // let duration = audio.duration;
          console.log(e, "e audio");
          resolve({
            url,
            duration: audio.duration,
          });

          hanleClearListeners();
        };

        const hanleClearListeners = function () {
          audio.removeEventListener("loadedmetadata", handleLoadedmetadata);
          audio.onerror = null;
        };
        audio.addEventListener("loadedmetadata", handleLoadedmetadata);

        audio.onerror = function (err) {
          reject(err);
        };

        audio.muted = false;
      });
    }

    const forms: any = this.forms;

    const optionList = mode == "image" ? forms[field] : forms.videos;

    const sendMediaApi =
      mode == "image" ? this.commonApi.getOss : this.commonApi.getOssvideo;

    if (mode == "video" && !file.duration) {
      const { duration } = await getVideoDuration(file.url).catch(
        (err: any) => {
          this.$message("获取时长失败");
        }
      );

      file.duration = duration;
    }

    optionList.push(file);

    console.log(forms, "forms");

    this.$set(this.forms, `${field}`, optionList);

    this.changeFilePropByList(file, mode, field, { uploading: true });
    sendMediaApi.call(
      this.commonApi,
      file instanceof File ? file : file.raw,
      (res: any) => {
        console.log(res, "res 图片上传成功");

        const { data } = res;

        const { data: uploadFileObj } = data || {};

        const { item: uploadFile } = uploadFileObj || {};

        const { id, url } = uploadFile;

        // this.$set(this.forms, 'avatar_id', id)
        // this.$set(this.forms, 'avatar', url)
        const changeProps: any = {
          uploading: false,
          fileId: id,
          uploadUrl: url,
        };

        if (file.duration) {
          changeProps.duration = file.duration.toString();
        }

        this.changeFilePropByList(file, mode, field, changeProps);
      },
      () => {
        this.$message.error("图片上传失败");

        this.changeFilePropByList(file, mode, field, {
          uploading: false,
          failed: true,
        });
      }
    );
  }

  private changeFilePropByList(
    file: any,
    mode: string,
    field: string,
    changes: any = {}
  ) {
    const forms: any = this.forms;

    const setList: any[] = mode == "image" ? forms[field] : forms.videos;
    const existFileIndex = setList.findIndex(
      (each_file: any) => each_file.uid == file.uid
    );

    if (existFileIndex > -1) {
      const originFile = setList[existFileIndex];

      if (Array.isArray(setList)) {
        setList.splice(existFileIndex, 1, Object.assign(originFile, changes));
      }

      this.$set(this.forms, `${field}`, setList);
    }
  }

  // 删除媒体文件
  private handleFileRemove(file: any, field: string, mode: string) {
    const forms = this.forms;

    const images = mode == "image" ? forms[field] : [];

    const videos = mode == "video" ? forms.videos : [];

    console.log(file, "file");

    const optionList = images.length > 0 ? images : videos;

    const existRemoveFileIndex = optionList.findIndex(
      (each_file: any) => each_file.uid == file.uid
    );

    if (existRemoveFileIndex > -1) {
      optionList.splice(existRemoveFileIndex, 1);

      this.$set(this.forms, `${field}`, optionList);
    }
  }

  // svga 文件
  private handleLoadSvgaEffect(file: any, { parser, player, videoItem }: any) {
    console.log(parser, "parser");
    console.log(player, "player");
    console.log(videoItem, "videoItem");
    file._parser = parser;
    file._player = player;
    file._videoItem = videoItem;
  }

  // 查看特效
  private handleTableCellEffect(row: any) {
    this.previewEffects = [
      {
        title: "",
        type: "svga",
        href: row.effect_url,
      },
      // {
      //   title: "",
      //   type: "svga",
      //   href: "https://asset.test.pandaball.cc/upload/img/ea2db2d9200149398da0b3e9b044b15d.svga",
      // },
    ];
  }

  // 预览媒体文件
  private previewImages: any[] = [];
  private previewEffects: any[] = [];
  private handlePictureCardPreview(file: any, field: string, mode: string) {
    const forms = this.forms;

    if (field == "effects") {
      // file._player.startAnimation()
      const images = forms[field];

      this.previewEffects = [
        ...images.map((img: any) => {
          return {
            title: "",
            type: "svga",
            href: img.url,
          };
        }),
      ];

      return;
    }

    const images = mode == "image" ? forms[field] : [];

    const videos = mode == "video" ? forms.videos : [];

    console.log(file, "file");

    const urls = [
      ...images.map((img: any) => {
        return {
          title: "",
          type: "image/jpeg",
          href: img.url,
          thumbnail: img,
        };
      }),
      ...videos.map((video: any) => {
        return {
          title: "",
          type: "video/mp4",
          href: video.url,
          poster: `${video.url}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`,
        };
      }),
    ];

    this.previewImages = urls;
  }

  /** end 上传图片方法 */

  // private venueList: any[] = [
  //   // {
  //   //   id: 1,
  //   //   label: '测试'
  //   // },
  //   // {
  //   //   id: 2,
  //   //   label: '测试2'
  //   // }
  // ];

  // private searchVenueLoading: boolean = false;

  // private remoteGetVenueQueryString: string = "";

  // private remoteSearchVenueTimer: any = null;

  // private searchVenuePageOption: any = {};

  // private initRemoteGetVenuePageOption() {
  //   this.searchVenuePageOption = {
  //     ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
  //   };
  // }

  // private getSearchVenueParams() {
  //   const searchVenuePageOption = this.searchVenuePageOption;

  //   const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

  //   const { page, limit } = searchVenuePageOption;

  //   const params: any = {
  //     page,
  //     limit,
  //   };

  //   if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
  //     params.name = remoteGetVenueQueryString.trim();
  //   }

  //   return params;
  // }

  // private getMoreVenueList(refresh: boolean = true) {
  //   return new Promise((resolve: Function, reject: Function) => {
  //     if (refresh) {
  //       this.initRemoteGetVenuePageOption();
  //     }

  //     const params = this.getSearchVenueParams();

  //     this.coachGiftManageApi.searchVenueList(
  //       params,
  //       (res: any) => {
  //         console.log(res, "res");

  //         const venueList: any[] = res.data || [];

  //         this.venueList = refresh
  //           ? venueList
  //           : this.venueList.concat(venueList);

  //         if (Array.isArray(res.data) && res.data.length == 0) {
  //           this.searchVenuePageOption.isRearchBottom = true;
  //         }
  //         this.searchVenueLoading = false;

  //         this.addVenueListLoading = false;

  //         resolve();
  //       },
  //       () => {
  //         this.searchVenueLoading = false;

  //         this.addVenueListLoading = false;

  //         reject();
  //       }
  //     );
  //   });
  // }

  // // 第一次搜索 无关键字
  // private handleFirstGetVenueList() {
  //   this.searchVenueLoading = true;

  //   this.getMoreVenueList(true);
  // }

  // // 清空搜索
  // private handleClearGetVenueList() {
  //   this.remoteGetVenueQueryString = "";

  //   this.searchVenueLoading = true;

  //   this.getMoreVenueList(true);
  // }

  // // 远程搜索
  // private remoteGetVenueMethod(query: string) {
  //   query = query.trim ? query.trim() : "";

  //   if (query !== "") {
  //     this.remoteGetVenueQueryString = query;
  //     clearTimeout(this.remoteSearchVenueTimer);

  //     this.remoteSearchVenueTimer = setTimeout(() => {
  //       this.searchVenueLoading = true;

  //       this.getMoreVenueList();

  //     }, 300);
  //   } else {
  //     this.remoteGetVenueQueryString = "";
  //     this.venueList = [];
  //   }
  // }

  // private addVenueListLoading: boolean = false;

  // private remoteAddVenueMethod() {
  //   if (this.addVenueListLoading) return;
  //   const searchVenuePageOption = this.searchVenuePageOption;

  //   const { isRearchBottom } = searchVenuePageOption;

  //   if (isRearchBottom) return;

  //   this.searchVenuePageOption.page++;

  //   this.addVenueListLoading = true;

  //   this.getMoreVenueList(false);
  // }

  // 流水
  // private handleLimitTotalMoney(val: string) {
  //   Util.limitIntegetInput(
  //     val,
  //     (new_val: string) => {
  //       const limit_val = Math.min(Number(new_val), maxTotalMoney).toString();

  //       if (maxTotalMoney == Number(limit_val)) {
  //         this.$set(this.forms, "total_money", limit_val);
  //       } else {
  //         this.$set(this.forms, "total_money", new_val);
  //       }
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "total_money", new_val);
  //     }
  //   );
  // }

  // private handleLimitSingleMaxCount(val: string) {
  //   Util.limitIntegetInput(
  //     val,
  //     (new_val: string) => {
  //       this.$set(this.forms, "maxLimitCount", new_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "maxLimitCount", new_val);
  //     }
  //   );
  // }

  private sumbitCoachGiftManageLoading: boolean = false;

  // 提交助教礼物配置
  private handleSumbitCoachGiftManage() {
    const storageLockerDialogMode = this.storageLockerDialogMode;

    const params = this.getCoachGiftManageFormsParams(storageLockerDialogMode);

    console.log(params, "params");
    if (params === false) return;

    this.sumbitCoachGiftManageLoading = true;
    switch (storageLockerDialogMode) {
      case "new-coach-gift-manage":
        this.coachGiftManageApi.addCoachGiftManage(
          params,
          () => {
            this.getCoachGiftManageList();
            this.dialogFormVisible = false;
            this.sumbitCoachGiftManageLoading = false;
          },
          () => {
            this.sumbitCoachGiftManageLoading = false;
          }
        );
        break;
      case "edit-coach-gift-manage":
        this.coachGiftManageApi.updateCoachGiftManage(
          params,
          () => {
            this.getCoachGiftManageList();
            this.dialogFormVisible = false;
            this.sumbitCoachGiftManageLoading = false;
          },
          () => {
            this.sumbitCoachGiftManageLoading = false;
          }
        );
        break;
    }
  }

  private getCoachGiftManageFormsParams(mode: string) {
    const params: any = {};

    const showErrorMessage = (msg: string) => {
      if (!!msg) {
        this.$message.error(msg);
      }
    };

    const forms = this.forms;

    const {
      id,
      // name,
      // expired_days,
      // meituanId,
      // timeDuration
      // machine_number,
      // total_money,
      // remark,
      // operate_name,
      images,
      effects,
      type,
      status,
    } = forms || {};

    console.log(forms, "forms");

    Object.assign(params, {
      type,
      status,
    });

    console.log(images, "images");
    console.log(effects, "effects");

    if (!(Array.isArray(images) && images.length == 1)) {
      this.$message.error("请上传礼物图片");

      return false;
    }

    if (images[0].uploading) {
      this.$message.error("正在上传，请稍等或者删除正在上传的图片");

      return false;
    }

    params.image = images[0].fileId;

    if (Array.isArray(effects) && effects.length >= 1) {
      if (effects[0].uploading) {
        this.$message.error("正在上传，请稍等或者删除正在上传的图片");

        return false;
      }

      params.effect = effects[0].fileId;
    }

    const keys = ["name", "panda_coin", "sort"];

    const key2EndKey: any = {
      name: "name",
      panda_coin: "panda_coin",
      sort: "sort",
    };

    const key2ErrMsg: any = {
      name: "礼物名称不可为空",
      panda_coin: "礼物价格不可为空",
      sort: "排序不可为空",
    };

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (forms[key]) {
        const errMsg = key2ErrMsg[`${key}_${forms[key]}`];
        if (errMsg) {
          showErrorMessage(errMsg);

          return false;
        }
        params[key2EndKey[key] || key] = forms[key];
      } else {
        showErrorMessage(key2ErrMsg[key]);

        return false;
      }
    }

    if (mode == "edit-coach-gift-manage") {
      params.id = id;
    }

    return params;
  }

  //获取助教礼物列表
  private getCoachGiftManageList(): void {
    this.coachGiftManageApi.getCoachGiftManageList(
      { ...this.getCoachGiftManageParams() },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page;
        this.tablePage.prepage = res.data.prepage;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private getCoachGiftManageParams(mode = "all"): any {
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    const { name, status } = form;

    if (name) {
      params.name = name;
    }

    if (typeof status == "number" && status !== -1) {
      params.status = status;
    }

    // if (Array.isArray(time) && time.length === 2) {
    //   const [startDate, endDate] = time;

    //   const startTime = Util.dateTimeUtilnohms(startDate);
    //   const endTime = Util.dateTimeUtilnohms(endDate);

    //   params.start_date = startTime;

    //   params.end_date = endTime;
    // }

    if (mode == "exportExcel" && !(params.start_date && params.end_date)) {
      this.$message.error("请选择日期导出数据");
      return null;
    }

    if (mode === "all") {
      params.page = page;

      params.limit = prepage;

      // outerParams.__isUnique = !firstLoading;
    }

    return params;
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  /** 导出excel */
  // private downloadExcelLoading: boolean = false;

  // // 字段名 文案对应关系
  // private merchantExcelList2ch: any = {
  //   name: "店铺名称",
  //   shop_id: " 店铺ID",
  //   cabinet_id: "绑定设备ID",
  //   admin_name: "操作人",
  //   updated_at: "操作时间"
  // };
  // // 商户Excel 表头的先后顺序
  // private merchantExcelSortList: string[] = [
  //   "name",
  //   "shop_id",
  //   "cabinet_id",
  //   "admin_name",
  //   "updated_at"
  // ];

  // // 点击导出excel 表格
  // private handleExportExcelCoachGiftManageList() {
  //   const params = this.getCoachGiftManageParams('exportExcel')

  //   if (params === null) {
  //     return false;
  //   }

  //   this.downloadExcelLoading = true;
  //   this.coachGiftManageApi.exportExcelStoragelockerList(
  //     params,
  //     (res: any) => {
  //       const { start_date, end_date } = params || {};

  //       const filename = `助教礼物管理列表 ${start_date}到${end_date}.xlsx`;
  //       const { data } = res || {};

  //       const {
  //         list: originList
  //       } = data || {}

  //       const newList = Array.isArray(originList)
  //         ? originList.map((item) => {
  //             const {
  //               // status,
  //               // back_goods_detail
  //              } = item || {};
  //             const newItem = {
  //               ...(item || {}),
  //               // __status_text: withdrawAccountAudit2Text[status],
  //               // __back_good_details: back_goods_detail.map((goods: any, index: number) => `${ goods.name }x${ goods.num }  ¥${ goods.price }`).join('; \t')
  //             };

  //             for (let key in item) {
  //               if (typeof item[key] == 'number') {
  //                 newItem[key] = item[key].toString()
  //               }
  //             }

  //             return newItem;
  //           })
  //         : [];

  //       if (newList.length > 0) {
  //         const merchantExcelSortList = this.merchantExcelSortList;

  //         const merchantExcelList2ch = this.merchantExcelList2ch;

  //         const excelJSONData = getExcelJSONDataByList(
  //           newList,
  //           merchantExcelSortList,
  //           merchantExcelList2ch
  //         );

  //         excuteDownloadExport2Excel(excelJSONData, filename);
  //       } else {
  //         this.$message.info("选中时段没有可导出的信息");
  //       }

  //       this.downloadExcelLoading = false;
  //     },
  //     () => {
  //       this.downloadExcelLoading = false;
  //     }
  //   );
  // }

  /** end 导出excel */

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };
}

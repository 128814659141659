/** 注意： meta  key 一旦确定不可修改 且 同级唯一。 是作为识别这个对象的唯一标识！！！   */

// 系统管理
export const systemManageRoutes = [
  // cashierClientUpdaterList
  {
    path: "/cashierClientUpdaterList",
    name: "cashierClientUpdaterList",
    meta: {
      title: "众享收银台客户端版本升级",
      // asideHidden: true, // 侧边栏不显示
      key: 'molis-cashier-client-updater-list',
      pageApisPrm: import("../views/systemManage/cashierClientUpdaterList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "systemManage" */ "../views/systemManage/cashierClientUpdaterList.vue"),
  },
  {
    path: "/cashierRightMenuList",
    name: "cashierRightMenuList",
    meta: {
      title: "收银台菜单管理",
      // asideHidden: true, // 侧边栏不显示
      key: 'cashier-right-menu-list',
      pageApisPrm: import("@/views/systemManage/cashierRightMenuList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "systemManage" */ "@/views/systemManage/cashierRightMenuList.vue"),
  },
  {
    path: "/departmentList",
    name: "departmentList",
    meta: {
      title: "部门管理",
      // asideHidden: true, // 侧边栏不显示
      key: 'department-list',
      pageApisPrm: import("@/views/systemManage/departmentList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "systemManage" */ "@/views/systemManage/departmentList.vue"),
  },
  {
    path: "/rightMenuList",
    name: "rightMenuList",
    meta: {
      title: "菜单管理",
      // asideHidden: true, // 侧边栏不显示
      key: 'right-menu-list',
      pageApisPrm: import("@/views/systemManage/rightMenuList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "systemManage" */ "@/views/systemManage/rightMenuList.vue"),
  },
  // systemUserMember
  {
    path: "/systemUserMember",
    name: "systemUserMember",
    meta: {
      title: "角色管理",
      // asideHidden: true, // 侧边栏不显示
      key: 'system-member-manage',
      pageApisPrm: import("@/views/systemManage/systemUserMember.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "systemManage" */ "@/views/systemManage/systemUserMember.vue"),
  },
  {
    path: "/systemOptionLog",
    name: "systemOptionLog",
    meta: {
      title: "操作日志",
      // asideHidden: true, // 侧边栏不显示
      key: 'system-option-log',
      pageApisPrm: import("@/views/systemManage/systemOptionLog.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "systemManage" */ "@/views/systemManage/systemOptionLog.vue"),
  },
  // pandaBallMerchantMenuList
  {
    path: "/pandaBallMerchantMenuList",
    name: "pandaBallMerchantMenuList",
    meta: {
      title: "熊猫球社商家端菜单配置管理",
      // asideHidden: true, // 侧边栏不显示
      key: 'panda-ball-merchant-menu-list',
      pageApisPrm: import("@/views/systemManage/pandaBallMerchantMenuList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "systemManage" */ "@/views/systemManage/pandaBallMerchantMenuList.vue"),
  },
  // pandaGloryMerchantMenuList
  {
    path: "/pandaGloryMerchantMenuList",
    name: "pandaGloryMerchantMenuList",
    meta: {
      title: "熊猫国粹馆商家端菜单配置管理",
      // asideHidden: true, // 侧边栏不显示
      key: 'panda-glory-merchant-menu-list',
      pageApisPrm: import("@/views/systemManage/pandaGloryMerchantMenuList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "systemManage" */ "@/views/systemManage/pandaGloryMerchantMenuList.vue"),
  },
]


import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class CoachTopicManageAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getCoachTopicManageList', {
      url: '/admin/topic/index',
      name: 'list',
      label: '列表'
    }],
    ['addCoachTopicManage', {
      url: '/admin/topic/add',
      name: 'add-gift',
      label: '添加助教礼物'
    }],
    ['updateCoachTopicManage', {
      url: '/admin/topic/edit',
      name: 'edit-gift',
      label: '编辑助教礼物'
    }],
   
    ['getCoachTopicDetail', {
      url: '/admin/topic/show',
      name: 'gift-detail',
      label: '助教礼物详情'
    }],
   
  ])

  // 助教礼物列表
  public getCoachTopicManageList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getCoachTopicManageList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 修改助教礼物
  public updateCoachTopicManage(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('updateCoachTopicManage').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 添加助教礼物
  public addCoachTopicManage(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('addCoachTopicManage').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 助教礼物详情
  public getCoachTopicDetail(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getCoachTopicDetail').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  
}
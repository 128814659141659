
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList,getPageAPIs } from "@/common/util";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import {SingleCardTransferRecordAPI} from "@/common/api/financeManage/singleCardTransferRecord";

import { CommonApi } from "@/common/api/common";

import ZlSelect from "@/components/third-custom-components/select";

import ZlOption from "@/components/third-custom-components/option";


import { excuteDownloadExport2Excel } from "@/common/xlsx";
export const pageApiList = getPageAPIs(SingleCardTransferRecordAPI);

// import {
//   transferUserLevel2ch
// } from '@/common/config'

const defaultForm = {
  shop_name: "", // 店铺名称
  phone: "", // 手机号

  create_time: [], // 时间

};

const defaultForms = {
  importFiles: [],
  venue_id: "",
  venue: {
    id: "",
    name: "",
  },
}

const defaultSearchVenuePageOption = {
  page: 1,
  limit: 10,
  isRearchBottom: false,
};

// const transfromGenders: any = [
//   '',
//   '女',
//   '男'
// ]


// 状态：0等待导入 1成功导入 2导入失败
const importFileStatus2text: string[] = [
  '等待导入',
  '成功导入',
  '导入失败'
]

@Component({
  filters: {
    showImportFileStatus(status: number) {
      return importFileStatus2text[status] || '未知'
    }
    // showUserMemberTypeName: function(type: number) {
    //   return transferUserLevel2ch[type] || ''
    // }
  },
  components: {
    ZlSelect,
    ZlOption,
  },
})
export default class SingleCardTransferRecord extends Vue {
  private singleCardTransferRecordApi: any;

  private deepLoopUtil: any;

  private utilIns: any;

  // private debounceRefreshSingleCardInfoFunc: any

  private commonApi: any;
  constructor() {
    super();
    this.singleCardTransferRecordApi = new SingleCardTransferRecordAPI();

    this.deepLoopUtil = new DeepLoop();

    this.commonApi = new CommonApi();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    }

    this.utilIns = new Util()

    // this.debounceRefreshSingleCardInfoFunc = this.utilIns.debounce.call(
    //   this,
    //   this.refreshSingleCardAccountInfo,
    //   800
    // )
  }

  private form: any;

  private forms: any;

  private firstLoading: boolean = true;
  // private chooseTechnicianItem : any = null;

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };


  private activated(): void {
    this.getTable();
  }

  private downloadFileUrl: string = ''

  private getTable(): void {
    this.singleCardTransferRecordApi.getSingleCardTranferRecordList(
      this.getSingleCardTranferRecordListParams(),
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        const { list, count, page, prepage, template_url } = data || {};

        const newTableList = Array.isArray(list)
          ? list.map(this.handleTableData)
          : [];

        this.$set(this.tablePage, "totalCount", count);
        this.$set(this.tablePage, "pageNo", page);
        this.$set(this.tablePage, "pageSize", prepage);

        this.downloadFileUrl = template_url || ''

        this.tableData = newTableList;

        this.firstLoading = false;
      },
      () => {
        this.firstLoading = false;
      },
      false
    );
  }

  private getSingleCardTranferRecordListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const firstLoading = this.firstLoading;

    const {
      // shop_name,
      // phone,
      // create_time, // 消费时间
      // finish_time, // 完成时间
    } = form || {};


    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};
    // if (shop_name) {
    //   outerParams.shop_name = shop_name;
    // }

    // if (phone) {
    //   outerParams.phone = phone
    // }

    // if (Array.isArray(create_time) && create_time.length === 2) {
    //   const [startDate, endDate] = create_time;

    //   const startTime = Util.dateTimeUtilnohms(startDate);
    //   const endTime = Util.dateTimeUtilnohms(endDate);

    //   outerParams.start_date = startTime;

    //   outerParams.end_date = endTime;
    // }

    if (
      mode == "exportExcel" &&
      !(outerParams.start_date && outerParams.end_date)
    ) {
      this.$message.error("请选择日期导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      outerParams.__isUnique = !firstLoading;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  private dialogFormVisible: boolean = false

  // // 下载空白文档
  private handleDownloadSingleCardTranferTemplateFexcel() {
    const downloadFileUrl = this.downloadFileUrl

    Util.downloadOtherFlieByNetworkLink(downloadFileUrl)
  }

  // 下载历史上传文档
  private handleDownloadHistorySingleCardRecord(singleCardReocrd: any) {
    const downloadHistoryUrl = singleCardReocrd.file_url

    Util.downloadOtherFlieByNetworkLink(downloadHistoryUrl)
  }

  private handleWakeSingleCardTranferUploadFile() {
    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    }

    console.log(this.forms, 'this.forms')

    this.$nextTick(() => {
      this.dialogFormVisible = true
    })
  }

  private handleUploadCustomFile(file: any, fileList: any[]) {
    this.handleUploadMedia(file, fileList, "file");
  }

  // 删除媒体文件
  private handleFileRemove(file: any, mode: string) {
    const forms = this.forms;

    const optionList = forms.importFiles;

    const existRemoveFileIndex = optionList.findIndex(
      (each_file: any) => each_file.uid == file.uid
    );

    if (existRemoveFileIndex > -1) {
      optionList.splice(existRemoveFileIndex, 1);

      this.$set(this.forms, `importFiles`, optionList);
    }
  }

  private async handleUploadMedia(
    file: any,
    fileList: any[],
    mode: string
  ) {
    console.log(file, "file");

    const forms: any = this.forms;

    const optionList = forms.importFiles;

    const sendMediaApi = this.commonApi.getOssFile

    optionList.push(file);

    console.log(forms, "forms");

    this.$set(this.forms, `importFiles`, optionList);

    this.changeFilePropByList(file, mode, { uploading: true });
    sendMediaApi.call(
      this.commonApi,
      file instanceof File ? file : file.raw,
      (res: any) => {
        console.log(res, "res 文件上传成功");

        const { data } = res;

        const { data: uploadFileObj } = data || {};

        const { item: uploadFile } = uploadFileObj || {};

        const { id, url } = uploadFile;

        // this.$set(this.editUserForm, 'avatar_id', id)
        // this.$set(this.editUserForm, 'avatar', url)
        const changeProps: any = {
          uploading: false,
          fileId: id,
          uploadUrl: url,
        };


        this.changeFilePropByList(file, mode, changeProps);
      },
      () => {
        this.$message.error("文件上传成功");

        this.changeFilePropByList(file, mode, {
          uploading: false,
          failed: true,
        });
      },
      {
        name: file.name
      }
    );
  }

  private changeFilePropByList(file: any, mode: string, changes: any = {}) {
    const forms: any = this.forms;

    const setList: any[] = forms.importFiles;
    const existFileIndex = setList.findIndex(
      (each_file: any) => each_file.uid == file.uid
    );

    if (existFileIndex > -1) {
      const originFile = setList[existFileIndex];

      if (Array.isArray(setList)) {
        setList.splice(existFileIndex, 1, Object.assign(originFile, changes));
      }

      this.$set(this.forms, `importFiles`, setList);
    }
  }

  private wakePreviewFileLoading: boolean = false

  private handleWakePreviewFileDialog() {
    const {
      // venue,
      importFiles
    } = this.forms || {}

    console.log(this.forms, 'this.forms')
    console.log(importFiles, 'importFiles')

    const [file] = importFiles || []

    if (!(file && file.fileId)) {
      this.$message.error('请上传文件')
      return 
    }

    this.wakePreviewFileLoading = true

    this.singleCardTransferRecordApi.getPreviewFileTable({
      file_id: file.fileId
    }, (res: any) => {

      const {
        header,
        list
      } = res.data || {}

      this.previewTableCols = header

      this.previewTableData = list

      this.dialogFormVisible = false

      this.dialogPreviewFileVisible = true

      this.wakePreviewFileLoading = false
    }, () => {
      this.wakePreviewFileLoading = false
    })
  }

  // 拉起预览文件弹框
  private dialogPreviewFileVisible: boolean = false

  private previewTableCols: any[] = []

  private previewTableData: any[] = [];


  // end 拉起预览文件弹框


  private sumbitCustomSingleCardAccountLoading: boolean = false
  // // 提交修改
  private handleSumbitCustomSingleCardAccount () {
    const {
      venue,
      importFiles
    } = this.forms || {}

    const [file] = importFiles || []


    if (!(venue && venue.id)) {
      this.$message.error('请选择店铺')
      return 
    }

    if (!(file && file.fileId)) {
      this.$message.error('请上传文件')
      return 
    }


    this.sumbitCustomSingleCardAccountLoading = true

    this.singleCardTransferRecordApi.importFileInfo({
      file_id: file.fileId,
      shop_id: venue.id,
    }, () => {

      this.sumbitCustomSingleCardAccountLoading = false
      this.dialogPreviewFileVisible = false

      this.getTable()
    }, () => {
      this.sumbitCustomSingleCardAccountLoading = false
    })
  }

  private curSingleCardInfo: any = {}

  private singleCardInfoLoading: boolean = false


  // 后台返回的字段 转化为前端定义的字段
  private handleTableData(data: any): any {
    // const { gender } = data;
    const newData = {
      ...data,
      // device_name: orderDeviceType2ch[device],
      // pay_type_name: payType2ch[pay_type],
      // user_level_type_name: userLevel2ch[user_type],
      // gender_sex: transfromGenders[gender]
    };

    return newData;
  }

  /** 远程搜索店铺 */
  private venueList: any[] = [
    // {
    //   id: 1,
    //   label: '测试'
    // },
    // {
    //   id: 2,
    //   label: '测试2'
    // }
  ];

  private searchVenueLoading: boolean = false;

  private remoteGetVenueQueryString: string = "";

  private remoteSearchVenueTimer: any = null;

  private searchVenuePageOption: any = {};

  private initRemoteGetVenuePageOption() {
    this.searchVenuePageOption = {
      ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    };
  }

  private getSearchVenueParams() {
    const searchVenuePageOption = this.searchVenuePageOption;

    const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

    const { page, limit } = searchVenuePageOption;

    const params: any = {
      page,
      limit,
    };

    if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
      params.name = remoteGetVenueQueryString.trim();
    }

    return params;
  }

  private getMoreVenueList(refresh: boolean = true) {
    return new Promise((resolve: Function, reject: Function) => {
      if (refresh) {
        this.initRemoteGetVenuePageOption();
      }

      const params = this.getSearchVenueParams();

      this.singleCardTransferRecordApi.searchVenueList(
        params,
        (res: any) => {
          console.log(res, "res");

          const venueList: any[] = res.data || [];

          this.venueList = refresh
            ? venueList
            : this.venueList.concat(venueList);

          if (Array.isArray(res.data) && res.data.length == 0) {
            this.searchVenuePageOption.isRearchBottom = true;
          }
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          resolve();
        },
        () => {
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          reject();
        }
      );
    });
  }

  // 第一次搜索 无关键字
  private handleFirstGetVenueList() {
    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 清空搜索
  private handleClearGetVenueList() {
    this.remoteGetVenueQueryString = "";

    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 远程搜索
  private remoteGetVenueMethod(query: string) {
    query = query.trim ? query.trim() : "";

    if (query !== "") {
      this.remoteGetVenueQueryString = query;
      clearTimeout(this.remoteSearchVenueTimer);

      this.remoteSearchVenueTimer = setTimeout(() => {
        this.searchVenueLoading = true;

        this.getMoreVenueList();
        // searchStu((res) => {
        //   const {
        //     students,
        //     total_count
        //   } = res || {}

        //   this.remoteAllStudents = [
        //     ...(students || [])
        //   ]

        //   this.remoteStudentTotalCount = total_count || 0

        //   this.remoteGetStudentLoading = false
        // }, {
        //   kw: query,
        //   ...this.getRemoteGetStudentPageOptionParams()
        // }, () => {
        //   this.remoteGetStudentLoading = false
        // })
      }, 300);
      // setTimeout(() => {
      //   this.remoteGetStudentLoading = false;
      //   this.options = this.list
      // }, 200);
    } else {
      this.remoteGetVenueQueryString = "";
      this.venueList = [];
    }
  }

  private addVenueListLoading: boolean = false;

  private remoteAddVenueMethod() {
    if (this.addVenueListLoading) return;
    const searchVenuePageOption = this.searchVenuePageOption;

    const { isRearchBottom } = searchVenuePageOption;

    if (isRearchBottom) return;

    this.searchVenuePageOption.page++;

    this.addVenueListLoading = true;

    this.getMoreVenueList(false);
  }
  /** end 远程搜索店铺 */
  

  /** 导出excel */
  // private downloadExcelLoading: boolean = false;

  // // 订单字段名 文案对应关系
  // private orderExcelList2ch: any = {
  //   "phone": "手机号",
  //   "user_name": "用户昵称",
  //   "shop_name": "店铺",
  //   "decr_price": "扣款金额",
  //   "remark": "扣款原因",
  //   "admin_name": "操作人",
  //   "created_at": "操作时间"
  // };
  // // 订单Excel 表头的先后顺序
  // private orderExcelSortList: string[] = [
  //   "phone",
  //   "user_name",
  //   "shop_name",
  //   "decr_price",
  //   "remark",
  //   "admin_name",
  //   "created_at"
  // ];

  // // 点击导出excel 表格
  // private handleDownloadExcel() {
  //   const params = this.getSingleCardTranferRecordListParams("exportExcel");

  //   if (params === null) {
  //     return false;
  //   }

  //   this.downloadExcelLoading = true;
  //   this.singleCardTransferRecordApi.exportExcelSingleCardAccountCheckList(
  //     params,
  //     (res: any) => {
  //       const {
  //         start_date,
  //         end_date,
  //         // over_start_time,
  //         // over_end_time,
  //       } = params || {};

  //       const create_time_duration_name =
  //         start_date && end_date
  //           ? `时间${start_date}到${end_date}`
  //           : "";

  //       // const finish_time_duration_name =
  //       //   over_start_time && over_end_time
  //       //     ? `完成时间${over_start_time}到${over_end_time}`
  //       //     : "";
  //       const filename = `单店会员余额扣款记录列表 ${create_time_duration_name}.xlsx`;
  //       const { data } = res || {};

  //       const { list: originList } = data || {};

  //       const newList = Array.isArray(originList)
  //         ? originList.map((item) => {
  //             const {
  //               // type,
           
  //             } = item || {};

  //             const newItem = {
  //               ...(item || {}),
              
  //               // memberTypeName: transferUserLevel2ch[type]
  //             };

  //             return newItem;
  //           })
  //         : [];

  //       if (newList.length > 0) {
  //         const orderExcelSortList = this.orderExcelSortList;

  //         const orderExcelList2ch = this.orderExcelList2ch;

  //         const excelJSONData = getExcelJSONDataByList(
  //           newList,
  //           orderExcelSortList,
  //           orderExcelList2ch
  //         );


  //         excuteDownloadExport2Excel(excelJSONData, filename);
  //       } else {
  //         this.$message.info("选中时段没有可导出的信息");
  //       }

  //       this.downloadExcelLoading = false;
  //     },
  //     () => {
  //       this.downloadExcelLoading = false;
  //     }
  //   );
  // }

  /** end 导出excel */

  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}

import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class UserGradeAduitManageAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getList', {
            url: '/admin/userGrade/index',
            name: 'list',
            label: '列表'
        }],
        ['updateUserGradeAuditPassOrRejectStatus',  {
            url: '/admin/userGrade/audit',
            name: 'pass-or-reject-status',
            label: '审核通过/拒绝'
        }]
    ])

    // momo达到家-助教基本信息审核列表
    public getList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    // 处理问题状态更新
    public updateUserGradeAuditPassOrRejectStatus(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        // 审核状态 1通过 2拒绝
        const mode2Status: any = {
            'agree': 1,
            'disagree': 2
        }

        let url: string = apiUrls.get('updateUserGradeAuditPassOrRejectStatus').url

        params.level_approve_status = mode2Status[params.mode]


        delete params.mode


        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading);
    }

}
var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"panda-chat-talk-dialog-container"},[_c('gallery',{attrs:{"images":_vm.previewImages}}),_c('div',{ref:"chatScrollContainer",class:[
      'chat-dialog-content-wrap',
      _vm.hiddenInput ? 'only-content-dialog-wrap' : '',
    ],on:{"scroll":_vm.onChatScroll}},[_c('div',{staticClass:"chat-content-wrap"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.scrollReachTopStatus == 'loading'),expression:"scrollReachTopStatus == 'loading'"}],class:['chat-more-loading-wrap', 'flex-row-center--center']},[_c('i',{staticClass:"el-icon-loading"})]),_c('div',{staticClass:"chat-list-wrap"},_vm._l((_vm.chatList),function(chatItem,index){return _c('div',{key:chatItem.id,staticClass:"chat-item"},[_c('chat-message-item',{attrs:{"type":chatItem.content.type,"is-self":chatItem.from_type == 2,"avatar":chatItem.from_avatar,"chat":chatItem,"prev-content":index == 0 ? null : _vm.chatList[index - 1].content,"content":chatItem.content},on:{"preview-media":_vm.onPreviewMedia}})],1)}),0),_c('div',{staticClass:"chat-bottom-ph",staticStyle:{"height":"35px"}})])]),_c('div',{class:['chat-input-wrap', _vm.hiddenInput ? 'hidden-chat-input-wrap' : '']},[_c('div',{staticClass:"input-other-wrap flex-row-center--left"},[_c('div',{staticClass:"input-other-item"},[_c('el-popover',{attrs:{"placement":"top-start","width":"343","trigger":"click","disabled":_vm.disabledEmoji,"popper-class":"chat-emoji-pop-picker"}},[_c('emoji-picker',{attrs:{"include":['people', 'Smileys'],"showSearch":false,"showPreview":false,"showCategories":false,"title":""},on:{"select":_vm.insertEmoji}}),_c('el-image',{class:[
              'input-other-icon',
              'emoji-icon',
              _vm.disabledEmoji ? 'disabled-input-other-icon' : '',
            ],attrs:{"slot":"reference","src":require('@/assets/imService/emoji-icon.png')},slot:"reference"})],1)],1),_c('div',{staticClass:"input-other-item"},[_c('el-upload',{staticClass:"upload-media",attrs:{"action":"#","accept":"image/*,video/mp4","show-file-list":false,"auto-upload":false,"disabled":_vm.disabledMedia,"on-change":_vm.chooseMedia}},[_c('el-image',{class:[
              'input-other-icon',
              'media-icon',
              _vm.disabledMedia ? 'disabled-input-other-icon' : '',
            ],attrs:{"src":require('@/assets/imService/upload-media-icon.png')}})],1)],1)]),_c('div',{staticClass:"bottom-chat-input-wrap"},[_c('el-input',{ref:"pandaChatElTextarea",staticClass:"chat-textarea-input",attrs:{"type":"textarea","disabled":_vm.disabledTextarea,"autosize":{
          minRows: 4,
          maxRows: 4,
        },"resize":"none","placeholder":"请输入消息"},nativeOn:{"keydown":function($event){return _vm.handleSumbitTextMessage.apply(null, arguments)}},model:{value:(_vm.textarea),callback:function ($$v) {_vm.textarea=$$v},expression:"textarea"}}),_c('div',{staticClass:"bottom-chat-send-btn-wrap flex-row-center--right"},[_c('p',{staticClass:"send-tip"},[_vm._v("Enter 发送，Ctrl + Enter 换行")]),_c('el-button',{attrs:{"disabled":!_vm.textarea.trim(),"type":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.handleConfirmSumbitTextMsg.apply(null, arguments)}}},[_vm._v("发送")])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
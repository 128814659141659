
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getPageAPIs } from "@/common/util";
// import { CouponsManageAPI } from "@/common/api/couponList";
import SaleMachineApi from '@/common/api/operationManage/saleMachineList';

import { DeepLoop } from "@/common/deepLoop/deepLoop";
import ZlSelect from "@/components/third-custom-components/select";

import ZlOption from "@/components/third-custom-components/option";

import {
  shareConfirmOptions
} from '@/common/config'

export const pageApiList = getPageAPIs(SaleMachineApi)

const defaultForms = {
  machine_number: '', // 设备编码
  fencheng_rate: '', // 分成比例
  venue_id: "",
  venue: {
    id: "",
    name: "",
  },
  // singleMaxCountType: '0',
  // shop_id: "",
  // maxLimitCount: ''
};

const defaultSearchVenuePageOption = {
  page: 1,
  limit: 10,
  isRearchBottom: false,
};

const saleMachineDialogTitle2ch: any = {
  "new-sale-machine": "新增售卖机",
  "edit-sale-machine": "编辑售卖机",
};

// 最大有效期
// const maxExpiredDays = 9999;

// 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;

const maxBenefitRate = 100

@Component({
  components: {
    ZlSelect,
    ZlOption,
  },
})
export default class SaleMachinesManage extends Vue {
  private form: any = {
    // saleMachineId: "",
    shop_name: "",
    shop_id: '',
    machine_number: '',
    // time: [],
    // startDate: "",
    // endDate: "",
  };
  private forms: any = {
    // maxType: "0",
    // ...defaultForms
  };

  private deepLoopUtil: any = {};

  // private merchantApi: any;

  private saleMachineApi = new SaleMachineApi();

  // private saleMachineTimeDurationAPI = new SaleMachineTimeDurationManageAPI();
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    this.searchVenuePageOption = {
      ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    };

    // this.merchantApi = new MerchantApi();
  }

  
  private created(): void {
    this.getSaleMachines();

    // this.refreshSaleMachineTimeDurations();

    // this.refreshTableClass();
  }
  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getSaleMachines();
  }
  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getSaleMachines();
  }

  private tableData: any[] = [];

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";
  
  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getSaleMachines();
  }

  private addOrUpdateSaleMachineTitle: string = "售卖机";

  private dialogFormVisible: boolean = false;

  // 拉起售卖机弹框
  private handleWakeSaleMachineDialog(mode: string) {
    // if (mode == 'new-sale-machine') { // 新增售卖机

    // }
    this.saleMachineDialogMode = mode;
    this.addOrUpdateSaleMachineTitle = saleMachineDialogTitle2ch[mode] || "售卖机";

    this.initSaleMachineForm(mode);

    this.dialogFormVisible = true;
  }

  private currentSaleMachineItem: any = null;

  private saleMachineDialogMode: string = "";

  private async initSaleMachineForm(mode: string) {
    const forms: any = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    if (mode == "edit-sale-machine") {
      // 编辑
      await this.getMoreVenueList(true);

      // await this.refreshSaleMachineTimeDurations();

      // await this.refreshTableClass();

      const currentSaleMachineItem = this.currentSaleMachineItem;

      const {
        id,
        machine_number,
        fencheng_rate,
        shop_name,
        shop_id,
       
      } = currentSaleMachineItem || {};

      if (machine_number) {
        forms.machine_number = machine_number;
      }

      if (typeof fencheng_rate == "number") {
        forms.fencheng_rate = fencheng_rate.toString();
      }


      if (shop_name && typeof shop_id == "number") {
        console.log(shop_id, "shop_id");

        console.log(this.venueList, "this.venueList");
        let existVenue = this.venueList.find((venue) => venue.id == shop_id);

        if (!existVenue) {
          existVenue = {
            id: shop_id,
            name: shop_name,
          };
          this.venueList.push(existVenue);
        }

        forms.venue = existVenue;
      }

      forms.id = id;
    }

    console.log(forms, "forms");

    console.log(this.venueList, "this.venueList");

    this.forms = forms;
  }

  private handleWakeEditSaleMachineDialog(saleMachineItem: any) {
    this.currentSaleMachineItem = saleMachineItem;

    this.handleWakeSaleMachineDialog("edit-sale-machine");
  }

  private handleDelteSaleMachineDialog(saleMachineItem: any) {
    this.$confirm("确定删除该售卖机?", "", {
      ...shareConfirmOptions,
    }).then(() => {
      this.saleMachineApi.deleteSaleMachine({
        id: saleMachineItem.id
      }, () => {
        this.getSaleMachines()
      }, () => {

      })
    });
  }

  private venueList: any[] = [
    // {
    //   id: 1,
    //   label: '测试'
    // },
    // {
    //   id: 2,
    //   label: '测试2'
    // }
  ];

  private searchVenueLoading: boolean = false;

  private remoteGetVenueQueryString: string = "";

  private remoteSearchVenueTimer: any = null;

  private searchVenuePageOption: any = {};

  private initRemoteGetVenuePageOption() {
    this.searchVenuePageOption = {
      ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    };
  }

  private getSearchVenueParams() {
    const searchVenuePageOption = this.searchVenuePageOption;

    const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

    const { page, limit } = searchVenuePageOption;

    const params: any = {
      page,
      limit,
    };

    if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
      params.name = remoteGetVenueQueryString.trim();
    }

    return params;
  }

  private getMoreVenueList(refresh: boolean = true) {
    return new Promise((resolve: Function, reject: Function) => {
      if (refresh) {
        this.initRemoteGetVenuePageOption();
      }

      const params = this.getSearchVenueParams();

      this.saleMachineApi.searchVenueList(
        params,
        (res: any) => {
          console.log(res, "res");

          const venueList: any[] = res.data || [];

          this.venueList = refresh
            ? venueList
            : this.venueList.concat(venueList);

          if (Array.isArray(res.data) && res.data.length == 0) {
            this.searchVenuePageOption.isRearchBottom = true;
          }
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          resolve();
        },
        () => {
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          reject();
        }
      );
    });
  }

  // 第一次搜索 无关键字
  private handleFirstGetVenueList() {
    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 清空搜索
  private handleClearGetVenueList() {
    this.remoteGetVenueQueryString = "";

    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 远程搜索
  private remoteGetVenueMethod(query: string) {
    query = query.trim ? query.trim() : "";

    if (query !== "") {
      this.remoteGetVenueQueryString = query;
      clearTimeout(this.remoteSearchVenueTimer);

      this.remoteSearchVenueTimer = setTimeout(() => {
        this.searchVenueLoading = true;

        this.getMoreVenueList();
        
      }, 300);
    } else {
      this.remoteGetVenueQueryString = "";
      this.venueList = [];
    }
  }

  private addVenueListLoading: boolean = false;

  private remoteAddVenueMethod() {
    if (this.addVenueListLoading) return;
    const searchVenuePageOption = this.searchVenuePageOption;

    const { isRearchBottom } = searchVenuePageOption;

    if (isRearchBottom) return;

    this.searchVenuePageOption.page++;

    this.addVenueListLoading = true;

    this.getMoreVenueList(false);
  }

  // 分成比例
  private handleLimitBenefitRate(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        const limit_val = Math.min(Number(new_val), maxBenefitRate).toString();
        this.$set(this.forms, "fencheng_rate", limit_val);
      },
      (new_val: string) => {
        this.$set(this.forms, "fencheng_rate", new_val);
      }
    );
  }

  private handleLimitSingleMaxCount(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        this.$set(this.forms, "maxLimitCount", new_val);
      },
      (new_val: string) => {
        this.$set(this.forms, "maxLimitCount", new_val);
      }
    );
  }

  private sumbitSaleMachineLoading: boolean = false;

  // 提交售卖机配置
  private handleSumbitSaleMachine() {
    const saleMachineDialogMode = this.saleMachineDialogMode;

    const params = this.getSaleMachineFormsParams(saleMachineDialogMode);

    console.log(params, "params");
    if (params === false) return;

    this.sumbitSaleMachineLoading = true;
    switch (saleMachineDialogMode) {
      case "new-sale-machine":
        this.saleMachineApi.addSaleMachine(
          params,
          () => {
            this.getSaleMachines();
            this.dialogFormVisible = false;
            this.sumbitSaleMachineLoading = false;
          },
          () => {
            this.sumbitSaleMachineLoading = false;
          }
        );
        break;
      case "edit-sale-machine":
        this.saleMachineApi.updateSaleMachine(
          params,
          () => {
            this.getSaleMachines();
            this.dialogFormVisible = false;
            this.sumbitSaleMachineLoading = false;
          },
          () => {
            this.sumbitSaleMachineLoading = false;
          }
        );
        break;
    }
  }

  private getSaleMachineFormsParams(mode: string) {
    const params: any = {};

    const showErrorMessage = (msg: string) => {
      if (!!msg) {
        this.$message.error(msg);
      }
    };

    const forms = this.forms;

    const {
      id,
      // name,
      // expired_days,
      // meituanId,
      // timeDuration
      // machine_number,
      fencheng_rate,
      venue,
    } = forms || {};

    if (venue && venue.id) {
      params.shop_id = venue.id;
    } else {
      showErrorMessage("请选择一个店铺");
      return false;
    }

    const keys = ["machine_number", "fencheng_rate"];

    const key2EndKey: any = {
      machine_number: "machine_number",
      fencheng_rate: "fencheng_rate",
    };

    const key2ErrMsg: any = {
      machine_number: "设备编码不能为空",
      fencheng_rate: "分成比例不能为空",
    };

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (forms[key]) {
        const errMsg = key2ErrMsg[`${key}_${forms[key]}`];
        if (errMsg) {
          showErrorMessage(errMsg);

          return false;
        }
        params[key2EndKey[key] || key] = forms[key];
      } else {
        showErrorMessage(key2ErrMsg[key]);

        return false;
      }
    }

    if (fencheng_rate) {
      params.fencheng_rate = Number(fencheng_rate);
    }

    

    if (mode == "edit-sale-machine") {
      params.id = id;
    }

    return params;

  }

  //获取售货机列表
  private getSaleMachines(): void {
    this.saleMachineApi.getSaleMachineList(
      { ...this.getSaleMachineParams() },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page;
        this.tablePage.prepage = res.data.prepage;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private getSaleMachineParams(): any {
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    const { shop_name, shop_id, machine_number } = form;

    if (shop_name) {
      params.shop_name = shop_name;
    }

    if (shop_id) {
      params.shop_id = shop_id
    }

    if (machine_number) {
      params.machine_number = machine_number
    }

    if (page) {
      params.page = page;
    }

    if (prepage) {
      params.limit = prepage;
    }

    return params;
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };
}

import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class LeagueCourserDetailListAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getLeagueCourserList', {
        url: '/admin/league_courser/enter',
        name: 'list',
        label: '列表'
    }],
    
])

  // 单个选手 参赛列表
  public getLeagueCourserList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getLeagueCourserList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  

}
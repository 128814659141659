import { BasicsClass } from '@/common/BasicsClass';

export class OperationalServiceRatingAPI extends BasicsClass {

    public apiUrls: any = new Map([
        ['list', {
            url: '/admin/praise/list',
            name: 'list',
            label: '列表'
        }],
        ['export', {
            url: '/admin/praise/exportList',
            name: 'export',
            label: '导出列表'
        }],
        ['userAll', {
            url: '/admin/praise/userAll',
            name: 'userAll',
            label: '运营人员下拉选择'
        }],
        ['searchVenueList', {
            url: '/admin/coupons/shop/list',
            name: 'shop-list',
            label: '店铺列表',
        }],

    ])
    public getList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {

        params = {
            ...(params || {})
        }

        const apiUrls = this.apiUrls

        const url = apiUrls.get('list').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    public exportList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('export').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    public getOperationalUser(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('userAll').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('searchVenueList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

}

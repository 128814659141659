import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class UserApi extends BasicsClass {
    public apiUrls: any = new Map([
        // ['forbideenUserAccount', {
        //     url: '/user/forbid',
        //     name: 'account-forbid',
        //     label: '账户封禁'
        // }],
        ['getUserTableList', {
            url: '/admin/user/list',
            name: 'list',
            label: '列表'
        }],
        ['getUserDetail', {
            url: '/admin/user/show',
            name: 'detail',
            label: '详情',
            // hidden: true
        }],
        ['editUserInfo', {
            url: '/admin/user/edit',
            name: 'edit',
            label: '编辑'
        }],
        ['exportExcelUserList', {
            url: '/admin/user/userExport',
            name: 'export-excel',
            label: '导出'
        }]
    ])


    // 用户--封禁用户
    // public forbideenUserAccount(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    //   const apiUrls = this.apiUrls

    //   const url = apiUrls.get('forbideenUserAccount').url

    //   this.BasicPost(url, {
    //       ...params
    //   }, false, false, false, successCb, failCb, showLoading)
    // }


    // // 用户列表
    public getUserTableList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getUserTableList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 用户详情
    public getUserDetail(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getUserDetail').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 用户--修改用户
    public editUserInfo(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('editUserInfo').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 导出用户列表
    public exportExcelUserList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('exportExcelUserList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

}

import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getPageAPIs } from "@/common/util";
import { CompetitionLiveAPI } from "@/common/api/competitionManage/competitionLiveList";

import { CommonApi } from "@/common/api/common";

import gallery from "@/components/gallery.vue";

// import MerchantApi from "@/common/api/merchant";
// import { util } from "vue/types/umd";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
// import ZlSelect from "@/components/third-custom-components/select";

// import ZlOption from "@/components/third-custom-components/option";

import {
  shareConfirmOptions,
  // getCommonList
} from "@/common/config";
export const pageApiList = getPageAPIs(CompetitionLiveAPI);



const defaultForms = {
  study_picture: [],
  live_title: "", // 标题
 
  sort: "", // 排序
  show_status: 1, // 上架状态：0下架 1上架
  // singleMaxCountType: '0',
  // shop_id: "",
  // maxLimitCount: ''
};

// const defaultSearchVenuePageOption = {
//   page: 1,
//   limit: 10,
//   isRearchBottom: false,
// };

const competitionPointDialogTitle2ch: any = {
  "new-competition-live": "新增赛事直播",
  "edit-competition-live": "编辑赛事直播",
};

function createPicItem(id: number, url: string) {
  const item = {
    id,
    url,
    href: url,
    type: "image/png",
    types: "1",
  };

  return item;
}

function createVideoItem(id: number, url: string) {
  const item = {
    id,
    url,
    href: url,
    type: "video/mp4",
    types: "2",
    poster: `${url}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`,
  };

  return item;
}

// 最大有效期
// const maxExpiredDays = 9999;

// 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;

// const maxBenefitRate = 100


// 上架状态：0下架 1上架
const allStudyStatus2ch: string[] = ["下架", "上架"];

@Component({
  filters: {
    showStudyStatusText(status: any) {
      return allStudyStatus2ch[status] || "未知";
    },
  },
  components: {
    gallery,
    // ZlSelect,
    // ZlOption,
  },
})
export default class CompetitionLivesManage extends Vue {
  private form: any = {
    // competitionPointId: "",
    live_title: "", // 标题 
    // shop_id: '',
    // time: [],
    // startDate: "",
    // endDate: "",
  };
  private forms: any = {
    // maxType: "0",
    // ...defaultForms
  };

  private deepLoopUtil: any = {};

  // private merchantApi: any;

  private competitionLiveApi = new CompetitionLiveAPI();



  private commonApi = new CommonApi();

  // private couponsManageAPI = new CouponsManageAPI();
  // private competitionPointTimeDurationAPI = new CompetitionLiveTimeDurationManageAPI();
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    // this.chooseShopTypeList = [
    //   ...this.deepLoopUtil.deepCopy(defaultChooseShopTypeList),
    // ]

    // this.chooseCategoryList = [
    //   ...this.deepLoopUtil.deepCopy(defaultCategoryList),
    // ];

    // this.searchVenuePageOption = {
    //   ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    // };

    // this.merchantApi = new MerchantApi();
  }

  private created(): void {
    this.getCompetitionLives();

    // this.refreshCompetitionLiveTimeDurations();

    // this.refreshTableClass();
  }
  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getCompetitionLives();
  }
  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getCompetitionLives();
  }

  private tableData: any[] = [];

  private handleGoLivePlayerSetting(competitionLiveItem: any) {
    this.$router.push({
      path: '/competitionLiveSettingList',
      query: {
        llid: competitionLiveItem.id
      }
    })
  }

  private handleChangeUploadPic(file: any, fileList: any[]) {
    console.log(file, fileList, "file", "fileList");

    this.commonApi.getOss(
      file instanceof File ? file : file.raw,
      (res: any) => {
        console.log(res, "res 图片上传成功");

        const { data } = res;

        const { data: uploadImgObjWrap } = data;

        const { item: uploadImgObj } = uploadImgObjWrap;

        const { id, url } = uploadImgObj;

        this.forms.study_picture.push(createPicItem(id, url));
      },
      () => {
        this.$message.error("图片上传失败");
      }
    );
  }

  private handleRemoveStudyPicture(file: any) {
    const forms = this.forms || {};
    const existIndex = forms.study_picture.findIndex(
      (picFile: any) => picFile == file
    );

    if (existIndex > -1) {
      forms.study_picture.splice(existIndex, 1);
    }
  }
  

  private images: any = [];

  private handleBeforePreviewPicture(row: any) {
    const forms = this.forms

    const {
      study_picture
    } = forms || {}

    const previewItem: any = {
      images: study_picture.map((pic: any) => pic.url),
      image_count: study_picture.length
    }

    this.handlePreviewMedia(previewItem)
  }

  private handlePreviewMedia(row: any) {
    const { image_count, images, video_count, videos } = row || {};

    const urls: any[] = [];

    if (image_count > 0) {
      urls.push(
        ...images.map((img: string) => {
          return {
            title: "",
            type: "image/jpeg",
            href: img,
            thumbnail: img,
          };
        })
      );
    }

    if (video_count > 0) {
      urls.push(
        ...videos.map((video: string) => {
          return {
            title: "",
            type: "video/mp4",
            href: video,
            poster: `${video}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`,
          };
        })
      );
    }

    console.log(urls, 'urls')

    this.images = urls;
  }

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";

  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getCompetitionLives();
  }

  private addOrUpdateCompetitionLiveTitle: string = "排名信息";

  private dialogFormVisible: boolean = false;


  // 拉起排名信息弹框
  private handleWakeCompetitionLiveDialog(mode: string) {
    // if (mode == 'new-competition-live') { // 新增排名信息

    // }
    this.competitionPointDialogMode = mode;
    this.addOrUpdateCompetitionLiveTitle =
      competitionPointDialogTitle2ch[mode] || "排名信息";

    this.initCompetitionLiveForm(mode);

    this.dialogFormVisible = true;
  }

  private currentCompetitionLiveItem: any = null;

  private competitionPointDialogMode: string = "";

  private async initCompetitionLiveForm(mode: string) {
    const forms: any = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    if (mode == "edit-competition-live") {
      const currentCompetitionLiveItem = this.currentCompetitionLiveItem;

      const {
        id,
        live_title,
        cover_image_id,
        cover_image_url,

        sort,
        show_status,
      } = currentCompetitionLiveItem || {};

      console.log(currentCompetitionLiveItem, 'currentCompetitionLiveItem')

      Object.assign(forms, {
        live_title,
        sort,
        show_status,
      });

      if (cover_image_id && cover_image_url) {
        forms.study_picture = [
          {
            ...createPicItem(cover_image_id, cover_image_url)
          }
        ]
      }

      // switch (media_type) {
      //   case 0: // 图片
      //     forms.study_picture = media_list.map((item: any) => {
      //       const { url, id } = item || {};

      //       return createPicItem(id, url);
      //     });
      //     break;
      //   case 1: // 视频
      //     forms.study_video = media_list.map((item: any) => {
      //       const { url, id } = item || {};

      //       return createVideoItem(id, url);
      //     });
      //     break;
      // }

      forms.league_live_id = id;
    }

    console.log(forms, "forms");

    this.forms = forms;
  }

  private handleWakeEditCompetitionLiveDialog(competitionPointItem: any) {
    this.currentCompetitionLiveItem = competitionPointItem;

    this.handleWakeCompetitionLiveDialog("edit-competition-live");
  }

  private handleDelteCompetitionLiveDialog(competitionPointItem: any) {
    this.$confirm("确定删除该赛事直播?", "", {
      ...shareConfirmOptions,
    }).then(() => {
      this.competitionLiveApi.deleteCompetitionLive(
        {
          league_live_id: competitionPointItem.id,
        },
        () => {
          this.getCompetitionLives();
        },
        () => {}
      );
    });
  }

  // 排序
  private handleLimitUserSort(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        // const limit_val = Math.min(Number(new_val), maxBenefitRate).toString();
        this.$set(this.forms, "sort", new_val);
      },
      (new_val: string) => {
        this.$set(this.forms, "sort", new_val);
      }
    );
  }

  private sumbitCompetitionLiveLoading: boolean = false;

  // 提交赛事直播配置
  private handleSumbitCompetitionLive() {
    const competitionPointDialogMode = this.competitionPointDialogMode;

    const params = this.getCompetitionLiveFormsParams(
      competitionPointDialogMode
    );

    console.log(params, "params");
    if (params === false) return;

    this.sumbitCompetitionLiveLoading = true;
    switch (competitionPointDialogMode) {
      case "new-competition-live":
        this.competitionLiveApi.addCompetitionLive(
          params,
          () => {
            this.getCompetitionLives();
            this.dialogFormVisible = false;
            this.sumbitCompetitionLiveLoading = false;
          },
          () => {
            this.sumbitCompetitionLiveLoading = false;
          }
        );
        break;
      case "edit-competition-live":
        this.competitionLiveApi.updateCompetitionLive(
          params,
          () => {
            this.getCompetitionLives();
            this.dialogFormVisible = false;
            this.sumbitCompetitionLiveLoading = false;
          },
          () => {
            this.sumbitCompetitionLiveLoading = false;
          }
        );
        break;
    }
  }

  private getCompetitionLiveFormsParams(mode: string) {
    const params: any = {};

    const showErrorMessage = (msg: string) => {
      if (!!msg) {
        this.$message.error(msg);
      }
    };

    const forms = this.forms;

    const {
      league_live_id,
      // name,
      // expired_days,
      // meituanId,
      // timeDuration
      // machine_number,
      // phone,
      // rank

   
      sort,
      show_status,

      study_picture,
    } = forms || {};

    const keys = ["live_title"];

    const key2EndKey: any = {
      live_title: "live_title",
   
    };

    const key2ErrMsg: any = {
      title: "直播标题不能为空",
    };

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (forms[key]) {
        const errMsg = key2ErrMsg[`${key}_${forms[key]}`];
        if (errMsg) {
          showErrorMessage(errMsg);

          return false;
        }
        params[key2EndKey[key] || key] = forms[key];
      } else {
        showErrorMessage(key2ErrMsg[key]);

        return false;
      }
    }

    Object.assign(params, {
      sort,
      show_status,
    });

    console.log(study_picture, "study_picture");

    if (study_picture && study_picture.length > 0) {
      params.cover_image_id = study_picture[0].id
    } else {
      this.$message.error('请上传封面图')
      return false
    }
 
    if (mode == "edit-competition-live") {
      params.league_live_id = league_live_id;
    }

    return params;
  }

  //获取赛事直播列表
  private getCompetitionLives(): void {
    this.competitionLiveApi.getCompetitionLiveList(
      { ...this.getCompetitionLiveParams() },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page;
        this.tablePage.prepage = res.data.prepage;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private getCompetitionLiveParams(): any {
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    const { live_title } = form;

    if (live_title) {
      params.live_title = live_title;
    }

    // if (shop_type) {
    //   params.shop_type = shop_type
    // }

    if (page) {
      params.page = page;
    }

    if (prepage) {
      params.limit = prepage;
    }

    return params;
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };
}


import dayjs from "@/plugins/dayjs";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getPageAPIs } from "@/common/util";
import { ShopIncomeChartAPI } from "@/common/api/dataWholeBoard/shopIncomeChart";

import PandaMobileTWFContainer from "@/components/mobile-custom/panda-table-with-filter-container.vue";

// import { util } from "vue/types/umd";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import ZlSelect from "@/components/third-custom-components/select";

import ZlOption from "@/components/third-custom-components/option";

import {
  shareConfirmOptions,
  shareShopType2ch
} from '@/common/config'

// 暴露的API 列表
export const pageApiList = getPageAPIs(ShopIncomeChartAPI);

console.log(pageApiList, 'pageApiList')

function getCurrentWeekDate() {
  const currentDay = (dayjs() as any).$d;

  const curWeekStartDay = (dayjs().startOf("week") as any).$d;

  return [curWeekStartDay, currentDay];
}

function getLastWeekDate() {
  const lastWeekEndDay = (dayjs().subtract(1, 'week').endOf("week") as any).$d;

  const lastWeekStartDay = (dayjs().subtract(1, 'week').startOf("week") as any).$d;

  return [lastWeekStartDay, lastWeekEndDay];
}

function getCurrentMonthDate() {
  const currentDay = (dayjs() as any).$d;

  const curMonthStartDay = (dayjs().startOf("month") as any).$d;

  return [curMonthStartDay, currentDay];
}

function getLastMonthDate() {
  const lastEndDay = (dayjs().subtract(1, "month").endOf("month") as any).$d;

  const lastStartDay = (dayjs().subtract(1, "month").startOf("month") as any)
    .$d;

  return [lastStartDay, lastEndDay];
}

const defaultChooseRangeModes = [
  {
    label: '本周',
    name: 'cur-week',
    rangeFunc: getCurrentWeekDate
  },
  {
    label: '上周',
    name: 'last-week',
    rangeFunc: getLastWeekDate
  },
  {
    label: '本月',
    name: 'cur-month',
    rangeFunc: getCurrentMonthDate
  },
  {
    label: '上月',
    name: 'last-month',
    rangeFunc: getLastMonthDate
  }
]

const defaultChooseRangeModeName: string = 'cur-week'

const defaultForm = {
  venue_id: "",
  venue: {
    id: "",
    name: "",
  },

  time: [
    
  ], // 日期
}


const defaultSearchVenuePageOption = {
  page: 1,
  limit: 10,
  isRearchBottom: false,
};


// // 最大有效期
// const maxExpiredDays = 9999;

// // 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;

// coupon 卡券分属类别
const couponShopType2ch: any = [
  ...shareShopType2ch
]

function getLineChartOption(xData: string[], yData: number[]) {
  const option = {
    xAxis: {
      type: 'category',
      nameLocation: 'center',
      boundaryGap: true,
      data: [
        ...(xData || [])
      ]
    },
    grid: {
      show: true,
    },
    yAxis: {
      type: 'value'
    },
    tooltip: {
      show: true,
      trigger: 'axis',
      axisPointer: {            
        type: 'line'        // 默认为直线，可选为：'line' | 'shadow'
      }
    },
    series: [
      {
        data: [
          ...(yData || [])
        ],
        type: 'line',
        areaStyle: {}
      }
    ]
  }

  console.log(option, 'option')

  return option
}

// type ShopIncomeDayItem = {
//   date: string,
//   total_money: string
// }


@Component({
  filters: {
    showCouponShopTypeName(shop_type: number) {
      return couponShopType2ch[shop_type] || ''
    }
  },
  components: {
    ZlSelect,
    ZlOption,
    PandaMobileTWFContainer
  },
})
export default class ShopIncomeChart extends Vue {
  [x: string]: any;
  private form: any = {
    // couponId: "",
    // time: [],
    // startDate: "",
    // endDate: "",
  };
  // private forms: any = {
  //   // maxType: "0",
  //   // ...defaultForms
  // };

  private deepLoopUtil: any = {};

  private timeRangeModes: any[] = [
    ...(defaultChooseRangeModes || [])
  ]

  private curRangeMode: string = defaultChooseRangeModeName


  private shopIncomeChartApi = new ShopIncomeChartAPI();
  // private couponTimeDurationAPI = new CouponTimeDurationManageAPI();
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
      time: [
        ...getCurrentWeekDate()
      ]
    };

    // this.forms = {
    //   ...this.deepLoopUtil.deepCopy(defaultForms, false),
    // };

    this.searchVenuePageOption = {
      ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    };

    // this.merchantApi = new MerchantApi();
  }

  private created(): void {


  }

  private activated() {

    this.refreshIncomeChart();
  }

  private mount():void {
    this.refreshIncomeChart();
  }
  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
    // this.form = new Util().clearObject(this.form);
    // this.$router.replace("/orderList");
    this.refreshIncomeChart();
  }

  //搜索
  private search(): void {
    this.tableData = [];
    this.refreshIncomeChart();
  }

  private tableData: any[] = [];

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";
  // private options: any = [];

  // private clearObject(): void {
  //   this.dialogFormVisible = false;
  //   this.forms = new Util().clearObject(this.forms);
  // }
  // private tablePage: any = {
  //   count: 0,
  //   page: 1,
  //   prepage: 10,
  //   pageSizes: [10, 20, 50, 100, 200, 500],
  //   totalPage: 0,
  // };
  // //翻页
  // private handlePageChange(val: any) {
  //   this.tablePage.page = val.currentPage;
  //   this.tablePage.prepage = val.pageSize;
  //   this.refreshIncomeChart();
  // }
  
  private venueList: any[] = [
    // {
    //   id: 1,
    //   label: '测试'
    // },
    // {
    //   id: 2,
    //   label: '测试2'
    // }
  ];

  private searchVenueLoading: boolean = false;

  private remoteGetVenueQueryString: string = "";

  private remoteSearchVenueTimer: any = null;

  private searchVenuePageOption: any = {};

  private initRemoteGetVenuePageOption() {
    this.searchVenuePageOption = {
      ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    };
  }

  private getSearchVenueParams() {
    const searchVenuePageOption = this.searchVenuePageOption;

    const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

    const { page, limit } = searchVenuePageOption;

    const params: any = {
      page,
      limit,
    };

    if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
      params.name = remoteGetVenueQueryString.trim();
    }

    return params;
  }

  private getMoreVenueList(refresh: boolean = true) {
    return new Promise((resolve: Function, reject: Function) => {
      if (refresh) {
        this.initRemoteGetVenuePageOption();
      }

      const params = this.getSearchVenueParams();

      this.shopIncomeChartApi.searchVenueList(
        params,
        (res: any) => {
          console.log(res, "res");

          const venueList: any[] = res.data || [];

          this.venueList = refresh
            ? venueList
            : this.venueList.concat(venueList);

          if (Array.isArray(res.data) && res.data.length == 0) {
            this.searchVenuePageOption.isRearchBottom = true;
          }
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          resolve();
        },
        () => {
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          reject();
        }
      );
    });
  }

  // 第一次搜索 无关键字
  private handleFirstGetVenueList() {
    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 清空搜索
  private handleClearGetVenueList() {
    this.remoteGetVenueQueryString = "";

    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 远程搜索
  private remoteGetVenueMethod(query: string) {
    query = query.trim ? query.trim() : "";

    if (query !== "") {
      this.remoteGetVenueQueryString = query;
      clearTimeout(this.remoteSearchVenueTimer);

      this.remoteSearchVenueTimer = setTimeout(() => {
        this.searchVenueLoading = true;

        this.getMoreVenueList();
        // searchStu((res) => {
        //   const {
        //     students,
        //     total_count
        //   } = res || {}

        //   this.remoteAllStudents = [
        //     ...(students || [])
        //   ]

        //   this.remoteStudentTotalCount = total_count || 0

        //   this.remoteGetStudentLoading = false
        // }, {
        //   kw: query,
        //   ...this.getRemoteGetStudentPageOptionParams()
        // }, () => {
        //   this.remoteGetStudentLoading = false
        // })
      }, 300);
      // setTimeout(() => {
      //   this.remoteGetStudentLoading = false;
      //   this.options = this.list
      // }, 200);
    } else {
      this.remoteGetVenueQueryString = "";
      this.venueList = [];
    }
  }

  private addVenueListLoading: boolean = false;

  private remoteAddVenueMethod() {
    if (this.addVenueListLoading) return;
    const searchVenuePageOption = this.searchVenuePageOption;

    const { isRearchBottom } = searchVenuePageOption;

    if (isRearchBottom) return;

    this.searchVenuePageOption.page++;

    this.addVenueListLoading = true;

    this.getMoreVenueList(false);
  }

  // // 原价限制
  // private handleLimitOriginPrice(val: string) {
  //   Util.limitDigitalnput(
  //     val,
  //     (new_val: string) => {
  //       this.$set(this.forms, "origin_price", new_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "origin_price", new_val);
  //     },
  //     2
  //   );
  // }

  // // 售价限制
  // private handleLimitSlaePrice(val: string) {
  //   Util.limitDigitalnput(
  //     val,
  //     (new_val: string) => {
  //       this.$set(this.forms, "sale_price", new_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "sale_price", new_val);
  //     },
  //     2
  //   );

  // }

  private handleChangeTimeRange(range: any) {
    console.log(range, 'range')
    this.curRangeMode = range.name

    const chooseTimeRanges = range.rangeFunc()

    this.$set(this.form, 'time', chooseTimeRanges)
  }

  private get shopIncomeChartDOM() {
    return this.$refs['shop-income-chart']
  }

  private shopIncomeEchartIns: any

  private refreshIncomeChart(): void {
    // this.form.startDate = Util.handleStartTimeAndEndTime(
    //   this.form.time,
    //   false
    // ).startTime;
    // this.form.endDate = Util.handleStartTimeAndEndTime(
    //   this.form.time,
    //   false
    // ).endTime;
    if (!this.shopIncomeChartDOM) return

    console.log(this.shopIncomeChartDOM, 'this.shopIncomeChartDOM')
    this.shopIncomeChartApi.getIncomeChartData(
      { ...this.getCouponParams() },
      (res: any) => {
        // res.data.list
        const {
          list
        } = res.data

        this.shopIncomeEchartIns = this.$echarts.init(this.shopIncomeChartDOM)

        if (Array.isArray(list)) {
          const {
            xData,
            yData
          } = this.getChartXYData(list)
          this.shopIncomeEchartIns.setOption(
            getLineChartOption(xData, yData)
          )
        }
      },
      () => {}
    );
  }

  private getChartXYData(list: any[]) {
    const xData: string[] = []
    const yData: number[] = []

    if (Array.isArray(list)) {
      list.forEach((item: any) => {
        const {
          date,
          total_money
        } = item || {}

        xData.push(date)

        if (total_money) {
          yData.push(Number(total_money))
        }

      })
    }

    return {
      xData,
      yData
    }
  }

  private getCouponParams(): any {
    const params: any = {};

    const form = this.form;



    const { venue, time } = form;

    if (venue && venue.name) {
      params.shop_name = venue.name;
    }

   if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      params.start_date = startTime;

      params.end_date = endTime;
    }

  
    return params;
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };

  // private getOptions(): void {
  //   this.shopIncomeChartApi.selectCoupons(
  //     (res: any) => {
  //       this.options = res.data.list;
  //       this.selectPage.page = res.data.page;
  //       this.selectPage.prepage = res.data.prepage;
  //       this.selectPage.totalPage = res.data.totalPage;
  //       this.selectPage.count = res.data.count;
  //       this.subId = "";
  //     },
  //     {
  //       ...this.selectPage,
  //       superpositionJoin: this.forms.superpositionJoin,
  //       id: this.subId,
  //     }
  //   );
  // }

  // @Watch("dialogFormVisible")
  // private changeDialog(): void {
  //   if (this.dialogFormVisible) {
  //     this.getOptions();
  //   }
  // }

  // private changeSuperpositionType(): void {
  //   this.$set(this.forms, "superpositionType", "2");

  //   // if (this.forms.type == 2) {

  //   //   this.forms.superpositionType = "2";
  //   // }
  // }

  // private changeMaxType(): void {
  //   // const MaxType=this.forms.maxType
  //   // this.$set(this.forms, "maxType", MaxType);
  //   this.$forceUpdate();
  // }

  // private changeMinType(): void {
  //   // if (this.forms.type == 2) {
  //   //   this.forms.minType = "0";
  //   // }
  //   this.$forceUpdate();
  // }

  // private changeType(): void {
  //   if (this.forms.type == 2) {
  //     this.forms.superpositionType = "2";
  //     // this.forms.minType = "0";
  //   }
  //   if (this.forms.type != 3) {
  //     this.forms.minMoneyLimit = 0;
  //   }
  //   this.$forceUpdate();
  // }

  // private changeforms(): void {
  //   // console.log(this.forms.time)
  //   this.$forceUpdate();
  // }
}

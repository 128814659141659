
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import PandaMobileTable from "@/components/mobile-custom/panda-table.vue";

import PandaMobileTWFContainer from "@/components/mobile-custom/panda-table-with-filter-container.vue";

import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import { ActivityCEBPayRecordApi } from "@/common/api/operationManage/chineseEightBallActivityPayRecord";

import { excuteDownloadExport2Excel } from "@/common/xlsx";
export const pageApiList = getPageAPIs(ActivityCEBPayRecordApi);

const defaultForm = {
  phone: "", // 手机号
};

const signupStatus2ch: any = ["未报名", "已报名"];
@Component({
  filters: {
    showSingupStatus(is_submit: number) {
      return signupStatus2ch[is_submit] || "未知";
    },
  },
  components: {
    PandaMobileTable,
    PandaMobileTWFContainer,
  },
})
export default class UserSignupPayList extends Vue {
  /** 移动端属性、方法 */

  private allMobileChooseFields: any[] = [
    {
      label: '用户昵称',
      field: 'user_name'
    },
    {
      label: '手机号码',
      field: 'user_phone'
    },
    {
      label: '报名号码',
      field: 'phone'
    },
    {
      label: '姓名',
      field: 'real_name'
    },
    {
      label: '身份证',
      field: 'identity_card'
    },
    {
      label: '报名费用',
      field: 'price'
    },
    {
      label: '支付时间',
      field: 'updated_at'
    }
  ];

  /** end 移动端属性、方法 */

  private activityApi: any;

  private deepLoopUtil: any;

  constructor() {
    super();
    this.activityApi = new ActivityCEBPayRecordApi();

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
  }

  private form: any;

  private firstLoading: boolean = true;
  // private chooseTechnicianItem : any = null;

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    this.activityApi.getUserSignupPayTableList(
      this.getUserSignupPayTableListParams(),
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        const { list, count, page, prepage } = data || {};

        const newTableList = Array.isArray(list)
          ? list.map(this.handleTableData)
          : [];

        this.$set(this.tablePage, "totalCount", count);
        this.$set(this.tablePage, "pageNo", page);
        this.$set(this.tablePage, "pageSize", prepage);

        this.tableData = newTableList;

        this.firstLoading = false;
      },
      () => {
        this.firstLoading = false;
      },
      false
    );
  }

  private getUserSignupPayTableListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const firstLoading = this.firstLoading;

    const { phone } = form || {};

    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};

    if (phone) {
      outerParams.phone = phone;
    }

    const route = this.$route;

    const { query } = route;

    const { userId } = query;

    if (userId) {
      outerParams.userId = userId;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      outerParams.__isUnique = !firstLoading;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  // 后台返回的字段 转化为前端定义的字段
  private handleTableData(data: any): any {
    const {
      // type: user_type
    } = data;
    const newData = {
      ...data,
    };

    return newData;
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 订单字段名 文案对应关系
  private orderExcelList2ch: any = {
    user_name: "用户昵称",
    user_phone: "手机号码",
    phone: "报名号码",
    real_name: "姓名",
    identity_card: "身份证",
    price: "报名费用",
    updated_at: "支付时间",
    // __singupStatus: "是否报名",
  };
  // 订单Excel 表头的先后顺序
  private orderExcelSortList: string[] = [
    "user_name",
    "user_phone",
    "phone",
    "real_name",
    "identity_card",
    "price",
    "updated_at",
    // "__singupStatus",
  ];

  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getUserSignupPayTableListParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.activityApi.exportExcelUserSignupPayList(
      params,
      (res: any) => {
        const {} = params || {};

        const filename = `比赛缴费记录列表.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const { is_submit } = item || {};

              const newItem = {
                ...(item || {}),
                __singupStatus: signupStatus2ch[is_submit],
              };

              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const orderExcelSortList = this.orderExcelSortList;

          const orderExcelList2ch = this.orderExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            orderExcelSortList,
            orderExcelList2ch
          );

          // console.log(newList, "newList");

          // console.log(excelJSONData, "excelJSONData");

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        // const testData = [
        //   { 主页: "test1223424", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
        //   { 主页: "433", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
        //   { 名称: "22", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
        //   { 名称: "43", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
        //   { 店家: "43", 价格: "6800", 数量: "6800", 昵称: "广告主网", }
        // ]

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */

  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
    // this.form = new Util().clearObject(this.form);
    // this.$router.replace("/orderList");
    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}

import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class RecordExamineAPIClass extends BasicsClass {

    public apiUrls: any = new Map([
        ['getReportTableList', {
            url: '/admin/dynamics/reportList',
            name: 'list',
            label: '动态举报列表'
        }],
        ['handleReport', {
            url: '/admin/dynamics/handleReport',
            name: 'handle-report',
            label: '处理举报'
        }]

    ])
    // 举报列表
    public getRecordExamineTableList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        params = {...(params || {}),}

        const apiUrls = this.apiUrls

        const url = apiUrls.get('getReportTableList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 处理举报
    public handleReport(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('handleReport').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}

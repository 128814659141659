import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class CoachwithdrawDepositApi extends BasicsClass {
    public apiUrls: any = new Map([
        ['getWithdrawTableList', {
            url: '/admin/coach/withdraw/lists',
            name: 'list',
            label: '列表'
        }],
        ['getAuditAgree',  {
            url: '/admin/coach/withdraw/approved',
            name: 'approved-audit',
            label: '同意提现'
        }],
        ['getAuditDisagree',  {
            url: '/admin/coach/withdraw/reject',
            name: 'reject-audit',
            label: '拒绝提现'
        }],
        ['batchAuditAgree', {
            url: '/admin/coach/withdraw/approvedAll',
            name: 'batch-approved-audit',
            label: '批量同意提现'
        }],
        ['exportExcelWithdrawList', {
            url: '/admin/coach/withdraw/export',
            name: 'export-excel',
            label: '导出'
        }]
    ])


    // 提现列表
    public getWithdrawTableList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getWithdrawTableList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 导出提现列表
    public exportExcelWithdrawList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('exportExcelWithdrawList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 审核通过
    public getAuditAgree(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getAuditAgree').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 批量审核通过
    public batchAuditAgree(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('batchAuditAgree').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 驳回
    public getAuditDisagree(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getAuditDisagree').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
  }
}
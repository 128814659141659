
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
// import { screen } from "@/common/screen/screen";
import SingleCardConsumeRecordAPI from "@/common/api/financeManage/singleCardConsumeRecord";
import { CommonApi } from "@/common/api/common";
import { excuteDownloadExport2Excel } from "@/common/xlsx";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import { utils } from "xlsx";

import gallery from "@/components/gallery.vue";

import {
  shareConfirmOptions
} from '@/common/config'


export const pageApiList = getPageAPIs(SingleCardConsumeRecordAPI)


// 审核状态 1已核销2已退款
const singleCardStatus2Text: any = ["", "已核销", "已退款"];


const defaultForm: any = {
  order_no: "",
  phone: "",
  status: 0, // 状态 来源:0:全部1已核销2已退款（不传为全部）
  receipt_code: "",
  shop_name: "",
  time: []
}

@Component({
  components: {
    gallery,
  },
  filters: {
    refundSignleCardStatusText(statusNum: any): any {
      return singleCardStatus2Text[statusNum] || "未知状态";
    },
  },
})
export default class SingleCardConsumeRecord extends Vue {
  private form: any = {
    // // type:  0, // 问题类型
    // user_id: "", // 用户ID
    // nickname: "", // 助教昵称
    // is_status: 1, // 状态
    // time: [], // 时段选择
  };
  private deepLoopUtil: any;

  constructor() {
    super()

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm, false),
    }
  }
  private singleCardConsumeRecordApi: any = new SingleCardConsumeRecordAPI();
  private created(): void {
    // this.getProblemTypes();
    this.search();
  }
  private getTable(): void {
   
    this.singleCardConsumeRecordApi.getList(
      {
        ...this.getWithdrawAccountAuditParams()
      },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.pageNo = res.data.page || this.tablePage.pageNo;
        this.tablePage.pageSize = res.data.prepage || this.tablePage.pageSize;
        this.tablePage.totalCount = res.data.count;
      }
    );
  }

  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }
  // private problemList: any[] = [
  //   ...problemList
  // ];

  // 审核状态 0:全部1已核销2已退款
  private statusList: any[] = [
    {
      label: "全部",
      value: 0,
    },
    {
      label: "已核销",
      value: 1,
    },
    {
      label: "已退款",
      value: 2,
    },
  ];

  private systemTypaName: any[] = ["未知", "安卓", "IOS", "小程序"];

  // 审核拒绝弹框
  private dialogFormRejectVisible: boolean = false

  private curRejectAuditItem: any

  private sumbitRejectAuditLoading: boolean = false

  private rejectForms: any = {
    reject_reason: ''
  }

  private handleSumbitRejectAudit() {
    const rejectForms = this.rejectForms

    const {
      reject_reason
    } = rejectForms || {}

    if (!reject_reason) {
      this.$message.error("请输入拒绝原因")
      return
    }

    const curRejectAuditItem = this.curRejectAuditItem

    this.sumbitRejectAuditLoading = true

    this.singleCardConsumeRecordApi.updateWithdrawAccountAuditStatus(
      {
        id: curRejectAuditItem.id,
        reject_reason,
        mode: 'disagree',
        // status: 1
      },
      () => {
        this.sumbitRejectAuditLoading = false

        this.dialogFormRejectVisible = false

        this.getTable();
      },
      () => {
        this.sumbitRejectAuditLoading = false
      }
    );
  }

  // 退款
  private handleRefundSingleCardConsumeRecord(row: any) {

    // const disArgeeConfirmOptions = {
    //   showInput: true,
      // inputPlaceholder: '请输入拒绝原因',
      // inputType: 'textarea'
    // }

   
    this.$confirm(`确定退款改美团单店卡核销?`, "", {
      ...shareConfirmOptions
    }).then(() => {
      const tableData = this.tableData;

      const existRowIndex = tableData.indexOf(row);

      if (existRowIndex > -1) {
        row.__refundSingleCardConsumeLoading = true;

        tableData.splice(existRowIndex, 1, row);

        this.singleCardConsumeRecordApi.refundMtVourcherRecord(
          {
            id: row.id,
            // status: 1
          },
          () => {
            row.__refundSingleCardConsumeLoading = false;

            tableData.splice(existRowIndex, 1, row);

            this.getTable();
          },
          () => {
            row.__refundSingleCardConsumeLoading = false;

            tableData.splice(existRowIndex, 1, row);
          }
        );
      }
    });
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

 
  private images: any = [];
 

  private getWithdrawAccountAuditParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const {
      order_no,
      phone,
      status,
      receipt_code,
      shop_name,
      time, // 
      // finish_time, // 完成时间
    } = form || {};


    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};

    if (order_no) {
      outerParams.order_no = order_no
    }

    if (phone) {
      outerParams.phone = phone;
    }

    if (typeof status == 'number') {
      outerParams.status = status
    }

    if (receipt_code) {
      outerParams.receipt_code = receipt_code
    }

    if (shop_name) {
      outerParams.shop_name = shop_name
    }

    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      outerParams.start_date = startTime;

      outerParams.end_date = endTime;
    }

    if (
      mode == "exportExcel" &&
      !(outerParams.start_date && outerParams.end_date)
    ) {
      this.$message.error("请选择日期导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      // outerParams.__isUnique = !firstLoading;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 字段名 文案对应关系
  private merchantExcelList2ch: any = {
    order_no: "订单编号",
    phone: "手机号",
    name: "名称",
    face_value: "面值额",
    price: "金额",
    receipt_code: "券码",
    shop_name: "店铺",
    created_at: "核销时间",
    __status: "状态",
    operation_name: "操作人",
    refund_time: "操作时间",
  };
  // 商户Excel 表头的先后顺序
  private merchantExcelSortList: string[] = [
    "order_no",
    "phone",
    "name",
    "face_value",
    "price",
    "receipt_code",
    "shop_name",
    "created_at",
    "__status",
    "operation_name",
    "refund_time",
  ];

  // 点击导出excel 表格
  private handleExportExcelSingleCardConsumeList() {
    const params = this.getWithdrawAccountAuditParams('exportExcel')

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.singleCardConsumeRecordApi.exportSingleCardConsumeList(
      params,
      (res: any) => {
        const { start_date, end_date } = params || {};

        const filename = `美团单店卡核销记录列表 ${start_date}到${end_date}.xlsx`;
        const { data } = res || {};

        const {
          list: originList
        } = data || {}

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const { 
                status
               } = item || {};
              const newItem = {
                ...(item || {}),
                __status: singleCardStatus2Text[status]
              };

              for (let key in item) {
                if (typeof item[key] == 'number') {
                  newItem[key] = item[key].toString()
                }
              }

             
              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const merchantExcelSortList = this.merchantExcelSortList;

          const merchantExcelList2ch = this.merchantExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            merchantExcelSortList,
            merchantExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */
}

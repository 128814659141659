
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import PandaMobileTable from "@/components/mobile-custom/panda-table.vue";

import PandaMobileTWFContainer from "@/components/mobile-custom/panda-table-with-filter-container.vue";

import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
// import { screen } from "@/common/screen/screen";
import TechnicalServiceFeeListAPI from "@/common/api/financeManage/technicalServiceFeeList";
import { CommonApi } from "@/common/api/common";
import { excuteDownloadExport2Excel } from "@/common/xlsx";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import { utils } from "xlsx";

import gallery from "@/components/gallery.vue";

import {
  shareConfirmOptions
} from '@/common/config'


export const pageApiList = getPageAPIs(TechnicalServiceFeeListAPI)


const transformGender = ["", "女", "男"];

// 审核状态 2:审核中3:审核通过5:审核未通过

const defaultForm: any = {
  // phone: "",
  // status: 2, // 状态  2:审核中3:审核通过5:审核未通过
  time: []
}

@Component({
  components: {
    gallery,
    PandaMobileTable,
    PandaMobileTWFContainer,
  },
  filters: {
    
  },
})
export default class TechnicalServiceFee extends Vue {
  /** 移动端相关属性 方法 */
  private allMobileChooseFields: any[] = [
    {
      label: '日期',
      field: 'date'
    },
    {
      label: '总抽成',
      field: 'total_commission'
    },
    {
      label: '台球抽成',
      field: 'billiard_commission'
    },
    {
      label: '棋牌抽成',
      field: 'chess_commission'
    },
    {
      label: '售卖柜抽成',
      field: 'sales_counter_commission'
    },
    {
      label: '操作',
      field: 'table-option'
    }
  ];
  /** end 移动端相关属性 方法 */

  private form: any = {
    // // type:  0, // 问题类型
    // user_id: "", // 用户ID
    // nickname: "", // 助教昵称
    // is_status: 1, // 状态
    // time: [], // 时段选择
  };
  private deepLoopUtil: any;

  constructor() {
    super()

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm, false),
    }
  }
  private technicalServiceFeeListApi: any = new TechnicalServiceFeeListAPI();
  private created(): void {
    // this.getProblemTypes();
    this.search();
  }
  private getTable(): void {
   
    this.technicalServiceFeeListApi.getList(
      {
        ...this.getTechnicalServiceFeeParams()
      },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.pageNo = res.data.page || this.tablePage.pageNo;
        this.tablePage.pageSize = res.data.prepage || this.tablePage.pageSize;
        this.tablePage.totalCount = res.data.count;
      }
    );
  }

  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }
  // private problemList: any[] = [
  //   ...problemList
  // ];

  // // 审核状态 1待审核 2审核通过 3驳回
  // private statusList: any[] = [
  //   {
  //     label: "审核中",
  //     value: 2,
  //   },
  //   {
  //     label: "审核通过",
  //     value: 3,
  //   },
  //   {
  //     label: "审核未通过",
  //     value: 5,
  //   },
  // ];

  // private systemTypaName: any[] = ["未知", "安卓", "IOS", "小程序"];

  // // 审核拒绝弹框
  // private dialogFormRejectVisible: boolean = false

  // private curRejectAuditItem: any

  // private sumbitRejectAuditLoading: boolean = false

  // private rejectForms: any = {
  //   reject_reason: ''
  // }

  // private handleSumbitRejectAudit() {
  //   const rejectForms = this.rejectForms

  //   const {
  //     reject_reason
  //   } = rejectForms || {}

  //   if (!reject_reason) {
  //     this.$message.error("请输入拒绝原因")
  //     return
  //   }

  //   const curRejectAuditItem = this.curRejectAuditItem

  //   this.sumbitRejectAuditLoading = true

  //   this.technicalServiceFeeListApi.updateTechnicalServiceFeeStatus(
  //     {
  //       id: curRejectAuditItem.id,
  //       reject_reason,
  //       mode: 'disagree',
  //       // status: 1
  //     },
  //     () => {
  //       this.sumbitRejectAuditLoading = false

  //       this.dialogFormRejectVisible = false

  //       this.getTable();
  //     },
  //     () => {
  //       this.sumbitRejectAuditLoading = false
  //     }
  //   );
  // }

  // // 更新状态
  // private handleUpdateTechnicalServiceFeeStatus(row: any, mode: string) {
  //   const modeMsgs: any = {
  //     agree: "通过",
  //     disagree: "不通过",
  //   };

  //   // const disArgeeConfirmOptions = {
  //   //   showInput: true,
  //     // inputPlaceholder: '请输入拒绝原因',
  //     // inputType: 'textarea'
  //   // }

  //   const confirmOptions = {
  //     ...shareConfirmOptions,
      
  //   }

  //   if (mode == 'disagree') {
  //     this.curRejectAuditItem = row

  //     this.rejectForms = {
  //       reject_reason: ''
  //     }

  //     this.dialogFormRejectVisible = true

  //     return 
  //   }
  //   this.$confirm(`确定${modeMsgs[mode]}该项申请?`, "", confirmOptions).then(() => {
  //     const tableData = this.tableData;

  //     const existRowIndex = tableData.indexOf(row);

  //     if (existRowIndex > -1) {
  //       row.__updateFeedbackStatusLoading = true;

  //       tableData.splice(existRowIndex, 1, row);

  //       this.technicalServiceFeeListApi.updateTechnicalServiceFeeStatus(
  //         {
  //           id: row.id,
  //           mode,
  //           // status: 1
  //         },
  //         () => {
  //           row.__updateFeedbackStatusLoading = false;

  //           tableData.splice(existRowIndex, 1, row);

  //           this.getTable();
  //         },
  //         () => {
  //           row.__updateFeedbackStatusLoading = false;

  //           tableData.splice(existRowIndex, 1, row);
  //         }
  //       );
  //     }
  //   });
  // }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

 
  private images: any = [];
 

  private getTechnicalServiceFeeParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;


    const {
    
      time, // 
      // finish_time, // 完成时间
    } = form || {};


    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};


    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      outerParams.start_date = startTime;

      outerParams.end_date = endTime;
    }

    if (
      mode == "exportExcel" &&
      !(outerParams.start_date && outerParams.end_date)
    ) {
      this.$message.error("请选择日期导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      // outerParams.__isUnique = !firstLoading;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  private handleGoShopTSFeeDetail(dateFeeItem: any) {
    console.log(dateFeeItem, 'dateFeeItem')

    this.$router.push({
      path: '/technicalServiceShopFeeList',
      query: {
        date: dateFeeItem.date || ''
      }
    })
    
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 字段名 文案对应关系
  private technicalServiceExcelList2ch: any = {
   "date": "日期",
   "total_commission": "总抽成",
   "billiard_commission": "台球抽成",
   "chess_commission": "棋牌抽成",
   "sales_counter_commission": "售卖柜抽成"
  };
  // 商户Excel 表头的先后顺序
  private technicalServiceExcelSortList: string[] = [
   "date",
   "total_commission",
   "billiard_commission",
   "chess_commission",
   "sales_counter_commission"
  ];

  // 点击导出excel 表格
  private handleExportExcelTechnicalServiceFeelList() {
    const params = this.getTechnicalServiceFeeParams('exportExcel')

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.technicalServiceFeeListApi.exportTechnicalServiceFeeList(
      params,
      (res: any) => {
        const { start_date, end_date } = params || {};

        const filename = `技术服务费汇总列表 ${start_date}到${end_date}.xlsx`;
        const { data } = res || {};

        const {
          list: originList
        } = data || {}

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const { 
                status
               } = item || {};
              const newItem = {
                ...(item || {}),
                // __status: withdrawAccountAudit2Text[status]
              };

              for (let key in item) {
                if (typeof item[key] == 'number') {
                  newItem[key] = item[key].toString()
                }
              }

             
              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const technicalServiceExcelSortList = this.technicalServiceExcelSortList;

          const technicalServiceExcelList2ch = this.technicalServiceExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            technicalServiceExcelSortList,
            technicalServiceExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */
}

/** 注意： meta  key 一旦确定不可修改 且 同级唯一。 是作为识别这个对象的唯一标识！！！   */

// 用户管理
export const userManageRoutes = [
  {
    path: "/userList",
    name: "userList",
    meta: {
      title: "用户列表",
      key: 'user-list',
      pageApisPrm: import("@/views/userManage/userList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "userManage" */ "@/views/userManage/userList.vue"),
  },
  {
    path: "/coachInfoManage",
    name: "coachInfoManage",
    meta: {
      title: "助教管理",
      key: 'coach-info-manage',
      pageApisPrm: import("@/views/userManage/coachInfoManage.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "userManage" */ "@/views/userManage/coachInfoManage.vue"),
  },
  {
    path: "/allianceBusinessList",
    name: "allianceBusinessList",
    meta: {
      title: "助教加盟商管理",
      key: 'coach-alliance-business-list',
      asideHidden: true, // 侧边栏不显示
      pageApisPrm: import("@/views/userManage/allianceBusinessList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "userManage" */ "@/views/userManage/allianceBusinessList.vue"),
  },
  {
    path: "/orderList",
    name: "orderList",
    meta: {
      title: "台球订单",
      key: 'order-list',
      pageApisPrm: import("@/views/userManage/orderList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "userManage" */ "@/views/userManage/orderList.vue"
      ),
  },
  {
    path: "/chessOrderList",
    name: "chessOrderList",
    meta: {
      title: "棋牌订单列表",
      key: 'chess-order-list',
      pageApisPrm: import("@/views/userManage/chessOrderList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "userManage" */ "@/views/userManage/chessOrderList.vue"
      ),
  },
  {
    path: "/coachOrderList",
    name: "coachOrderList",
    meta: {
      title: "助教订单列表",
      key: 'coach-order-list',
      pageApisPrm: import("@/views/userManage/coachOrderList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "userManage" */ "@/views/userManage/coachOrderList.vue"
      ),
  },
  {
    path: '/goodsOrderList',
    name: 'goodsOrderList',
    meta: {
      title: "商品订单列表",
      key: 'goods-order-list',
      pageApisPrm: import("@/views/userManage/goodsOrderList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "userManage" */ "@/views/userManage/goodsOrderList.vue"
      ),
  }
]
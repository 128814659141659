import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class MonthIncomeApi extends BasicsClass {
    public apiUrls: any = new Map([
        ['monthBusiness', {
            url: '/admin/shop/monthBusiness',
            name: 'list',
            label: '列表'
        }],
    
        ['exportExcelMonthIncomeAuditList', {
            url: '/admin/shop/exportMonthBusiness',
            name: 'export-excel',
            label: '导出'
        }]
    ])

    // 每月营收列表
    public monthBusiness(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
      const apiUrls = this.apiUrls

      const url = apiUrls.get('monthBusiness').url

      this.BasicPost(url, {
          ...params
      }, false, false, false, successCb, failCb, showLoading)
    }

    // 导出每月营收列表
    public exportExcelMonthIncomeAuditList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('exportExcelMonthIncomeAuditList').url
  
        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

}

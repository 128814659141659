import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class GuessingGamePraizesSettingAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getGuessingGamePraizesSettingList', {
      url: '/admin/guess/question/list',
      name: 'list',
      label: '列表'
    }],
    ['refreshGuessingGamePraizesDetail', {
      url: '/admin/guess/question/view',
      name: 'guessing-game-detail',
      label: '有奖竞猜详情'
    }],
    ['addGuessingGamePraizesSetting', {
      url: '/admin/guess/question/add',
      name: 'add-guess-quation',
      label: '添加有奖竞猜问题'
    }],
    ['updateGuessingGamePraizesSetting', {
      url: '/admin/guess/question/update',
      name: 'edit-guess-quation',
      label: '编辑有奖竞猜问题'
    }],
    
    ['changeGuessingGameAnswer', {
      url: '/admin/guess/question/answer',
      name: 'set-guess-quation-answer',
      label: '设置有奖竞猜问题答案'
    }]
   
  ])

  // 有奖竞猜列表
  public getGuessingGamePraizesSettingList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getGuessingGamePraizesSettingList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
  // 有奖竞猜详情
  public refreshGuessingGamePraizesDetail(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('refreshGuessingGamePraizesDetail').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 修改有奖竞猜
  public updateGuessingGamePraizesSetting(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('updateGuessingGamePraizesSetting').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 添加有奖竞猜
  public addGuessingGamePraizesSetting(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('addGuessingGamePraizesSetting').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 设置有奖竞猜答案
  public changeGuessingGameAnswer(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('changeGuessingGameAnswer').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
  
}
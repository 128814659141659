import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class BusinessReminderListAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getBusinessReminderList', {
      url: '/admin/operate_hint/list',
      name: 'list',
      label: '列表'
    }],
    ['addBusinessReminder', {
      url: '/admin/operate_hint/add',
      name: 'add',
      label: '添加'
    }],
    ['updateBusinessReminder', {
      url: '/admin/operate_hint/edit',
      name: 'edit',
      label: '编辑'
    }],
    ['deleteBusinessReminder', {
      url: '/admin/operate_hint/delete',
      name: 'delete',
      label: '删除'
    }],
    ['searchVenueList', {
      url: '/admin/coupons/shop/list',
      name: 'shop-list',
      label: '店铺列表',
      // hidden: true
    }],
   
  ])

  // 运营提醒列表
  public getBusinessReminderList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getBusinessReminderList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 修改运营提醒
  public updateBusinessReminder(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('updateBusinessReminder').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 添加运营提醒
  public addBusinessReminder(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('addBusinessReminder').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 删除台球柜
  public deleteBusinessReminder(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('deleteBusinessReminder').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 搜索店铺列表
  public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('searchVenueList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  
}
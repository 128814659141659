import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class SingleCardUseRecordAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getSingleCardUseRecordTableList', {
            url: '/admin/shopMember/spend',
            name: 'list',
            label: '列表'
        }],
        ['exportExcelSingleCardUseTableList', {
            url: '/admin/shopMember/exportSpend',
            name: 'export-excel',
            label: '导出'
        }],
        // ['searchVenueList', {
        //     url: '/admin/coupons/shop/list',
        //     name: 'shop-list',
        //     label: '店铺列表',
        // }],
    ])

    // 开台时长列表
    public getSingleCardUseRecordTableList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getSingleCardUseRecordTableList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // // 导出开台时长记录
    public exportExcelSingleCardUseTableList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('exportExcelSingleCardUseTableList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 搜索店铺列表
    // public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    //     const apiUrls = this.apiUrls

    //     const url = apiUrls.get('searchVenueList').url

    //     this.BasicPost(url, {
    //         ...params
    //     }, false, false, false, successCb, failCb, showLoading)
    // }
}
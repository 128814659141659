import { render, staticRenderFns } from "./cashierClientUpdaterList.vue?vue&type=template&id=ca0f5a44&scoped=true"
import script from "./cashierClientUpdaterList.vue?vue&type=script&lang=ts"
export * from "./cashierClientUpdaterList.vue?vue&type=script&lang=ts"
import style0 from "./cashierClientUpdaterList.vue?vue&type=style&index=0&id=ca0f5a44&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca0f5a44",
  null
  
)

export default component.exports
import { BasicsClass } from '@/common/BasicsClass';
export class CouponPlatformGivenAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getCouponPlatformGivens', {
            url: '/admin/summer/givePlatformCouponList',
            name: 'list',
            label: '列表'
        }],
        // ['exportCouponPlatformGivens',  {
        //     url: '/admin/coupons/exportVerifyList',
        //     name: 'export-excel',
        //     label: '导出'
        // }],
        // ['searchVenueList', {
        //     url: '/admin/coupons/shop/list',
        //     name: 'shop-list',
        //     label: '店铺列表',
        // }]
        ['couponsPlatformList', {
            url: '/admin/couponsPlatform/list',
            name: 'choose-platform-coupon',
            label: '获取平台小时券'
        }],

        ['sumbitGivenCoupon', {
            url: '/admin/summer/givePlatformCoupon',
            name: 'given-platform-coupon',
            label: '赠送平台券'
        }]
    ])

    // 团购券核销记录列表
    public getCouponPlatformGivens(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getCouponPlatformGivens').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 导出团购券核销记录列表
    // public exportCouponPlatformGivens(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    //     const apiUrls = this.apiUrls

    //     const url = apiUrls.get('exportCouponPlatformGivens').url

    //     this.BasicPost(url, {
    //         ...params
    //     }, false, false, false, successCb, failCb, showLoading)
    // }

    // 搜索店铺列表
    // public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    //     const apiUrls = this.apiUrls

    //     const url = apiUrls.get('searchVenueList').url

    //     this.BasicPost(url, {
    //         ...params
    //     }, false, false, false, successCb, failCb, showLoading)
    // }
    // 获取平台小时券
    public couponsPlatformList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('couponsPlatformList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 获取平台小时券
    public sumbitGivenCoupon(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('sumbitGivenCoupon').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}
/** 注意： meta  key 一旦确定不可修改 且 同级唯一。 是作为识别这个对象的唯一标识！！！   */

// 市场部
export const marketManageRoutes = [
  
  {
    path: "/openShopOrderList",
    name: "openShopOrderList",
    meta: {
      title: "开店订单管理",
      key: 'open-shop-order-list',
      pageApisPrm: import("@/views/marketManage/openShopOrderList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "marketManage" */ "@/views/marketManage/openShopOrderList.vue"
      ),
  },
  // openShopFinanceList
  {
    path: "/openShopFinanceList",
    name: "openShopFinanceList",
    meta: {
      title: "开店财务管理",
      key: 'open-shop-finance-list',
      asideHidden: true, // 侧边栏不显示
      pageApisPrm: import("@/views/marketManage/openShopFinanceList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "marketManage" */ "@/views/marketManage/openShopFinanceList.vue"
      ),
  },
  {
    path: "/marketDepartDataStatistic",
    name: "marketDepartDataStatistic",
    meta: {
      title: "市场部数据统计",
      key: 'market-depart-data-statistic',
      pageApisPrm: import("@/views/marketManage/marketDepartDataStatistic.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "marketManage" */ "@/views/marketManage/marketDepartDataStatistic.vue"
      ),
  },
  {
    path: "/clueSourceList",
    name: "clueSourceList",
    meta: {
      title: "线索来源",
      key: 'clue-source-list',
      pageApisPrm: import("@/views/marketManage/clueSourceList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "marketManage" */ "@/views/marketManage/clueSourceList.vue"
      ),
  },
  {
    path: '/molixLaunchList',
    name: 'molixLaunchList',
    meta: {
      title: "麦力士投放",
      key: 'molix-launch-list',
      pageApisPrm: import("@/views/marketManage/molixLaunchList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "marketManage" */ "@/views/marketManage/molixLaunchList.vue"
      ),
  },
  // poolOrderList
  {
    path: '/poolOrderList',
    name: 'poolOrderList',
    meta: {
      title: "球桌订单列表",
      key: 'pool-order-list',
      pageApisPrm: import("@/views/marketManage/poolOrderList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "marketManage" */ "@/views/marketManage/poolOrderList.vue"
      ),
  },
  {
    path: '/poolBrandList',
    name: 'poolBrandList',
    meta: {
      title: "球桌型号",
      key: 'pool-brand-list',
      pageApisPrm: import("@/views/marketManage/poolBrandList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "marketManage" */ "@/views/marketManage/poolBrandList.vue"
      ),
  },
  {
    path: '/marketAccountList',
    name: 'marketAccountList',
    meta: {
      title: "账号管理",
      key: 'account-manage-list',
      pageApisPrm: import("@/views/marketManage/accountList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "marketManage" */ "@/views/marketManage/accountList.vue"
      ),
  },
]


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getPageAPIs } from "@/common/util";
import { CompetitionLiveSettingAPI } from "@/common/api/competitionManage/competitionLiveSettingList";

import { CommonApi } from "@/common/api/common";

import gallery from "@/components/gallery.vue";

// import MerchantApi from "@/common/api/merchant";
// import { util } from "vue/types/umd";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import ZlSelect from "@/components/third-custom-components/select";

import ZlOption from "@/components/third-custom-components/option";

import {
  shareConfirmOptions,
  // getCommonList
} from "@/common/config";
export const pageApiList = getPageAPIs(CompetitionLiveSettingAPI);



const defaultForms = {
  study_picture: [],
  study_top_ad_picture: [],
  study_bottom_ad_picture: [],
  live_detail_title: "", // 标题
  live_detail_subtitle: "", // 副标题
  pool_name: '',
 
  sort: "", // 排序
  show_status: 1, // 上架状态：0下架 1上架
  is_tv: 1, // 是否tv：0否 1是
  // singleMaxCountType: '0',
  // shop_id: "",
  // maxLimitCount: ''
};

const defaultLiveDeviceForms = {
  venue_id: '',
  venue: {
    id: "",
    name: "",
  },
  show_shop_monitor_id: '',
  status: 0,  // 状态：0未开播1直播中
}

const defaultSearchVenuePageOption = {
  page: 1,
  limit: 10,
  isRearchBottom: false,
};

const competitionPointDialogTitle2ch: any = {
  "new-competition-live-setting": "新增直播设置",
  "edit-competition-live-setting": "编辑直播设置",
};

function createPicItem(id: number, url: string) {
  const item = {
    id,
    url,
    href: url,
    type: "image/png",
    types: "1",
  };

  return item;
}

function createVideoItem(id: number, url: string) {
  const item = {
    id,
    url,
    href: url,
    type: "video/mp4",
    types: "2",
    poster: `${url}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`,
  };

  return item;
}

// 最大有效期
// const maxExpiredDays = 9999;

// 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;

// const maxBenefitRate = 100


// 上架状态：0下架 1上架
const allStudyStatus2ch: string[] = ["下架", "上架"];

// 是否绑定：0否1是
const deviceBindStatus2ch: string[] = ['未绑定', '已绑定']

// 是否tv：0否1是
const liveTvStatus2ch: string[] = ['否', '是']

@Component({
  filters: {
    showStudyStatusText(status: any) {
      return allStudyStatus2ch[status] || "未知";
    },

    showLiveTvStatusText(is_tv: number) {
      return liveTvStatus2ch[is_tv] || '未知'
    },

    showDeviceBindStatus(is_bind: number) {
      return deviceBindStatus2ch[is_bind] || '未知'
    }
  },
  components: {
    gallery,
    ZlSelect,
    ZlOption,
  },
})
export default class CompetitionLiveSettingsManage extends Vue {
  private form: any = {
    // competitionPointId: "",
    live_detail_title: "", // 标题
    // shop_id: '',
    // time: [],
    // startDate: "",
    // endDate: "",
  };
  private forms: any = {
    // maxType: "0",
    // ...defaultForms
  };

  private liveDeviceForms: any

  private deepLoopUtil: any = {};

  // private merchantApi: any;

  private competitionLiveSettingApi = new CompetitionLiveSettingAPI();



  private commonApi = new CommonApi();

  private get leagueLiveId() {
    const route = this.$route

    const {
      query = {}
    } = route || {}

    return query.llid || ''
  }

  // private couponsManageAPI = new CouponsManageAPI();
  // private competitionPointTimeDurationAPI = new CompetitionLiveSettingTimeDurationManageAPI();
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    this.liveDeviceForms = {
      ...this.deepLoopUtil.deepCopy(defaultLiveDeviceForms, false),
    }

    // this.chooseShopTypeList = [
    //   ...this.deepLoopUtil.deepCopy(defaultChooseShopTypeList),
    // ]

    // this.chooseCategoryList = [
    //   ...this.deepLoopUtil.deepCopy(defaultCategoryList),
    // ];

    // this.searchVenuePageOption = {
    //   ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    // };

    // this.merchantApi = new MerchantApi();
  }

  private created(): void {
    this.getCompetitionLiveSettings();

    // this.refreshCompetitionLiveSettingTimeDurations();

    // this.refreshTableClass();
  }

  private activated() {
    this.getCompetitionLiveSettings();
  }

  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getCompetitionLiveSettings();
  }
  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getCompetitionLiveSettings();
  }

  private tableData: any[] = [];


  private handleChangeUploadStudyPic(file: any, fileList: any[]) {
    this.handleChangeUploadPic('study_picture', file, fileList)
  }
  private handleChangeUploadStudyTopAdPic(file: any, fileList: any[]) {
    this.handleChangeUploadPic('study_top_ad_picture', file, fileList)
  }
  private handleChangeUploadStudyBottomAdPic(file: any, fileList: any[]) {
    this.handleChangeUploadPic('study_bottom_ad_picture', file, fileList)
  }
  private handleChangeUploadPic(imgField: string, file: any, fileList: any[]) {
    console.log(file, fileList, "file", "fileList");

    this.commonApi.getOss(
      file instanceof File ? file : file.raw,
      (res: any) => {
        console.log(res, "res 图片上传成功");

        const { data } = res;

        const { data: uploadImgObjWrap } = data;

        const { item: uploadImgObj } = uploadImgObjWrap;

        const { id, url } = uploadImgObj;

        this.forms[imgField].push(createPicItem(id, url));
      },
      () => {
        this.$message.error("图片上传失败");
      }
    );
  }

  private handleRemoveStudyPicture(imgField: string, file: any) {
    const forms = this.forms || {};
    const existIndex = forms[imgField].findIndex(
      (picFile: any) => picFile == file
    );

    if (existIndex > -1) {
      forms[imgField].splice(existIndex, 1);
    }
  }

  

  private images: any = [];

  private handleBeforePreviewPicture(field: string) {
    const forms = this.forms

    // const {
      
    // } = forms || {}

    const previewItem: any = {
      images: forms[field].map((pic: any) => pic.url),
      image_count: forms[field].length
    }

    this.handlePreviewMedia(previewItem)
  }

  private handlePreviewMedia(row: any) {
    const { image_count, images, video_count, videos } = row || {};

    const urls: any[] = [];

    if (image_count > 0) {
      urls.push(
        ...images.map((img: string) => {
          return {
            title: "",
            type: "image/jpeg",
            href: img,
            thumbnail: img,
          };
        })
      );
    }

    if (video_count > 0) {
      urls.push(
        ...videos.map((video: string) => {
          return {
            title: "",
            type: "video/mp4",
            href: video,
            poster: `${video}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`,
          };
        })
      );
    }

    console.log(urls, 'urls')

    this.images = urls;
  }

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";

  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getCompetitionLiveSettings();
  }

  private addOrUpdateCompetitionLiveSettingTitle: string = "直播设置";

  private dialogFormVisible: boolean = false;


  // 拉起直播设置弹框
  private handleWakeCompetitionLiveSettingDialog(mode: string) {
    // if (mode == 'new-competition-live-setting') { // 新增直播设置

    // }
    this.competitionPointDialogMode = mode;
    this.addOrUpdateCompetitionLiveSettingTitle =
      competitionPointDialogTitle2ch[mode] || "直播设置";

    this.initCompetitionLiveSettingForm(mode);

    this.dialogFormVisible = true;
  }

  private currentCompetitionLiveSettingItem: any = null;

  private competitionPointDialogMode: string = "";

  private async initCompetitionLiveSettingForm(mode: string) {
    const forms: any = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    if (mode == "edit-competition-live-setting") {
      const currentCompetitionLiveSettingItem = this.currentCompetitionLiveSettingItem;

      const {
        id,
        live_detail_title = '',
        live_detail_subtitle = '',
        pool_name = '',
        cover_image_id,
        cover_image_url,
        top_adv_image_id,
        top_adv_image_url,
        bottom_adv_image_id,
        bottom_adv_image_url,

        sort,
        show_status,
        is_tv,
      } = currentCompetitionLiveSettingItem || {};

      console.log(currentCompetitionLiveSettingItem, 'currentCompetitionLiveSettingItem')

      Object.assign(forms, {
        live_detail_title,
        live_detail_subtitle,
        pool_name,
        sort,
        show_status,
        is_tv,
      });

      if (cover_image_id && cover_image_url) {
        forms.study_picture = [
          {
            ...createPicItem(cover_image_id, cover_image_url)
          }
        ]
      }

      if (top_adv_image_id && top_adv_image_url) {
        forms.study_top_ad_picture = [
          {
            ...createPicItem(top_adv_image_id, top_adv_image_url)
          }
        ]
      }

      if (bottom_adv_image_id && bottom_adv_image_url) {
        forms.study_bottom_ad_picture = [
          {
            ...createPicItem(bottom_adv_image_id, bottom_adv_image_url)
          }
        ]
      }

      // switch (media_type) {
      //   case 0: // 图片
      //     forms.study_picture = media_list.map((item: any) => {
      //       const { url, id } = item || {};

      //       return createPicItem(id, url);
      //     });
      //     break;
      //   case 1: // 视频
      //     forms.study_video = media_list.map((item: any) => {
      //       const { url, id } = item || {};

      //       return createVideoItem(id, url);
      //     });
      //     break;
      // }

      forms.league_live_detail_id = id;
    }

    console.log(forms, "forms");

    this.forms = forms;
  }

  private handleWakeEditCompetitionLiveSettingDialog(competitionPointItem: any) {
    this.currentCompetitionLiveSettingItem = competitionPointItem;

    this.handleWakeCompetitionLiveSettingDialog("edit-competition-live-setting");
  }

  private handleDelteCompetitionLiveSettingDialog(competitionPointItem: any) {
    this.$confirm("确定删除该直播设置?", "", {
      ...shareConfirmOptions,
    }).then(() => {
      this.competitionLiveSettingApi.deleteCompetitionLiveSetting(
        {
          league_live_detail_id: competitionPointItem.id,
        },
        () => {
          this.getCompetitionLiveSettings();
        },
        () => {}
      );
    });
  }

  // 排序
  private handleLimitUserSort(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        // const limit_val = Math.min(Number(new_val), maxBenefitRate).toString();
        this.$set(this.forms, "sort", new_val);
      },
      (new_val: string) => {
        this.$set(this.forms, "sort", new_val);
      }
    );
  }

  private sumbitCompetitionLiveSettingLoading: boolean = false;

  private dialogLiveDeviceFormsVisible: boolean = false

  // 提交直播设置配置
  private handleSumbitCompetitionLiveSetting() {
    const competitionPointDialogMode = this.competitionPointDialogMode;

    const params = this.getCompetitionLiveSettingFormsParams(
      competitionPointDialogMode
    );

    console.log(params, "params");
    if (params === false) return;

    this.sumbitCompetitionLiveSettingLoading = true;
    switch (competitionPointDialogMode) {
      case "new-competition-live-setting":
        this.competitionLiveSettingApi.addCompetitionLiveSetting(
          params,
          () => {
            this.getCompetitionLiveSettings();
            this.dialogFormVisible = false;
            this.sumbitCompetitionLiveSettingLoading = false;
          },
          () => {
            this.sumbitCompetitionLiveSettingLoading = false;
          }
        );
        break;
      case "edit-competition-live-setting":
        this.competitionLiveSettingApi.updateCompetitionLiveSetting(
          params,
          () => {
            this.getCompetitionLiveSettings();
            this.dialogFormVisible = false;
            this.sumbitCompetitionLiveSettingLoading = false;
          },
          () => {
            this.sumbitCompetitionLiveSettingLoading = false;
          }
        );
        break;
    }
  }

  private refreshCompetitionLiveSettingDetail(params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.competitionLiveSettingApi.getCompetitionLiveSettingDetail({
        ...params
      }, (res: any) => {
        resolve(res.data || {})
      }, (err: any) => {
        reject(err)
      })
    })
  }

  private chooseBindDeviceList: any[] = []

  private handleChangeShopInfo(shop: any) {
    console.log(shop, 'shop change')
    this.refreshShopDeviceList({
      shop_id: shop.id
    })
  }

  private refreshShopDeviceList(params: any) {
    return new Promise((resolve, reject) => {
      this.competitionLiveSettingApi.getCompetitionLiveDeviceList({
        ...params,
        page: 1,
        limit: 999
      }, (res: any) => {
        const {
          data
        } = res || {}

        const {
          list = []
        } = data || {}

        resolve(list)
      }, (err: any) => {
        reject(err)
      })
    }).then((list: any) => {
      this.chooseBindDeviceList = list

      return list
    })
  }

  private curLiveCompetitionDetailItem: any
  // 直播设备绑定弹框
  private async handleWakeLiveBindDeviceDialog(liveComptitionDetailItem: any) {
    this.curLiveCompetitionDetailItem = liveComptitionDetailItem

    const {
      id
    } = liveComptitionDetailItem || {}

    const {
      shop_id,
      shop_name,

      show_shop_monitor_id,
      status,
      // pool_table_name
    } = await this.refreshCompetitionLiveSettingDetail({
      league_live_detail_id: id
    })

    const liveDeviceForms = {
      ...this.deepLoopUtil.deepCopy(defaultLiveDeviceForms, false),

      status
    }

    await this.getMoreVenueList(true);


    if (shop_id) {
      const venue: any = {};

      const handleAddExtraVenue = (venue: any[], id: any, name: any) => {
        if (name && typeof id == "number") {
          let existVenue = this.venueList.find((venue) => venue.id == id);

          if (!existVenue) {
            existVenue = {
              id,
              name,
            };
            this.venueList.push(existVenue);
          }

          Object.assign(venue, {
            ...existVenue
          })
        }
      };

      handleAddExtraVenue(venue, shop_id, shop_name);

      Object.assign(liveDeviceForms, {
        venue,
        venue_id: shop_id
      })

      if (show_shop_monitor_id) {
        await this.refreshShopDeviceList({
          shop_id
        })

        Object.assign(liveDeviceForms, {
          show_shop_monitor_id
        })
      }
    }

    console.log(liveDeviceForms, 'liveDeviceForms')


    this.liveDeviceForms = liveDeviceForms

    this.dialogLiveDeviceFormsVisible = true
  }


  private sumbitLiveBindDeviceInfoLoading: boolean = false
  private handleSumbitLiveBindDeviceInfo() {
    const params = this.getLiveBindDeviceParams()

    if (typeof params != 'object') return

    this.sumbitLiveBindDeviceInfoLoading = true
    this.competitionLiveSettingApi.updateLiveDeviceBind(params, () => {
      this.getCompetitionLiveSettings();
      this.sumbitLiveBindDeviceInfoLoading = false

      this.dialogLiveDeviceFormsVisible = false
    }, () => {
      this.sumbitLiveBindDeviceInfoLoading =false
    })
  }

  private getLiveBindDeviceParams() {
    const params: any = {}

    const liveDeviceForms = this.liveDeviceForms

    const curCompetitionDetailItem = this.curLiveCompetitionDetailItem

    console.log(liveDeviceForms, 'liveDeviceForms')

    const {
      venue,
      show_shop_monitor_id,
      status
    } = liveDeviceForms || {}

    Object.assign(params, {
      league_live_detail_id: curCompetitionDetailItem.id,
      status
    })

    if ((venue && venue.id)) {
      params.shop_id = venue.id
    } else {
      this.$message.error('请选择店铺')
      return false
    }

    if (show_shop_monitor_id) {
      params.show_shop_monitor_id = show_shop_monitor_id
    } else {
      this.$message.error('请选择直播设备')
      return false
    }

    return params
  }


   /** 远程搜索店铺 */
  private venueList: any[] = [
    // {
    //   id: 1,
    //   label: '测试'
    // },
    // {
    //   id: 2,
    //   label: '测试2'
    // }
  ];

  private searchVenueLoading: boolean = false;

  private remoteGetVenueQueryString: string = "";

  private remoteSearchVenueTimer: any = null;

  private searchVenuePageOption: any = {};

  private initRemoteGetVenuePageOption() {
    this.searchVenuePageOption = {
      ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    };
  }

  private getSearchVenueParams() {
    const searchVenuePageOption = this.searchVenuePageOption;

    const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

    const { page, limit } = searchVenuePageOption;

    const params: any = {
      page,
      limit,
    };

    if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
      params.name = remoteGetVenueQueryString.trim();
    }

    return params;
  }

  private getMoreVenueList(refresh: boolean = true) {
    return new Promise((resolve: Function, reject: Function) => {
      if (refresh) {
        this.initRemoteGetVenuePageOption();
      }

      const params = this.getSearchVenueParams();

      this.competitionLiveSettingApi.searchVenueList(
        params,
        (res: any) => {
          console.log(res, "res");

          const venueList: any[] = res.data || [];

          this.venueList = refresh
            ? venueList
            : this.venueList.concat(venueList);

          if (Array.isArray(res.data) && res.data.length == 0) {
            this.searchVenuePageOption.isRearchBottom = true;
          }
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          resolve();
        },
        () => {
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          reject();
        }
      );
    });
  }

  // 第一次搜索 无关键字
  private handleFirstGetVenueList() {
    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 清空搜索
  private handleClearGetVenueList() {
    this.remoteGetVenueQueryString = "";

    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 远程搜索
  private remoteGetVenueMethod(query: string) {
    query = query.trim ? query.trim() : "";

    if (query !== "") {
      this.remoteGetVenueQueryString = query;
      clearTimeout(this.remoteSearchVenueTimer);

      this.remoteSearchVenueTimer = setTimeout(() => {
        this.searchVenueLoading = true;

        this.getMoreVenueList();
        
      }, 300);
    } else {
      this.remoteGetVenueQueryString = "";
      this.venueList = [];
    }
  }

  private addVenueListLoading: boolean = false;

  private remoteAddVenueMethod() {
    if (this.addVenueListLoading) return;
    const searchVenuePageOption = this.searchVenuePageOption;

    const { isRearchBottom } = searchVenuePageOption;

    if (isRearchBottom) return;

    this.searchVenuePageOption.page++;

    this.addVenueListLoading = true;

    this.getMoreVenueList(false);
  }
  /** end 远程搜索店铺 */

  private getCompetitionLiveSettingFormsParams(mode: string) {
    const params: any = {
      league_live_id: this.leagueLiveId
    };

    const showErrorMessage = (msg: string) => {
      if (!!msg) {
        this.$message.error(msg);
      }
    };

    const forms = this.forms;

    const {
      league_live_detail_id,
      // name,
      // expired_days,
      // meituanId,
      // timeDuration
      // machine_number,
      // phone,
      // rank

   
      sort,
      show_status,
      is_tv,

      study_picture,
      study_top_ad_picture,
      study_bottom_ad_picture
    } = forms || {};

    const keys = ["live_detail_title", "live_detail_subtitle", "pool_name"];

    const key2EndKey: any = {
      live_detail_title: "live_detail_title",
      live_detail_subtitle: "live_detail_subtitle",
      pool_name: "pool_name"
   
    };

    const key2ErrMsg: any = {
      title: "直播标题不能为空",
      live_detail_subtitle: "副标题不能为空",
      pool_name: "球桌不能为空"
    };

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (forms[key]) {
        const errMsg = key2ErrMsg[`${key}_${forms[key]}`];
        if (errMsg) {
          showErrorMessage(errMsg);

          return false;
        }
        params[key2EndKey[key] || key] = forms[key];
      } else {
        showErrorMessage(key2ErrMsg[key]);

        return false;
      }
    }

    Object.assign(params, {
      sort,
      show_status,
      is_tv
    });

    console.log(study_picture, "study_picture");

    if (study_picture && study_picture.length > 0) {
      params.cover_image_id = study_picture[0].id
    } else {
      this.$message.error('请上传封面图')
      return false
    }

    if (study_top_ad_picture && study_top_ad_picture.length > 0) {
      params.top_adv_image_id = study_top_ad_picture[0].id
    } else {
      this.$message.error('请上传顶部广告图')
      return false
    }

    if (study_bottom_ad_picture && study_bottom_ad_picture.length > 0) {
      params.bottom_adv_image_id = study_bottom_ad_picture[0].id
    } else {
      this.$message.error('请上传顶部广告图')
      return false
    }
 
 
    if (mode == "edit-competition-live-setting") {
      params.league_live_detail_id = league_live_detail_id;
    }

    return params;
  }

  //获取直播设置列表
  private getCompetitionLiveSettings(): void {
    this.competitionLiveSettingApi.getCompetitionLiveSettingList(
      { ...this.getCompetitionLiveSettingParams() },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page;
        this.tablePage.prepage = res.data.prepage;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private getCompetitionLiveSettingParams(): any {
    const params: any = {
      league_live_id: this.leagueLiveId
    };

    const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    const { live_detail_title } = form;

    if (live_detail_title) {
      params.live_detail_title = live_detail_title;
    }

    // if (shop_type) {
    //   params.shop_type = shop_type
    // }

    if (page) {
      params.page = page;
    }

    if (prepage) {
      params.limit = prepage;
    }

    return params;
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };
}

import {
  categorys2routeList,
  getOriginMenuList,

  getRightMenuConfig,
  getConfigMapList
} from './asideMenu'


// 收集当前项目 菜单分类、页面、接口权限结构数据
export function createAsideMenuApisListData() {
  const categoryList: any[] = getOriginMenuList()

  const newCategoryMenuList: any[] = categoryList.map(({
    name,
    key,
  }: any) => {
    const newCategoryMenu: any = {
      menu_name: name,
      is_record: 1,
      is_show: 1,
      menu_key: key,
      children: []
    }

    return newCategoryMenu
  })

  categorys2routeList.forEach(async ( routeList: any, index) => {
    const pageMenuList: any[] = []
    for (const { path, meta } of routeList) {
      const {
        title,
        key,
        asideHidden,
        pageApisPrm
      } = meta || {}

      const pageApiList = await pageApisPrm

      const newPageMenu: any = {
        menu_name: title,
        menu_url: path,
        is_record: 1,
        is_show: asideHidden ? 0 : 1,
        menu_key: key,
        children: []
      }

      if (Array.isArray(pageApiList)) {
        newPageMenu.children.push(
          ...pageApiList.map(({
            url,
            name,
            label
          }: any) => {
            const newAPIAuth: any = {
              menu_name: label,
              menu_url: url,
              is_record: 1,
              is_show: 1,
              menu_key: name,
            }

            return newAPIAuth
          })
        )
      }

      pageMenuList.push(newPageMenu)
    }

    
    newCategoryMenuList[index].children.push(
      ...pageMenuList
    )
  })


  console.log(newCategoryMenuList, 'newCategoryMenuList')

  return newCategoryMenuList
}



// 合并 页面收集到的权限 以及 后台返回的配置权限
export async function mergeStrategyAddNewParams() {
  const defaulRightMenuApiList: any[] = createAsideMenuApisListData()
  const { list } = await getRightMenuConfig('batch')

  const mapList = getConfigMapList(list)

  function handleMergeList(sourceList: any[], mapList: Map<string, any>) {
    let startIndex: number = 0, i = 0

    const newList: any[] = []

    for (; i <= sourceList.length; ++i) {
      const sourceItem = sourceList[i]

      const {
        menu_key,
        children
      } = sourceItem || {}

      // console.log(sourceItem, 'sourceItem')
      // console.log(mapList, 'mapList')

      // console.log(menu_key, 'menu_key')

      if (mapList && mapList.has(menu_key)) {
        const configItem = mapList.get(menu_key)
        const mergeItem = {
          ...(sourceItem || {}),
          ...(configItem || {})
        }

        if (Array.isArray(children)) {
          mergeItem.children = handleMergeList(children, configItem.children)
        }

        if (startIndex <= i) {
          newList.push(
            ...(sourceList.slice(startIndex, i) || []),
            mergeItem
          )

          startIndex = i + 1
        }
      }
    }

    // if (sourceList[0].menu_url == '/admin/admin_department/list') {
    //   console.log(sourceList, 'sourceList')
    //   console.log(sourceList.slice(startIndex), 'sourceList.slice(startIndex)')
    //   console.log(startIndex, 'startIndex')
    //   console.log(i, 'i')
    // }

    if (startIndex <= i) {
      newList.push(
        ...(sourceList.slice(startIndex) || [])
      )
    }

    // console.log(newList, 'newList')

    return newList
  }

  // console.log(list, 'list')

  if (Array.isArray(list)) {
    if (list.length == 0) {
      return defaulRightMenuApiList
    }

    return handleMergeList(defaulRightMenuApiList, mapList)
  } else {
    return []
  }
}
import { BasicsClass } from '@/common/BasicsClass';

export default class CueLockerIncomeApi extends BasicsClass {
  public apiUrls: any = new Map([
    ['getCueLockerIncomeList', {
      url: '/admin/shopBox/order',
      name: 'list',
      label: '列表',
    }],
    ['exportExcelCueLockerIncomeList', {
      url: '/admin/shopBox/exportOrder',
      name: 'export-excel',
      label: '导出',
    }],
  ]);
  // 列表
  public getCueLockerIncomeList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls;

    const url = apiUrls.get('getCueLockerIncomeList').url;

    this.BasicPost(url, {
      ...params,
    }, false, false, false, successCb, failCb, showLoading);
  }

  // 导出
  public exportExcelCueLockerIncomeList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls;

    const url = apiUrls.get('exportExcelCueLockerIncomeList').url;

    this.BasicPost(url, {
      ...params,
    }, false, false, false, successCb, failCb, showLoading);
  }
}

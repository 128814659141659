
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import { CoachOrderApi } from "@/common/api/userManage/coachOrderList";

import { excuteDownloadExport2Excel } from "@/common/xlsx";

import {
  brandType2Ch
} from '@/common/config'
export const pageApiList = getPageAPIs(CoachOrderApi);

import { userLevel2ch } from "@/common/config";

const defaultForm = {
  searchKw: "", // 搜索订单号
  order_id: "", // 订单ID
  user_id: "", // 用户ID

  listSortType: -1, // 顾客订单状态

  orderPlaformType: -1, // 类别
  user_phone: "", // 用户手机号
  coach_phone: "", // 助教手机号
  coach_id: "", // 助教ID
  channelId: "",
  shop_name: "", // 店铺名称
  create_time: [], // 创建时间
  finish_time: [], // 完成时间
};

// 设备:0=未知,1=ios,2=安卓,3=台球小程序,4=收银系统web端,5=棋牌小程序 6麦力士
const orderDeviceType2ch: any = {
  0: "未知",
  1: "8号玩家APP IOS",
  2: "8号玩家APP Android",
  3: "熊猫球社小程序",
  // 4: "收银台",
  // 5: "熊猫国粹馆小程序",
  6: "众享小程序"
};

const payType2ch: any = {
  "1": "微信小程序",
  "2": "微信app",
  "3": "支付宝app",
  "4": "账户支付",
};



// 助教等级 1初级助教 2中级助教 3高级助教 4金牌助教 5初级教练 6中级教练 7高级教练 8金牌教练
const coachLevel2Type: any[] = [
  "",
  "初级助教",
  "中级助教",
  "高级助教",
  "金牌助教",
  "初级教练",
  "中级教练",
  "高级教练",
  "金牌教练",
];

// 状态：1 提交订单 2 已付款 3 已取消 4 超时取消 5已拒绝 6已接单 7已开始 8已完成 9提前结束
const userOrderStatus2ch = [
  "",
  "待付款",
  "已预约",
  "已取消",
  "超时取消",
  "已拒绝",
  "已接单",
  "进行中",
  "已完成",
  "提前结束",
];

@Component({
  filters: {
    getStatusName(status: number) {
      return userOrderStatus2ch[status] || "";
    },
    getCoachLevelName(level: number) {
      return coachLevel2Type[level] || "";
    },

    showCoachBrandTypeName(brandType: number) {
      return brandType2Ch[brandType] || '未知'
    }
  },
})
export default class OrderList extends Vue {
  private coachOrderApi: any;

  private deepLoopUtil: any;

  constructor() {
    super();
    this.coachOrderApi = new CoachOrderApi();

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
  }

  private form: any;

  private firstLoading: boolean = true;

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  /**订单状态，
   * 1 提交订单  未支付
   * 2 已付款 已预约，
   * 3 已取消,
   * 4 超时取消，
   * 
   * 
   * 7 进行中，
   * 8 已完成
  
    暂时未使用的状态
    5 未接单 已拒绝，
    6 已接单，
    9 提前结束 
  */
  private sortTypeList: any[] = [
    {
      value: -1,
      label: "全部",
    },
    {
      label: "待付款",
      value: 1,
    },
    {
      label: "已预约",
      value: 2,
    },
    {
      label: "已取消",
      value: 3,
    },
    {
      label: "超时取消",
      value: 4,
    },
    // {
    //   label: "已完成",
    //   value: 5,
    // },
    {
      label: "进行中",
      value: 7,
    },
    {
      label: "已完成",
      value: 8,
    },
    {
      label: "提前结束",
      value: 9,
    },
    // {
    //   label: "申请退款成功",
    //   value: 7,
    // },
    // {
    //   label: "退款失败",
    //   value: 8,
    // },
    // {
    //   label: "申请退款",
    //   value: 9,
    // },
    // {
    //   label: "申请取消订单",
    //   value: 10,
    // },
  ];

  private orderPlaformTypeList: any = [
    {
      label: "全部",
      value: -1,
    },
    {
      label: "ios",
      value: 1,
    },
    {
      label: "安卓",
      value: 2,
    },
    {
      label: "小程序",
      value: 3,
    },
  ];

  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    const params: any = this.getOrderTableListParams();
    this.coachOrderApi.getOrderTableList(
      params,
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        /** ！！！！  后端 列表接口表示 总数的字段 统一 改为 count  再传total 打回去 重新改  ！！！！  */
        /** ！！！！  后端 列表接口表示 总数的字段 统一 改为 count  再传total 打回去 重新改  ！！！！  */
          // 该处是遗留页面，暂时不好修改
        const { list, total, page, prepage } = data || {};
        /** ！！！！ end 后端 列表接口表示 总数的字段 统一 改为 count  再传total 打回去 重新改  ！！！！  */
        /** ！！！！ end 后端 列表接口表示 总数的字段 统一 改为 count  再传total 打回去 重新改  ！！！！  */

        const newTableList = Array.isArray(list)
          ? list.map(this.handleTableData)
          : [];

        this.$set(this.tablePage, "totalCount", total);
        this.$set(this.tablePage, "pageNo", params.page);
        this.$set(this.tablePage, "pageSize", params.limit);

        this.tableData = newTableList;

        this.firstLoading = false;
      },
      () => {
        this.firstLoading = false;
      },
      false
    );
  }

  private getOrderTableListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const firstLoading = this.firstLoading;

    const {
      searchKw, // 搜索订单号
      order_id, // 订单ID
      coach_id, // 助教ID
      user_id, // 用户ID

      user_phone, // 用户手机号
      coach_phone, // 助教手机号

      listSortType, // 顾客订单状态
      channelId,
      shop_name,
      create_time, // 创建时间
      finish_time, // 完成时间
    } = form || {};

    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};

    if (searchKw) {
      outerParams.coach_no = searchKw;
    }

    if (order_id) {
      outerParams.coach_order_id = order_id;
    }

    if (user_id) {
      outerParams.user_id = user_id - 0;
    }

    if (coach_id) {
      outerParams.coach_id = coach_id - 0;
    }

    if (user_phone) {
      outerParams.user_phone = user_phone;
    }

    if (coach_phone) {
      outerParams.coach_phone = coach_phone;
    }

    if (channelId) {
      outerParams.channelId = channelId;
    }

    if (shop_name) {
      outerParams.shop_name = shop_name;
    }

    if (listSortType != -1 && typeof listSortType == "number") {
      outerParams.status = listSortType;
    }

    if (Array.isArray(create_time) && create_time.length === 2) {
      const [startDate, endDate] = create_time;

      console.log(create_time, "create_time");

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      outerParams.created_start = startTime;

      outerParams.created_end = endTime;
    }

    if (Array.isArray(finish_time) && finish_time.length === 2) {
      const [startDate, endDate] = finish_time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      outerParams.over_start = startTime;

      outerParams.over_end = endTime;
    }

    const route = this.$route;

    const { query } = route;

    const { userId } = query;

    if (userId) {
      outerParams.userId = userId;
    }

    if (
      mode == "exportExcel" &&
      !(outerParams.created_start && outerParams.created_end)
    ) {
      this.$message.error("请选择创建时段导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      outerParams.__isUnique = !firstLoading;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  // private getOrderSpendMoney(row: any) {
  //   const { price, refunded_price } = row;

  //   return (price - refunded_price).toFixed(2);
  // }

  // 后台返回的字段 转化为前端定义的字段
  private handleTableData(data: any): any {
    const { device, pay_type, user_type } = data;
    const newData = {
      ...data,
      device_name: orderDeviceType2ch[device],
      pay_type_name: payType2ch[pay_type],
      user_level_type_name: userLevel2ch[user_type],
    };

    return newData;
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 订单字段名 文案对应关系
  private orderExcelList2ch: any = {
    id: "订单ID",
    coach_no: "订单编号",
    created_at: "创建时间",
    pay_time: "付款时间",
    cancel_time: "取消时间",
    over_time: "结束时间",
    reserve_start: "预约开始时间",
    reserve_end: "预约结束时间",
    user_name: "用户昵称",
    user_id: "用户ID",
    user_phone: "用户手机号",
    user_level_type_name: "用户会员等级",

    coach_name: "助教昵称",
    coach_id: "助教ID",
    coach_phone: "助教手机号",
    level_name: "助教等级",
    device_name: "下单渠道",
    shop_name: "店铺名称",
    _brand_type_name: "所属品牌",
    status_name: "顾客订单状态",
    order_duration: "订单时长（分钟）",
    taxi_price: "车费",
    price: "助教费用",

    pay_type_name: "支付渠道",
    total_price: "支付金额",
    coach_rate: "助教分成比例",
    coach_income: "助教收益",
    use_minute: "退款后实际使用时长（分钟）",
    total_actual_price: "扣款",
    total_refunded_price: "退回金额",
  };
  // 订单Excel 表头的先后顺序
  private orderExcelSortList: string[] = [
    "id",
    "coach_no",
    "created_at",
    "pay_time",
    "cancel_time",
    "over_time",
    "reserve_start",
    "reserve_end",
    "user_name",
    "user_id",
    "user_phone",
    "user_level_type_name",

    "coach_name",
    "coach_id",
    "coach_phone",
    "level_name",
    "shop_name",
    "_brand_type_name",
    "device_name",
    "status_name",
    "order_duration",
    "taxi_price",
    "price",

    "pay_type_name",
    "total_price",
    "coach_rate",
    "coach_income",
    "use_minute",
    "total_actual_price",
    "total_refunded_price",
  ];

  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getOrderTableListParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.coachOrderApi.exportExcelOrderList(
      params,
      (res: any) => {
        const {
          created_start: created_start_time,
          created_end: created_end_time,
          over_start: over_start_time,
          over_end: over_end_time,
        } = params || {};

        const create_time_duration_name =
          created_start_time && created_end_time
            ? `创建时间${created_start_time}到${created_end_time}`
            : "";

        const finish_time_duration_name =
          over_start_time && over_end_time
            ? `完成时间${over_start_time}到${over_end_time}`
            : "";
        const filename = `助教订单列表 ${create_time_duration_name} ${finish_time_duration_name}.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const {
                user_type,
                // price,
                // refunded_price,
                device,
                pay_type,
                // daytime_minute,
                // night_minute,
                status,
                level,
                brandType,
              } = item || {};

              const newItem = {
                ...(item || {}),
                device_name: orderDeviceType2ch[device] || '',
                pay_type_name: payType2ch[pay_type] || '',
                // total_miniute: daytime_minute + night_minute,
                // actual_price: price - refunded_price || "",
                user_level_type_name: userLevel2ch[user_type] || '',
                status_name: userOrderStatus2ch[status] || '',
                level_name: coachLevel2Type[level] || '',

                _brand_type_name: brandType2Ch[brandType] || ''
              };

              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const orderExcelSortList = this.orderExcelSortList;

          const orderExcelList2ch = this.orderExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            orderExcelSortList,
            orderExcelList2ch
          );

          // console.log(newList, "newList");

          // console.log(excelJSONData, "excelJSONData");

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */

  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
    // this.form = new Util().clearObject(this.form);
    // this.$router.replace("/orderList");
    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}

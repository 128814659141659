import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export  class SystemOptionLogAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getLogList', {
            url: '/admin/adminLog/lists',
            name: 'list',
            label: '列表'
        }],
        ['exportExcelOptionLogList',  {
            url: '/admin/adminLog/export',
            name: 'export-excel',
            label: '导出'
        }],
       
    ])
    
    // 操作日志列表
    public getLogList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getLogList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 导出操作日志列表
    public exportExcelOptionLogList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('exportExcelOptionLogList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

}
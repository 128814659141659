
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
// import { screen } from "@/common/screen/screen";
import RecommandWithdrawAuditAPI from "@/common/api/financeManage/recommanderPartnerWithdrawAccountAudit";
import { CommonApi } from "@/common/api/common";
import { excuteDownloadExport2Excel } from "@/common/xlsx";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import { utils } from "xlsx";

import gallery from "@/components/gallery.vue";

import {
  shareConfirmOptions
} from '@/common/config'


export const pageApiList = getPageAPIs(RecommandWithdrawAuditAPI)


const transformGender = ["", "女", "男"];

// 审核状态 2:审核中3:审核通过5:审核未通过
const withdrawAccountAudit2Text: any = ["", "", "审核中", "审核通过", "", "审核未通过"];


const defaultForm: any = {
  phone: "",
  status: 2, // 状态  2:审核中3:审核通过5:审核未通过
  time: []
}

@Component({
  components: {
    gallery,
  },
  filters: {
    refundAuditStatusText(statusNum: any): any {
      return withdrawAccountAudit2Text[statusNum] || "未知状态";
    },
  },
})
export default class RecommandWithdrawAccountAudit extends Vue {
  private form: any = {
    // // type:  0, // 问题类型
    // user_id: "", // 用户ID
    // nickname: "", // 助教昵称
    // is_status: 1, // 状态
    // time: [], // 时段选择
  };
  private deepLoopUtil: any;

  constructor() {
    super()

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm, false),
    }
  }
  private recommandWithdrawAuditApi: any = new RecommandWithdrawAuditAPI();
  private created(): void {
    // this.getProblemTypes();
    this.search();
  }
  private getTable(): void {
   
    this.recommandWithdrawAuditApi.getList(
      {
        ...this.getWithdrawAccountAuditParams()
      },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.pageNo = res.data.page || this.tablePage.pageNo;
        this.tablePage.pageSize = res.data.prepage || this.tablePage.pageSize;
        this.tablePage.totalCount = res.data.count;
      }
    );
  }

  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }
  // private problemList: any[] = [
  //   ...problemList
  // ];

  private checkboxConfig: any = {
    // 只有审核中的 才能选中
    checkMethod: ({ row }: any) => {
      return row.status == 2
    }
  }

  private curChooseRows: any[] = []

  private onSelectAllCheckBox({ selection }: any) {
    console.log(selection, 'selection')

    if (Array.isArray(selection) && selection.length) {
      this.curChooseRows = selection
    } else {
      this.curChooseRows = []
    }

  }

  private onSelectCheckBox({ selection }: any) {
    console.log(selection, 'selection')
    if (Array.isArray(selection) && selection.length) {
      this.curChooseRows = selection
    } else {
      this.curChooseRows = []
    }
  }

  private allAuditPassLoading: boolean = false

  private handleAllAuditPass() {
    const curChooseRows = this.curChooseRows

    console.log(curChooseRows, 'curChooseRows')

    if (Array.isArray(curChooseRows) && curChooseRows.length) {
      this.$confirm(`确定选中全部通过审核?`, "", {
        ...shareConfirmOptions,
      }).then(() => {
        this.allAuditPassLoading = true

        const params = {
          ids: curChooseRows.map((row: any) => row.id).join(',')
        }

        this.recommandWithdrawAuditApi.batchAuditAgree(params, () => {
          this.allAuditPassLoading = false
          this.getTable()
        }, () => {
          this.allAuditPassLoading = false
        })
      })
    }
  }

  // 审核状态 1待审核 2审核通过 3驳回
  private statusList: any[] = [
    {
      label: "审核中",
      value: 2,
    },
    {
      label: "审核通过",
      value: 3,
    },
    {
      label: "审核未通过",
      value: 5,
    },
  ];

  private systemTypaName: any[] = ["未知", "安卓", "IOS", "小程序"];

  // 审核拒绝弹框
  private dialogFormRejectVisible: boolean = false

  private curRejectAuditItem: any

  private sumbitRejectAuditLoading: boolean = false

  private rejectForms: any = {
    reject_reason: ''
  }

  private handleSumbitRejectAudit() {
    const rejectForms = this.rejectForms

    const {
      reject_reason
    } = rejectForms || {}

    if (!reject_reason) {
      this.$message.error("请输入拒绝原因")
      return
    }

    const curRejectAuditItem = this.curRejectAuditItem

    this.sumbitRejectAuditLoading = true

    this.recommandWithdrawAuditApi.updateWithdrawAccountAuditStatus(
      {
        id: curRejectAuditItem.id,
        reject_reason,
        mode: 'disagree',
        // status: 1
      },
      () => {
        this.sumbitRejectAuditLoading = false

        this.dialogFormRejectVisible = false

        this.getTable();
      },
      () => {
        this.sumbitRejectAuditLoading = false
      }
    );
  }

  // 更新状态
  private handleUpdateWithdrawAccountAuditStatus(row: any, mode: string) {
    const modeMsgs: any = {
      agree: "通过",
      disagree: "不通过",
    };

    // const disArgeeConfirmOptions = {
    //   showInput: true,
      // inputPlaceholder: '请输入拒绝原因',
      // inputType: 'textarea'
    // }

    const confirmOptions = {
      ...shareConfirmOptions,
      
    }

    if (mode == 'disagree') {
      this.curRejectAuditItem = row

      this.rejectForms = {
        reject_reason: ''
      }

      this.dialogFormRejectVisible = true

      return 
    }
    this.$confirm(`确定${modeMsgs[mode]}该项申请?`, "", confirmOptions).then(() => {
      const tableData = this.tableData;

      const existRowIndex = tableData.indexOf(row);

      if (existRowIndex > -1) {
        row.__updateFeedbackStatusLoading = true;

        tableData.splice(existRowIndex, 1, row);

        this.recommandWithdrawAuditApi.updateWithdrawAccountAuditStatus(
          {
            id: row.id,
            mode,
            // status: 1
          },
          () => {
            row.__updateFeedbackStatusLoading = false;

            tableData.splice(existRowIndex, 1, row);

            this.getTable();
          },
          () => {
            row.__updateFeedbackStatusLoading = false;

            tableData.splice(existRowIndex, 1, row);
          }
        );
      }
    });
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

 
  private images: any = [];
 

  private getWithdrawAccountAuditParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;


    const {
      phone,
      status,
      time, // 
      // finish_time, // 完成时间
    } = form || {};


    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};

    if (phone) {
      outerParams.phone = phone;
    }

    if (typeof status == 'number' && status !== -1) {
      outerParams.status = status
    }

    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      outerParams.start_date = startTime;

      outerParams.end_date = endTime;
    }

    if (
      mode == "exportExcel" &&
      !(outerParams.start_date && outerParams.end_date)
    ) {
      this.$message.error("请选择日期导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      // outerParams.__isUnique = !firstLoading;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 字段名 文案对应关系
  private merchantExcelList2ch: any = {
    phone: "手机号码",
    user_name: "用户昵称",
    price: "提现金额",
    account_money: "剩余可提现金额",
    real_price: "实际到账金额",
    account_name: "提现账号",
    real_name: "支付宝实名",
    identity_card: "身份证号码",
    created_at: "提现时间",
    audit_time: "审核时间",
    audit_name: "审核人",
    __status: "状态"
  };
  // 商户Excel 表头的先后顺序
  private merchantExcelSortList: string[] = [
    "phone",
    "user_name",
    "price",
    "account_money",
    "real_price",
    "account_name",
    "real_name",
    "identity_card",
    "created_at",
    "audit_time",
    "audit_name",
    "__status"
  ];

  // 点击导出excel 表格
  private handleExportExcelRecommanderWithdrawAuditlList() {
    const params = this.getWithdrawAccountAuditParams('exportExcel')

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.recommandWithdrawAuditApi.exportRecommanderWithdrawAuditList(
      params,
      (res: any) => {
        const { start_date, end_date } = params || {};

        const filename = `运营合伙人提现审核列表 ${start_date}到${end_date}.xlsx`;
        const { data } = res || {};

        const {
          list: originList
        } = data || {}

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const { 
                status
               } = item || {};
              const newItem = {
                ...(item || {}),
                __status: withdrawAccountAudit2Text[status]
              };

              for (let key in item) {
                if (typeof item[key] == 'number') {
                  newItem[key] = item[key].toString()
                }
              }

             
              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const merchantExcelSortList = this.merchantExcelSortList;

          const merchantExcelList2ch = this.merchantExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            merchantExcelSortList,
            merchantExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */
}

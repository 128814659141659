import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

import { CommonApi } from "../common";

export class ClueSourceAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getClueSourceist', {
      url: '/admin/market/sourceList',
      name: 'list',
      label: '列表'
    }],
    // ['exportExcelSClueSourceList', {
    //   url: '/admin/seller_blacklist/exportList',
    //   name: 'export-excel',
    //   label: '导出'
    // }],
    ['addClueSource', {
      url: '/admin/market/sourceAdd',
      name: 'add',
      label: '添加线索来源'
    }],
    ['editClueSource', {
      url: '/admin/market/sourceEdit',
      name: 'edit',
      label: '编辑线索来源'
    }],
    ['deleteClueSource', {
      url: '/admin/market/sourceDel',
      name: 'delete',
      label: '删除线索来源'
    }],


  ])
  // api 方法
  // 招商线索来源列表
  public getClueSourceist(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getClueSourceist').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }


  // 导出招商线索来源列表
  // public exportExcelSClueSourceList(
  //   params: any,
  //   successCb: Function,
  //   failCb: Function,
  //   showLoading: boolean = false
  // ) {
  //   const apiUrls = this.apiUrls

  //   const url = apiUrls.get('exportExcelSClueSourceList').url

  //   this.BasicPost(url, {
  //     ...params
  //   }, false, false, false, successCb, failCb, showLoading)
  // }

  // 添加招商线索来源
  public addClueSource(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('addClueSource').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 编辑招商线索来源
  public editClueSource(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('editClueSource').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 删除招商线索来源
  public deleteClueSource(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('deleteClueSource').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

}

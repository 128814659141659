
import { Component, Prop, Vue, Watch } from "vue-property-decorator";


@Component
export default class PandaTable extends Vue {
  @Prop({
    type: Array,
    default: (): any[] => [
      
    ],
  })
  private tableData: any;

  @Prop({
    type: Array,
    default: (): any[] => [
      
    ],
  })
  private chooseFields: any;

  @Prop({
    type: [String, Number],
    default: ''
  })
  private cellWidth: any;

  
}

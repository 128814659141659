import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class MerchantCreateCompetitionDetailAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getDetail', {
            url: '/admin/league_courser/contestDetail',
            name: 'detail',
            label: '详情'
        }],
        
       
        // ['exportExcelRefundAuditList', {
        //     url: '/admin/seller/exportRefundList',
        //     name: 'export-excel',
        //     label: '导出'
        // }]
    ])
    
    // momo达到家-助教基本信息审核列表
    public getDetail(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getDetail').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

   

    // // 退款审核列表 导出
    // public exportExcelRefundAuditList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    //     const apiUrls = this.apiUrls

    //     const url = apiUrls.get('exportExcelRefundAuditList').url

    //     this.BasicPost(url, {
    //         ...params
    //     }, false, false, false, successCb, failCb, showLoading)
    // }
}
import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

import { CommonApi } from "../common";

export default class RecruitPartnerContactAPI extends BasicsClass {

  public apiUrls: any = new Map([
    ['getRecruitPartnerContactList', {
        url: '/admin/investment/index',
        name: 'list',
        label: '列表'
    }],
    ['handleRecruitPartnerContact',  {
        url: '/admin/investment/contact',
        name: 'contact-partner',
        label: '去联系合伙人'
    }],
    
  ])

  public getRecruitPartnerContactList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getRecruitPartnerContactList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }


  // 去联系合伙人
  public handleRecruitPartnerContact(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('handleRecruitPartnerContact').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

}

import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

import { CommonApi } from "../common";

export class CompetitionPkSetListAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getCompetitionPkSetList', {
      url: '/admin/league_courser/rivalList',
      name: 'list',
      label: '正赛列表'
    }],
     
    ['getPrecompetitionPkSetList', {
      url: '/admin/league_courser/rivalListQualifier',
      name: 'pre-list',
      label: '预选赛列表'
    }],

    ['getCompetitionPkSetLevelList', {
      url: '/admin/league_courser/rivalNode',
      name: 'level-list',
      label: '正赛场次'
    }],

    ['getPrecompetitionPkSetLevelList', {
      url: '/admin/league_courser/rivalNodeQualifier',
      name: 'pre-level-list',
      label: '预选赛场次'
    }],

    ['getPrecompetitionPkSetDetail', {
      url: '/admin/league_courser/rivalDetail',
      name: 'pk-set-detail',
      label: '比赛对局详情'
    }],

    ['updatePkSetDetail', {
      url: '/admin/league_courser/rivalEdit',
      name: 'edit-pk-set',
      label: '编辑比赛对局'
    }],

    ['getPrecompetitionPkJoinerList', {
      url: '/admin/league_courser/drawUserList',
      name: 'pk-joiner-list',
      label: '比赛参赛手列表'
    }],


    ['getShopPoolList', {
      url: 'admin/poolTable/lists',
      name: 'shop-pool-list',
      label: '店铺球桌列表'
    }]
  ])
  
  // api 方法
  // 正赛列表
  public getCompetitionPkSetList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getCompetitionPkSetList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 预选赛列表
  public getPrecompetitionPkSetList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getPrecompetitionPkSetList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 正赛场次列表
  public getCompetitionPkSetLevelList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getCompetitionPkSetLevelList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 预选赛场次列表
  public getPrecompetitionPkSetLevelList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getPrecompetitionPkSetLevelList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 比赛对局详情
  public getPrecompetitionPkSetDetail(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getPrecompetitionPkSetDetail').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 编辑比赛对局
  public updatePkSetDetail(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('updatePkSetDetail').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // getPrecompetitionPkJoinerList
  // 比赛参赛手列表
  public getPrecompetitionPkJoinerList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getPrecompetitionPkJoinerList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // getShopPoolList
  // 店铺球桌列表
  public getShopPoolList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getShopPoolList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}

import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class TechnicalServiceFeeListAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getList', {
            url: '/admin/platformIncome/lists',
            name: 'list',
            label: '列表'
        }],
        ['exportTechnicalServiceFeeList', {
            url: '/admin/platformIncome/export',
            name: 'export-excel',
            label: '导出'
        }],
    ])
    
    // 运营合伙人提现审核列表
    public getList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 导出运营合伙人提现审核列表 
    public exportTechnicalServiceFeeList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('exportTechnicalServiceFeeList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

}
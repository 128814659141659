import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

import { CommonApi } from "../common";

export class CoachLeaderSubordinateAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getCoachLeaderSubordinateList', {
      url: '/admin/coachLeader/tutor',
      name: 'list',
      label: '列表'
    }],
   
    ['addCoachLeaderSubordinate', {
      url: '/admin/coachLeader/bind',
      name: 'add-subordinate',
      label: '添加绑定助教'
    }],
    // ['editCoachLeaderSubordinate', {
    //   url: '/admin/seller_blacklist/edit',
    //   name: 'edit',
    //   label: '编辑黑名单'
    // }],
    ['deleteCoachLeaderSubordinate', {
      url: '/admin/coachLeader/unbind',
      name: 'delete-subordinate',
      label: '解绑助教'
    }],


  ])
  // api 方法
  // 助教队长属下列表
  public getCoachLeaderSubordinateList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getCoachLeaderSubordinateList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

 


  // 添加助教队长属下
  public addCoachLeaderSubordinate(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('addCoachLeaderSubordinate').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 编辑助教队长属下
  // public editCoachLeaderSubordinate(
  //   params: any,
  //   successCb: Function,
  //   failCb: Function,
  //   showLoading: boolean = false
  // ) {
  //   const apiUrls = this.apiUrls

  //   const url = apiUrls.get('editCoachLeaderSubordinate').url

  //   this.BasicPost(url, {
  //     ...params
  //   }, false, false, false, successCb, failCb, showLoading)
  // }

  // 解绑助教队长属下
  public deleteCoachLeaderSubordinate(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('deleteCoachLeaderSubordinate').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
  
}

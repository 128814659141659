import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class CoachTaxiCostStatisticAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['taxiCostList', {
            url: '/admin/coachTaxiPrice/list',
            name: 'list',
            label: '列表'
        }],
    
        ['exportExcelCoachTaxiCostList', {
            url: '/admin/coachTaxiPrice/exportList',
            name: 'export-excel',
            label: '导出'
        }]
    ])

    // 助教车费数据统计列表
    public taxiCostList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
      const apiUrls = this.apiUrls

      const url = apiUrls.get('taxiCostList').url

      this.BasicPost(url, {
          ...params
      }, false, false, false, successCb, failCb, showLoading)
    }

    // 导出助教车费数据统计列表
    public exportExcelCoachTaxiCostList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('exportExcelCoachTaxiCostList').url
  
        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

}

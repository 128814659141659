import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class MutipleShopApplySameAuthAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getMutipleShopAuthList', {
      url: '/admin/mtShopBind/list',
      name: 'list',
      label: '列表'
    }],
    
    ['addMutipleShopAuth', {
      url: '/admin/mtShopBind/add',
      name: 'add-mutiple-shop-auth',
      label: '添加美团多店铺授权'
    }],

    ['deleteMutipleShopAuth', {
      url: '/admin/mtShopBind/delete',
      name: 'delete-mutiple-shop-auth',
      label: '删除美团多店铺授权'
    }],
   
    ['searchVenueList', {
      url: '/admin/coupons/shop/list',
      name: 'shop-list',
      label: '店铺列表',
      // hidden: true
    }],

    ['searchAuthVenueList', {
      url: '/admin/mtShopBind/mtAuthList',
      name: 'auth-venue-list',
      label: '授权店铺列表',
    }]
  ])

  // 美团多店铺授权列表
  public getMutipleShopAuthList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getMutipleShopAuthList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 添加美团多店铺授权
  public addMutipleShopAuth(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('addMutipleShopAuth').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 添加美团多店铺授权
  public deleteMutipleShopAuth(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('deleteMutipleShopAuth').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 搜索店铺列表
  public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('searchVenueList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 搜索授权店铺列表
  public searchAuthVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('searchAuthVenueList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}
import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class SingleMemberQrcodeListAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getSingleMemberQrcodeList', {
      url: '/admin/shopMember/qrCodeList',
      name: 'list',
      label: '列表'
    }],

    ['getSingleMemberQrcodeDownload', {
      url: '/admin/shopMember/qrCode',
      name: 'single-member-qrocde',
      label: '下载单店卡二维码'
    }],
    
    // ['exportExcelSingleMemberQrcodeList', {
    //   url: '/admin/shopMember/exportAccountList',
    //   name: 'export-excel',
    //   label: '导出'
    // }],
   
    ['searchVenueList', {
      url: '/admin/coupons/shop/list',
      name: 'shop-list',
      label: '店铺列表',
      // hidden: true
    }],
  ])

  // 单店卡二维码列表
  public getSingleMemberQrcodeList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getSingleMemberQrcodeList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 单店卡二维码
  public getSingleMemberQrcodeDownload(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getSingleMemberQrcodeDownload').url

    this.BasicPost(url, {
      ...params
    }, false, false, {
      responseType: 'blob'
    }, successCb, failCb, showLoading)
  }

  // 导出单店卡二维码列表
  // public exportExcelSingleMemberQrcodeList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
  //   const apiUrls = this.apiUrls

  //   const url = apiUrls.get('exportExcelSingleMemberQrcodeList').url

  //   this.BasicPost(url, {
  //     ...params
  //   }, false, false, false, successCb, failCb, showLoading)
  // }

  // 搜索店铺列表
  public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('searchVenueList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}
import {
  competitionRoutes,
  limitTimeActiviesRoutes,
  rankingOptionsRoutes,
  coachOptionsRoutes,
  recommanderPartnerRoutes,
  saleMachineRoutes,
  couponAndmemberAndDiamondRoutes,
  socialManageRoutes,
  venueSettingRoutes,
  websiteOperateManageRoutes,
  chatManageRoutes
} from './operationManageSub'


/** 注意： meta  key 一旦确定不可修改 且 同级唯一。 是作为识别这个对象的唯一标识！！！   */

// 运营管理
export const operationManageRoutes = [
  ...competitionRoutes,
  {
    path: '/openOrderDurationList',
    name: 'openOrderDurationList',
    meta: {
      title: "开台时长榜",
      key: 'open-order-duration-list',
      pageApisPrm: import("@/views/operationManage/openOrderDurationList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "@/views/operationManage/openOrderDurationList.vue"
      ),
  },
  // guessingGamePraizesRecord 有奖竞猜记录
  {
    path: '/guessingGamePraizesRecord',
    name: 'guessingGamePraizesRecord',
    meta: {
      title: "有奖竞猜记录",
      key: 'guessing-game-praizes-record',
      pageApisPrm: import("@/views/operationManage/guessingGamePraizesRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "@/views/operationManage/guessingGamePraizesRecord.vue"
      ),
  },
  // 个人竞猜详情
  {
    path: '/guessingGamePersonalDetail',
    name: 'guessingGamePersonalDetail',
    meta: {
      title: "个人竞猜详情",
      key: 'guessing-game-personal-detail',
      asideHidden: true, // 侧边栏不显示
      pageApisPrm: import("@/views/operationManage/guessingGamePersonalDetail.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "@/views/operationManage/guessingGamePersonalDetail.vue"
      ),
  },
  {
    path: '/guessingGamePraizesSetting',
    name: 'guessingGamePraizesSetting',
    meta: {
      title: "有奖竞猜设置",
      key: 'guessing-game-praizes-setting',
      pageApisPrm: import("@/views/operationManage/guessingGamePraizesSetting.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "@/views/operationManage/guessingGamePraizesSetting.vue"
      ),
  },

  {
    path: '/businessReminderList',
    name: 'businessReminderList',
    meta: {
      title: "运营提醒设置",
      key: 'business-reminder-list',
      pageApisPrm: import("@/views/operationManage/businessReminderList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "@/views/operationManage/businessReminderList.vue"
      ),
  },
  // 大屏数据
  {
    path: '/boardcastBigViewList',
    name: 'boardcastBigViewList',
    meta: {
      title: "宣传大屏数据管理",
      key: 'boardcast-big-view-list',
      pageApisPrm: import("@/views/operationManage/boardcastBigViewList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "@/views/operationManage/boardcastBigViewList.vue"
      ),
  },
  // 礼物管理
  {
    path: '/coachGiftManage',
    name: 'coachGiftManage',
    meta: {
      title: "礼物管理",
      key: 'coach-gift-manage',
      pageApisPrm: import("@/views/operationManage/coachGiftManage.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "@/views/operationManage/coachGiftManage.vue"
      ),
  },
  // 话题管理
  {
    path: '/coachTopicManage',
    name: 'coachTopicManage',
    meta: {
      title: "话题管理",
      key: 'coach-topic-manage',
      pageApisPrm: import("@/views/operationManage/coachTopicManage.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "@/views/operationManage/coachTopicManage.vue"
      ),
  },

  // brilliantShowList
  {
    path: '/brilliantShowList',
    name: 'brilliantShowList',
    meta: {
      title: "精彩球列表",
      key: 'brilliant-show-list',
      pageApisPrm: import("@/views/operationManage/brilliantShowList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "@/views/operationManage/brilliantShowList.vue"
      ),
  },

  {
    path: '/userGradeAduitManage',
    name: 'userGradeAduitManage',
    meta: {
      title: "档位测试及认证",
      key: 'user-grade-aduit-manage',
      pageApisPrm: import("@/views/operationManage/userGradeAduitManage.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "operationManage" */ "@/views/operationManage/userGradeAduitManage.vue"
      ),
  },


  ...limitTimeActiviesRoutes,

  // openTimeLevelRewardRecord

  ...rankingOptionsRoutes,
  ...coachOptionsRoutes,
  ...recommanderPartnerRoutes,

  ...saleMachineRoutes,
  // saleMachineBlackUserList
  ...couponAndmemberAndDiamondRoutes,
  // recommanderManage
  // saleMachineBlackUserList

  ...venueSettingRoutes,
  ...socialManageRoutes,

  ...websiteOperateManageRoutes,
  ...chatManageRoutes

]

import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList,getPageAPIs } from "@/common/util";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import {CompetitionPkSetListAPI} from "@/common/api/competitionManage/competitionPkSetList";

// import ZlSelect from "@/components/third-custom-components/select";

// import ZlOption from "@/components/third-custom-components/option";


import { excuteDownloadExport2Excel } from "@/common/xlsx";
export const pageApiList = getPageAPIs(CompetitionPkSetListAPI);

import {
  shareConfirmOptions,
  leagueType2Text,

  // getCommonList
} from '@/common/config'

const defaultForm = {
  // mobile: "", // 手机号
  // realname: "", // 真实名称
  // is_status: 0, // 全部

  // time: [], // 时间

};

const defaultForms = {
  league_draw_id: '',
  qualifier_type: '',

  league_time: '',
  pool_id: '',

  left_phone: '',
  left_user_name: '',
  left_user_avatar: '',

  right_phone: '',
  right_user_name: '',
  right_user_avatar: '',

  left_score: '',
  right_score: '',
  

  success_user_point: 0, // 胜利者 0未设置 1左 2右
}

// const defaultSearchVenuePageOption = {
//   page: 1,
//   limit: 10,
//   isRearchBottom: false,
// };

// const transfromGenders: any = [
//   '',
//   '女',
//   '男'
// ]

const leftWinnerSetting: number = 1
const rightWinnerSetting: number = 2

@Component({
  filters: {
    // showUserMemberTypeName: function(type: number) {
    //   return transferUserLevel2ch[type] || ''
    // }
   
  },
  components: {
    // ZlSelect,
    // ZlOption,
  },
})
export default class AllCompetitionPartnerList extends Vue {
  private competitionPkSetListApi: any;

  private deepLoopUtil: any;

  // private utilIns: any;

  // private debounceRefreshSingleCardInfoFunc: any

  private get competitionTitleName() {
    const route = this.$route

    const {
      query
    } = route || {}

    const {
      name
    } = query || {}

    return name || ''
  }

  private get competitionMode() {
    const route = this.$route

    const {
      query
    } = route || {}

    const {
      mode
    } = query || {}

    return mode || ''
  }

  private get competitionId() {
    const route = this.$route

    const {
      query
    } = route || {}

    const {
      ctid
    } = query || {}

    return ctid || ''
  }

  private get shopId() {
    const route = this.$route

    const {
      query
    } = route || {}

    const {
      spid
    } = query || {}

    return spid || ''
  }

  private get idOrLevel() {
    const route = this.$route

    const {
      query
    } = route || {}

    const {
      idOrLevel
    } = query || {}

    return idOrLevel || ''
  }

  // private partnerStatusList: any[] = getCommonList(competitionPartnerStatus2Text, 0)

  private leagueJoinStuList: any[] = []

  private refreshAllLeagueJoinStuList() {
    const pkSetItem = this.curPkSetItem
    this.competitionPkSetListApi.getPrecompetitionPkJoinerList({
      league_draw_id: pkSetItem.id,
      qualifier_type: this.competitionMode,
    }, (res: any) => {
      if (Array.isArray(res.data)) {
        this.leagueJoinStuList = res.data

      } else {
        this.leagueJoinStuList = []
      }
    }, (err: any) => {

    })
  }

  constructor() {
    super();
    this.competitionPkSetListApi = new CompetitionPkSetListAPI();

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms),
    }

    // this.utilIns = new Util()

    // this.debounceRefreshSingleCardInfoFunc = this.utilIns.debounce.call(
    //   this,
    //   this.refreshSingleCardAccountInfo,
    //   800
    // )
  }

  private form: any;

  private forms: any;

  private firstLoading: boolean = true;
  // private chooseTechnicianItem : any = null;

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };


  private activated(): void {
    console.log(this.$route.meta, 'this.$route.meta')
    if (this.$route.meta) {
      this.$route.meta.showTitle = this.competitionTitleName
    }
    this.getTable();
  }

  private subRoundList: any[] = []

  private curSubRound: any = null

  private curSubCustomKey: string = ''

  private getCompeteListFunc: Function = () => {}

  private getTable(): void {
    this.tableData = []

    this.subRoundList = []
    let getCompeteLevelFunc: any = () => {}

    let getCompeteListFunc: any = () => {}

    const levelParams: any = {}

    // if (!this.idOrLevel) return 

    switch (this.competitionMode) {
      case '1':
        Object.assign(levelParams, {
          league_id: this.competitionId,
          level: this.idOrLevel
        })
        getCompeteLevelFunc = this.competitionPkSetListApi.getCompetitionPkSetLevelList

        getCompeteListFunc = this.competitionPkSetListApi.getCompetitionPkSetList

        this.curSubCustomKey = 'kind'
        break;
      case '2':
        Object.assign(levelParams, {
          league_qualifier_id: this.idOrLevel
        })
        getCompeteLevelFunc = this.competitionPkSetListApi.getPrecompetitionPkSetLevelList

        getCompeteListFunc = this.competitionPkSetListApi.getPrecompetitionPkSetList

        this.curSubCustomKey = 'success_multiple'

        break;
    }

    this.getCompeteListFunc = getCompeteListFunc

    getCompeteLevelFunc.call(this.competitionPkSetListApi, levelParams, (res: any) => {
      const {
        list
      } = res.data || {}

      this.subRoundList = Array.isArray(list) ? list : []

      this.curSubRound = this.subRoundList.length > 0 ? {
        ...this.subRoundList[0]
      } : null

      this.refreshPkSetList()
    }, () => {

    })

  }

  private getCompetitionPkSetListParams() {
    // mode 可选的值 all  noPageOption exportExcel

    const curSubRound = this.curSubRound

    const curSubCustomKey = this.curSubCustomKey



    const outerParams: any = {
      league_id: this.competitionId,
      level: this.idOrLevel,
    };
    // if (shop_name) {
    //   outerParams.shop_name = shop_name;
    // }

    if (curSubRound) {
      outerParams[curSubCustomKey] = curSubRound[curSubCustomKey]
    }
    

   

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  private refreshPkSetList() {
    this.getCompeteListFunc.call(this.competitionPkSetListApi, this.getCompetitionPkSetListParams(), (res: any) => {
      const {
        list
      } = res.data || {}

      this.tableData = list

      this.firstLoading = false;

    }, () => {
      this.firstLoading = false;
    })
  }


  private handleChangeSubRound(subRound: any) {
    this.curSubRound = subRound

    this.tableData = []

    this.refreshPkSetList()
  }

  // 编辑对局弹框
  private dialogFormVisible: boolean = false

  private curPkSetItem: any = {}

  private leftWinnerSetting: Number = leftWinnerSetting
  private rightWinnerSetting: Number = rightWinnerSetting
  

  private chooseShopPoolList: any[] = []

  private sumbitPkSetLoading: boolean = false

  private handleWakeEditPkSetDialog(pkSetItem: any) {
    this.curPkSetItem = pkSetItem

    const forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms)
    }

    Object.assign(forms, {
      league_draw_id: pkSetItem.id,
      qualifier_type: this.competitionMode,
    })

    this.refreshAllLeagueJoinStuList()

    this.competitionPkSetListApi.getShopPoolList({
      shop_id: this.shopId,
      page: 1,
      limit: 1000
    }, (res: any) => {
      const {
        list
      } = res.data || {}

      this.chooseShopPoolList = list

      this.competitionPkSetListApi.getPrecompetitionPkSetDetail({
        league_draw_id: pkSetItem.id,
        qualifier_type: this.competitionMode
      }, (res: any) => {
        const {
          league_time,
          pool_id,
          left_phone,
          left_user_name,
          left_user_avatar,

          right_phone,
          right_user_name,
          right_user_avatar,

          left_score,
          right_score,
          success_user_point
        } = res.data || {}

        Object.assign(forms, {
          league_draw_id: pkSetItem.id,
          qualifier_type: this.competitionMode,

          league_time,
          pool_id,

          left_phone,
          left_user_name,
          left_user_avatar,

          right_phone,
          right_user_name,
          right_user_avatar,

          left_score,
          right_score,
          success_user_point
        })

        this.forms = forms

        console.log(this.forms, 'this.forms')

        this.dialogFormVisible = true
      }, () => {

        

      })
    }, () => {

    })

    
  }

  private handleChangeFormsJoiner(joinerMobile: string, mode: string) {
    const leagueJoinStuList = this.leagueJoinStuList

    const chooseUserItem = leagueJoinStuList.find((item: any) => item.phone == joinerMobile) || null

    if (!chooseUserItem) return

    const forms = this.deepLoopUtil.deepCopy(this.forms, false)

    switch(mode) {
      case 'left':
        Object.assign(forms, {
          left_user_avatar: chooseUserItem.avatar,
          left_user_name: chooseUserItem.user_name,
        })

        break;
      case 'right':
        Object.assign(forms, {
          right_user_avatar: chooseUserItem.avatar,
          right_user_name: chooseUserItem.user_name,
          
        })
        break;
    }

    this.forms = forms
  }

  private handleChangeSuccessUserPoint(point: number) {
    this.forms.success_user_point = point
  }

  private handleSumbitPkSetInfo() {
    const forms = this.forms

    const {
      league_time,
      pool_id,

    } = forms || {}

    if (!league_time) {
      this.$message.error('请选择比赛时间')
      return 
    }

    if (typeof pool_id != 'number') {
      this.$message.error('请选择球桌')

      return 
    }

    this.sumbitPkSetLoading = true
    this.competitionPkSetListApi.updatePkSetDetail(forms, () => {
      this.refreshPkSetList()

      this.sumbitPkSetLoading = false

      this.dialogFormVisible = false
    }, () => {
      this.sumbitPkSetLoading = false
    })
  }

  // end 编辑对局弹框
 

  

  // private curSingleCardInfo: any = {}

  // private singleCardInfoLoading: boolean = false

  // // 获取单店会员余额
  // private refreshSingleCardAccountInfo() {
  //   const forms = this.forms

  //   const {
  //     phone,
  //     venue
  //   } = forms || {}

  //   if (phone && venue && venue.id) {
  //     this.singleCardInfoLoading = true

  //     this.competitionPkSetListApi.getSingleCardAccountInfo({
  //       shop_id: venue.id,
  //       phone
  //     }, (res: any) => {
  //       const {
  //         shop_money
  //       } = res.data || {}

  //       this.singleCardInfoLoading = false


  //       this.$set(this.curSingleCardInfo, 'shop_money', shop_money || '')
  //       this.$set(this.curSingleCardInfo, 'errMsg', '')
  //     }, (err: any) => {

  //       console.log(err, 'err')

  //       this.singleCardInfoLoading = false

  //       const {
  //         message
  //       } = err || {}

  //       if (message) {
  //         this.$set(this.curSingleCardInfo, 'shop_money', '')
  //         this.$set(this.curSingleCardInfo, 'errMsg', message)
  //       }
  //     })
  //   }
  // }


 
  /** 远程搜索店铺 */
  // private venueList: any[] = [
  //   // {
  //   //   id: 1,
  //   //   label: '测试'
  //   // },
  //   // {
  //   //   id: 2,
  //   //   label: '测试2'
  //   // }
  // ];

  // private searchVenueLoading: boolean = false;

  // private remoteGetVenueQueryString: string = "";

  // private remoteSearchVenueTimer: any = null;

  // private searchVenuePageOption: any = {};

  // private initRemoteGetVenuePageOption() {
  //   this.searchVenuePageOption = {
  //     ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
  //   };
  // }

  // private getSearchVenueParams() {
  //   const searchVenuePageOption = this.searchVenuePageOption;

  //   const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

  //   const { page, limit } = searchVenuePageOption;

  //   const params: any = {
  //     page,
  //     limit,
  //   };

  //   if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
  //     params.name = remoteGetVenueQueryString.trim();
  //   }

  //   return params;
  // }

  // private getMoreVenueList(refresh: boolean = true) {
  //   return new Promise((resolve: Function, reject: Function) => {
  //     if (refresh) {
  //       this.initRemoteGetVenuePageOption();
  //     }

  //     const params = this.getSearchVenueParams();

  //     this.competitionPkSetListApi.searchVenueList(
  //       params,
  //       (res: any) => {
  //         console.log(res, "res");

  //         const venueList: any[] = res.data || [];

  //         this.venueList = refresh
  //           ? venueList
  //           : this.venueList.concat(venueList);

  //         if (Array.isArray(res.data) && res.data.length == 0) {
  //           this.searchVenuePageOption.isRearchBottom = true;
  //         }
  //         this.searchVenueLoading = false;

  //         this.addVenueListLoading = false;

  //         resolve();
  //       },
  //       () => {
  //         this.searchVenueLoading = false;

  //         this.addVenueListLoading = false;

  //         reject();
  //       }
  //     );
  //   });
  // }

  // // 第一次搜索 无关键字
  // private handleFirstGetVenueList() {
  //   this.searchVenueLoading = true;

  //   this.getMoreVenueList(true);
  // }

  // // 清空搜索
  // private handleClearGetVenueList() {
  //   this.remoteGetVenueQueryString = "";

  //   this.searchVenueLoading = true;

  //   this.getMoreVenueList(true);
  // }

  // // 远程搜索
  // private remoteGetVenueMethod(query: string) {
  //   query = query.trim ? query.trim() : "";

  //   if (query !== "") {
  //     this.remoteGetVenueQueryString = query;
  //     clearTimeout(this.remoteSearchVenueTimer);

  //     this.remoteSearchVenueTimer = setTimeout(() => {
  //       this.searchVenueLoading = true;

  //       this.getMoreVenueList();
  //       // searchStu((res) => {
  //       //   const {
  //       //     students,
  //       //     total_count
  //       //   } = res || {}

  //       //   this.remoteAllStudents = [
  //       //     ...(students || [])
  //       //   ]

  //       //   this.remoteStudentTotalCount = total_count || 0

  //       //   this.remoteGetStudentLoading = false
  //       // }, {
  //       //   kw: query,
  //       //   ...this.getRemoteGetStudentPageOptionParams()
  //       // }, () => {
  //       //   this.remoteGetStudentLoading = false
  //       // })
  //     }, 300);
  //     // setTimeout(() => {
  //     //   this.remoteGetStudentLoading = false;
  //     //   this.options = this.list
  //     // }, 200);
  //   } else {
  //     this.remoteGetVenueQueryString = "";
  //     this.venueList = [];
  //   }
  // }

  // private addVenueListLoading: boolean = false;

  // private remoteAddVenueMethod() {
  //   if (this.addVenueListLoading) return;
  //   const searchVenuePageOption = this.searchVenuePageOption;

  //   const { isRearchBottom } = searchVenuePageOption;

  //   if (isRearchBottom) return;

  //   this.searchVenuePageOption.page++;

  //   this.addVenueListLoading = true;

  //   this.getMoreVenueList(false);
  // }
  /** end 远程搜索店铺 */
  

  /** 导出excel */
  // private downloadExcelLoading: boolean = false;

  // // 订单字段名 文案对应关系
  // private orderExcelList2ch: any = {
  //   "phone": "手机号",
  //   "real_name": "真实姓名",
  //   "identity_card": "身份证号",
  //   "__address": "地区",
  //   "realname": "用户名",
  //   "bank_name": "银行",
  //   "province_city": "省市",
  //   "branch_name": "支行",
  //   "bank_card": "银行卡号",
  //   "pay_time": "报名时间",
  //   "__partnerStatus": "状态"
  // };
  // // 订单Excel 表头的先后顺序
  // private orderExcelSortList: string[] = [
  //   "phone",
  //   "real_name",
  //   "identity_card",
  //   "__address",
  //   "realname",
  //   "bank_name",
  //   "province_city",
  //   "branch_name",
  //   "bank_card",
  //   "pay_time",
  //   "__partnerStatus"
  // ];

  // // 点击导出excel 表格
  // private handleDownloadExcel() {
  //   const params = this.getCompetitionPkSetListParams();

  //   if (params === null) {
  //     return false;
  //   }

  //   this.downloadExcelLoading = true;
  //   this.competitionPkSetListApi.exportExcelCompetitionPartnerList(
  //     params,
  //     (res: any) => {
  //       const {
  //         start_date,
  //         end_date,
  //         // over_start_time,
  //         // over_end_time,
  //       } = params || {};

  //       const create_time_duration_name =
  //         start_date && end_date
  //           ? `报名时间${start_date}到${end_date}`
  //           : "";

  //       // const finish_time_duration_name =
  //       //   over_start_time && over_end_time
  //       //     ? `完成时间${over_start_time}到${over_end_time}`
  //       //     : "";
  //       const filename = `全部参赛选手列表 ${create_time_duration_name}.xlsx`;
  //       const { data } = res || {};

  //       const { list: originList } = data || {};

  //       const newList = Array.isArray(originList)
  //         ? originList.map((item) => {
  //             const {
  //               // type,
  //               province,
  //               city,
  //               county,
  //               is_retire
  //             } = item || {};

  //             const newItem = {
  //               ...(item || {}),

  //               __address: `${province || ''} ${city || ''} ${county || ''}`,

  //               __partnerStatus: allPartnerCompeteStatus[is_retire]
              
  //               // memberTypeName: transferUserLevel2ch[type]
  //             };

  //             return newItem;
  //           })
  //         : [];

  //       if (newList.length > 0) {
  //         const orderExcelSortList = this.orderExcelSortList;

  //         const orderExcelList2ch = this.orderExcelList2ch;

  //         const excelJSONData = getExcelJSONDataByList(
  //           newList,
  //           orderExcelSortList,
  //           orderExcelList2ch
  //         );


  //         excuteDownloadExport2Excel(excelJSONData, filename);
  //       } else {
  //         this.$message.info("选中时段没有可导出的信息");
  //       }

  //       this.downloadExcelLoading = false;
  //     },
  //     () => {
  //       this.downloadExcelLoading = false;
  //     }
  //   );
  // }

  /** end 导出excel */

  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];


  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}

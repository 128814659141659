
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList,getPageAPIs } from "@/common/util";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import {SaleMachineBlackUserAPI} from "@/common/api/operationManage/saleMachineBlackUserList";

// import ZlSelect from "@/components/third-custom-components/select";

// import ZlOption from "@/components/third-custom-components/option";


import { excuteDownloadExport2Excel } from "@/common/xlsx";
export const pageApiList = getPageAPIs(SaleMachineBlackUserAPI);

import {
  shareConfirmOptions
} from '@/common/config'

const defaultForm = {
  phone: "", // 手机号

  create_time: [], // 时间

};

const defaultForms = {
  phone: "",
  // venue_id: "",
  // venue: {
  //   id: "",
  //   name: "",
  // },
  // decr_price: '', // 扣款金额
  reason: '', // 备注
}

// const defaultSearchVenuePageOption = {
//   page: 1,
//   limit: 10,
//   isRearchBottom: false,
// };

// const transfromGenders: any = [
//   '',
//   '女',
//   '男'
// ]

@Component({
  filters: {
    // showUserMemberTypeName: function(type: number) {
    //   return transferUserLevel2ch[type] || ''
    // }
  },
  components: {
    // ZlSelect,
    // ZlOption,
  },
})
export default class MerchantWithdrawList extends Vue {
  private saleMachineBlackUserApi: any;

  private deepLoopUtil: any;

  // private utilIns: any;

  // private debounceRefreshSingleCardInfoFunc: any

  constructor() {
    super();
    this.saleMachineBlackUserApi = new SaleMachineBlackUserAPI();

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms),
    }

    // this.utilIns = new Util()

    // this.debounceRefreshSingleCardInfoFunc = this.utilIns.debounce.call(
    //   this,
    //   this.refreshSingleCardAccountInfo,
    //   800
    // )
  }

  private form: any;

  private forms: any;

  private firstLoading: boolean = true;
  // private chooseTechnicianItem : any = null;

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };


  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    this.saleMachineBlackUserApi.getSaleMachineBlackUserist(
      this.getSaleMachineBlackUseristParams(),
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        const { list, count, page, prepage } = data || {};

        const newTableList = Array.isArray(list)
          ? list.map(this.handleTableData)
          : [];

        this.$set(this.tablePage, "totalCount", count);
        this.$set(this.tablePage, "pageNo", page);
        this.$set(this.tablePage, "pageSize", prepage);

        this.tableData = newTableList;

        this.firstLoading = false;
      },
      () => {
        this.firstLoading = false;
      },
      false
    );
  }

  private getSaleMachineBlackUseristParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const firstLoading = this.firstLoading;

    const {
      // shop_name,
      phone,
      create_time, // 消费时间
      // finish_time, // 完成时间
    } = form || {};


    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};
    // if (shop_name) {
    //   outerParams.shop_name = shop_name;
    // }

    if (phone) {
      outerParams.phone = phone
    }

    if (Array.isArray(create_time) && create_time.length === 2) {
      const [startDate, endDate] = create_time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      outerParams.start_date = startTime;

      outerParams.end_date = endTime;
    }

    if (
      mode == "exportExcel" &&
      !(outerParams.start_date && outerParams.end_date)
    ) {
      this.$message.error("请选择日期导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      outerParams.__isUnique = !firstLoading;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  private dialogFormVisible: boolean = false

  private sumbitAddSaleMachineBlackUserLoading: boolean = false
  // // 唤起添加售卖柜黑名单弹框
  private handleWakeAddSaleMachineBlackUserDialog() {
    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms),
    }


    this.dialogFormVisible = true

  }

  // 手机号输入限制
  private handleLimitPhone(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        this.$set(this.forms, "phone", new_val);

      },
      (new_val: string) => {
        this.$set(this.forms, "phone", new_val);
      }
    );
  }

  // 扣款金额限制
  private handleLimitConsumePrice(val: string) {
    Util.limitDigitalnput(
      val,
      (new_val: string) => {
        this.$set(this.forms, "decr_price", new_val);
      },
      (new_val: string) => {
        this.$set(this.forms, "decr_price", new_val);
      },
      2
    );
  }

  // // 提交 黑名单
  private handleSumbitAddSaleMachineBlackUser () {
    const {
      phone,
      
      reason
    } = this.forms || {}

    if (!phone) {
      this.$message.error('请输入手机号')
      return 
    }

    if (!reason) {
      this.$message.error('请输入原因')
      return
    }

    this.sumbitAddSaleMachineBlackUserLoading = true

    this.saleMachineBlackUserApi.addSaleMachineBlackUser({
      phone,
      reason
    }, () => {
      this.$message.success('添加黑名单成功')

      this.sumbitAddSaleMachineBlackUserLoading = false
      this.dialogFormVisible = false

      this.getTable()
    }, () => {
      this.sumbitAddSaleMachineBlackUserLoading = false
    })
  }

  // private curSingleCardInfo: any = {}

  // private singleCardInfoLoading: boolean = false

  // // 获取单店会员余额
  // private refreshSingleCardAccountInfo() {
  //   const forms = this.forms

  //   const {
  //     phone,
  //     venue
  //   } = forms || {}

  //   if (phone && venue && venue.id) {
  //     this.singleCardInfoLoading = true

  //     this.saleMachineBlackUserApi.getSingleCardAccountInfo({
  //       shop_id: venue.id,
  //       phone
  //     }, (res: any) => {
  //       const {
  //         shop_money
  //       } = res.data || {}

  //       this.singleCardInfoLoading = false


  //       this.$set(this.curSingleCardInfo, 'shop_money', shop_money || '')
  //       this.$set(this.curSingleCardInfo, 'errMsg', '')
  //     }, (err: any) => {

  //       console.log(err, 'err')

  //       this.singleCardInfoLoading = false

  //       const {
  //         message
  //       } = err || {}

  //       if (message) {
  //         this.$set(this.curSingleCardInfo, 'shop_money', '')
  //         this.$set(this.curSingleCardInfo, 'errMsg', message)
  //       }
  //     })
  //   }
  // }


  // 后台返回的字段 转化为前端定义的字段
  private handleTableData(data: any): any {
    // const { gender } = data;
    const newData = {
      ...data,
      // device_name: orderDeviceType2ch[device],
      // pay_type_name: payType2ch[pay_type],
      // user_level_type_name: userLevel2ch[user_type],
      // gender_sex: transfromGenders[gender]
    };

    return newData;
  }

  private handleDeleteSaleMachineBlackUser(blackUserItem: any, rowIndex: number) {
    const tableData = this.tableData
    const rowUserItem = tableData[rowIndex]

    function setUserProp(target: any, item: any, prop: string, val: any) {
      if (!rowUserItem) return
      if (item.id == rowUserItem.id) {
        target.$set(target.tableData[rowIndex], prop, val)
      }
    }

    this.$confirm('', '是否删除该黑名单', {
      ...shareConfirmOptions
    }).then(() => {
      setUserProp(this, blackUserItem, '__deleteBlackStatusLoading', true)
      this.saleMachineBlackUserApi.deleteSaleMachineBlackUser({
        id: blackUserItem.id
      }, () => {
        setUserProp(this, blackUserItem, '__deleteBlackStatusLoading', false)

        this.getTable()
      }, () => {
        setUserProp(this, blackUserItem, '__deleteBlackStatusLoading', false)
      })
    })
  }

  /** 远程搜索店铺 */
  // private venueList: any[] = [
  //   // {
  //   //   id: 1,
  //   //   label: '测试'
  //   // },
  //   // {
  //   //   id: 2,
  //   //   label: '测试2'
  //   // }
  // ];

  // private searchVenueLoading: boolean = false;

  // private remoteGetVenueQueryString: string = "";

  // private remoteSearchVenueTimer: any = null;

  // private searchVenuePageOption: any = {};

  // private initRemoteGetVenuePageOption() {
  //   this.searchVenuePageOption = {
  //     ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
  //   };
  // }

  // private getSearchVenueParams() {
  //   const searchVenuePageOption = this.searchVenuePageOption;

  //   const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

  //   const { page, limit } = searchVenuePageOption;

  //   const params: any = {
  //     page,
  //     limit,
  //   };

  //   if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
  //     params.name = remoteGetVenueQueryString.trim();
  //   }

  //   return params;
  // }

  // private getMoreVenueList(refresh: boolean = true) {
  //   return new Promise((resolve: Function, reject: Function) => {
  //     if (refresh) {
  //       this.initRemoteGetVenuePageOption();
  //     }

  //     const params = this.getSearchVenueParams();

  //     this.saleMachineBlackUserApi.searchVenueList(
  //       params,
  //       (res: any) => {
  //         console.log(res, "res");

  //         const venueList: any[] = res.data || [];

  //         this.venueList = refresh
  //           ? venueList
  //           : this.venueList.concat(venueList);

  //         if (Array.isArray(res.data) && res.data.length == 0) {
  //           this.searchVenuePageOption.isRearchBottom = true;
  //         }
  //         this.searchVenueLoading = false;

  //         this.addVenueListLoading = false;

  //         resolve();
  //       },
  //       () => {
  //         this.searchVenueLoading = false;

  //         this.addVenueListLoading = false;

  //         reject();
  //       }
  //     );
  //   });
  // }

  // // 第一次搜索 无关键字
  // private handleFirstGetVenueList() {
  //   this.searchVenueLoading = true;

  //   this.getMoreVenueList(true);
  // }

  // // 清空搜索
  // private handleClearGetVenueList() {
  //   this.remoteGetVenueQueryString = "";

  //   this.searchVenueLoading = true;

  //   this.getMoreVenueList(true);
  // }

  // // 远程搜索
  // private remoteGetVenueMethod(query: string) {
  //   query = query.trim ? query.trim() : "";

  //   if (query !== "") {
  //     this.remoteGetVenueQueryString = query;
  //     clearTimeout(this.remoteSearchVenueTimer);

  //     this.remoteSearchVenueTimer = setTimeout(() => {
  //       this.searchVenueLoading = true;

  //       this.getMoreVenueList();
  //       // searchStu((res) => {
  //       //   const {
  //       //     students,
  //       //     total_count
  //       //   } = res || {}

  //       //   this.remoteAllStudents = [
  //       //     ...(students || [])
  //       //   ]

  //       //   this.remoteStudentTotalCount = total_count || 0

  //       //   this.remoteGetStudentLoading = false
  //       // }, {
  //       //   kw: query,
  //       //   ...this.getRemoteGetStudentPageOptionParams()
  //       // }, () => {
  //       //   this.remoteGetStudentLoading = false
  //       // })
  //     }, 300);
  //     // setTimeout(() => {
  //     //   this.remoteGetStudentLoading = false;
  //     //   this.options = this.list
  //     // }, 200);
  //   } else {
  //     this.remoteGetVenueQueryString = "";
  //     this.venueList = [];
  //   }
  // }

  // private addVenueListLoading: boolean = false;

  // private remoteAddVenueMethod() {
  //   if (this.addVenueListLoading) return;
  //   const searchVenuePageOption = this.searchVenuePageOption;

  //   const { isRearchBottom } = searchVenuePageOption;

  //   if (isRearchBottom) return;

  //   this.searchVenuePageOption.page++;

  //   this.addVenueListLoading = true;

  //   this.getMoreVenueList(false);
  // }
  /** end 远程搜索店铺 */
  

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 订单字段名 文案对应关系
  private orderExcelList2ch: any = {
    "phone": "手机号",
    "user_name": "用户昵称",
    "reason": "原因",
    "opt_name": "操作人",
    "opt_time": "操作时间"
  };
  // 订单Excel 表头的先后顺序
  private orderExcelSortList: string[] = [
    "phone",
    "user_name",
    "reason",
    "opt_name",
    "opt_time"
  ];

  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getSaleMachineBlackUseristParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.saleMachineBlackUserApi.exportExcelSSaleMachineBlackUserList(
      params,
      (res: any) => {
        const {
          start_date,
          end_date,
          // over_start_time,
          // over_end_time,
        } = params || {};

        const create_time_duration_name =
          start_date && end_date
            ? `时间${start_date}到${end_date}`
            : "";

        // const finish_time_duration_name =
        //   over_start_time && over_end_time
        //     ? `完成时间${over_start_time}到${over_end_time}`
        //     : "";
        const filename = `售卖柜黑名单列表 ${create_time_duration_name}.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const {
                // type,
           
              } = item || {};

              const newItem = {
                ...(item || {}),
              
                // memberTypeName: transferUserLevel2ch[type]
              };

              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const orderExcelSortList = this.orderExcelSortList;

          const orderExcelList2ch = this.orderExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            orderExcelSortList,
            orderExcelList2ch
          );


          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */

  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}

import { BasicsClass } from '@/common/BasicsClass';
export class BannersManageAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getBanners', {
            url: '/admin/banner/list',
            name: 'list',
            label: '列表'
        }],
        ['addBanners',  {
            url: '/admin/banner/add',
            name: 'add',
            label: '添加'
        }],
        ['updateBanners',  {
            url: '/admin/banner/edit',
            name: 'edit',
            label: '编辑'
        }],
        ['deleteBanners', {
            url: '/admin/banner/delete',
            name: 'delete',
            label: '删除'
        }]
    ])


    //新增banner
    public addBanners(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('addBanners').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    //修改banner
    public updateBanners(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('updateBanners').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    //删除banner
    public deleteBanners(params: any, successCb: Function, failCb: Function, showLoading: boolean = false): void {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('deleteBanners').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    //优惠券列表
    public getBanners(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getBanners').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

}

import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import WebsitePandaContactAPI from "@/common/api/officialWebManage/websitePandaContactList";

import { excuteDownloadExport2Excel } from "@/common/xlsx";

import {
  shareConfirmOptions,
  officialWebChannel2ch
} from '@/common/config'

export const pageApiList = getPageAPIs(WebsitePandaContactAPI)

const defaultForm = {
  phone: "", // 手机号
};

// const couponStatus2ch: any = [
//   '',
//   '未使用',
//   '已使用',
//   '已过期'
// ]

@Component({
  filters: {
    showBroadcastChannelName(channel: number) {
      return officialWebChannel2ch[channel] || '未知'
    }
  }
})
export default class WebsitePandaContact extends Vue {
  private websitePandaContactApi: any;

  private deepLoopUtil: any;

  constructor() {
    super();
    this.websitePandaContactApi = new WebsitePandaContactAPI();

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
  }

  private form: any;

  private firstLoading: boolean = true;
  // private chooseTechnicianItem : any = null;

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

 

  // 所有可选技师
  private technicianList: any[] = [];

  // private showtimecard: boolean = false;
  private activated(): void {
    this.getTable();

  }

  // private mounted(): void {

  // }
  private getTable(): void {
    const params = this.getCustomContactListParams()
    this.websitePandaContactApi.getCustomContactList(
      params,
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        const { list, count: total, page, prepage } = data || {};

        const newTableList = Array.isArray(list)
          ? list.map(this.handleTableData)
          : [];

        this.$set(this.tablePage, "totalCount", total);
        this.$set(this.tablePage, "pageNo", page || params.page);
        this.$set(this.tablePage, "pageSize", prepage || params.limit);

        this.tableData = newTableList;

        this.firstLoading = false;
      },
      () => {
        this.firstLoading = false;
      },
      false
    );
  }

  private channelList: any[] = [];

  private getCustomContactListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const firstLoading = this.firstLoading;

    const {
      // status, // 状态 1未使用 2已使用 3已过期
      phone,
      // receipt_code,
      // create_time, // 消费时间
      // finish_time, // 完成时间
    } = form || {};

    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};


    if (phone) {
      outerParams.phone = phone;
    }


    if (
      mode == "exportExcel" &&
      !(outerParams.created_start_time && outerParams.created_end_time)
    ) {
      this.$message.error("请选择消费时段导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      outerParams.__isUnique = !firstLoading;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  
  // 后台返回的字段 转化为前端定义的字段
  private handleTableData(data: any): any {
    const { status, } = data;
    const newData = {
      ...data,
      // device_name: orderDeviceType2ch[device],
      // pay_type_name: payType2ch[pay_type],
      // user_level_type_name: userLevel2ch[user_type],
    };

    return newData;
  }

  // 去联系
  private handleGoContactCustom(customComtactItem: any) {
    this.$confirm("确定去联系该用户?", "", {
      ...shareConfirmOptions,
    }).then(() => {
      this.websitePandaContactApi.handleCustomContact(
        {
          id: customComtactItem.id,
        },
        () => {
          this.getTable();
        },
        () => {}
      );
    });
  }


  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}

import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class ShopInformationCollectAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getShopInformationCollectTableList', {
            url: '/admin/shop/collect',
            name: 'list',
            label: '列表'
        }],
        ['exportExcelShopInformationCollectList', {
            url: '/admin/shop/exportCollect',
            name: 'export-excel',
            label: '导出'
        }],
        // ['searchVenueList', {
        //     url: '/admin/coupons/shop/list',
        //     name: 'shop-list',
        //     label: '店铺列表',
        // }],
    ])

    // 开台时长列表
    public getShopInformationCollectTableList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getShopInformationCollectTableList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // // 导出开台时长记录
    public exportExcelShopInformationCollectList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('exportExcelShopInformationCollectList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 搜索店铺列表
    // public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    //     const apiUrls = this.apiUrls

    //     const url = apiUrls.get('searchVenueList').url

    //     this.BasicPost(url, {
    //         ...params
    //     }, false, false, false, successCb, failCb, showLoading)
    // }
}
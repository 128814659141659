
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import { CouponVertifyCodeRecordAPI } from "@/common/api/couponManage/cashierCouponVertifyCodeRecord";

import { excuteDownloadExport2Excel } from "@/common/xlsx";

// import { util } from "vue/types/umd";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import ZlSelect from "@/components/third-custom-components/select";

import ZlOption from "@/components/third-custom-components/option";

import {
  shareConfirmOptions,
  shareShopType2ch
} from '@/common/config'

// 暴露的API 列表
export const pageApiList = getPageAPIs(CouponVertifyCodeRecordAPI);

console.log(pageApiList, 'pageApiList')

const defaultForm = {
  // phone: "", // 手机号
  venue_id: "",
  venue: {
    id: "",
    name: "",
  },

  receipt_code: "", // 券码

  shop_name: "", // 店铺名称

  // receipt_order_id: "", // 订单编号

  time: []
}

const defaultForms = {
  // name: "",
  // origin_price: "",
  // sale_price: "",
  // shop_type: 1,
  // expired_days: "",
  // meituanId: "",
  // timeDuration: "",
  // pool_table_category_ids: [],
  // coupons_time_id: "",
  // venue_id: "",
  // venue: {
  //   id: "",
  //   name: "",
  // },
  // singleMaxCountType: '0',
  // shop_id: "",
  // maxLimitCount: ''
};


const defaultSearchVenuePageOption = {
  page: 1,
  limit: 10,
  isRearchBottom: false,
};

// const couponDialogTitle2ch: any = {
//   "new-coupon": "新增时长卡",
//   "edit-coupon": "编辑时长卡",
// };

// 最大有效期
// const maxExpiredDays = 9999;

// // 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;

// // coupon 卡券分属类别
// const couponShopType2ch: any = [
//   ...shareShopType2ch
// ]

// 开台券类型 3美团券  4抖音券
const useCouponTypeName2ch: string[] = ['', '', '', '美团券', '抖音券']

// 核销券状态 是否使用：0否1是
const useCouponStatus2ch: string[] = ['未使用', '已使用']

// // 3为美团7为抖音11为快手
// const useCouponTypeName2ch: string[] = ['', '', '', '美团', '', '', '', '抖音', '', '', '', '快手']


@Component({
  filters: {
    // showCouponShopTypeName(shop_type: number) {
    //   return couponShopType2ch[shop_type] || ''
    // }
    showUseCouponType2ch(type: number) {
      return useCouponTypeName2ch[type] || '未知'
    },

    showUseCouponStatusName(is_use: number) {
      return useCouponStatus2ch[is_use] || '未知'
    }
  },
  components: {
    ZlSelect,
    ZlOption,
  },
})
export default class CouponsManage extends Vue {
  private form: any = {
    // couponId: "",
    shop_name: "",
    // time: [],
    // startDate: "",
    // endDate: "",
  };
  private forms: any = {
    // maxType: "0",
    // ...defaultForms
  };

  private deepLoopUtil: any = {};

  // private merchantApi: any;

  private couponVertifyCodeRecordApi = new CouponVertifyCodeRecordAPI();
  // private couponTimeDurationAPI = new CouponTimeDurationManageAPI();
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    // this.searchVenuePageOption = {
    //   ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    // };

    // this.merchantApi = new MerchantApi();
  }

  private created(): void {
    this.getCouponVertifyCodeRecords();

    // this.refreshCouponTimeDurations();

    // this.refreshTableClass();
  }
  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
    // this.form = new Util().clearObject(this.form);
    // this.$router.replace("/orderList");
    this.getCouponVertifyCodeRecords();
  }

  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getCouponVertifyCodeRecords();
  }

  private tableData: any[] = [];


  private formLabelWidth: string = "100px";

  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getCouponVertifyCodeRecords();
  }

  //获取优惠券列表
  private getCouponVertifyCodeRecords(): void {
    this.couponVertifyCodeRecordApi.getCouponVertifyCodeRecords(
      { ...this.getCouponVertifyCodeRecordParams() },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page;
        this.tablePage.prepage = res.data.prepage;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  /** 下拉搜索 */
  private venueList: any[] = [
    // {
    //   id: 1,
    //   label: '测试'
    // },
    // {
    //   id: 2,
    //   label: '测试2'
    // }
  ];

  private searchVenueLoading: boolean = false;

  private remoteGetVenueQueryString: string = "";

  private remoteSearchVenueTimer: any = null;

  private searchVenuePageOption: any = {};

  private initRemoteGetVenuePageOption() {
    this.searchVenuePageOption = {
      ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    };
  }

  private getSearchVenueParams() {
    const searchVenuePageOption = this.searchVenuePageOption;

    const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

    const { page, limit } = searchVenuePageOption;

    const params: any = {
      page,
      limit,
    };

    if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
      params.name = remoteGetVenueQueryString.trim();
    }

    return params;
  }

  private getMoreVenueList(refresh: boolean = true) {
    return new Promise((resolve: Function, reject: Function) => {
      if (refresh) {
        this.initRemoteGetVenuePageOption();
      }

      const params = this.getSearchVenueParams();

      this.couponVertifyCodeRecordApi.searchVenueList(
        params,
        (res: any) => {
          console.log(res, "res");

          const venueList: any[] = res.data || [];

          this.venueList = refresh
            ? venueList
            : this.venueList.concat(venueList);

          if (Array.isArray(res.data) && res.data.length == 0) {
            this.searchVenuePageOption.isRearchBottom = true;
          }
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          resolve();
        },
        () => {
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          reject();
        }
      );
    });
  }

  // 第一次搜索 无关键字
  private handleFirstGetVenueList() {
    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 清空搜索
  private handleClearGetVenueList() {
    this.remoteGetVenueQueryString = "";

    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 远程搜索
  private remoteGetVenueMethod(query: string) {
    query = query.trim ? query.trim() : "";

    if (query !== "") {
      this.remoteGetVenueQueryString = query;
      clearTimeout(this.remoteSearchVenueTimer);

      this.remoteSearchVenueTimer = setTimeout(() => {
        this.searchVenueLoading = true;

        this.getMoreVenueList();
        // searchStu((res) => {
        //   const {
        //     students,
        //     total_count
        //   } = res || {}

        //   this.remoteAllStudents = [
        //     ...(students || [])
        //   ]

        //   this.remoteStudentTotalCount = total_count || 0

        //   this.remoteGetStudentLoading = false
        // }, {
        //   kw: query,
        //   ...this.getRemoteGetStudentPageOptionParams()
        // }, () => {
        //   this.remoteGetStudentLoading = false
        // })
      }, 300);
      // setTimeout(() => {
      //   this.remoteGetStudentLoading = false;
      //   this.options = this.list
      // }, 200);
    } else {
      this.remoteGetVenueQueryString = "";
      this.venueList = [];
    }
  }

  private addVenueListLoading: boolean = false;

  private remoteAddVenueMethod() {
    if (this.addVenueListLoading) return;
    const searchVenuePageOption = this.searchVenuePageOption;

    const { isRearchBottom } = searchVenuePageOption;

    if (isRearchBottom) return;

    this.searchVenuePageOption.page++;

    this.addVenueListLoading = true;

    this.getMoreVenueList(false);
  }
  /** end 下拉搜索 */

  private getCouponVertifyCodeRecordParams(mode = "all"): any {
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    const {
      // phone,
      venue,
      receipt_code,
      // shop_name,
      // receipt_order_id,
      time
    } = form;

    // if (phone) {
    //   params.phone = phone
    // }

    if (venue && venue.id) {
      params.shop_id = venue.id
    }

    if (receipt_code) {
      params.receipt_code = receipt_code
    }

    // if (shop_name) {
    //   params.shop_name = shop_name
    // }

    // if (receipt_order_id) {
    //   params.receipt_order_id = receipt_order_id
    // }

    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      params.start_date = startTime;

      params.end_date = endTime;
    }

    if (
      mode == "exportExcel" &&
      !(params.start_date && params.end_date)
    ) {
      this.$message.error("请选择核销时段导出数据");
      return null;
    }

    if (mode === "all") {
      params.page = page;

      params.limit = prepage;

    }

    return params;
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  /** 导出excel */
  // private downloadExcelLoading: boolean = false;

  // // 订单字段名 文案对应关系
  // private orderExcelList2ch: any = {
  //   receipt_order_id: "订单编号",
  //   phone: "手机号",
  //   name: "卡券名称",
  //   divide_price: "金额",
  //   receipt_code: "券码",
  //   __useCouponType: "类型",
  //   shop_name: "消费店铺",
  //   created_at: "核销时间"
  // };
  // // 订单Excel 表头的先后顺序
  // private orderExcelSortList: string[] = [
  //   "receipt_order_id",
  //   "phone",
  //   "name",
  //   "divide_price",
  //   "receipt_code",
  //   "__useCouponType",
  //   "shop_name",
  //   "created_at"
  // ];

  // // 点击导出excel 表格
  // private handleDownloadExcel() {
  //   const params = this.getCouponVertifyCodeRecordParams("exportExcel");

  //   if (params === null) {
  //     return false;
  //   }

  //   this.downloadExcelLoading = true;
  //   this.couponVertifyCodeRecordApi.exportCouponVertifyCodeRecords(
  //     params,
  //     (res: any) => {
  //       const {
  //         start_date,
  //         end_date,
  //       } = params || {};

  //       const filename = `团购券核销记录列表 ${start_date}到${end_date}.xlsx`;
  //       const { data } = res || {};

  //       const { list: originList } = data || {};

  //       const newList = Array.isArray(originList)
  //         ? originList.map((item) => {
  //             const {
  //               // user_type,
  //               // price,
  //               // refunded_price,
  //               // device,
  //               // pay_type,
  //               // daytime_minute,
  //               // night_minute,
  //               // goods_detail,
  //               // status
  //               source
  //             } = item || {};

  //             const newItem = {
  //               ...(item || {}),
  //               // device_name: orderDeviceType2ch[device],
  //               // pay_type_name: payType2ch[pay_type],
  //               // total_miniute: daytime_minute + night_minute,
  //               // actual_price: price - refunded_price || "",
  //               // user_level_type_name: userLevel2ch[user_type],
  //               // __status_name: orderStatus2Ch[status],
  //               // __good_details: goods_detail.map((goods: any, index: number) => `${index + 1}、${ goods.name }x${ goods.num }  ¥${ goods.price }`).join('; \t')
  //               __useCouponType: useCouponTypeName2ch[source]
  //             };

  //             return newItem;
  //           })
  //         : [];

  //       if (newList.length > 0) {
  //         const orderExcelSortList = this.orderExcelSortList;

  //         const orderExcelList2ch = this.orderExcelList2ch;

  //         const excelJSONData = getExcelJSONDataByList(
  //           newList,
  //           orderExcelSortList,
  //           orderExcelList2ch
  //         );

  //         // console.log(newList, "newList");

  //         // console.log(excelJSONData, "excelJSONData");

  //         excuteDownloadExport2Excel(excelJSONData, filename);
  //       } else {
  //         this.$message.info("选中时段没有可导出的信息");
  //       }

    

  //       this.downloadExcelLoading = false;
  //     },
  //     () => {
  //       this.downloadExcelLoading = false;
  //     }
  //   );
  // }

  /** end 导出excel */

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };
}

import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

import { CommonApi } from "../common";

export class AbnormalPayOrderAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getAbnormalPayOrderList', {
      url: '/admin/refundException/list',
      name: 'list',
      label: '列表'
    }],
    
    ['getAbnormalOrderQueryInfo', {
        url: '/admin/refundException/query',
        name: 'abnormal-order-query',
        label: '查询异常订单'
    }],
    ['abnormalOrderManualSetStatus', {
        url: '/admin/refundException/handle',
        name: 'abnormal-order-manual-set',
        label: '手动设置异常订单状态'
    }],
    
  ])
  // 订单api 方法
  // 商家提现余额扣款记录列表
  public getAbnormalPayOrderList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getAbnormalPayOrderList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 提现查询
  public getAbnormalOrderQueryInfo(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getAbnormalOrderQueryInfo').url

    this.BasicPost(url, {
        ...params
      }, false, false, false, successCb, failCb, showLoading)
  }

  // 提现查询
  public abnormalOrderManualSetStatus(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
      const apiUrls = this.apiUrls

      const url = apiUrls.get('abnormalOrderManualSetStatus').url

      this.BasicPost(url, {
          ...params
      }, false, false, false, successCb, failCb, showLoading)
  }
 
}

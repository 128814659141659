import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

import { CommonApi } from "../common";

export class SaleMachineBlackUserAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getSaleMachineBlackUserist', {
      url: '/admin/seller_blacklist/list',
      name: 'list',
      label: '列表'
    }],
    ['exportExcelSSaleMachineBlackUserList', {
      url: '/admin/seller_blacklist/exportList',
      name: 'export-excel',
      label: '导出'
    }],
    ['addSaleMachineBlackUser', {
      url: '/admin/seller_blacklist/add',
      name: 'add',
      label: '添加黑名单'
    }],
    // ['editSaleMachineBlackUser', {
    //   url: '/admin/seller_blacklist/edit',
    //   name: 'edit',
    //   label: '编辑黑名单'
    // }],
    ['deleteSaleMachineBlackUser', {
      url: '/admin/seller_blacklist/delete',
      name: 'delete',
      label: '删除黑名单'
    }],


  ])
  // api 方法
  // 售卖柜黑名单列表
  public getSaleMachineBlackUserist(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getSaleMachineBlackUserist').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

 
  // 导出售卖柜黑名单列表
  public exportExcelSSaleMachineBlackUserList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('exportExcelSSaleMachineBlackUserList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 添加售卖柜黑名单
  public addSaleMachineBlackUser(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('addSaleMachineBlackUser').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 编辑售卖柜黑名单
  // public editSaleMachineBlackUser(
  //   params: any,
  //   successCb: Function,
  //   failCb: Function,
  //   showLoading: boolean = false
  // ) {
  //   const apiUrls = this.apiUrls

  //   const url = apiUrls.get('editSaleMachineBlackUser').url

  //   this.BasicPost(url, {
  //     ...params
  //   }, false, false, false, successCb, failCb, showLoading)
  // }

  // 删除售卖柜黑名单
  public deleteSaleMachineBlackUser(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('deleteSaleMachineBlackUser').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
  
}

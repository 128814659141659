import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class MerchantsGloryAuditByDayAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['chessBusiness', {
            url: '/admin/shop/chessBusinessDetail',
            name: 'list',
            label: '列表'
        }],
        ['exportExcelMerchantGloryAuditList', {
            url: '/admin/shop/exportChessBusinessDetail',
            name: 'export-excel',
            label: '导出'
        }],

    ])
    // 棋牌营收列表
    public chessBusiness(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
      const apiUrls = this.apiUrls

      const url = apiUrls.get('chessBusiness').url

      this.BasicPost(url, {
          ...params
      }, false, false, false, successCb, failCb, showLoading)
    }

    // 导出棋牌营收列表
    public exportExcelMerchantGloryAuditList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
      const apiUrls = this.apiUrls

      const url = apiUrls.get('exportExcelMerchantGloryAuditList').url

      this.BasicPost(url, {
          ...params
      }, false, false, false, successCb, failCb, showLoading)
    }
}
import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

import { CommonApi } from "../common";

export class MerchantCouponAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getCouponRecordList', {
        url: '/admin/couponsOrder/list',
        name: 'list',
        label: '列表'
    }],
    // debugger changeExport;
    ['exportExcelOrderList', {
        url: '/admin/couponsOrder/exportList',
        name: 'export-excel',
        label: '导出'
    }],
    ['refundMerchantCouponCharge', {
        url: '/admin/couponsOrder/back',
        name: 'refund-coupon',
        label: '退款畅打券'
    }],

    ['searchVenueList', {
      url: '/admin/coupons/shop/list',
      name: 'shop-list',
      label: '店铺列表',
      // hidden: true
    }],

  ])

  // 优惠券列表
  public getCouponRecordList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getCouponRecordList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 导出订单列表
  public exportExcelOrderList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('exportExcelOrderList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 退款畅打券
  public refundMerchantCouponCharge(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('refundMerchantCouponCharge').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 搜索店铺列表
  public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('searchVenueList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}

import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class LampOptionLogAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['lampLogList', {
            url: '/admin/aqara/log',
            name: 'list',
            label: '列表'
        }],
    
        // ['exportExcelMonthIncomeAuditList', {
        //     url: '/admin/shop/exportMonthBusiness',
        //     name: 'export-excel',
        //     label: '导出'
        // }]
    ])

    // 绿米灯控操作记录列表
    public lampLogList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
      const apiUrls = this.apiUrls

      const url = apiUrls.get('lampLogList').url

      this.BasicPost(url, {
          ...params
      }, false, false, false, successCb, failCb, showLoading)
    }

    // 导出绿米灯控操作记录列表
    // public exportExcelMonthIncomeAuditList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    //     const apiUrls = this.apiUrls

    //     const url = apiUrls.get('exportExcelMonthIncomeAuditList').url
  
    //     this.BasicPost(url, {
    //         ...params
    //     }, false, false, false, successCb, failCb, showLoading)
    // }

}

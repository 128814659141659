import { BasicsClass } from '@/common/BasicsClass';
export class DataStatisticRecordAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getDataTotalRecords', {
            url: '/admin/data_board/lists',
            name: 'total-borad',
            label: '汇总数据'
        }]
       
    ])

    // 总数据记录列表
    public getDataTotalRecords(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getDataTotalRecords').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

}

import {Component, Vue} from 'vue-property-decorator';
import {Util, getPageAPIs, getExcelJSONDataByList} from '@/common/util';
import {BusinessLimitWarning} from '@/common/api/operationManage/businessLimitWarning';
import {DeepLoop} from '@/common/deepLoop/deepLoop';
import ZlSelect from '@/components/third-custom-components/select';
import ZlOption from '@/components/third-custom-components/option';
import {shareConfirmOptions} from '@/common/config';
import { CommonApi } from "@/common/api/common";
import { excuteDownloadExport2Excel } from "@/common/xlsx";
import gallery from "@/components/gallery.vue";
// 暴露的API 列表
export const pageApiList = getPageAPIs(BusinessLimitWarning);

const defaultForm = {
  shop_id: '',
  venue: {
    id: '',
    name: '',
  },
};

const defaultAddForms = {
  venue: {
    id: '',
    name: '',
  },
  turnover: '',
  name: ''
};

const defaultSearchVenuePageOption = {
  page: 1,
  limit: 10,
  isRearchBottom: false,
};

@Component({
  components: {
    ZlSelect,
    ZlOption,
    gallery,
  },
  filters: {
    showPicOrThumbnail(row: any) {
      const { image_list } = row || {};
      return image_list.length > 0 ? image_list[0].url : "";
    }
  },
})
export default class CouponsManage extends Vue {
  private form: any = {
    shop_name: '',
  };
  private addForms: any = {};
  private handleForms: any = {
    remark:'',
    images:[],
    id:''
  };
  private handleListTime:any[] = []
  private max_upload_image_count = 9;
  private deepLoopUtil: any = {};
  private operateType: string = 'add';
  private is_show: boolean = false;
  private handleFinishVisible: boolean = false;
  private handleListVisible: boolean = false;
  private businessLimitWarningApi = new BusinessLimitWarning();
  private commonApi: any;
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };

    this.addForms = {
      ...this.deepLoopUtil.deepCopy(defaultAddForms, false),
    };
    this.commonApi = new CommonApi();
  }

  private created(): void {
    this.getList();
  }

  private restForm(): void {
    this.form = {...this.deepLoopUtil.deepCopy(defaultForm)};
    this.getList();
  }

  private restFormHandle(): void {
    this.handleListTime = []
    this.handleParams.start_date = '';
    this.handleParams.end_date = '';
    this.getHandleList();
  }

  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getList();
  }

  private searchHandleList(): void {
    this.handleTableData = [];
    this.handleTablePage.page = 1;
    this.handleTablePage.totalCount = 0;
    this.getHandleList();
  }

  private tableData: any[] = [];
  private handleTableData: any[] = [];
  private handleParams:any = {
    id:'',
    start_date:null,
    end_date:null,
  }
  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };

  private handleTablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };

  private async addDialog(type: string = 'add', row: any = {}): void {
    this.operateType = type;
    console.log(row, 'row');
    this.addForms = {
      ...this.deepLoopUtil.deepCopy(defaultAddForms, false),
    };
    if (this.operateType === 'edit') {
      await this.getMoreVenueList(true);
      let existVenue = this.venueList.find((venue) => venue.id == row.shop_id);

      if (!existVenue) {
        existVenue = {
          id: row.shop_id,
          name: row.shop_name,
        };
        this.venueList.push(existVenue);
      }
      this.addForms = {
        venue: existVenue,
        turnover: parseInt(row.turnover),
        name: row.operate_personnel,
        id:row.id
      };
    }
    this.is_show = true;
  }

  private onHandleFinish(row: any): void {
    this.handleForms = new Util().clearObject(this.handleForms);
    this.handleForms.id = row.id
    this.handleFinishVisible = true
  }

  private onHandleList(row: any): void {
    this.handleParams.id = row.id
    this.getHandleList()
    this.handleListVisible = true
  }


  private onDelete(row: any): void {
    this.$confirm(`确认删除“${row.shop_name}”的预警月营业额吗？`, '提示', {
      ...shareConfirmOptions,
    }).then(() => {
      this.businessLimitWarningApi.deleteBusinessLimit(
          {id: row.id},
          () => {
            this.$message.success('删除成功');
            this.search()
          },()=>{});
    });
  }
  
  private onChangeTablePage(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getList();
  }

  private onChangeHandleTablePage(val: any) {
    this.handleTablePage.page = val.currentPage;
    this.handleTablePage.prepage = val.pageSize;
    this.getHandleList();
  }



  private getList(): void {
    this.businessLimitWarningApi.businessLimitWarningList(
        {...this.getParams()},
        (res: any) => {
          // res.data.list
          this.tableData = res.data.list;
          this.tablePage.page = res.data.page;
          this.tablePage.prepage = res.data.prepage;
          // this.tablePage.totalPage = res.data.total;
          this.tablePage.count = res.data.count;
        },
        () => {
        }
    );
  }

  private getHandleList(): void {
    if (Array.isArray(this.handleListTime) && this.handleListTime.length === 2) {
      const [startDate, endDate] = this.handleListTime;
      this.handleParams.start_date = startDate;
      this.handleParams.end_date = endDate;
    }
    this.businessLimitWarningApi.handleList(
        {
          ...this.handleParams,
          page: this.handleTablePage.pageNo,
          limit: this.handleTablePage.pageSize,
        },
        (res: any) => {
          this.handleTableData = res.data.list;
          this.handleTablePage.page = res.data.page;
          this.handleTablePage.prepage = res.data.prepage;
          this.handleTablePage.count = res.data.count;
        },
        () => {}
    );
  }
  private onInputTurnover(value:any):void{
    const max = 9999999
    const min = 1
    this.addForms.turnover = value.replace(/[^0-9]/g,'');
    if(this.addForms.turnover>max){
      this.addForms.turnover = max
      return
    }
    if(this.addForms.turnover==='0'){
      this.addForms.turnover = min
      return
    }
  }
  private submit(): void {
    const addForms = this.addForms;
    let params:any = {
      turnover: addForms.turnover,
      name: addForms.name,
    };
    if (addForms.venue) {
      params['shop_id'] = addForms.venue.id;
    }
    if(this.operateType === 'edit'){
      params['id'] = addForms.id
      this.businessLimitWarningApi.editBusinessLimitWarning(params, () => {
        this.is_show = false;
        Util.showToast('修改成功');
        this.search();
      },()=>{});
    }else{
      this.businessLimitWarningApi.addBusinessLimitWarning(params, () => {
        this.is_show = false;
        Util.showToast('新增成功');
        this.search();
      },()=>{});
    }
  }

  private onHandleFinishSubmit():void {
    console.log('handle',this.handleForms)
    const { images,remark,id} = this.handleForms
    const imagesId  = images.map((item:any)=>item.fileId).toString()
    console.log('imagesId',imagesId)
    const params = {
      id,
      remark,
      images:imagesId
    }
    this.businessLimitWarningApi.handleFinish(params, () => {
      this.handleFinishVisible = false;
      Util.showToast('处理成功');
      this.search();
    },()=>{});
  }


  // 上传媒体文件
  private handleUploadUserPhoto(file: any, fileList: any[]) {
    this.handleUploadUserMedia(file, fileList, "image");
  }
  private async handleUploadUserMedia(
      file: any,
      fileList: any[],
      mode: string
  ) {
    function getVideoDuration(url: string): any {
      return new Promise((resolve, reject) => {
        var audio = new Audio(url);
        audio.muted = true;
        audio.play().then(() => audio.pause());
        // 元数据已加载

        const handleLoadedmetadata = function (e: any) {
          resolve({
            url,
            duration: audio.duration,
          });

          hanleClearListeners();
        };

        const hanleClearListeners = function () {
          audio.removeEventListener("loadedmetadata", handleLoadedmetadata);
          audio.onerror = null;
        };
        audio.addEventListener("loadedmetadata", handleLoadedmetadata);

        audio.onerror = function (err) {
          reject(err);
        };

        audio.muted = false;
      });
    }

    const editForm: any = this.handleForms;

    const optionList = mode == "image" ? editForm.images : editForm.videos;

    const sendMediaApi =
        mode == "image" || editForm.media_type == 2 ? this.commonApi.getOss : this.commonApi.getOssvideo;

    if (mode == "video" && !file.duration) {
      console.log(editForm, "editForm");
      console.log(editForm.media_type, "editForm.media_type");
      if (editForm.media_type == 1) {
        const { duration } = await getVideoDuration(file.url).catch(
            (err: any) => {
              this.$message("获取时长失败");
            }
        );

        file.duration = duration;
      }
    }

    optionList.push(file);
    
    this.$set(this.handleForms, `${mode}s`, optionList);

    this.changeFilePropByList(file, mode, { uploading: true });
    sendMediaApi.call(
        this.commonApi,
        file instanceof File ? file : file.raw,
        (res: any) => {
          console.log(res, "res 媒体文件上传成功");

          const { data } = res;

          const { data: uploadFileObj } = data || {};

          const { item: uploadFile } = uploadFileObj || {};

          const { id, url } = uploadFile;

          // this.$set(this.forms, 'avatar_id', id)
          // this.$set(this.forms, 'avatar', url)
          const changeProps: any = {
            uploading: false,
            fileId: id,
            uploadUrl: url,
          };

          if (file.duration) {
            changeProps.duration = file.duration.toString();
          }

          this.changeFilePropByList(file, mode, changeProps);
        },
        (err: any) => {
          console.log(err, 'err')
          this.$message.error("媒体文件上传失败");

          this.changeFilePropByList(file, mode, {
            uploading: false,
            failed: true,
          });
        }
    );
  }

  private changeFilePropByList(file: any, mode: string, changes: any = {}) {
    const editForm: any = this.handleForms;

    const setList: any[] = mode == "image" ? editForm.images : editForm.videos;
    const existFileIndex = setList.findIndex(
        (each_file: any) => each_file.uid == file.uid
    );

    if (existFileIndex > -1) {
      const originFile = setList[existFileIndex];

      if (Array.isArray(setList)) {
        setList.splice(existFileIndex, 1, Object.assign(originFile, changes));
      }

      this.$set(this.handleForms, `${mode}s`, setList);
    }
  }


  // 预览媒体文件
  private previewImages: any[] = [];
  private handlePictureCardPreview(file: any, mode: string) {
    const forms = this.handleForms;

    const images = mode == "image" ? forms.images : (forms.media_type === 2 ? forms.videos : []);

    const videos = mode == "video" && forms.media_type === 1 ? forms.videos : [];

    console.log(file, "file");

    console.log(forms, 'forms')

    console.log(images, "images");
    console.log(videos, "videos");

    const urls = [
      ...images.map((img: any) => {
        return {
          title: "",
          type: "image/jpeg",
          href: img.url,
          thumbnail: img,
        };
      }),
      ...videos.map((video: any) => {
        return {
          title: "",
          type: "video/mp4",
          href: video.uploadUrl || video.url,
          poster: `${
              video.uploadUrl || video.url
          }?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`,
        };
      }),
    ];

    console.log(urls, 'urls');

    this.previewImages = urls;
  }

  // 删除媒体文件
  private handleFileRemove(file: any, mode: string) {
    const forms = this.handleForms;

    const images = mode == "image" ? forms.images : [];

    const videos = mode == "video" ? forms.videos : [];

    console.log(file, "file");

    const optionList = images.length > 0 ? images : videos;

    const existRemoveFileIndex = optionList.findIndex(
        (each_file: any) => each_file.uid == file.uid
    );

    if (existRemoveFileIndex > -1) {
      optionList.splice(existRemoveFileIndex, 1);

      this.$set(this.handleForms, `${mode}s`, optionList);
    }
  }
  /** 下拉搜索 */
  private venueList: any[] = [];

  private searchVenueLoading: boolean = false;

  private remoteGetVenueQueryString: string = '';

  private remoteSearchVenueTimer: any = null;

  private searchVenuePageOption: any = {};

  private initRemoteGetVenuePageOption() {
    this.searchVenuePageOption = {
      ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    };
  }

  private getSearchVenueParams() {
    const searchVenuePageOption = this.searchVenuePageOption;

    const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

    const {page, limit} = searchVenuePageOption;

    const params: any = {
      page,
      limit,
    };

    if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
      params.name = remoteGetVenueQueryString.trim();
    }

    return params;
  }

  private getMoreVenueList(refresh: boolean = true) {
    return new Promise((resolve: Function, reject: Function) => {
      if (refresh) {
        this.initRemoteGetVenuePageOption();
      }

      const params = this.getSearchVenueParams();

      this.businessLimitWarningApi.searchVenueList(
          params,
          (res: any) => {
            console.log(res, 'res');

            const venueList: any[] = res.data || [];

            this.venueList = refresh
                ? venueList
                : this.venueList.concat(venueList);

            if (Array.isArray(res.data) && res.data.length == 0) {
              this.searchVenuePageOption.isRearchBottom = true;
            }
            this.searchVenueLoading = false;

            this.addVenueListLoading = false;

            resolve();
          },
          () => {
            this.searchVenueLoading = false;

            this.addVenueListLoading = false;

            reject();
          }
      );
    });
  }

  // 第一次搜索 无关键字
  private handleFirstGetVenueList() {
    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 清空搜索
  private handleClearGetVenueList() {
    this.remoteGetVenueQueryString = '';

    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 远程搜索
  private remoteGetVenueMethod(query: string) {
    query = query.trim ? query.trim() : '';
    if (query !== '') {
      this.remoteGetVenueQueryString = query;
      clearTimeout(this.remoteSearchVenueTimer);
      this.remoteSearchVenueTimer = setTimeout(() => {
        this.searchVenueLoading = true;
        this.getMoreVenueList();
      }, 300);
    } else {
      this.remoteGetVenueQueryString = '';
      this.venueList = [];
    }
  }

  private addVenueListLoading: boolean = false;

  private remoteAddVenueMethod() {
    if (this.addVenueListLoading) {
      return;
    }
    const searchVenuePageOption = this.searchVenuePageOption;

    const {isRearchBottom} = searchVenuePageOption;

    if (isRearchBottom) {
      return;
    }

    this.searchVenuePageOption.page++;

    this.addVenueListLoading = true;

    this.getMoreVenueList(false);
  }

  /** end 下拉搜索 */

  private getParams(mode = 'all'): any {
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const {page, prepage} = tablePage;

    const {venue} = form;


    if (venue && venue.id) {
      params.shop_id = venue.id;
    }


    if (mode === 'all') {
      params.page = page;

      params.limit = prepage;

    }

    return params;
  }

  private downloadExcelLoading: boolean = false;

  // 用户字段名 文案对应关系
  private userExcelList2ch: any = {
    'shop_name': '店铺名称',
    'remark':'文字内容',
    'handle_time': '处理日期',
    'handle_name': '处理人',
  };
  // 用户Excel 表头的先后顺序
  private userExcelSortList: string[] = Object.keys(this.userExcelList2ch)
  // 点击导出excel 表格
  private handleDownloadExcel() {
    if (Array.isArray(this.handleListTime) && this.handleListTime.length === 2) {
      const [startDate, endDate] = this.handleListTime;
      this.handleParams.start_date = startDate;
      this.handleParams.end_date = endDate;
    }
    if (!(this.handleParams.start_date && this.handleParams.end_date)) {
      this.$message.error("请选择时段导出数据");
      return null;
    }
    this.downloadExcelLoading = true;
    this.businessLimitWarningApi.exportList(this.handleParams, (res: any) => {
          const { start_date, end_date } = this.handleParams ;
          const filename = `处理列表 ${start_date}到${end_date}.xlsx`;
          const { data } = res ;

          const { list: originList } = data ;
          const newList = originList

          if (newList.length > 0) {
            const userExcelSortList = this.userExcelSortList;

            const userExcelList2ch = this.userExcelList2ch;

            const excelJSONData = getExcelJSONDataByList(
                newList,
                userExcelSortList,
                userExcelList2ch
            );

            excuteDownloadExport2Excel(excelJSONData, filename);
          } else {
            this.$message.info("选中时段没有可导出的信息");
          }

          this.downloadExcelLoading = false;
        },
        () => {
          this.downloadExcelLoading = false;
        }
    );
  }
  private getUserTableListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.handleParams;

    const tablePage = this.tablePage;

    const { pageNo, pageSize } = tablePage ;

    const outerParams: any = {};

    if (Array.isArray(this.handleListTime) && this.handleListTime.length === 2) {
      const [startDate, endDate] = this.handleListTime;
      outerParams.start_date = startDate;
      outerParams.end_date = endDate;
    }

    if (mode == "exportExcel" && !(outerParams.start_date && outerParams.end_date)) {
      this.$message.error("请选择时段导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;
      outerParams.limit = pageSize;
    }

    return outerParams;
  }
  // 查看视频/图片
  private handleBeforePreviewMedia(row: any) {
    const { image_list } = row || {};

    const previewItem: any = {};

    previewItem.image_count = image_list.length;

    previewItem.images = image_list.map((pic: any) => pic);

    this.handlePreviewMedia(previewItem);
  }

  private images: any = [];

  private handlePreviewMedia(row: any) {
    const { image_count, images } = row || {};

    const urls: any[] = [];
    if(image_count){
      urls.push(
          ...images.map((item: any) => {
            return {
              title: "",
              type: "image/jpeg",
              href: item.url,
              thumbnail: item.url,
            };
          })
      )
    }

    this.images = urls;
  }
}

import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export  class merchantsAudit extends BasicsClass {
    public apiUrls: any = new Map([
        ['business', {
            url: '/admin/shop/business',
            name: 'list',
            label: '列表'
        }],
        ['exportExcelMerchantAuditList',  {
            url: '/admin/shop/exportBusiness',
            name: 'export-excel',
            label: '导出'
        }],
        ['searchVenueList', {
            url: '/admin/coupons/shop/list',
            name: 'shop-list',
            label: '店铺列表',
        }]
       
    ])
    
    // 台球营收列表
    public business(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('business').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 导出台球营收列表
    public exportExcelMerchantAuditList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('exportExcelMerchantAuditList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 搜索店铺列表
    public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('searchVenueList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

}
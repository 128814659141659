import { Axios, AxiosData } from '@/common/http/http';
import router from '@/router';
import { BasicsClass } from '@/common/BasicsClass';
import {
    clearServingChat
  } from '@/utils/index'

interface Result {
    access_token: string;
    token_type: string;
    refresh_token: string;
    id: string;
    name: string;
    showMerchantsSelectionBox: boolean;
    groupCode: string;
}

export interface login {
    phone: string;
    code: string;
}

export interface LoginUserMerchants {
    permissionMchId: string;
    id: string;
    username: string;
    password: string;
    company: string;
    name: string;
    mobile: string;
    email: string;
    mchId: string;
    status: number;
    createTime: string;
    updateTime: string;
    stopTime: string;
    createUserId: string;
    updateUserId: string;
    isAdmin: boolean;
    deleteFlag: boolean;
    roles: string[];
    roleIds: string;
    roleNames: string;
}

export class Login extends BasicsClass {

    private axios: Axios;

    constructor() {
        super();
        this.axios = new Axios();
    }


    // public setRefreshToken(): void {
    //
    //     setTimeout(async () => {
    //         const axios: Axios = new Axios();
    //         await (axios.post<Result>('/edu/sys/refresh', {
    //             refreshToken: localStorage.getItem('refresh_token'),
    //         }).then((result) => {
    //
    //             this.setLocalStorage(result);
    //
    //             this.setRefreshToken();
    //         }).catch((err) => {
    //             // do nothing
    //         }));
    //
    //     }, 1000 * 60 * 60 * 1.5);
    // }

    public async getLoginVertifyCode(param: login, callBack: Function, errCb: Function) {
        try {
            const loginCodeData: any = await this.axios.post<Result>('/admin/send', {
                phone: param.phone,
            }).then(() => {
                callBack({
                    message: ''
                })
            }).catch((err: any) => {
                errCb(err ? err.message : '服务器异常');
            });

           
        } catch (e: any) {

            if (e.message) {
                // this.vue.$message.error(e.message);
                errCb(e.message);
            }

        }

    }


    public async submitForm(param: login, callBack: Function, errCb: Function) {
        try {

            // 更改为 短信登录
            const loginTokenData: any = await this.axios.post<Result>('/admin/sms', {
                phone: param.phone,
                code: param.code,
            });

            const {
                data
            } = loginTokenData || {}

            console.log(data, 'data')

            const {
                token: accessToken,
                end_time: token_expired_ts,
                info: role
            } = data || {}
            const loginData: any = {
                data: {
                    id: 1,
                    admin_name: "测试",
                    showMerchantsSelectionBox: "none"
                }
            }

            // this.setLocalStorage(loginData);
            localStorage.setItem('id', loginData.data.id);
            localStorage.setItem('name', loginData.data.admin_name);
            localStorage.setItem('role', JSON.stringify(role));

            if (accessToken) {
                localStorage.setItem('token', accessToken)

                localStorage.setItem('tokne_expired', token_expired_ts)
            }

            if (loginData.data.showMerchantsSelectionBox) {
                localStorage.setItem('showMerchantsSelectionBox', 'yes');
            }
            // localStorage.setItem('menus', JSON.stringify(userMenus.data));
            // console.log(JSON.stringify(userMenus.data[0].children[0]));

            // for (const i in userMenus.data) {
            //     if (userMenus.data[i].children.length) {
            //         console.log(userMenus.data[i].children[0].url);
            //         this.$router.push(userMenus.data[0].children[0].url);
            //         break;
            //     }
            // }
            // }

            callBack(data);
            // this.$store.commit('setIsTimer');

            // end

            // get menus

            // this.axios.get('/animal/web/menu/currentMenus', {})
            //     .then((data: any) => {
            //         console.log(data);
            //         if (data.data.length !== 0) {
            //             localStorage.setItem('menus', JSON.stringify(data.data));
            //             // let flag: boolean = true;
            //             // for (const i in data.data) {
            //             //     // if(data.data[i].children.length){
            //             //     //     this.$router.push(data.data[i].children[0].url);
            //             //     //     break;
            //             //     // }
            //             //     if (data.data[i].url === '/bigData') {
            //             //         flag = false;
            //             //         this.$router.push(data.data[i].url);
            //             //     }
            //             // }

            //             // if (flag) {

            //             const anies = data.data.filter((value: any) => value.url === '/bigData' || value.url === '/bigDataByPro');
            //             if (anies.length === 2) {
            //                 for (let i = 0; i < data.data.length; i++) {
            //                     if (data.data[i].url === '/bigData') {
            //                         data.data.splice(i, 1);
            //                         localStorage.setItem('bigDataChange', '11');
            //                         break;
            //                     }
            //                 }

            //                 this.$router.push('/bigDataByPro');

            //                 return;
            //             } else if (anies.length === 1) {

            //                 this.$router.push(anies[0].url);
            //                 return;
            //             } else {
            //                 localStorage.removeItem('bigDataChange');
            //             }

            //             for (const i in data.data) {
            //                 if (data.data[i].children.length) {
            //                     console.log(data.data[i].children[0].url);
            //                     this.$router.push(data.data[i].children[0].url);
            //                     break;
            //                 }
            //             }
            //             // }

            //             callBack();

            //         } else {
            //             this.vue.$message.warning('暂无任何权限，请联系管理员添加权限');
            //         }
            //     });

            // end

            // refresh code
            // if (this.$store.state.isTimer) {
            //     this.setRefreshToken();
            // }
            // end
        } catch (e: any) {

            if (e.message) {
                // this.vue.$message.error(e.message);
                errCb(e.message);
            }

        }

    }

    public refreshToken(form: any, showLoading: boolean = false) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('token')

            const token_expired = localStorage.getItem('tokne_expired')

            const nowTs = Math.floor(Date.now() / 1000)


            if (token) {
                if (token_expired && nowTs < Number(token_expired)) {
                    const token_expired_ts = Number(token_expired)

                    if (nowTs > token_expired_ts - 24 * 3600) { // token 有效期不到一天的情况下再刷新
                        const successCb = (res: any) => {
                            const {
                                data
                            } = res || {}

                            // console.log(res, 'res')
                            // console.log(data, 'data')
                            const {
                                token: accessToken,
                                end_time: token_expired_ts
                            } = data || {}

                            if (accessToken) {
                                localStorage.setItem('token', accessToken)

                                localStorage.setItem('tokne_expired', token_expired_ts)
                            }
                            resolve({
                                status: true
                            })
                        }
                        const failCb = (err: any) => {
                            reject(err)
                        }
                        this.BasicPost('/admin/token/refresh', { ...form }, false, false, false, successCb, failCb, showLoading);
                    } else {
                        resolve({
                            status: true
                        })
                    }
                } else {
                    resolve({
                        status: false
                    })
                }

            } else {
                resolve({
                    status: false
                })
            }
        })

    }

    // public getCurrentMerchants(cb: Function): void {
    // this.BasicGet('/animal/web/user/currentMerchants', {}, false, false, cb);
    // }
    public logout(form: any, showLoading: boolean = false): void {
        const successCb = (res: any) => {
            localStorage.setItem('token', '')

            localStorage.setItem('tokne_expired', '0')

            clearServingChat()

            router.replace('/login')
        }
        const failCb = (err: any) => {

        }
        this.BasicPost('/admin/login/out', {
            ...form,
            // groupCode: form.groupCode[form.groupCode.length - 1],
            // dealStartTime: dealTime.startTime,
            // dealEndTime: dealTime.endTime,
            // createStartTime: createTime.startTime,
            // createEndTime: createTime.endTime,
            // createTime: undefined,
            // dealTime: undefined
        }, false, false, false, successCb, failCb, showLoading);
    }
    // private setLocalStorage(result: AxiosData<Result>): void {
    //
    //     localStorage.setItem('access_token', result.data.access_token);
    //     localStorage.setItem('token_type', result.data.token_type);
    //     localStorage.setItem('refresh_token', result.data.refresh_token);
    //
    // }
}

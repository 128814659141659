
import {Component, Vue} from "vue-property-decorator";
import {Util, getPageAPIs} from "@/common/util";
import ChatBanListAPIClass from "@/common/api/operationManage/chatBanList";

export const pageApiList = getPageAPIs(ChatBanListAPIClass)

@Component({})
export default class MerchantsAudit extends Vue {
  private ChatBanListAPIClass: any;
  private title: string = "";
  private is_show: boolean = false;

  private params: any = {
    phone: '',
    status: null,
    start_date: null,
    end_date: null,
  };
  private time = []
  private expireTimeOption = {
    disabledDate(date) {
      // 当天可选：date.getTime() < Date.now() - 24 * 60 * 60 * 1000
      return date.getTime() < Date.now()
    }
  }
  private statusArrays = [
    {value: 0, label: '全部'},
    {value: 1, label: '封禁中'},
    {value: 2, label: '已解封'},
  ]
  private ban_type_arrays = [
    {value: 1, label: '禁止发布动态'},
    {value: 2, label: '禁止登录'},
    {value: 3, label: '禁止评论动态'},
  ]

  private add_forms: any = {
    phone: '',
    ban_type: null,
    ban_time: null,
    ban_reason: '',
  };

  constructor() {
    super();
    this.ChatBanListAPIClass = new ChatBanListAPIClass();
  }


  private addDialog(): void {
    this.title = "新增";
    this.is_show = true;
    this.$nextTick(()=>{
      this.add_forms = {
        phone: '',
        ban_type: null,
        ban_time: null,
        ban_reason: '',
      };
    })
  }

  private submit(): void {
    console.log('this.add_forms', this.add_forms)
    this.ChatBanListAPIClass.addBan(this.add_forms, () => {
      this.is_show = false;
      Util.showToast("新增成功");
      this.search();
    });
  }

  private secure(id: string) {
    this.ChatBanListAPIClass.freeBan(
        {
          id: id,
        },
        () => {
          this.is_show = false;
          Util.showToast("解除成功");
          this.search();
        }
    );
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private activated(): void {
    this.search();
  }

  private getTable(): void {
    this.ChatBanListAPIClass.getList(
        {
          ...this.params,
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
        }
    );
  }

  private restForm(): void {
    this.params = new Util().clearObject(this.params);
    this.time = []
    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    if (Array.isArray(this.time) && this.time.length === 2) {
      const [startDate, endDate] = this.time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      this.params.start_date = startTime;
      this.params.end_date = endTime;
    }
    this.getTable();
  }

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

}

import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class PandaCoinManageAPIClass extends BasicsClass {

    public apiUrls: any = new Map([
        ['getPandaCoinTableList', {
            url: '/admin/currency/index',
            name: 'list',
            label: '列表'
        }],
        ['addPandaCoin', {
            url: '/admin/currency/add',
            name: 'add-merchant',
            label: '添加'
        }],
        ['updatePandaCoin', {
            url: '/admin/currency/edit',
            name: 'edit',
            label: '修改'
        }],
        ['deletePandaCoin', {
            url: '/admin/currency/del',
            name: 'del',
            label: '删除',
        }],
        ['getPandaCoinView', {
            url: '/admin/currency/show',
            name: 'show',
            label: '详情',
        }],

    ])
    // 店铺列表
    public getPandaCoinTableList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const start_date = Util.handleStartTimeAndEndTime(params.time).startTime;
        const end_date = Util.handleStartTimeAndEndTime(params.time).endTime;

        params = {
            ...(params || {}),
            start_date: start_date,
            end_date: end_date
        }

        const apiUrls = this.apiUrls

        const url = apiUrls.get('getPandaCoinTableList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 添加店铺地址
    public addPandaCoin(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('addPandaCoin').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 修改店铺地址
    public updatePandaCoin(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('updatePandaCoin').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    // 删除店铺地址
    public deletePandaCoin(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('deletePandaCoin').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 店铺地址详情
    public getPandaCoinView(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getPandaCoinView').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

}

import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class MerchantOperationerAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getChooseOperationer', {
            url: '/admin/praise/userAll',
            name: 'choose-bind-operationer',
            label: '可绑定运营人员',
            // hidden: true
        }],
        ['merchantOperationerList', {
            url: '/admin/praise/bind',
            name: 'list',
            label: '绑定运营人员列表'
        }],
        ['merchantOperationerAdd', {
            url: '/admin/praise/bindAdd',
            name: 'add',
            label: '新增运营人员'
        }],
        ['merchantOperationerEdit', {
            url: '/admin/praise/bindEdit',
            name: 'edit',
            label: '编辑运营人员'
        }],
        ['merchantOperationerDel', {
            url: '/admin/praise/bindDel',
            name: 'delete',
            label: '删除运营人员'
        }],
    ])

    // 可绑定运营人员
    public getChooseOperationer(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getChooseOperationer').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 绑定运营人员列表
    public merchantOperationerList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('merchantOperationerList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 新增绑定运营人员
    public merchantOperationerAdd(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('merchantOperationerAdd').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 编辑绑定运营人员
    public merchantOperationerEdit(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('merchantOperationerEdit').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 删除绑定运营人员
    public merchantOperationerDel(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('merchantOperationerDel').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}

import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getPageAPIs } from "@/common/util";
// import { screen } from "@/common/screen/screen";
import OpenTimeRewardSettingAPI from "@/common/api/operationManage/openTimeLevelRewardSetting";
// import { CommonApi } from "@/common/api/common";
import { excuteDownloadExport2Excel } from "@/common/xlsx";
import { utils } from "xlsx";

import {
  transferUserLevel2ch,
  getCommonList
} from '@/common/config'

export const pageApiList = getPageAPIs(OpenTimeRewardSettingAPI);



@Component({
  filters: {
    showMemberTypeName(type: number) {
      return transferUserLevel2ch[type] || '未知'
    }
  }
})
export default class OpenTimeRewardSetting extends Vue {
  private form: any = {
    // channelId: "", // 渠道id
    // time: [], // 时段选择
  };
  private openTimeRewardSettingApi: any = new OpenTimeRewardSettingAPI();

  private memberTypeList: any[] = getCommonList(transferUserLevel2ch, 1)

  // private commonApi: any = new CommonApi();
  private created(): void {
    // this.getofficialChannel();
    // this.search();

    this.getTable()
  }
  private getTable(): void {
    this.openTimeRewardSettingApi.getOpenTimeRewardSettingList(
      {
        // ...this.form,
        // startDate: startTime,
        // endDate: endTime,
        page: this.tablePage.pageNo,
        limit: this.tablePage.pageSize,
      },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.pageNo = res.data.page;
        this.tablePage.pageSize = res.data.prepage;
        this.tablePage.totalCount = res.data.count;
      }
    );
  }

  private restForm(): void {
    // this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  // private handlePageChange(val: any) {
  //   this.tablePage.pageNo = val.currentPage;
  //   this.tablePage.pageSize = val.pageSize;

  //   this.getTable();
  // }

  // 扣款金额限制
  private handleLimitMemberPrice(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        this.$set(this.openTimeRewardForm, "member_price", new_val);
      },
      (new_val: string) => {
        this.$set(this.openTimeRewardForm, "member_price", new_val);
      },
    );
  }

  private openTimeRewardForm: any = {
    name: '',
    member_type: '',
    member_price: '',

    is_reward: 1, // 周榜开关
  }

  private refreshOpenTimeSettingInfo(): any {
    return new Promise((resolve, reject) => {
      this.openTimeRewardSettingApi.getOpenTimeSettingInfo({}, (res: any) => 
      resolve(res.data || {}), (err: any) => reject(err))
    })
  }

  private chooseCityList: any[] = []

  private get isEditOpenTimeSettingMode() {
    const mode = this.openTimeRewardDialogMode

    return mode == 'edit'
  }

  private get isSwitchRewardOpenTimeSettingMode() {
    const mode = this.openTimeRewardDialogMode

    return mode == 'switch-reward'
  }

  private async initOpenTimeRewardDialogForm(mode: string = 'edit', currentSettingItem: any) {
    const openTimeRewardForm: any = {
      is_reward: 0,
    }

    this.openTimeRewardDialogTitle = '周榜奖励开关'

    // const chooseCityListParams: any = {
    //   operation: 'switch-reward'
    // }

    switch (mode) {
      case 'switch-reward':
        {
          const {
            is_reward
          } = await this.refreshOpenTimeSettingInfo() || {}

          Object.assign(openTimeRewardForm, {
            is_reward: typeof is_reward == 'number' ? is_reward : openTimeRewardForm.is_reward
          })
        }
        break;
      case 'edit':
        {
          // chooseCityListParams.operation = 'save'
          this.openTimeRewardDialogTitle = '编辑'

          const {
            id,
            name,
            member_type,
            member_price
          } = currentSettingItem || {}

          // chooseCityListParams.id = id
          

          Object.assign(openTimeRewardForm, {
            id,
            name,
            member_type,
            member_price
          })
        }
        break;
    }


    this.openTimeRewardForm = openTimeRewardForm
  }

  // 编辑充值项
  private handleEditRechargeItem(openTimeRewardItem: any) {
    console.log(openTimeRewardItem, 'openTimeRewardItem')
    this.openTimeRewardDialogMode = 'edit'
    this.initOpenTimeRewardDialogForm(this.openTimeRewardDialogMode, openTimeRewardItem)

    this.openTimeRewardDialogVisible = true

    this.$nextTick(() => {
      this.clearvaliateOpenTimeRewardForm()
    })
  }

  // 拉起 奖励开关弹框
  private handleWakeSwitchOpenTimeRewardDialog() {
    this.openTimeRewardDialogMode = 'switch-reward'
    this.initOpenTimeRewardDialogForm(this.openTimeRewardDialogMode, null)

    this.openTimeRewardDialogVisible = true

    this.$nextTick(() => {
      this.clearvaliateOpenTimeRewardForm()

    })
  }

  // 清空表单的校验
  private clearvaliateOpenTimeRewardForm() {
    const refs: any = this.$refs

    const {openTimeRewardForm} = refs

    const {
      clearValidate
    } = openTimeRewardForm

    if (typeof clearValidate == 'function') {
      clearValidate()
    }
  }

  // 充值项的参数
  private getOpenTimeRewardFormParams(): any {
    const params: any = {}

    const openTimeRewardForm = this.openTimeRewardForm

    console.log(openTimeRewardForm, 'openTimeRewardForm')

    const mode = this.openTimeRewardDialogMode

    const {
      member_price,
      member_type,
      is_reward,
      id,
    } = openTimeRewardForm || {}

    switch (mode) {
      case 'switch-reward':
        Object.assign(params, {
          is_reward
        })
        break;
      case 'edit':
        if (!member_price) {
          this.$message.error('请输入奖励金额') 
          return
        }

        Object.assign(params, {
          id,
          member_price,
          member_type
        })

        break;
    }

   

    // console.log(params, 'params')


    // return false

    return params
  }

  private openTimeRewardDialogMode: string = "switch-reward"; // 开关 switch-reward  编辑 edit

  private openTimeRewardDialogTitle: string = '新增'

  private openTimeRewardDialogVisible: boolean = false

  // 充值项的修改
  private sumbitRechargeLoading: boolean = false

  // 提交充值项
  private handleSumbitRecharge() {
    const refs: any = this.$refs

    const {openTimeRewardForm} = refs

    openTimeRewardForm.validate((valid: boolean) => {
      if (valid) {
        const params = this.getOpenTimeRewardFormParams()

        console.log(params, 'params')

        if (!params) { return }

        this.sumbitRechargeLoading = true

        const openTimeRewardDialogMode = this.openTimeRewardDialogMode

        if (openTimeRewardDialogMode == 'edit') { // 编辑充值配置
          this.openTimeRewardSettingApi.updateOpenTimeRewardConfig(params, () => {
            this.sumbitRechargeLoading = false

            this.openTimeRewardDialogVisible = false
            this.getTable()
          }, () => {
            this.sumbitRechargeLoading = false
          })
        } else if (openTimeRewardDialogMode == 'switch-reward'){ // 开关
          this.openTimeRewardSettingApi.editOpenTimeRewardConfig(params, () => {
            this.sumbitRechargeLoading = false

            this.openTimeRewardDialogVisible = false
            this.getTable()
          }, () => {
            this.sumbitRechargeLoading = false
          })
        }

      } else {
        this.$message.error("带 * 项为必填项")
      }
    })
    
  }

  /** 导出excel */
  // private downloadExcelLoading: boolean = false;

  // // channel渠道字段名 文案对应关系
  // private merchantExcelList2ch: any = {
  //   channelId: '渠道id',
  //   channelName: "渠道名称",
  //   date: "日期",
  //   followNum: "扫码关注数量",
  //   registerNum: "注册数量",
  //   LoginNum: "登录用户数",
  //   completeOrderNum: '完成订单数',
  //   completeOrderAmount: '完成订单金额',
  //   completeOrderPeopleNum: '完成订单人数',
  //   notCompleteNum: '未完成订单数',
  //   notCompleteAmount: '未完成订单金额',
  //   notCompletePeopleNum: '未完成订单数人数',
  //   deviceNum: '新增设备数',
  //   newUserCompleteOrderNum: '新用户完成订单数',
  //   newUserCompleteOrderAmount: '新用户完成订单支付金额',
  //   newUserCompleteOrderPeopleNum: '新用户完成订单人数',
  //   newUserNotCompleteNum: '新用户未完成订单数',
  //   newUserNotCompleteAmount: '新用户未完成订单金额',
  //   newUserNotCompletePeopleNum: '新用户未完成订单人数',
  //   timeoutOrderNum: '超时取消订单数',
  //   timeoutNotPayAmount: '超时未支付金额',
  //   timeoutPeopleNum: '超时取消订单人数',
  //   activeNum: '活跃人数',
  //   retentionPeopleNum: '次日留存人数',
  //   retentionRate: '次日留存率',
  //   totalPaymentPeopleNum: '总付费人数',
  //   totalPaymentRate: '总付费率',
  //   averagePrice: '平均单价',
  //   arpu: 'ARPU',
  //   arppu: 'ARPPU'
  // };
  // // 商户Excel 表头的先后顺序
  // private merchantExcelSortList: string[] = [
  //   "channelId",
  //   "channelName",
  //   "date",
  //   "followNum",
  //   "registerNum",
  //   "LoginNum",
  //   "completeOrderNum",
  //   "completeOrderAmount",
  //   "completeOrderPeopleNum",
  //   "notCompleteNum",
  //   "notCompleteAmount",
  //   "notCompletePeopleNum",
  //   "deviceNum",
  //   "newUserCompleteOrderNum",
  //   "newUserCompleteOrderAmount",
  //   "newUserCompleteOrderPeopleNum",
  //   "newUserNotCompleteNum",
  //   "newUserNotCompleteAmount",
  //   "newUserNotCompletePeopleNum",
  //   "timeoutOrderNum",
  //   "timeoutNotPayAmount",
  //   "timeoutPeopleNum",
  //   "activeNum",
  //   "retentionPeopleNum",
  //   "retentionRate",
  //   "totalPaymentPeopleNum",
  //   "totalPaymentRate",
  //   "averagePrice",
  //   "arpu",
  //   "arppu"
  // ];

  // // 点击导出excel 表格
  // private handleExportExcelChannelList() {
  //   const form = this.form
  //   const {
  //     time,
  //     channelId
  //   } = form

  //   const params: any = {
  //     channelId, 
  //   };
    
  //   if (Array.isArray(time) && time.length === 2) {
  //     params.startDate = Util.dateTimeUtilnohms(this.form.time[0])
  //     params.endDate = Util.dateTimeUtilnohms(this.form.time[1])
  //   } else {
  //     this.$message.error("请选择时段")

  //     return
  //   }

  //   if (params === null) {
  //     return false;
  //   }

  //   this.downloadExcelLoading = true;
  //   this.openTimeRewardSettingApi.exportExcelChannelList(
  //     params,
  //     (res: any) => {
  //       const { startDate, endDate } = params || {};

  //       const filename = `商户列表 ${startDate}到${endDate}.xlsx`;
  //       const { data: originList } = res || {};

  //       // const {
  //       //   list: originList
  //       // } = data || {}

  //       const newList = Array.isArray(originList)
  //         ? originList.map((item) => {
  //             // const { orderNum } = item || {};
  //             const newItem = {
  //               ...(item || {}),
  //             };

  //             for (let key in item) {
  //               if (typeof item[key] == 'number') {
  //                 newItem[key] = item[key].toString()
  //               }
  //             }

  //             // newItem.orderNum =
  //             //   typeof orderNum == "number"
  //             //     ? orderNum.toString()
  //             //     : orderNum || "";

  //             return newItem;
  //           })
  //         : [];

  //       if (newList.length > 0) {
  //         const merchantExcelSortList = this.merchantExcelSortList;

  //         const merchantExcelList2ch = this.merchantExcelList2ch;

  //         const excelJSONData = getExcelJSONDataByList(
  //           newList,
  //           merchantExcelSortList,
  //           merchantExcelList2ch
  //         );

  //         excuteDownloadExport2Excel(excelJSONData, filename);
  //       } else {
  //         this.$message.info("选中时段没有可导出的信息");
  //       }

  //       // const testData = [
  //       //   { 主页: "test1223424", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
  //       //   { 主页: "433", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
  //       //   { 名称: "22", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
  //       //   { 名称: "43", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
  //       //   { 店家: "43", 价格: "6800", 数量: "6800", 昵称: "广告主网", }
  //       // ]

  //       this.downloadExcelLoading = false;
  //     },
  //     () => {
  //       this.downloadExcelLoading = false;
  //     }
  //   );
  // }

  /** end 导出excel */
}

import { render, staticRenderFns } from "./operationalServiceRating.vue?vue&type=template&id=a1650c7e&scoped=true"
import script from "./operationalServiceRating.vue?vue&type=script&lang=ts"
export * from "./operationalServiceRating.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1650c7e",
  null
  
)

export default component.exports
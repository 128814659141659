
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList,getPageAPIs } from "@/common/util";
// import { screen } from "@/common/screen/screen";
import {MolisMerchantListAPI} from "@/common/api/merchantManage/molisMerchantList";
import { CommonApi } from "@/common/api/common";
import { excuteDownloadExport2Excel } from "@/common/xlsx";
import { utils } from "xlsx";
// import gallery from "@/components/gallery.vue";
import { DeepLoop } from "@/common/deepLoop/deepLoop";

import {
  // shareShopTag2ch,
  shareConfirmOptions
} from '@/common/config';

import amap from "@/components/amap.vue";
export const pageApiList = getPageAPIs(MolisMerchantListAPI);


@Component({
  components: {
    amap,
    // gallery,
  },
})
export default class MerchantsList extends Vue {
  private form: any = {
    shop_id: '',
    shop_name: '',
    mobile: '',
    time: [],
  };
  private molisMerchantListApi: any;

  private deepLoopUtil: any;

  constructor() {
    super();
    this.molisMerchantListApi = new MolisMerchantListAPI();

    this.deepLoopUtil = new DeepLoop();
  }

  private activated(): void {
    this.getTable();
  }
  
  private getMolixMerchantListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const { pageNo: page, pageSize: prepage } = tablePage;

    const {
      shop_id = '',
      shop_name = '',
      mobile = '',
      time,
    } = form;

    if (shop_id) {
      params.shop_id = shop_id
    }

    if (shop_name) {
      params.shop_name = shop_name
    }

    if (mobile) {
      params.mobile = mobile
    }


    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      params.start_date = startTime;

      params.end_date = endTime;
    }


    // if (
    //   mode == "exportExcel" &&
    //   !(params.gain_date_start && params.gain_date_end)
    // ) {
    //   this.$message.error("请选择日期导出数据");
    //   return null;
    // }

    if (mode === "all") {
      params.page = page;

      params.limit = prepage;
    }

    return params;
  }

  private getTable(): void {
    this.molisMerchantListApi.getMerchantTableList(
      {
        ...this.getMolixMerchantListParams(),
      },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.totalCount = res.data.count;
      }
    );
  }

  // 切换为 熊猫球社 店铺类型
  private handleSwitchShopType(shopItem: any) {
     this.$confirm(`确定把麦力士切换为熊猫球社平台显示吗?`, "提示", {
      ...shareConfirmOptions,
    }).then(() => {
      this.molisMerchantListApi.switchMolisMerchant(
        {
          id: shopItem.id
        },
        () => {
          this.$message.success('切换成功')

          this.getTable()
        }
      )
    })
  }

  // private shop_member: number = 1;
  // private changeshop_member(): void {
  //   if (this.shop_member) {
  //     this.storeinfo.shop_member = 2;
  //   } else {
  //     this.storeinfo.shop_member = 1;
  //   }
  // }

  /** 导出excel */
  // private downloadExcelLoading: boolean = false;

  // // 商户字段名 文案对应关系
  // private merchantExcelList2ch: any = {
  //   merchantName: "商户名",
  //   proofCode: "营业执照ID",
  //   mobile: "联络电话",
  //   price: "收入",
  //   orderNum: "完成单数",
  // };
  // // 商户Excel 表头的先后顺序
  // private merchantExcelSortList: string[] = [
  //   "merchantName",
  //   "proofCode",
  //   "mobile",
  //   "price",
  //   "orderNum",
  // ];

  // // 点击导出excel 表格
  // private handleExportExcelMerchantList() {
  //   this.getTable();
  //   const params: any = this.tableData;

  //   if (params === null) {
  //     return false;
  //   }

  //   this.downloadExcelLoading = true;
  //   this.molisMerchantListApi.exportExcelMerchantList(
  //     params,
  //     (res: any) => {
  //       const { startDate, endDate } = params || {};

  //       const filename = `商户列表 ${startDate}到${endDate}.xlsx`;
  //       const { data: originList } = res || {};

  //       // const {
  //       //   list: originList
  //       // } = data || {}

  //       const newList = Array.isArray(originList)
  //         ? originList.map((item) => {
  //             const { orderNum } = item || {};
  //             const newItem = {
  //               ...(item || {}),
  //             };

  //             newItem.orderNum =
  //               typeof orderNum == "number"
  //                 ? orderNum.toString()
  //                 : orderNum || "";

  //             return newItem;
  //           })
  //         : [];

  //       if (newList.length > 0) {
  //         const merchantExcelSortList = this.merchantExcelSortList;

  //         const merchantExcelList2ch = this.merchantExcelList2ch;

  //         const excelJSONData = getExcelJSONDataByList(
  //           newList,
  //           merchantExcelSortList,
  //           merchantExcelList2ch
  //         );

  //         excuteDownloadExport2Excel(excelJSONData, filename);
  //       } else {
  //         this.$message.info("选中时段没有可导出的信息");
  //       }

  //       // const testData = [
  //       //   { 主页: "test1223424", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
  //       //   { 主页: "433", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
  //       //   { 名称: "22", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
  //       //   { 名称: "43", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
  //       //   { 店家: "43", 价格: "6800", 数量: "6800", 昵称: "广告主网", }
  //       // ]

  //       this.downloadExcelLoading = false;
  //     },
  //     () => {
  //       this.downloadExcelLoading = false;
  //     }
  //   );
  // }

  /** end 导出excel */
 
  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }
}

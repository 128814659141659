import {
  ASEETS_DOAMIN
} from '@/common/env'

const NOTIFY_DENY_MSG: string = '当前浏览器已禁用通知，请手动打开配置开启通知权限'

const NOTIFY_NOT_SUPPORT_MSG: string = '当前浏览器不支持通知功能，请更换浏览器或升级浏览器版本'

export function requireNiotifyPermissionUseSharedWork(errCallBack: Function): Promise<any> {
  const tipCb = (...args: any) => {
    if (typeof errCallBack == 'function') {
      errCallBack(...args)
    }
  }

  console.log(Notification.permission, 'Notification.permission')

  return new Promise((resolve, reject) => {
    if (!(typeof Notification == 'function')) {
      console.log(NOTIFY_NOT_SUPPORT_MSG)
      tipCb(NOTIFY_NOT_SUPPORT_MSG)
      reject(NOTIFY_NOT_SUPPORT_MSG)
    } else if (Notification.permission === "granted") {
      resolve({
        msg: 'success'
      })
    } else if (Notification.permission === "denied") {
      tipCb(NOTIFY_DENY_MSG)
      reject(NOTIFY_DENY_MSG)
    } else {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          resolve({
            msg: 'success'
          })
        } else {
          tipCb(NOTIFY_DENY_MSG)
          reject(NOTIFY_DENY_MSG)
        }
      })
    }
  })
  
}

export function requireNiotifyPermission(): Promise<any> {
  if (!window) return Promise.reject('err env')
  return new Promise((resolve, reject) => {
    if (!(typeof Notification == 'function')) {
      console.log(NOTIFY_NOT_SUPPORT_MSG)
      import("element-ui").then(({ Message }) => Message.error(NOTIFY_NOT_SUPPORT_MSG))
      
      reject(NOTIFY_NOT_SUPPORT_MSG)
    } else if (Notification.permission === "granted") {
      resolve({
        msg: 'success'
      })
    } else if (Notification.permission === "denied") {
      import("element-ui").then(({ Message }) => Message.error(NOTIFY_DENY_MSG))
      reject(NOTIFY_DENY_MSG)
    } else {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          resolve({
            msg: 'success'
          })
        } else {
          import("element-ui").then(({ Message }) => Message.error(NOTIFY_DENY_MSG))
          reject(NOTIFY_DENY_MSG)
        }
      })
    }
  })
  
}


const defaultNotificationOption: any = {
  dir: 'auto', // 显示通知的方向。它默认为 auto，即只采用浏览器的语言设置行为，但你可以通过设置 ltr 和 rtl 的值来覆盖该行为（尽管大多数浏览器似乎忽略这些设置）

  lang: 'ZH', // 指定通知的语言，根据 RFC 5646: 识别语言的标签（也被称为 BCP47）使用表示语言标签的字符串指定。请参阅 Sitepoint ISO 2 字母语言代码页面以获取简单参考。

  badge: `${ASEETS_DOAMIN}/upload/img/avatar.png`, // 一个包含图像 URL 的字符串，用于在没有足够空间显示通知本身时表示通知。

  tag: "熊猫通知", // 一个表示通知的识别标签的字符串，默认值是一个空字符串。

  silent: false, // 一个布尔值，指定通知是否静音（不发出声音或振动），无论设备设置如何。默认值为 null。如果被设置为 true，那么不能同时存在 vibrate 参数

  requireInteraction: true, // 指示通知应保持活动状态，直到用户单击或关闭它，而不是自动关闭。默认值为 false。 
  
  icon: `${ASEETS_DOAMIN}/upload/img/avatar.png`,
  // icon: pandaIcon, // 一个包含要在通知中显示的图标的 URL 的字符串。

  body: '您有一条新消息，请及时回复'
}

const activeNotifications: Notification[] = []


export function initActiveNotification(notification: Notification) {
  notification.onclose = function() {
    const existNotifyIndex: number = activeNotifications.findIndex((notify) => notify == this)

    if (existNotifyIndex > -1) {
      activeNotifications.splice(existNotifyIndex, 1)
    }
  }

  activeNotifications.push(notification)
}

export function closeNotification(notification: Notification) {
  notification.close()
}

export function clearNotification() {
  console.log(activeNotifications, 'activeNotifications')
  activeNotifications.forEach((notify) => notify.close())

  activeNotifications.length = 0
}


export async function spawnPandaNotification(title: string, body: string = defaultNotificationOption.body, useShareWork: boolean = false, errCallBack: Function) {
  console.log(Notification, 'Notification')
  const result = await (useShareWork ? requireNiotifyPermissionUseSharedWork(errCallBack) : requireNiotifyPermission())

  console.log(result, 'result')

  const {
    msg
  } = result || {}

  const option = Object.assign({}, defaultNotificationOption, {
    body
  })

  if (msg == 'success') {
    const notification = new Notification(title, option);

    initActiveNotification(notification)

    return Promise.resolve({
      notification
    })
  } else {
    return Promise.reject('通知未知异常')
  }
}





import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class SmsSendRecordAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getSmsSendRecordList', {
      url: '/admin/smsPush/smsPushRecordList',
      name: 'list',
      label: '列表'
    }],
    
    // ['exportExcelSmsSendRecordList', {
    //   url: '/admin/officialWeekReward/exportRecordList',
    //   name: 'export-excel',
    //   label: '导出'
    // }],
   
  ])

  // 短信发送记录列表
  public getSmsSendRecordList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getSmsSendRecordList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

   // 导出短信发送记录列表
  //  public exportExcelSmsSendRecordList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
  //   const apiUrls = this.apiUrls

  //   const url = apiUrls.get('exportExcelSmsSendRecordList').url

  //   this.BasicPost(url, {
  //     ...params
  //   }, false, false, false, successCb, failCb, showLoading)
  // }
}

import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Util, getExcelJSONDataByList, getPageAPIs} from "@/common/util";
import {ShopAddressAPIClass} from "@/common/api/operationManage/shopAddress";
import gallery from "@/components/gallery.vue";
import { CommonApi } from "@/common/api/common";
import {DeepLoop} from "@/common/deepLoop/deepLoop";

import {
  chooseShopTagList, shareConfirmOptions
} from '@/common/config';

import amap from "@/components/amap.vue";

export const pageApiList = getPageAPIs(ShopAddressAPIClass);

interface Form {
  name: string,
  images: any[],
  cover_id?: any,
  address:string,
  lat?:number | string,
  long?:number | string,
  id?:number | string,
}

const defaultStoreInfo: Form = {
  name: '',
  images: [],
  address : '',
  lat:'',
  long:'',
}

@Component({
  components: {
    amap,
    gallery,
  },
})
export default class MerchantsList extends Vue {
  private form: any = {
    time: [],
  };
  private dialogType:string = 'add'
  private dialogVisible: boolean = false;
  private storeinfo: Form = {
    name: '',
    images: [],
    address : '',
    lat:'',
    long:'',
  };
  private shopAddressApi: any;
  private isWatch: boolean = true;
  private deepLoopUtil: any;

  private commonApi: any;

  constructor() {
    super();
    this.shopAddressApi = new ShopAddressAPIClass();

    this.deepLoopUtil = new DeepLoop();

    this.commonApi = new CommonApi();

    this.storeinfo = this.deepLoopUtil.deepCopy(defaultStoreInfo, false)
  }

  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    this.shopAddressApi.getShopAddressTableList(
        {
          ...this.form,
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
        }
    );
  }

  /** 上传图片方法 */
  // 上传媒体文件
  private handleUploadUserImages(file: any, fileList: any[]) {
    this.handleUploadUserMedia(file, fileList, "images", "image");
  }

  private async handleUploadUserMedia(
    file: any,
    fileList: any[],
    field: string,
    mode: string
  ) {
    console.log(file, "file");

    function getVideoDuration(url: string): any {
      return new Promise((resolve, reject) => {
        var audio = new Audio(url);
        audio.muted = true;
        audio.play().then(() => audio.pause());
        // 元数据已加载

        const handleLoadedmetadata = function (e: any) {
          // let duration = audio.duration;
          console.log(e, "e audio");
          resolve({
            url,
            duration: audio.duration,
          });

          hanleClearListeners();
        };

        const hanleClearListeners = function () {
          audio.removeEventListener("loadedmetadata", handleLoadedmetadata);
          audio.onerror = null;
        };
        audio.addEventListener("loadedmetadata", handleLoadedmetadata);

        audio.onerror = function (err) {
          reject(err);
        };

        audio.muted = false;
      });
    }

    const forms: any = this.storeinfo;

    const optionList = mode == "image" ? forms[field] : forms.videos;

    const sendMediaApi =
      mode == "image" ? this.commonApi.getOss : this.commonApi.getOssvideo;

    if (mode == "video" && !file.duration) {
      const { duration } = await getVideoDuration(file.url).catch(
        (err: any) => {
          this.$message("获取时长失败");
        }
      );

      file.duration = duration;
    }

    optionList.push(file);

    console.log(forms, "forms");

    this.$set(this.storeinfo, `${field}`, optionList);

    this.changeFilePropByList(file, mode, field, { uploading: true });
    sendMediaApi.call(
      this.commonApi,
      file instanceof File ? file : file.raw,
      (res: any) => {
        console.log(res, "res 图片上传成功");

        const { data } = res;

        const { data: uploadFileObj } = data || {};

        const { item: uploadFile } = uploadFileObj || {};

        const { id, url } = uploadFile;

        // this.$set(this.forms, 'avatar_id', id)
        // this.$set(this.forms, 'avatar', url)
        const changeProps: any = {
          uploading: false,
          fileId: id,
          uploadUrl: url,
        };

        if (file.duration) {
          changeProps.duration = file.duration.toString();
        }

        this.changeFilePropByList(file, mode, field, changeProps);
      },
      () => {
        this.$message.error("图片上传失败");

        this.changeFilePropByList(file, mode, field, {
          uploading: false,
          failed: true,
        });
      }
    );
  }

  private changeFilePropByList(
    file: any,
    mode: string,
    field: string,
    changes: any = {}
  ) {
    const forms: any = this.storeinfo;

    const setList: any[] = mode == "image" ? forms[field] : forms.videos;
    const existFileIndex = setList.findIndex(
      (each_file: any) => each_file.uid == file.uid
    );

    if (existFileIndex > -1) {
      const originFile = setList[existFileIndex];

      if (Array.isArray(setList)) {
        setList.splice(existFileIndex, 1, Object.assign(originFile, changes));
      }

      this.$set(this.storeinfo, `${field}`, setList);
    }
  }

  // 删除媒体文件
  private handleFileRemove(file: any, field: string, mode: string) {
    const forms: any = this.storeinfo;

    const images = mode == "image" ? forms[field] : [];

    const videos = mode == "video" ? forms.videos : [];

    console.log(file, "file");

    const optionList = images.length > 0 ? images : videos;

    const existRemoveFileIndex = optionList.findIndex(
      (each_file: any) => each_file.uid == file.uid
    );

    if (existRemoveFileIndex > -1) {
      optionList.splice(existRemoveFileIndex, 1);

      this.$set(this.storeinfo, `${field}`, optionList);
    }
  }



  // 删除店铺地址
  private clickDeleteShopAddress(item: any) {
    this.$confirm(`确定删除该${item.address}？`, '', {
      ...shareConfirmOptions
    }).then(() => {
      this.shopAddressApi.deleteShopAddress({
            id: item.id
          },
          () => {
            Util.showToast("删除成功");
            this.search();
          }
      )
    })
  }


  private getAddress(regeocode: any, lat: string, log: string): void {
    this.isWatch = false;
    this.storeinfo.lat = log;
    this.storeinfo.long = lat;
    this.storeinfo.address = regeocode.formattedAddress;
    this.$nextTick(() => {
      this.isWatch = true;
    });
  }


  private setCity(city: any) {
    console.log(city);
  }

  private addstore(item: any): void {
    this.dialogType = 'add'
    this.storeinfo = this.deepLoopUtil.deepCopy(defaultStoreInfo, false)

    console.log(this.storeinfo, 'this.storeinfo')

    this.dialogVisible = true
    this.$nextTick(()=>{
      this.storeinfo.address = '武汉'
    })
  }

  private clickEditShopAddress(item:any):void{
    this.dialogType = 'edit'
    this.dialogVisible = true
    this.$nextTick(()=>{
      this.shopAddressApi.getShopAddressView({id:item.id}, (res: any) => {
        const shop = res.data.item

        const images: any[] = []

        if (item.cover_id) {
          images.push({
            fileId: item.cover_id,
            uploadUrl: item.cover_url
          })
        }
        this.storeinfo = {
          name: item.name,
          images,
          address:item.address,
          lat:shop.lat,
          long:shop.long,
          id:shop.id,
        }
      });
    })

  }
  private handleClose(done: any): void {
    this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {
        });
  }


  private substore(): void {
    if(!this.storeinfo.address){
      Util.showToast("店铺地址必填",'error');
      return
    }

    if (this.storeinfo.images && this.storeinfo.images.length) {
      this.storeinfo.cover_id = this.storeinfo.images[0].fileId
    } else {
      Util.showToast("店铺封面必填",'error');
      return
    }

    console.log('this.storeinfo',this.storeinfo)
    if(this.dialogType === 'edit'){
      this.shopAddressApi.updateShopAddress(this.storeinfo, (res: any) => {
        this.dialogVisible = false;
        Util.showToast("修改成功");
        this.search();
      });
    }else{
      this.shopAddressApi.addShopAddress(this.storeinfo, (res: any) => {
        this.dialogVisible = false;
        Util.showToast("添加成功");
        this.search();
      });
    }

  }


  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

}

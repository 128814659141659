import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class RecommandWithdrawAuditAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getList', {
            url: '/admin/operate_partner/withdrawInfo',
            name: 'list',
            label: '列表'
        }],
        ['exportRecommanderWithdrawAuditList', {
            url: '/admin/operate_partner/exportWithdrawInfo',
            name: 'export-excel',
            label: '导出'
        }],
        ['updateWithdrawAccountAuditPassStatus',  {
            url: '/admin/operate_partner/withdrawAgree',
            name: 'pass-status',
            label: '审核通过'
        }],
        ['updateWithdrawAccountAuditRejectStatus',  {
            url: '/admin/operate_partner/withdrawReject',
            name: 'reject-status',
            label: '审核拒绝'
        }],
        ['batchAuditAgree', {
            url: '/admin/operate_partner/approvedAll',
            name: 'batch-pass-status',
            label: '批量审核通过'
        }],
    ])
    
    // 运营合伙人提现审核列表
    public getList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    

    // 导出运营合伙人提现审核列表 
    public exportRecommanderWithdrawAuditList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('exportRecommanderWithdrawAuditList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 运营合伙人提现审核状态更新
    public updateWithdrawAccountAuditStatus(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        
        const urlModes: any = {
            'agree': apiUrls.get('updateWithdrawAccountAuditPassStatus').url,
            'disagree': apiUrls.get('updateWithdrawAccountAuditRejectStatus').url,
        }

        let url: string = urlModes[params.mode]

        delete params.mode

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading);
    }

    // 批量审核通过
    public batchAuditAgree(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('batchAuditAgree').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}

import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
// import { CouponsManageAPI } from "@/common/api/couponList";
import BoardcastBigViewListAPI from "@/common/api/operationManage/boardcastBigViewList";

import { CommonApi } from "@/common/api/common";

import { excuteDownloadExport2Excel } from "@/common/xlsx";
import { DeepLoop } from "@/common/deepLoop/deepLoop";

import {
  brandType2Ch,
  getCommonList
} from '@/common/config'

import gallery from "@/components/gallery.vue";

import ZlSelect from "@/components/third-custom-components/select";

import ZlOption from "@/components/third-custom-components/option";

import { shareConfirmOptions } from "@/common/config";

export const pageApiList = getPageAPIs(BoardcastBigViewListAPI);

const defaultForm = {
  brand_type: 1, // 品牌类型 1熊猫 2麦力士（众享）
}

const defaultForms = {
  // total_money: '', // 流水
  // remark: '', // 备注
  // operate_name: '', // 运营
  images: [],
  videos: [],
  // is_timing: 0, // 是否定时开关机:1是0否
  // open_time: "", // 开启时间
  // close_time: "", // 关闭时间
  // sound_size: 0,
  media_type: 1, // 媒体类型:1视频2图片 3直播
  is_view_image: 1, // 是否播放助教图片:0否1是
  // is_clear: 1, // 是否定期清除缓存1:是0:否

  is_round_table: 1, // 是否球桌轮询1:是0:否

  live_streaming_address: '', // 直播流地址
  venue_id: "",
  venues: [],
  // singleMaxCountType: '0',
  // shop_id: "",
  // maxLimitCount: ''
};

const defaultDeviceOptionForms = {
  sound_size: 0, // 声音大小0-100
  is_clear: 1, // 是否定期清除缓存1:是0:否
};

const defaultSearchVenuePageOption = {
  page: 1,
  limit: 10,
  isRearchBottom: false,
};

const businessReminderDialogTitle2ch: any = {
  "new-boardcast-big-view": "新增",
  "edit-boardcast-big-view": "编辑",
};

const mode2BrandType: any = {
  'panda': 1,
  'molis': 2,
}

// 最大有效期
// const maxExpiredDays = 9999;

// 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;

const maxTotalMoney = 9999999;

@Component({
  components: {
    ZlSelect,
    ZlOption,
    gallery,
  },
  filters: {
    showMutipleShopNames(tipRow: any) {
      const { shop_name = "", assistant_shop_name = "" } = tipRow || {};

      const names: string[] = [shop_name, assistant_shop_name].filter(
        (name: string) => !!name
      );

      return names.join("、");
    },

    showAutoSwitchSetting(is_timimg: number) {
      // 是否定时开关机:1是0否
      const settingMode2Ch: string[] = ["关闭", "开启"];

      return settingMode2Ch[is_timimg];
    },

    showBrandTypeName(brand_type: number) {
      return brandType2Ch[brand_type] || '未知'
    }
  },
})
export default class BoardcastBigViewList extends Vue {
  private form: any = {
   
    // time: []
    // shop_id: '',
    // time: [],
    // startDate: "",
    // endDate: "",
  };
  private forms: any = {
    // maxType: "0",
    // ...defaultForms
  };

  private deviceForms: any = {};

  private deepLoopUtil: any = {};

  // private merchantApi: any;

  private boardcastBigViewListApi = new BoardcastBigViewListAPI();

  private commonApi: any;

  private max_upload_image_count = 30;

  private max_upload_video_count = 36; // 媒体上传 上限统一改为 36

  // 媒体类型:1视频2图片3直播
  private mediaTypeList: any[] = [
    {
      label: "图片",
      value: 2,
    },
    {
      label: "视频",
      value: 1,
    },
    {
      label: "直播",
      value: 3,
    },
  ];

  private get isShowUploadMedia() {
    const forms = this.forms

    const {
      media_type
    } = forms || {}
    
    const allowShowUploadMediaTypes: number[] = [1, 2]

    return allowShowUploadMediaTypes.includes(media_type)
  }

  private get uploadMediaType() {
    const forms = this.forms;

    const { media_type } = forms || {};

    switch (media_type) {
      case 1:
        return "video/*";
      case 2:
        return "image/*";
      default:
        return "video/*";
    }
  }

  private chooseBrandTypeList: any[] = [
    ...getCommonList(brandType2Ch, 1)
  ]

  private _handleRefreshMeidaList() {
    this.$set(this.forms, "videos", []);
  }

  // private commonTimerPickOptions: any;

  // private storageLockerTimeDurationAPI = new BoardcastBigViewTimeDurationManageAPI();
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.commonApi = new CommonApi();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm, false)
    }

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    this.deviceForms = {
      ...this.deepLoopUtil.deepCopy(defaultDeviceOptionForms, false),
    };

    this.searchVenuePageOption = {
      ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    };

    // this.merchantApi = new MerchantApi();
  }

  private created(): void {
    this.getBoardcastBigViewList();

    // this.refreshBoardcastBigViewTimeDurations();

    // this.refreshTableClass();
  }
  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm, false)
    }

    this.getBoardcastBigViewList();
  }
  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getBoardcastBigViewList();
  }

  private tableData: any[] = [];

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "70px";

  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getBoardcastBigViewList();
  }

  private handleRefreshFullScreen(isFullScreen: number, row: any) {
    this.boardcastBigViewListApi.updateDeviceOptionView({
      id: row.id,
      is_full_screen: isFullScreen,
      brand_type: row.brand_type
    }, () => {
      this.$message.success('修改成功')
    }, () => {})
  }

  private addOrUpdateBoardcastBigViewTitle: string = "运营提醒";

  private dialogFormVisible: boolean = false;

  // 拉起运营提醒弹框
  private handleWakeBoardcastBigViewDialog(mode: string) {
    // if (mode == 'new-boardcast-big-view') { // 新增运营提醒

    // }
    this.boardcaseBigViewDialogMode = mode;
    this.addOrUpdateBoardcastBigViewTitle =
      businessReminderDialogTitle2ch[mode] || "运营提醒";
    
    this.boardcastBigViewEditMode = 'single'

    this.initBoardcastBigViewForm(mode);

    this.dialogFormVisible = true;
  }

  private currentBoardcastBigViewItem: any = null;

  private boardcaseBigViewDialogMode: string = "";

  private boardcastBigViewEditMode: string = 'single' // all single

  private async initBoardcastBigViewForm(mode: string) {
    const forms: any = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    if (mode == "edit-boardcast-big-view") {
      // 编辑
      await this.getMoreVenueList(true);

      // await this.refreshBoardcastBigViewTimeDurations();

      // await this.refreshTableClass();

      const currentBoardcastBigViewItem = this.currentBoardcastBigViewItem;

      const {
        id,

        shop_id,
        shop_name,

        is_view_image,
        is_round_table,
        media_type,
        live_streaming_address,
        // sound_size,
        // is_clear,
        image_list,
        video_list,
      } = currentBoardcastBigViewItem || {};

      // fileId
      Object.assign(forms, {
        is_view_image,
        is_round_table,
        media_type,
        // sound_size,
        // is_clear,
        live_streaming_address: live_streaming_address || '',
        images: [
          ...image_list.map((item: any) => {
            const newItem = {
              ...item,
              fileId: item.id,
            };

            return newItem;
          }),
        ],
        videos: [
          ...video_list.map((item: any) => {
            const newItem = {
              ...item,
              fileId: item.id,
            };

            newItem.poster = `${item.url}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`;

            return newItem;
          }),
        ],
      });

      const venues: any[] = [];

      const handleAddExtraVenue = (venues: any[], id: any, name: any) => {
        if (name && typeof id == "number") {
          let existVenue = this.venueList.find((venue) => venue.id == id);

          if (!existVenue) {
            existVenue = {
              id,
              name,
            };
            this.venueList.push(existVenue);
          }

          venues.push(existVenue);
        }
      };

      handleAddExtraVenue(venues, shop_id, shop_name);
      // handleAddExtraVenue(venues, assistant_shop_id, assistant_shop_name)

      // if (shop_name && typeof shop_id == "number") {
      //   console.log(shop_id, "shop_id");

      //   console.log(this.venueList, "this.venueList");
      //   let existVenue = this.venueList.find((venue) => venue.id == shop_id);

      //   if (!existVenue) {
      //     existVenue = {
      //       id: shop_id,
      //       name: shop_name,
      //     };
      //     this.venueList.push(existVenue);
      //   }

      //   forms.venue = existVenue;
      // }

      forms.venues = venues;

      forms.id = id;
    }

    console.log(forms, "forms");

    console.log(this.venueList, "this.venueList");

    this.forms = forms;
  }

  private searchShopBarndType: number = 1


  // 一键编辑
  private handleWakeEditAllBoardcastBigViewDialog(mode: string) {
    this.boardcaseBigViewDialogMode = 'edit-boardcast-big-view'

    this.searchShopBarndType = mode2BrandType[mode]

    this.addOrUpdateBoardcastBigViewTitle = '一键编辑'

    this.boardcastBigViewEditMode = 'all'

    this.initBoardcastBigViewForm('new-boardcast-big-view')

    this.dialogFormVisible = true;
  }

  private handleWakeEditBoardcastBigViewDialog(boardcastDeviceItem: any) {
    this.currentBoardcastBigViewItem = boardcastDeviceItem;

    this.searchShopBarndType = boardcastDeviceItem.brand_type

    this.handleWakeBoardcastBigViewDialog("edit-boardcast-big-view");
  }

  // 上传媒体文件
  private handleUploadUserPhoto(file: any, fileList: any[]) {
    this.handleUploadUserMedia(file, fileList, "image");
  }

  private handleUploadUserVideo(file: any, fileList: any[]) {
    this.handleUploadUserMedia(file, fileList, "video");
  }
  private async handleUploadUserMedia(
    file: any,
    fileList: any[],
    mode: string
  ) {
    console.log(file, "file");

    function getVideoDuration(url: string): any {
      return new Promise((resolve, reject) => {
        var audio = new Audio(url);
        audio.muted = true;
        audio.play().then(() => audio.pause());
        // 元数据已加载

        const handleLoadedmetadata = function (e: any) {
          // let duration = audio.duration;
          console.log(e, "e audio");
          resolve({
            url,
            duration: audio.duration,
          });

          hanleClearListeners();
        };

        const hanleClearListeners = function () {
          audio.removeEventListener("loadedmetadata", handleLoadedmetadata);
          audio.onerror = null;
        };
        audio.addEventListener("loadedmetadata", handleLoadedmetadata);

        audio.onerror = function (err) {
          reject(err);
        };

        audio.muted = false;
      });
    }

    const editForm: any = this.forms;

    const optionList = mode == "image" ? editForm.images : editForm.videos;

    const sendMediaApi =
      mode == "image" || editForm.media_type == 2 ? this.commonApi.getOss : this.commonApi.getOssvideo;

    if (mode == "video" && !file.duration) {
      console.log(editForm, "editForm");
      console.log(editForm.media_type, "editForm.media_type");
      if (editForm.media_type == 1) {
        const { duration } = await getVideoDuration(file.url).catch(
          (err: any) => {
            this.$message("获取时长失败");
          }
        );

        file.duration = duration;
      }
    }

    optionList.push(file);

    console.log(editForm, "editForm");

    this.$set(this.forms, `${mode}s`, optionList);

    this.changeFilePropByList(file, mode, { uploading: true });
    sendMediaApi.call(
      this.commonApi,
      file instanceof File ? file : file.raw,
      (res: any) => {
        console.log(res, "res 媒体文件上传成功");

        const { data } = res;

        const { data: uploadFileObj } = data || {};

        const { item: uploadFile } = uploadFileObj || {};

        const { id, url } = uploadFile;

        // this.$set(this.forms, 'avatar_id', id)
        // this.$set(this.forms, 'avatar', url)
        const changeProps: any = {
          uploading: false,
          fileId: id,
          uploadUrl: url,
        };

        if (file.duration) {
          changeProps.duration = file.duration.toString();
        }

        this.changeFilePropByList(file, mode, changeProps);
      },
      (err: any) => {
        console.log(err, 'err')
        this.$message.error("媒体文件上传失败");

        this.changeFilePropByList(file, mode, {
          uploading: false,
          failed: true,
        });
      }
    );
  }

  private changeFilePropByList(file: any, mode: string, changes: any = {}) {
    const editForm: any = this.forms;

    const setList: any[] = mode == "image" ? editForm.images : editForm.videos;
    const existFileIndex = setList.findIndex(
      (each_file: any) => each_file.uid == file.uid
    );

    if (existFileIndex > -1) {
      const originFile = setList[existFileIndex];

      if (Array.isArray(setList)) {
        setList.splice(existFileIndex, 1, Object.assign(originFile, changes));
      }

      this.$set(this.forms, `${mode}s`, setList);
    }
  }

  // 删除媒体文件
  private handleFileRemove(file: any, mode: string) {
    const forms = this.forms;

    const images = mode == "image" ? forms.images : [];

    const videos = mode == "video" ? forms.videos : [];

    console.log(file, "file");

    const optionList = images.length > 0 ? images : videos;

    const existRemoveFileIndex = optionList.findIndex(
      (each_file: any) => each_file.uid == file.uid
    );

    if (existRemoveFileIndex > -1) {
      optionList.splice(existRemoveFileIndex, 1);

      this.$set(this.forms, `${mode}s`, optionList);
    }
  }

  // 预览媒体文件
  private previewImages: any[] = [];
  private handlePictureCardPreview(file: any, mode: string) {
    const forms = this.forms;

    const images = mode == "image" ? forms.images : (forms.media_type === 2 ? forms.videos : []);

    const videos = mode == "video" && forms.media_type === 1 ? forms.videos : [];

    console.log(file, "file");

    console.log(forms, 'forms')

    console.log(images, "images");
    console.log(videos, "videos");

    const urls = [
      ...images.map((img: any) => {
        return {
          title: "",
          type: "image/jpeg",
          href: img.url,
          thumbnail: img,
        };
      }),
      ...videos.map((video: any) => {
        return {
          title: "",
          type: "video/mp4",
          href: video.uploadUrl || video.url,
          poster: `${
            video.uploadUrl || video.url
          }?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`,
        };
      }),
    ];

    console.log(urls, 'urls');

    this.previewImages = urls;
  }

  private handleDelteBoardcastBigViewDialog(boardcastDeviceItem: any) {
    this.$confirm("确定删除该配置数据?", "", {
      ...shareConfirmOptions,
    }).then(() => {
      this.boardcastBigViewListApi.deleteBoardcastBigView(
        {
          id: boardcastDeviceItem.id,
        },
        () => {
          this.getBoardcastBigViewList();
        },
        () => {}
      );
    });
  }

  private venueList: any[] = [
    // {
    //   id: 1,
    //   label: '测试'
    // },
    // {
    //   id: 2,
    //   label: '测试2'
    // }
  ];

  private searchVenueLoading: boolean = false;

  private remoteGetVenueQueryString: string = "";

  private remoteSearchVenueTimer: any = null;

  private searchVenuePageOption: any = {};

  private initRemoteGetVenuePageOption() {
    this.searchVenuePageOption = {
      ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    };
  }

  private getSearchVenueParams() {
    const searchVenuePageOption = this.searchVenuePageOption;

    const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

    const { page, limit } = searchVenuePageOption;

    const params: any = {
      page,
      limit,
    };

    if (this.searchShopBarndType) {
      params.brand_type = this.searchShopBarndType
    }

    if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
      params.name = remoteGetVenueQueryString.trim();
    }

    return params;
  }

  private getMoreVenueList(refresh: boolean = true) {
    return new Promise((resolve: Function, reject: Function) => {
      if (refresh) {
        this.initRemoteGetVenuePageOption();
      }

      const params = this.getSearchVenueParams();

      this.boardcastBigViewListApi.searchVenueList(
        params,
        (res: any) => {
          console.log(res, "res");

          const venueList: any[] = res.data || [];

          this.venueList = refresh
            ? venueList
            : this.venueList.concat(venueList);

          if (Array.isArray(res.data) && res.data.length == 0) {
            this.searchVenuePageOption.isRearchBottom = true;
          }
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          resolve();
        },
        () => {
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          reject();
        }
      );
    });
  }

  // 第一次搜索 无关键字
  private handleFirstGetVenueList() {
    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 清空搜索
  private handleClearGetVenueList() {
    this.remoteGetVenueQueryString = "";

    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 远程搜索
  private remoteGetVenueMethod(query: string) {
    query = query.trim ? query.trim() : "";

    if (query !== "") {
      this.remoteGetVenueQueryString = query;
      clearTimeout(this.remoteSearchVenueTimer);

      this.remoteSearchVenueTimer = setTimeout(() => {
        this.searchVenueLoading = true;

        this.getMoreVenueList();
      }, 300);
    } else {
      this.remoteGetVenueQueryString = "";
      this.venueList = [];
    }
  }

  private addVenueListLoading: boolean = false;

  private remoteAddVenueMethod() {
    if (this.addVenueListLoading) return;
    const searchVenuePageOption = this.searchVenuePageOption;

    const { isRearchBottom } = searchVenuePageOption;

    if (isRearchBottom) return;

    this.searchVenuePageOption.page++;

    this.addVenueListLoading = true;

    this.getMoreVenueList(false);
  }

  // 流水
  // private handleLimitTotalMoney(val: string) {
  //   Util.limitIntegetInput(
  //     val,
  //     (new_val: string) => {
  //       const limit_val = Math.min(Number(new_val), maxTotalMoney).toString();

  //       if (maxTotalMoney == Number(limit_val)) {
  //         this.$set(this.forms, "total_money", limit_val);
  //       } else {
  //         this.$set(this.forms, "total_money", new_val);
  //       }
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "total_money", new_val);
  //     }
  //   );
  // }

  // private handleLimitSingleMaxCount(val: string) {
  //   Util.limitIntegetInput(
  //     val,
  //     (new_val: string) => {
  //       this.$set(this.forms, "maxLimitCount", new_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "maxLimitCount", new_val);
  //     }
  //   );
  // }

  private sumbitBoardcastBigViewLoading: boolean = false;

  // 提交广告内容配置
  private handleSumbitBoardcastBigView() {
    const boardcaseBigViewDialogMode = this.boardcaseBigViewDialogMode;

    const params = this.getBoardcastBigViewFormsParams(
      boardcaseBigViewDialogMode
    );

    console.log(params, "params");
    if (params === false) return;

    const boardcastBigViewEditMode = this.boardcastBigViewEditMode

    this.sumbitBoardcastBigViewLoading = true;
    switch (boardcaseBigViewDialogMode) {
      case "new-boardcast-big-view":
        // this.boardcastBigViewListApi.addBoardcastBigView(
        //   params,
        //   () => {
        //     this.getBoardcastBigViewList();
        //     this.dialogFormVisible = false;
        //     this.sumbitBoardcastBigViewLoading = false;
        //   },
        //   () => {
        //     this.sumbitBoardcastBigViewLoading = false;
        //   }
        // );
        break;
      case "edit-boardcast-big-view":
        switch (boardcastBigViewEditMode) {
          case 'single':
            this.boardcastBigViewListApi.updateBoardcastBigView(
              params,
              () => {
                this.getBoardcastBigViewList();
                this.dialogFormVisible = false;
                this.sumbitBoardcastBigViewLoading = false;
              },
              () => {
                this.sumbitBoardcastBigViewLoading = false;
              }
            );
            break;
          case 'all':
            this.boardcastBigViewListApi.updateBatchBoardcastBigView(
              params,
              () => {
                this.getBoardcastBigViewList();
                this.dialogFormVisible = false;
                this.sumbitBoardcastBigViewLoading = false;
              },
              () => {
                this.sumbitBoardcastBigViewLoading = false;
              }
            );
            break;
        }
        
        break;
    }
  }

  private getBoardcastBigViewFormsParams(mode: string) {
    const params: any = {
      
    };

    const searchBrandType = this.searchShopBarndType

    if (searchBrandType) {
      params.brand_type = searchBrandType
    }

    const showErrorMessage = (msg: string) => {
      if (!!msg) {
        this.$message.error(msg);
      }
    };

    const forms = this.forms;

    const boardcastBigViewEditMode = this.boardcastBigViewEditMode


    const { id, venues, images, media_type, live_streaming_address, videos, is_view_image, is_round_table } =
      forms || {};

    // console.log(venues, 'venues')

    if (!(mode == "edit-boardcast-big-view" && boardcastBigViewEditMode == 'all')) {
      if (venues && venues.length > 0) {
        params.shop_id = venues[0].id;

        if (venues[1]) {
          params.assistant_shop_id = venues[1].id;
        }
      } else {
        showErrorMessage("请至少选择一个店铺");
        return false;
      }
    }
    

    if (mode == "edit-boardcast-big-view") {
      Object.assign(params, {
        is_view_image,
        is_round_table,
        media_type,
      });

      if (Array.isArray(images) && images.length) {
        if (images.some((image) => image.uploading || image.failed)) {
          this.$message("有正在上传或者上传失败的文件，请稍等或重新上传");
          return false;
        }
        params.image_id = images
          .map((item) => {
            return item.fileId;
          })
          .join(",");
      }

      if (this.isShowUploadMedia) {
        if (Array.isArray(videos) && videos.length) {
          if (videos.some((video) => video.uploading || video.failed)) {
            this.$message("有正在上传或者上传失败的文件，请稍等或重新上传");
            return false;
          }
          params.video_id = videos
            .map((item) => {
              return item.fileId;
            })
            .join(",");
        }
      } else {
        if (!live_streaming_address) {
          showErrorMessage("请输入直播流地址");
          return false
        }

        params.live_streaming_address = live_streaming_address
      }

      if (boardcastBigViewEditMode == 'single') {
        params.id = id;
      }
    }

    return params;
  }

  private dialogDeviceOptionFormVisible: boolean = false;

  private sumbitDeviceOptionLoading: boolean = false;

  // 编辑设备配置
  private handleWakeEditDeviceOptionDialog(boardcastDeviceItem: any) {
    this.currentBoardcastBigViewItem = boardcastDeviceItem;

    const deviceForms = {
      ...this.deepLoopUtil.deepCopy(defaultDeviceOptionForms, false),
    };

    const {
      id,

      sound_size,
      is_clear,
    } = boardcastDeviceItem || {};

    Object.assign(deviceForms, {
      sound_size,
      is_clear,
      // sound_size,
      // is_clear,
    });

    deviceForms.id = id;

    console.log(deviceForms, 'deviceForms')

    this.deviceForms = deviceForms;

    this.dialogDeviceOptionFormVisible = true;
  }

  private handleSumbitDeviceOptionView() {
    const searchBrandType = this.searchShopBarndType


    const deviceForms = this.deviceForms;
    const params = {
      ...deviceForms,
    };

    if (searchBrandType) {
      params.brand_type = searchBrandType
    }

    delete params.sound_size;

    this.sumbitDeviceOptionLoading = true;

    this.boardcastBigViewListApi.updateDeviceOptionView(
      params,
      () => {
        this.sumbitDeviceOptionLoading = false;
        this.dialogDeviceOptionFormVisible = false;

        this.$message.success("修改配置成功");

        this.getBoardcastBigViewList()
      },
      () => {
        this.sumbitDeviceOptionLoading = false;
      }
    );
  }

  // 重启应用
  private _handleResetDevice(deviceOptionItem: any) {
    this.$confirm("确定重启该设备?", "", {
      ...shareConfirmOptions,
    }).then(() => {
      this.boardcastBigViewListApi.rebootBoardcastDeviceApp(
        {
          id: deviceOptionItem.id,
        },
        (res: any) => {
          const { result, message } = res.data || {};

          if (result) {
            this.$message.success(message || "重启请求已发送");
          }
        },
        () => {}
      );
    });
  }

  //获取广告设备列表
  private getBoardcastBigViewList(): void {
    this.boardcastBigViewListApi.getBoardcastBigViewList(
      { ...this.getBoardcastBigViewParams() },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page;
        this.tablePage.prepage = res.data.prepage;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private getBoardcastBigViewParams(mode = "all"): any {
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    const { brand_type } = form;

    if (brand_type) {
      params.brand_type = brand_type;
    }

    // if (Array.isArray(time) && time.length === 2) {
    //   const [startDate, endDate] = time;

    //   const startTime = Util.dateTimeUtilnohms(startDate);
    //   const endTime = Util.dateTimeUtilnohms(endDate);

    //   params.start_date = startTime;

    //   params.end_date = endTime;
    // }

    if (mode == "exportExcel" && !(params.start_date && params.end_date)) {
      this.$message.error("请选择日期导出数据");
      return null;
    }

    if (mode === "all") {
      params.page = page;

      params.limit = prepage;

      // outerParams.__isUnique = !firstLoading;
    }

    return params;
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  /** 导出excel */
  // private downloadExcelLoading: boolean = false;

  // // 字段名 文案对应关系
  // private merchantExcelList2ch: any = {
  //   name: "店铺名称",
  //   shop_id: " 店铺ID",
  //   cabinet_id: "绑定设备ID",
  //   admin_name: "操作人",
  //   updated_at: "操作时间"
  // };
  // // 商户Excel 表头的先后顺序
  // private merchantExcelSortList: string[] = [
  //   "name",
  //   "shop_id",
  //   "cabinet_id",
  //   "admin_name",
  //   "updated_at"
  // ];

  // // 点击导出excel 表格
  // private handleExportExcelBoardcastBigViewList() {
  //   const params = this.getBoardcastBigViewParams('exportExcel')

  //   if (params === null) {
  //     return false;
  //   }

  //   this.downloadExcelLoading = true;
  //   this.boardcastBigViewListApi.exportExcelStoragelockerList(
  //     params,
  //     (res: any) => {
  //       const { start_date, end_date } = params || {};

  //       const filename = `运营提醒管理列表 ${start_date}到${end_date}.xlsx`;
  //       const { data } = res || {};

  //       const {
  //         list: originList
  //       } = data || {}

  //       const newList = Array.isArray(originList)
  //         ? originList.map((item) => {
  //             const {
  //               // status,
  //               // back_goods_detail
  //              } = item || {};
  //             const newItem = {
  //               ...(item || {}),
  //               // __status_text: withdrawAccountAudit2Text[status],
  //               // __back_good_details: back_goods_detail.map((goods: any, index: number) => `${ goods.name }x${ goods.num }  ¥${ goods.price }`).join('; \t')
  //             };

  //             for (let key in item) {
  //               if (typeof item[key] == 'number') {
  //                 newItem[key] = item[key].toString()
  //               }
  //             }

  //             return newItem;
  //           })
  //         : [];

  //       if (newList.length > 0) {
  //         const merchantExcelSortList = this.merchantExcelSortList;

  //         const merchantExcelList2ch = this.merchantExcelList2ch;

  //         const excelJSONData = getExcelJSONDataByList(
  //           newList,
  //           merchantExcelSortList,
  //           merchantExcelList2ch
  //         );

  //         excuteDownloadExport2Excel(excelJSONData, filename);
  //       } else {
  //         this.$message.info("选中时段没有可导出的信息");
  //       }

  //       this.downloadExcelLoading = false;
  //     },
  //     () => {
  //       this.downloadExcelLoading = false;
  //     }
  //   );
  // }

  /** end 导出excel */

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };
}

import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

import { CommonApi } from "../common";

export class SettingCompetitionAssistantAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getCompetitionList', {
      url: '/admin/league/list',
      name: 'list',
      label: '列表'
    }],
   
    ['updateCompetitionOtherInfo', {
      url: '/admin/league/editPart',
      name: 'update-complex-competition',
      label: '更新比赛其他信息'
    }],

    ['getCompetitionPkSetList', {
      url: '/admin/league/pkSet',
      name: 'competition-pk-set-list',
      label: '比赛对局设置'
    }],

  ])
  // api 方法
  // 复杂比赛列表
  public getCompetitionList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getCompetitionList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 更新比赛其他信息
  public updateCompetitionOtherInfo(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('updateCompetitionOtherInfo').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 比赛对局设置
  public getCompetitionPkSetList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getCompetitionPkSetList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

}

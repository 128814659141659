import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class PandaServiceAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getPandaSerInfo', {
      url: '/admin/admin/info',
      name: 'panda-ser-info',
      label: '客服账户信息'
    }],

    ['offlinePandaSer', {
      url: '/admin/admin/offline',
      name: 'service-offline',
      label: '客服离线'
    }],

    ['onlinePandaSer', {
      url: '/admin/admin/online',
      name: 'service-online',
      label: '客服在线'
    }],

    ['getTalkingList', {
      url: '/admin/im_chat/talkingList',
      name: 'servcing-list',
      label: '会话中列表'
    }],



    
    ['getFinishTalkList', {
      url: '/admin/im_chat/overList',
      name: 'over-service-list',
      label: '结束会话列表'
    }],
    ['finishTalkService', {
      url: '/admin/im_chat/over',
      name: 'over-service',
      label: '结束会话'
    }],
   
    ['transferTalkService', {
      url: '/admin/im_chat/transfer',
      name: 'transfer-service',
      label: '转接客服'
    }],
    ['getAvailableTransferService', {
      url: '/admin/im_chat/waiter',
      name: 'available-transfer-list',
      label: '可转接客服列表',
      // hidden: true
    }],
    ['getQuickReplyList', {
      url: '/admin/im_chat/quick',
      name: 'quick-reply-list',
      label: '快捷回复列表',
    }],
    ['getHistoryChat', {
      url: '/admin/im_chat/chat',
      name: 'history-chat-list',
      label: '聊天记录',
    }],
    ['getServiceTimelineRecord', {
      url: '/admin/im_chat/via',
      name: 'ser-timeline-list',
      label: '服务记录',
    }]
    // ['exportExcelStoragelockerList', {
    //   url: '/admin/shopBox/export',
    //   name: 'export-excel',
    //   label: '导出',
    // }],
  ])

  // 客服账户信息
  public getPandaSerInfo(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getPandaSerInfo').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 客服离线
  public offlinePandaSer(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('offlinePandaSer').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 客服在线
  public onlinePandaSer(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('onlinePandaSer').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 会话中列表
  public getTalkingList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getTalkingList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 结束会话列表
  public getFinishTalkList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getFinishTalkList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 结束会话
  public finishTalkService(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('finishTalkService').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }


  // 转接客服
  public transferTalkService(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('transferTalkService').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 可转接客服列表
  public getAvailableTransferService(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getAvailableTransferService').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 快捷回复列表
  public getQuickReplyList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getQuickReplyList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 快捷回复列表
  public getHistoryChat(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getHistoryChat').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 服务记录
  public getServiceTimelineRecord(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getServiceTimelineRecord').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}

import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

import { CommonApi } from "../common";

export class CompetitionSnakeBroadcastScreenOptionAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getCompetitionSnakeBallOption', {
      url: '/admin/telecast/show',
      name: 'mine-snake-info',
      label: '赛事斯诺克详情'
    }],

    // ['searchVenueList', {
    //   url: '/admin/coupons/shop/list',
    //   name: 'shop-list',
    //   label: '店铺列表',
    //   // hidden: true
    // }],

    ['setCompetitionSnakeBallOption', {
      url: '/admin/telecast/setInfo',
      name: 'competition-snake-ball-option',
      label: '斯诺克赛事配置',
      // hidden: true
    }],
    
    ['setCompetitionSnakeBallUserInfoOption', {
      url: '/admin/telecast/setUser',
      name: 'competition-snake-ball-user-info-option',
      label: '斯诺克赛事用户信息配置配置',
      // hidden: true
    }],
  ])
  // 赛事斯诺克详情
  public getCompetitionSnakeBallOption(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getCompetitionSnakeBallOption').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 搜索店铺列表
  // public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
  //   const apiUrls = this.apiUrls

  //   const url = apiUrls.get('searchVenueList').url

  //   this.BasicPost(url, {
  //       ...params
  //   }, false, false, false, successCb, failCb, showLoading)
  // }

  // 斯诺克赛事配置
  public setCompetitionSnakeBallOption(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('setCompetitionSnakeBallOption').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 斯诺克赛事用户信息配置配置
  public setCompetitionSnakeBallUserInfoOption(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('setCompetitionSnakeBallUserInfoOption').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}
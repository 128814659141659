<template>
  <div class="box">
    <div id="container"></div>
    <div style="z-index: 99" id="panel"></div>
  </div>
</template>

<script>
// 地图导航组件
import { getAMap } from "@/plugins/amap.js";

// import AMapLoader from "@amap/amap-jsapi-loader";

//下载自官网示例数据  http://a.amap.com/Loca/static/mock/districts.js
// const districts = [
//   { name: "北京市", center: "116.407394,39.904211" },
//   { name: "天津市", center: "117.200983,39.084158" },
//   { name: "河北省", center: "114.530235,38.037433" },
//   { name: "山西省", center: "112.562678,37.873499" },
//   { name: "内蒙古自治区", center: "111.76629,40.81739" },
//   { name: "辽宁省", center: "123.431382,41.836175" },
//   { name: "吉林省", center: "125.32568,43.897016" },
//   { name: "黑龙江省", center: "126.661665,45.742366" },
//   { name: "上海市", center: "121.473662,31.230372" },
//   { name: "江苏省", center: "118.762765,32.060875" },
//   { name: "浙江省", center: "120.152585,30.266597" },
//   { name: "安徽省", center: "117.329949,31.733806" },
//   { name: "福建省", center: "119.295143,26.100779" },
//   { name: "江西省", center: "115.81635,28.63666" },
//   { name: "山东省", center: "117.019915,36.671156" },
//   { name: "河南省", center: "113.753394,34.765869" },
//   { name: "湖北省", center: "114.341745,30.546557" },
//   { name: "湖南省", center: "112.9836,28.112743" },
//   { name: "广东省", center: "113.26641,23.132324" },
//   { name: "广西壮族自治区", center: "108.327546,22.815478" },
//   { name: "海南省", center: "110.349228,20.017377" },
//   { name: "重庆市", center: "106.551643,29.562849" },
//   { name: "四川省", center: "104.075809,30.651239" },
//   { name: "贵州省", center: "106.70546,26.600055" },
//   { name: "云南省", center: "102.710002,25.045806" },
//   { name: "西藏自治区", center: "91.117525,29.647535" },
//   { name: "陕西省", center: "108.954347,34.265502" },
//   { name: "甘肃省", center: "103.826447,36.05956" },
//   { name: "青海省", center: "101.780268,36.620939" },
//   { name: "宁夏回族自治区", center: "106.259126,38.472641" },
//   { name: "新疆维吾尔自治区", center: "87.627704,43.793026" },
//   { name: "台湾省", center: "121.509062,25.044332" },
//   { name: "香港特别行政区", center: "114.171203,22.277468" },
//   { name: "澳门特别行政区", center: "113.543028,22.186835" },
// ];

export default {
  props: {
    address: {
      default: "武汉",
    },
    isWatch: {
      default: true,
    },
  },
  data() {
    return {
      option: {
        zoom: 12,
        center: [114.288657, 30.568449],
        panel: "panel",
      },
      marker: "",
      AMap: "",
      map: "",
      callbackList: [],
      placeSearch: "",
    };
  },
  mounted() {
    // console.log(this.address);
    this.getGis();
  },
  methods: {
    getGis() {
      getAMap()
        .then((AMap) => {
          this.AMap = AMap;
          this.creatMap();
          for (let i of this.callbackList) {
            i();
          }
          this.callbackList.length = 0;
        })
        .catch(() => {});
    },
    //生成地图
    creatMap() {
      let that = this;
      this.map = new AMap.Map("container", {
        zoom: that.option.zoom,
        center: that.option.center,
        // features: ["bg", "road"],
        resizeEnable: true,
        // Loca 自 1.2.0 起 viewMode 模式默认为 3D，如需 2D 模式，请显示配置。
        // viewMode: '3D'
      });
      //为地图注册click事件获取鼠标点击出的经纬度坐标
      this.map.on("click", function (e) {
        console.log(e);
        if (that.marker) {
          that.marker.setMap(null);
          that.marker = null;
        }

        that.marker = new AMap.Marker({
          position: new AMap.LngLat(e.lnglat.getLng(), e.lnglat.getLat()), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        });
        that.map.add(that.marker);
        that.map.setFitView(that.marker);

        AMap.plugin("AMap.Geocoder", function () {
          var geocoder = new AMap.Geocoder({
            // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
            city: "全国",
          });

          // 使用geocoder做地理/逆地理编码
          const lat = e.lnglat.getLng();
          const log = e.lnglat.getLat();
          geocoder.getAddress(
            [e.lnglat.getLng(), e.lnglat.getLat()],
            function (status, result) {
              if (status === "complete" && result.info === "OK") {
                console.log(result);
                that.$emit("getAddress", result.regeocode, lat, log);
              } else {
                console.log(status, result);
              }
            }
          );
        });
      });
    },
    //通过输入地址展示在地图上
    getAddressInMap() {
      console.log(this.address);
      let AMapNew = this.AMap;
      let that = this;

      AMapNew.plugin("AMap.PlaceSearch", function () {
        //构造地点查询类
        that.placeSearch = new AMapNew.PlaceSearch({
          pageSize: 6, // 单页显示结果条数
          // pageIndex: 1, // 页码
          city: "全国", // 兴趣点城市
          citylimit: false, //是否强制限制在设置的城市内搜索
          map: that.map, // 展现结果的地图实例
          panel: "", // 结果列表将在此容器中进行展示。
          autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
        });
        //关键字查询
        that.placeSearch.search(that.address, function (status, result) {
          // 查询成功时，result即对应匹配的POI信息
          console.log(result);
        });

        that.placeSearch.on("listElementClick", listElementClick);
        function listElementClick(e) {
          console.log(e);
          that.$emit("setCity", e.data);
          // that.getCity(e);
        }
      });

      // AMapNew.plugin("AMap.Geocoder", function () {
      //   var geocoder = new AMapNew.Geocoder({
      //     // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
      //     city: "全国",
      //   });

      //   geocoder.getLocation(that.address, function (status, result) {
      //     if (status === "complete" && result.info === "OK") {
      //       console.log(result);
      //       that.$emit("setCity", result.geocodes);
      //       if (that.marker) {
      //         that.marker.setMap(null);
      //         that.marker = null;
      //       }

      //       that.marker = new AMapNew.Marker({
      //         position: new AMapNew.LngLat(
      //           result.geocodes[0].location.lng,
      //           result.geocodes[0].location.lat
      //         ), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
      //       });
      //       // that.creatMap();
      //       that.map.add(that.marker);
      //       that.map.setFitView(that.marker);
      //     }
      //   });
      // });
    },
    getCity(e) {
      let AMapNew = this.AMap;
      let that = this;
      AMapNew.plugin("AMap.Geocoder", function () {
        var geocoder = new AMapNew.Geocoder({
          // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
          city: "全国",
        });

        geocoder.getLocation(e.data.adcode, function (status, result) {
          console.log(status, result);

          if (status === "complete" && result.info === "OK") {
            that.$emit("setCity", result.geocodes);
            if (that.marker) {
              that.marker.setMap(null);
              that.marker = null;
            }

            that.marker = new AMapNew.Marker({
              position: new AMapNew.LngLat(
                result.geocodes[0].location.lng,
                result.geocodes[0].location.lat
              ), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            });
            // that.creatMap();
            that.map.add(that.marker);
            that.map.setFitView(that.marker);
          }
        });
      });
    },
  },
  watch: {
    address: {
      handler: function () {
        if (this.isWatch == true && this.address != "") {
          this.getAddressInMap();
        }
      },
      immediate:false
    },
  },
};
</script>

<style scoped>
.box {
  height: 100%;
  display: flex;
}
#container {
  width: 100%;
  height: 100%;
}
#panel {
  /* display: none; */
}
</style>

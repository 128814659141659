import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class BoardcastBigViewListAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getBoardcastBigViewList', {
      url: '/admin/screen/list',
      name: 'list',
      label: '列表'
    }],
    ['updateDeviceOptionView', {
      url: '/admin/screen/setEdit',
      name: 'edit-device-option',
      label: '编辑设备设置'
    }],
    ['rebootBoardcastDeviceApp', {
      url: '/admin/screen/reboot',
      name: 'reboot-device-app',
      label: '重启设备应用'
    }],
    ['updateBatchBoardcastBigView', {
      url: '/admin/screen/allEdit',
      name: 'batch-edit',
      label: '一键编辑'
    }],
    ['updateBoardcastBigView', {
      url: '/admin/screen/edit',
      name: 'edit',
      label: '编辑'
    }],
    ['deleteBoardcastBigView', {
      url: '/admin/screen/delete',
      name: 'delete',
      label: '删除'
    }],
    ['searchVenueList', {
      url: '/admin/coupons/shop/list',
      name: 'shop-list',
      label: '店铺列表',
      // hidden: true
    }],
   
  ])

  // 运营提醒列表
  public getBoardcastBigViewList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getBoardcastBigViewList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 修改运营提醒
  public updateBoardcastBigView(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('updateBoardcastBigView').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 一键批量编辑设备设置
  public updateBatchBoardcastBigView(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('updateBatchBoardcastBigView').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 编辑设备设置
  public updateDeviceOptionView(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('updateDeviceOptionView').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 重启设备应用
  public rebootBoardcastDeviceApp(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('rebootBoardcastDeviceApp').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 删除
  public deleteBoardcastBigView(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('deleteBoardcastBigView').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 搜索店铺列表
  public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('searchVenueList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  
}
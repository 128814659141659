
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import PandaMobileTable from "@/components/mobile-custom/panda-table.vue";

import PandaMobileTWFContainer from "@/components/mobile-custom/panda-table-with-filter-container.vue";

import { AxiosData } from "@/common/http/http";
import { Util,getPageAPIs } from "@/common/util";
import { DeviceAPI } from "@/common/api/merchantManage/device";
export const pageApiList = getPageAPIs(DeviceAPI);

@Component({
  components: {
    PandaMobileTable,
    PandaMobileTWFContainer,
  },
})
export default class MerchantsAudit extends Vue {
  /** 移动端相关属性 方法 */
  private allMobileChooseFields: any[] = [
    {
      label: '名称',
      field: 'name'
    },
    {
      label: '操作时间',
      field: 'updated_at'
    },
    {
      label: '操作人',
      field: 'admin_name'
    }
  ];
  /** end 移动端相关属性 方法 */

  private merchantApi: any;

  constructor() {
    super();
    this.merchantApi = new DeviceAPI();
  }
  private dialogFormVisible: boolean = false;

  private form: any = {
    shop_id: "",
  };
  private title: string = "";
  private forms: any = {
    subject_id: "",
    resource_id: "",
    shop_id: "",
  };
  private dList: any = [];
  private device(): void {
    this.merchantApi.device(
      {
        aqara_id: this.$route.query.aqara_id,
      },
      (res: any) => {
        if (res.data.list) {
          this.dList = res.data.list;
        } else {
          this.dList = [];
        }
      }
    );
  }
  private rList: any = [];
  private resource(): void {
    this.merchantApi.resource(
      {
        aqara_id: this.$route.query.aqara_id,
        did: [this.forms.subject_id],
      },
      (res: any) => {
        this.rList = res.data;
      }
    );
  }
  private onclose(): void {
    this.forms = new Util().clearObject(this.forms);
    this.dialogFormVisible = false;
  }
  private submit(): void {
    if (this.title == "新增") {
      this.forms.shop_id = this.$route.query.id;
      this.forms.aqara_token_id = this.$route.query.aqara_id;
      this.merchantApi.deviceC(this.forms, (res: any) => {
        Util.showToast("新增成功");
        this.dialogFormVisible = false;
        this.forms = new Util().clearObject(this.forms);

        this.search();
      });
    } else {
      this.merchantApi.deviceE(this.forms, (res: any) => {
        Util.showToast("修改成功");
        this.dialogFormVisible = false;
        this.forms = new Util().clearObject(this.forms);
        this.search();
      });
    }
  }
  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private activated(): void {
    if (this.$route.query.id) {
      this.form.shop_id = this.$route.query.id;
    }
    this.search();
    this.device();
  }

  private getTable(): void {
    this.merchantApi.getdevice(
      {
        ...this.form,
        page: this.tablePage.pageNo,
        limit: this.tablePage.pageSize,
      },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.totalCount = res.data.count;
      }
    );
  }

  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private view(row: any): void {
    this.merchantApi.deviceS({ id: row.id }, (res: any) => {
      this.forms = res.data;
      this.resource();
      this.dialogFormVisible = true;
    });
  }

  private deviceDel(id: string): void {
    this.merchantApi.deviceD(
      {
        id: id,
      },
      () => {
        Util.showToast("删除成功");
        this.search();
      }
    );
  }
  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
  private deviceAdd(type: any): void {
    if (type == "add") {
      this.title = "新增";
      this.dialogFormVisible = true;
    } else {
      this.title = "编辑";
      this.view(type);
    }
  }
}

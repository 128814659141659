import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

import { CommonApi } from "../common";

export default class UserCouponApi extends BasicsClass {

  public apiUrls: any = new Map([
    ['getUserCouponTableList', {
        url: '/admin/userCoupons/index',
        name: 'list',
        label: '列表'
    }],
    ['expireAvailableCoupon',  {
        url: '/admin/userCoupons/overdue',
        name: 'overdue-coupon',
        label: '使优惠券过期'
    }],
    // debugger changeExport;
    ['exportExcelUserCouponList', {
        url: '/admin/userCoupons/exportList',
        name: 'export-excel',
        label: '导出'
    }]
  ])

  public getUserCouponTableList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getUserCouponTableList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 用户优惠券 导出
  public exportExcelUserCouponList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    console.log(params, "params");

    params.is_export = 1

    const apiUrls = this.apiUrls

    const url = apiUrls.get('exportExcelUserCouponList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 用户优惠券---过期处理
  public expireAvailableCoupon(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('expireAvailableCoupon').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

}

import { render, staticRenderFns } from "./userGradeAduitManage.vue?vue&type=template&id=61acf78c&scoped=true"
import script from "./userGradeAduitManage.vue?vue&type=script&lang=ts"
export * from "./userGradeAduitManage.vue?vue&type=script&lang=ts"
import style0 from "./userGradeAduitManage.vue?vue&type=style&index=0&id=61acf78c&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61acf78c",
  null
  
)

export default component.exports
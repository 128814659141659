
import dayjs from "@/plugins/dayjs";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import PandaMobileTable from "@/components/mobile-custom/panda-table.vue";

import PandaMobileTWFContainer from "@/components/mobile-custom/panda-table-with-filter-container.vue";

import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import { FinanceLeaseTakeRateAPI } from "@/common/api/financeManage/financeLeaseTakeRateList";

import { excuteDownloadExport2Excel } from "@/common/xlsx";


// import { util } from "vue/types/umd";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
// import ZlSelect from "@/components/third-custom-components/select";

// import ZlOption from "@/components/third-custom-components/option";

import {
  shareConfirmOptions,
  shareShopType2ch
} from '@/common/config'

// 暴露的API 列表
export const pageApiList = getPageAPIs(FinanceLeaseTakeRateAPI);

console.log(pageApiList, 'pageApiList')

// function getCurrentWeekDate() {
//   const currentDay = (dayjs() as any).$d;

//   const curWeekStartDay = (dayjs().startOf("week") as any).$d;

//   return [curWeekStartDay, currentDay];
// }

// function getLastWeekDate() {
//   const lastWeekEndDay = (dayjs().subtract(1, 'week').endOf("week") as any).$d;

//   const lastWeekStartDay = (dayjs().subtract(1, 'week').startOf("week") as any).$d;

//   return [lastWeekStartDay, lastWeekEndDay];
// }

// function getCurrentMonthDate() {
//   const currentDay = (dayjs() as any).$d;

//   const curMonthStartDay = (dayjs().startOf("month") as any).$d;

//   return [curMonthStartDay, currentDay];
// }

// function getLastMonthDate() {
//   const lastEndDay = (dayjs().subtract(1, "month").endOf("month") as any).$d;

//   const lastStartDay = (dayjs().subtract(1, "month").startOf("month") as any)
//     .$d;

//   return [lastStartDay, lastEndDay];
// }

// const defaultChooseRangeModes = [
  
//   {
//     label: '上周',
//     name: 'last-week',
//     rangeFunc: getLastWeekDate
//   },
//   {
//     label: '本周',
//     name: 'cur-week',
//     rangeFunc: getCurrentWeekDate
//   },
//   // {
//   //   label: '本月',
//   //   name: 'cur-month',
//   //   rangeFunc: getCurrentMonthDate
//   // },
//   {
//     label: '上月',
//     name: 'last-month',
//     rangeFunc: getLastMonthDate
//   }
// ]

const defaultChooseRangeModeName: string = 'cur-week'

const defaultForm = {
  is_type: 0, 
  time: [
    
  ], // 日期
}

// const shopType2ch: string[] = ['', '台球', '棋牌']

// const defaultSearchVenuePageOption = {
//   page: 1,
//   limit: 10,
//   isRearchBottom: false,
// };


// // 最大有效期
// const maxExpiredDays = 9999;

// // 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;

// coupon 卡券分属类别
// const couponShopType2ch: any = [
//   ...shareShopType2ch
// ]


// type ShopIncomeDayItem = {
//   date: string,
//   total_money: string
// }

// const payMode2ch: any[] = ['未知', '微信', '支付宝', '现金', '会员支付', '组合支付']

@Component({
  filters: {
    // showCouponShopTypeName(shop_type: number) {
    //   return couponShopType2ch[shop_type] || ''
    // }

    // showPayModeText(mode: number) {
    //   return payMode2ch[mode]
    // }

    // showShopTypeName(shop_type: number) {
    //   return shopType2ch[shop_type]
    // }
  },
  components: {
    // ZlSelect,
    // ZlOption,
    PandaMobileTable,
    PandaMobileTWFContainer,
  },
})
export default class ShopIncomeChart extends Vue {
  /** 移动端相关属性 方法 */
  private allMobileChooseFields: any[] = [
    {
      label: '日期',
      field: 'date'
    },
    {
      label: '今日营业额',
      field: 'today_turnover'
    },
    {
      label: '抽成比例（%）',
      field: 'rate'
    },
    {
      label: '抽成金额',
      field: 'amount'
    },
    {
      label: '剩余抽成金额',
      field: 'surplus_amount'
    },
  
  ];
  /** end 移动端相关属性 方法 */


  private form: any = {
    // couponId: "",
    // time: [],
    // startDate: "",
    // endDate: "",
  };
  // private forms: any = {
  //   // maxType: "0",
  //   // ...defaultForms
  // };

  // private incomeTypeList: any[] = [
  //   {
  //     label: '全部',
  //     value: 0
  //   },
  //   {
  //     label: '台球',
  //     value: 1
  //   },
  //   {
  //     label: '棋牌',
  //     value: 2
  //   },
  //   {
  //     label: '售卖柜',
  //     value: 3
  //   }
  // ]

  // private shopId() {
  //   const route = this.$route
  //   const {
  //     query
  //   } = route || {}

  //   const {
  //     shop_id
  //   } = query || {}

  //   return shop_id || ''
  // }

  private deepLoopUtil: any = {};

  // private timeRangeModes: any[] = [
  //   ...(defaultChooseRangeModes || [])
  // ]

  private curRangeMode: string = defaultChooseRangeModeName


  private financeLeaseTakeRateApi = new FinanceLeaseTakeRateAPI();
  // private couponTimeDurationAPI = new CouponTimeDurationManageAPI();
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
      time: [
        // ...getCurrentWeekDate()
      ]
    };

    // this.forms = {
    //   ...this.deepLoopUtil.deepCopy(defaultForms, false),
    // };

    // this.searchVenuePageOption = {
    //   ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    // };

    // this.merchantApi = new MerchantApi();
  }

  private created(): void {
    // console.log(this.$route)
  }

  private activated() {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.refrshTableDataList();
  }

  private mount():void {
    this.refrshTableDataList();
  }
  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
    // this.form = new Util().clearObject(this.form);
    // this.$router.replace("/orderList");
    this.refrshTableDataList();
  }

  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.refrshTableDataList();
  }

  private get curShopId() {
    const route = this.$route

    const {
      query
    } = route || {}

    return query ? query.shop_id || '' : ''
  }

  private tableData: any[] = [];

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";
  // private options: any = [];

  // private clearObject(): void {
  //   this.dialogFormVisible = false;
  //   this.forms = new Util().clearObject(this.forms);
  // }
  // private tablePage: any = {
  //   count: 0,
  //   page: 1,
  //   prepage: 10,
  //   pageSizes: [10, 20, 50, 100, 200, 500],
  //   totalPage: 0,
  // };
  // //翻页
  // private handlePageChange(val: any) {
  //   this.tablePage.page = val.currentPage;
  //   this.tablePage.prepage = val.pageSize;
  //   this.refrshTableDataList();
  // }
  
  // private venueList: any[] = [
  //   // {
  //   //   id: 1,
  //   //   label: '测试'
  //   // },
  //   // {
  //   //   id: 2,
  //   //   label: '测试2'
  //   // }
  // ];

  // private searchVenueLoading: boolean = false;

  // private remoteGetVenueQueryString: string = "";

  // private remoteSearchVenueTimer: any = null;

  // private searchVenuePageOption: any = {};

  // private initRemoteGetVenuePageOption() {
  //   this.searchVenuePageOption = {
  //     ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
  //   };
  // }

  // private getSearchVenueParams() {
  //   const searchVenuePageOption = this.searchVenuePageOption;

  //   const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

  //   const { page, limit } = searchVenuePageOption;

  //   const params: any = {
  //     page,
  //     limit,
  //   };

  //   if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
  //     params.name = remoteGetVenueQueryString.trim();
  //   }

  //   return params;
  // }

  // private getMoreVenueList(refresh: boolean = true) {
  //   return new Promise((resolve: Function, reject: Function) => {
  //     if (refresh) {
  //       this.initRemoteGetVenuePageOption();
  //     }

  //     const params = this.getSearchVenueParams();

  //     this.financeLeaseTakeRateApi.searchVenueList(
  //       params,
  //       (res: any) => {
  //         console.log(res, "res");

  //         const venueList: any[] = res.data || [];

  //         this.venueList = refresh
  //           ? venueList
  //           : this.venueList.concat(venueList);

  //         if (Array.isArray(res.data) && res.data.length == 0) {
  //           this.searchVenuePageOption.isRearchBottom = true;
  //         }
  //         this.searchVenueLoading = false;

  //         this.addVenueListLoading = false;

  //         resolve();
  //       },
  //       () => {
  //         this.searchVenueLoading = false;

  //         this.addVenueListLoading = false;

  //         reject();
  //       }
  //     );
  //   });
  // }

  // // 第一次搜索 无关键字
  // private handleFirstGetVenueList() {
  //   this.searchVenueLoading = true;

  //   this.getMoreVenueList(true);
  // }

  // // 清空搜索
  // private handleClearGetVenueList() {
  //   this.remoteGetVenueQueryString = "";

  //   this.searchVenueLoading = true;

  //   this.getMoreVenueList(true);
  // }

  // // 远程搜索
  // private remoteGetVenueMethod(query: string) {
  //   query = query.trim ? query.trim() : "";

  //   if (query !== "") {
  //     this.remoteGetVenueQueryString = query;
  //     clearTimeout(this.remoteSearchVenueTimer);

  //     this.remoteSearchVenueTimer = setTimeout(() => {
  //       this.searchVenueLoading = true;

  //       this.getMoreVenueList();
  //       // searchStu((res) => {
  //       //   const {
  //       //     students,
  //       //     total_count
  //       //   } = res || {}

  //       //   this.remoteAllStudents = [
  //       //     ...(students || [])
  //       //   ]

  //       //   this.remoteStudentTotalCount = total_count || 0

  //       //   this.remoteGetStudentLoading = false
  //       // }, {
  //       //   kw: query,
  //       //   ...this.getRemoteGetStudentPageOptionParams()
  //       // }, () => {
  //       //   this.remoteGetStudentLoading = false
  //       // })
  //     }, 300);
  //     // setTimeout(() => {
  //     //   this.remoteGetStudentLoading = false;
  //     //   this.options = this.list
  //     // }, 200);
  //   } else {
  //     this.remoteGetVenueQueryString = "";
  //     this.venueList = [];
  //   }
  // }

  // private addVenueListLoading: boolean = false;

  // private remoteAddVenueMethod() {
  //   if (this.addVenueListLoading) return;
  //   const searchVenuePageOption = this.searchVenuePageOption;

  //   const { isRearchBottom } = searchVenuePageOption;

  //   if (isRearchBottom) return;

  //   this.searchVenuePageOption.page++;

  //   this.addVenueListLoading = true;

  //   this.getMoreVenueList(false);
  // }

  // private handleChangeTimeRange(range: any) {
  //   console.log(range, 'range')
  //   this.curRangeMode = range.name

  //   const chooseTimeRanges = range.rangeFunc()

  //   this.$set(this.form, 'time', chooseTimeRanges)
  // }

  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    console.log(val, 'val')
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.refrshTableDataList();
  }


  private refrshTableDataList() {
    const params = this.getFinanceLeaseTakeRateParams()

    if (typeof params != 'object') return

    this.financeLeaseTakeRateApi.getFinanceLeaseTakeRateTableList(
      {
        ...params
      },
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        const { list, count, page, prepage } = data || {};

        const newTableList = Array.isArray(list)
          ? list.map(this.handleTableData)
          : [];

        // totalCount: 0,
        // page: 1,
        // pageSize: 10,
        this.$set(this.tablePage, "totalCount", count);
        this.$set(this.tablePage, "page", page);
        this.$set(this.tablePage, "pageSize", prepage);

        this.tableData = newTableList;

      },
      () => {

      }
    )

  }

  private getFinanceLeaseTakeRateParams(mode = "all"): any {
    const params: any = {

    };

    const form = this.form;

    const tablePage = this.tablePage;

    const { time } = form;

    // if (typeof is_type == 'number') {
    //   params.is_type = is_type
    // }

    if (this.curShopId) {
      params.shop_id = this.curShopId
    }

    const { page, prepage: pageSize } = tablePage || {};

   if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      params.start_date = startTime;

      params.end_date = endTime;
    }


    if (
      mode == "exportExcel" &&
      !(params.start_date && params.end_date)
    ) {
      this.$message.error("请选择时段导出数据");
      return null;
    }

    if (mode === "all") {
      params.page = page;

      params.limit = pageSize;

    }

    console.log(params, "params");

  
    return params;
  }

   // 后台返回的字段 转化为前端定义的字段
  private handleTableData(data: any): any {
    const { 

    } = data;
    const newData = {
      ...data,
   
    };

    return newData;
  }

  /** 导出excel */
  // private downloadExcelLoading: boolean = false;

  // // 用户字段名 文案对应关系
  // private userExcelList2ch: any = {
  //   "shop_name": "店铺名称",
  //   "created_at": "时间",
  //   "order_no": "订单编号",
  //   "type_name": "收益类型",
  //   "price": "总金额",
  //   "platform_income": "平台抽成收益"
   
  // };
  // // 用户Excel 表头的先后顺序
  // private userExcelSortList: string[] = [
  //   "shop_name",
  //   "created_at",
  //   "order_no",
  //   "type_name",
  //   "price",
  //   "platform_income"
  // ];

  // // 点击导出excel 表格
  // private handleDownloadExcel() {
  //   const params = this.getFinanceLeaseTakeRateParams("exportExcel");

  //   if (params === null) {
  //     return false;
  //   }

  //   this.downloadExcelLoading = true;
  //   this.financeLeaseTakeRateApi.exportExcelFinanceLeaseTakeRateTableList(
  //     params,
  //     (res: any) => {
  //       const { start_date, end_date } = params || {};

  //       const filename = `技术服务费店铺收益明细列表 ${start_date}到${end_date}.xlsx`;
  //       const { data } = res || {};

  //       const { list: originList } = data || {};

  //       const newList = Array.isArray(originList)
  //         ? originList.map((item) => {
  //             const {
  //               // name,
  //               // pay_mode
                
  //             } = item;
  //             const newItem = {
  //               ...(item || {}),
  //               // __goods_info: `${name}x1`,
  //               // __pay_name: payMode2ch[pay_mode] || ''
  //             };


  //             return newItem;
  //           })
  //         : [];

  //       if (newList.length > 0) {
  //         const userExcelSortList = this.userExcelSortList;

  //         const userExcelList2ch = this.userExcelList2ch;

  //         const excelJSONData = getExcelJSONDataByList(
  //           newList,
  //           userExcelSortList,
  //           userExcelList2ch
  //         );

  //         excuteDownloadExport2Excel(excelJSONData, filename);
  //       } else {
  //         this.$message.info("选中时段没有可导出的信息");
  //       }

  //       this.downloadExcelLoading = false;
  //     },
  //     () => {
  //       this.downloadExcelLoading = false;
  //     }
  //   );
  // }

  /** end 导出excel */

}

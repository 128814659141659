
import { Component,Vue} from "vue-property-decorator";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import CueLockerIncomeApi from '@/common/api/operationManage/cueLockerIncome';
import { excuteDownloadExport2Excel } from "@/common/xlsx";

export const pageApiList = getPageAPIs(CueLockerIncomeApi)
@Component({

})
export default class CueLockerIncome extends Vue {
  constructor() {
    super();
  }
  private CueLockerIncomeApi = new CueLockerIncomeApi();
  private form: any = {
    order_no: "",
    phone: "",
    shop_name: "",
    time: []
  };
  private tableData: any[] = [];
  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  private pay_type_object:any={
    1:"微信小程序",
    2:"微信app",
    3:"支付宝app",
    4:"会员"
  }
  private activated(): void {
    console.log('3211111111111111111113333333333333')
    this.getCueLockerIncome();
  }
  private restForm(): void {
    this.form = new Util().clearObject(this.form);
    this.getCueLockerIncome();
  }

  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getCueLockerIncome();
  }

  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getCueLockerIncome();
  }

  //获取球杆柜列表
  private getCueLockerIncome(): void {
    this.CueLockerIncomeApi.getCueLockerIncomeList(
      {...this.getStorageLockerParams() },
      (res: any) => {
        console.log('11111111111',res.data.list)
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page;
        this.tablePage.prepage = res.data.prepage;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private getStorageLockerParams(mode = "all"): any {
    const params: any = {};
    const form = this.form;
    const tablePage = this.tablePage;
    const { page, prepage } = tablePage;
    const {order_no,phone, shop_name, time } = form;
    if (order_no) {
      params.order_no = order_no;
    }
    if (phone) {
      params.phone = phone;
    }
    if (shop_name) {
      params.shop_name = shop_name;
    }

    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;
      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);
      params.start_date = startTime;
      params.end_date = endTime;
    }

     if (
      mode == "exportExcel" &&
      !(params.start_date && params.end_date)
    ) {
      this.$message.error("请选择日期导出数据");
      return null;
    }
    if (mode === "all") {
      params.page = page;
      params.limit = prepage;
    }
    console.log('导出参数',params)

    return params;
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 字段名 文案对应关系
  private merchantExcelList2ch: any = {
    order_no: "订单编号",
    phone: "用户手机号",
    shop_name: "店铺名称",
    box_name: "球杆柜名称",
    price: "金额",
    pay_type: " 支付方式",
    pay_time: "支付时间"
  };
  // 商户Excel 表头的先后顺序
  private merchantExcelSortList: string[] = [
    "order_no",
    "phone",
    "shop_name",
    "box_name",
    "price",
    "pay_type",
    "pay_time"
  ];

  // 点击导出excel 表格
  private handleExportExcelStorageLockerList() {
    const params = this.getStorageLockerParams('exportExcel')
    if (params === null) {
      return false;
    }
    this.downloadExcelLoading = true;
    this.CueLockerIncomeApi.exportExcelCueLockerIncomeList(
      params,
      (res: any) => {
        const { start_date, end_date } = params

        const filename = `球杆柜收益列表 ${start_date}到${end_date}.xlsx`;
        const { data } = res

        const {list: originList} = data
        const pay_type_object =  this.pay_type_object
        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const newItem = {...item};
              for (let key in item) {
                if (typeof item[key] == 'number') {
                  newItem[key] = item[key].toString()
                }
              }
              newItem.pay_type = pay_type_object[newItem.pay_type]
              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const merchantExcelSortList = this.merchantExcelSortList;

          const merchantExcelList2ch = this.merchantExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            merchantExcelSortList,
            merchantExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

}

import { BasicsClass } from '@/common/BasicsClass';
export class PlatformCouponsRecord extends BasicsClass {
    public apiUrls: any = new Map([
        ['list', {
            url: '/admin/couponsPlatform/record',
            name: 'list',
            label: '列表'
        }],
        ['export',  {
            url: '/admin/couponsPlatform/recordExport',
            name: 'export-excel',
            label: '导出'
        }],
        ['searchVenueList', {
            url: '/admin/coupons/shop/list',
            name: 'shop-list',
            label: '店铺列表',
        }]
       
    ])

    public getList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('list').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }


    public exportList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('export').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 搜索店铺列表
    public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('searchVenueList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}

import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
// import { CouponsManageAPI } from "@/common/api/couponList";
import StorageLockerApi from '@/common/api/operationManage/storageLockerList';
import { excuteDownloadExport2Excel } from "@/common/xlsx";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import ZlSelect from "@/components/third-custom-components/select";

import ZlOption from "@/components/third-custom-components/option";

import {
  shareConfirmOptions,
  getCommonList
} from '@/common/config'

export const pageApiList = getPageAPIs(StorageLockerApi)

const defaultForms = {
  cabinet_id: '', // 设备编码
  venue_id: "",
  venue: {
    id: "",
    name: "",
  },
  box_type: 1, // 球杆柜控制设备类型：1千鸣 2闪优
  // singleMaxCountType: '0',
  // shop_id: "",
  // maxLimitCount: ''
};

const deviceBoxType2ch: string[] = ['', '千鸣', '闪优']

const defaultSearchVenuePageOption = {
  page: 1,
  limit: 10,
  isRearchBottom: false,
};

const storageLockerDialogTitle2ch: any = {
  "new-storage-locker": "新增",
  "edit-storage-locker": "编辑",
};

// 最大有效期
// const maxExpiredDays = 9999;

// 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;

const maxBenefitRate = 100

@Component({
  components: {
    ZlSelect,
    ZlOption,
  },
  filters: {
    showDeviceTypeName(box_type: number) {
      return deviceBoxType2ch[box_type] || '未知'
    }
  }
})
export default class StorageLockersManage extends Vue {
  private form: any = {
    // storageLockerId: "",
    shop_name: "",
    time: []
    // shop_id: '',
    // time: [],
    // startDate: "",
    // endDate: "",
  };
  private forms: any = {
    // maxType: "0",
    // ...defaultForms
  };

  private deepLoopUtil: any = {};

  private deviceTypeList: any[] = getCommonList(deviceBoxType2ch, 1)

  // private merchantApi: any;

  private storageLockerApi = new StorageLockerApi();

  // private storageLockerTimeDurationAPI = new StorageLockerTimeDurationManageAPI();
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    this.searchVenuePageOption = {
      ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    };

    // this.merchantApi = new MerchantApi();
  }

  private async handleChangeDeviceType(val: number) {
    const forms = {
      ...this.deepLoopUtil.deepCopy(this.forms, false),
    };

    Object.assign(forms, {
      cabinet_id: ''
    })
    switch (val) {
      case 1: // 千鸣
        
        break;
      case 2: // 闪优
        await this.refreshSanyouDeviceList()
        break;
    }

    this.forms = forms
  }

  private handleRefreshVenueDeviceInfo() {
    const forms = this.forms

    const {
      box_type
    } = forms

    if (box_type == 2) {
      this.refreshSanyouDeviceList() 
    }
  }

  private sanyouDeviceList: any[] = []

  private refreshSanyouDeviceList() {
    const forms = this.forms

    const {
      venue
    } = forms || {}

    return new Promise((resolve, reject) => {
      if (venue && venue.id) {
        this.storageLockerApi.sanYouDevice({
          shop_id: venue.id
        }, (res: any) => {
          resolve(res.data)
          this.sanyouDeviceList = res.data
        }, (err: any) => {
          reject(err)
          console.log(err, 'err')
        })
      } else {
        reject()
        this.$message.error('请先选择店铺')
      }
    })

    
  }

  
  private created(): void {
    this.getStorageLockers();

    // this.refreshStorageLockerTimeDurations();

    // this.refreshTableClass();
  }
  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getStorageLockers();
  }
  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getStorageLockers();
  }

  private tableData: any[] = [];

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";
  
  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getStorageLockers();
  }

  private addOrUpdateStorageLockerTitle: string = "球杆柜";

  private dialogFormVisible: boolean = false;

  // 拉起球杆柜弹框
  private handleWakeStorageLockerDialog(mode: string) {
    // if (mode == 'new-storage-locker') { // 新增球杆柜

    // }
    this.storageLockerDialogMode = mode;
    this.addOrUpdateStorageLockerTitle = storageLockerDialogTitle2ch[mode] || "球杆柜";

    this.initStorageLockerForm(mode);

    this.dialogFormVisible = true;
  }

  private currentStorageLockerItem: any = null;

  private storageLockerDialogMode: string = "";

  private async initStorageLockerForm(mode: string) {
    const forms: any = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    if (mode == "edit-storage-locker") {
      // 编辑
      await this.getMoreVenueList(true);


      // await this.refreshStorageLockerTimeDurations();

      // await this.refreshTableClass();

      const currentStorageLockerItem = this.currentStorageLockerItem;

      const {
        id,
        cabinet_id,
        name,
        box_type,
        shop_id,
      } = currentStorageLockerItem || {};

      Object.assign(forms, {
        box_type: box_type || 1,
        cabinet_id: cabinet_id || ''
      })

      if (name && typeof shop_id == "number") {
        console.log(shop_id, "shop_id");

        console.log(this.venueList, "this.venueList");
        let existVenue = this.venueList.find((venue) => venue.id == shop_id);

        if (!existVenue) {
          existVenue = {
            id: shop_id,
            name: name,
          };
          this.venueList.push(existVenue);
        }

        forms.venue = existVenue;
      }

      forms.id = id;
    }

    console.log(forms, "forms");

    console.log(this.venueList, "this.venueList");

    this.forms = forms;

    if (mode == "edit-storage-locker") {
      await this.refreshSanyouDeviceList()
    }
  }

  private handleWakeEditStorageLockerDialog(storageLockerItem: any) {
    this.currentStorageLockerItem = storageLockerItem;

    this.handleWakeStorageLockerDialog("edit-storage-locker");
  }

  private handleDelteStorageLockerDialog(storageLockerItem: any) {
    this.$confirm("确定删除该球杆柜?", "", {
      ...shareConfirmOptions,
    }).then(() => {
      this.storageLockerApi.deleteStorageLocker({
        id: storageLockerItem.id
      }, () => {
        this.getStorageLockers()
      }, () => {

      })
    });
  }

  // 跳转球杆柜管理列表
  private handleGoLockerManageList(storageLockerItem: any) {
    console.log(storageLockerItem, 'storageLockerItem')

    this.$router.push({
      path: '/lockerDoorList',
      query: {
        sbid: storageLockerItem.id
      }
    })
  }

  private venueList: any[] = [
    // {
    //   id: 1,
    //   label: '测试'
    // },
    // {
    //   id: 2,
    //   label: '测试2'
    // }
  ];

  private searchVenueLoading: boolean = false;

  private remoteGetVenueQueryString: string = "";

  private remoteSearchVenueTimer: any = null;

  private searchVenuePageOption: any = {};

  private initRemoteGetVenuePageOption() {
    this.searchVenuePageOption = {
      ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    };
  }

  private getSearchVenueParams() {
    const searchVenuePageOption = this.searchVenuePageOption;

    const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

    const { page, limit } = searchVenuePageOption;

    const params: any = {
      page,
      limit,
    };

    if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
      params.name = remoteGetVenueQueryString.trim();
    }

    return params;
  }

  private getMoreVenueList(refresh: boolean = true) {
    return new Promise((resolve: Function, reject: Function) => {
      if (refresh) {
        this.initRemoteGetVenuePageOption();
      }

      const params = this.getSearchVenueParams();

      this.storageLockerApi.searchVenueList(
        params,
        (res: any) => {
          console.log(res, "res");

          const venueList: any[] = res.data || [];

          this.venueList = refresh
            ? venueList
            : this.venueList.concat(venueList);

          if (Array.isArray(res.data) && res.data.length == 0) {
            this.searchVenuePageOption.isRearchBottom = true;
          }
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          resolve();
        },
        () => {
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          reject();
        }
      );
    });
  }

  // 第一次搜索 无关键字
  private handleFirstGetVenueList() {
    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 清空搜索
  private handleClearGetVenueList() {
    this.remoteGetVenueQueryString = "";

    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 远程搜索
  private remoteGetVenueMethod(query: string) {
    query = query.trim ? query.trim() : "";

    if (query !== "") {
      this.remoteGetVenueQueryString = query;
      clearTimeout(this.remoteSearchVenueTimer);

      this.remoteSearchVenueTimer = setTimeout(() => {
        this.searchVenueLoading = true;

        this.getMoreVenueList();
        
      }, 300);
    } else {
      this.remoteGetVenueQueryString = "";
      this.venueList = [];
    }
  }

  private addVenueListLoading: boolean = false;

  private remoteAddVenueMethod() {
    if (this.addVenueListLoading) return;
    const searchVenuePageOption = this.searchVenuePageOption;

    const { isRearchBottom } = searchVenuePageOption;

    if (isRearchBottom) return;

    this.searchVenuePageOption.page++;

    this.addVenueListLoading = true;

    this.getMoreVenueList(false);
  }

  // 分成比例
  // private handleLimitBenefitRate(val: string) {
  //   Util.limitIntegetInput(
  //     val,
  //     (new_val: string) => {
  //       const limit_val = Math.min(Number(new_val), maxBenefitRate).toString();
  //       this.$set(this.forms, "fencheng_rate", limit_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "fencheng_rate", new_val);
  //     }
  //   );
  // }

  // private handleLimitSingleMaxCount(val: string) {
  //   Util.limitIntegetInput(
  //     val,
  //     (new_val: string) => {
  //       this.$set(this.forms, "maxLimitCount", new_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "maxLimitCount", new_val);
  //     }
  //   );
  // }

  private sumbitStorageLockerLoading: boolean = false;

  // 提交球杆柜配置
  private handleSumbitStorageLocker() {
    const storageLockerDialogMode = this.storageLockerDialogMode;

    const params = this.getStorageLockerFormsParams(storageLockerDialogMode);

    console.log(params, "params");
    if (params === false) return;

    this.sumbitStorageLockerLoading = true;
    switch (storageLockerDialogMode) {
      case "new-storage-locker":
        this.storageLockerApi.addStorageLocker(
          params,
          () => {
            this.getStorageLockers();
            this.dialogFormVisible = false;
            this.sumbitStorageLockerLoading = false;
          },
          () => {
            this.sumbitStorageLockerLoading = false;
          }
        );
        break;
      case "edit-storage-locker":
        this.storageLockerApi.updateStorageLocker(
          params,
          () => {
            this.getStorageLockers();
            this.dialogFormVisible = false;
            this.sumbitStorageLockerLoading = false;
          },
          () => {
            this.sumbitStorageLockerLoading = false;
          }
        );
        break;
    }
  }

  private getStorageLockerFormsParams(mode: string) {
    const params: any = {};

    const showErrorMessage = (msg: string) => {
      if (!!msg) {
        this.$message.error(msg);
      }
    };

    const forms = this.forms;

    const {
      id,
      // name,
      // expired_days,
      // meituanId,
      // timeDuration
      // machine_number,
      cabinet_id,
      venue,
    } = forms || {};

    if (venue && venue.id) {
      params.shop_id = venue.id;
    } else {
      showErrorMessage("请选择一个店铺");
      return false;
    }

    const keys = ["cabinet_id", "box_type"];

    const key2EndKey: any = {
      cabinet_id: "cabinet_id",
      box_type: "box_type"
    };

    const key2ErrMsg: any = {
      cabinet_id: "设备编码不能为空",
      box_type: "设备类型不能为空"
    };

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (forms[key]) {
        const errMsg = key2ErrMsg[`${key}_${forms[key]}`];
        if (errMsg) {
          showErrorMessage(errMsg);

          return false;
        }
        params[key2EndKey[key] || key] = forms[key];
      } else {
        showErrorMessage(key2ErrMsg[key]);

        return false;
      }
    }
    

    if (mode == "edit-storage-locker") {
      params.id = id;
    }

    return params;

  }

  //获取球杆柜列表
  private getStorageLockers(): void {
    const params = this.getStorageLockerParams()
    this.storageLockerApi.getStorageLockerList(
      { ...params },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page || params.page;
        this.tablePage.prepage = res.data.prepage || params.limit;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private getStorageLockerParams(mode = "all"): any {
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    const { shop_name, time } = form;

    if (shop_name) {
      params.shop_name = shop_name;
    }

    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      params.start_date = startTime;

      params.end_date = endTime;
    }

     if (
      mode == "exportExcel" &&
      !(params.start_date && params.end_date)
    ) {
      this.$message.error("请选择日期导出数据");
      return null;
    }



    if (mode === "all") {
      params.page = page;

      params.limit = prepage;

      // outerParams.__isUnique = !firstLoading;
    }

    return params;
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 字段名 文案对应关系
  private merchantExcelList2ch: any = {
    name: "店铺名称",
    shop_id: " 店铺ID",
    cabinet_id: "绑定设备ID",
    _device_type_name: "绑定设备类型",
    admin_name: "操作人",
    updated_at: "操作时间"
  };
  // 商户Excel 表头的先后顺序
  private merchantExcelSortList: string[] = [
    "name",
    "shop_id",
    "cabinet_id",
    "_device_type_name",
    "admin_name",
    "updated_at"
  ];

  // 点击导出excel 表格
  private handleExportExcelStorageLockerList() {
    const params = this.getStorageLockerParams('exportExcel')

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.storageLockerApi.exportExcelStoragelockerList(
      params,
      (res: any) => {
        const { start_date, end_date } = params || {};

        const filename = `球杆柜管理列表 ${start_date}到${end_date}.xlsx`;
        const { data } = res || {};

        const {
          list: originList
        } = data || {}

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const { 
                // status,
                // back_goods_detail
                box_type
               } = item || {};
              const newItem = {
                ...(item || {}),
                // __status_text: withdrawAccountAudit2Text[status],
                // __back_good_details: back_goods_detail.map((goods: any, index: number) => `${ goods.name }x${ goods.num }  ¥${ goods.price }`).join('; \t')
                _device_type_name: deviceBoxType2ch[box_type] || '未知'
              };

              for (let key in item) {
                if (typeof item[key] == 'number') {
                  newItem[key] = item[key].toString()
                }
              }

             
              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const merchantExcelSortList = this.merchantExcelSortList;

          const merchantExcelList2ch = this.merchantExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            merchantExcelSortList,
            merchantExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };
}

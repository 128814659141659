import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class DiamondRecordApi extends BasicsClass {
    public apiUrls: any = new Map([
        ['getDiamondTakeRecordTableList', {
            url: '/admin/exchange/obtainRecord',
            name: 'list',
            label: '列表'
        }],
        ['exportExcelDiamondTakeList', {
            url: '/admin/exchange/exportObtainRecord',
            name: 'export-excel',
            label: '导出'
        }]
    ])

    // 钻石获取记录列表
    public getDiamondTakeRecordTableList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getDiamondTakeRecordTableList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // // 导出钻石获取记录
    public exportExcelDiamondTakeList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('exportExcelDiamondTakeList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}

import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getPageAPIs } from "@/common/util";
import ProportionSettingApi from "@/common/api/operationManage/coachProportionSetting";
// import { CommonApi } from "@/common/api/common";

import { excuteDownloadExport2Excel } from "@/common/xlsx";

import {
  shareConfirmOptions
} from '@/common/config'

const defaultAuditStatus2ch: any = {
  1: "审核中",
  2: "审核通过",
  3: "驳回",
};


export const pageApiList = getPageAPIs(ProportionSettingApi)


@Component({})
export default class CoachProportionSetting extends Vue {
  private utilInstance: any;
  private proportionSettingApi: any;

  // private commonApi: any;

  private proportionForm: any = {
    coachRadio: "", // 助教比例
    merchantRadio: "", // 商户比例
    platformRadio: "", // 平台比例

    allianceShopRate: '', // 加盟商 平台比例

    giftRate: "", // 礼物分成
    inviteGiftRate: "", // 邀请好友好友送礼物分成
  };

  constructor() {
    super();
    this.proportionSettingApi = new ProportionSettingApi();

    // this.commonApi = new CommonApi();

    this.utilInstance = new Util();

  }

  private activated(): void {
    this.getTable();
  }
  private getTable(): void {
    this.proportionSettingApi.getProportionSettingInfo(
      {},
      (res: any) => {
        console.log(res, "res");

        const { coach_rate, platform_rate, shop_rate, alliance_shop_rate, gift_rate, invite_gift_rate, invite_validity } = res.data || {};

        const proportionForm: any = {
          coachRadio: typeof coach_rate == 'number' ? coach_rate.toString() : "",
          merchantRadio: typeof shop_rate == 'number' ? shop_rate.toString() : "",
          platformRadio: typeof platform_rate == 'number' ? platform_rate.toString() : "",
          allianceShopRate: typeof alliance_shop_rate == 'number' ? alliance_shop_rate.toString() : "",

          giftRate: typeof gift_rate == 'number' ? gift_rate.toString() : '',
          inviteGiftRate: typeof invite_gift_rate == 'number' ? invite_gift_rate.toString() : '',
          inviteValidity: typeof invite_validity == 'number' ? invite_validity.toString() : ''
        };

        this.proportionForm = proportionForm;
      },
      () => {}
    );
  }

  private handleLimitRadio(val: string, propName: string) {
    const MAX_VALUE = 100;
    Util.limitDigitalnput(
      val,
      (new_val: string) => {
        if (Number(new_val) > MAX_VALUE) {
          this.$set(this.proportionForm, propName, MAX_VALUE);
        } else {
          this.$set(this.proportionForm, propName, new_val);
        }
      },
      (new_val: string) => {
        this.$set(this.proportionForm, propName, new_val);
      }
    );
  }

  private handleLimitInt(val: string, propName: string) {
    Util.limitDigitalnput(
      val,
      (new_val: string) => {
        this.$set(this.proportionForm, propName, new_val);
      },
      (new_val: string) => {
        this.$set(this.proportionForm, propName, new_val);
      }
    );
  }


  private getProportionParams() {
    const proportionForm = this.proportionForm;
    const { coachRadio, merchantRadio, platformRadio, allianceShopRate, giftRate, inviteGiftRate, inviteValidity } = proportionForm || {};

    const params: any = {};

    let errorMsg: string = "";

    if (coachRadio) {
      params.coach_rate = coachRadio;
    } else {
      errorMsg = "助教分成不可为空";
    }

    if (merchantRadio) {
      params.shop_rate = merchantRadio;
    } else {
      errorMsg = "商户分成不可为空";
    }

    if (platformRadio) {
      params.platform_rate = platformRadio;
    } else {
      errorMsg = "平台分成不可为空";
    }

    if (allianceShopRate) {
      params.alliance_shop_rate = allianceShopRate
    } else {
      errorMsg = "加盟商平台分成不可为空";
    }

    if (giftRate) {
     params.gift_rate = giftRate
    } else {
      errorMsg = "礼物分成不可为空";
    }

    if (inviteGiftRate) {
      params.invite_gift_rate = inviteGiftRate
    } else {
      errorMsg = "好友送礼物分成不可为空";
    }

    if (inviteValidity) {
     params.invite_validity = inviteValidity
    } else {
      errorMsg = "邀请好友有效绑定天数不可为空";
    }

    if (errorMsg) {
      this.$message(errorMsg);
      return false;
    }

    return params;
  }

  private handleSaveProportionSetting() {
    const params: any = this.getProportionParams();

    if (typeof params !== "object") return;

    this.$confirm("确定修改分成配置", "", {
      ...shareConfirmOptions,
    }).then(() => {
      this.proportionSettingApi.setProportionSettingInfo(params, () => {
        this.$message.success('修改配置成功')
      }, () => {

      });
    });
  }

 


  private restForm(): void {
    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}

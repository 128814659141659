import { render, staticRenderFns } from "./springFRewardRecord.vue?vue&type=template&id=60d4f10d&scoped=true"
import script from "./springFRewardRecord.vue?vue&type=script&lang=ts"
export * from "./springFRewardRecord.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60d4f10d",
  null
  
)

export default component.exports
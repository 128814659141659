
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList,getPageAPIs } from "@/common/util";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import {AddOrEditCompetitionAPI} from "@/common/api/competitionManage/addOrEditComplexCompetition";

import { CommonApi } from "@/common/api/common";

import gallery from "@/components/gallery.vue";

import ZlSelect from "@/components/third-custom-components/select";

import ZlOption from "@/components/third-custom-components/option";


import { excuteDownloadExport2Excel } from "@/common/xlsx";
export const pageApiList = getPageAPIs(AddOrEditCompetitionAPI);

import {
  shareConfirmOptions,
  number2CN,
  leagueType2Text,
  getCommonList,
  chooseLeagueTypeList,
  leagueLevelList,
  // isEmptyLeagueReward,
  getLeagueRewardTxt,

  leagueSetTypeList,
  leagueRuleTypeList
} from '@/common/config'

const defaultCompeteRewardForm = {
  __label: '',
  level: '',
  shop_member_price: '',
  cash: '',
  coupon_shop_id: '',
  coupon_name: '',
  coupon_num: '',
  reward_text: '',

  __show_reward_text: ''
}


// 生成比赛奖金空表单数据 同时根据后端返回值覆写
function getLeagueRewradListForm(rewardList: any[] = []) {
  const list: any[] = []

  for (const { label, value } of leagueLevelList) {
    const form = {
      ...DeepLoop.deepClone(defaultCompeteRewardForm)
    }

    Object.assign(form, {
      level: value,
      __label: label
    })

    const existRewardItem = rewardList.find((reward: any) => reward.level == value)

    if (existRewardItem) {
      Object.assign(form, {
        ...existRewardItem,
        __show_reward_text: getLeagueRewardTxt(existRewardItem)
      })
    }

    // 是参赛奖
    if (value == 8) {
      list.unshift(form)
    } else {
      list.push(form)
    }
  }

  // 将参赛奖项移到最后
  list.push(list.shift())

  // console.log(list, 'list')

  return list
}

// 报名条件
// 报名条件: 1付费报名 2会员免费报名 3会员才能报名 4会员联赛积分达标报名 5乙级联赛积分达标报名 6甲级联赛积分达标报名 7超级联赛积分达标报名 8会员充值累计200免费报名 9会员充值累计300免费报名 10会员充值累计500免费报名 11会员充值累计1000免费报名 12会员单次充值199元及以上免费报名  13会员单次充值500元及以上免费报名 14 会员单次充值1000元及以上免费报名
const defaultSignupCompetitionConditions: any[] = [
  {
    label: '付费报名',
    value: 1
  },
  {
    label: '会员免费报名',
    value: 2
  },
  {
    label: '会员才能报名',
    value: 3
  },
  {
    label: '会员联赛积分达标报名',
    value: 4
  },
  {
    label: '乙级联赛积分达标报名',
    value: 5
  },
  {
    label: '甲级联赛积分达标报名',
    value: 6
  },
  {
    label: '超级联赛积分达标报名',
    value: 7
  },
  {
    label: '会员充值累计200免费报名',
    value: 8
  },
  {
    label: '会员充值累计300免费报名',
    value: 9
  },
  {
    label: '会员充值累计500免费报名',
    value: 10
  },
  {
    label: '会员充值累计1000免费报名',
    value: 11
  },
  {
    label: '会员单次充值199元及以上免费报名',
    value: 12
  },
  {
    label: '会员单次充值500元及以上免费报名',
    value: 13
  },
  {
    label: '会员单次充值1000元及以上免费报名',
    value: 14
  }
]

const withExtraLimitFormCompetitionConditions: number[] = [4, 5, 6, 7]


// 正赛form
const defaultCompeteSetForm = {
  __label: '',  
  level: '',
  type: '',
  limit_minute: '',
  draw_start_time: '',
  draw_end_time: ''
}

// 生成正赛设置空表单数据 同时根据后端返回值覆写
function getLeagueSetListForm(setList: any[] = []) {
  const list: any[] = []

  for (const { label, value } of leagueSetTypeList) {
    const form = {
      ...DeepLoop.deepClone(defaultCompeteSetForm)
    }

    Object.assign(form, {
      level: value,
      __label: label
    })

    const existSetItem = setList.find((setItem: any) => setItem.level == value)

    if (existSetItem) {
      Object.assign(form, {
        ...existSetItem,
      })
    }

    list.unshift(form)
  }

  return list
} 

// 类型:1单败 2双败
const defaultCompeteSetTypeList: any[] = [
  {
    label: '单败',
    value: 1
  },
  {
    label: '双败',
    value: 2
  }
]


// 预选赛form数据
const defaultCompeteQualifierSetForm = {
  __label: '',
  level: '',
  success_person_num: '',
  limit_minute: '',
  register_price: '',
  register_end_time: '',
  draw_start_time: '',
  draw_end_time: '',
  register_person_num_limit: ''
}

function getCompeteQualifierSetFormList(qualifier_level: number, qualifierSetList: any[] = []) {
  console.log(qualifierSetList, 'qualifierSetList')

  const list: any[] = new Array(qualifier_level).fill(0).map((_, index: number) => index + 1).map((level: number) => {
    const form = {
      ...DeepLoop.deepClone(defaultCompeteQualifierSetForm)
    }

    Object.assign(form, {
      __label: `第${number2CN[level]}轮`,
      level,
    })

    const existQualifierSetItem = qualifierSetList.find((qualifier) => qualifier.level == level)

    if (existQualifierSetItem) {
      Object.assign(form, {
        ...existQualifierSetItem
      })
    }


    return form
  })

  return list
}



// 可选轮次 0~8
const defaultQulifierLevel2text: number[] = new Array(9).fill(0).map((_, index: number) => index)

// 预选赛报名类型:1单轮单报,2多轮单报
const defaultQualifierRegister2text: string[] = [
  '',
  '单轮单报',
  '多轮单报'
]


const defaultForm = {
  venue_id: "",
  venue: {
    id: "",
    name: "",
  },
  league_name: '',
  register_condition: '',
  quarter: '',
  score: '',
  is_qualifier: 1,
  is_student_league: 0, // 是否学生联赛0:否1是
  register_price: '',
  register_end_time: '',
  league_start_time: '',
  league_end_time: '',
  register_person_num_limit: '',
  league_type: '',
  fee_type: '',

  league_reward_list: getLeagueRewradListForm(),

  league_set_list: getLeagueSetListForm(),

  qualifier_level: '',
  qualifier_register_type: 1, // 预选赛报名类型:1单轮单报,2多轮单报
  league_qualifier_set_list: [],

  is_auto_register: 1, // 是否自动报名:0否,1是

  league_info_type: 1, // 比赛详情类型:1中式台球 2自定义 3上传图片

  league_info_custom_text: '',


  league_info_images: [],
  league_info_image_id: '',

  league_info_image_url: '',

  show_image_id: '',
  show_image_url: '',

  is_show: 1, // 是否展示:0否,1是

};

const defaultForms = {
  // league_name: "", // 赛事名称
  // shop_name: "", // 店铺名称
  // venue_id: "",
  // venue: {
  //   id: "",
  //   name: "",
  // },
  // decr_price: '', // 扣款金额
  reason: '', // 备注
}


// 店铺搜索
const defaultSearchVenuePageOption = {
  page: 1,
  limit: 10,
  isRearchBottom: false,
};

// 报名费 范围0-9999
const maxRegisterPrice = 9999

// 参赛人数 范围0-999
const maxRegisterPersonNum = 999

// 台费类型:1负方支付台费 2不收台费
const defaultFeeTypeList: any[] = [
  {
    label: '负方支付台费',
    value: 1
  },
  {
    label: '不收台费',
    value: 2
  }
]

// 单店卡 范围 0 ~ 9999
const maxShopMemberPrice = 9999

// 畅打券 返回 0 ~ 99
const maxShopCouponNum = 99

@Component({
  filters: {
    showLeagueRewardTxt(wholeText: string) {
      if (wholeText.length > 20) {
        return wholeText.slice(0, 20) + '...'
      } else {
        return wholeText
      }
    }
  },
  components: {
    gallery,
    ZlSelect,
    ZlOption,
  },
})
export default class SettingCompetitionAssistant extends Vue {
  private addOrEditCompetitionApi: any;

  private deepLoopUtil: any;

  private commonApi: any;

  // add  edit
  private get competitionMode() {
    const route = this.$route

    const {
      query
    } = route || {}

    const {
      mode
    } = query || {}

    return mode || 'add'
  }
  
  private get competitionId() {
    const route = this.$route

    const {
      query
    } = route || {}

    const {
      ctid
    } = query || {}

    return ctid || null
  }


  private form: any;

  private forms: any;

  private registerConditionList: any[] = [];

  private chooseLeagueTypeList: any[] = []

  private allFeeTypeList: any[] = []

  private withExtraLimitFormCompetitionConditions: number[] = [
    ...withExtraLimitFormCompetitionConditions
  ]

  private allLeagueSetTypeList: any[] = []

  private allLeagueQualifierLevelList: number[] = [
    ...defaultQulifierLevel2text
  ]

  private allLeagueQualifierTypeList: any[] = getCommonList(defaultQualifierRegister2text, 1)

  private leagueRuleTypeList: any[] = [];

  constructor() {
    super();
    this.addOrEditCompetitionApi = new AddOrEditCompetitionAPI();

    this.deepLoopUtil = new DeepLoop();

    this.commonApi = new CommonApi();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm, false),
    };

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms),
    }

    this.registerConditionList = [
      ...this.deepLoopUtil.deepCopy(defaultSignupCompetitionConditions)
    ]

    this.chooseLeagueTypeList = [
      ...this.deepLoopUtil.deepCopy(chooseLeagueTypeList)
    ]

    this.allFeeTypeList = [
      ...this.deepLoopUtil.deepCopy(defaultFeeTypeList)
    ]

    this.allLeagueSetTypeList = [
      ...this.deepLoopUtil.deepCopy(defaultCompeteSetTypeList)
    ]
    
    this.leagueRuleTypeList = [
      ...this.deepLoopUtil.deepCopy(leagueRuleTypeList)
    ]
  }

  private competitionRuleList: any[] = []

  private activated() {
    this.addOrEditCompetitionApi.getComplexCompetitionRuleInfo({}, (res: any) => {
      const {
        rule_list
      } = res.data || {}

      this.competitionRuleList = rule_list
    })

    this.initCompetitionForm()
  }

  private async initCompetitionForm() {
    const mode = this.competitionMode

    const form = {
      ...this.deepLoopUtil.deepCopy(defaultForm, false)
    }

    if (mode == 'edit') {
      // 编辑
      await this.getMoreVenueList(true);

      this.addOrEditCompetitionApi.getComplexCompetitionDetail({
        league_id: this.competitionId
      }, (res: any) => {
        const {
          shop_id,
          shop_name,
          league_name,
          register_condition,
          quarter,
          score,

          is_qualifier,
          is_student_league,
          register_price,
          register_end_time,
          league_start_time,
          league_end_time,
          register_person_num_limit,
          league_type,
          fee_type,

          league_reward_list,
          league_set_list,

          is_auto_register,

          qualifier_level,
          qualifier_register_type,
          league_qualifier_set_list,

          league_info_type,
          league_info_custom_text,

          league_info_image_id,
          league_info_image_url,

          show_image_id,
          show_image_url,

          is_show
        } = res.data || {}

        Object.assign(form, {
          venue_id: shop_id || '',
          venue: {
            id: shop_id || '',
            name: shop_name || ''
          },
          league_name,
          register_condition,
          quarter: quarter || '',
          score: score || '',
          is_qualifier,
          is_student_league,
          register_price: register_price || '',
          register_end_time: register_end_time || '',
          league_start_time,
          league_end_time,
          
          league_type,
          fee_type,

          league_reward_list: getLeagueRewradListForm(league_reward_list),
          league_set_list: getLeagueSetListForm(league_set_list),


          league_info_type,
          league_info_custom_text: league_info_custom_text || '',

          show_image_id,
          show_image_url: show_image_url || '',

          is_show
        })

        if (is_qualifier == 1) {
          Object.assign(form, {
            qualifier_level,
            qualifier_register_type,
            league_qualifier_set_list: getCompeteQualifierSetFormList(qualifier_level, league_qualifier_set_list),
            is_auto_register,
          })
        } else {
          Object.assign(form, {
            register_person_num_limit: typeof register_person_num_limit == 'number' ?register_person_num_limit.toString() : '',
          })
        }

        if (league_info_type == 3) {
          const league_info_image_ids = league_info_image_id ? league_info_image_id.split(',') : []
          const league_info_image_urls = league_info_image_url ? league_info_image_url.split(',') : []

          // console.log(league_info_image_ids, 'league_info_image_ids')
          // console.log(league_info_image_urls, 'league_info_image_urls')

          // console.log(league_info_image_id, league_info_image_url, 'league_info_image_id, league_info_image_url')

          const league_info_images = league_info_image_ids.map((id: string, index: number) => {
            const imageItem = {
              uid: Number(id),
              fileId: Number(id),
              url: league_info_image_urls[index],
            }

            return imageItem
          })

          Object.assign(form, {
            league_info_images,
            league_info_image_id,
            league_info_image_url
          })
        }

        console.log(form, 'form')

        this.form = form
      }, () => {})

      return
    }

    console.log(form, 'form')


    this.form = form
  }

  private sumbitCompetitionInfoLoading: boolean = false

  private handleSumbitComplexCompetition() {
    const params = this.getComplexCompetitionParams()

    const mode = this.competitionMode

    if (typeof params != 'object') return

    let sendSumbitFunc: any = (...args: any[]) => Promise.resolve()

    switch (mode) {
      case 'add':
        sendSumbitFunc = this.addOrEditCompetitionApi.addComplexCompetition
        break;
      case 'edit':
        sendSumbitFunc = this.addOrEditCompetitionApi.editComplexCompetition
        break;
    }

    sendSumbitFunc.call(this.addOrEditCompetitionApi, params, (res: any) => {
      this.$message.success('比赛信息提交成功')

      setTimeout(() => {
        this.$router.go(-1)
      }, 300)
    })
  }

  private getCompetitionBaseInfoParams(form: any): any {
    const {
      venue,
      // league_name,
      register_condition,
      quarter,
      score,
      is_qualifier,
      is_student_league,
      register_price,
      register_end_time,
      // league_start_time,
      // league_end_time,
      register_person_num_limit
    } = form || {}

    const subParams: any = {
      is_qualifier,
      is_student_league
    }

    if (!(venue && venue.id)) {
      this.$message.error('请选择店铺')

      return false
    } 

    subParams.shop_id = venue.id

    if (!register_condition) {
      this.$message.error('请选择报名条件')

      return false
    }

    subParams.register_condition = register_condition

    if (withExtraLimitFormCompetitionConditions.includes(register_condition)) {
      if (!(quarter && score)) {
        this.$message.error('请输入要求积分条件')

        return false
      }

      subParams.quarter = quarter

      subParams.score = score
    }

    if (is_qualifier === 0) {
      if (!register_price) {
        this.$message.error('请输入报名费')

        return false
      }

      subParams.register_price = register_price

      if (!register_end_time) {
        this.$message.error('请输入报名截止时间')

        return false
      }

      subParams.register_end_time = register_end_time

      if (!register_person_num_limit) {
        this.$message.error('请输入参赛人数限制')

        return false
      }
      subParams.register_person_num_limit = register_person_num_limit
    }


    const keys = [
      'league_name',
      // 'register_condition',
      // 'register_end_time',
      'league_start_time',
      'league_end_time',
      // 'register_person_num_limit',
      'league_type',
      'fee_type'
    ]

    const err2Msg: any = {
      'league_name': '请输入赛事名称',
      // 'register_condition': '请选择报名条件',
      // 'register_end_time': '请选择报名截止时间',
      'league_start_time': '请选择比赛开始时间',
      'league_end_time': '请选择比赛结束时间',
      // 'register_person_num_limit': '请输入参赛人数限制',
      'league_type': '请选择比赛类型',
      'fee_type': '请选择台费'
    }

    for (const key of keys) {
      if (!form[key]) {
        const errMsg = err2Msg[key]

        this.$message.error(errMsg)
        return false
      }

      subParams[key] = form[key]
    }


    return subParams

  }


  private getComplexCompetitionParams() {
    const form = this.form
    const mode = this.competitionMode

    const {
      league_reward_list,
      league_set_list,
      is_qualifier,

      qualifier_level,
      qualifier_register_type,
      league_qualifier_set_list,

      is_auto_register,
      league_info_type,

      league_info_custom_text,

      league_info_images,

      show_image_id,
      // show_image_url

      is_show
    } = form || {}

    const params: any = {
      league_info_type,

      is_show
    }

    if (mode == 'edit') {
      params.league_id = this.competitionId
    }

    const baseInfoParams = this.getCompetitionBaseInfoParams(form)

    if (typeof baseInfoParams != 'object') return false

    Object.assign(params, {
      ...(baseInfoParams || {})
    })

    params.league_reward_list = JSON.stringify(league_reward_list)

    params.league_set_list = JSON.stringify(league_set_list)

    if (is_qualifier === 1) {
      if (!qualifier_level) {
        this.$message.error('请选择预选赛轮数')
        return false
      }

      params.qualifier_level = qualifier_level

      params.qualifier_register_type = qualifier_register_type

      params.league_qualifier_set_list = JSON.stringify(league_qualifier_set_list)

      if (qualifier_register_type == 1) {
        params.is_auto_register = is_auto_register
      }
    }

    if (league_info_type == 2) {
      params.league_info_custom_text = league_info_custom_text || ''
    }

    if (league_info_type == 3) {
      if (Array.isArray(league_info_images) && league_info_images.length) {
        if (league_info_images.some((image: any) => image.uploading || image.failed)) {
          this.$message("有正在上传或者上传失败的文件，请稍等或重新上传");
          return false;
        }

        params.league_info_image_id = league_info_images.map((image: any) => image.fileId || '').filter((id: any)=> !!id).join(',')
      }
    }

    if (!show_image_id) {
      this.$message.error('请上传展示图')

      return false
    }

    params.show_image_id = show_image_id

    return params
  }

  private handleLimitRegisterPrice(val: string) {
    Util.limitDigitalnput(
      val,
      (new_val: string) => {
        const limit_val = Math.min(Number(new_val), maxRegisterPrice).toString();
        if (maxRegisterPrice == Number(limit_val)) {
          this.$set(this.form, "register_price", limit_val);
        } else {
          this.$set(this.form, "register_price", new_val);
        }
      },
      (new_val: string) => {
        this.$set(this.form, "register_price", new_val);
      },
      2
    )
  }

  private handleLimitRegisterPersonNum(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        const limit_val = Math.min(Number(new_val), maxRegisterPersonNum).toString();
        if (maxRegisterPersonNum == Number(limit_val)) {
          this.$set(this.form, "register_person_num_limit", limit_val);
        } else {
          this.$set(this.form, "register_person_num_limit", new_val);
        }
      },
      (new_val: string) => {
        this.$set(this.form, "register_person_num_limit", new_val);
      },
    )
  }

  /** 奖金设置弹框 */
  private curEditCompeteRewardForm: any = {};
  
  private dialogCompeteRewardFormVisible: boolean = false

  private chooseReardSingleCouponList: any[] = []

  private handleWakeSettingCompeteRewardDialog(rewardItem: any) {
    const form = this.form
    const {
      venue
    } = form || {}

    if (!(venue && venue.id)) {
      this.$message.error('请先选择一个店铺')
      return 
    }

    this.curEditCompeteRewardForm = {
      ...this.deepLoopUtil.deepCopy(rewardItem)
    }

    this.addOrEditCompetitionApi.getSingleCouponList({
      shop_id: venue.id,
      page: 1,
      limit: 1000
    }, (res: any) => {
      const {
        list
      } = res.data || {}

      this.chooseReardSingleCouponList = list

      this.dialogCompeteRewardFormVisible = true
    }, () => {

    })

  }

  private handleUpdateCompetitionRewardList() {
    const curEditCompeteRewardForm = this.curEditCompeteRewardForm

    const {
      league_reward_list,
      
    } = this.form

    const existRewardItemIndex = league_reward_list.findIndex((reward: any) => reward.level == curEditCompeteRewardForm.level)

    console.log(existRewardItemIndex, 'existRewardItemIndex')

    if (existRewardItemIndex > -1) {
      Object.assign(league_reward_list[existRewardItemIndex], {
        ...curEditCompeteRewardForm,

        __show_reward_text: getLeagueRewardTxt(curEditCompeteRewardForm)
      })

      // this.$set(this.form.league_reward_list, existRewardItemIndex, league_reward_list[existRewardItemIndex])
    }

    console.log(this.form, 'this.form')

    this.dialogCompeteRewardFormVisible = false
  }

  private handleChangeChooseSingleCoupon(coupon_id: number) {
    const chooseReardSingleCouponList = this.chooseReardSingleCouponList

    const chooseCouponItem = chooseReardSingleCouponList.find((coupon: any) => coupon.id == coupon_id)

    if (chooseCouponItem) {
      this.curEditCompeteRewardForm.coupon_name = chooseCouponItem.name
    }
  }

  private handleLimitCash(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
       
        this.$set(this.curEditCompeteRewardForm, "cash", new_val);
        
      },
      (new_val: string) => {
        this.$set(this.curEditCompeteRewardForm, "cash", new_val);
      },
    )
  }

  private handleLimitShopMemberPrice(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        const limit_val = Math.min(Number(new_val), maxShopMemberPrice).toString();
        if (maxShopMemberPrice == Number(limit_val)) {
          this.$set(this.curEditCompeteRewardForm, "shop_member_price", limit_val);
        } else {
          this.$set(this.curEditCompeteRewardForm, "shop_member_price", new_val);
        }
      },
      (new_val: string) => {
        this.$set(this.curEditCompeteRewardForm, "shop_member_price", new_val);
      },
    )
  }

  private handleLimitSingleCouponNum(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        const limit_val = Math.min(Number(new_val), maxShopCouponNum).toString();
        if (maxShopCouponNum == Number(limit_val)) {
          this.$set(this.curEditCompeteRewardForm, "coupon_num", limit_val);
        } else {
          this.$set(this.curEditCompeteRewardForm, "coupon_num", new_val);
        }
      },
      (new_val: string) => {
        this.$set(this.curEditCompeteRewardForm, "shop_member_price", new_val);
      },
    )
  }
  /** end 奖金设置弹框 */


  /** 预选赛设置 */
  private handleResetCompeteQualifierListForm(val: number) {
    const {
      league_qualifier_set_list: origin_league_qualifier_set_list
    } = this.form || {}

    console.log(this.form, 'this.form')

    console.log(origin_league_qualifier_set_list, 'origin_league_qualifier_set_list')


    const league_qualifier_set_list = getCompeteQualifierSetFormList(val, origin_league_qualifier_set_list)

    this.form.league_qualifier_set_list = league_qualifier_set_list
  }
  /**end 预选赛设置 */

  /** 比赛规则设置 */
  private previewImages: any[] = [];
  // 上传媒体文件
  private handleUploadULeagueInfoMedia(file: any, fileList: any[]) {
    this.handleUploadMedia(file, fileList, "image");
  }

  // 删除媒体文件
  private handleFileRemove(file: any, mode: string) {
    const form = this.form;

    const images = mode == "image" ? form.league_info_images : [];

    const optionList = images;

    const existRemoveFileIndex = optionList.findIndex(
      (each_file: any) => each_file.uid == file.uid
    );

    if (existRemoveFileIndex > -1) {
      optionList.splice(existRemoveFileIndex, 1);

      this.$set(this.form, `league_info_images`, optionList);
    }
  }

  private async handleUploadMedia(
    file: any,
    fileList: any[],
    mode: string
  ) {
    console.log(file, "file");

    function getVideoDuration(url: string): any {
      return new Promise((resolve, reject) => {
        var audio = new Audio(url);
        audio.muted = true;
        audio.play().then(() => audio.pause());
        // 元数据已加载

        const handleLoadedmetadata = function (e: any) {
          // let duration = audio.duration;
          console.log(e, "e audio");
          resolve({
            url,
            duration: audio.duration,
          });

          hanleClearListeners();
        };

        const hanleClearListeners = function () {
          audio.removeEventListener("loadedmetadata", handleLoadedmetadata);
          audio.onerror = null;
        };
        audio.addEventListener("loadedmetadata", handleLoadedmetadata);

        audio.onerror = function (err) {
          reject(err);
        };

        audio.muted = false;
      });
    }

    const form: any = this.form;

    const optionList = form.league_info_images;

    const sendMediaApi =
      mode == "image" ? this.commonApi.getOss : this.commonApi.getOssvideo;

    if (mode == "video" && !file.duration) {
      const { duration } = await getVideoDuration(file.url).catch(
        (err: any) => {
          this.$message("获取时长失败");
        }
      );

      file.duration = duration;
    }

    optionList.push(file);

    console.log(form, "editForm");

    this.$set(this.form, `league_info_images`, optionList);

    this.changeFilePropByList(file, mode, { uploading: true });
    sendMediaApi.call(
      this.commonApi,
      file instanceof File ? file : file.raw,
      (res: any) => {
        console.log(res, "res 图片上传成功");

        const { data } = res;

        const { data: uploadFileObj } = data || {};

        const { item: uploadFile } = uploadFileObj || {};

        const { id, url } = uploadFile;

        // this.$set(this.editUserForm, 'avatar_id', id)
        // this.$set(this.editUserForm, 'avatar', url)
        const changeProps: any = {
          uploading: false,
          fileId: id,
          uploadUrl: url,
        };

        if (file.duration) {
          changeProps.duration = file.duration.toString();
        }

        this.changeFilePropByList(file, mode, changeProps);
      },
      () => {
        this.$message.error("图片上传失败");

        this.changeFilePropByList(file, mode, {
          uploading: false,
          failed: true,
        });
      }
    );
  }

  private handlePictureCardPreview(file: any, mode: string) {
    const form = this.form;

    const images = mode == "image" ? form.league_info_images : [];


    console.log(file, "file");

    const urls = [
      ...images.map((img: any) => {
        return {
          title: "",
          type: "image/jpeg",
          href: img.url,
          thumbnail: img,
        };
      }),
      // ...videos.map((video: any) => {
      //   return {
      //     title: "",
      //     type: "video/mp4",
      //     href: video.url,
      //     poster: `${video.url}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`,
      //   };
      // }),
    ];

    this.previewImages = urls;
  }

  private changeFilePropByList(file: any, mode: string, changes: any = {}) {
    const form: any = this.form;

    const setList: any[] = form.league_info_images;
    const existFileIndex = setList.findIndex(
      (each_file: any) => each_file.uid == file.uid
    );

    if (existFileIndex > -1) {
      const originFile = setList[existFileIndex];

      if (Array.isArray(setList)) {
        setList.splice(existFileIndex, 1, Object.assign(originFile, changes));
      }

      this.$set(this.form, `league_info_images`, setList);
    }
  }
  /** end 比赛规则设置 */

  /** 展示图上传 */
  private userAvatarUploading: boolean = false;

  private handleUploadLeagueShowPic(file: any, fileList: any[]) {
    console.log(file, fileList, "file", "fileList");

    this.userAvatarUploading = true;
    this.commonApi.getOss(
      file instanceof File ? file : file.raw,
      (res: any) => {
        console.log(res, "res 图片上传成功");

        const { data } = res;

        const { data: callbackData } = data;

        const { item: uploadImgObj } = callbackData || {};

        const { id, url } = uploadImgObj;

        this.$set(this.form, "show_image_id", id);
        this.$set(this.form, "show_image_url", url);

        this.userAvatarUploading = false;
      },
      () => {
        this.$message.error("图片上传失败");

        this.userAvatarUploading = false;
      }
    );
  }

  /** end 展示图上传 */


  /** 店铺搜索 */
  private venueList: any[] = [
    // {
    //   id: 1,
    //   label: '测试'
    // },
    // {
    //   id: 2,
    //   label: '测试2'
    // }
  ];

  private searchVenueLoading: boolean = false;

  private remoteGetVenueQueryString: string = "";

  private remoteSearchVenueTimer: any = null;

  private searchVenuePageOption: any = {};

  private initRemoteGetVenuePageOption() {
    this.searchVenuePageOption = {
      ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    };
  }

  private getSearchVenueParams() {
    const searchVenuePageOption = this.searchVenuePageOption;

    const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

    const { page, limit } = searchVenuePageOption;

    const params: any = {
      page,
      limit,
      shop_type: 1 // 店铺类型 0未知 1台球 2棋牌
    };

    if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
      params.name = remoteGetVenueQueryString.trim();
    }

    return params;
  }

  private getMoreVenueList(refresh: boolean = true) {
    return new Promise((resolve: Function, reject: Function) => {
      if (refresh) {
        this.initRemoteGetVenuePageOption();
      }

      const params = this.getSearchVenueParams();

      this.addOrEditCompetitionApi.searchVenueList(
        params,
        (res: any) => {
          console.log(res, "res");

          const venueList: any[] = res.data || [];

          this.venueList = refresh
            ? venueList
            : this.venueList.concat(venueList);

          if (Array.isArray(res.data) && res.data.length == 0) {
            this.searchVenuePageOption.isRearchBottom = true;
          }
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          resolve();
        },
        () => {
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          reject();
        }
      );
    });
  }

  // 第一次搜索 无关键字
  private handleFirstGetVenueList() {
    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 清空搜索
  private handleClearGetVenueList() {
    this.remoteGetVenueQueryString = "";

    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 远程搜索
  private remoteGetVenueMethod(query: string) {
    query = query.trim ? query.trim() : "";

    if (query !== "") {
      this.remoteGetVenueQueryString = query;
      clearTimeout(this.remoteSearchVenueTimer);

      this.remoteSearchVenueTimer = setTimeout(() => {
        this.searchVenueLoading = true;

        this.getMoreVenueList();
        
      }, 300);
      
    } else {
      this.remoteGetVenueQueryString = "";
      this.venueList = [];
    }
  }

  private addVenueListLoading: boolean = false;

  private remoteAddVenueMethod() {
    if (this.addVenueListLoading) return;
    const searchVenuePageOption = this.searchVenuePageOption;

    const { isRearchBottom } = searchVenuePageOption;

    if (isRearchBottom) return;

    this.searchVenuePageOption.page++;

    this.addVenueListLoading = true;

    this.getMoreVenueList(false);
  }

  /** end 店铺搜索 */

}

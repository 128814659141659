import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

import { CommonApi } from "../common";

export class AddOrEditCompetitionAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['addComplexCompetition', {
      url: '/admin/league/add',
      name: 'add-complex-compettition',
      label: '添加复杂比赛'
    }],

    ['editComplexCompetition', {
      url: '/admin/league/edit',
      name: 'edit-complex-compettition',
      label: '编辑复杂比赛'
    }],
   
    ['getComplexCompetitionDetail', {
      url: '/admin/league/info',
      name: 'complex-compeition-detail',
      label: '复杂比赛详情'
    }],

    ['getComplexCompetitionRuleInfo', {
      url: '/admin/league/ruleList',
      name: 'complex-compeition-rule-info',
      label: '比赛规则信息'
    }],

    ['getSingleCouponList', {
      url: '/admin/voucher/list',
      name: 'single-coupon-list',
      label: '单店优惠券',
      // hidden: true
    }],

    ['searchVenueList', {
      url: '/admin/coupons/shop/list',
      name: 'shop-list',
      label: '店铺列表',
      // hidden: true
    }],

  ])
  // api 方法
  // 添加 复杂比赛
  public addComplexCompetition(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('addComplexCompetition').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
  // 编辑 复杂比赛
  public editComplexCompetition(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('editComplexCompetition').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 复杂比赛 详情
  public getComplexCompetitionDetail(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getComplexCompetitionDetail').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 比赛规则列表
  public getComplexCompetitionRuleInfo(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getComplexCompetitionRuleInfo').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 单店优惠券列表
  public getSingleCouponList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getSingleCouponList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 搜索店铺列表
  public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('searchVenueList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}

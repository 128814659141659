
import { Component, Vue } from "vue-property-decorator";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import WithdrawDepositApi from "@/common/api/financeManage/withdrawDeposit";
import { excuteDownloadExport2Excel } from "@/common/xlsx";


// 状态 1待审核（审核中） 2审核通过、打款成功  3驳回、打款失败  4打款中  6提现异常
const defaultAuditStatus2ch: any = {
  1: "审核中",
  2: "审核通过(打款成功)",
  3: "驳回(打款失败)",
  4: "打款中",
  6: "提现异常"
};

// 查询状态类型
// 提现状态  1交易成功  2交易失败 3交易处理中 4交易退票
const defaultWithdrawQueryStatus2ch: string[] = [
  '',
  '交易成功',
  '交易失败',
  '交易处理中',
  '交易退票'
]

export const pageApiList = getPageAPIs(WithdrawDepositApi)

@Component({
  filters: {
    showWithdrawQueryStatus(status: number) {
      return defaultWithdrawQueryStatus2ch[status] || ''
    }
  }
})
export default class WithdrawDeposit extends Vue {
  private utilInstance: any;
  private withdrawDepositApi: any;

  private commonApi: any;

  // 自动审核 开关  状态 1开启  2未开启  开启后 每天凌晨1：00自动审核
  private isAutoAduit: number = 2


  constructor() {
    super();
    this.withdrawDepositApi = new WithdrawDepositApi();

    // this.commonApi = new CommonApi();

    this.utilInstance = new Util();

    // 封禁时间列表
    // this.all_forbidden_time_list = Util.getFoibiddenTimeList();
  }

  private firstLoading: boolean = true;

  private form: any = {
    // searchKw: "", // 搜索用户昵称/用户ID/手机号
    shop_id: "", // 店铺ID
    name: "", // 店铺昵称

    time: [], // 时间选择
    auditStatus: 1, // 审核状态
  };

  // private show: boolean = false;

  // 状态  0全部  1待审核 2打款成功 3打款失败 4打款中   5提现异常
  private auditStatusList: any[] = [
    {
      value: 0,
      label: "全部",
    },
    {
      value: 1,
      label: "审核中",
    },
    {
      value: 2,
      label: "审核通过",
    },
    {
      value: 3,
      label: "驳回(打款失败)",
    },
    {
      value: 4,
      label: "打款中",
    },
    {
      value: 5,
      label: "提现异常",
    },
  ];

  // private showtimecard: boolean = false;
  private activated(): void {
    this.getTable();
  }
  private getTable(): void {
    this.withdrawDepositApi.getAutoAuditSetting({}, (res: any) => {
      const {
        is_state
      } = res.data || {}

      this.isAutoAduit = is_state
    }, () => {

    })

    this.withdrawDepositApi.getWithdrawTableList(
      this.getUserTableListParams(),
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        const { list, count, page, prepage } = data || {};

        const newTableList = Array.isArray(list)
          ? list.map(this.handleTableData)
          : [];

        // totalCount: 0,
        // pageNo: 1,
        // pageSize: 10,
        this.$set(this.tablePage, "totalCount", count);
        this.$set(this.tablePage, "pageNo", page);
        this.$set(this.tablePage, "pageSize", prepage);

        this.tableData = newTableList;

        this.firstLoading = false;
      },
      () => {
        this.firstLoading = false;
      }
    );
  }

  private getUserTableListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const firstLoading = this.firstLoading;

    const {
      shop_id, // 店铺ID
      name: shop_name, // 店铺昵称

      time, // 时间选择
      auditStatus, // 审核状态
    } = form || {};

    // console.log(technicianItem, "technicianItem");

    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};

    if (shop_id) {
      outerParams.shop_id = shop_id;
    }

    if (shop_name) {
      outerParams.name = shop_name;
    }

    if (auditStatus) {
      outerParams.is_status = auditStatus;
    }

    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      outerParams.start_date = startTime;

      outerParams.end_date = endTime;
    }

    if (
      mode == "exportExcel" &&
      !(outerParams.start_date && outerParams.end_date)
    ) {
      this.$message.error("请选择时段导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      outerParams.__isUnique = !firstLoading;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  // 后台返回的字段 转化为前端定义的字段
  private handleTableData(data: any): any {
    const {
      // userId,
      // gender,
      identity,
      money,
      actual_money: actualMoney,
      surplus_money: surplusMoney,
      withdrawal_account: withdrawalAccount,
      realname,
      created_at: ctime,
      examine_time: examineTime,
      examine_user: examineUser,
      is_status: isStatus,
      // activeTime,
      // nickname,
      // avatarUrl,
      // mobile,
      // forbidTime,
    } = data;
    const auditStatus = defaultAuditStatus2ch[isStatus] || "未知审核状态";
    const newData = {
      ...data,
      identify: identity,
      withdrawMoney: money,
      actualMoney,
      restWithdrawMoney: surplusMoney,
      withDrawAccount: withdrawalAccount,
      alipayActualName: realname,
      withdrawTime: ctime,
      auditTime: examineTime,
      auditOpUser: examineUser,
      auditStatus,
    };


    return newData;
  }

  private isShowAgreeBtn(row: any) {
    const { is_status: isStatus } = row || {};

    return isStatus == 1;
  }

  private isShowDisagreeBtn(row: any) {
    const { is_status: isStatus } = row || {};

    return isStatus == 1;
  }

  private handleRefreshAuditSetting(val: number) {
    console.log(val, 'val')

    this.withdrawDepositApi.updateAutoAuditSetting({
      is_state: val
    }, () => {
      this.$message.success('自动审核开关更改成功')
    })
  }

  // 修改审核状态
  private handleChangeAuditStatus(withdrawItem: any, mode: string) {
    const mode2MessageTip: any = {
      agree: "通过",
      disagree: "驳回",
    };
    this.$confirm(`是否确定${mode2MessageTip[mode]}?`, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      // type: "warning",
    }).then(() => {
      switch (mode) {
        case "agree":
          set(
            this,
            this.tableData,
            withdrawItem,
            "__changeAuditStatusAgreeLoading",
            true
          );

          this.withdrawDepositApi.getAuditAgree(
            {
              id: withdrawItem.id,
            },
            () => {
              set(
                this,
                this.tableData,
                withdrawItem,
                "__changeAuditStatusAgreeLoading",
                false
              );
              this.getTable();
            },
            () => {
              set(
                this,
                this.tableData,
                withdrawItem,
                "__changeAuditStatusAgreeLoading",
                false
              );
            }
          );
          break;
        case "disagree":
          set(
            this,
            this.tableData,
            withdrawItem,
            "__changeAuditStatusDisagreeLoading",
            true
          );

          this.withdrawDepositApi.getAuditDisagree(
            {
              id: withdrawItem.id,
            },
            () => {
              set(
                this,
                this.tableData,
                withdrawItem,
                "__changeAuditStatusDisagreeLoading",
                false
              );
              this.getTable();
            },
            () => {
              set(
                this,
                this.tableData,
                withdrawItem,
                "__changeAuditStatusDisagreeLoading",
                false
              );
            }
          );
          break;
      }
    });

    function set<T>(vm: any, list: T[], item: T, key: string, val: any) {
      const existIndex = list.findIndex((eachItem) => eachItem == item);

      if (existIndex > -1) {
        if (item && typeof item == "object" && !Array.isArray(item))
          vm.$set(list[existIndex], key, val);
      }
    }
  }

  private dialogFormVisible: boolean = false

  private withdrawQueryInfo: any = {}

  private withdrawManualSetLoading: boolean = false

  private withdrawManualState:number = 0

  // 提现状态查询
  private handleWakeQueryMoneyFlow(withdrawItem: any) {
    console.log(withdrawItem, 'withdrawItem')
    set(
    this,
    this.tableData,
    withdrawItem,
    "__moneyTransferQueryLoading",
      true
    );


    this.withdrawDepositApi.getWithdrawQueryInfo({
      id: withdrawItem.id
    }, (res: any) => {
      set(
        this,
        this.tableData,
        withdrawItem,
        "__moneyTransferQueryLoading",
        false
      );

      const {
        data: withdrawQueryInfo
      } = res || {}

      this.withdrawQueryInfo = withdrawQueryInfo

      this.dialogFormVisible = true
    }, () => {
      set(
        this,
        this.tableData,
        withdrawItem,
        "__moneyTransferQueryLoading",
          false
      );
    })

    function set<T>(vm: any, list: T[], item: T, key: string, val: any) {
      const  existIndex = list.findIndex((eachItem) => eachItem == item);

      if (existIndex > -1) {
        if (item && typeof item == "object" && !Array.isArray(item))
          vm.$set(list[existIndex], key, val);
      }
    }
  }

  private handleWithdrawSetSuccess(queryInfo: any, state: number) {
    this.withdrawManualState = state
    const params: any = {
      id: queryInfo.id,
      state
    }

    this.withdrawManualSet(params)
  }

  private handleWithdrawSetFail(queryInfo: any, state: number) {
    this.withdrawManualState = state
    const params: any = {
      id: queryInfo.id,
      state
    }

    this.withdrawManualSet(params)
  }

  private withdrawManualSet(params: any) {
    this.withdrawManualSetLoading = true
    this.withdrawDepositApi.withdrawManualSetStatus(params, () => {
      this.$message.success('标记状态完成')

      this.withdrawManualSetLoading = false

      this.dialogFormVisible = false

      this.getTable()
    }, () => {
      this.withdrawManualSetLoading = false
    })
  }


  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 用户字段名 文案对应关系
  private userExcelList2ch: any = {
    shop_id: "店铺ID",
    name: "店铺昵称",
    mobile: "手机号码",
    money: "提现金额（元）",
    fee_money: "手续费",
    actual_money: "实际到账金额",
    realname: "用户名",
    bank_name: "银行",
    province_city: "省市",
    branch_name: "支行",
    bank_card: "银行卡号",
    created_at: "提现时间",
    examine_time: "审核时间",
    examine_user: "审核人",
    auditStatus: "打款状态",
  };
  // 用户Excel 表头的先后顺序
  private userExcelSortList: string[] = [
    "shop_id",
    "name",
    "mobile",
    "money",
    "fee_money",
    "actual_money",
    "realname",
    "bank_name",
    "province_city",
    "branch_name",
    "bank_card",
    "created_at",
    "examine_time",
    "examine_user",
    "auditStatus",
  ];

  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getUserTableListParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.withdrawDepositApi.exportExcelWithdrawList(
      params,
      (res: any) => {
        const { start_date, end_date } = params || {};

        const filename = `提现列表 ${start_date}到${end_date}.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const { is_status: isStatus, mobile } = item;
              const newItem = {
                ...(item || {}),
              };

              if (isStatus) {
                newItem.auditStatus =
                  defaultAuditStatus2ch[isStatus] || "未知审核状态";
              }

              if (typeof mobile == "number") {
                newItem.mobile = mobile.toString();
              }

              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const userExcelSortList = this.userExcelSortList;

          const userExcelList2ch = this.userExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            userExcelSortList,
            userExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */

  private restForm(): void {
    this.form = this.utilInstance.clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }
}

import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class CompeitionPointAdjustRecordAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getCompeitionPointRecordList', {
      url: '/admin/league/scoreRecordList',
      name: 'list',
      label: '列表'
    }],
    ['addCompeitionPoint', {
      url: '/admin/league/addScoreRecord',
      name: 'add',
      label: '添加'
    }],
 
   
  ])

  // 赛事积分列表
  public getCompeitionPointRecordList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getCompeitionPointRecordList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }


  // 添加赛事积分
  public addCompeitionPoint(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('addCompeitionPoint').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  
}
/** 注意： meta  key 一旦确定不可修改 且 同级唯一。 是作为识别这个对象的唯一标识！！！   */

// 熊猫客服
export const customerServiceRoutes = [
  {
    path: '/robotSettingList',
    name: 'robotSettingList',
    meta: {
      title: "机器人设置",
      key: 'robot-setting-list',
      pageApisPrm: import("@/views/customerService/robotSettingList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "customerService" */ "@/views/customerService/robotSettingList.vue"
      ),
  },
  // servicedRecord
  {
    path: '/servicedRecord',
    name: 'servicedRecord',
    meta: {
      title: "客服服务记录",
      key: 'serviced-record-list',
      pageApisPrm: import("@/views/customerService/servicedRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "customerService" */ "@/views/customerService/servicedRecord.vue"
      ),
  },
  {
    path: '/quickReplyManage',
    name: 'quickReplyManage',
    meta: {
      title: "快捷回复管理",
      key: 'quick-reply-manage',
      pageApisPrm: import("@/views/customerService/quickReplyManage.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "customerService" */ "@/views/customerService/quickReplyManage.vue"
      ),
  },
]

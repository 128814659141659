import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class CoachLeaderWithdrawDepositAuditAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getList', {
            url: '/admin/trainerWithdraw/lists',
            name: 'list',
            label: '列表'
        }],
        ['exportCoachLeaderWithdrawDepositAuditList', {
            url: '/admin/trainerWithdraw/export',
            name: 'export-excel',
            label: '导出'
        }],
        ['updateWithdrawAccountAuditPassStatus',  {
            url: '/admin/trainerWithdraw/approved',
            name: 'pass-status',
            label: '审核通过'
        }],
        ['updateWithdrawAccountAuditRejectStatus',  {
            url: '/admin/trainerWithdraw/reject',
            name: 'reject-status',
            label: '审核拒绝'
        }],

        ['getWithdrawQueryInfo', {
            url: '/admin/trainerWithdraw/query',
            name: 'withdraw-query',
            label: '查询提现'
        }],
        ['batchAuditAgree', {
            url: '/admin/trainerWithdraw/approvedAll',
            name: 'batch-pass-status',
            label: '批量审核通过'
        }],
        ['withdrawManualSetStatus', {
            url: '/admin/trainerWithdraw/mark',
            name: 'withdraw-manual-set',
            label: '手动设置提现状态'
        }],
    ])
    
    // 助教队长提现审核列表
    public getList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 导出助教队长提现审核列表 
    public exportCoachLeaderWithdrawDepositAuditList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('exportCoachLeaderWithdrawDepositAuditList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 助教队长提现审核状态更新
    public updateWithdrawAccountAuditStatus(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        
        const urlModes: any = {
            'agree': apiUrls.get('updateWithdrawAccountAuditPassStatus').url,
            'disagree': apiUrls.get('updateWithdrawAccountAuditRejectStatus').url,
        }

        let url: string = urlModes[params.mode]

        delete params.mode

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading);
    }

    // 批量审核通过
    public batchAuditAgree(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('batchAuditAgree').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 提现查询
    public getWithdrawQueryInfo(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getWithdrawQueryInfo').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 提现查询手动标记
    public withdrawManualSetStatus(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('withdrawManualSetStatus').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}
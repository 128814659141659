
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import { PlatformCouponsRecord } from "@/common/api/couponManage/platformCouponsRecord";
import { excuteDownloadExport2Excel } from "@/common/xlsx";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import ZlSelect from "@/components/third-custom-components/select";
import ZlOption from "@/components/third-custom-components/option";

// 暴露的API 列表
export const pageApiList = getPageAPIs(PlatformCouponsRecord);

console.log(pageApiList, 'pageApiList')

const defaultForm = {
  phone: "", // 手机号

  order_no: "", // 券码

  shop_name: "", // 店铺名称

  time: []
}

const defaultForms = {

};

const defaultSearchVenuePageOption = {
  page: 1,
  limit: 10,
  isRearchBottom: false,
};

@Component({
  components: {
    ZlSelect,
    ZlOption,
  },
})
export default class CouponsManage extends Vue {

  private form: any = {

  };
  private forms: any = {

  };

  private deepLoopUtil: any = {};


  private PlatformCouponsRecord = new PlatformCouponsRecord();
  // private couponTimeDurationAPI = new CouponTimeDurationManageAPI();
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };
  }

  private created(): void {
    this.getList();

  }
  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
    this.getList();
  }

  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getList();
  }

  private tableData: any[] = [];



  private remoteGetVenueQueryString: string = "";


  /** 下拉搜索 */
  private venueList: any[] = [
    // {
    //   id: 1,
    //   label: '测试'
    // },
    // {
    //   id: 2,
    //   label: '测试2'
    // }
  ];

  private searchVenueLoading: boolean = false;

  private remoteGetVenueQueryString: string = "";

  private remoteSearchVenueTimer: any = null;

  private searchVenuePageOption: any = {};

  private initRemoteGetVenuePageOption() {
    this.searchVenuePageOption = {
      ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    };
  }

  private getSearchVenueParams() {
    const searchVenuePageOption = this.searchVenuePageOption;

    const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

    const { page, limit } = searchVenuePageOption;

    const params: any = {
      page,
      limit,
    };

    if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
      params.name = remoteGetVenueQueryString.trim();
    }

    return params;
  }

  private getMoreVenueList(refresh: boolean = true) {
    return new Promise((resolve: Function, reject: Function) => {
      if (refresh) {
        this.initRemoteGetVenuePageOption();
      }

      const params = this.getSearchVenueParams();

      this.PlatformCouponsRecord.searchVenueList(
          params,
          (res: any) => {
            console.log(res, "res");

            const venueList: any[] = res.data || [];

            this.venueList = refresh
                ? venueList
                : this.venueList.concat(venueList);

            if (Array.isArray(res.data) && res.data.length == 0) {
              this.searchVenuePageOption.isRearchBottom = true;
            }
            this.searchVenueLoading = false;

            this.addVenueListLoading = false;

            resolve();
          },
          () => {
            this.searchVenueLoading = false;

            this.addVenueListLoading = false;

            reject();
          }
      );
    });
  }

  // 第一次搜索 无关键字
  private handleFirstGetVenueList() {
    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 清空搜索
  private handleClearGetVenueList() {
    this.remoteGetVenueQueryString = "";

    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 远程搜索
  private remoteGetVenueMethod(query: string) {
    query = query.trim ? query.trim() : "";

    if (query !== "") {
      this.remoteGetVenueQueryString = query;
      clearTimeout(this.remoteSearchVenueTimer);

      this.remoteSearchVenueTimer = setTimeout(() => {
        this.searchVenueLoading = true;

        this.getMoreVenueList();
        // searchStu((res) => {
        //   const {
        //     students,
        //     total_count
        //   } = res || {}

        //   this.remoteAllStudents = [
        //     ...(students || [])
        //   ]

        //   this.remoteStudentTotalCount = total_count || 0

        //   this.remoteGetStudentLoading = false
        // }, {
        //   kw: query,
        //   ...this.getRemoteGetStudentPageOptionParams()
        // }, () => {
        //   this.remoteGetStudentLoading = false
        // })
      }, 300);
      // setTimeout(() => {
      //   this.remoteGetStudentLoading = false;
      //   this.options = this.list
      // }, 200);
    } else {
      this.remoteGetVenueQueryString = "";
      this.venueList = [];
    }
  }

  private addVenueListLoading: boolean = false;

  private remoteAddVenueMethod() {
    if (this.addVenueListLoading) return;
    const searchVenuePageOption = this.searchVenuePageOption;

    const { isRearchBottom } = searchVenuePageOption;

    if (isRearchBottom) return;

    this.searchVenuePageOption.page++;

    this.addVenueListLoading = true;

    this.getMoreVenueList(false);
  }

  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getList();
  }

  //获取优惠券列表
  private getList(): void {
    this.PlatformCouponsRecord.getList(
      { ...this.getCouponVertifyCodeRecordParams() },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page;
        this.tablePage.prepage = res.data.prepage;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private getCouponVertifyCodeRecordParams(mode = "all"): any {
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    const {
      venue,
      phone,
      order_no,
      time,
    } = form;


    if (venue && venue.id) {
      params.shop_id = venue.id
    }

    if (phone) {
      params.phone = phone
    }

    if (order_no) {
      params.order_no = order_no
    }



    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      const startTime = startDate;
      const endTime = endDate;

      params.start_time = startTime;

      params.end_time = endTime;
    }

    if (
      mode == "exportExcel" &&
      !(params.start_time && params.end_time)
    ) {
      this.$message.error("请选择核销时段导出数据");
      return null;
    }

    if (mode === "all") {
      params.page = page;

      params.limit = prepage;

    }

    return params;
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 订单字段名 文案对应关系
  private orderExcelList2ch: any = {
    order_no: "订单编号",
    phone: "手机号",
    name: "卡券名称",
    shop_name: "消费店铺",
    subsidy_amount: "金额",
    use_time: "使用时间"
  };
  // 订单Excel 表头的先后顺序
  private orderExcelSortList: string[] = Object.keys(this.orderExcelList2ch)

  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getCouponVertifyCodeRecordParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.PlatformCouponsRecord.exportList(
      params,
      (res: any) => {
        const {
          start_time,
          end_time,
        } = params || {};

        const filename = `团购券核销记录列表 ${start_time}到${end_time}.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const {} = item || {};
              const newItem = {
                ...(item || {}),
              };

              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const orderExcelSortList = this.orderExcelSortList;

          const orderExcelList2ch = this.orderExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            orderExcelSortList,
            orderExcelList2ch
          );


          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

    

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };
}

import { BasicsClass } from '@/common/BasicsClass';
export class OpenShopOrderListAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getOpenSOrderList', {
            url: '/admin/marketOrder/list',
            name: 'list',
            label: '列表'
        }],
        ['addOpenSOrderList', {
            url: '/admin/marketOrder/add',
            name: 'add',
            label: '添加'
        }],
        ['updateOpenSOrderList', {
            url: '/admin/marketOrder/edit',
            name: 'edit',
            label: '编辑'
        }],
        ['exportExcelOpenSOrderList', {
            url: '/admin/marketOrder/exportList',
            name: 'export-excel',
            label: '导出'
        }],
        ['importFileInfo', {
            url: '/admin/marketOrder/import',
            name: 'import-file-info',
            label: '导入上传文件',
        }],

        // ['deleteOpenSOrderListItem', {
        //     url: '/admin/coupons/delete',
        //     name: 'delete',
        //     label: '删除'
        // }],
       
        ['getPoolTypeList', {
            url: '/admin/marketPool/allPool',
            name: 'pool-type-list',
            label: '球桌类型列表',
            // hidden: true
        }],
       
    ])


    //新增市场部开店订单
    public addOpenSOrderList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('addOpenSOrderList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    //修改市场部开店订单
    public updateOpenSOrderList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('updateOpenSOrderList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    //市场部开店订单列表
    public getOpenSOrderList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getOpenSOrderList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 导出市场部开店订单
    public exportExcelOpenSOrderList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('exportExcelOpenSOrderList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    // 删除 市场部开店订单
    // public deleteOpenSOrderListItem(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    //     const apiUrls = this.apiUrls

    //     const url = apiUrls.get('deleteOpenSOrderListItem').url

    //     this.BasicPost(url, {
    //         ...params
    //     }, false, false, false, successCb, failCb, showLoading)
    // }

    // 导入上传文件
    public importFileInfo(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('importFileInfo').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }


    // 球桌型号列表
    public getPoolTypeList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getPoolTypeList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

 
}
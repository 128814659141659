import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

import { CommonApi } from '../common';

export class RechargeRecordAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['storeList', {
      url: '/admin/shop/lists',
      name: 'list',
      label: '列表'
    }],
    ['storeview', {
      url: '/admin/shop/view',
      name: 'info',
      label: '店铺详情',
      // hidden: true
    }],
    ['savestore', {
      url: '/admin/shop/save',
      name: 'savestore',
      label: '编辑店铺'
    }],
    // ['mtshop', {
    //   url: '/admin/mt/shop',
    //   name: 'mtshop',
    //   label: '美团北极星授权店铺地址获取',
    //   // hidden: true
    // }],
    ['aqauth', {
      url: '/admin/aqara/auth',
      name: 'aqauth',
      label: '获取已绑定aqara账号',
      // hidden: true
    }],

    ['sendVertifyCode', {
      url: '/admin/aqara/send',
      name: 'send-aqauth-code',
      label: '发送绿米授权验证码',
      // hidden: true
    }],
    ['authVertifyCode', {
      url: '/admin/aqara/code',
      name: 'auth-aqauth-code',
      label: '手机号授权绿米',
    }],

    ['getShopWithdrawMobileInfo', {
      url: '/admin/withdraw/mobile',
      name: 'shop-withdraw-mobile',
      label: '提现手机号信息',
    }],

    ['updateShopWithdrawMobileInfo', {
      url: '/admin/withdraw/saveMobile',
      name: 'edit-shop-withdraw-mobile',
      label: '修改提现手机号信息',
    }],


    ['getShopMonitorList', {
      url: '/admin/shopMonitor/index',
      name: 'shop-monitor-list',
      label: '店铺录像设备列表',
    }],
    ['addShopMonitor', {
      url: '/admin/shopMonitor/add',
      name: 'add-shop-monitor',
      label: '添加店铺录像设备',
    }],
    ['editShopMonitor', {
      url: '/admin/shopMonitor/edit',
      name: 'edit-shop-monitor',
      label: '编辑店铺录像设备',
    }],
    ['deleteShopMonitor', {
      url: '/admin/shopMonitor/del',
      name: 'delete-shop-monitor',
      label: '删除店铺录像设备',
    }],

    ['getSpeakerMonitorList', {
      url: '/admin/showShopMonitor/index',
      name: 'speaker-monitor-list',
      label: '精彩秀录像设备列表',
    }],
    ['addSpeakerMonitor', {
      url: '/admin/showShopMonitor/add',
      name: 'add-speaker-monitor',
      label: '添加精彩秀录像设备',
    }],
    ['editSpeakerMonitor', {
      url: '/admin/showShopMonitor/edit',
      name: 'edit-speaker-monitor',
      label: '编辑精彩秀录像设备',
    }],
    ['deleteSpeakerMonitor', {
      url: '/admin/showShopMonitor/del',
      name: 'delete-speaker-monitor',
      label: '删除精彩秀录像设备',
    }],

    ['searchKsShopAuthList', {
      url: '/admin/kuaishou/merchantList',
      name: 'search-ks-shop-auth-list',
      label: '快手商家授权ID列表',
    }],

    ['getKsOfflineShopList', {
      url: '/admin/kuaishou/poiQuery',
      name: 'ks-offline-shop-list',
      label: '快手商家线下门店列表',
    }],



    ['getPoolTableList', {
      url: '/admin/poolTable/allLists',
      name: 'pool-air-device-list',
      label: '球桌列表',
    }],

  ])
  // 店铺列表
  public storeList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const start_date = Util.handleStartTimeAndEndTime(params.time).startTime;
    const end_date = Util.handleStartTimeAndEndTime(params.time).endTime;

    params = {
      ...params,
      start_date: start_date,
      end_date: end_date
    }

    const apiUrls = this.apiUrls

    const url = apiUrls.get('storeList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 店铺详情
  public storeview(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('storeview').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 编辑店铺
  public savestore(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('savestore').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
  // 美团北极星授权店铺地址获取
  // public mtshop(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
  //   const apiUrls = this.apiUrls

  //   const url = apiUrls.get('mtshop').url

  //   this.BasicPost(url, {
  //     ...params
  //   }, false, false, false, successCb, failCb, showLoading)
  // }
  // 获取已绑定aqara账号
  public aqauth(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('aqauth').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  public sendVertifyCode(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('sendVertifyCode').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  public authVertifyCode(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('authVertifyCode').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 提现手机号信息
  public getShopWithdrawMobileInfo(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getShopWithdrawMobileInfo').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 修改提现手机号信息
  public updateShopWithdrawMobileInfo(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('updateShopWithdrawMobileInfo').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  public getShopMonitorList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getShopMonitorList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  public addShopMonitor(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('addShopMonitor').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
  public editShopMonitor(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('editShopMonitor').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  public deleteShopMonitor(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('deleteShopMonitor').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 精彩秀录像设备列表
  public getSpeakerMonitorList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getSpeakerMonitorList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 添加精彩秀录像设备
  public addSpeakerMonitor(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('addSpeakerMonitor').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 编辑精彩秀录像设备
  public editSpeakerMonitor(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('editSpeakerMonitor').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 删除精彩秀录像设备
  public deleteSpeakerMonitor(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('deleteSpeakerMonitor').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 快手商家授权ID列表
  public searchKsShopAuthList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('searchKsShopAuthList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 快手商家线下门店列表
  public getKsOfflineShopList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getKsOfflineShopList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  public getPoolTableList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getPoolTableList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}
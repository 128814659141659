import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class AllianceBusinessListAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getAllianceBusinessList', {
      url: '/admin/coach/manage/allianceList',
      name: 'list',
      label: '列表'
    }],
    ['bindAllianceBusinessShop', {
      url: '/admin/coach/manage/allianceBind',
      name: 'bind-shop',
      label: '绑定加盟商店铺'
    }],
   
    ['unbindAllianceBusinessShop', {
      url: '/admin/coach/manage/allianceUnbind',
      name: 'unbind-shop',
      label: '解绑加盟商店铺'
    }],
    ['searchVenueList', {
      url: '/admin/coupons/shop/list',
      name: 'shop-list',
      label: '店铺列表',
      // hidden: true
    }],
    // ['exportExcelStoragelockerList', {
    //   url: '/admin/shopBox/export',
    //   name: 'export-excel',
    //   label: '导出',
    // }],
  ])

  // 加盟商列表
  public getAllianceBusinessList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getAllianceBusinessList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 绑定加盟商店铺
  public bindAllianceBusinessShop(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('bindAllianceBusinessShop').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 解绑加盟商店铺
  public unbindAllianceBusinessShop(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('unbindAllianceBusinessShop').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }


  // 搜索店铺列表
  public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('searchVenueList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  
}
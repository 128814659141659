
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList,getPageAPIs } from "@/common/util";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import {MerchantCouponAPI} from "@/common/api/financeManage/merchantCouponSaleRecord";

import ZlSelect from "@/components/third-custom-components/select";

import ZlOption from "@/components/third-custom-components/option";

import { excuteDownloadExport2Excel } from "@/common/xlsx";
export const pageApiList = getPageAPIs(MerchantCouponAPI);

import {
  shareConfirmOptions
} from '@/common/config'

// 店铺搜索
const defaultSearchVenuePageOption = {
  page: 1,
  limit: 10,
  isRearchBottom: false,
};

const defaultForm = {
  venue_id: "", // 店铺名称
  venue: {
    id: "",
    name: "",
  },
  // shop_name: '', // 店铺名称
  order_no: "", // 订单编号
  status: -1, // 状态
  // order_id: "", // 订单ID
  // user_id: "", // 用户ID
  phone: '', // 手机号
  time: [], // 日期
  // listSortType: -1, // 顾客订单状态
  
  // orderPlaformType: -1, // 类别
  // user_mobile: "", // 用户手机号
  // coach_id: "", // 助教ID
  // channelId: "",
  // shop_name: "", // 店铺名称
  // create_time: [], // 创建时间
  // finish_time: [], // 完成时间
};

// 状态：1 提交订单 2 已付款 3 已取消 4已退款 5退款中
const orderStatus2ch: string[] = [
  '',
  '提交订单',
  '已支付',
  '已取消',
  '已退款',
  '退款中'
]

@Component({
  filters: {
    // getStatusName(status: number) {
    //   return userOrderStatus2ch[status] || "";
    // },
    // getCoachLevelName(level: number) {
    //   return coachLevel2Type[level] || "";
    // },
    getOrderStatusText(status: number): string {
      return orderStatus2ch[status] || ''
    }
  },
  components: {
    ZlSelect,
    ZlOption,
  },
})
export default class OrderList extends Vue {
  private merchantCouponApi: any;

  private deepLoopUtil: any;

  private orderStatusList: any[] = [
    {
      label: '全部',
      value: -1,
    },
    {
      label: '已支付',
      value: 2
    },
    {
      label: '已取消',
      value: 3
    },
    {
      label: '已退款',
      value: 4
    },
    {
      label: '退款中',
      value: 5
    }
  ]

  constructor() {
    super();
    this.merchantCouponApi = new MerchantCouponAPI();

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
  }

  private form: any;

  private firstLoading: boolean = true;

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

 

  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    const params: any = this.getCouponRecordListParams();
    this.merchantCouponApi.getCouponRecordList(
      params,
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        const { list, count: total, page, prepage } = data || {};

        const newTableList = Array.isArray(list)
          ? list.map(this.handleTableData)
          : [];

 
        this.$set(this.tablePage, "totalCount", total);
        this.$set(this.tablePage, "pageNo", params.page);
        this.$set(this.tablePage, "pageSize", params.limit);

        this.tableData = newTableList;

        this.firstLoading = false;
      },
      () => {
        this.firstLoading = false;
      },
      false
    );
  }

  private getCouponRecordListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const firstLoading = this.firstLoading;

    const {
      venue,
      order_no,
      time,
      status,
      phone
    } = form || {};


    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};

    // if (shop_name) {
    //   outerParams.shop_name = shop_name;
    // }

    if ((venue && venue.id)) {
      outerParams.shop_id = venue.id
    } 


    if (order_no) {
      outerParams.order_no = order_no;
    }

    if (phone) {
      outerParams.phone = phone;
    }

    if (status !== -1 && typeof status == 'number') {
      outerParams.status = status
    }

    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      console.log(time, "create_time");

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      outerParams.start_date = startTime;

      outerParams.end_date = endTime;
    }

    const route = this.$route;

    const { query } = route;

    const { userId } = query;

    if (userId) {
      outerParams.userId = userId;
    }

    if (
      mode == "exportExcel" &&
      !(outerParams.start_date && outerParams.end_date)
    ) {
      this.$message.error("请选择日期导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      outerParams.__isUnique = !firstLoading;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  private getOrderSpendMoney(row: any) {
    const { price, refunded_price } = row;

    return (price - refunded_price).toFixed(2);
  }

  

  // 后台返回的字段 转化为前端定义的字段
  private handleTableData(data: any): any {
    // const { device, pay_type, user_type } = data;
    const newData = {
      ...data,
      // device_name: orderDeviceType2ch[device],
      // pay_type_name: payType2ch[pay_type],
      // user_level_type_name: userLevel2ch[user_type],
    };

    return newData;
  }

  private handleRefundMerchantCouponChargeDialog(merchantCouponSaleItem: any) {
    const set = Util.setListProp

    console.log(merchantCouponSaleItem, 'merchantCouponSaleItem')
    this.$confirm("确定退款该畅打券?", "", {
      ...shareConfirmOptions,
    }).then(() => {
      set(
        this,
        this.tableData,
        merchantCouponSaleItem,
        "__refundMoneyLoading",
        true
      );

      this.merchantCouponApi.refundMerchantCouponCharge({
        id: merchantCouponSaleItem.id
      }, () => {
        set(
          this,
          this.tableData,
          merchantCouponSaleItem,
          "__refundMoneyLoading",
          false
        );
        this.getTable()
      }, () => {
        set(
          this,
          this.tableData,
          merchantCouponSaleItem,
          "__refundMoneyLoading",
          false
        );
      })
    });
  }

  /** 店铺搜索 */
  private venueList: any[] = [
    // {
    //   id: 1,
    //   label: '测试'
    // },
    // {
    //   id: 2,
    //   label: '测试2'
    // }
  ];

  private searchVenueLoading: boolean = false;

  private remoteGetVenueQueryString: string = "";

  private remoteSearchVenueTimer: any = null;

  private searchVenuePageOption: any = {};

  private initRemoteGetVenuePageOption() {
    this.searchVenuePageOption = {
      ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    };
  }

  private getSearchVenueParams() {
    const searchVenuePageOption = this.searchVenuePageOption;

    const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

    const { page, limit } = searchVenuePageOption;

    const params: any = {
      page,
      limit,
      shop_type: 1 // 店铺类型 0未知 1台球 2棋牌
    };

    if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
      params.name = remoteGetVenueQueryString.trim();
    }

    return params;
  }

  private getMoreVenueList(refresh: boolean = true) {
    return new Promise((resolve: Function, reject: Function) => {
      if (refresh) {
        this.initRemoteGetVenuePageOption();
      }

      const params = this.getSearchVenueParams();

      this.merchantCouponApi.searchVenueList(
        params,
        (res: any) => {
          console.log(res, "res");

          const venueList: any[] = res.data || [];

          this.venueList = refresh
            ? venueList
            : this.venueList.concat(venueList);

          if (Array.isArray(res.data) && res.data.length == 0) {
            this.searchVenuePageOption.isRearchBottom = true;
          }
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          resolve();
        },
        () => {
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          reject();
        }
      );
    });
  }

  // 第一次搜索 无关键字
  private handleFirstGetVenueList() {
    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 清空搜索
  private handleClearGetVenueList() {
    this.remoteGetVenueQueryString = "";

    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 远程搜索
  private remoteGetVenueMethod(query: string) {
    query = query.trim ? query.trim() : "";

    if (query !== "") {
      this.remoteGetVenueQueryString = query;
      clearTimeout(this.remoteSearchVenueTimer);

      this.remoteSearchVenueTimer = setTimeout(() => {
        this.searchVenueLoading = true;

        this.getMoreVenueList();
        
      }, 300);
      
    } else {
      this.remoteGetVenueQueryString = "";
      this.venueList = [];
    }
  }

  private addVenueListLoading: boolean = false;

  private remoteAddVenueMethod() {
    if (this.addVenueListLoading) return;
    const searchVenuePageOption = this.searchVenuePageOption;

    const { isRearchBottom } = searchVenuePageOption;

    if (isRearchBottom) return;

    this.searchVenuePageOption.page++;

    this.addVenueListLoading = true;

    this.getMoreVenueList(false);
  }

  /** end 店铺搜索 */

  

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 订单字段名 文案对应关系
  private orderExcelList2ch: any = {
    order_no: "订单编号",
    shop_name: "店铺名称",
    user_name: "用户昵称",
    phone: "用户手机号",
    coupon_name: "卡券名称",
    sale_price: "卡券价格(元)",
    _s_icon_price: "钻石抵扣(元)",
    pay_time: "付款时间",
    price: "实付(元)",
    __status: "状态"
  };
  // 订单Excel 表头的先后顺序
  private orderExcelSortList: string[] = [
    "order_no",
    "shop_name",
    "user_name",
    "phone",
    "coupon_name",
    "sale_price",
    "_s_icon_price",
    "pay_time",
    "price",
    "__status"
  ];

  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getCouponRecordListParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.merchantCouponApi.exportExcelOrderList(
      params,
      (res: any) => {
        const {
          start_date,
          end_date
        } = params || {};

        const filename = `畅打券销售记录列表 ${start_date}至${end_date}.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const {
                // user_type,
                // price,
                // refunded_price,
                // device,
                // pay_type,
                // // daytime_minute,
                // // night_minute,
                // status,
                // level,
                icon_price,
                status
              } = item || {};

              const newItem = {
                ...(item || {}),
                // device_name: orderDeviceType2ch[device],
                // pay_type_name: payType2ch[pay_type],
                // // total_miniute: daytime_minute + night_minute,
                // actual_price: price - refunded_price || "",
                // user_level_type_name: userLevel2ch[user_type],
                // status_name: userOrderStatus2ch[status],
                // level_name: coachLevel2Type[level],
                _s_icon_price: `-${icon_price}`,
                __status: orderStatus2ch[status]
              };

              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const orderExcelSortList = this.orderExcelSortList;

          const orderExcelList2ch = this.orderExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            orderExcelSortList,
            orderExcelList2ch
          );

          // console.log(newList, "newList");

          // console.log(excelJSONData, "excelJSONData");

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */

  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
    // this.form = new Util().clearObject(this.form);
    // this.$router.replace("/orderList");
    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}

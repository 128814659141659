import { BasicsClass } from '@/common/BasicsClass';
export class CouponsHourManageAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getHourCoupons', {
            url: '/admin/couponsPlatform/list',
            name: 'list',
            label: '列表'
        }],
        ['addHourCoupons', {
            url: '/admin/couponsPlatform/add',
            name: 'add',
            label: '添加'
        }],
        ['updateHourCoupons', {
            url: '/admin/couponsPlatform/edit',
            name: 'edit',
            label: '编辑'
        }],
        ['delteHourCouponItem', {
            url: '/admin/couponsPlatform/delete',
            name: 'delete',
            label: '删除'
        }],
        ['poolTableCategory', {
            url: '/admin/poolTableCategory/list',
            name: 'pool-category',
            label: '台桌/包厢类型',
            // hidden: true
        }],
    ])


    // 列表
    public getHourCoupons(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getHourCoupons').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 新增
    public addHourCoupons(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('addHourCoupons').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 修改
    public updateHourCoupons(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('updateHourCoupons').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }



    // 删除
    public delteHourCouponItem(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('delteHourCouponItem').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }



    //球桌
    public poolTableCategory(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('poolTableCategory').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}
import { BasicsClass } from '@/common/BasicsClass';
export class LockerDoorsManageAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getLockerDoors', {
            url: '/admin/shopBox/door',
            name: 'list',
            label: '列表'
        }],
        ['addLockerDoors',  {
            url: '/admin/shopBox/addDoor',
            name: 'add',
            label: '添加'
        }],
        ['updateLockerDoors',  {
            url: '/admin/shopBox/editDoor',
            name: 'edit',
            label: '编辑'
        }],
        ['deleteLockerDoors', {
            url: '/admin/shopBox/delDoor',
            name: 'delete',
            label: '删除'
        }]
    ])


    //新增lockerDoor
    public addLockerDoors(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('addLockerDoors').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    //修改lockerDoor
    public updateLockerDoors(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('updateLockerDoors').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    //删除lockerDoor
    public deleteLockerDoors(params: any, successCb: Function, failCb: Function, showLoading: boolean = false): void {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('deleteLockerDoors').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 球杆柜列表
    public getLockerDoors(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getLockerDoors').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

}
import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

import { CommonApi } from "../common";

export default class WebsitePandaContactAPI extends BasicsClass {

  public apiUrls: any = new Map([
    ['getCustomContactList', {
        url: '/admin/websiteContact/club',
        name: 'list',
        label: '列表'
    }],
    ['handleCustomContact',  {
        url: '/admin/websiteContact/clubTouch',
        name: 'contact-custom',
        label: '去联系顾客'
    }],
    
  ])

  public getCustomContactList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getCustomContactList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }


  // 用户优惠券---过期处理
  public handleCustomContact(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('handleCustomContact').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getPageAPIs } from "@/common/util";
import { CashierClientUpdaterAPI } from "@/common/api/systemManage/cashierClientUpdaterList";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import ZlSelect from "@/components/third-custom-components/select";
import ZlOption from "@/components/third-custom-components/option";

import {
  shareConfirmOptions,
  // shareShopType2ch,
  defaultWeekList
} from '@/common/config'

// 暴露的API 列表
export const pageApiList = getPageAPIs(CashierClientUpdaterAPI)

const defaultForms = {
  title: "",
  context: '',
  valid_time: "",
  version: '',
  force_update: 0, // 强制更新 0否  1是
};

// const defaultSearchVenuePageOption = {
//   page: 1,
//   limit: 10,
//   isRearchBottom: false,
// };

const couponDialogTitle2ch: any = {
  "new-cashier-client-updater": "新增版本升级",
  "edit-cashier-client-updater": "编辑版本升级",
};


const maxExpiredDays = 999;

const maxTimeDuration = 999;

const maxAmount = 99;

// coupon 卡券分属类别
// const couponShopType2ch: any = [
//   ...shareShopType2ch
// ]

@Component({
  filters: {
    // showCashierClientUpdaterShopTypeName(shop_type: number) {
    //   return couponShopType2ch[shop_type] || ''
    // }
  },
  components: {
    ZlSelect,
    ZlOption,
  },
})
export default class CashierClientUpdater extends Vue {
  private form: any = {
    shop_name: "",
  };
  private forms: any = {

  };

  private deepLoopUtil: any = {};

  private cashierClientUpdaterApi = new CashierClientUpdaterAPI();

  private chooseWeekDayList: any[] = []


  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();


    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    // this.searchVenuePageOption = {
    //   ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    // };

    this.chooseWeekDayList = [
      ...this.deepLoopUtil.deepCopy(defaultWeekList, false)
    ]

  }

  private created(): void {
    this.getCashierClientUpdaters();

    // this.refreshTableClass();
  }
  private restForm(): void {
    this.getCashierClientUpdaters();
  }

  private tableData: any[] = [];

  private formLabelWidth: string = "100px";
  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getCashierClientUpdaters();
  }


  // private allTableCategoryList: any[] = [];

  // private refreshTableClass() {
  //   return new Promise((resolve, reject) => {
  //     this.cashierClientUpdaterApi.poolTableCategory(
  //       {},
  //       (res: any) => {
  //         const { list: allTableCategoryList } = (res ? res.data : {}) as any;

  //         this.allTableCategoryList = allTableCategoryList;

  //         resolve(allTableCategoryList);
  //       },
  //       (err: any) => {
  //         reject(err);
  //       }
  //     );
  //   });
  // }

  private changeCashierClientUpdaterTitle: string = "版本升级";

  private dialogFormVisible: boolean = false;

  // 拉起卡券弹框
  private handleWakeCashierClientUpdaterDialog(mode: string) {
    this.couponDialogMode = mode;
    this.changeCashierClientUpdaterTitle = couponDialogTitle2ch[mode] || "版本升级";
    this.forms =  {...this.deepLoopUtil.deepCopy(defaultForms, false)}
    if(this.couponDialogMode==='edit-cashier-client-updater'){
      this.forms = {...this.currentCashierClientUpdaterItem}
      let pool_table_category_ids = this.forms.pool_table_category_ids.split(',').map(Number)

      this.$set(this.forms,'pool_table_category_ids',pool_table_category_ids)
    }
    this.dialogFormVisible = true;
  }

  private currentCashierClientUpdaterItem: any = null;

  private couponDialogMode: string = "";


  // private handleWakeEditCashierClientUpdaterDialog(couponItem: any) {
  //   this.currentCashierClientUpdaterItem = couponItem;

  //   this.handleWakeCashierClientUpdaterDialog("edit-cashier-client-updater");
  // }

  // private handleDeleteCashierClientUpdater(couponItem: any) {
  //   this.$confirm("确认删除该卡券？", '', {
  //     ...(shareConfirmOptions || {})
  //   }).then(() => {
  //     this.cashierClientUpdaterApi.delteHourCashierClientUpdaterItem({
  //       id: couponItem.id
  //     }, () => {
  //       this.getCashierClientUpdaters();
  //     }, () => {

  //     })
  //   })
  // }

  // private searchVenuePageOption: any = {};


  // 有效期
  // private handleLimitExpiredDays(val: string) {
  //   Util.limitIntegetInput(
  //       val,
  //       (new_val: string) => {
  //         const limit_val = Math.min(Number(new_val), maxExpiredDays).toString();
  //         this.$set(this.forms, "valid_time", limit_val);
  //       },
  //       (new_val: string) => {
  //         this.$set(this.forms, "valid_time", new_val);
  //       }
  //   );
  // }

  // 卡券时长
  // private handleLimitTime(val: string) {
  //   // const reg = new RegExp("[0-9]{1,}", "g");
  //   //
  //   // let newVal = val.match(reg);
  //   //
  //   // if (val && Array.isArray(newVal)) {
  //   //   this.$set(this.forms, "value", newVal[0])
  //   // } else {
  //   //   this.$set(this.forms, "value",  "")
  //   // }

  //   Util.limitIntegetInput(
  //       val,
  //       (new_val: string) => {
  //         const limit_val = Math.min(Number(new_val), maxTimeDuration).toString();
  //         this.$set(this.forms, "value", limit_val);
  //       },
  //       (new_val: string) => {
  //         this.$set(this.forms, "value", new_val);
  //       }
  //   );
  // }

  // 补贴金额
  // private handleLimitAmount(val: string) {
  //   Util.limitIntegetInput(
  //       val,
  //       (new_val: string) => {
  //         const limit_val = Math.min(Number(new_val), maxAmount).toString();
  //         this.$set(this.forms, "subsidy_amount", limit_val);
  //       },
  //       (new_val: string) => {
  //         this.$set(this.forms, "subsidy_amount", new_val);
  //       }
  //   );
  // }




  private sumbitCashierClientUpdaterLoading: boolean = false;

  // 提交 版本升级配置
  private handleSumbitCashierClientUpdater() {
    const params = {...this.forms}
    // params.pool_table_category_ids = params.pool_table_category_ids.toString()
    // console.log(params)
    const couponDialogMode = this.couponDialogMode;
    this.sumbitCashierClientUpdaterLoading = true;
    switch (couponDialogMode) {
      case "new-cashier-client-updater":
        this.cashierClientUpdaterApi.addCashierClientUpdater(
          params,
          () => {
            this.getCashierClientUpdaters();
            this.dialogFormVisible = false;
            this.sumbitCashierClientUpdaterLoading = false;
          },
          () => {
            this.sumbitCashierClientUpdaterLoading = false;
          }
        );
        break;
      case "edit-cashier-client-updater":
        // this.cashierClientUpdaterApi.updateCashierClientUpdater(
        //   params,
        //   () => {
        //     this.getCashierClientUpdaters();
        //     this.dialogFormVisible = false;
        //     this.sumbitCashierClientUpdaterLoading = false;
        //   },
        //   () => {
        //     this.sumbitCashierClientUpdaterLoading = false;
        //   }
        // );
        break;
    }
  }


  //获取版本升级列表
  private getCashierClientUpdaters(): void {
    this.cashierClientUpdaterApi.getCashierClientUpdater(
      { ...this.getCashierClientUpdaterParams() },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page;
        this.tablePage.prepage = res.data.prepage;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private getCashierClientUpdaterParams(): any {
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    const { shop_name, name, third_party_id } = form;

    if (shop_name) {
      params.shop_name = shop_name;
    }

    if (name) {
      params.name = name
    }

    if (third_party_id) {
      params.third_party_id = third_party_id
    }

    if (page) {
      params.page = page;
    }

    if (prepage) {
      params.limit = prepage;
    }

    return params;
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };

  // private getOptions(): void {
  //   this.cashierClientUpdaterApi.selectCashierClientUpdaters(
  //     (res: any) => {
  //       this.options = res.data.list;
  //       this.selectPage.page = res.data.page;
  //       this.selectPage.prepage = res.data.prepage;
  //       this.selectPage.totalPage = res.data.totalPage;
  //       this.selectPage.count = res.data.count;
  //       this.subId = "";
  //     },
  //     {
  //       ...this.selectPage,
  //       superpositionJoin: this.forms.superpositionJoin,
  //       id: this.subId,
  //     }
  //   );
  // }

  // @Watch("dialogFormVisible")
  // private changeDialog(): void {
  //   if (this.dialogFormVisible) {
  //     this.getOptions();
  //   }
  // }

  // private changeSuperpositionType(): void {
  //   this.$set(this.forms, "superpositionType", "2");

  //   // if (this.forms.type == 2) {

  //   //   this.forms.superpositionType = "2";
  //   // }
  // }

  // private changeMaxType(): void {
  //   // const MaxType=this.forms.maxType
  //   // this.$set(this.forms, "maxType", MaxType);
  //   this.$forceUpdate();
  // }

  // private changeMinType(): void {
  //   // if (this.forms.type == 2) {
  //   //   this.forms.minType = "0";
  //   // }
  //   this.$forceUpdate();
  // }

  // private changeType(): void {
  //   if (this.forms.type == 2) {
  //     this.forms.superpositionType = "2";
  //     // this.forms.minType = "0";
  //   }
  //   if (this.forms.type != 3) {
  //     this.forms.minMoneyLimit = 0;
  //   }
  //   this.$forceUpdate();
  // }

  // private changeforms(): void {
  //   // console.log(this.forms.time)
  //   this.$forceUpdate();
  // }
}

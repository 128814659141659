
import dayjs from "@/plugins/dayjs";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";

import PandaMobileTable from "@/components/mobile-custom/panda-table.vue";

import PandaMobileTWFContainer from "@/components/mobile-custom/panda-table-with-filter-container.vue";

import { LampOptionLogAPI } from "@/common/api/dataWholeBoard/lvmiLampOptionLog";

import {
  getCommonList
} from '@/common/config'

import { excuteDownloadExport2Excel } from "@/common/xlsx";


// 类型 1发送指令 2回调
const lampOptionType2Ch: string[] = ['', '发指令', '回调']

// 开关状态 0关 1开
const lampStatus2ch: string[] = ['关', '开']

// function getCurrentMonthDate() {
//   const currentDay = (dayjs() as any).format("YYYY-MM");

//   const curMonthStartDay = (dayjs().startOf("month") as any).format("YYYY-MM");

//   return [curMonthStartDay, currentDay];
// }

// function getLastMonthDate() {
//   const lastEndDay = (dayjs().startOf("month") as any).format("YYYY-MM");

//   const lastStartDay = (
//     dayjs().subtract(1, "month").endOf("month") as any
//   ).format("YYYY-MM");

//   return [lastStartDay, lastEndDay];
// }

// console.log(getLastMonthDate(), 'getLastMonthDate')
export const pageApiList = getPageAPIs(LampOptionLogAPI);

@Component({
  components: {
    PandaMobileTable,
    PandaMobileTWFContainer,
  },
  filters: {
    // getTodayTotalIncome(row: any) {
    // }
    showLampOptionTypeName(type: number) {
      return lampOptionType2Ch[type] || '未知'
    },
    showLamepOptionStatusName(status: number) {
      return lampStatus2ch[status] || '未知'
    }
  },
})
export default class MerchantsAudit extends Vue {
  /** 移动端相关属性 方法 */
  private allMobileChooseFields: any[] = [
    {
      label: "店铺名称",
      field: "shop_name",
    },
    {
      label: "店铺ID",
      field: "shop_id",
    },
    {
      label: "球桌ID",
      field: "pool_id",
    },
    {
      label: "球桌名称",
      field: "pool_name",
    },
    {
      label: "时间",
      field: "created_at",
    },
    {
      label: "操作类型（回调/发指令）",
      field: "table-type",
    },
    {
      label: "开关ID",
      field: "subject_id",
    },
    {
      label: "按钮ID",
      field: "resource_id",
    },
    {
      label: "操作状态",
      field: "table-switch",
    },
    {
      label: "操作原因",
      field: "remark",
    }
  ];
  /** end 移动端相关属性 方法 */

  private lampOptionLogApi: any;

  constructor() {
    super();
    this.lampOptionLogApi = new LampOptionLogAPI();
  }

  private form: any = {
    // shop_name: '',
    pool_id: '', // 球桌ID
    resource_id: '', // 按钮ID
    subject_id: '', // 开关ID
    time: [
      // ...getLastMonthDate()
    ],
  };

  // private pickerOptions: any = {
  //   shortcuts: [
  //     // {
  //     //   text: '本月',
  //     //   onClick(picker: any) {

  //     //     picker.$emit('pick', getCurrentMonthDate());
  //     //   }
  //     // },
  //     // {
  //     //   text: "上月",
  //     //   onClick(picker: any) {
  //     //     picker.$emit("pick", getLastMonthDate());
  //     //   },
  //     // },
  //   ],
  // };

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private activated(): void {
    // if (this.$route.query.name) {
    //   this.form.shop_name = this.$route.query.name;
    // }
    this.search();
  }

  private getTable(): void {
    this.lampOptionLogApi.lampLogList(
      {
        ...this.getLampOptionLogTableListParams(),
      },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.totalCount = res.data.count;
      }
    );
  }

  private getLampOptionLogTableListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const {
      // shop_name, // 店铺名称
      pool_id,
      resource_id,
      subject_id,
      time,
    } = form || {};

    // console.log(technicianItem, "technicianItem");

    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};

    // if (shop_name) {
    //   outerParams.shop_name = shop_name;
    // }

    if (pool_id) {
      outerParams.pool_id = pool_id
    }

    if (resource_id) {
      outerParams.resource_id = resource_id
    }

    if (subject_id) {
      outerParams.subject_id = subject_id
    }

    if (Array.isArray(time) && time.length === 2) {
      const [start_time, end_time] = time;

      outerParams.start_time = start_time;

      outerParams.end_time = end_time;
    }

    if (
      mode == "exportExcel" &&
      !(outerParams.start_time && outerParams.end_time)
    ) {
      this.$message.error("请选择创建时段导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;
    } else if (mode == "exportExcel") {
      // outerParams.is_export = 1
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  /** 导出excel */
  // private downloadExcelLoading: boolean = false;

  // // 商户字段名 文案对应关系
  // private monthIncomeExcelList2ch: any = {
  //   date: "日期",
  //   member_recharge: "会员充值金额",
  //   member_consume: "会员消费金额",
  //   shop_member_price: "单店卡充值",
  //   shop_member_consume: "单店卡消费",
  //   member_money: "会员账户余额",
  //   member_recharge_income: "会员充值分佣金额",
  //   merchant_income: "商家最终收益",
  //   entry_fee: "报名费",
  //   product_sales_price: "商品销售",
  //   merchant_account_balance: "商家账户余额",
  //   merchant_withdrawal_money: "商家提现金额",
  //   voucher_income: "优惠券销售",
  //   meituan_price: "美团券核销金额",
  //   douyin_price: "抖音券核销金额",
  //   kuaishou_price: "快手券核销金额",
  //   sport_price: "体育券核销金额",
  //   meituan_shop_member_price: "美团单店卡",
  //   coach_income: "本月教练收益",
  //   assistant_income: "线下助教收益",
  //   wechat_price: "微信充值",
  //   alipay_price: "支付宝充值",
  //   billiards_consume_price: "台球消费",
  //   chess_consume_price: "棋牌消费",
  //   assistant_consume_price: "助教消费",
  // };
  // // 商户Excel 表头的先后顺序
  // private monthIncomeExcelSortList: string[] = [
  //   "date",
  //   "member_recharge",
  //   "member_consume",
  //   "shop_member_price",
  //   "shop_member_consume",
  //   "member_money",
  //   "member_recharge_income",
  //   "merchant_income",
  //   "entry_fee",
  //   "product_sales_price",
  //   "merchant_account_balance",
  //   "merchant_withdrawal_money",
  //   "voucher_income",
  //   "meituan_price",
  //   "douyin_price",
  //   "kuaishou_price",
  //   "sport_price",
  //   "meituan_shop_member_price",
  //   "coach_income",
  //   "assistant_income",
  //   "wechat_price",
  //   "alipay_price",
  //   "billiards_consume_price",
  //   "chess_consume_price",
  //   "assistant_consume_price",
  // ];

  // 点击导出excel 表格
  // private handleExportExcelLampOptionLogList() {
  //   this.getTable();
  //   const params: any = this.getLampOptionLogTableListParams("exportExcel");

  //   if (params === null) {
  //     return false;
  //   }

  //   this.downloadExcelLoading = true;
  //   this.lampOptionLogApi.exportExcelLampOptionLogList(
  //     params,
  //     (res: any) => {
  //       const { start_time, end_time } = params || {};

  //       const filename = `绿米灯控操作记录列表 ${start_time}到${end_time}.xlsx`;
  //       const { data } = res || {};

  //       const { list: originList } = data || {};

  //       const newList = Array.isArray(originList)
  //         ? originList.map((item) => {
  //             const { orderNum } = item || {};
  //             const newItem = {
  //               ...(item || {}),
  //             };

  //             newItem.orderNum =
  //               typeof orderNum == "number"
  //                 ? orderNum.toString()
  //                 : orderNum || "";

  //             return newItem;
  //           })
  //         : [];

  //       if (newList.length > 0) {
  //         const monthIncomeExcelSortList = this.monthIncomeExcelSortList;

  //         const monthIncomeExcelList2ch = this.monthIncomeExcelList2ch;

  //         const excelJSONData = getExcelJSONDataByList(
  //           newList,
  //           monthIncomeExcelSortList,
  //           monthIncomeExcelList2ch
  //         );

  //         excuteDownloadExport2Excel(excelJSONData, filename);
  //       } else {
  //         this.$message.info("选中时段没有可导出的信息");
  //       }

  //       // const testData = [
  //       //   { 主页: "test1223424", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
  //       //   { 主页: "433", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
  //       //   { 名称: "22", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
  //       //   { 名称: "43", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
  //       //   { 店家: "43", 价格: "6800", 数量: "6800", 昵称: "广告主网", }
  //       // ]

  //       this.downloadExcelLoading = false;
  //     },
  //     () => {
  //       this.downloadExcelLoading = false;
  //     }
  //   );
  // }

  /** end 导出excel */

  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}


import {Component, Vue} from "vue-property-decorator";
import {Util, getPageAPIs} from "@/common/util";
import ChatBanListAPIClass from "@/common/api/operationManage/coachBanList";
import {shareConfirmOptions} from '@/common/config';

export const pageApiList = getPageAPIs(ChatBanListAPIClass)
interface addForm {
  phone:string,
  day:number | string
}
const defaultAddForm = {
  phone:'',
  day:''
}
@Component({})
export default class MerchantsAudit extends Vue {
  private ChatBanListAPIClass: any;
  private title: string = "";
  private is_show: boolean = false;

  private params: any = {
    mobile: '',
  };

  private add_forms: addForm = {
    ...defaultAddForm
  };

  constructor() {
    super();
    this.ChatBanListAPIClass = new ChatBanListAPIClass();
  }


  private addDialog(): void {
    this.title = "新增";
    this.is_show = true;
    this.$nextTick(()=>{
      this.add_forms = new Util().clearObject(this.add_forms);
    })
  }
  private onInputDays(value:any):void{
    const max = 99
    const min = 1
    this.add_forms.day = value.replace(/[^0-9]/g,'');
    if(this.add_forms.day>max){
      this.add_forms.day = max
      return
    }
    if(this.add_forms.day==='0'){
      this.add_forms.day = min
      return
    }
  }
  private submit(): void {
    this.ChatBanListAPIClass.addBan(this.add_forms, () => {
      this.is_show = false;
      Util.showToast("新增成功");
      this.search();
    });
  }

  private secure(row: any) {
    this.$confirm(`确认解封${row.nickname}"吗？`, '', {
      ...(shareConfirmOptions || {})
    }).then(() => {
      this.ChatBanListAPIClass.freeBan(
          {id: row.id,},
          () => {
            this.is_show = false;
            Util.showToast("解封成功");
            this.search();
          }
      );
    })
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private activated(): void {
    this.search();
  }

  private getTable(): void {
    this.ChatBanListAPIClass.getList(
        {
          ...this.params,
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
        }
    );
  }

  private restForm(): void {
    this.params = new Util().clearObject(this.params);
    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getTable();
  }

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

}

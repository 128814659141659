import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class OperationPartnerAmountAdjustRecordAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getOperationPartnerAmountRecordList', {
      url: '/admin/operatePartnerAccountHandle/index',
      name: 'list',
      label: '列表'
    }],
    ['changePartnerAmount', {
      url: '/admin/operatePartnerAccountHandle/add',
      name: 'change-partner-amount',
      label: '修改合伙人余额'
    }],


    ['searchVenueList', {
      url: '/admin/deduction/operate_partner/shop',
      name: 'shop-list',
      label: '手机号绑定店铺列表',
      // hidden: true
    }],
   
  ])

  // 赛事积分列表
  public getOperationPartnerAmountRecordList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getOperationPartnerAmountRecordList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }


  // 添加赛事积分
  public changePartnerAmount(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('changePartnerAmount').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 搜索店铺列表
  public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('searchVenueList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}

import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import amap from "@/components/amap.vue";
import PandaMobileTable from "@/components/mobile-custom/panda-table.vue";

import PandaMobileTWFContainer from "@/components/mobile-custom/panda-table-with-filter-container.vue";

import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import { RechargeRecordAPI } from "@/common/api/merchantManage/rechargeRecord";

import gallery from "@/components/gallery.vue";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import { CommonApi } from "@/common/api/common";
import { excuteDownloadExport2Excel } from "@/common/xlsx";

import ZlSelect from "@/components/third-custom-components/select";

import ZlOption from "@/components/third-custom-components/option";

import { shareConfirmOptions, chooseShopTagList, getCommonList } from "@/common/config";

export const pageApiList = getPageAPIs(RechargeRecordAPI);

const defaultForm = {
  shop_id: "",
  mobile: "",
  full_name: "",
  name: "",
  is_status: 0,
  time: [],
  merchant_id: "",
};

const defaultStoreInfo: any = {
  shop_type: "",
  brand_type:"",
  ball_room_type: '', // 台球类型  1自助球房 2专业球房 3商业球房
  id: "",
  name: "",
  address: "",
  lat: "",
  long: "",
  ksAuth: {
    kuaishou_auth_id: '',
    kuaishou_merchant_id: '',
  },
  kuaishou_poi_id: '',

  zkb_user_id: "",
  divide_max_price: "",
  contact_phone: "",

  shop_mode: 1, // 店铺类型  1普通 2品牌 3旗舰

  is_status: "",
  shop_member: "",
  auto_withdraw: 1, // 1 表示  每周日自动申请提现； 0 表示 关闭
  is_has_order: 0, // 是否有订单开门 1是 0否
  main_door: "", // 绑定门禁设备编号  多个编号以英文逗隔开（如：编号1,编号2）
};

const defaultAuthLvmiForm = {
  phone: "",
  code: "",
};

const MAX_COUNT_DOWN_NUM = 60;

const defaultVideoDeviceTablePage: any = {
  totalCount: 0,
  pageNo: 1,
  pageSize: 10,
  pageSizes: [10, 20, 50, 100, 200, 500],
};

// 监控设备表单
const defaultVideoRecordDeviceForm = {
  id: "",
  device_no: "",
  dev_index: "",
  pool_id: "",
  pool: {
    id: "",
    name: "",
  },
  channel_id: '',
  enable_code: 0, // 是否开启精彩秀，需要设置h264编码 0否 1是
};

const defaultStoreStatusList: any[] = [
  {
    label: "待审核",
    value: 1,
  },
  {
    label: "上架",
    value: 2,
  },
  {
    label: "下架",
    value: 3,
  },
  {
    label: "装修中",
    value: 4,
  },
];

export const defaultSearchCommonPageOption = {
  page: 1,
  limit: 10,
  isRearchBottom: false,
}

const mointorCodeStatus2Ch: string[] = ["未开通", "已开通"];

// 抖音核销类型:1自研,2服务商
const douyinReciptType2ch: string[] = ['', '自研', '服务商']

// 台球类型  1自助球房 2专业球房 3商业球房
const ballRoomType2Ch: string[] = ['', '自助球房', '专业球房', '商业球房']

@Component({
  filters: {
    showStoreStatusText(row: any) {
      const statusItem =
        defaultStoreStatusList.find((item) => item.value == row.is_status) ||
        null;

      if (statusItem) return statusItem.label;

      return "未知";
    },
    showMointorCodeText(enable_code: number) {
      return mointorCodeStatus2Ch[enable_code] || "";
    },

    showFilterText: Util.showFilterText,

    showBallRoomTypeName(ball_room_type: number) {
      return ballRoomType2Ch[ball_room_type] || '未知'
    }
  },
  components: {
    gallery,
    amap,
    ZlSelect,
    ZlOption,
    PandaMobileTable,
    PandaMobileTWFContainer,
  },
})
export default class RechargeRecordList extends Vue {
  /** 移动端相关属性 方法 */
  private allMobileChooseFields: any[] = [
    {
      label: "店铺ID",
      field: "id",
    },
    {
      label: "商家姓名",
      field: "full_name",
    },
    {
      label: "手机号码",
      field: "mobile",
    },
    {
      label: "店铺名称",
      field: "name",
    },
    // {
    //   label: "店铺地址",
    //   field: "address",
    // },
    // {
    //   label: "球桌数量",
    //   field: "pool_table_num",
    // },
    // {
    //   label: "营业时间",
    //   field: "table-business-duration",
    // },
    // {
    //   label: "联系电话",
    //   field: "contact_phone",
    // },
    {
      label: "分成比例",
      field: "table-divide-rate",
    },
    {
      label: "品牌类型",
      field: "table-brand-type",
    },
    {
      label: "子账号",
      field: "sub_account_num",
    },
    {
      label: "球房",
      field: "table-ball-room-type",
    },
    {
      label: "上架状态",
      field: "table-on-sale-status",
    },
    {
      label: "收银后台地址链接",
      field: "cashier_url",
    },
    {
      label: "美团授权状态",
      field: "table-meituan-auth-status",
    },
    {
      label: "美团授权地址url",
      field: "meituan_auth_url",
    },
    {
      label: "操作时间",
      field: "updated_at",
    },
    {
      label: "操作人",
      field: "operation_user",
    },
    {
      label: "操作",
      field: "table-option",
    },
  ];
  /** end 移动端相关属性 方法 */

  private shopTagList: any[] = [...chooseShopTagList];

  private rechargeRecordApi: any;

  private deepLoopUtil: any;

  private storeinfo: any = {};

  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();
    this.rechargeRecordApi = new RechargeRecordAPI();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };

    this.storeinfo = {
      ...this.deepLoopUtil.deepCopy(defaultStoreInfo),
    };

    this.authMobileForm = {
      ...this.deepLoopUtil.deepCopy(defaultAuthLvmiForm),
    };

    this.videoDeviceTablePage = {
      ...this.deepLoopUtil.deepCopy(defaultVideoDeviceTablePage),
    };

    this.videoRecordDeviceForm = {
      ...this.deepLoopUtil.deepCopy(defaultVideoRecordDeviceForm),
    };
  }

  private firstLoading: boolean = true;
  private images: any = [];
  private form: any = {};

  private douyinReceiptTypeList: any[] = getCommonList(douyinReciptType2ch, 1)

  private created(): void {}
  private activated(): void {
    if (this.$route.query.merchant_id) {
      this.form.merchant_id = this.$route.query.merchant_id;
    } else {
      delete this.form.merchant_id;
    }
    this.getTable();
  }
  private getTable(): void {
    this.rechargeRecordApi.storeList(
      {
        ...this.form,
        page: this.tablePage.pageNo,
        limit: this.tablePage.pageSize,
      },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.totalCount = res.data.count;
        for (let i of this.tableData) {
          for (let x of i.shop_pictures) {
            if (x.type == 1) {
              x.type = "image/jpeg";
            } else {
              x.type = "video/mp4";
            }
            x.href = x.url;
          }
        }
      }
    );
  }
  private storeshow: boolean = false;

  private forms: any = {};
  private hideUpload4: boolean = false;
  private hideUpload5: boolean = false;
  private dialogVisible: boolean = false;
  private dialogImageUrl: string = "";

  private shopTypeList: any[] = [
    {
      label: "台球",
      value: 1,
    },
    {
      label: "棋牌",
      value: 2,
    },
    // {
    //   label: '台球 + 棋牌',
    //   value: 3
    // }
  ];

  private brandTypeList: any[] = [
    {
      label: "熊猫",
      value: 1,
    },
    {
      label: "麦力士",
      value: 2,
    },
  ];

  private onChangeBrandType(val: number) {
    switch (val) {
      case 1:
        // 默认自助
        this.$set(this.storeinfo, 'ball_room_type', 1)
        break;
      case 2:
        // 默认专业
        this.$set(this.storeinfo, 'ball_room_type', 2)
        break;
    }
  }

  private ballRoomTypeList: any[] = [
    ...getCommonList(ballRoomType2Ch, 1)
  ]

  private get hasLockInfo() {
    const storeinfo = this.storeinfo;

    const { shop_type } = storeinfo || {};

    const allowHasLockTypes: number[] = [2, 3];

    return allowHasLockTypes.includes(shop_type);
  }

  private get hasDeskBallInfo() {
    const storeinfo = this.storeinfo;

    const { shop_type } = storeinfo || {};

    const allowHasDeskTypes: number[] = [1, 3];

    return allowHasDeskTypes.includes(shop_type);
  }

  // 仅仅是 台球店铺
  private get onlyPandaBallShop() {
    const storeinfo = this.storeinfo;

    const { shop_type } = storeinfo || {};

    const allowHasLockTypes: number[] = [1];

    return allowHasLockTypes.includes(shop_type);
  }

  private hasPoolManageBtn(row: any) {
    const { shop_type } = row || {};

    const allowHasPoolManageBtns: number[] = [1, 3];

    return allowHasPoolManageBtns.includes(shop_type);
  }

  private hasChessManageBtn(row: any) {
    const { shop_type } = row || {};

    const allowHasChessManageBtns: number[] = [2, 3];

    return allowHasChessManageBtns.includes(shop_type);
  }

  private filterStoreStatusList: any = [
    {
      label: "全部",
      value: 0,
    },
    ...defaultStoreStatusList,
  ];

  private storeStatusList: any[] = [...(defaultStoreStatusList || [])];

  //预览
  private handlePictureCardPreview(file: any): void {
    this.dialogVisible = true;
    this.dialogImageUrl = file.url;
  }
  private commonApi = new CommonApi();

  private getOss(file: File, cb: Function) {
    console.log(file);
    if (file.type == "image/jpeg" || file.type == "image/png") {
      this.commonApi.getOss(
        file,
        (res: any) => {
          cb(res);
        },
        () => {}
      );
    } else {
      this.commonApi.getOssvideo(
        file,
        (res: any) => {
          cb(res);
        },
        () => {}
      );
    }
  }

  private fileChange4(file: any, fileList: any[]): void {
    this.hideUpload4 = fileList.length >= 20;
    this.getOss(file.raw, (res: any) => {
      console.log(res);
      const raw = res.data.data.item;
      let obj: any = {
        id: "",
        type: "",
        href: "",
        types: "",
      };
      if (raw.type == 2) {
        obj.type = "video/mp4";
        obj.types = "2";
        obj.id = raw.id;
        obj.url = raw.url;
        obj.href = raw.url;
        obj.poster =
          raw.url +
          "?spm=a2c4g.11186623.2.1.yjOb8V&x-oss-process=video/snapshot,t_7000,f_jpg,w_800,h_600,m_fast";
      } else {
        obj.types = "1";
        obj.type = "image/jpeg";
        obj.id = raw.id;
        obj.url = raw.url;
        obj.href = raw.url;
      }
      console.log(obj);
      this.storeinfo.shop_picture.push(obj);
    });
  }
  private fileChange5(file: any, fileList: any[]): void {
    this.hideUpload5 = fileList.length >= 5;
    this.getOss(file.raw, (res: any) => {
      console.log(res);
      const raw = res.data.data.item;
      let obj: any = {
        id: raw.id,
        url: raw.url,
      };
      this.storeinfo.pool_table_maps.push(obj);
      console.log(this.storeinfo.pool_table_maps);
    });
  }
  private handleRemove4(file: any): void {
    this.hideUpload4 = false;
    for (let i in this.storeinfo.shop_picture) {
      if (this.storeinfo.shop_picture[i].id == file.id) {
        this.storeinfo.shop_picture.splice(i, 1);
      }
    }
  }
  private handleRemove5(file: any): void {
    this.hideUpload5 = false;
    for (let i in this.storeinfo.pool_table_maps) {
      if (this.storeinfo.pool_table_maps[i].id == file.id) {
        this.storeinfo.pool_table_maps.splice(i, 1);
      }
    }
  }
  // private mtshopList: any = [];
  // private mtshop(id: string): void {
  //   this.mtshopList = [];
  //   this.rechargeRecordApi.mtshop(
  //     {
  //       shop_id: id,
  //     },
  //     (res: any) => {
  //       this.mtshopList = res.data;
  //     }
  //   );
  // }
  private aqauth_list: any = [];
  private aqauth(): void {
    this.rechargeRecordApi.aqauth({}, (res: any) => {
      this.aqauth_list = res.data.list;
      this.aqauth_list.unshift({
        id: 0,
        phone: "无",
      });
    });
  }

  private change(): void {
    console.log(1);
    this.$forceUpdate();
  }

  // private changeShop(): void {
  //   console.log(this.storeinfo.open_shop_uuid);
  //   for (let i of this.mtshopList) {
  //     if (this.storeinfo.open_shop_uuid == i.open_shop_uuid) {
  //       this.storeinfo.meituan_shop_name = i.shopname;
  //     }
  //   }
  // }
  private auditStore(id: string, aqara_id: string): void {
    // this.mtshop(id);
    this.aqauth();
    this.rechargeRecordApi.storeview(
      {
        shop_id: id,
      },
      (res: any) => {
        const {
          kuaishou_auth_id,
          kuaishou_merchant_id,
        } = res.data || {}

        this.storeinfo = res.data;
        if (res.data.merchant_user.length == 0) {
          this.storeinfo.merchant_user = [{ mobile: "" }];
        }
        this.storeinfo.aqara_id = aqara_id;
        if (this.storeinfo.fencheng_rule[0]) {
          this.storeinfo.business_income =
            this.storeinfo.fencheng_rule[0].business_income;
          if (this.storeinfo.fencheng_rule[0].is_level == 1) {
            this.storeinfo.rate_up = this.storeinfo.fencheng_rule[0].rate;
            this.storeinfo.rate_top = this.storeinfo.fencheng_rule[1].rate;
          } else {
            this.storeinfo.rate_up = this.storeinfo.fencheng_rule[1].rate;
            this.storeinfo.rate_top = this.storeinfo.fencheng_rule[0].rate;
          }
        }

        if (this.storeinfo.pool_table_maps.length >= 5) {
          this.hideUpload4 = true;
        }
        if (this.storeinfo.shop_picture.length >= 20) {
          this.hideUpload5 = true;
        }

        if (kuaishou_auth_id && kuaishou_merchant_id) {
          this.$set(this.storeinfo, 'ksAuth', {
            kuaishou_auth_id,
            kuaishou_merchant_id,
          })
        }


        for (let i of this.storeinfo.shop_picture) {
          if (i.type == 1) {
            i.type = "image/jpeg";
          } else {
            i.type = "video/mp4";
          }
          i.href = i.url;
        }
        this.storeshow = true;
      }
    );
  }

  private poolTable(id: string, aqara_id: string, zkb_user_id: string) {
    this.$router.push({
      path: "/withhold",
      query: {
        id: id,
        aqara_id: aqara_id,
        zkb_user_id,
      },
    });
  }

  private roomTable(id: string, aqara_id: string, zkb_user_id: string) {
    this.$router.push({
      path: "/withholdChess",
      query: {
        id: id,
        aqara_id: aqara_id,
        zkb_user_id,
      },
    });
  }

  private revenue(name: string): void {
    this.$router.push({
      path: "/merchantsAudit",
      query: {
        name: name,
      },
    });
  }
  private revenueGlory(venueItem: any): void {
    console.log(venueItem, "venueItem");
    this.$router.push({
      path: "/merchantsGloryAudit",
      query: {
        shop_id: venueItem.id,
        shop_name: venueItem.name,
      },
    });
  }
  private handleClose(done: any): void {
    this.$confirm("确认关闭？")
      .then((_) => {
        done();
      })
      .catch((_) => {});
  }
  private isWatch: boolean = true;
  private getAddress(regeocode: any, lat: string, log: string): void {
    this.isWatch = false;

    this.storeinfo.address = regeocode.formattedAddress;

    this.storeinfo.lat = log;
    this.storeinfo.long = lat;
    this.$nextTick(() => {
      this.isWatch = true;
    });
  }
  private addAddress() {
    console.log(1);
    // this.restForms();
    const amap: any = this.$refs.amap;
    try {
      amap.placeSearch.clear();
    } catch (e) {
      amap.callbackList.push(amap.getAddressInMap);
    }
    // this.storeinfo = { ...data };
    this.$nextTick(() => {
      const amap: any = this.$refs.amap;
      try {
        amap.getAddressInMap();
      } catch (e) {
        amap.callbackList.push(amap.getAddressInMap);
      }
    });
  }
  private setCity(city: any) {
    console.log(city);
  }
  private add(index: number): void {
    this.storeinfo.merchant_user.push({ mobile: "", nickname: "" });
  }
  private delete_account_id: any = [];
  private remove(index: number): void {
    if (this.storeinfo.merchant_user[index].id != undefined) {
      this.delete_account_id.push(this.storeinfo.merchant_user[index].id);
    }
    this.storeinfo.merchant_user.splice(index, 1);
    if (this.storeinfo.merchant_user.length == 0) {
      this.storeinfo.merchant_user.push({ mobile: "", nickname: "" });
    }
  }

  private preview(file: any): void {
    console.log(file);
    this.images = [file];
  }

  private previews(arr: any, type: number): void {
    if (type == 2) {
      this.images = [...arr];
    } else {
      let arr2 = [];
      for (let i of arr) {
        let obj: any = {};
        obj.type = "image/jpeg";
        obj.href = i;
        arr2.push(obj);
      }

      this.images = arr2;
    }
  }
  private substore(): void {
    const storeinfo = this.storeinfo;
    const {
      poi_id,
      lock_account,
      lock_password,
      sanyou_account,
      sanyou_password,
      auto_withdraw,
      main_door,
      ksAuth,
      kuaishou_poi_id,
      zkb_user_id,
      douyin_receipt_type,
      is_has_order,
      shop_mode,
      // meituan_divide
    } = storeinfo || {};

    const {
      kuaishou_auth_id
    } = ksAuth || {}

    this.forms.shop_type = this.storeinfo.shop_type;
    this.forms.brand_type = this.storeinfo.brand_type;

    //if (this.storeinfo.shop_type == 1) {
    this.forms.ball_room_type =this.storeinfo.ball_room_type
    
    //}
    this.forms.shop_id = this.storeinfo.id;
    this.forms.name = this.storeinfo.name;
    this.forms.address = this.storeinfo.address;
    this.forms.lat = this.storeinfo.lat;
    this.forms.long = this.storeinfo.long;
    this.forms.divide_max_price = this.storeinfo.divide_max_price;
    this.forms.contact_phone = this.storeinfo.contact_phone;
    this.forms.is_status = this.storeinfo.is_status;
    this.forms.shop_member = this.storeinfo.shop_member;
    this.forms.spread_image = "";

    this.forms.auto_withdraw = auto_withdraw;

    // if (this.onlyPandaBallShop) {
    this.forms.meituan_divide = this.storeinfo.meituan_divide;
    // }

    this.forms.main_door = main_door || "";

    this.forms.douyin_receipt_type = douyin_receipt_type || ""

    if (sanyou_account && sanyou_password) {
      Object.assign(this.forms, {
        sanyou_account,
        sanyou_password,
      });
    }

    if (kuaishou_auth_id) {
      Object.assign(this.forms, {
        kuaishou_auth_id,
      });
    }

    if (kuaishou_poi_id) {
      Object.assign(this.forms, {
        kuaishou_poi_id,
      });
    }

    if (zkb_user_id) {
      Object.assign(this.forms, {
        zkb_user_id,
      });
    }

    if (this.hasDeskBallInfo) {
      this.forms.shop_mode = shop_mode;
    }

    if (this.hasLockInfo) {
      this.forms.is_has_order = is_has_order;
    }

    this.forms.lock_account = lock_account || "";
    this.forms.lock_password = lock_password || "";
    console.log(this.storeinfo.aqara_id);
    if (this.storeinfo.aqara_id == "") {
      this.forms.aqara_id = 0;
    } else {
      this.forms.aqara_id = this.storeinfo.aqara_id;
    }
    // this.forms.open_shop_uuid = this.storeinfo.open_shop_uuid;

    this.forms.poi_id = poi_id || "";
    // this.forms.meituan_shop_name = this.storeinfo.meituan_shop_name;

    const imageType2Flags: any = {
      "video/mp4": 2,
      "image/jpeg": 1,
    };

    this.forms.spread_image = (this.storeinfo.shop_picture || [])
      .map((item: any) => `${item.id}=${imageType2Flags[item.type] || 1}`)
      .join(",");

    this.forms.pool_table_map = "";

    this.forms.pool_table_map = (this.storeinfo.pool_table_maps || [])
      .map((item: any) => item.id)
      .join(",");

    this.forms.sub_account = "";

    this.forms.sub_account = (this.storeinfo.merchant_user || [])
      .map((item: any) => `${item.mobile}=${item.nickname}`)
      .join(",");

    if (this.storeinfo.fencheng_rule && this.storeinfo.fencheng_rule.length == 2) {
      this.forms.fengcheng_rule =
        this.storeinfo.fencheng_rule[0].id +
        "=" +
        Number(this.storeinfo.business_income) +
        "," +
        this.storeinfo.rate_up +
        "|" +
        this.storeinfo.fencheng_rule[1].id +
        "=" +
        Number(this.storeinfo.business_income) +
        "," +
        this.storeinfo.rate_top;
    }

    if (this.delete_account_id.length > 0) {
      console.log(this.delete_account_id);
      this.forms.delete_account_id = "";

      this.forms.delete_account_id = this.delete_account_id.join(",");
    }

    console.log('提交参数',this.forms);
    this.rechargeRecordApi.savestore(this.forms, (res: any) => {
      this.storeshow = false;
      this.delete_account_id = new Util().clearObject(this.delete_account_id);
      this.delete_account_id = [];
      this.forms.delete_account_id = "";
      Util.showToast("修改成功");
      this.search();
    });
  }

  // 更多菜单操作
  private handleMoreOptions(command: any, row: any) {
    switch (command as String) {
      case "ball-income-detail": // 台球营收详情
        this.revenue(row.name);
        break;
      case "glory-income-detail": // 棋牌营收详情
        this.revenueGlory(row);
        break;
      case "door-black":  // 门禁黑名单
        // doorBlackList
        this.$router.push({
          path: "/doorBlackList",
          query: {
            id: row.id,
          },
        });
        break;
      case "other-switch": // 其他开关
        this.device(row.id, row.aqara_id);
        break;
      case "air-config": // 空调配置
        this.air_config(row.id, row.aqara_id);
        break;
      case "bind-operator": // 绑定运营
        this.merchantBindOperator(row)
        break;
      case "sing-board-light": // 招牌灯
        this.singboardLightManage(row.id, row.aqara_id);
        break;
      case 'withdraw-change-mobile': // 提现手机号
        this.wakeWithdrawMobileDialog(row)
        break;
      case "air-list": // 空调列表
        this.air_config_set(row.id, row.aqara_id);
        break;
      case "bind-monitor-device": // 绑定监控设备
        this.handleWakeShopMonitorDialog(row);
        break;
      case "bind-speaker-monitor-device": // 绑定精彩秀监控设备
        this.handleWakeSpeakerMonitorDialog(row);
        break;
    }
  }

  private refreshShopWithdrawInfo(): Promise<any> {
    const shopItem: any = this.curVideoRecordDeviceShopItem    

    return new Promise((resolve, reject) => {
      this.rechargeRecordApi.getShopWithdrawMobileInfo({
        shop_id: shopItem.id
      }, (res: any) => {
        resolve(res.data || {})
      }, (err: any) => {
        reject(err)
      })
    })
  }

  // 提现手机号
  private async wakeWithdrawMobileDialog(shopItem: any) {
    this.curVideoRecordDeviceShopItem = shopItem;

    const { 
      mobile
    } = await this.refreshShopWithdrawInfo()

    this.withdrawForm = {
      mobile: mobile || ''
    }

    this.withdrawMobileVisible = true
  }
  private withdrawForm: any = {
    mobile: ''
  }
  private withdrawMobileVisible: boolean = false

  private withdrawMobileLoading: boolean = false

  private sumbitWithdrawMobile() {
    const withdrawForm = this.withdrawForm

    const shopItem = this.curVideoRecordDeviceShopItem

    const merchantItem = this.curVideoRecordDeviceShopItem

    const {
      mobile
    } = withdrawForm || {}

    this.withdrawMobileLoading = true

    this.rechargeRecordApi.updateShopWithdrawMobileInfo({
      shop_id: shopItem.id,
      phone: mobile || ''
    }, () => {

      this.withdrawMobileLoading = false

      this.withdrawMobileVisible = false

      this.$message.success('修改成功')
    }, () => {
      this.withdrawMobileLoading = false
    })
  }
  //end 提现手机号

  private handleChangeHiddenText({
    row,
    rowIndex,
    column,
    columnIndex,
    $event,
  }: any) {
    // console.log(column, "column");

    const { property } = column || {};
    const allowColumnProps: string[] = ["meituan_auth_url", "cashier_url"];

    if (allowColumnProps.includes(property)) {
      // console.log(row, rowIndex, $event, "row, rowIndex, $event");

      const allControlKeys: any = {
        meituan_auth_url: "__is_show_whole_auth_url",
        cashier_url: "__is_show_whole_cashier_url",
      };
      const controlKey = allControlKeys[property];

      // console.log(column, 'column')
      const { type: eventType } = $event || {};

      switch (eventType) {
        case "mouseleave":
          // row[controlKey] = false

          this.$set(this.tableData[rowIndex], controlKey, false);

          break;
        case "mouseenter":
          // row[controlKey] = true

          this.$set(this.tableData[rowIndex], controlKey, true);

          break;
        case "click":
          // this.$set(this.tableData[rowIndex], controlKey, true)
          break;
      }
    }
  }

  // 授权绿米
  private authLvmiMobileDialogVisible: boolean = false;

  private authMobileForm: any = {};

  private isDisableVertifyCodeBtn: Boolean = false;

  private vertifyCodeLoadingBtn: Boolean = false;

  private countDownVertifyTimer: any;

  private showCountDownNumber: number = MAX_COUNT_DOWN_NUM;

  private handleSendVertifyCode() {
    const authMobileForm = this.authMobileForm;

    const { phone } = authMobileForm || {};

    if (!phone) {
      this.$message.error("请输入手机号");

      return;
    }

    const params = {
      phone,
    };

    this.rechargeRecordApi.sendVertifyCode(
      params,
      (res: any) => {
        this.$message.success("验证码发送成功");

        this.initDisableSendVertifyBtn();
      },
      () => {}
    );
  }

  private initDisableSendVertifyBtn() {
    this.isDisableVertifyCodeBtn = true;

    this.showCountDownNumber = MAX_COUNT_DOWN_NUM - 1;

    const animateCountDown = (count: number) => {
      if (count > 0) {
        this.showCountDownNumber = count;
        this.countDownVertifyTimer = setTimeout(() => {
          animateCountDown(count - 1);
        }, 1000);
      } else {
        this.isDisableVertifyCodeBtn = false;
      }
    };

    clearTimeout(this.countDownVertifyTimer);

    animateCountDown(this.showCountDownNumber);
  }

  private handleWakeAuthLvmiMobileDialog() {
    this.authMobileForm = {
      ...this.deepLoopUtil.deepCopy(defaultAuthLvmiForm),
    };

    this.authLvmiMobileDialogVisible = true;

    console.log(
      this.authLvmiMobileDialogVisible,
      "this.authLvmiMobileDialogVisible"
    );
  }

  private handleSubmitVertifyLvmiMobile() {
    const authMobileForm = this.authMobileForm;

    const { phone, code } = authMobileForm || {};

    if (!phone) {
      this.$message.error("请输入手机号");

      return;
    }

    if (!code) {
      this.$message.error("请输入验证码");

      return;
    }

    const params = {
      phone,
      code,
    };

    this.vertifyCodeLoadingBtn = true;

    this.rechargeRecordApi.authVertifyCode(
      params,
      (res: any) => {
        this.$message.success("授权成功");
        this.vertifyCodeLoadingBtn = false;

        this.authLvmiMobileDialogVisible = false;
      },
      () => {
        this.vertifyCodeLoadingBtn = false;
      }
    );
  }

  /** 绑定视频回放设备 */

  private mointorMode: string = 'normal';

  // 唤起店铺监控设备列表弹框
  private handleWakeShopMonitorDialog(shopItem: any) {
    this.mointorMode = 'normal'

    this.$nextTick(() => {
      this.wakeCommonMonitorDialog(shopItem)
    })
  }

  // 唤起精彩秀监控设备
  private handleWakeSpeakerMonitorDialog(shopItem: any) {
    this.mointorMode = 'speaker'

    this.$nextTick(() => {
      this.wakeCommonMonitorDialog(shopItem)
    })
  }

  private wakeCommonMonitorDialog(shopItem: any) {
    this.curVideoRecordDeviceShopItem = shopItem;

    this.videoDeviceTablePage = {
      ...this.deepLoopUtil.deepCopy(defaultVideoDeviceTablePage),
    };

    this.getVideoRecordTable();

    this.videoRecordDeviceVisible = true;
  }


  // 监控设备列表弹框
  private curVideoRecordDeviceShopItem: any;

  private videoRecordDeviceVisible: boolean = false;

  private videoDeviceTablePage: any = {};

  private shopVideoRecordDeviceTableData: any[] = [];

  private getVideoRecordDeviceParams() {
    const videoDeviceTablePage = this.videoDeviceTablePage;

    const curVideoRecordDeviceShopItem = this.curVideoRecordDeviceShopItem;

    const { pageNo, pageSize } = videoDeviceTablePage || {};

    const params: any = {
      page: pageNo,
      limit: pageSize,
    };

    console.log(params, "params");

    if (curVideoRecordDeviceShopItem.id) {
      params.shop_id = curVideoRecordDeviceShopItem.id;
    }

    return params;
  }

  private getVideoRecordTable() {
    const params = this.getVideoRecordDeviceParams();

    let getMonitorListApi: any;

    const mointorMode = this.mointorMode;

    switch (mointorMode) {
      case 'normal':
        getMonitorListApi = this.rechargeRecordApi.getShopMonitorList
        break;
      case 'speaker':
        getMonitorListApi = this.rechargeRecordApi.getSpeakerMonitorList
        break;
    }

    getMonitorListApi.call(this.rechargeRecordApi, params, (res: any) => {
      this.shopVideoRecordDeviceTableData = res.data.list;
      this.videoDeviceTablePage.page = res.data.page || params.page;
      this.videoDeviceTablePage.prepage = res.data.prepage || params.limit;
      // this.tablePage.totalPage = res.data.total;
      this.videoDeviceTablePage.totalCount = res.data.count;
    });
  }

  private handleVideoRecordDevicePageChange(val: any) {
    this.videoDeviceTablePage.pageNo = val.currentPage;
    this.videoDeviceTablePage.pageSize = val.pageSize;

    this.getVideoRecordTable();
  }

  private allChoosePoolList: any[] = [];

  private refreshVideoRecordPoolList() {
    const curVideoRecordDeviceShopItem = this.curVideoRecordDeviceShopItem;

    this.rechargeRecordApi.getPoolTableList(
      {
        shop_id: curVideoRecordDeviceShopItem.id,
        page: 1,
        limit: 200,
      },
      (res: any) => {
        const { list } = res.data || {};

        this.allChoosePoolList = list;
      }
    );
  }

  private videoRecordDialogVisible: boolean = false;

  private videoRecordDialogTitle: string = "设备信息";

  private videoRecordDialogMode: string = "add";

  private videoRecordSubmitLoading: boolean = false;

  // private curShopMonitorItem: any

  private videoRecordDeviceForm: any;

  private handleAddShopMonitor() {
    this.videoRecordDialogMode = "add";

    this.videoRecordDialogTitle = "新增";

    this.refreshVideoRecordPoolList();

    this.initShopMointorForm();

    this.videoRecordDialogVisible = true;
  }

  private handleEditVideoMonitor(row: any) {
    // this.curShopMonitorItem = row

    this.videoRecordDialogTitle = "编辑";

    this.videoRecordDialogMode = "edit";

    this.refreshVideoRecordPoolList();

    this.initShopMointorForm(row);

    this.videoRecordDialogVisible = true;
  }

  private handleDeleteVideoMonitor(row: any) {
    const mointorMode = this.mointorMode;

    let delMonitorApi: any;


    switch (mointorMode) {
      case 'normal':
        delMonitorApi = this.rechargeRecordApi.deleteShopMonitor;
        break;
      case 'speaker':
        delMonitorApi = this.rechargeRecordApi.deleteSpeakerMonitor;
        break;
    }


    this.$confirm("是否删除该录像设备?", "", {
      ...shareConfirmOptions,
    }).then(() => {
      delMonitorApi.call(this.rechargeRecordApi, {
        id: row.id,
      }, () => {
        this.getVideoRecordTable();
      });

    });
  }

  private initShopMointorForm(row: any = null) {
    const videoRecordDialogMode = this.videoRecordDialogMode;

    const videoRecordDeviceForm = {
      ...this.deepLoopUtil.deepCopy(defaultVideoRecordDeviceForm),
    };

    if (videoRecordDialogMode == "edit") {
      const mointorMode = this.mointorMode;


      const { id, device_no = '', dev_index = '', channel_id = '', pool_id, pool_name, enable_code = '' } = row || {};

      Object.assign(videoRecordDeviceForm, {
        id,
      });

      switch (mointorMode) {
        case 'normal':
          Object.assign(videoRecordDeviceForm, {
            device_no,
            enable_code,
          });
          break;
        case 'speaker':
          Object.assign(videoRecordDeviceForm, {
            dev_index,
            channel_id
          });
          break;
      }

      if (pool_id && pool_name) {
        videoRecordDeviceForm.pool_id = pool_id;

        videoRecordDeviceForm.pool = {
          id: pool_id,
          name: pool_name,
        };
      }
    }

    this.videoRecordDeviceForm = videoRecordDeviceForm;
  }

  private getVideoRecordParams() {
    const videoRecordDeviceForm = this.videoRecordDeviceForm;

    const mointorMode = this.mointorMode;

    const { id, device_no, dev_index, channel_id, pool_id, enable_code } = videoRecordDeviceForm || {};

    const videoRecordDialogMode = this.videoRecordDialogMode;

    if (!pool_id) {
      this.$message.error("请选择球桌");
      return false;
    }

    const params: any = {
      pool_id,
    };

    switch (mointorMode) {
      case 'normal':
        if (!device_no) {
          this.$message.error("请输入设备编号");
          return false;
        }

        Object.assign(params, {
          device_no,
          enable_code,
        })
        break;
      case 'speaker':
        if (!dev_index) {
          this.$message.error("请输入设备编号");
          return false;
        }

        if (!channel_id) {
          this.$message.error("请输入通道号");
          return false;
        }

        Object.assign(params, {
          dev_index,
          channel_id
        });
        break;
    }

    if (videoRecordDialogMode == "edit") {
      params.id = id;
    }

    return params;
  }

  private handleSubmitVideoRecordDialog() {
    const params = this.getVideoRecordParams();

    if (typeof params !== "object") return;

    const mointorMode = this.mointorMode;

    let editMonitorApi: any;

    let addMointorApi: any;

    switch (mointorMode) {
      case 'normal':
        editMonitorApi = this.rechargeRecordApi.editShopMonitor;

        addMointorApi = this.rechargeRecordApi.addShopMonitor;
        break;
      case 'speaker':
        editMonitorApi = this.rechargeRecordApi.editSpeakerMonitor;

        addMointorApi = this.rechargeRecordApi.addSpeakerMonitor;
        break;
    }


    const videoRecordDialogMode = this.videoRecordDialogMode;

    this.videoRecordSubmitLoading = true;

    switch (videoRecordDialogMode) {
      case "edit":
        editMonitorApi.call(this.rechargeRecordApi, 
          params,
          () => {
            this.videoRecordSubmitLoading = false;

            this.videoRecordDialogVisible = false;

            this.getVideoRecordTable();
          },
          () => {
            this.videoRecordSubmitLoading = false;
          }
        );

        break;
      case "add":
        addMointorApi.call(this.rechargeRecordApi, 
          params,
          () => {
            this.videoRecordSubmitLoading = false;

            this.videoRecordDialogVisible = false;

            this.getVideoRecordTable();
          },
          () => {
            this.videoRecordSubmitLoading = false;
          }
        );
        break;
    }
  }

  /** end 绑定视频回放设备 */

  /** 快手商家列表 */
  private ksShopAuthList: any[] = []

  private searchShopAuthLoading: boolean = false;

  private remoteGetKsShopAuthQueryString: string = "";

  private remoteSearchKsShopAuthTimer: any = null;

  private searchKsShopAuthPageOption: any = {};

  private initRemoteGetKsShopAuthPageOption() {
    this.searchKsShopAuthPageOption = {
      ...this.deepLoopUtil.deepCopy(defaultSearchCommonPageOption, false),
    };
  }

  private getSearchKsShopAuthParams() {
    const searchKsShopAuthPageOption = this.searchKsShopAuthPageOption;

    const remoteGetKsShopAuthQueryString = this.remoteGetKsShopAuthQueryString;

    const { page, limit } = searchKsShopAuthPageOption;

    const params: any = {
      page,
      limit,
    };

    if (remoteGetKsShopAuthQueryString && remoteGetKsShopAuthQueryString.trim()) {
      params.kuaishou_merchant_id = remoteGetKsShopAuthQueryString.trim();
    }

    return params;
  }

  private getMoreKsShopAuthList(refresh: boolean = true) {
    return new Promise((resolve: Function, reject: Function) => {
      if (refresh) {
        this.initRemoteGetKsShopAuthPageOption();
      }

      const params = this.getSearchKsShopAuthParams();

      this.rechargeRecordApi.searchKsShopAuthList(
        params,
        (res: any) => {
          console.log(res, "res");
          const {
            list
          } = res.data || {}

          const ksShopAuthList: any[] = list || [];

          this.ksShopAuthList = refresh
            ? ksShopAuthList
            : this.ksShopAuthList.concat(ksShopAuthList);

          if (Array.isArray(res.data) && res.data.length == 0) {
            this.searchKsShopAuthPageOption.isRearchBottom = true;
          }
          this.searchShopAuthLoading = false;

          this.addKsShopAuthListLoading = false;

          resolve();
        },
        () => {
          this.searchShopAuthLoading = false;

          this.addKsShopAuthListLoading = false;

          reject();
        }
      );
    });
  }

  // 第一次搜索 无关键字
  private handleFirstGetKsShopAuthList() {
    this.searchShopAuthLoading = true;

    this.getMoreKsShopAuthList(true);
  }

  // 清空搜索
  private handleClearGetKsShopAuthList() {
    this.remoteGetKsShopAuthQueryString = "";

    this.searchShopAuthLoading = true;

    this.getMoreKsShopAuthList(true);
  }

  // 远程搜索
  private remoteGetKsShopAuthMethod(query: string) {
    query = query.trim ? query.trim() : "";

    if (query !== "") {
      this.remoteGetKsShopAuthQueryString = query;
      clearTimeout(this.remoteSearchKsShopAuthTimer);

      this.remoteSearchKsShopAuthTimer = setTimeout(() => {
        this.searchShopAuthLoading = true;

        this.getMoreKsShopAuthList();
        // searchStu((res) => {
        //   const {
        //     students,
        //     total_count
        //   } = res || {}

        //   this.remoteAllStudents = [
        //     ...(students || [])
        //   ]

        //   this.remoteStudentTotalCount = total_count || 0

        //   this.remoteGetStudentLoading = false
        // }, {
        //   kw: query,
        //   ...this.getRemoteGetStudentPageOptionParams()
        // }, () => {
        //   this.remoteGetStudentLoading = false
        // })
      }, 300);
      // setTimeout(() => {
      //   this.remoteGetStudentLoading = false;
      //   this.options = this.list
      // }, 200);
    } else {
      this.remoteGetKsShopAuthQueryString = "";
      this.ksShopAuthList = [];
    }
  }

  private addKsShopAuthListLoading: boolean = false;

  private remoteAddVenueMethod() {
    if (this.addKsShopAuthListLoading) return;
    const searchKsShopAuthPageOption = this.searchKsShopAuthPageOption;

    const { isRearchBottom } = searchKsShopAuthPageOption;

    if (isRearchBottom) return;

    this.searchKsShopAuthPageOption.page++;

    this.addKsShopAuthListLoading = true;

    this.getMoreKsShopAuthList(false);
  }

  private async handleRefreshKuaishouOtherInfo(shopAuth: any) {
    console.log(shopAuth, 'shopAuth')
    await this.refreshKsAuthOfflineShopList()

    const kuaishouPoiList = this.kuaishouPoiList

    if (Array.isArray(kuaishouPoiList) && kuaishouPoiList.length > 0) {
      this.$set(this.storeinfo, 'kuaishou_poi_id', kuaishouPoiList[0].poi_id)
    }
  }

  /** end 快手商家列表 */

  /** 快手店铺列表 */
  private kuaishouPoiList: any[] = []
  private refreshKsAuthOfflineShopList() {
    const storeinfo = this.storeinfo
    const {
      ksAuth
    } = storeinfo || {}

    const {
      kuaishou_auth_id
    } = ksAuth || {}

    console.log(ksAuth, 'ksAuth')

    if (typeof kuaishou_auth_id !== 'number') return Promise.resolve()

    return new Promise((resolve, reject) => {
      this.rechargeRecordApi.getKsOfflineShopList({
        kuaishou_auth_id
      }, (res: any) => {
        const {
          list = []
        } = res.data || {}

        this.kuaishouPoiList = list

        resolve(list)
      }, (err: any) => {
        reject(err)
      })
    })
    
  }
  /** end 快手店铺列表 */

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 用户字段名 文案对应关系
  private userExcelList2ch: any = {
    orderNo: "订单编号",
    nickname: "用户昵称",
    arrivalAmount: "充值金额",
    // inviter: "邀请人",
    paymentAmount: "支付金额",
    payStyle: "支付方式",
    payTime: "支付时间",
  };
  // 用户Excel 表头的先后顺序
  private userExcelSortList: string[] = [
    "orderNo",
    "nickname",
    "arrivalAmount",
    // "inviter",
    "paymentAmount",
    "payStyle",
    "payTime",
  ];

  /** end 导出excel */

  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  private device(id: string, aqara_id: string): void {
    this.$router.push({
      path: "/device",
      query: {
        id: id,
        aqara_id: aqara_id,
      },
    });
  }
  private air_config(id: string, aqara_id: string): void {
    this.$router.push({
      path: "/air_config",
      query: {
        id: id,
        aqara_id: aqara_id,
      },
    });
  }
  private air_config_set(id: string, aqara_id: string): void {
    this.$router.push({
      path: "/air_config_set",
      query: {
        id: id,
        aqara_id: aqara_id,
      },
    });
  }

  private singboardLightManage(id: string, aqara_id: string): void {
    this.$router.push({
      path: "/signboardLightManage",
      query: {
        id: id,
        aqara_id: aqara_id,
      },
    });
  }

  private merchantBindOperator(row: any) {
    this.$router.push({
      path: "/merchantOperationerList",
      query: {
        id: row.id,
      },
    });
  }
}

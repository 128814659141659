
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getPageAPIs } from "@/common/util";
// import { screen } from "@/common/screen/screen";
import OpenCityManageAPI from "@/common/api/operationManage/openCity";
// import { CommonApi } from "@/common/api/common";
import { excuteDownloadExport2Excel } from "@/common/xlsx";
import { utils } from "xlsx";

import {
  shareConfirmOptions
} from '@/common/config'

export const pageApiList = getPageAPIs(OpenCityManageAPI)

@Component({
  filters: {
   
  }
})
export default class OpenCityManageList extends Vue {
  private form: any = {
    // channelId: "", // 渠道id
    // time: [], // 时段选择
  };
  private openCityManageApi: any = new OpenCityManageAPI();

  // private commonApi: any = new CommonApi();
  private created(): void {
    // this.getofficialChannel();
    // this.search();

    this.getTable()
  }
  private getTable(): void {
    this.openCityManageApi.getOpenCitiesConfigList(
      {
        // ...this.form,
        // startDate: startTime,
        // endDate: endTime,
        page: this.tablePage.pageNo,
        limit: this.tablePage.pageSize,
      },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.pageNo = res.data.page;
        this.tablePage.pageSize = res.data.prepage;
        this.tablePage.totalCount = res.data.count;
      }
    );
  }

  private restForm(): void {
    // this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private openCityForm: any = {
 
    city_id: ''
  }

  private chooseCityList: any[] = []

  private initOpenCityDialogForm(mode: string = 'edit', currentCofigItem: any) {
    const openCityForm: any = {
      city_id: ''
    }

    this.openCityDialogTitle = '新增'

    const chooseCityListParams: any = {
      operation: 'add'
    }

    if (mode == 'edit') { // 编辑状态
      chooseCityListParams.operation = 'save'
      this.openCityDialogTitle = '编辑'

      const {
        city_id,
        id
      } = currentCofigItem

      chooseCityListParams.id = id
      

      openCityForm.city_id = city_id

      openCityForm.id = id
    }

    this.openCityManageApi.getChooseCityList(chooseCityListParams, (res: any) => {
      const {
        data
      } = res

      const {
        list
      } = data

      this.chooseCityList = list
    }, () => {

    })

    this.openCityForm = openCityForm
  }

  // 编辑充值项
  private handleEditRechargeItem(openCityItem: any) {
    console.log(openCityItem, 'openCityItem')
    this.openCityDialogMode = 'edit'
    this.initOpenCityDialogForm(this.openCityDialogMode, openCityItem)

    this.openCityDialogVisible = true

    this.$nextTick(() => {
      this.clearvaliateOpenCityForm()
    })
  }

  // 拉起 充值弹框
  private handleWakeAddOpenCityDialog() {
    this.openCityDialogMode = 'create'
    this.initOpenCityDialogForm(this.openCityDialogMode, null)

    this.openCityDialogVisible = true

    this.$nextTick(() => {
      this.clearvaliateOpenCityForm()

    })
  }

  // 清空表单的校验
  private clearvaliateOpenCityForm() {
    const refs: any = this.$refs

    const {openCityForm} = refs

    const {
      clearValidate
    } = openCityForm

    if (typeof clearValidate == 'function') {
      clearValidate()
    }
  }

  // 充值项的参数
  private getOpenCityFormParams(): any {
    const params: any = {}

    const openCityForm = this.openCityForm

    console.log(openCityForm, 'openCityForm')

    const {
      city_id,
      id,
    } = openCityForm

    if (city_id) {
      params.city_id = city_id
    }

    if (id) {
      params.id = id
    }

    // console.log(params, 'params')


    // return false

    return params
  }

  private openCityDialogMode: string = "create"; // 新增 create  编辑 edit

  private openCityDialogTitle: string = '新增'

  private openCityDialogVisible: boolean = false

  // 充值项的修改
  private sumbitRechargeLoading: boolean = false

  // 提交充值项
  private handleSumbitRecharge() {
    const refs: any = this.$refs

    const {openCityForm} = refs

    openCityForm.validate((valid: boolean) => {
      if (valid) {
        const params = this.getOpenCityFormParams()

        if (!params) { return }

        this.sumbitRechargeLoading = true

        const openCityDialogMode = this.openCityDialogMode

        if (openCityDialogMode == 'edit') { // 编辑充值配置
          this.openCityManageApi.updateOpenCityConfig(params, () => {
            this.sumbitRechargeLoading = false

            this.openCityDialogVisible = false
            this.getTable()
          }, () => {
            this.sumbitRechargeLoading = false
          })
        } else if (openCityDialogMode == 'create'){
          this.openCityManageApi.createOpenCityConfig(params, () => {
            this.sumbitRechargeLoading = false

            this.openCityDialogVisible = false
            this.getTable()
          }, () => {
            this.sumbitRechargeLoading = false
          })
        }

      } else {
        this.$message.error("带 * 项为必填项")
      }
    })
    
  }

  /** 导出excel */
  // private downloadExcelLoading: boolean = false;

  // // channel渠道字段名 文案对应关系
  // private merchantExcelList2ch: any = {
  //   channelId: '渠道id',
  //   channelName: "渠道名称",
  //   date: "日期",
  //   followNum: "扫码关注数量",
  //   registerNum: "注册数量",
  //   LoginNum: "登录用户数",
  //   completeOrderNum: '完成订单数',
  //   completeOrderAmount: '完成订单金额',
  //   completeOrderPeopleNum: '完成订单人数',
  //   notCompleteNum: '未完成订单数',
  //   notCompleteAmount: '未完成订单金额',
  //   notCompletePeopleNum: '未完成订单数人数',
  //   deviceNum: '新增设备数',
  //   newUserCompleteOrderNum: '新用户完成订单数',
  //   newUserCompleteOrderAmount: '新用户完成订单支付金额',
  //   newUserCompleteOrderPeopleNum: '新用户完成订单人数',
  //   newUserNotCompleteNum: '新用户未完成订单数',
  //   newUserNotCompleteAmount: '新用户未完成订单金额',
  //   newUserNotCompletePeopleNum: '新用户未完成订单人数',
  //   timeoutOrderNum: '超时取消订单数',
  //   timeoutNotPayAmount: '超时未支付金额',
  //   timeoutPeopleNum: '超时取消订单人数',
  //   activeNum: '活跃人数',
  //   retentionPeopleNum: '次日留存人数',
  //   retentionRate: '次日留存率',
  //   totalPaymentPeopleNum: '总付费人数',
  //   totalPaymentRate: '总付费率',
  //   averagePrice: '平均单价',
  //   arpu: 'ARPU',
  //   arppu: 'ARPPU'
  // };
  // // 商户Excel 表头的先后顺序
  // private merchantExcelSortList: string[] = [
  //   "channelId",
  //   "channelName",
  //   "date",
  //   "followNum",
  //   "registerNum",
  //   "LoginNum",
  //   "completeOrderNum",
  //   "completeOrderAmount",
  //   "completeOrderPeopleNum",
  //   "notCompleteNum",
  //   "notCompleteAmount",
  //   "notCompletePeopleNum",
  //   "deviceNum",
  //   "newUserCompleteOrderNum",
  //   "newUserCompleteOrderAmount",
  //   "newUserCompleteOrderPeopleNum",
  //   "newUserNotCompleteNum",
  //   "newUserNotCompleteAmount",
  //   "newUserNotCompletePeopleNum",
  //   "timeoutOrderNum",
  //   "timeoutNotPayAmount",
  //   "timeoutPeopleNum",
  //   "activeNum",
  //   "retentionPeopleNum",
  //   "retentionRate",
  //   "totalPaymentPeopleNum",
  //   "totalPaymentRate",
  //   "averagePrice",
  //   "arpu",
  //   "arppu"
  // ];

  // // 点击导出excel 表格
  // private handleExportExcelChannelList() {
  //   const form = this.form
  //   const {
  //     time,
  //     channelId
  //   } = form

  //   const params: any = {
  //     channelId, 
  //   };
    
  //   if (Array.isArray(time) && time.length === 2) {
  //     params.startDate = Util.dateTimeUtilnohms(this.form.time[0])
  //     params.endDate = Util.dateTimeUtilnohms(this.form.time[1])
  //   } else {
  //     this.$message.error("请选择时段")

  //     return
  //   }

  //   if (params === null) {
  //     return false;
  //   }

  //   this.downloadExcelLoading = true;
  //   this.openCityManageApi.exportExcelChannelList(
  //     params,
  //     (res: any) => {
  //       const { startDate, endDate } = params || {};

  //       const filename = `商户列表 ${startDate}到${endDate}.xlsx`;
  //       const { data: originList } = res || {};

  //       // const {
  //       //   list: originList
  //       // } = data || {}

  //       const newList = Array.isArray(originList)
  //         ? originList.map((item) => {
  //             // const { orderNum } = item || {};
  //             const newItem = {
  //               ...(item || {}),
  //             };

  //             for (let key in item) {
  //               if (typeof item[key] == 'number') {
  //                 newItem[key] = item[key].toString()
  //               }
  //             }

  //             // newItem.orderNum =
  //             //   typeof orderNum == "number"
  //             //     ? orderNum.toString()
  //             //     : orderNum || "";

  //             return newItem;
  //           })
  //         : [];

  //       if (newList.length > 0) {
  //         const merchantExcelSortList = this.merchantExcelSortList;

  //         const merchantExcelList2ch = this.merchantExcelList2ch;

  //         const excelJSONData = getExcelJSONDataByList(
  //           newList,
  //           merchantExcelSortList,
  //           merchantExcelList2ch
  //         );

  //         excuteDownloadExport2Excel(excelJSONData, filename);
  //       } else {
  //         this.$message.info("选中时段没有可导出的信息");
  //       }

  //       // const testData = [
  //       //   { 主页: "test1223424", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
  //       //   { 主页: "433", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
  //       //   { 名称: "22", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
  //       //   { 名称: "43", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
  //       //   { 店家: "43", 价格: "6800", 数量: "6800", 昵称: "广告主网", }
  //       // ]

  //       this.downloadExcelLoading = false;
  //     },
  //     () => {
  //       this.downloadExcelLoading = false;
  //     }
  //   );
  // }

  /** end 导出excel */
}

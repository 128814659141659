import {BasicsClass} from '@/common/BasicsClass';

export class BusinessLimitWarning extends BasicsClass {
    public apiUrls: any = new Map([
        ['list', {
            url: '/admin/turnover_warning/lists',
            name: 'list',
            label: '营业额预警列表'
        }],
        ['add', {
            url: '/admin/turnover_warning/add',
            name: 'add',
            label: '新增营业额预警'
        }],
        ['edit', {
            url: '/admin/turnover_warning/update',
            name: 'edit',
            label: '修改营业额预警'
        }],
        ['delete', {
            url: '/admin/turnover_warning/delete',
            name: 'delete',
            label: '删除营业额预警'
        }],
        ['handleList', {
            url: '/admin/turnover_warning/handleLists',
            name: 'handleList',
            label: '处理列表'
        }],
        ['handleFinish', {
            url: '/admin/turnover_warning/handle',
            name: 'handleFinish',
            label: '处理完成'
        }],
        ['exportHandleLists', {
            url: '/admin/turnover_warning/exportHandleLists',
            name: 'exportHandleLists',
            label: '导出'
        }],
        ['searchVenueList', {
            url: '/admin/coupons/shop/list',
            name: 'shop-list',
            label: '店铺列表',
        }]

    ]);

    public businessLimitWarningList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls;

        const url = apiUrls.get('list').url;

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading);
    }

    public addBusinessLimitWarning(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls;

        const url = apiUrls.get('add').url;

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading);
    }

    public editBusinessLimitWarning(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls;

        const url = apiUrls.get('edit').url;

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading);
    }

    public deleteBusinessLimit(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls;

        const url = apiUrls.get('delete').url;

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading);
    }

    public handleList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls;

        const url = apiUrls.get('handleList').url;

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading);
    }

    public handleFinish(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls;

        const url = apiUrls.get('handleFinish').url;

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading);
    }

    public exportList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls;

        const url = apiUrls.get('exportHandleLists').url;

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading);
    }


    // 搜索店铺列表
    public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls;

        const url = apiUrls.get('searchVenueList').url;

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading);
    }
}
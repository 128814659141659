
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Util, getExcelJSONDataByList, getPageAPIs} from "@/common/util";
import {PandaCoinManageAPIClass} from "@/common/api/operationManage/pandaCoinManage";
import gallery from "@/components/gallery.vue";
import {DeepLoop} from "@/common/deepLoop/deepLoop";

import {
  chooseShopTagList, shareConfirmOptions
} from '@/common/config';

import amap from "@/components/amap.vue";

export const pageApiList = getPageAPIs(PandaCoinManageAPIClass);

interface Form {
  name:string,
  panda_coin:number | string,
  price:number | string,
  sort:string,
  type:number | string,
  status:number | string,
  product_id?:number | string | undefined,
  id?:number | string | undefined
}

@Component({
  components: {
    gallery,
  },
})
export default class PandaCoinManageList extends Vue {
  private form: any = {
    name:'',
  };
  private dialogType:string = 'add'
  private dialogVisible: boolean = false;
  private addForm: Form = {
    name:'',
    panda_coin:'',
    price:'',
    sort:'',
    type:0,
    status:1
  };
  private pandaCoinApi: any;
  constructor() {
    super();
    this.pandaCoinApi = new PandaCoinManageAPIClass();
  }

  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    this.pandaCoinApi.getPandaCoinTableList(
        {
          ...this.form,
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
        }
    );
  }

  private restForm(): void {

    this.form.name = ''
  }

  // 删除
  private clickDeletePandaCoin(item: any) {
    this.$confirm(`确定删除该${item.name}？`, '', {
      ...shareConfirmOptions
    }).then(() => {
      this.pandaCoinApi.deletePandaCoin({
            id: item.id
          },
          () => {
            Util.showToast("删除成功");
            this.search();
          }
      )
    })
  }



  private addstore(item: any): void {
    this.dialogType = 'add'
    this.dialogVisible = true
    this.$nextTick(()=>{
      this.addForm = {
        name:'',
        panda_coin:'',
        price:'',
        sort:'',
        type:0,
        status:0
      }
    })
  }

  private clickEditPandaCoin(item:any):void{
    this.dialogType = 'edit'
    this.dialogVisible = true
    this.pandaCoinApi.getPandaCoinView({id:item.id}, (res: any) => {
      const data = res.data
      this.addForm = {
        name:data.name,
        panda_coin:data.panda_coin,
        price:data.price,
        sort:data.sort,
        type:data.type,
        status:data.status,
        product_id:data.product_id,
        id:item.id
      }
    });

  }
  private handleClose(done: any): void {
    this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {
        });
  }


  private substore(): void {
    if(this.addForm.type===1 && !this.addForm.product_id){
      Util.showToast("苹果内购ID",'error');
      return
    }
    console.log('this.addForm',this.addForm)
    if(this.dialogType === 'edit'){
      this.pandaCoinApi.updatePandaCoin(this.addForm, (res: any) => {
        this.dialogVisible = false;
        Util.showToast("修改成功");
        this.search();
      });
    }else{
      this.pandaCoinApi.addPandaCoin(this.addForm, (res: any) => {
        this.dialogVisible = false;
        Util.showToast("添加成功");
        this.search();
      });
    }

  }


  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

}

import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class RobotSettingAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getRobotSettingList', {
      url: '/admin/robotSet/list',
      name: 'list',
      label: '列表'
    }],
    ['updateRobotSetting', {
      url: '/admin/robotSet/edit',
      name: 'edit',
      label: '编辑机器人设置'
    }],
    ['addRobotSetting', {
      url: '/admin/robotSet/add',
      name: 'add',
      label: '添加机器人设置'
    }],
    ['deleteRobotSetting', {
      url: '/admin/robotSet/delete',
      name: 'delete',
      label: '删除机器人设置'
    }],

  ])
  // 机器人设置列表
  public getRobotSettingList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getRobotSettingList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 修改机器人设置
  public updateRobotSetting(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('updateRobotSetting').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 添加机器人设置
  public addRobotSetting(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('addRobotSetting').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 删除机器人设置
  public deleteRobotSetting(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('deleteRobotSetting').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}
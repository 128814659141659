
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList,getPageAPIs } from "@/common/util";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import {OrderApi} from "@/common/api/userManage/goodsOrderList";

import { excuteDownloadExport2Excel } from "@/common/xlsx";
export const pageApiList = getPageAPIs(OrderApi);

const defaultForm = {
  goods_order_id: "", // 订单ID
  order_no: "", // 订单编号
  phone: '', // 用户手机号
  user_id: "", // 用户ID
  shop_id: "", // 店铺ID
  shop_name: "", // 店铺名称
  listSortType: -1, // 顾客订单状态

  create_time: [], // 创建时间
  
};

const orderStatus2Ch: any = [
  '',
  '未付款',
  '已付款',
  '申请退款',
  '已退款',
  '已取消',
  '',
  '',
  '异常订单'
]

@Component({
  filters: {
    getOrderStatusText(status: number): string {
      return orderStatus2Ch[status] || ''
    }
  }
})
export default class GoodsOrderList extends Vue {
  private orderApi: any;

  private deepLoopUtil: any;

  constructor() {
    super();
    this.orderApi = new OrderApi();

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
  }

  private form: any;

  private firstLoading: boolean = true;
  // private chooseTechnicianItem : any = null;

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  // private show: boolean = false;
  // 订单状态 1未付款  2已付款 3申请退款 4已退款
  private sortTypeList: any[] = [
    {
      value: -1,
      label: "全部",
    },
    {
      label: "未付款",
      value: 1,
    },
    {
      label: "已付款",
      value: 2,
    },
    {
      label: "申请退款",
      value: 3,
    },
    {
      label: "已退款",
      value: 4,
    },
    {
      label: "已取消",
      value: 5,
    },
    {
      label: '异常订单',
      value: 8
    }
  ];

  // 所有可选技师
  private technicianList: any[] = [];

  private activated(): void {
    this.getTable();
    
  }

  private getTable(): void {
    this.orderApi.getOrderTableList(
      this.getOrderTableListParams(),
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        const { list, count, page, prepage } = data || {};

        const newTableList = Array.isArray(list)
          ? list.map(this.handleTableData)
          : [];

        // totalCount: 0,
        // pageNo: 1,
        // pageSize: 10,
        this.$set(this.tablePage, "totalCount", count);
        this.$set(this.tablePage, "pageNo", page);
        this.$set(this.tablePage, "pageSize", prepage);

        this.tableData = newTableList;

        this.firstLoading = false;
      },
      () => {
        this.firstLoading = false;
      },
      false
    );
  }

  private getOrderTableListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const firstLoading = this.firstLoading;

    const {
      goods_order_id, // 订单ID
      order_no, // 订单编号
      phone, // 用户手机号
      user_id, // 用户ID
      shop_id, // 店铺ID
      shop_name, // 店铺名称
     
      listSortType, // 顾客订单状态

      create_time, // 创建时间
    } = form || {};


    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};

    if (goods_order_id) {
      outerParams.goods_order_id = goods_order_id;
    }

    if (order_no) {
      outerParams.order_no = order_no;
    }

    if (phone) {
      outerParams.phone = phone;
    }

    if (user_id) {
      outerParams.user_id = user_id - 0;
    }

    if (shop_id) {
      outerParams.shop_id = shop_id - 0;
    }

    if (shop_name) {
      outerParams.shop_name = shop_name
    }

    if (listSortType != -1 && typeof listSortType == "number") {
      outerParams.status = listSortType;
    }

    // if (technicianItem && technicianItem.id) {
    //   outerParams.artificerId = technicianItem.id;
    // }

    if (Array.isArray(create_time) && create_time.length === 2) {
      const [startDate, endDate] = create_time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      outerParams.start_date = startTime;

      outerParams.end_date = endTime;
    }

    const route = this.$route;

    const { query } = route;

    const { userId } = query;

    if (userId) {
      outerParams.userId = userId;
    }

    if (
      mode == "exportExcel" &&
      !(outerParams.start_date && outerParams.end_date)
    ) {
      this.$message.error("请选择创建时段导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      outerParams.__isUnique = !firstLoading;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  private getOrderTotalMins(row: any) {
    const { daytime_minute, night_minute } = row || {};

    function tranformMinNums(mins: any) {
      return isNaN(Number(mins)) ? 0 : Number(mins);
    }

    return tranformMinNums(daytime_minute) + tranformMinNums(night_minute);
  }


  private getOrderSpendMoney(row: any) {
    const { price, refunded_price } = row;

    return (price - refunded_price).toFixed(2);
  }

  

  // 后台返回的字段 转化为前端定义的字段
  private handleTableData(data: any): any {
    // const { device, pay_type, user_type } = data;
    const newData = {
      ...data,
      // device_name: orderDeviceType2ch[device],
      // pay_type_name: payType2ch[pay_type],
      // user_level_type_name: userLevel2ch[user_type],
    };

    return newData;
  }


  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 订单字段名 文案对应关系
  private orderExcelList2ch: any = {
    id: "订单ID",
    order_no: "订单编号",
    created_at: "创建时间",
    callback_time: "付款时间",
    user_name: "用户昵称",
    user_id: "用户ID",
    phone: "用户手机号",
    shop_id: "店铺ID",
    shop_name: "店铺名称",
    __good_details: "商品信息",
    product_price: "总价（元）",
    __status_name: "订单状态",
    fencheng_rate: "商家分成比例（%）",
    income: "商家最终收益",
   
  };
  // 订单Excel 表头的先后顺序
  private orderExcelSortList: string[] = [
    "id",
    "order_no",
    "created_at",
    "callback_time",
    "user_name",
    "user_id",
    "phone",
    "shop_id",
    "shop_name",
    "__good_details",
    "product_price",
    "__status_name",
    "fencheng_rate",
    "income",
  ];

  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getOrderTableListParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.orderApi.exportExcelOrderList(
      params,
      (res: any) => {
        const {
          start_date,
          end_date,
   
        } = params || {};

        const filename = `商品订单列表 ${start_date}到${end_date}.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const {
                
                goods_detail,
                status
              } = item || {};

              const newItem = {
                ...(item || {}),
                
                __status_name: orderStatus2Ch[status],
                __good_details: goods_detail.map((goods: any, index: number) => `${index + 1}、${ goods.name }x${ goods.num }  ¥${ goods.price }`).join('; \t')
              };

              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const orderExcelSortList = this.orderExcelSortList;

          const orderExcelList2ch = this.orderExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            orderExcelSortList,
            orderExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */

  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
    // this.form = new Util().clearObject(this.form);
    // this.$router.replace("/orderList");
    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}

import { BasicsClass } from '@/common/BasicsClass';

export default class ChatBanListAPIClass extends BasicsClass {

    public apiUrls: any = new Map([
        ['getList', {
            url: '/admin/ban/list',
            name: 'list',
            label: '封禁列表'
        }],
        ['addBan', {
            url: '/admin/ban/add',
            name: 'add',
            label: '添加封禁'
        }],
        ['freeBan', {
            url: '/admin/ban/free',
            name: 'free',
            label: '解除封禁'
        }],

    ])
    // 封禁列表
    public getList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {

        params = {
            ...(params || {})
        }

        const apiUrls = this.apiUrls

        const url = apiUrls.get('getList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 添加封禁
    public addBan(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('addBan').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    // 解除封禁
    public freeBan(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('freeBan').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

}

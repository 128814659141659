
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import PandaMobileTable from "@/components/mobile-custom/panda-table.vue";

import PandaMobileTWFContainer from "@/components/mobile-custom/panda-table-with-filter-container.vue";

import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
// import { CouponsManageAPI } from "@/common/api/couponList";
import LeagueCourserListAPI from '@/common/api/operationManage/leagueCourserList';
 
import { excuteDownloadExport2Excel } from "@/common/xlsx";

import { DeepLoop } from "@/common/deepLoop/deepLoop";

import {
  shareConfirmOptions
} from '@/common/config'

export const pageApiList = getPageAPIs(LeagueCourserListAPI)



// const defaultSearchVenuePageOption = {
//   page: 1,
//   limit: 10,
//   isRearchBottom: false,
// };

// const gloryShopQRChargeDialogTitle2ch: any = {
//   "new-sale-machine": "新增售卖机",
//   "edit-sale-machine": "编辑售卖机",
// };

// 最大有效期
// const maxExpiredDays = 9999;

// 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;
// 状态：1提交订单 2已付款 3已取消 4已退款 5退款中
const orderStatus2Ch = [
  '',
  '提交订单',
  '已付款',
  '已取消',
  '已退款',
  '退款中'
]

const maxBenefitRate = 100

@Component({
  components: {
    PandaMobileTable,
    PandaMobileTWFContainer,
  },
  filters: {
    getOrderStatusText(status: number) {
      return orderStatus2Ch[status] || ''
    }
  }
})
export default class LeagueCourserChargesManage extends Vue {
  /** 移动端属性、方法 */
  private allMobileChooseFields: any[] = [
    {
      label: '注册手机号',
      field: 'phone'
    },
    {
      label: '真实姓名',
      field: 'real_name'
    },
    {
      label: '身份证号',
      field: 'identity_card'
    },
    {
      label: '地区',
      field: 'table-address'
    },
    {
      label: '用户名',
      field: 'bank_user_name'
    },
    {
      label: '银行',
      field: 'bank_name'
    },
    {
      label: '省市',
      field: 'province_city'
    },
    {
      label: '支行',
      field: 'branch_name'
    },
    {
      label: '银行卡号',
      field: 'bank_card'
    },
    {
      label: '第一次报名时间',
      field: 'pay_time'
    },
    {
      label: '操作',
      field: 'table-option'
    }
  ];

  /** end 移动端属性、方法 */

  private form: any = {
    phone: '',
    real_name: '',
    time: []
  };

  

  private deepLoopUtil: any = {};


  private leagueCourserListApi = new LeagueCourserListAPI();

  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

  }

  private created(): void {
    this.getLeagueCourserList();
  }
  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getLeagueCourserList();
  }
  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getLeagueCourserList();
  }

  private tableData: any[] = [];

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";
  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getLeagueCourserList();
  }
  
  //获取推广人列表
  private getLeagueCourserList(): void {
    const params: any = this.getLeagueCourserParams()
    this.leagueCourserListApi.getLeagueCourserList(
      { ...params },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page || params.page;
        this.tablePage.prepage = res.data.prepage || params.limit;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private getLeagueCourserParams(mode = 'all'): any {
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    const { real_name, phone, time } = form;

    if (real_name) {
      params.real_name = real_name;
    }

    if (phone) {
      params.phone = phone
    }

    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      params.pay_start_time = startTime;

      params.pay_end_time = endTime;
    }

    if (
      mode == "exportExcel" &&
      !(params.pay_start_time && params.pay_end_time)
    ) {
      this.$message.error("请选择时段导出数据");
      return null;
    }

    if (mode === "all") {
      if (page) {
        params.page = page;
      }

      if (prepage) {
        params.limit = prepage;
      }
    }

    return params;
  }

  // 查看详情
  handleGoLeagueCourserDetail(leagueCourserItem: any) {
    console.log(leagueCourserItem, 'leagueCourserItem')

    this.$router.push({
      path: '/leagueCourserDetailList',
      query: {
        user_id: leagueCourserItem.user_id
      }
    })
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 用户字段名 文案对应关系
  private userExcelList2ch: any = {
    phone: "注册手机号",
    real_name: "真实姓名",
    identity_card: "身份证号",
    __place_district: "地区",
    bank_user_name: "用户名",
    bank_name: "银行",
    province_city: "省市",
    branch_name: "支行",
    bank_card: "银行卡号",
    pay_time: "第一次报名时间"
  };
  // 用户Excel 表头的先后顺序
  private userExcelSortList: string[] = [
    "phone",
    "real_name",
    "identity_card",
    "__place_district",
    "bank_user_name",
    "bank_name",
    "province_city",
    "branch_name",
    "bank_card",
    "pay_time"
  ];

  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getLeagueCourserParams('exportExcel');

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.leagueCourserListApi.exportExcelLeagueCourserList(
      params,
      (res: any) => {
        const { pay_start_time, pay_end_time } = params || {};

        const filename = `参赛选手列表 ${pay_start_time}至${pay_end_time}.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const { 
                province_name,
                city_name,
                county_name
              } = item;
              const newItem = {
                ...(item || {}),
                __place_district: `${province_name}${city_name}${county_name}`
              };


              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const userExcelSortList = this.userExcelSortList;

          const userExcelList2ch = this.userExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            userExcelSortList,
            userExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */
}

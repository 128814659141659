import { BasicsClass } from '@/common/BasicsClass';

export default class MatchEntryFeeManageAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getMatchEntryFeeManageList', {
      url: '/admin/league_courser/record',
      name: 'list',
      label: '列表',
    }],
    ['exportMatchEntryFeeManageList', {
      url: '/admin/league_courser/exportRecord',
      name: 'export-excel',
      label: '导出',
    }],
  ]);
  // 列表
  public getMatchEntryFeeManageList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls;

    const url = apiUrls.get('getMatchEntryFeeManageList').url;

    this.BasicPost(url, {
      ...params,
    }, false, false, false, successCb, failCb, showLoading);
  }

  // 导出
  public exportMatchEntryFeeManageList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls;

    const url = apiUrls.get('exportMatchEntryFeeManageList').url;

    this.BasicPost(url, {
      ...params,
    }, false, false, false, successCb, failCb, showLoading);
  }
}

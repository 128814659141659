import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

import { CommonApi } from "../common";

export  class UserCasherConsumeApi extends BasicsClass {
  public apiUrls: any = new Map([
    ['getUserCasherConsumeTableList', {
        url: '/admin/userSpend/cashier',
        name: 'list',
        label: '列表'
    }],
    // debugger changeExport; 
    ['exportExcelUserCasherConsumeList', {
        url: '/admin/userSpend/exportCashier',
        name: 'export-excel',
        label: '导出'
    }],

])
  // 订单api 方法
  // momo达到家-订单列表
  public getUserCasherConsumeTableList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getUserCasherConsumeTableList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // momo达到家-导出订单列表
  public exportExcelUserCasherConsumeList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('exportExcelUserCasherConsumeList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }


}

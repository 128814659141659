import { BasicsClass } from '@/common/BasicsClass';
export class CouponsKsManageAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getKuaishouCoupons', {
            url: '/admin/coupons/kuaishouList',
            name: 'list',
            label: '列表'
        }],
        ['addKuaishouCoupons', {
            url: '/admin/coupons/kuaishouAdd',
            name: 'add',
            label: '添加'
        }],
        ['updateKuaishouCoupons', {
            url: '/admin/coupons/kuaishouEdit',
            name: 'edit',
            label: '编辑'
        }],
        ['delteKuaishouCouponItem', {
            url: '/admin/coupons/kuaishouDelete',
            name: 'delete',
            label: '删除'
        }],
        ['searchVenueList', {
            url: '/admin/coupons/shop/list',
            name: 'shop-list',
            label: '店铺列表',
            // hidden: true
        }],
        // ['getCouponTimeDurations', {
        //     url: '/admin/coupons/timeList',
        //     name: 'coupon-time',
        //     label: '优惠券可用时段',
        //     // hidden: true
        // }],
        ['poolTableCategory', {
            url: '/admin/poolTableCategory/list',
            name: 'pool-category',
            label: '台桌/包厢类型',
            // hidden: true
        }],
    ])


    // 新增抖音优惠券
    public addKuaishouCoupons(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('addKuaishouCoupons').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 修改抖音优惠券
    public updateKuaishouCoupons(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('updateKuaishouCoupons').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 抖音优惠券列表
    public getKuaishouCoupons(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getKuaishouCoupons').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 删除抖音优惠券
    public delteKuaishouCouponItem(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('delteKuaishouCouponItem').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 搜索店铺列表
    public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('searchVenueList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }


    //卡券时间段列表
    // public getCouponTimeDurations(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    //     const apiUrls = this.apiUrls

    //     const url = apiUrls.get('getCouponTimeDurations').url

    //     this.BasicPost(url, {
    //         ...params
    //     }, false, false, false, successCb, failCb, showLoading)
    // }

    //球桌分类列表
    public poolTableCategory(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('poolTableCategory').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}
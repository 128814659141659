import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class CoachGiftManageAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getCoachGiftManageList', {
      url: '/admin/gift/index',
      name: 'list',
      label: '列表'
    }],
    ['addCoachGiftManage', {
      url: '/admin/gift/add',
      name: 'add-gift',
      label: '添加助教礼物'
    }],
    ['updateCoachGiftManage', {
      url: '/admin/gift/edit',
      name: 'edit-gift',
      label: '编辑助教礼物'
    }],
    ['deleteCoachGiftManage', {
      url: '/admin/gift/del',
      name: 'delete-gift',
      label: '删除助教礼物'
    }],
    ['getCoachGiftDetail', {
      url: '/admin/gift/show',
      name: 'gift-detail',
      label: '助教礼物详情'
    }],
   
  ])

  // 助教礼物列表
  public getCoachGiftManageList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getCoachGiftManageList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 修改助教礼物
  public updateCoachGiftManage(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('updateCoachGiftManage').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 添加助教礼物
  public addCoachGiftManage(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('addCoachGiftManage').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 助教礼物详情
  public deleteCoachGiftManage(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('deleteCoachGiftManage').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 删除助教礼物
  public getCoachGiftDetail(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getCoachGiftDetail').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  
}
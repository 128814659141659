import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class MolisMerchantListAPI extends BasicsClass {

    public apiUrls: any = new Map([
        ['getMerchantTableList', {
            url: '/admin/molis/shop/lists',
            name: 'list',
            label: '列表'
        }],
      
        ['switchMolisMerchant', {
            url: '/admin/molis/switch',
            name: 'switch-shop-type',
            label: '切换店铺类型'
        }],
    ])
    // 商户列表
    public getMerchantTableList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        // const start_date = Util.handleStartTimeAndEndTime(params.time).startTime;
        // const end_date = Util.handleStartTimeAndEndTime(params.time).endTime;

        params = {
            ...(params || {}),
            // start_date: start_date,
            // end_date: end_date
        }

        const apiUrls = this.apiUrls

        const url = apiUrls.get('getMerchantTableList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

   

    // 切换店铺类型
    public switchMolisMerchant(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('switchMolisMerchant').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}
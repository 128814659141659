import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class StorageLockerApi extends BasicsClass {
  public apiUrls: any = new Map([
    ['getStorageLockerList', {
      url: '/admin/shopBox/index',
      name: 'list',
      label: '列表'
    }],
    ['sanYouDevice', {
      url: '/admin/aqara/sanYouDevice',
      name: 'sanYou-device-list',
      label: '授权闪优设备列表',
  }],
    ['addStorageLocker', {
      url: '/admin/shopBox/addBind',
      name: 'add',
      label: '添加'
    }],
    ['updateStorageLocker', {
      url: '/admin/shopBox/editBind',
      name: 'edit',
      label: '编辑'
    }],
    ['deleteStorageLocker', {
      url: '/admin/shopBox/delBind',
      name: 'delete',
      label: '删除'
    }],
    ['searchVenueList', {
      url: '/admin/coupons/shop/list',
      name: 'shop-list',
      label: '店铺列表',
      // hidden: true
    }],
    ['exportExcelStoragelockerList', {
      url: '/admin/shopBox/export',
      name: 'export-excel',
      label: '导出',
    }],
  ])

  // 台球柜列表
  public getStorageLockerList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getStorageLockerList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 闪优设备列表
  public sanYouDevice(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('sanYouDevice').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
}

  // 修改台球柜
  public updateStorageLocker(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('updateStorageLocker').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 添加台球柜
  public addStorageLocker(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('addStorageLocker').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 删除台球柜
  public deleteStorageLocker(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('deleteStorageLocker').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 搜索店铺列表
  public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('searchVenueList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 导出球杆柜管理列表
  public exportExcelStoragelockerList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('exportExcelStoragelockerList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}
import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class MerchantApplyCompetitionAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getList', {
            url: '/admin/league_courser/review',
            name: 'list',
            label: '列表'
        }],
        ['updateMerchantApplyCompetitionPassOrRejectStatus',  {
            url: '/admin/league_courser/reviewAudit',
            name: 'audit-status',
            label: '审核是否通过'
        }],
       
        // ['exportExcelRefundAuditList', {
        //     url: '/admin/seller/exportRefundList',
        //     name: 'export-excel',
        //     label: '导出'
        // }]
    ])
    
    // momo达到家-助教基本信息审核列表
    public getList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 处理问题状态更新
    public updateMerchantApplyCompetitionStatus(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        
        const auditStatus: any = {
            'agree': 2,
            'disagree': 3
        }

        let url: string = apiUrls.get('updateMerchantApplyCompetitionPassOrRejectStatus').url

        params.audit_status = auditStatus[params.mode]

        delete params.mode


        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading);
    }

    // // 退款审核列表 导出
    // public exportExcelRefundAuditList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    //     const apiUrls = this.apiUrls

    //     const url = apiUrls.get('exportExcelRefundAuditList').url

    //     this.BasicPost(url, {
    //         ...params
    //     }, false, false, false, successCb, failCb, showLoading)
    // }
}
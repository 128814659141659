import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

import { CommonApi } from "../common";

export class AccounMixCheckAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getAccounMixCheckRecordList', {
      url: '/admin/deduction/record',
      name: 'list',
      label: '列表'
    }],
    ['exportExcelAccounMixCheckList', {
      url: '/admin/deduction/record/export',
      name: 'export-excel',
      label: '导出'
    }],
    ['customDecreaseAccounMix', {
      url: '/admin/deduction/deduct',
      name: 'custom-decrease-mix-account',
      label: '自定义混合类型扣款'
    }],
    ['getAccounMixInfo', {
      url: '/admin/deduction/balance',
      name: 'get-mix-account',
      label: '混合类型余额',
    }],

    ['searchVenueList', {
      url: '/admin/deduction/operate_partner/shop',
      name: 'shop-list',
      label: '手机号绑定店铺列表',
      // hidden: true
    }],
  ])
  // 订单api 方法
  // 商家提现余额扣款记录列表
  public getAccounMixCheckRecordList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getAccounMixCheckRecordList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

 
  // 导出商家提现余额扣款记录列表
  public exportExcelAccounMixCheckList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('exportExcelAccounMixCheckList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  public getAccounMixInfo(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getAccounMixInfo').url
    
    Object.assign(params, {
      __customError: true
    })

    this.BasicPost(url, {
      ...params
    }, false, true, false, successCb, failCb, showLoading)
  }

  // 自定义商家提现余额扣款
  public customDecreaseAccounMix(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('customDecreaseAccounMix').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 搜索店铺列表
  public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('searchVenueList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}

import { BasicsClass } from '@/common/BasicsClass';
export class OpenShopFinanceListAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getOpenSFinanceList', {
            url: '/admin/marketOrder/financeList',
            name: 'list',
            label: '列表'
        }],
        // ['addOpenSFinanceList', {
        //     url: '/admin/marketOrder/add',
        //     name: 'add',
        //     label: '添加'
        // }],
        ['updateOpenSFinanceList', {
            url: '/admin/marketOrder/financeEdit',
            name: 'edit',
            label: '编辑'
        }],
        ['exportExcelOpenSFinanceList', {
            url: '/admin/marketOrder/exportFinanceList',
            name: 'export-excel',
            label: '导出'
        }],
       
    ])

    //市场部开店财务 编辑
    public getOpenSFinanceList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getOpenSFinanceList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    //编辑市场部 开店财务
    public updateOpenSFinanceList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('updateOpenSFinanceList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 导出市场部开店财务
    public exportExcelOpenSFinanceList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('exportExcelOpenSFinanceList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    // 删除 市场部数据统计
    // public deleteOpenSFinanceListItem(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    //     const apiUrls = this.apiUrls

    //     const url = apiUrls.get('deleteOpenSFinanceListItem').url

    //     this.BasicPost(url, {
    //         ...params
    //     }, false, false, false, successCb, failCb, showLoading)
    // }

 
}
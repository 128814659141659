import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

import { CommonApi } from "../common";

export default class DepartmentAPI extends BasicsClass {

  public apiUrls: any = new Map([
    ['getDepartmentTableList', {
        url: '/admin/admin_department/list',
        name: 'list',
        label: '列表'
    }],
    ['addDepart',  {
        url: '/admin/admin_department/add',
        name: 'add',
        label: '添加'
    }],
    ['editDepart', {
        url: '/admin/admin_department/edit',
        name: 'edit',
        label: '编辑'
    }],
    ['deleteDepart', {
      url: '/admin/admin_department/delete',
      name: 'delete',
      label: '删除'
    }],
    ['getDepartRightMenuList', {
      url: '/admin/permissions/manageMenu',
      name: 'get-depart-rightmenu-list',
      label: '获取部门权限菜单列表'
    }],
    ['editDepartRightmenus', {
      url: '/admin/permissions/save',
      name: 'edit-depart-rightmenu',
      label: '编辑部门权限菜单'
    }]
  ])

  public getDepartmentTableList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    // console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getDepartmentTableList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  public addDepart(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    // console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('addDepart').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  public editDepart(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    // console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('editDepart').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  public deleteDepart(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    // console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('deleteDepart').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  public getDepartRightMenuList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    // console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getDepartRightMenuList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  public editDepartRightmenus(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    // console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('editDepartRightmenus').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import { OpenShopFinanceListAPI } from "@/common/api/marketManage/openShopFinanceList";

// import { util } from "vue/types/umd";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import ZlSelect from "@/components/third-custom-components/select";

import ZlOption from "@/components/third-custom-components/option";

import { excuteDownloadExport2Excel } from "@/common/xlsx";

import {
  shareConfirmOptions,
  shareShopType2ch,
  toChineseNumber,
  // defaultWeekList,
  getCommonList,
} from "@/common/config";





// 暴露的API 列表
export const pageApiList = getPageAPIs(OpenShopFinanceListAPI);

console.log(pageApiList, "pageApiList");

const defaultForm = {
  shop_name: "", // 店铺/客户名称

  province: "", // 省份

  city: "", // 城市

  team: "", // 团队

  contract_person: "", // 签约人

  finance_status: 0, // 财务状态:0全部1未付款2部分付款3已付清


  sign_time: [], // 签约日期

  address_time: [], // 选址日期

  bussiness_time: [], // 开业日期


  // customer_name: "", // 客户名称

  // phone: "", // 电话

  // market_source_ids: [], // 线索来源id
};



// 财务状态:0全部1未付款2部分付款3已付清
const financeStatus2Ch: string[] = ["", "未付款", "部分付款", "已付清"]

const defaultForms = {
  received_price: "", // 已收金额

  // dataStatistics_time_id: "",
  // singleMaxCountType: '0',
  // shop_id: "",
  // maxLimitCount: ''
};

// const defaultSearchVenuePageOption = {
//   page: 1,
//   limit: 10,
//   isRearchBottom: false,
// };

const dataStatisticDialogTitle2ch: any = {
  "new-open-shop-finance": "新增",
  "edit-open-shop-finance": "编辑",
};

// 最大有效期
// const maxExpiredDays = 9999;

// // 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;

// // dataStatistic 开店财务分属类别
// const dataStatisticShopType2ch: any = [
//   ...shareShopType2ch
// ]

@Component({
  filters: {
    showFinaceStatusName(status: number) {
      return financeStatus2Ch[status] || '未知'
    },
  },
  components: {
    ZlSelect,
    ZlOption,
  },
})
export default class OpenSFinanceListManage extends Vue {
  private form: any = {
    // dataStatisticId: "",
    shop_name: "",
    // time: [],
    // startDate: "",
    // endDate: "",
  };
  private forms: any = {
    // maxType: "0",
    // ...defaultForms
  };

  private deepLoopUtil: any = {};

  // private merchantApi: any;

  // private shopTypeList: any[] = [
  //   {
  //     label: "台球",
  //     value: 1,
  //   },
  //   {
  //     label: "棋牌",
  //     value: 2,
  //   },
  // ];

  private openShopFinanceListApi = new OpenShopFinanceListAPI();
  // private dataStatisticTimeDurationAPI = new OpenSFinanceListTimeDurationManageAPI();

  // private chooseWeekDayList: any[] = []

  private financeStatusList: any[] = [
    ...getCommonList(financeStatus2Ch, 1)
  ]

  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    // this.searchVenuePageOption = {
    //   ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    // };

    // this.chooseWeekDayList = [
    //   ...this.deepLoopUtil.deepCopy(defaultWeekList, false)
    // ]

    // this.merchantApi = new MerchantApi();
  }

  private created(): void {
    // this.refreshClueSourceList();

    this.getOpenSFinanceList();

    // this.refreshOpenSFinanceListTimeDurations();

    // this.refreshTableClass();
  }

  private activated() {
    this.getOpenSFinanceList();
  }
  
  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
    // this.form = new Util().clearObject(this.form);
    // this.$router.replace("/orderList");
    this.getOpenSFinanceList();
  }

  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getOpenSFinanceList();
  }

  private tableData: any[] = [];

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";
  // private options: any = [];

  // private clearObject(): void {
  //   this.dialogFormVisible = false;
  //   this.forms = new Util().clearObject(this.forms);
  // }
  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getOpenSFinanceList();
  }

  // private allOpenSFinanceListTimeDurations: any[] = [];

  // private refreshOpenSFinanceListTimeDurations() {
  //   return new Promise((resolve, reject) => {
  //     this.openShopFinanceListApi.getOpenSFinanceListTimeDurations(
  //       {
  //         page: 1,
  //         limit: 999999,
  //       },
  //       (res: any) => {
  //         const { data: allOpenSFinanceListTimeDurationList } = (
  //           res ? res.data : {}
  //         ) as any;

  //         this.allOpenSFinanceListTimeDurations = allOpenSFinanceListTimeDurationList || [];

  //         resolve(allOpenSFinanceListTimeDurationList);
  //       },
  //       (err: any) => {
  //         reject(err);
  //       }
  //     );
  //   });
  // }

  // private allTableCategoryList: any[] = [];

  // private refreshTableClass() {
  //   return new Promise((resolve, reject) => {
  //     this.openShopFinanceListApi.poolTableCategory(
  //       {},
  //       (res: any) => {
  //         const { list: allTableCategoryList } = (res ? res.data : {}) as any;
  //         // this.tableData = res.data.list;
  //         // this.tablePage.totalCount = res.data.count;

  //         this.allTableCategoryList = allTableCategoryList;

  //         resolve(allTableCategoryList);
  //       },
  //       (err: any) => {
  //         reject(err);
  //       }
  //     );
  //   });
  // }

  private get marketOrderId() {
    const route = this.$route

    const {
      query = {}
    } = route || {}
    const {
      id: market_order_id
    } = query

    return market_order_id || ''
  }


  private addOrUpdateOpenSFinanceListTitle: string = "开店财务";

  private dialogFormVisible: boolean = false;

  // 拉起开店财务弹框
  private handleWakeOpenSFinanceListDialog(mode: string) {
    // if (mode == 'new-open-shop-finance') { // 新增开店财务

    // }
    this.dataStatisticDialogMode = mode;
    this.addOrUpdateOpenSFinanceListTitle =
      dataStatisticDialogTitle2ch[mode] || "开店财务";

    this.initOpenSFinanceListForm(mode);

    this.dialogFormVisible = true;
  }

  private currentOpenSFinanceListItem: any = null;

  private dataStatisticDialogMode: string = "";

  private async initOpenSFinanceListForm(mode: string) {
    const forms: any = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    if (mode == "edit-open-shop-finance") {
      // 编辑
      // await this.getMoreVenueList(true);

      // await this.refreshOpenSFinanceListTimeDurations();

      // await this.refreshTableClass();

      const currentOpenSFinanceListItem =
        this.currentOpenSFinanceListItem;

      const {
        id,
        received_price = "",
      } = currentOpenSFinanceListItem || {};

      Object.assign(forms, {
        received_price
        // dataStatistics_time_id,
        // first_price,
        // second_price,
        // third_price,
      });

      forms.id = id;
    }

    console.log(forms, "forms");

    // console.log(this.venueList, "this.venueList");

    this.forms = forms;
  }

  private handleWakeEditOpenSFinanceListDialog(dataStatisticItem: any) {
    this.currentOpenSFinanceListItem = dataStatisticItem;

    this.handleWakeOpenSFinanceListDialog("edit-open-shop-finance");
  }

  // private handleDeleteOpenSFinanceList(dataStatisticItem: any) {
  //   this.$confirm("确认删除该开店财务？", '', {
  //     ...(shareConfirmOptions || {})
  //   }).then(() => {
  //     this.openShopFinanceListApi.deleteOpenSFinanceListItem({
  //       id: dataStatisticItem.id
  //     }, () => {
  //       this.getOpenSFinanceList();
  //     }, () => {

  //     })
  //   })
  // }

  // private venueList: any[] = [
  //   // {
  //   //   id: 1,
  //   //   label: '测试'
  //   // },
  //   // {
  //   //   id: 2,
  //   //   label: '测试2'
  //   // }
  // ];

  // private searchVenueLoading: boolean = false;

  // private remoteGetVenueQueryString: string = "";

  // private remoteSearchVenueTimer: any = null;

  // private searchVenuePageOption: any = {};

  // private initRemoteGetVenuePageOption() {
  //   this.searchVenuePageOption = {
  //     ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
  //   };
  // }

  // private getSearchVenueParams() {
  //   const searchVenuePageOption = this.searchVenuePageOption;

  //   const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

  //   const { page, limit } = searchVenuePageOption;

  //   const params: any = {
  //     page,
  //     limit,
  //   };

  //   if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
  //     params.name = remoteGetVenueQueryString.trim();
  //   }

  //   return params;
  // }

  // private getMoreVenueList(refresh: boolean = true) {
  //   return new Promise((resolve: Function, reject: Function) => {
  //     if (refresh) {
  //       this.initRemoteGetVenuePageOption();
  //     }

  //     const params = this.getSearchVenueParams();

  //     this.openShopFinanceListApi.searchVenueList(
  //       params,
  //       (res: any) => {
  //         console.log(res, "res");

  //         const venueList: any[] = res.data || [];

  //         this.venueList = refresh
  //           ? venueList
  //           : this.venueList.concat(venueList);

  //         if (Array.isArray(res.data) && res.data.length == 0) {
  //           this.searchVenuePageOption.isRearchBottom = true;
  //         }
  //         this.searchVenueLoading = false;

  //         this.addVenueListLoading = false;

  //         resolve();
  //       },
  //       () => {
  //         this.searchVenueLoading = false;

  //         this.addVenueListLoading = false;

  //         reject();
  //       }
  //     );
  //   });
  // }

  // // 第一次搜索 无关键字
  // private handleFirstGetVenueList() {
  //   this.searchVenueLoading = true;

  //   this.getMoreVenueList(true);
  // }

  // // 清空搜索
  // private handleClearGetVenueList() {
  //   this.remoteGetVenueQueryString = "";

  //   this.searchVenueLoading = true;

  //   this.getMoreVenueList(true);
  // }

  // // 远程搜索
  // private remoteGetVenueMethod(query: string) {
  //   query = query.trim ? query.trim() : "";

  //   if (query !== "") {
  //     this.remoteGetVenueQueryString = query;
  //     clearTimeout(this.remoteSearchVenueTimer);

  //     this.remoteSearchVenueTimer = setTimeout(() => {
  //       this.searchVenueLoading = true;

  //       this.getMoreVenueList();
  //       // searchStu((res) => {
  //       //   const {
  //       //     students,
  //       //     total_count
  //       //   } = res || {}

  //       //   this.remoteAllStudents = [
  //       //     ...(students || [])
  //       //   ]

  //       //   this.remoteStudentTotalCount = total_count || 0

  //       //   this.remoteGetStudentLoading = false
  //       // }, {
  //       //   kw: query,
  //       //   ...this.getRemoteGetStudentPageOptionParams()
  //       // }, () => {
  //       //   this.remoteGetStudentLoading = false
  //       // })
  //     }, 300);
  //     // setTimeout(() => {
  //     //   this.remoteGetStudentLoading = false;
  //     //   this.options = this.list
  //     // }, 200);
  //   } else {
  //     this.remoteGetVenueQueryString = "";
  //     this.venueList = [];
  //   }
  // }

  // private addVenueListLoading: boolean = false;

  // private remoteAddVenueMethod() {
  //   if (this.addVenueListLoading) return;
  //   const searchVenuePageOption = this.searchVenuePageOption;

  //   const { isRearchBottom } = searchVenuePageOption;

  //   if (isRearchBottom) return;

  //   this.searchVenuePageOption.page++;

  //   this.addVenueListLoading = true;

  //   this.getMoreVenueList(false);
  // }

  // // 原价限制
  // private handleLimitOriginPrice(val: string) {
  //   Util.limitDigitalnput(
  //     val,
  //     (new_val: string) => {
  //       this.$set(this.forms, "origin_price", new_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "origin_price", new_val);
  //     },
  //     2
  //   );
  // }

  // // 售价限制
  // private handleLimitSlaePrice(val: string) {
  //   Util.limitDigitalnput(
  //     val,
  //     (new_val: string) => {
  //       this.$set(this.forms, "sale_price", new_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "sale_price", new_val);
  //     },
  //     2
  //   );

  // }

  // 有效期
  // private handleLimitExpiredDays(val: string) {
  //   Util.limitIntegetInput(
  //     val,
  //     (new_val: string) => {
  //       const limit_val = Math.min(Number(new_val), maxExpiredDays).toString();
  //       this.$set(this.forms, "expired_days", limit_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "expired_days", new_val);
  //     }
  //   );
  // }

  // // 时长
  // private handleLimitTimeDuration(val: string) {
  //   Util.limitIntegetInput(
  //     val,
  //     (new_val: string) => {
  //       const limit_val = Math.min(Number(new_val), maxTimeDuration).toString();
  //       this.$set(this.forms, "timeDuration", limit_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "timeDuration", new_val);
  //     }
  //   );
  // }

  // private handleLimitSingleMaxCount(val: string) {
  //   Util.limitIntegetInput(
  //     val,
  //     (new_val: string) => {
  //       this.$set(this.forms, "maxLimitCount", new_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "maxLimitCount", new_val);
  //     }
  //   );
  // }

  private sumbitOpenSFinanceListLoading: boolean = false;

  // 提交开店财务配置
  private handleSumbitOpenSFinanceList() {
    const dataStatisticDialogMode = this.dataStatisticDialogMode;

    const params = this.getOpenSFinanceListFormsParams(
      dataStatisticDialogMode
    );

    console.log(params, "params");
    if (params === false) return;

    this.sumbitOpenSFinanceListLoading = true;
    switch (dataStatisticDialogMode) {
      case "new-open-shop-finance":
        // this.openShopFinanceListApi.addOpenSFinanceList(
        //   params,
        //   () => {
        //     this.getOpenSFinanceList();
        //     this.dialogFormVisible = false;
        //     this.sumbitOpenSFinanceListLoading = false;
        //   },
        //   () => {
        //     this.sumbitOpenSFinanceListLoading = false;
        //   }
        // );
        break;
      case "edit-open-shop-finance":
        this.openShopFinanceListApi.updateOpenSFinanceList(
          params,
          () => {
            this.getOpenSFinanceList();
            this.dialogFormVisible = false;
            this.sumbitOpenSFinanceListLoading = false;
          },
          () => {
            this.sumbitOpenSFinanceListLoading = false;
          }
        );
        break;
    }
  }

  private getOpenSFinanceListFormsParams(mode: string) {
    const params: any = {};

    const showErrorMessage = (msg: string) => {
      if (!!msg) {
        this.$message.error(msg);
      }
    };

    const forms = this.forms;

    const {
      id,
      // name,
      // expired_days,
      // meituanId,
      // timeDuration
      // dataStatistics_time_id,
    } = forms || {};

    const keys = [
      "received_price",
 
    ];

    const key2EndKey: any = {
      received_price: "received_price",
    };

    const key2ErrMsg: any = {
      received_price: "已收金额不能为空",
      // expired_days: "有效期天数不能为空",
      // timeDuration: "时长不能为空",
      // shop_type: "开店财务类型不能为空",
      // expired_days_0: "有效天数不能为零",
      // timeDuration_0: "时长不能为零",
      // meituanId: "美团ID不能为空",
      // quantity: "团购核验次数不能为空",
    };


    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (forms[key]) {
        // const errMsg = key2ErrMsg[`${key}_${forms[key]}`];
        // if (errMsg) {
        //   showErrorMessage(errMsg);

        //   return false;
        // }
        params[key2EndKey[key] || key] = forms[key];
      } else {
        // showErrorMessage(key2ErrMsg[key]);
        // return false;
      }
    }

    // if (typeof dataStatistics_time_id == "number") {
    //   params.dataStatistics_time_id = dataStatistics_time_id;
    // }

    if (mode == "edit-open-shop-finance") {
      params.id = id;
    }

    return params;

    // if (name) {
    //   params.name = name
    // } else {
    //   showErrorMessage('名称不能为空')
    // }

    // if (expired_days) {
    //   params.valid_time = expired_days
    // }

    // if (timeDuration) {
    //   params.value = timeDuration
    // }

    // if (meituanId) {

    // }
  }

  // 统计总计
  private stat_price: any = "";

  //获取开店财务列表
  private getOpenSFinanceList(): void {
    // this.form.startDate = Util.handleStartTimeAndEndTime(
    //   this.form.time,
    //   false
    // ).startTime;
    // this.form.endDate = Util.handleStartTimeAndEndTime(
    //   this.form.time,
    //   false
    // ).endTime;
    this.openShopFinanceListApi.getOpenSFinanceList(
      { ...this.getOpenSFinanceListParams() },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;

        this.stat_price = res.data.stat_price;
        this.tablePage.page = res.data.page;
        this.tablePage.prepage = res.data.prepage;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private getOpenSFinanceListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const params: any = {
      market_order_id: this.marketOrderId
    };

    const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    const {
      shop_name,
      province,
      city,
      team,
      contract_person,
     
      finance_status,
      sign_time,
    } = form;

    if (shop_name) {
      params.shop_name = shop_name;
    }

    if (province) {
      params.province = province
    }

    if (city) {
      params.city = city
    }

    if (team) {
      params.team = team
    }

    if (contract_person) {
      params.contract_person = contract_person
    }

    if (typeof finance_status == 'number') {
      params.finance_status = finance_status
    }


    if (Array.isArray(sign_time) && sign_time.length === 2) {
      const [startDate, endDate] = sign_time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      params.contract_start_time = startTime;

      params.contract_end_time = endTime;
    }


    // if (
    //   mode == "exportExcel" &&
    //   !(params.gain_date_start && params.gain_date_end)
    // ) {
    //   this.$message.error("请选择日期导出数据");
    //   return null;
    // }

    if (mode === "all") {
      params.page = page;

      params.limit = prepage;
    }

    return params;
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 订单字段名 文案对应关系
  private orderExcelList2ch: any = {
    contract_time: "签约日期",
    pool_delivery_time: "球桌发货时间",
    _finance_status_name: "财务状态",
    shop_name: "店铺/客户",
    province: "省份",
    city: "城市",
    customer_address: "客户详情地址",
    customer_name: "客户姓名",
    phone: "手机号",
    team: "所属团队",
    contract_person: "签约人",
    _pool_type_Infos: "球桌",
    pool_price: "球桌款全额",
    gift_pack_price: "大礼包全额",
    chess_num: "棋牌室数量",
    chess_single_price: "棋牌室单价",
    chess_price: "棋牌室全额",
    receivable_price: "应收金额",
    received_price: "已收金额",
    discount_price: "折扣金额",
    await_receive_price: "待收金额",
    // first_price: "首款",
    // second_price: "第二笔",
    // third_price: "第三笔",
  };
  // 订单Excel 表头的先后顺序
  private orderExcelSortList: string[] = [
    "contract_time",
    "pool_delivery_time",
    "_finance_status_name",
    "shop_name",
    "province",
    "city",
    "customer_address",
    "customer_name",
    "phone",
    "team",
    "contract_person",
    "_pool_type_Infos",
    "pool_price",
    "gift_pack_price",
    "chess_num",
    "chess_single_price",
    "chess_price",
    "receivable_price",
    "received_price",
    "discount_price",
    "await_receive_price",
    // "first_price",
    // "second_price",
    // "third_price",
  ];

  // // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getOpenSFinanceListParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.openShopFinanceListApi.exportExcelOpenSFinanceList(
      params,
      (res: any) => {
        const {
          contract_start_time,
          contract_end_time
        } = params || {};

        const signing_time_duration_name =
          contract_start_time && contract_end_time
            ? ` 签约时间${contract_start_time}到${contract_end_time}`
            : "";

        const filename = `市场部开店财务列表${signing_time_duration_name}.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const { 
                finance_status,
                pool_type_list = []
              } = item || {};

              const newItem = {
                ...(item || {}),

                _finance_status_name: financeStatus2Ch[finance_status] || '',
                _pool_type_Infos: pool_type_list.map(({ pool_type, pool_num, pool_single_price }: any) => {
                  return `类型：${pool_type}；数量：${pool_num}；单价：${pool_single_price}`
                }).join('\n')
              };

              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const orderExcelSortList = this.orderExcelSortList;

          const orderExcelList2ch = this.orderExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            orderExcelSortList,
            orderExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
// import { CouponsManageAPI } from "@/common/api/couponList";
import GuessingGamePersonalDetailAPI from '@/common/api/operationManage/guessingGamePersonalDetail';
 
import { excuteDownloadExport2Excel } from "@/common/xlsx";

import { DeepLoop } from "@/common/deepLoop/deepLoop";

import {
  shareConfirmOptions
} from '@/common/config'

export const pageApiList = getPageAPIs(GuessingGamePersonalDetailAPI)



// const defaultSearchVenuePageOption = {
//   page: 1,
//   limit: 10,
//   isRearchBottom: false,
// };

// const gloryShopQRChargeDialogTitle2ch: any = {
//   "new-sale-machine": "新增售卖机",
//   "edit-sale-machine": "编辑售卖机",
// };

// 最大有效期
// const maxExpiredDays = 9999;

// 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;

// 状态： 1待公布 2正确 3错误
const questionStatus2Ch = [
  '',
  '待公布',
  '正确',
  '错误',
]

const maxBenefitRate = 100

@Component({
  components: {},
  filters: {
    getQuestionStatusText(is_status: number) {
      return questionStatus2Ch[is_status] || '未知'
    }
  }
})
export default class GuessingGamePersonalDetailChargesManage extends Vue {
  private form: any = {
    phone: '',
    chance_rate_type: '', // 胜率
    time: []
  };

  // 胜率 1=60-80  2=80-100 3=100
  private allWinRateList: any[] = [
    {
      label: '60%到80%之间',
      value: 1
    },
    {
      label: '80%到100%之间',
      value: 2
    },
    {
      label: '100%',
      value: 3
    }
  ]

  

  private deepLoopUtil: any = {};


  private guessingGamePersonalDetailApi = new GuessingGamePersonalDetailAPI();

  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

  }

  private get personalUserId() {
    const route = this.$route

    const {
      query
    } = route || {}

    const {
      user_id
    } = query || {}

    return user_id || ''
  }

  private created(): void {
    
  }

  private activated() {
    this.getGuessingGamePersonalDetailList();
  }
  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getGuessingGamePersonalDetailList();
  }
  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getGuessingGamePersonalDetailList();
  }

  private tableData: any[] = [];

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";
  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getGuessingGamePersonalDetailList();
  }
  
  //获取有奖竞猜列表
  private getGuessingGamePersonalDetailList(): void {
    const params: any = this.getGuessingGamePersonalDetailParams()
    this.guessingGamePersonalDetailApi.getGuessingGamePersonalDetailList(
      { ...params },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page || params.page;
        this.tablePage.prepage = res.data.prepage || params.limit;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private getGuessingGamePersonalDetailParams(mode = "all"): any {
    const params: any = {
      user_id: this.personalUserId
    };

    // const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    // const { chance_rate_type, phone, time } = form;

    // if (typeof chance_rate_type == 'number') {
    //   params.chance_rate_type = chance_rate_type;
    // }

    // if (phone) {
    //   params.phone = phone
    // }

    // if (Array.isArray(time) && time.length === 2) {
    //   const [startDate, endDate] = time;

    //   const startTime = Util.dateTimeUtilnohms(startDate);
    //   const endTime = Util.dateTimeUtilnohms(endDate);

    //   params.start_time = startTime;

    //   params.end_time = endTime;
    // }

    // if (
    //   mode == "exportExcel" &&
    //   !(params.start_time && params.end_time)
    // ) {
    //   this.$message.error("请选择日期导出数据");
    //   return null;
    // }

    if (mode === "all") {
      if (page) {
        params.page = page;
      }

      if (prepage) {
        params.limit = prepage;
      }

    }

    

    return params;
  }

  // 查看详情
  // handleGoGuessingGamePersonalDetail(userInfoItem: any) {
  //   console.log(userInfoItem, 'userInfoItem')

  //   this.$router.push({
  //     path: '/recommanderDetailList',
  //     query: {
  //       user_id: userInfoItem.user_id
  //     }
  //   })
  // }

  /** 导出excel */
  // private downloadExcelLoading: boolean = false;

  // // 用户字段名 文案对应关系
  // private userExcelList2ch: any = {
  //   phone: "手机号码",
  //   nickname: "用户昵称",
  //   guess_num: "竞猜次数",
  //   guess_correctly_num: "猜中次数",
  //   chance_rate: "竞猜胜率（%）",
  //   address: "地址",
  //   created_at: "日期",
    
  // };
  // // 用户Excel 表头的先后顺序
  // private userExcelSortList: string[] = [
  //   "phone",
  //   "nickname",
  //   "guess_num",
  //   "guess_correctly_num",
  //   "chance_rate",
  //   "address",
  //   "created_at"
  // ];

  // // 点击导出excel 表格
  // private handleDownloadExcel() {
  //   const params = this.getGuessingGamePersonalDetailParams('exportExcel');

  //   if (params === null) {
  //     return false;
  //   }

  //   this.downloadExcelLoading = true;
  //   this.guessingGamePersonalDetailApi.exportExcelGuessingGamePersonalDetailTableList(
  //     params,
  //     (res: any) => {
  //       const { start_time, end_time } = params || {};

  //       const filename = `有奖竞猜记录${start_time}至${end_time}列表.xlsx`;
  //       const { data } = res || {};

  //       const { list: originList } = data || {};

  //       const newList = Array.isArray(originList)
  //         ? originList.map((item) => {
  //             const {  } = item;
  //             const newItem = {
  //               ...(item || {}),
  //             };


  //             return newItem;
  //           })
  //         : [];

  //       if (newList.length > 0) {
  //         const userExcelSortList = this.userExcelSortList;

  //         const userExcelList2ch = this.userExcelList2ch;

  //         const excelJSONData = getExcelJSONDataByList(
  //           newList,
  //           userExcelSortList,
  //           userExcelList2ch
  //         );

  //         excuteDownloadExport2Excel(excelJSONData, filename);
  //       } else {
  //         this.$message.info("选中时段没有可导出的信息");
  //       }

  //       this.downloadExcelLoading = false;
  //     },
  //     () => {
  //       this.downloadExcelLoading = false;
  //     }
  //   );
  // }

  /** end 导出excel */
}


import {Component, Vue} from "vue-property-decorator";
import { Util, getExcelJSONDataByList, getPageAPIs} from "@/common/util";
import {SpringFExchangeRecordAPI} from "@/common/api/operationManage/springFExchangeRecord";
import { excuteDownloadExport2Excel } from "@/common/xlsx";
import {DeepLoop} from '@/common/deepLoop/deepLoop';
import {shareConfirmOptions} from '@/common/config';

export const pageApiList = getPageAPIs(SpringFExchangeRecordAPI);
const defaultSearchVenuePageOption = {
  page: 1,
  limit: 10,
  isRearchBottom: false,
};
const defaultForm = {
  phone:'',
  // type:null,
  name:'',
  time: [],
}

// 状态：1待发货 2已发货 2逾期作废
const deliveryStatus2Ch: string[] = ['', '待发货', '已发货', '逾期作废']
@Component({
  filters: {
    showDeliveryStatusName(status: number) {
      return deliveryStatus2Ch[status] || ''
    }
  }
})
export default class SpringFExchangeRecord extends Vue {
  constructor() {
    super();
    this.deepLoopUtil = new DeepLoop();
    this.springFExchangeRecordApi = new SpringFExchangeRecordAPI();
    this.form = {...this.deepLoopUtil.deepCopy(defaultForm)}
  }
  private springFExchangeRecordApi: any;
 
  private form: any = {}
  private deepLoopUtil: any = {}
  private downloadExcelLoading: boolean = false;
  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    
   
    this.getTable();
  }

  private onConfirmDelivery(deliveryItem: any) {
    const set = Util.setListProp

    set(
      this,
      this.tableData,
      deliveryItem,
      "__updateDevliveryLoading",
      true
    );
    this.springFExchangeRecordApi.delivery({
      id: deliveryItem.id
    }, () => {
      set(
        this,
        this.tableData,
        deliveryItem,
        "__updateDevliveryLoading",
        false
      );

      this.getTable()
    }, () => {
      set(
        this,
        this.tableData,
        deliveryItem,
        "__updateDevliveryLoading",
        false
      );
    })
  }


  
  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    this.springFExchangeRecordApi.getList(
        {
          ...this.getSpringFExchangeRecordParams()
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
        }
    );
  }

  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
    this.search()

  }

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;
    this.getTable();
  }
  // 用户字段名 文案对应关系
  private userExcelList2ch: any = {
    'phone': '手机号',
    'user_name':'昵称',
    'name': '奖品名称',
    '_status_name': '状态',
    'address': '地址',
    'created_at':'兑奖时间'
  };
  // 用户Excel 表头的先后顺序
  private userExcelSortList: string[] = [
    'phone',
    'user_name',
    'name',
    '_status_name',
    'address',
    'created_at'
  ]
  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getSpringFExchangeRecordParams("exportExcel");
    if (params === null) {
      return false;
    }
    this.downloadExcelLoading = true;
    this.springFExchangeRecordApi.exportList(params, (res: any) => {
          const { start_date, end_date } = params ;
          const timeDuration = start_date && end_date ? `${start_date}到${end_date}` : ''
          const filename = `春节兑奖记录 ${timeDuration}.xlsx`;
          const { data } = res ;

          const { list: originList } = data ;
          const newList = Array.isArray(originList)
              ? originList.map((item) => {
                
                const {
                  status
                } = item

                const newItem = {
                  ...item,
                  _status_name: deliveryStatus2Ch[status] || ''
                }
                // item.type =  this.statusObject[item.type]
                return newItem;
              })
              : [];

          if (newList.length > 0) {
            const userExcelSortList = this.userExcelSortList;

            const userExcelList2ch = this.userExcelList2ch;

            const excelJSONData = getExcelJSONDataByList(
                newList,
                userExcelSortList,
                userExcelList2ch
            );

            excuteDownloadExport2Excel(excelJSONData, filename);
          } else {
            this.$message.info("选中时段没有可导出的信息");
          }

          this.downloadExcelLoading = false;
        },
        () => {
          this.downloadExcelLoading = false;
        }
    );
  }
  private getSpringFExchangeRecordParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;


    const {
      phone, // 手机号
      name,

      time
    } = form ;

    const { pageNo, pageSize } = tablePage ;

    const outerParams: any = {};


    if (phone) {
      outerParams.phone = phone;
    }

    if (name) {
      outerParams.name = name;
    }


    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;
      outerParams.start_date = startDate;
      outerParams.end_date = endDate;
    }

    if (mode == "exportExcel" && !(outerParams.start_date && outerParams.end_date)) {
      this.$message.error("请选择时段导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

    }


    return outerParams;
  }
}

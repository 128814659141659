import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class UnEvaluateMerchantListAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getUnEvaluateMerchantList', {
      url: '/admin/praise/notPraiseList',
      name: 'list',
      label: '列表'
    }],
    
    ['exportExcelUnEvaluateMerchantList', {
      url: '/admin/praise/exportNotPraiseList',
      name: 'export-excel',
      label: '导出'
    }],

    ['remindMerchantEvaluate', {
      url: '/admin/praise/remindPraise',
      name: 'remind-merchant-evaluate',
      label: '提醒商家评分'
    }],

    ['getOperationerList', {
      url: '/admin/praise/userAll',
      name: 'operationer-list',
      label: '获取运营人员列表' 
    }],
   
    ['searchVenueList', {
      url: '/admin/coupons/shop/list',
      name: 'shop-list',
      label: '店铺列表',
      // hidden: true
    }],
  ])

  // 未提交评分店铺列表
  public getUnEvaluateMerchantList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getUnEvaluateMerchantList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 导出未提交评分店铺列表
  public exportExcelUnEvaluateMerchantList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('exportExcelUnEvaluateMerchantList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 提醒商家评分
  public remindMerchantEvaluate(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('remindMerchantEvaluate').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 获取运营人员列表
  public getOperationerList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getOperationerList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 搜索店铺列表
  public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('searchVenueList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}
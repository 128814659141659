import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

import { CommonApi } from "../common";

export default class RightMenuAPI extends BasicsClass {

  public apiUrls: any = new Map([
    ['getRightMenuTableList', {
        url: '/admin/cashier_menu/menuList',
        name: 'list',
        label: '列表'
    }],
    // [
    //   'getRightMenuTreeConfigList', {
    //     url: '/admin/permissions/menus',
    //     name: 'get-menu-config-list',
    //     label: '获取左侧配置菜单栏'
    //   }
    // ],
    ['addMenu',  {
        url: '/admin/cashier_menu/add',
        name: 'add',
        label: '添加'
    }],
    ['editMenu', {
        url: '/admin/cashier_menu/edit',
        name: 'edit',
        label: '编辑'
    }],
    ['deleteMenu', {
      url: '/admin/cashier_menu/delete',
      name: 'delete',
      label: '删除'
    }],
    ['copyTransferCommonRights', {
      url: '/admin/cashier_menu/copyCommonMenu',
      name: 'copy-transfer-common-rights',
      label: '复制移入公共菜单'
    }],
    // [
    //   'getRightMenuTreeBatchList', {
    //     url: '/admin/admin_menu/list',
    //     name: 'get-menu-batch-list',
    //     label: '获取批量配置菜单栏'
    //   }
    // ],
    // ['batchAddMenuList', {
    //   url: '/admin/admin_menu/multi_add_edit',
    //   name: 'batch-add-menu',
    //   label: '批量添加菜单'
    // }]
  ])

  public getRightMenuTableList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getRightMenuTableList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  public addMenu(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('addMenu').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  public editMenu(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('editMenu').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  public deleteMenu(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('deleteMenu').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // copyTransferCommonRights
  public copyTransferCommonRights(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('copyTransferCommonRights').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // public getRightMenuTreeConfigList(
  //   params: any,
  //   successCb: Function,
  //   failCb: Function,
  //   showLoading: boolean = false
  // ) {
  //   console.log(params, "params");

  //   const apiUrls = this.apiUrls

  //   const url = apiUrls.get('getRightMenuTreeConfigList').url

  //   this.BasicPost(url, {
  //       ...params
  //   }, false, false, false, successCb, failCb, showLoading)
  // }

  // public getRightMenuTreeBatchList(
  //   params: any,
  //   successCb: Function,
  //   failCb: Function,
  //   showLoading: boolean = false
  // ) {
  //   console.log(params, "params");

  //   const apiUrls = this.apiUrls

  //   const url = apiUrls.get('getRightMenuTreeBatchList').url

  //   this.BasicPost(url, {
  //       ...params
  //   }, false, false, false, successCb, failCb, showLoading)
  // }

  // public batchAddMenuList(
  //   params: any,
  //   successCb: Function,
  //   failCb: Function,
  //   showLoading: boolean = false
  // ) {
  //   console.log(params, "params");

  //   const apiUrls = this.apiUrls

  //   const url = apiUrls.get('batchAddMenuList').url

  //   this.BasicPost(url, {
  //       ...params
  //   }, false, false, false, successCb, failCb, showLoading)
  // }
}

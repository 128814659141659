
// @ is an alias to /src
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import vTags from "@/components/Tags.vue";
import pandaNotifyBell from '@/components/rightNotify.vue'

import pandaService from '@/components/sharedPandaService.vue'
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import { Login, LoginUserMerchants } from "@/common/login/login";

import {
  filterShowRouteStrategy
} from '@/common/asideMenu';


import { CommonApi } from "@/common/api/common";

import { AxiosData } from "@/common/http/http";
//轮询获取新订单
import { Socket } from "@/common/bus";

@Component({
  components: {
    "v-tags": vTags,
    "panda-service": pandaService,
    "panda-notify-bell": pandaNotifyBell
  },
})
export default class HelloWorld extends Vue {
  //  移动端 属性 以及 方法
  private mobileAsideFloder: boolean = false

  private curChoosePageRoute: any = null;

  private handleChangeMobileAsideFolder() {
    this.mobileAsideFloder = !this.mobileAsideFloder
  }

  private handleSetMobileSelect(item: any) {
    this.handleChangeMobileAsideFolder()

    console.log(item, 'item')

    this.curChoosePageRoute = item

    this.setSelect(item.id, item.url)
  }


  // 
  private showUserMenus: any = JSON.parse(localStorage.getItem("menus") as string);

  private asideSearchTimer: any = null

  // 过滤 筛选条件
  private asideSearchQuery: string = '';

  // 过滤前提加载
  private filterAsideSearchLoading: Boolean = false

  // 获取过滤结果 
  private handleGetFiltersAsideMenus(query: string) {
    console.log(query, 'query')
    // const userMenus = [
    //   ...this.deepLoopUtil.deepCopy(this.userMenus)
    // ]

    this.filterAsideSearchLoading = true

    this.commonApi.getFilterAsideMenus({
      name: query
    },
    async (res: any) => {
      const {
        list
      }: any = res.data || {}


      console.log(res, 'res')


      const newUserMenus: any = []
      
      await filterShowRouteStrategy(newUserMenus, () => Promise.resolve({
        list
      }))

      console.log(newUserMenus, 'newUserMenus')

      this.showUserMenus = newUserMenus
      

      this.filterAsideSearchLoading = false
    },
    () => {
      this.filterAsideSearchLoading = false
    }
    )
  }

  // 搜索信息
  private handleChangeAsideSearch() {
    const query: string = this.asideSearchQuery
    clearTimeout(this.asideSearchTimer)

    this.asideSearchTimer = setTimeout(() => {
      console.log(query, 'query')
      // console.log(this.asideSearchQuery, 'this.asideSearchQuery')
      // if (this.asideSearchQuery !== query) {
        if (query) {
          this.handleGetFiltersAsideMenus(query)
        } else {
          this.showUserMenus = this.userMenus
        }
      // }
    }, 300)
  }

  //
  private handleChangeSearchInput(query: string) {
    clearTimeout(this.asideSearchTimer)

    this.asideSearchTimer = setTimeout(() => {
      console.log(query, 'query')
        if (!query) {
          this.showUserMenus = this.userMenus
        }
      // }
    }, 300)
  }
  
  private timer: any = "";

  private userMenus: any = JSON.parse(localStorage.getItem("menus") as string);
  // private defaultActive: any = this.$route.name;
  private deepLoopUtil: any;

  // private handleChangeSelect(index: string) {
  //   console.log(index, 'index')
  // }

  private setSelect(id: string, url: string): void {
    if (this.$route.name != id) {
      // this.defaultActive = id;
      console.log(id, 'id')

      console.log(this.$refs.pcMenu)

      this.$router.push(url);
    }
  }
  private created() {
    // if (localStorage.getItem("menus")) {
    //   const resourceCodes = this.deepLoopUtil.getResources(
    //     JSON.parse(localStorage.getItem("menus")!)
    //   );
    //   this.$store.commit("setResourceCodes", resourceCodes);
    // }

    // new Socket().getNewOrder(this);
    // this.timer = setInterval(() => {
    //   new Socket().getNewOrder(this);
    // }, 2 * 60 * 1000);
  }

  private showMerchantsSelectionBox: boolean = false;

  private loginUserMerchantsList: LoginUserMerchants[] = [];

  private logout: Login;

  private commonApi = new CommonApi();
  constructor() {
    super();
    this.logout = new Login();
    this.deepLoopUtil = new DeepLoop();
  }
  // 用户名下拉菜单选择事件
  private handleCommand(command: string): void {
    if (command == "loginout") {
      clearInterval(this.timer);
      this.logout.logout({});
      
    }
  }
  // end

  // @Watch("$route")
  // $setMenusList(): void {
  //   console.log(this.$route, 'this.$route')
  //   console.log(this.$route.name, 'this.$route.name')
  //   this.defaultActive = this.$route.name;
  // }
}

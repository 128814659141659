import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class GoodsRefundAuditApi extends BasicsClass {
    public apiUrls: any = new Map([
        ['getList', {
            url: '/admin/seller/refundList',
            name: 'list',
            label: '列表'
        }],
        ['updateGoodsRefundAuditPassStatus',  {
            url: '/admin/seller/pass',
            name: 'pass-status',
            label: '审核通过'
        }],
        ['updateGoodsRefundAuditRejectStatus',  {
            url: '/admin/seller/refuse',
            name: 'reject-status',
            label: '审核拒绝'
        }],
        ['exportExcelRefundAuditList', {
            url: '/admin/seller/exportRefundList',
            name: 'export-excel',
            label: '导出'
        }]
    ])
    
    // momo达到家-助教基本信息审核列表
    public getList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 处理问题状态更新
    public updateGoodsRefundAuditStatus(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        
        const urlModes: any = {
            'agree': apiUrls.get('updateGoodsRefundAuditPassStatus').url,
            'disagree': apiUrls.get('updateGoodsRefundAuditRejectStatus').url,
        }

        let url: string = urlModes[params.mode]

        delete params.mode

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading);
    }

    // 退款审核列表 导出
    public exportExcelRefundAuditList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('exportExcelRefundAuditList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}
import { BasicsClass } from '@/common/BasicsClass';
export class PoolOrderListAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getPoolOrderList', {
            url: '/admin/marketPool/orderIndex',
            name: 'list',
            label: '列表'
        }],
        ['addPoolOrderList', {
            url: '/admin/marketPool/addOrder',
            name: 'add-pool-order',
            label: '添加'
        }],

        ['getPoolBrands', {
            url: '/admin/marketPool/allPool',
            name: 'all-pool-brand',
            label: '市场部-球桌型号'
        }],
        ['updatePoolOrderList', {
            url: '/admin/marketPool/editOrder',
            name: 'edit-pool-order',
            label: '编辑'
        }],
      
        ['deletePoolOrderListItem', {
            url: '/admin/marketPool/delOrder',
            name: 'delete-pool-order',
            label: '删除'
        }],
      
     
    ])


    //新增球桌订单
    public addPoolOrderList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('addPoolOrderList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

   

    //市场部-球桌型号（下拉选项）
    public getPoolBrands(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getPoolBrands').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    //球桌订单列表
    public getPoolOrderList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getPoolOrderList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

     //修改球桌订单
    public updatePoolOrderList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('updatePoolOrderList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 删除 球桌订单
    public deletePoolOrderListItem(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('deletePoolOrderListItem').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

 
}
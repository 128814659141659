import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class AirConfigAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['device', {
            url: '/admin/aqara/device',
            name: 'device-list',
            label: '授权设备列表',
            // // hidden: true
        }],
        ['resource', {
            url: '/admin/aqara/resource',
            name: 'device-resource',
            label: '设备开关资源',
            // hidden: true
        }],
        ['sanyouDevices', {
            url: '/admin/aqara/sanYouDevice',
            name: 'shanyou-device',
            label: '闪优物联设备',
        }],
        ['sanyouStudyDiyType', {
            url: '/admin/shopAir/sanyouDiy',
            name: 'shanyou-study-diy-type',
            label: '闪优物联学习类型',
        }],
        ['shopAir', {
            url: '/admin/shopAir/index',
            name: 'list',
            label: '列表'
        }],
        ['shopAiradd', {
            url: '/admin/shopAir/add',
            name: 'add',
            label: '新增设备'
        }],
        ['shopAiredit', {
            url: '/admin/shopAir/edit',
            name: 'edit',
            label: '编辑设备'
        }],
        ['shopAirshow', {
            url: '/admin/shopAir/show',
            name: 'detail',
            label: '设备详情',
            // hidden: true
        }],
        ['shopAirdel', {
            url: '/admin/shopAir/del',
            name: 'delete',
            label: '删除设备'
        }],

    ])

    // 设备列表
    public device(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('device').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 设备资源名
    public resource(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('resource').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 闪优物联设备
    public sanyouDevices(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('sanyouDevices').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 闪优物联学习类型
    public sanyouStudyDiyType(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('sanyouStudyDiyType').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    public shopAir(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('shopAir').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    public shopAirshow(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('shopAirshow').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    public shopAiradd(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('shopAiradd').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    public shopAiredit(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('shopAiredit').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    public shopAirdel(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('shopAirdel').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

}
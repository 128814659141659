
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";

import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import PandaBallMerchantMenuAPI from "@/common/api/systemManage/pandaBallMerchantMenuList";

import { excuteDownloadExport2Excel } from "@/common/xlsx";

import {
  shareConfirmOptions
} from '@/common/config'

export const pageApiList = getPageAPIs(PandaBallMerchantMenuAPI)

const editModes: string[] = ['edit-category', 'edit-page', 'edit-api']

const defaultForm: any = {
  menu_name: '', // 菜单名称
  parent_id: '', // 父级id
  menu_url: '', // 菜单路径
  sort: '', // 排序
  is_record: 1, // 是否记录日志 0否 1是
  // is_show: 1, // 是否展示 0否 1是
  power_type: 1 // 权限类型 1:其他权限 2查看
}

const menuDialogTitles: any = {
  'add-category': '新增菜单分类',
  'add-page': '新增页面',
  'add-api': '新增接口权限',
  'edit-category': '编辑菜单分类',
  'edit-page': '编辑页面',
  'edit-api': '编辑接口权限'
}

const menuName2ch: string[] = [
  '',
  '其他权限',
  '查看'
]

@Component({
  filters: {
    showOptionsRecord(is_record: number) {
      return is_record == 1 ? '是' : '否'
    },
    // 权限菜单
    showRightMenu(power_type: number) {
      return menuName2ch[power_type] || ''
    }
  }
})
export default class UserCouponList extends Vue {
  private pandaBallMerchantMenuApi: any;

  private deepLoopUtil: any;

  private form: any;

  private availablePowerTypeList: any[] = [
    {
      label: '其他权限',
      value: 1,
    },
    {
      label: '查看权限',
      value: 2
    }
  ]

  constructor() {
    super();
    this.pandaBallMerchantMenuApi = new PandaBallMerchantMenuAPI();

    this.deepLoopUtil = new DeepLoop();


    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm)
    }

  }

  // private firstLoading: boolean = true;
  // // private chooseTechnicianItem : any = null;

  // private tablePage: any = {
  //   totalCount: 0,
  //   pageNo: 1,
  //   pageSize: 10,
  //   pageSizes: [10, 20, 50, 100, 200, 500],
  // };


  // private showtimecard: boolean = false;
  private activated(): void {
    this.getTable();

  }


  private tableData: any[] = [];

  private getTable(): void {
    const params = this.getRightMenuTableListParams({
      level: 1
    })
    this.pandaBallMerchantMenuApi.getRightMenuTableList(
      params,
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        const { list } = data || {};

        const newTableList = Array.isArray(list)
          ? list.map(this.handleTableData)
          : [];


        this.tableData = newTableList;

      },
      () => {
      },
      false
    );
  }

  private getRightMenuTableListParams({
    level,
    parent_id = 0
  }: { level?: number, parent_id?: number }) {
    const outerParams: any = {};

    if (level) {
      outerParams.level = level
    }

    if (typeof parent_id == 'number' && parent_id > 0) {
      outerParams.parent_id = parent_id
    }


    console.log(outerParams, "outerParams");

    return outerParams;
  }

  // 控制是否显示的组件本身是否隐藏
  private get isShowRightMenuOpen() {
    const menuMode = this.menuMode

    const allAllowOptionMenusModes: string[] = [
      // 'add-category',
      // 'edit-category',
      // 'add-page',
      // 'edit-page',
      'add-api',
      'edit-api',
    ]

    return allAllowOptionMenusModes.includes(menuMode)
  }

  // 控制是否记录的组件 本省是否隐藏
  private get isShowRecordSwitch() {
    const menuMode = this.menuMode

    const allAllowOptionMenusModes: string[] = [
      'add-api',
      'edit-api',
    ]

    return allAllowOptionMenusModes.includes(menuMode)
  }
  
  // 后台返回的字段 转化为前端定义的字段
  private handleTableData(data: any): any {
    const {
      // status,
    } = data;
    const newData = {
      ...data,
    };

    return newData;
  }


  private get isShowOptionMenuUrl() {
    const menuMode = this.menuMode

    const allAllowOptionMenusModes: string[] = [
      'add-page',
      'edit-page',
      'add-api',
      'edit-api'
    ]

    return allAllowOptionMenusModes.includes(menuMode)
  }

  private rightMenuDialogVisible: boolean = false

  private editRightMenuLoading: boolean = false

  private menuMode: string = ''

  private menuDialogTitle: string = ''

  // 新增菜单分类、 页面、 接口权限 唤起
  private handleAddRightMenu(menuMode: string = '', parentMenuItem: any) {
    this.menuMode = menuMode

    this.menuDialogTitle = menuDialogTitles[menuMode]

    if (menuMode == 'add-page') {
      this.curMenuCategoryItem = parentMenuItem
    } else if (menuMode == 'add-api') {
      this.curPageMenuItem = parentMenuItem
    }

    this.initRightMenuform(menuMode)

    this.rightMenuDialogVisible = true
  }

  //编辑菜单分类、 页面、 接口权限 唤起
  private handleEditRightMenu(menuMode: string = '', rightMenuItem: any) {
    console.log(rightMenuItem, 'rightMenuItem')
    this.menuMode = menuMode

    this.menuDialogTitle = menuDialogTitles[menuMode]

    this.initRightMenuform(menuMode, rightMenuItem)

    this.rightMenuDialogVisible = true
  }

  // 提交菜单分类、 页面、 接口权限信息
  private handleSubmitEditRightMenu() {
    const menuMode = this.menuMode

    const params = this.getRightMenuParams(menuMode)
  
    if (typeof params != 'object') return

    this.editRightMenuLoading = true

    switch (menuMode) {
      case 'add-category':
      case 'add-page':
      case 'add-api':
        this.pandaBallMerchantMenuApi.addMenu(
          params,
          (res: any) => {
            this.editRightMenuLoading = false

            this.rightMenuDialogVisible = false

            this.refreshMenuListByChangeMode(menuMode)
          },
          () => {
            this.editRightMenuLoading = false
          }
        )
        break;
      case 'edit-category':
      case 'edit-page':
      case 'edit-api':
        this.pandaBallMerchantMenuApi.editMenu(
          params,
          (res: any) => {
            this.editRightMenuLoading = false

            this.rightMenuDialogVisible = false

            this.refreshMenuListByChangeMode(menuMode)
          },
          () => {
            this.editRightMenuLoading = false
          }
        )
        break;
    }
  }

  // 提交菜单分类、 页面、 接口权限参数
  private getRightMenuParams(menuMode: string) {
    const form = this.form

    const {
      id,
      menu_name,
      parent_id,
      menu_url,
      sort,
      is_record,
      power_type
    } = form || {}

    const params: any = {
      is_record,
      power_type
    }

    if (parent_id) {
      params.parent_id = parent_id
    }

    if (menu_url) {
      params.menu_url = menu_url
    }

    if (sort && !isNaN(sort)) {
      params.sort = Number(sort)
    } else {
      this.$message.error('请输入有效排序')
      return false
    }

    if (menu_name) {
      params.menu_name = menu_name
    } else {
      this.$message.error('请输入名称')
      return false
    }

    if (editModes.includes(menuMode)) {
      params.id = id
    }

    return params
  }

  // 初始化 菜单分类、 页面、 接口权限弹框
  private initRightMenuform(mode: string = '', rightMenuItem: any = null) {
    const {
      id,
      parent_id,
      sort,
      menu_name,
      menu_url,
      is_record,
      power_type
    } = rightMenuItem || {}

    // const editModes: string[] = ['edit-category', 'edit-page', 'edit-api']

    const form: any = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    }

    if (editModes.includes(mode)) {
      form.is_record = is_record

      form.power_type = power_type
      if (parent_id) {
        form.parent_id = parent_id
      }

      if (typeof sort == 'number') {
        form.sort = sort.toString()
      }

      if (menu_name) {
        form.menu_name = menu_name
      }

      if (menu_url) {
        form.menu_url = menu_url
      }

      if (id) {
        form.id = id
      }
    }

    if (mode == 'add-page') {
      const curMenuCategoryItem = this.curMenuCategoryItem

      form.parent_id = curMenuCategoryItem.id
    } else if (mode == 'add-api') {
      const curPageMenuItem = this.curPageMenuItem

      form.parent_id = curPageMenuItem.id
    }

  
    this.form = form
  }

  private refreshMenuListByChangeMode(changeMode: string) {
    switch (changeMode) {
      case 'delete-category':
      case 'add-category':
      case 'edit-category':
        this.getTable()
        break;
      case 'delete-page':
      // case 'add-page':
      case 'edit-page':
        this.refreshPageMenuTableList()
        break;
      case 'delete-api':
      // case 'add-api':
      case 'edit-api':
        this.refreshApiMenuTableList()
        break;
    }
  }

  // 删除菜单分类、 页面、 接口权限
  private handleDeleteRightMenu(rightMenuItem: any, deleteMode = '') {
    console.log(rightMenuItem, 'rightMenuItem')

    const deleteModeTitles: any = {
      'delete-category': '菜单分类',
      'delete-page': '页面菜单',
      'delete-api': '接口权限'
    }

    this.$confirm(`确定删除该${deleteModeTitles[deleteMode]}？`, '', {
      ...shareConfirmOptions
    }).then(() => {
      this.pandaBallMerchantMenuApi.deleteMenu({
        id: rightMenuItem.id
      },
      () => {
        this.refreshMenuListByChangeMode(deleteMode)
      }
      )
    })
  }

  private handleWakePageMenuList(curMenuCategoryItem: any) {
    this.curMenuCategoryItem = curMenuCategoryItem

    this.refreshPageMenuTableList()

    this.pageRightMenuListVisible = true
  }

  /** 页面菜单列表 弹框 */
  private pageRightMenuListVisible: boolean = false

  private pageMenuTableList: any[] = []

  private curMenuCategoryItem: any

  private refreshPageMenuTableList() {
    const curMenuCategoryItem = this.curMenuCategoryItem

    if (!(curMenuCategoryItem && curMenuCategoryItem.id)) {
      this.$message.info('暂无获取到菜单分类信息, 请重试')

      return
    } 

    const params = this.getRightMenuTableListParams({
      parent_id: curMenuCategoryItem.id
    })
    this.pandaBallMerchantMenuApi.getRightMenuTableList(
      params,
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        const { list } = data || {};

        const newTableList = Array.isArray(list)
          ? list.map(this.handleTableData)
          : [];


        this.pageMenuTableList = newTableList;

      },
      () => {
      },
      false
    );
  }

  /** end 页面菜单列表 弹框 */
  private handleWakeApiRightList(curPageMenuItem: any) {
    this.curPageMenuItem = curPageMenuItem

    this.refreshApiMenuTableList()

    this.apiRightListVisible = true
  }

  /** 接口权限列表 弹框 */
  private apiRightListVisible: boolean = false

  private apiRightTableList: any[] = []

  private curPageMenuItem: any

  private refreshApiMenuTableList() {
    const curPageMenuItem = this.curPageMenuItem

    if (!(curPageMenuItem && curPageMenuItem.id)) {
      this.$message.info('暂无获取到页面菜单信息, 请重试')

      return
    } 

    const params = this.getRightMenuTableListParams({
      parent_id: curPageMenuItem.id
    })
    this.pandaBallMerchantMenuApi.getRightMenuTableList(
      params,
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        const { list } = data || {};

        const newTableList = Array.isArray(list)
          ? list.map(this.handleTableData)
          : [];


        this.apiRightTableList = newTableList;
      },
      () => {
      },
      false
    );
  }


  private handleTransferCommonRightMenu(apiRightItem: any) {
    this.$confirm('确认复制该接口权限移入公共菜单？', '', {
      ...shareConfirmOptions
    }).then(() => {
      this.pandaBallMerchantMenuApi.copyTransferCommonRights({
        id: apiRightItem.id
      }, () => {
        this.$message.success('复制权限移入成功')
      })
    })
  }
  /** end 接口权限列表 弹框 */

  // private sumbitBacthAddNewRightMenuLoading: boolean = false


  // // 获取收集新增分类、页面以及权限 自动批量新增
  // private handleAutoBatchAddMenus() {
  //   mergeStrategyAddNewParams().then((addlistParams) => {
  //     console.log(addlistParams, 'addlistParams')

  //     // const filterAddlistParams = addlistParams.filter((item, index) => index %2)
  //   //  addlistParams.splice(3, 1)
  //   //  addlistParams.splice(5, 1)

  //   //  const filterAddlistParams = [
  //   //   ...addlistParams
  //   //  ]
  //   //   console.log(filterAddlistParams, 'filterAddlistParams')

  //     const resultParams = JSON.stringify(addlistParams)

  //     // console.log(resultParams, 'resultParams')

  //     this.sumbitBacthAddNewRightMenuLoading = true

  //     this.pandaBallMerchantMenuApi.batchAddMenuList({
  //       data: resultParams
  //     }, () => {
  //       this.sumbitBacthAddNewRightMenuLoading = false
  //       this.$message.success('批量新增成功')

  //       this.getTable()
  //     }, () => {
  //       this.sumbitBacthAddNewRightMenuLoading = false
  //     })
  //   })

  // }


  // private handlePageChange(val: any) {
  //   this.tablePage.pageNo = val.currentPage;
  //   this.tablePage.pageSize = val.pageSize;

  //   this.getTable();
  // }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import { MarketDepartDataStatisticAPI } from "@/common/api/marketManage/marketDepartDataStatistic";

// import { util } from "vue/types/umd";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import ZlSelect from "@/components/third-custom-components/select";

import ZlOption from "@/components/third-custom-components/option";

import { excuteDownloadExport2Excel } from "@/common/xlsx";

import {
  shareConfirmOptions,
  shareShopType2ch,
  toChineseNumber,
  // defaultWeekList,
  getCommonList,
} from "@/common/config";

const defaultPayItem = {
  price: "",
  payment_date: "",
};

function getPayLabel(index: number): string {
  const chNum: string = toChineseNumber(index + 1);

  return chNum === "一" ? `首款` : `第${chNum}笔`;
}

function createPayItemForm(originItem: any = null, index: number): any {
  const { index: idx } = originItem || {};

  const newIdx = typeof idx == "number" ? idx : index;

  const item = {
    ...DeepLoop.deepClone(defaultPayItem, false),
    __label: getPayLabel(newIdx),
    ...(originItem ? DeepLoop.deepClone(originItem, false) : {}),
  };

  return item;
}

function createPaymentInfoTxt(payment_info: any[]): string {
  if (!Array.isArray(payment_info)) return "";
  return payment_info.map(createPayTxt).join("\n");
}

function createPayTxt(item: any, index: number) {
  const { index: idx, __label, price, payment_date: date } = item || {};

  const newIdx = typeof idx == "number" ? idx : index;

  return `${__label || getPayLabel(newIdx)}: ${price} ${
    date ? "时间：" + date : ""
  }`;
}

// 暴露的API 列表
export const pageApiList = getPageAPIs(MarketDepartDataStatisticAPI);

console.log(pageApiList, "pageApiList");

const defaultForm = {
  shop_name: "", // 店铺名称

  type: 1, // 业务类型 1熊猫球社 2麦力士

  time: [], // 线索获取日期

  sign_time: [], // 签约日期

  payment_time: [], // 打款日期

  customer_name: "", // 客户名称

  phone: "", // 电话

  market_source_ids: [], // 线索来源id
};

// 业务类型 1熊猫球社 2麦力士
const bussinessType2Ch: string[] = ["", "熊猫球社", "麦力士"];

const defaultForms = {
  type: 1, // 业务类型 1熊猫球社 2麦力士
  // origin_price: "",
  // sale_price: "",
  customer_no: "",
  customer_name: "",
  phone: "",
  gain_date: "",
  signing_date: "",
  market_source_id: "",

  sale_name: "",

  payment_info: [createPayItemForm(null, 0)],

  // dataStatistics_time_id: "",
  // singleMaxCountType: '0',
  // shop_id: "",
  // maxLimitCount: ''
};

// const defaultSearchVenuePageOption = {
//   page: 1,
//   limit: 10,
//   isRearchBottom: false,
// };

const dataStatisticDialogTitle2ch: any = {
  "new-data-statistic": "新增",
  "edit-data-statistic": "编辑",
};

// 最大有效期
// const maxExpiredDays = 9999;

// // 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;

// // dataStatistic 数据统计分属类别
// const dataStatisticShopType2ch: any = [
//   ...shareShopType2ch
// ]

@Component({
  filters: {
    showBussinessTypeName(type: number) {
      return bussinessType2Ch[type] || "未知";
    },

    createPaymentInfoTxt,
  },
  components: {
    ZlSelect,
    ZlOption,
  },
})
export default class MarketDDataStatisticManage extends Vue {
  private form: any = {
    // dataStatisticId: "",
    shop_name: "",
    // time: [],
    // startDate: "",
    // endDate: "",
  };
  private forms: any = {
    // maxType: "0",
    // ...defaultForms
  };

  private deepLoopUtil: any = {};

  // private merchantApi: any;

  // private shopTypeList: any[] = [
  //   {
  //     label: "台球",
  //     value: 1,
  //   },
  //   {
  //     label: "棋牌",
  //     value: 2,
  //   },
  // ];

  private marketDepartDataStatisticApi = new MarketDepartDataStatisticAPI();
  // private dataStatisticTimeDurationAPI = new MarketDDataStatisticTimeDurationManageAPI();

  // private chooseWeekDayList: any[] = []

  private bussinessTypeList: any[] = [
    // {
    //   label: '全部',
    //   value: '',
    // },
    ...getCommonList(bussinessType2Ch, 1),
  ];

  private chooseClueSources: any[] = [];

  private refreshClueSourceList() {
    this.marketDepartDataStatisticApi.getClueSourceList(
      {},
      (res: any) => {
        const { list } = res.data || {};

        if (Array.isArray(list)) {
          this.chooseClueSources = list;
        } else {
          this.chooseClueSources = [];
        }
      },
      (err: any) => {}
    );
  }

  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    // this.searchVenuePageOption = {
    //   ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    // };

    // this.chooseWeekDayList = [
    //   ...this.deepLoopUtil.deepCopy(defaultWeekList, false)
    // ]

    // this.merchantApi = new MerchantApi();
  }

  private created(): void {
    this.refreshClueSourceList();

    this.getMarketDDataStatistic();

    // this.refreshMarketDDataStatisticTimeDurations();

    // this.refreshTableClass();
  }
  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
    // this.form = new Util().clearObject(this.form);
    // this.$router.replace("/orderList");
    this.getMarketDDataStatistic();
  }

  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getMarketDDataStatistic();
  }

  private tableData: any[] = [];

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";
  // private options: any = [];

  // private clearObject(): void {
  //   this.dialogFormVisible = false;
  //   this.forms = new Util().clearObject(this.forms);
  // }
  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getMarketDDataStatistic();
  }

  // private allMarketDDataStatisticTimeDurations: any[] = [];

  // private refreshMarketDDataStatisticTimeDurations() {
  //   return new Promise((resolve, reject) => {
  //     this.marketDepartDataStatisticApi.getMarketDDataStatisticTimeDurations(
  //       {
  //         page: 1,
  //         limit: 999999,
  //       },
  //       (res: any) => {
  //         const { data: allMarketDDataStatisticTimeDurationList } = (
  //           res ? res.data : {}
  //         ) as any;

  //         this.allMarketDDataStatisticTimeDurations = allMarketDDataStatisticTimeDurationList || [];

  //         resolve(allMarketDDataStatisticTimeDurationList);
  //       },
  //       (err: any) => {
  //         reject(err);
  //       }
  //     );
  //   });
  // }

  // private allTableCategoryList: any[] = [];

  // private refreshTableClass() {
  //   return new Promise((resolve, reject) => {
  //     this.marketDepartDataStatisticApi.poolTableCategory(
  //       {},
  //       (res: any) => {
  //         const { list: allTableCategoryList } = (res ? res.data : {}) as any;
  //         // this.tableData = res.data.list;
  //         // this.tablePage.totalCount = res.data.count;

  //         this.allTableCategoryList = allTableCategoryList;

  //         resolve(allTableCategoryList);
  //       },
  //       (err: any) => {
  //         reject(err);
  //       }
  //     );
  //   });
  // }

  private removeLastPayItem() {
    const forms = this.forms;

    if (Array.isArray(forms.payment_info) && forms.payment_info.length > 0) {
      forms.payment_info.pop();
    }
  }

  private addPayItem(index: number) {
    const forms = this.forms;

    if (Array.isArray(forms.payment_info)) {
      forms.payment_info.push(createPayItemForm(null, index + 1));
    }
  }

  private addOrUpdateMarketDDataStatisticTitle: string = "数据统计";

  private dialogFormVisible: boolean = false;

  // 拉起数据统计弹框
  private handleWakeMarketDDataStatisticDialog(mode: string) {
    // if (mode == 'new-data-statistic') { // 新增数据统计

    // }
    this.dataStatisticDialogMode = mode;
    this.addOrUpdateMarketDDataStatisticTitle =
      dataStatisticDialogTitle2ch[mode] || "数据统计";

    this.initMarketDDataStatisticForm(mode);

    this.dialogFormVisible = true;
  }

  private currentMarketDDataStatisticItem: any = null;

  private dataStatisticDialogMode: string = "";

  private async initMarketDDataStatisticForm(mode: string) {
    const forms: any = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    if (mode == "edit-data-statistic") {
      // 编辑
      // await this.getMoreVenueList(true);

      // await this.refreshMarketDDataStatisticTimeDurations();

      // await this.refreshTableClass();

      const currentMarketDDataStatisticItem =
        this.currentMarketDDataStatisticItem;

      const {
        id,
        market_source_id = "",
        type = "",
        customer_no = "",
        customer_name = "",
        phone = "",
        gain_date = "",
        signing_date = "",
        sale_name = "",
        shop_name = "",
        payment_info = [],
        // dataStatistics_time_id,
        // first_price = "",
        // second_price = "",
        // third_price = "",
      } = currentMarketDDataStatisticItem || {};

      Object.assign(forms, {
        market_source_id: market_source_id
          ? Number(market_source_id)
          : market_source_id || "",
        type,
        customer_no,
        customer_name,
        phone,
        gain_date,
        signing_date,
        sale_name,
        shop_name,
        payment_info: payment_info.map((item: any, index: number) =>
          createPayItemForm(item, index)
        ),
        // dataStatistics_time_id,
        // first_price,
        // second_price,
        // third_price,
      });

      forms.id = id;
    }

    console.log(forms, "forms");

    // console.log(this.venueList, "this.venueList");

    this.forms = forms;
  }

  private handleWakeEditMarketDDataStatisticDialog(dataStatisticItem: any) {
    this.currentMarketDDataStatisticItem = dataStatisticItem;

    this.handleWakeMarketDDataStatisticDialog("edit-data-statistic");
  }

  // private handleDeleteMarketDDataStatistic(dataStatisticItem: any) {
  //   this.$confirm("确认删除该数据统计？", '', {
  //     ...(shareConfirmOptions || {})
  //   }).then(() => {
  //     this.marketDepartDataStatisticApi.deleteMarketDDataStatisticItem({
  //       id: dataStatisticItem.id
  //     }, () => {
  //       this.getMarketDDataStatistic();
  //     }, () => {

  //     })
  //   })
  // }

  // private venueList: any[] = [
  //   // {
  //   //   id: 1,
  //   //   label: '测试'
  //   // },
  //   // {
  //   //   id: 2,
  //   //   label: '测试2'
  //   // }
  // ];

  // private searchVenueLoading: boolean = false;

  // private remoteGetVenueQueryString: string = "";

  // private remoteSearchVenueTimer: any = null;

  // private searchVenuePageOption: any = {};

  // private initRemoteGetVenuePageOption() {
  //   this.searchVenuePageOption = {
  //     ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
  //   };
  // }

  // private getSearchVenueParams() {
  //   const searchVenuePageOption = this.searchVenuePageOption;

  //   const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

  //   const { page, limit } = searchVenuePageOption;

  //   const params: any = {
  //     page,
  //     limit,
  //   };

  //   if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
  //     params.name = remoteGetVenueQueryString.trim();
  //   }

  //   return params;
  // }

  // private getMoreVenueList(refresh: boolean = true) {
  //   return new Promise((resolve: Function, reject: Function) => {
  //     if (refresh) {
  //       this.initRemoteGetVenuePageOption();
  //     }

  //     const params = this.getSearchVenueParams();

  //     this.marketDepartDataStatisticApi.searchVenueList(
  //       params,
  //       (res: any) => {
  //         console.log(res, "res");

  //         const venueList: any[] = res.data || [];

  //         this.venueList = refresh
  //           ? venueList
  //           : this.venueList.concat(venueList);

  //         if (Array.isArray(res.data) && res.data.length == 0) {
  //           this.searchVenuePageOption.isRearchBottom = true;
  //         }
  //         this.searchVenueLoading = false;

  //         this.addVenueListLoading = false;

  //         resolve();
  //       },
  //       () => {
  //         this.searchVenueLoading = false;

  //         this.addVenueListLoading = false;

  //         reject();
  //       }
  //     );
  //   });
  // }

  // // 第一次搜索 无关键字
  // private handleFirstGetVenueList() {
  //   this.searchVenueLoading = true;

  //   this.getMoreVenueList(true);
  // }

  // // 清空搜索
  // private handleClearGetVenueList() {
  //   this.remoteGetVenueQueryString = "";

  //   this.searchVenueLoading = true;

  //   this.getMoreVenueList(true);
  // }

  // // 远程搜索
  // private remoteGetVenueMethod(query: string) {
  //   query = query.trim ? query.trim() : "";

  //   if (query !== "") {
  //     this.remoteGetVenueQueryString = query;
  //     clearTimeout(this.remoteSearchVenueTimer);

  //     this.remoteSearchVenueTimer = setTimeout(() => {
  //       this.searchVenueLoading = true;

  //       this.getMoreVenueList();
  //       // searchStu((res) => {
  //       //   const {
  //       //     students,
  //       //     total_count
  //       //   } = res || {}

  //       //   this.remoteAllStudents = [
  //       //     ...(students || [])
  //       //   ]

  //       //   this.remoteStudentTotalCount = total_count || 0

  //       //   this.remoteGetStudentLoading = false
  //       // }, {
  //       //   kw: query,
  //       //   ...this.getRemoteGetStudentPageOptionParams()
  //       // }, () => {
  //       //   this.remoteGetStudentLoading = false
  //       // })
  //     }, 300);
  //     // setTimeout(() => {
  //     //   this.remoteGetStudentLoading = false;
  //     //   this.options = this.list
  //     // }, 200);
  //   } else {
  //     this.remoteGetVenueQueryString = "";
  //     this.venueList = [];
  //   }
  // }

  // private addVenueListLoading: boolean = false;

  // private remoteAddVenueMethod() {
  //   if (this.addVenueListLoading) return;
  //   const searchVenuePageOption = this.searchVenuePageOption;

  //   const { isRearchBottom } = searchVenuePageOption;

  //   if (isRearchBottom) return;

  //   this.searchVenuePageOption.page++;

  //   this.addVenueListLoading = true;

  //   this.getMoreVenueList(false);
  // }

  // // 原价限制
  // private handleLimitOriginPrice(val: string) {
  //   Util.limitDigitalnput(
  //     val,
  //     (new_val: string) => {
  //       this.$set(this.forms, "origin_price", new_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "origin_price", new_val);
  //     },
  //     2
  //   );
  // }

  // // 售价限制
  // private handleLimitSlaePrice(val: string) {
  //   Util.limitDigitalnput(
  //     val,
  //     (new_val: string) => {
  //       this.$set(this.forms, "sale_price", new_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "sale_price", new_val);
  //     },
  //     2
  //   );

  // }

  // 有效期
  // private handleLimitExpiredDays(val: string) {
  //   Util.limitIntegetInput(
  //     val,
  //     (new_val: string) => {
  //       const limit_val = Math.min(Number(new_val), maxExpiredDays).toString();
  //       this.$set(this.forms, "expired_days", limit_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "expired_days", new_val);
  //     }
  //   );
  // }

  // // 时长
  // private handleLimitTimeDuration(val: string) {
  //   Util.limitIntegetInput(
  //     val,
  //     (new_val: string) => {
  //       const limit_val = Math.min(Number(new_val), maxTimeDuration).toString();
  //       this.$set(this.forms, "timeDuration", limit_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "timeDuration", new_val);
  //     }
  //   );
  // }

  // private handleLimitSingleMaxCount(val: string) {
  //   Util.limitIntegetInput(
  //     val,
  //     (new_val: string) => {
  //       this.$set(this.forms, "maxLimitCount", new_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "maxLimitCount", new_val);
  //     }
  //   );
  // }

  private sumbitMarketDDataStatisticLoading: boolean = false;

  // 提交数据统计配置
  private handleSumbitMarketDDataStatistic() {
    const dataStatisticDialogMode = this.dataStatisticDialogMode;

    const params = this.getMarketDDataStatisticFormsParams(
      dataStatisticDialogMode
    );

    console.log(params, "params");
    if (params === false) return;

    this.sumbitMarketDDataStatisticLoading = true;
    switch (dataStatisticDialogMode) {
      case "new-data-statistic":
        this.marketDepartDataStatisticApi.addMarketDDataStatistic(
          params,
          () => {
            this.getMarketDDataStatistic();
            this.dialogFormVisible = false;
            this.sumbitMarketDDataStatisticLoading = false;
          },
          () => {
            this.sumbitMarketDDataStatisticLoading = false;
          }
        );
        break;
      case "edit-data-statistic":
        this.marketDepartDataStatisticApi.updateMarketDDataStatistic(
          params,
          () => {
            this.getMarketDDataStatistic();
            this.dialogFormVisible = false;
            this.sumbitMarketDDataStatisticLoading = false;
          },
          () => {
            this.sumbitMarketDDataStatisticLoading = false;
          }
        );
        break;
    }
  }

  private getMarketDDataStatisticFormsParams(mode: string) {
    const params: any = {};

    const showErrorMessage = (msg: string) => {
      if (!!msg) {
        this.$message.error(msg);
      }
    };

    const forms = this.forms;

    const {
      id,
      // name,
      // expired_days,
      // meituanId,
      // timeDuration
      // dataStatistics_time_id,
      type,
      market_source_id,

      payment_info,
    } = forms || {};

    const keys = [
      "customer_no",
      "customer_name",
      "phone",
      "gain_date",
      "signing_date",
      "sale_name",
      "shop_name",
      // "first_price",
      // "second_price",
      // "third_price",
    ];

    const key2EndKey: any = {
      customer_no: "customer_no",
      customer_name: "customer_name",
      phone: "phone",
      gain_date: "gain_date",
      signing_date: "signing_date",
      sale_name: "sale_name",
      shop_name: "shop_name",
      // first_price: "first_price",
      // second_price: "second_price",
      // third_price: "third_price",
    };

    const key2ErrMsg: any = {
      // name: "名称不能为空",
      // expired_days: "有效期天数不能为空",
      // timeDuration: "时长不能为空",
      // shop_type: "数据统计类型不能为空",
      // expired_days_0: "有效天数不能为零",
      // timeDuration_0: "时长不能为零",
      // meituanId: "美团ID不能为空",
      // quantity: "团购核验次数不能为空",
    };

    Object.assign(params, {
      type,
      payment_info: payment_info,
    });

    if (typeof market_source_id == "number") {
      params.market_source_id = market_source_id;
    }

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (forms[key]) {
        // const errMsg = key2ErrMsg[`${key}_${forms[key]}`];
        // if (errMsg) {
        //   showErrorMessage(errMsg);

        //   return false;
        // }
        params[key2EndKey[key] || key] = forms[key];
      } else {
        // showErrorMessage(key2ErrMsg[key]);
        // return false;
      }
    }

    // if (typeof dataStatistics_time_id == "number") {
    //   params.dataStatistics_time_id = dataStatistics_time_id;
    // }

    if (mode == "edit-data-statistic") {
      params.id = id;
    }

    return params;

    // if (name) {
    //   params.name = name
    // } else {
    //   showErrorMessage('名称不能为空')
    // }

    // if (expired_days) {
    //   params.valid_time = expired_days
    // }

    // if (timeDuration) {
    //   params.value = timeDuration
    // }

    // if (meituanId) {

    // }
  }

  // 统计总计
  private stat_price: any = "";

  //获取优惠券列表
  private getMarketDDataStatistic(): void {
    // this.form.startDate = Util.handleStartTimeAndEndTime(
    //   this.form.time,
    //   false
    // ).startTime;
    // this.form.endDate = Util.handleStartTimeAndEndTime(
    //   this.form.time,
    //   false
    // ).endTime;
    this.marketDepartDataStatisticApi.getMarketDDataStatistic(
      { ...this.getMarketDDataStatisticParams() },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;

        this.stat_price = res.data.stat_price;
        this.tablePage.page = res.data.page;
        this.tablePage.prepage = res.data.prepage;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private getMarketDDataStatisticParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    const {
      shop_name,
      type,
      time,
      sign_time,
      payment_time,
      customer_name,
      phone,
      market_source_ids,
    } = form;

    if (shop_name) {
      params.shop_name = shop_name;
    }

    if (type) {
      params.type = type;
    }

    if (customer_name) {
      params.customer_name = customer_name;
    }

    if (phone) {
      params.phone = phone;
    }

    if (Array.isArray(market_source_ids) && market_source_ids.length > 0) {
      params.market_source_ids = market_source_ids;
    }

    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      params.gain_date_start = startTime;

      params.gain_date_end = endTime;
    }

    if (Array.isArray(sign_time) && sign_time.length === 2) {
      const [startDate, endDate] = sign_time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      params.signing_date_start = startTime;

      params.signing_date_end = endTime;
    }

    if (Array.isArray(payment_time) && payment_time.length === 2) {
      const [startDate, endDate] = payment_time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      params.payment_date_start = startTime;

      params.payment_date_end = endTime;
    }

    // if (
    //   mode == "exportExcel" &&
    //   !(params.gain_date_start && params.gain_date_end)
    // ) {
    //   this.$message.error("请选择日期导出数据");
    //   return null;
    // }

    if (mode === "all") {
      params.page = page;

      params.limit = prepage;
    }

    return params;
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 订单字段名 文案对应关系
  private orderExcelList2ch: any = {
    _bussinessName: "业务线",
    customer_no: "客户编号",
    customer_name: "客户名称",
    phone: "电话",
    gain_date: "线索获取日期",
    signing_date: "签约日期",
    source_name: "线索来源",
    sale_name: "销售",
    shop_name: "店铺名称",
    _pay_list_info: "打款",
    // first_price: "首款",
    // second_price: "第二笔",
    // third_price: "第三笔",
    total_price: "累计",
  };
  // 订单Excel 表头的先后顺序
  private orderExcelSortList: string[] = [
    "_bussinessName",
    "customer_no",
    "customer_name",
    "phone",
    "gain_date",
    "signing_date",
    "source_name",
    "sale_name",
    "shop_name",
    "_pay_list_info",
    // "first_price",
    // "second_price",
    // "third_price",
    "total_price",
  ];

  // // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getMarketDDataStatisticParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.marketDepartDataStatisticApi.exportExcelMarketDDataStatistic(
      params,
      (res: any) => {
        const {
          gain_date_start: start_date,
          gain_date_end: end_date,
          signing_date_start,
          signing_date_end,
          payment_date_start,
          payment_date_end,
        } = params || {};

        const create_time_duration_name =
          start_date && end_date
            ? ` 线索获取时间${start_date}到${end_date}`
            : "";

        const signing_time_duration_name =
          signing_date_start && signing_date_end
            ? ` 签约时间${signing_date_start}到${signing_date_end}`
            : "";

        const payment_time_duration_name =
          payment_date_start && payment_date_end
            ? ` 打款时间${payment_date_start}到${payment_date_end}`
            : "";
        const filename = `市场部数据统计列表${create_time_duration_name}${signing_time_duration_name}${payment_time_duration_name}.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const { type, payment_info_view } = item || {};

              const newItem = {
                ...(item || {}),

                _pay_list_info: createPaymentInfoTxt(payment_info_view),

                _bussinessName: bussinessType2Ch[type],
              };

              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const orderExcelSortList = this.orderExcelSortList;

          const orderExcelList2ch = this.orderExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            orderExcelSortList,
            orderExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getPageAPIs } from "@/common/util";
import { LockerDoorsManageAPI } from "@/common/api/operationManage/lockerDoorList";
import PandaDownloadBtn from '@/components/downloadQRCode.vue'

// import { util } from "vue/types/umd";
import { DeepLoop } from "@/common/deepLoop/deepLoop";

import { CommonApi } from "@/common/api/common";

import {
  shareConfirmOptions,
  shareShopType2ch
} from '@/common/config'

export const pageApiList = getPageAPIs(LockerDoorsManageAPI)

const defaultForms = {
  name: "", // 球杆柜名称
  box_no: '', // 柜门编号
  serial: '', // 排序
  enable: 1, // 状态

};


const lockerDoorDialogTitle2ch: any = {
  "new-locker-door": "球杆柜门添加",
  "edit-locker-door": "球杆柜门编辑",
};

const maxSerialNum = 9999

const defaultTablePage: any = {
  count: 0,
  page: 1,
  prepage: 10,
  pageSizes: [10, 20, 50, 100, 200, 500],
  totalPage: 0,
};

@Component({
  filters: {
    // showJumpTypeName(jump_type: number) {
    //   return jumpType2ch[jump_type] || "未知";
    // },
    // showLockerDoorStatusName(is_enable: number) {
    //   return lockerDoorStatus2ch[is_enable] || "未知";
    // },

    // belongToProjectName(shop_type: number) {
    //   return lockerDoorShopType[shop_type] || ''
    // }
  },
  components: {
    PandaDownloadBtn
  }
})
export default class LockerManageList extends Vue {
  private form: any = {
    // couponId: "",
    shop_name: "",
    // time: [],
    // startDate: "",
    // endDate: "",
  };
  private forms: any = {
    // maxType: "0",
    // ...defaultForms
  };

  private commonApi: any;

  private deepLoopUtil: any = {};

  private maxSerialNum: number = maxSerialNum;

  // private allJumpTypeList: any[] = [];

  private lockerDoorsManageApi = new LockerDoorsManageAPI();
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.commonApi = new CommonApi();

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    this.tablePage = {
      ...this.deepLoopUtil.deepCopy(defaultTablePage, false),
    }

    // this.searchVenuePageOption = {
    //   ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    // };

    // this.allJumpTypeList = jumpType2ch.map((item: string, index: number) => {
    //   return {
    //     label: item,
    //     value: index,
    //   };
    // });
  }

  private get getShopBoxId() {
    const query = this.$route.query || {}
    return query.sbid
  }

  // private belongShopTypeList: any[] = [
  //   {
  //     label: '熊猫台球',
  //     value: 1
  //   },
  //   {
  //     label: '熊猫国粹馆',
  //     value: 2
  //   }
  // ]

  private created(): void {
    this.getLockerDoors();
  }
  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getLockerDoors();
  }

  private tableData: any[] = [];

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";

  private tablePage: any;

  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getLockerDoors();
  }

  // 初始化参数
  private initPageParams() {
    this.tablePage = {
      ...this.deepLoopUtil.deepCopy(defaultTablePage, false),
    }
  }

  private addOrUpdateLockerDoorTitle: string = "球杆柜门";

  private dialogFormVisible: boolean = false;

  // 拉起lockerDoor弹框
  private handleWakeLockerDoorDialog(mode: string) {
    // if (mode == 'new-locker-door') { // 新增卡券

    // }
    this.lockerDoorDialogMode = mode;
    this.addOrUpdateLockerDoorTitle = lockerDoorDialogTitle2ch[mode] || "球杆柜门";

    this.initLockerDoorForm(mode);

    this.dialogFormVisible = true;
  }

  // 删除 lockerDoor
  handleDeleteLockerDoor(row: any) {
    this.$confirm("确定删除该球杆柜门项?", "", {
      ...shareConfirmOptions,
    }).then(() => {
      this.lockerDoorsManageApi.deleteLockerDoors(
        {
          id: row.id,
        },
        () => {
          this.getLockerDoors();
        },
        () => {}
      );
    });
  }

  private currentLockerDoorItem: any = null;

  private lockerDoorDialogMode: string = "";

  private async initLockerDoorForm(mode: string) {
    const forms: any = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    if (mode == "edit-locker-door") {
      // 编辑
      // await this.getMoreVenueList(true)

      const currentLockerDoorItem = this.currentLockerDoorItem;

      const {
        id,
        box_no,
        serial,
        name,
        enable,
      } = currentLockerDoorItem || {};

      if (box_no) {
        forms.box_no = box_no;
      }

      if (name) {
        forms.name = name;
      }

      if (typeof serial == "number") {
        forms.serial = serial.toString();
      }

      if (typeof enable == "number") {
        forms.enable = enable;
      }

      forms.id = id;
    }

    console.log(forms, "forms");

    // console.log(this.venueList, "this.venueList");

    this.forms = forms;
  }

  private handleWakeEditLockerDoorDialog(couponItem: any) {
    this.currentLockerDoorItem = couponItem;

    this.handleWakeLockerDoorDialog("edit-locker-door");
  }

  // 编号
  // private handleLimitShopId(val: string) {
  //   Util.limitIntegetInput(
  //     val,
  //     (new_val: string) => {
  //       this.$set(this.forms, "value_object", new_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "value_object", new_val);
  //     }
  //   );
  // }

  // 上传头像
  // private userAvatarUploading: boolean = false;
  // private handleUploadUserAvatar(file: any, fileList: any[]) {
  //   console.log(file, fileList, "file", "fileList");

  //   this.userAvatarUploading = true;
  //   this.commonApi.getOss(
  //     file instanceof File ? file : file.raw,
  //     (res: any) => {
  //       console.log(res, "res 图片上传成功");

  //       const { data } = res;

  //       const { data: uploadImgObjWrap } = data;

  //       const { item: uploadImgObj } = uploadImgObjWrap;

  //       const { id, url } = uploadImgObj;

  //       this.$set(this.forms, "avatar_id", id);
  //       this.$set(this.forms, "avatar", url);

  //       this.userAvatarUploading = false;
  //     },
  //     () => {
  //       this.$message.error("图片上传失败");

  //       this.userAvatarUploading = false;
  //     }
  //   );
  // }

  // serial排序
  private handleLimitSerial(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        const limit_val = Math.min(Number(new_val), maxSerialNum).toString();
        this.$set(this.forms, "serial", limit_val);
      },
      (new_val: string) => {
        this.$set(this.forms, "serial", new_val);
      }
    );
  }

  private sumbitLockerDoorLoading: boolean = false;

  // 提交柜门配置
  private handleSumbitLockerDoor() {
    const lockerDoorDialogMode = this.lockerDoorDialogMode;

    const params = this.getLockerDoorFormsParams(lockerDoorDialogMode);

    console.log(params, "params");
    if (params === false) return;

    this.sumbitLockerDoorLoading = true;
    switch (lockerDoorDialogMode) {
      case "new-locker-door":
        this.lockerDoorsManageApi.addLockerDoors(
          params,
          () => {
            this.getLockerDoors();
            this.dialogFormVisible = false;
            this.sumbitLockerDoorLoading = false;
          },
          () => {
            this.sumbitLockerDoorLoading = false;
          }
        );
        break;
      case "edit-locker-door":
        this.lockerDoorsManageApi.updateLockerDoors(
          params,
          () => {
            this.getLockerDoors();
            this.dialogFormVisible = false;
            this.sumbitLockerDoorLoading = false;
          },
          () => {
            this.sumbitLockerDoorLoading = false;
          }
        );
        break;
    }
  }

  private getLockerDoorFormsParams(mode: string) {
    const params: any = {
      shop_box_id: this.getShopBoxId
    };

    const showErrorMessage = (msg: string) => {
      if (!!msg) {
        this.$message.error(msg);
      }
    };

    const forms = this.forms;

    const {
      id,
      serial
    } = forms || {};

    const keys = ["name", "box_no", "enable"];

    const key2EndKey: any = {
      name: "name",
      box_no: "box_no",
      enable: "enable",
    };

    const key2ErrMsg: any = {
      name: "球杆柜名称不能为空",
      box_no: "柜门编号不能为空",
    };

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (
        forms[key] !== "" &&
        forms[key] !== undefined &&
        forms[key] !== null
      ) {
        const errMsg = key2ErrMsg[`${key}_${forms[key]}`];
        if (errMsg) {
          showErrorMessage(errMsg);

          return false;
        }
        params[key2EndKey[key] || key] = forms[key];
      } else {
        showErrorMessage(key2ErrMsg[key]);

        return false;
      }
    }

    if (typeof serial == "string") {
      params.serial = Number(serial);
    }

    console.log(id, 'id')

    if (typeof id == "number") {
      params.id = id;
    }

    return params;

  }

  //获取柜门列表
  @Watch('$route.query')
  private handleRefreshLockerDoorsByRoute(val: any) {
    if (val && val.sbid) {
      this.initPageParams()
      this.getLockerDoors()
    }
  }
  private getLockerDoors(): void {
    const params = this.getLockerDoorParams()
    this.lockerDoorsManageApi.getLockerDoors(
      { ...params },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page || params.page;
        this.tablePage.prepage = res.data.prepage || params.limit;
        // this.tablePage.totalPage = res.data.total;

        console.log(this.tablePage, 'this.tablePage')
        this.tablePage.count = res.data.total;
      },
      () => {}
    );
  }

  private getLockerDoorParams(): any {
    const params: any = {
      shop_box_id: this.getShopBoxId
    };

    // const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    // const { shop_name } = form;

    // if (shop_name) {
    //   params.name = shop_name;
    // }

    if (page) {
      params.page = page;
    }

    if (prepage) {
      params.limit = prepage;
    }

    return params;
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };
}

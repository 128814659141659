/** 注意： meta  key 一旦确定不可修改 且 同级唯一。 是作为识别这个对象的唯一标识！！！   */

// 赛事管理
export const competitionManageRoutes = [
  {
    path: '/settingCompetitionAssistant',
    name: 'settingCompetitionAssistant',
    meta: {
      title: "赛事助手",
      key: 'setting-competition-assistant',
      pageApisPrm: import("@/views/competitionManage/settingCompetitionAssistant.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "@/views/competitionManage/settingCompetitionAssistant.vue"
      ),
  },
  // competitionCNNineBallBroadcastScreenOption
  {
    path: '/competitionCNNineBallBroadcastScreenOption',
    name: 'competitionCNNineBallBroadcastScreenOption',
    meta: {
      title: "中式九球大赛",
      key: 'competition-cn-nine-ball-broadcast-screen-option',
      pageApisPrm: import("@/views/competitionManage/competitionCNNineBallBroadcastScreenOption.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "@/views/competitionManage/competitionCNNineBallBroadcastScreenOption.vue"
      ),
  },
  {
    path: '/competitionBroadcastScreenOption',
    name: 'competitionBroadcastScreenOption',
    meta: {
      title: "九球大赛",
      key: 'competition-broadcast-screen-option',
      pageApisPrm: import("@/views/competitionManage/competitionBroadcastScreenOption.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "@/views/competitionManage/competitionBroadcastScreenOption.vue"
      ),
  },
  // competitionBroadcastScreenOption
  // competitionSnakeBroadcastScreenOption
  {
    path: '/competitionSnakeBroadcastScreenOption',
    name: 'competitionSnakeBroadcastScreenOption',
    meta: {
      title: "斯诺克直播",
      key: 'competition-snake-broadcast-screen-option',
      pageApisPrm: import("@/views/competitionManage/competitionSnakeBroadcastScreenOption.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "@/views/competitionManage/competitionSnakeBroadcastScreenOption.vue"
      ),
  },
  {
    path: '/studentSignupList',
    name: 'studentSignupList',
    meta: {
      title: "学生报名信息",
      key: 'student-signup-list',
      pageApisPrm: import("@/views/competitionManage/studentSignupList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "@/views/competitionManage/studentSignupList.vue"
      ),
  },
  {
    path: '/allCompetitionPartnerList',
    name: 'allCompetitionPartnerList',
    meta: {
      title: "全部参赛选手",
      key: 'all-competition-partner-list',
      asideHidden: true, // 侧边栏不显示
      pageApisPrm: import("@/views/competitionManage/allCompetitionPartnerList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "@/views/competitionManage/allCompetitionPartnerList.vue"
      ),
  },

  // competitionPartnerPrePkSetList
  {
    path: '/competitionPartnerPrePkSetList',
    name: 'competitionPartnerPrePkSetList',
    meta: {
      title: "参赛选手列表",
      key: 'competition-partner-pre-pk-set-list',
      asideHidden: true, // 侧边栏不显示
      pageApisPrm: import("@/views/competitionManage/competitionPartnerPrePkSetList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "@/views/competitionManage/competitionPartnerPrePkSetList.vue"
      ),
  },

  // competitionPkSetList
  {
    path: '/competitionPkSetList',
    name: 'competitionPkSetList',
    meta: {
      title: "比赛对局列表",
      showTitle: '',
      key: 'competition-pk-set-list',
      asideHidden: true, // 侧边栏不显示
      pageApisPrm: import("@/views/competitionManage/competitionPkSetList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "@/views/competitionManage/competitionPkSetList.vue"
      ),
  },
  // competitionPointExcelRecord
  {
    path: '/competitionPointExcelRecord',
    name: 'competitionPointExcelRecord',
    meta: {
      title: "赛事积分导入",
      key: 'competition-point-excel-record',
      pageApisPrm: import("@/views/competitionManage/competitionPointExcelRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "@/views/competitionManage/competitionPointExcelRecord.vue"
      ),
  },
  {
    path: '/compeitionPointAdjustRecord',
    name: 'compeitionPointAdjustRecord',
    meta: {
      title: "赛事积分管理",
      key: 'compeition-point-adjust-record',
      pageApisPrm: import("@/views/competitionManage/compeitionPointAdjustRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "@/views/competitionManage/compeitionPointAdjustRecord.vue"
      ),
  },
  // operationPartnerAmountAdjustRecord
  {
    path: '/operationPartnerAmountAdjustRecord',
    name: 'operationPartnerAmountAdjustRecord',
    meta: {
      title: "合伙人余额管理",
      key: 'operation-partner-amount-adjust-record',
      pageApisPrm: import("@/views/competitionManage/operationPartnerAmountAdjustRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "@/views/competitionManage/operationPartnerAmountAdjustRecord.vue"
      ),
  },
  {
    path: '/addOrEditComplexCompetition',
    name: 'addOrEditComplexCompetition',
    meta: {
      title: "赛事详情",
      key: 'add-or-edit-complex-competition',
      asideHidden: true, // 侧边栏不显示
      pageApisPrm: import("@/views/competitionManage/addOrEditComplexCompetition.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "@/views/competitionManage/addOrEditComplexCompetition.vue"
      ),
  },
  {
    path: '/merchantApplyCompetition',
    name: 'merchantApplyCompetition',
    meta: {
      title: "赛事申请",
      key: 'merchant-apply-competition',
      pageApisPrm: import("@/views/competitionManage/merchantApplyCompetition.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "@/views/competitionManage/merchantApplyCompetition.vue"
      ),
  },
  {
    path: '/merchantCreateCompetitionAudit',
    name: 'merchantCreateCompetitionAudit',
    meta: {
      title: "赛事创建审核",
      key: 'merchant-create-competition-audit',
      pageApisPrm: import("@/views/competitionManage/merchantCreateCompetitionAudit.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "@/views/competitionManage/merchantCreateCompetitionAudit.vue"
      ),
  },
  {
    path: '/merchantCreateCompetitionDetail',
    name: 'merchantCreateCompetitionDetail',
    meta: {
      title: "赛事创建详情",
      key: 'merchant-create-competition-detail',
      asideHidden: true, // 侧边栏不显示
      pageApisPrm: import("@/views/competitionManage/merchantCreateCompetitionDetail.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "@/views/competitionManage/merchantCreateCompetitionDetail.vue"
      ),
  },
  // competitionLiveSettingList
  {
    path: '/competitionLiveList',
    name: 'competitionLiveList',
    meta: {
      title: "赛事直播",
      key: 'competition-live-list',
      pageApisPrm: import("@/views/competitionManage/competitionLiveList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "@/views/competitionManage/competitionLiveList.vue"
      ),
  },
  {
    path: '/competitionLiveSettingList',
    name: 'competitionLiveSettingList',
    meta: {
      title: "直播设置",
      key: 'competition-live-setting-list',
      asideHidden: true, // 侧边栏不显示
      pageApisPrm: import("@/views/competitionManage/competitionLiveSettingList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "competitionManage" */ "@/views/competitionManage/competitionLiveSettingList.vue"
      ),
  },
]
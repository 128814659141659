
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import {
  showChatTimeFormat
} from '@/utils/index'

@Component
export default class PandaTable extends Vue {
  @Prop({
    type: Object,
    default: (): any => {},
  })
  private item: any;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isActive: any;

  @Prop({
    type: Boolean,
    default: false
  })
  private enableDot: any;

  @Prop({
    type: Boolean,
    default: false
  })
  private isDotted: any;

  // @Prop({
  //   type: String,
  //   default: '你好，测试'
  // })
  // private nextMsg: any;

  private get nextMsg() {
    const item = this.item

    const {
      content
    } = item || {}

    if (!content) return ''

    const {
      type,
      content: msg 
    } = content || {}

    switch (type) {
      case 1: // 普通文字消息
        return msg
      case 2: // 图片消息
        return `[图片]`
      case 3: // 视频消息
        return `[视频]`
      case 4: // 消息模板
        return `[机器人回复]`
      case 5: // 问题库消息
        return `[问题库]`
      case 8: // 选择机器人选项回复
        return msg
    }
  }

  private get msgTime() {
    const item = this.item

    const {
      content,
    } = item || {}

    if (!content) return ''

    const {
      send_time 
    } = content || {}

    return showChatTimeFormat(send_time)
  }

  


  private handleClickChatMerchant() {
    const item = this.item
    this.$emit('click-merchant', {
      item
    })
  }
  
}

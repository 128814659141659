import { BasicsClass } from '@/common/BasicsClass';

export default class RecordManageAPIClass extends BasicsClass {

    public apiUrls: any = new Map([
        ['getDynamicsTableList', {
            url: '/admin/dynamics/lists',
            name: 'lists',
            label: '动态列表'
        }],
        ['deleteDynamics', {
            url: '/admin/dynamics/delete',
            name: 'delete',
            label: '删除动态'
        }],
        ['updateDynamicsState', {
            url: '/admin/dynamics/state',
            name: 'state',
            label: '修改动态状态（上下架）'
        }],
        ['getDynamicsCommentTableList', {
            url: '/admin/dynamics/comment/lists',
            name: 'comment-lists',
            label: '评论列表'
        }],
        ['deleteDynamicsComment', {
            url: '/admin/dynamics/comment/delete',
            name: 'comment-delete',
            label: '删除评论'
        }]

    ])
    // 动态列表
    public getDynamicsTableList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        params = {...(params || {}),}

        const apiUrls = this.apiUrls

        const url = apiUrls.get('getDynamicsTableList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 删除动态
    public deleteDynamics(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('deleteDynamics').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 修改动态状态（上下架）
    public updateDynamicsState(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('updateDynamicsState').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 评论列表
    public getDynamicsCommentTableList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getDynamicsCommentTableList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    // 删除评论
    public deleteDynamicsComment(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('deleteDynamicsComment').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}

import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class ProportionSettingApi extends BasicsClass {
    public apiUrls: any = new Map([
        ['getProportionSettingInfo', {
            url: '/admin/coach_divide/index',
            name: 'info',
            label: '分成信息'
        }],
        ['setProportionSettingInfo',  {
            url: '/admin/coach_divide/setting',
            name: 'edit',
            label: '编辑'
        }]
    ])


    // 分成配置信息
    public getProportionSettingInfo(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getProportionSettingInfo').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 设置分成配置信息
    public setProportionSettingInfo(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('setProportionSettingInfo').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}
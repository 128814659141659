
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getPageAPIs } from "@/common/util";
import { MembersManageAPI } from "@/common/api/operationManage/memberList";
// import { util } from "vue/types/umd";
import { DeepLoop } from "@/common/deepLoop/deepLoop";

import {
  shareConfirmOptions,
  transferUserLevel2ch
} from '@/common/config'
// import ZlSelect from '@/components/third-custom-components/select'

// import ZlOption from '@/components/third-custom-components/option'

export const pageApiList = getPageAPIs(MembersManageAPI)

const defaultForms = {
  type: "",
  name: "", // 会员名称
  // origin_price: "",
  // sale_price: "",
  first_price: "", // 首开价格
  renewal_price: "", // 续费价格
  discount: "", // 折扣
  chess_discount: "", // 棋牌折扣
  invite_rebate: "", // 邀请返利
  coach_discount: "", // 助教折扣
  shop_rebate: "", // 店铺返利
  // venue: {
  //   id: '',
  //   name: ''
  // }
  // singleMaxCountType: '0',
  // shop_id: "",
  // maxLimitCount: ''
};

const defaultMemberType2ch: any[] = [
  ...(transferUserLevel2ch || [])
];

// const defaultSearchVenuePageOption = {
//   page: 1,
//   limit: 10,
//   isRearchBottom: false
// }

const memberDialogTitle2ch: any = {
  "new-member": "新增",
  "edit-member": "编辑",
};

// 最大首开价格
const maxFirstPrice = 99999;

// 最大续费价格
const maxRenewalPrice = 99999;

// 最大折扣
const maxDiscount = 100;

// 最大邀请返利
const maxInviteRebate = 10;

// 最大店铺分成
const maxShopRebate = 10;

// 最大助教折扣
const maxCoachDiscount = 100;
@Component({
  components: {
    // ZlSelect,
    // ZlOption
  },
})
export default class MemberList extends Vue {
  private form: any = {
    // couponId: "",
    shop_name: "",
    // time: [],
    // startDate: "",
    // endDate: "",
  };
  private forms: any = {
    // maxType: "0",
    // ...defaultForms
  };

  private memberType2ch: any[] = defaultMemberType2ch;

  private deepLoopUtil: any = {};

  private membersManageApi = new MembersManageAPI();

  private maxDiscount: number = maxDiscount;

  private maxInviteRebate: number = maxInviteRebate;

  private maxShopRebate: number = maxShopRebate;

  private maxCoachDiscount: number = maxCoachDiscount;
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    // this.searchVenuePageOption = {
    //   ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false)
    // }
  }

  private created(): void {
    this.getMembers();
  }
  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getMembers();
  }

  private tableData: any[] = [];

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";
 
  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getMembers();
  }

  private addOrUpdateMemberTitle: string = "";

  private dialogFormVisible: boolean = false;

  // 拉起卡券弹框
  private handleWakeMemberDialog(mode: string) {
    // if (mode == 'new-member') { // 新增卡券

    // }
    this.memberDialogMode = mode;
    this.addOrUpdateMemberTitle = memberDialogTitle2ch[mode] || "";

    this.initMemberForm(mode);

    this.dialogFormVisible = true;
  }

  private currentMemberItem: any = null;

  private memberDialogMode: string = "";

  private async initMemberForm(mode: string) {
    const forms: any = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    if (mode == "edit-member") {
      // 编辑
      // await this.getMoreVenueList(true)

      const currentMemberItem = this.currentMemberItem;

      const {
        type,
        name,
        discount,
        chess_discount,
        invite_rebate,
        shop_rebate,
        coach_discount,
        first_price,
        renewal_price,
      } = currentMemberItem || {};

      if (name) {
        forms.name = name;
      }

      if (first_price) {
        forms.first_price = first_price.toString();
      }

      if (renewal_price) {
        forms.renewal_price = renewal_price.toString();
      }

      if (discount) {
        forms.discount = discount.toString();
      }

      if (chess_discount) {
        forms.chess_discount = chess_discount.toString()
      }

      if (typeof invite_rebate == "number") {
        forms.invite_rebate = invite_rebate.toString();
      }

      if (typeof shop_rebate == "number") {
        forms.shop_rebate = shop_rebate.toString();
      }

      if (typeof coach_discount == "number") {
        forms.coach_discount = coach_discount.toString();
      }

      forms.type = type;
    }

    console.log(forms, "forms");

    // console.log(this.venueList, 'this.venueList')

    this.forms = forms;
  }

  private handleWakeEditMemberDialog(memberItem: any) {
    this.currentMemberItem = memberItem;

    this.handleWakeMemberDialog("edit-member");
  }


  // 首开价格
  private ghandleLimitFirstPrice(val: string) {
    Util.limitDigitalnput(
      val,
      (new_val: string) => {
        const limit_val = Math.min(Number(new_val), maxFirstPrice).toString();

        if (maxFirstPrice == Number(limit_val)) {
          this.$set(this.forms, "first_price", limit_val);
        } else {
          this.$set(this.forms, "first_price", new_val);
        }
      },
      (new_val: string) => {
        this.$set(this.forms, "first_price", new_val);
      },
      2
    );
  }

  // 续费价格
  private handleLimitRenewalPrice(val: string) {
    Util.limitDigitalnput(
      val,
      (new_val: string) => {
        const limit_val = Math.min(Number(new_val), maxRenewalPrice).toString();
        if (maxRenewalPrice == Number(limit_val)) {
          this.$set(this.forms, "renewal_price", limit_val);
        } else {
          this.$set(this.forms, "renewal_price", new_val);
        }
      },
      (new_val: string) => {
        this.$set(this.forms, "renewal_price", new_val);
      },
      2
    );
  }

  // 折扣
  private handleLimitDiscount(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        const limit_val = Math.min(Number(new_val), maxDiscount).toString();
        this.$set(this.forms, "discount", limit_val);
      },
      (new_val: string) => {
        this.$set(this.forms, "discount", new_val);
      }
    );
  }

  // 棋牌折扣
  private handleLimitChessDiscount(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        const limit_val = Math.min(Number(new_val), maxDiscount).toString();
        this.$set(this.forms, "chess_discount", limit_val);
      },
      (new_val: string) => {
        this.$set(this.forms, "chess_discount", new_val);
      }
    );
  }

  // 邀请返利
  private handleLimitInviteRebate(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        const limit_val = Math.min(Number(new_val), maxInviteRebate).toString();
        this.$set(this.forms, "invite_rebate", limit_val);
      },
      (new_val: string) => {
        this.$set(this.forms, "invite_rebate", new_val);
      }
    );
  }

  private handleLimitShopRebate(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        const limit_val = Math.min(Number(new_val), maxShopRebate).toString();
        this.$set(this.forms, "shop_rebate", limit_val);
      },
      (new_val: string) => {
        this.$set(this.forms, "shop_rebate", new_val);
      }
    );
  }

  private handleLimitCoachDiscount(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        const limit_val = Math.min(
          Number(new_val),
          maxCoachDiscount
        ).toString();
        this.$set(this.forms, "coach_discount", limit_val);
      },
      (new_val: string) => {
        this.$set(this.forms, "coach_discount", new_val);
      }
    );
  }

  private sumbitMemberLoading: boolean = false;

  // 提交配置
  private handleSumbitMember() {
    const memberDialogMode = this.memberDialogMode;

    const params = this.getMemberFormsParams(memberDialogMode);

    console.log(params, "params");
    if (params === false) return;

    // return

    this.sumbitMemberLoading = true;
    switch (memberDialogMode) {
      case "new-member":
        // this.membersManageApi.addMembers(params, () => {
        //   this.getMembers()
        //   this.dialogFormVisible = false
        //   this.sumbitMemberLoading = false

        // }, () => {
        //   this.sumbitMemberLoading = false
        // })
        break;
      case "edit-member":
        this.membersManageApi.updateMembers(
          params,
          () => {
            this.getMembers();
            this.dialogFormVisible = false;
            this.sumbitMemberLoading = false;
          },
          () => {
            this.sumbitMemberLoading = false;
          }
        );
        break;
    }
  }

  private getMemberFormsParams(mode: string) {
    const params: any = {};

    const showErrorMessage = (msg: string) => {
      if (!!msg) {
        this.$message.error(msg);
      }
    };

    const forms = this.forms;

    const {
      type,
      // name,
      // expired_days,
      // meituanId,
      // timeDuration
      // venue
    } = forms || {};

    const keys = [
      "name",
      "first_price",
      "renewal_price",
      "discount",
      "chess_discount",
      "invite_rebate",
      "shop_rebate",
      "coach_discount",
    ];

    const key2EndKey: any = {
      name: "name",
      first_price: "first_price",
      renewal_price: "renewal_price",
      discount: "discount",
      chess_discount: "chess_discount",
      invite_rebate: "invite_rebate",
      shop_rebate: "shop_rebate",
      coach_discount: "coach_discount",
    };

    const key2ErrMsg: any = {
      name: "名称不能为空",
      first_price: "首开价格不能为空",
      renewal_price: "续费价格不能为空",
      first_price_0: "首开价格不能为零",
      renewal_price_0: "续费价格不能为零",
      discount: "折扣不能为空",
      chess_discount: "棋牌折扣不能为空",
      discount_0: "折扣不能为零",
      chess_discount_0: "棋牌折扣不能为零",
      invite_rebate: "邀请返利不能为空",
      coach_discount: "助教折扣不能为空",
    };

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (forms[key]) {
        const errMsg = key2ErrMsg[`${key}_${forms[key]}`];
        if (errMsg) {
          showErrorMessage(errMsg);

          return false;
        }
        params[key2EndKey[key] || key] = forms[key];
      } else {
        showErrorMessage(key2ErrMsg[key]);

        return false;
      }
    }

  

    if (mode == "edit-member") {
      params.type = type;
    }

    return params;
  }

  private handleConfirmDeleteMember(memberItem: any) {
    console.log(memberItem, "memberItem");

    this.$confirm("确认删除该会员配置？", "", {
      ...shareConfirmOptions,
    }).then(() => {
      this.membersManageApi.deleteMembers(
        {
          type: memberItem.type,
        },
        () => {
          this.getMembers();
        },
        () => {}
      );
    });
  }

  //获取优惠券列表
  private getMembers(): void {
    this.membersManageApi.getMembers(
      { ...this.getMemberParams() },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page;
        this.tablePage.prepage = res.data.prepage;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private getMemberParams(): any {
    const params: any = {};

    // const form = this.form

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    // const {
    //   shop_name
    // } = form

    // if (shop_name) {
    //   params.name = shop_name
    // }

    if (page) {
      params.page = page;
    }

    if (prepage) {
      params.limit = prepage;
    }

    return params;
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };
}

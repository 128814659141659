
import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

import { CommonApi } from "../common";

export class CompetitionSnakeBroadcastScreenOptionAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getCompetitionCNNineBallOption', {
      url: '/admin/hornet/show',
      name: 'cn-nine-ball-info',
      label: '赛事中式九球详情'
    }],

    // ['searchVenueList', {
    //   url: '/admin/coupons/shop/list',
    //   name: 'shop-list',
    //   label: '店铺列表',
    //   // hidden: true
    // }],

    ['setCompetitionCNNineBallOption', {
      url: '/admin/hornet/setInfo',
      name: 'competition-cn-nine-ball-option',
      label: '中式九球赛事配置',
      // hidden: true
    }],
    
    ['setCompetitionCNNineBallUserInfoOption', {
      url: '/admin/hornet/setUser',
      name: 'competition-cn-nine-ball-user-info-option',
      label: '中式九球赛事用户信息配置配置',
      // hidden: true
    }],
  ])
  // 赛事中式九球详情
  public getCompetitionCNNineBallOption(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getCompetitionCNNineBallOption').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 搜索店铺列表
  // public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
  //   const apiUrls = this.apiUrls

  //   const url = apiUrls.get('searchVenueList').url

  //   this.BasicPost(url, {
  //       ...params
  //   }, false, false, false, successCb, failCb, showLoading)
  // }

  // 中式九球赛事配置
  public setCompetitionCNNineBallOption(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('setCompetitionCNNineBallOption').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 中式九球赛事用户信息配置配置
  public setCompetitionCNNineBallUserInfoOption(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('setCompetitionCNNineBallUserInfoOption').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}
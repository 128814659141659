
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import PandaMobileTable from "@/components/mobile-custom/panda-table.vue";

import PandaMobileTWFContainer from "@/components/mobile-custom/panda-table-with-filter-container.vue";

import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import MemeberRecordApi from "@/common/api/operationManage/memberOpenRecord";
// import { CommonApi } from "@/common/api/common";

import { excuteDownloadExport2Excel } from "@/common/xlsx";

import { shareConfirmOptions } from "@/common/config";

export const pageApiList = getPageAPIs(MemeberRecordApi)

const getDividePriceStr = (recordItem: any) => {
  if (typeof recordItem != "object") return "";
  const { divide_price, shop_divide_price, currency_type } = recordItem;

  if (currency_type === 1) {
    // 货币类型 1RMB 2砖石
    return Number(divide_price) + Number(shop_divide_price) + "元";
  } else if (currency_type === 2) {
    return `${divide_price}钻石`;
  }
};

const allMemberOpenOrderStatus2ch: string[] = [
  '待付款',
  '已付款',
  '已开通并分成',
  '退款申请中',
  '已退款'
]

@Component({
  filters: {
    showTotalDividePrice(recordItem: any) {
      return getDividePriceStr(recordItem);
    },

    showMemberOrderStatusName(is_status: number) {
      return allMemberOpenOrderStatus2ch[is_status] || ''
    }
  },
  components: {
    PandaMobileTable,
    PandaMobileTWFContainer,
  },
})
export default class MemberOpenRecord extends Vue {
  /** 移动端相关属性 方法 */
  private allMobileChooseFields: any[] = [
    {
      label: "订单号",
      field: "order_no",
    },
    {
      label: "手机号",
      field: "phone",
    },
    {
      label: "开通时间",
      field: "created_at",
    },
    {
      label: "会员等级",
      field: "type",
    },
    {
      label: "金额",
      field: "price",
    },
    {
      label: "用户昵称",
      field: "nickname",
    },
    {
      label: '用户ID',
      field: 'user_id'
    },
    {
      label: '店铺名称',
      field: 'shop_name'
    },
    {
      label: '邀请码',
      field: 'invite_code'
    },
    {
      label: '分成',
      field: 'table-devide-price'
    },
    {
      label: '状态',
      field: 'is_status_name'
    },
    {
      label: '操作人',
      field: 'operation_name'
    },
    {
      label: '操作时间',
      field: 'updated_at'
    },
  ];
  /** end 移动端相关属性 方法 */
  private utilInstance: any;
  private memeberRecordApi: any;

  private commonApi: any;

  private memberOpenOrderStatusList: any[] = [
    {
      label: '全部',
      value: 0
    },
    {
      label: '已付款',
      value: 1
    },
    {
      label: '已退款',
      value: 2
    }
  ]

  constructor() {
    super();
    this.memeberRecordApi = new MemeberRecordApi();

    // this.commonApi = new CommonApi();

    this.utilInstance = new Util();

    // 封禁时间列表
    // this.all_forbidden_time_list = Util.getFoibiddenTimeList();
  }

  private firstLoading: boolean = true;

  private memberTypeList: any[] = [];
  private form: any = {
    // searchKw: "", // 搜索用户昵称/用户ID/手机号
    order_no: '', // 订单号
    phone: '', // 手机号
    type_id: "", // 会员等级
    user_id: "", // 用户ID
    invite_code: "", // 邀请码
    is_status: 0, // 状态
    shop_name: "", // 店铺名称
    // mobile: "", // 手机号
    time: [], // 时间选择
  };

  // private showtimecard: boolean = false;
  private activated(): void {
    this.getTable(true);

    this.refreshMemberTypeList();
  }
  private getTable(noUnique = false): void {
    this.memeberRecordApi.getMemberOpenRecordTableList(
      this.getMemberOpenRecordTableListParams("all", noUnique),
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        const { list, count, page, prepage } = data || {};

        const newTableList = Array.isArray(list)
          ? list.map(this.handleTableData)
          : [];

        // totalCount: 0,
        // pageNo: 1,
        // pageSize: 10,
        this.$set(this.tablePage, "totalCount", count);
        this.$set(this.tablePage, "pageNo", page);
        this.$set(this.tablePage, "pageSize", prepage);

        this.tableData = newTableList;

        this.firstLoading = false;
      },
      () => {
        this.firstLoading = false;
      }
    );
  }

  private getMemberOpenRecordTableListParams(mode = "all", noUnique = false) {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const firstLoading = this.firstLoading;

    const {
      order_no, //订单号
      phone, // 手机号
      type_id, // 会员等级
      user_id, // 用户ID
      invite_code, // 邀请码
      is_status,
      shop_name, // 店铺名称
      time, // 时间
    } = form || {};

    // console.log(technicianItem, "technicianItem");

    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};
    if (order_no) {
      outerParams.order_no = order_no;
    }

    if (phone) {
      outerParams.phone = phone
    }

    if (type_id) {
      outerParams.type_id = type_id - 0;
    }

    if (user_id) {
      outerParams.user_id = user_id;
    }

    if (invite_code) {
      outerParams.invite_code = invite_code;
    }

    if (typeof is_status == 'number') {
      outerParams.is_status = is_status
    }

    if (shop_name) {
      outerParams.shop_name = shop_name
    }

    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      outerParams.start_date = startTime;

      outerParams.end_date = endTime;
    }

    if (
      mode == "exportExcel" &&
      !(outerParams.start_date && outerParams.end_date)
    ) {
      this.$message.error("请选择时段导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      outerParams.__isUnique = !firstLoading && !noUnique;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  // 后台返回的字段 转化为前端定义的字段
  private handleTableData(data: any): any {
    const {
      
    } = data;
    const newData = {
      ...data,
     
    };
   
    return newData;
  }

  private refreshMemberTypeList() {
    this.memeberRecordApi.getMemberTypeList(
      {},
      (res: any) => {
        const { list: memberTypeList } = res.data || {};

        this.memberTypeList = memberTypeList;
      },
      () => {}
    );
  }

  private handleRefundMemberOpenChargeDialog(memberOrderItem: any) {
    const set = Util.setListProp
    
    this.$confirm("确定退款该会员?", "", {
      ...shareConfirmOptions,
    }).then(() => {
      set(
        this,
        this.tableData,
        memberOrderItem,
        "__refundMoneyLoading",
        true
      );
      this.memeberRecordApi.refundMemberOrderCharge({
        id: memberOrderItem.id
      }, () => {
        set(
          this,
          this.tableData,
          memberOrderItem,
          "__refundMoneyLoading",
          false
        );
        this.getTable()
      }, () => {
        set(
          this,
          this.tableData,
          memberOrderItem,
          "__refundMoneyLoading",
          false
        );
      })
    });
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 用户字段名 文案对应关系
  private userExcelList2ch: any = {
    order_no: "订单号",
    phone: "手机号",
    created_at: "开通时间",
    type: "会员等级",
    // inviter: "邀请人",
    price: "金额",
    nickname: "用户昵称",
    user_id: "用户ID",
    shop_name: "店铺名称",
    invite_code: "邀请码",
    __show_divide_price__: "分成",
    is_status_name: "状态",
    operation_name: "操作人",
    updated_at: "操作时间"
  };
  // 用户Excel 表头的先后顺序
  private userExcelSortList: string[] = [
    "order_no",
    "phone",
    "created_at",
    "type",
    // "inviter",
    "price",
    "nickname",
    "user_id",
    "shop_name",
    "invite_code",
    "__show_divide_price__",
    "is_status_name",
    "operation_name",
    "updated_at"
  ];

  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getMemberOpenRecordTableListParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.memeberRecordApi.exportExcelMemberOpenList(
      params,
      (res: any) => {
        const { start_date, end_date } = params || {};

        const filename = `会员开通记录列表 ${start_date}到${end_date}.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const { is_status } = item;
              const newItem = {
                ...(item || {}),
              };

              newItem.__show_divide_price__ = getDividePriceStr(newItem);

              newItem.__member_open_status_text = allMemberOpenOrderStatus2ch[is_status] || ''

              return newItem;
            })
          : [];

        // console.log(newList, 'newList')

        if (newList.length > 0) {
          const userExcelSortList = this.userExcelSortList;

          const userExcelList2ch = this.userExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            userExcelSortList,
            userExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }
        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */

  private restForm(): void {
    this.form = this.utilInstance.clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}

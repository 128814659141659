import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class AirConfigSetAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getRoomAndPoolList', {
            url: '/admin/poolTable/allLists',
            name: 'pool-air-device-list',
            label: '设备列表/空调专用',
            // hidden: true
        }],
        ['shopAir', {
            url: '/admin/shopAir/index',
            name: 'air-device-list',
            label: '设备列表',
            // hidden: true
        }],
        ['shopAirpool', {
            url: '/admin/shopAir/pool',
            name: 'list',
            label: '桌子列表'
        }],
        ['shopAiraddBind', {
            url: '/admin/shopAir/addBind',
            name: 'add',
            label: '新增设备'
        }],
        ['shopAireditBind', {
            url: '/admin/shopAir/editBind',
            name: 'edit',
            label: '编辑设备'
        }],
        ['shopAirbindShow', {
            url: '/admin/shopAir/bindShow',
            name: 'detail',
            label: '设备详情',
            // hidden: true
        }],
        ['shopAirdelBind', {
            url: '/admin/shopAir/delBind',
            name: 'delete',
            label: '删除设备'
        }],

    ])
    // 包厢/桌子 列表 空调专用
    public getRoomAndPoolList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getRoomAndPoolList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    public shopAirpool(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('shopAirpool').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    public shopAirbindShow(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('shopAirbindShow').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    public shopAiraddBind(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('shopAiraddBind').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    public shopAireditBind(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('shopAireditBind').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    public shopAirdelBind(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('shopAirdelBind').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    public shopAir(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('shopAir').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

}

import dayjs from "@/plugins/dayjs";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import { SingleCardUseRecordAPI } from "@/common/api/financeManage/singleCardUseRecord";

import { excuteDownloadExport2Excel } from "@/common/xlsx";


// import { util } from "vue/types/umd";
import { DeepLoop } from "@/common/deepLoop/deepLoop";

import {
  shareConfirmOptions,
  shareShopType2ch
} from '@/common/config'


// 暴露的API 列表
export const pageApiList = getPageAPIs(SingleCardUseRecordAPI);

console.log(pageApiList, 'pageApiList')

// function getCurrentWeekDate() {
//   const currentDay = (dayjs() as any).$d;

//   const curWeekStartDay = (dayjs().startOf("week") as any).$d;

//   return [curWeekStartDay, currentDay];
// }

// function getLastWeekDate() {
//   const lastWeekEndDay = (dayjs().subtract(1, 'week').endOf("week") as any).$d;

//   const lastWeekStartDay = (dayjs().subtract(1, 'week').startOf("week") as any).$d;

//   return [lastWeekStartDay, lastWeekEndDay];
// }

// function getCurrentMonthDate() {
//   const currentDay = (dayjs() as any).$d;

//   const curMonthStartDay = (dayjs().startOf("month") as any).$d;

//   return [curMonthStartDay, currentDay];
// }

// function getLastMonthDate() {
//   const lastEndDay = (dayjs().subtract(1, "month").endOf("month") as any).$d;

//   const lastStartDay = (dayjs().subtract(1, "month").startOf("month") as any)
//     .$d;

//   return [lastStartDay, lastEndDay];
// }

// const defaultChooseRangeModes = [
  
//   {
//     label: '上周',
//     name: 'last-week',
//     rangeFunc: getLastWeekDate
//   },
//   {
//     label: '本周',
//     name: 'cur-week',
//     rangeFunc: getCurrentWeekDate
//   },
//   // {
//   //   label: '本月',
//   //   name: 'cur-month',
//   //   rangeFunc: getCurrentMonthDate
//   // },
//   {
//     label: '上月',
//     name: 'last-month',
//     rangeFunc: getLastMonthDate
//   }
// ]

// 消费类型：1台球 2棋牌 3后台
const allSpendType2ch: string[] = [
  '',
  '台球',
  '棋牌',
  '后台'
]


// 结算类型：1自动结算 2收银台 3后台
const allSettleType2ch: string[] = [
  '',
  '自动结算',
  '收银台',
  '后台'
]


const defaultChooseRangeModeName: string = 'cur-week'

const defaultForm = {
  order_no: '',
  phone: '',
  shop_name: '',
  // venue_id: "",
  // venue: {
  //   id: "",
  //   name: "",
  // },

  time: [
    
  ], // 日期
}


// const defaultSearchVenuePageOption = {
//   page: 1,
//   limit: 10,
//   isRearchBottom: false,
// };


// // 最大有效期
// const maxExpiredDays = 9999;

// // 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;

// coupon 卡券分属类别
// const couponShopType2ch: any = [
//   ...shareShopType2ch
// ]


// type ShopIncomeDayItem = {
//   date: string,
//   total_money: string
// }

const payMode2ch: any[] = ['未知', '微信', '支付宝', '现金', '会员支付', '组合支付']

@Component({
  filters: {
    showSpendTypeName(type: number) {
      return allSpendType2ch[type] || ''
    },

    showSettleTypeName(type: number) {
      return allSettleType2ch[type] || ''
    }
  },
  components: {

  },
})
export default class ShopIncomeChart extends Vue {
  private form: any = {
    // couponId: "",
    // time: [],
    // startDate: "",
    // endDate: "",
  };
  // private forms: any = {
  //   // maxType: "0",
  //   // ...defaultForms
  // };

  private deepLoopUtil: any = {};

  // private timeRangeModes: any[] = [
  //   ...(defaultChooseRangeModes || [])
  // ]

  private curRangeMode: string = defaultChooseRangeModeName


  private singleCardUseRecordApi = new SingleCardUseRecordAPI();
  // private couponTimeDurationAPI = new CouponTimeDurationManageAPI();
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
      time: [
        // ...getCurrentWeekDate()
      ]
    };

    // this.forms = {
    //   ...this.deepLoopUtil.deepCopy(defaultForms, false),
    // };

    // this.searchVenuePageOption = {
    //   ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    // };

    // this.merchantApi = new MerchantApi();
  }

  private created(): void {
    console.log(this.$route)
  }

  private activated() {
    this.refrshTableDataList();
  }

  private mount():void {
    this.refrshTableDataList();
  }
  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
    // this.form = new Util().clearObject(this.form);
    // this.$router.replace("/orderList");
    this.refrshTableDataList();
  }

  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.refrshTableDataList();
  }

  private tableData: any[] = [];

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";
  // private options: any = [];

  // private clearObject(): void {
  //   this.dialogFormVisible = false;
  //   this.forms = new Util().clearObject(this.forms);
  // }
  // private tablePage: any = {
  //   count: 0,
  //   page: 1,
  //   prepage: 10,
  //   pageSizes: [10, 20, 50, 100, 200, 500],
  //   totalPage: 0,
  // };
  // //翻页
  // private handlePageChange(val: any) {
  //   this.tablePage.page = val.currentPage;
  //   this.tablePage.prepage = val.pageSize;
  //   this.refrshTableDataList();
  // }
  
 

  // private handleChangeTimeRange(range: any) {
  //   console.log(range, 'range')
  //   this.curRangeMode = range.name

  //   const chooseTimeRanges = range.rangeFunc()

  //   this.$set(this.form, 'time', chooseTimeRanges)
  // }

  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    console.log(val, 'val')
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.refrshTableDataList();
  }


  private refrshTableDataList() {
    const params = this.getSingleCardUseParams()

    if (typeof params != 'object') return

    this.singleCardUseRecordApi.getSingleCardUseRecordTableList(
      {
        ...params
      },
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        const { list, count, page, prepage } = data || {};

        const newTableList = Array.isArray(list)
          ? list.map(this.handleTableData)
          : [];

        // totalCount: 0,
        // page: 1,
        // pageSize: 10,
        this.$set(this.tablePage, "totalCount", count);
        this.$set(this.tablePage, "page", page);
        this.$set(this.tablePage, "pageSize", prepage);

        this.tableData = newTableList;

      },
      () => {

      }
    )

  }

  private getSingleCardUseParams(mode = "all"): any {
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const { order_no, phone, shop_name, time } = form;

    if (phone) {
      params.phone = phone;
    }

    if (order_no) {
      params.order_no = order_no
    }

    if (shop_name) {
      params.shop_name = shop_name
    }

    const { page, prepage: pageSize } = tablePage || {};

   if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      params.spend_start_time = startTime;

      params.spend_end_time = endTime;
    }


    if (
      mode == "exportExcel" &&
      !(params.spend_start_time && params.spend_end_time)
    ) {
      this.$message.error("请选择时段导出数据");
      return null;
    }

    if (mode === "all") {
      params.page = page;

      params.limit = pageSize;

    }

    console.log(params, "params");

  
    return params;
  }

   // 后台返回的字段 转化为前端定义的字段
  private handleTableData(data: any): any {
    const { 

    } = data;
    const newData = {
      ...data,
   
    };

    return newData;
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 用户字段名 文案对应关系
  private userExcelList2ch: any = {
    phone: "手机号",
    user_name: "昵称",
    order_no: "订单编号",
    shop_name: "店铺名称",
    price: "费用",
    __spend_type_name: "消费类型",
    __settle_type_name: "结算类型",
    before_price: "会员变动前余额",
    after_price: "会员变动后余额",
    spend_time: "消费时间"
  };
  // 用户Excel 表头的先后顺序
  private userExcelSortList: string[] = [
    "phone",
    "user_name",
    "order_no",
    "shop_name",
    "price",
    "__spend_type_name",
    "__settle_type_name",
    "before_price",
    "after_price",
    "spend_time"
  ];

  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getSingleCardUseParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.singleCardUseRecordApi.exportExcelSingleCardUseTableList(
      params,
      (res: any) => {
        const { spend_start_time: start_date, spend_end_time: end_date } = params || {};

        const filename = `单店卡消费记录列表 ${start_date}到${end_date}.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const {
                spend_type,
                settle_type,
                
              } = item;
              const newItem = {
                ...(item || {}),
                __spend_type_name: allSpendType2ch[spend_type],
                __settle_type_name: allSettleType2ch[settle_type]
              };


              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const userExcelSortList = this.userExcelSortList;

          const userExcelList2ch = this.userExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            userExcelSortList,
            userExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */

}

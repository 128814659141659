import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

import { CommonApi } from "../common";

export class SingleCardAccountCheckAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getSingleCardAccountCheckRecordList', {
      url: '/admin/userAccountOperate/index',
      name: 'list',
      label: '列表'
    }],
    ['exportExcelSingleCardAccountCheckList', {
      url: '/admin/userAccountOperate/export',
      name: 'export-excel',
      label: '导出'
    }],
    ['customDecreaseSingleCardAccount', {
      url: '/admin/userAccountOperate/add',
      name: 'custom-decrease-single-card-account',
      label: '自定义单店会员扣款'
    }],
    ['getSingleCardAccountInfo', {
      url: '/admin/userAccountOperate/shopBalance',
      name: 'get-single-card-account',
      label: '单店会员余额',
    }],

    ['searchVenueList', {
      url: '/admin/coupons/shop/list',
      name: 'shop-list',
      label: '店铺列表',
      // hidden: true
    }],
  ])
  // 订单api 方法
  // 商家提现余额扣款记录列表
  public getSingleCardAccountCheckRecordList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getSingleCardAccountCheckRecordList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

 
  // 导出商家提现余额扣款记录列表
  public exportExcelSingleCardAccountCheckList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('exportExcelSingleCardAccountCheckList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  public getSingleCardAccountInfo(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getSingleCardAccountInfo').url
    
    Object.assign(params, {
      __customError: true
    })

    this.BasicPost(url, {
      ...params
    }, false, true, false, successCb, failCb, showLoading)
  }

  // 自定义商家提现余额扣款
  public customDecreaseSingleCardAccount(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('customDecreaseSingleCardAccount').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 搜索店铺列表
  public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('searchVenueList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
}
}

import { BasicsClass } from '@/common/BasicsClass';

export default class SpringFActivityHorseAPIAPI extends BasicsClass {

  public apiUrls: any = new Map([
    ['list', {
      url: '/admin/newYearActivity/exchangeRecordList',
      name: 'list',
      label: '跑马灯列表'
    }],
    ['add', {
      url: '/admin/newYearActivity/exchangeRecordAdd',
      name: 'add',
      label: '添加跑马灯内容'
    }],
    ['edit', {
      url: '/admin/newYearActivity/exchangeRecordUpdate',
      name: 'edit',
      label: '修改内容'
    }],
    ['delete', {
      url: '/admin/newYearActivity/exchangeRecordDelete',
      name: 'delete',
      label: '删除内容'
    }],
  ])
  public getList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {

    params = {
      ...(params || {})
    }

    const apiUrls = this.apiUrls

    const url = apiUrls.get('list').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
  public add(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {

    params = {
      ...(params || {})
    }

    const apiUrls = this.apiUrls

    const url = apiUrls.get('add').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  public edit(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {

    params = {
      ...(params || {})
    }

    const apiUrls = this.apiUrls

    const url = apiUrls.get('edit').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  public delete(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {

    params = {
      ...(params || {})
    }

    const apiUrls = this.apiUrls

    const url = apiUrls.get('delete').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }



}

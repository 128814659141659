import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

import { CommonApi } from "../common";

export class PoolBrandAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getPoolBrandist', {
      url: '/admin/marketPool/index',
      name: 'list',
      label: '列表'
    }],
    // ['exportExcelSPoolBrandList', {
    //   url: '/admin/seller_blacklist/exportList',
    //   name: 'export-excel',
    //   label: '导出'
    // }],
    ['addPoolBrand', {
      url: '/admin/marketPool/addPool',
      name: 'add-pool-brand',
      label: '添加球桌型号'
    }],
    ['editPoolBrand', {
      url: '/admin/marketPool/editPool',
      name: 'edit-pool-brand',
      label: '编辑球桌型号'
    }],
    ['deletePoolBrand', {
      url: '/admin/marketPool/delPool',
      name: 'delete-pool-brand',
      label: '删除球桌型号'
    }],


  ])
  // api 方法
  // 球桌型号列表
  public getPoolBrandist(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getPoolBrandist').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }


  // 导出球桌型号列表
  // public exportExcelSPoolBrandList(
  //   params: any,
  //   successCb: Function,
  //   failCb: Function,
  //   showLoading: boolean = false
  // ) {
  //   const apiUrls = this.apiUrls

  //   const url = apiUrls.get('exportExcelSPoolBrandList').url

  //   this.BasicPost(url, {
  //     ...params
  //   }, false, false, false, successCb, failCb, showLoading)
  // }

  // 添加球桌型号
  public addPoolBrand(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('addPoolBrand').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 编辑球桌型号
  public editPoolBrand(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('editPoolBrand').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 删除球桌型号
  public deletePoolBrand(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('deletePoolBrand').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

}

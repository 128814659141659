
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import { MemberConsumeApi } from "@/common/api/operationManage/memberConsumeRecord";

import { excuteDownloadExport2Excel } from "@/common/xlsx";
export const pageApiList = getPageAPIs(MemberConsumeApi);

import {
  userLevel2ch
} from '@/common/config'

const defaultForm = {
  order_no: "", // 搜索订单号
  phone: "", // 手机号
  // coach_id: '', // 助教ID
  // coach_mobile: '', // 助教手机号
  create_time: [], // 消费时间
  // finish_time: [], // 完成时间
  // activeStatus: "", // 活跃状态
};




@Component({})
export default class MemberConsumeList extends Vue {
  private memberConsumeApi: any;

  private deepLoopUtil: any;

  constructor() {
    super();
    this.memberConsumeApi = new MemberConsumeApi();

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
  }

  private form: any;

  private firstLoading: boolean = true;
  // private chooseTechnicianItem : any = null;

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };


  private activated(): void {

    this.getTable();
   
  }

  // private mounted(): void {

  // }
  private getTable(): void {
    this.memberConsumeApi.getMemberConsumeTableList(
      this.getMemberConsumeTableListParams(),
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        const { list, count, page, prepage } = data || {};

        const newTableList = Array.isArray(list)
          ? list.map(this.handleTableData)
          : [];

        // totalCount: 0,
        // pageNo: 1,
        // pageSize: 10,
        this.$set(this.tablePage, "totalCount", count);
        this.$set(this.tablePage, "pageNo", page);
        this.$set(this.tablePage, "pageSize", prepage);

        this.tableData = newTableList;

        this.firstLoading = false;
      },
      () => {
        this.firstLoading = false;
      },
      false
    );
  }

  private getMemberConsumeTableListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const firstLoading = this.firstLoading;

    const {
      order_no, // 搜索订单号
      
      phone,
      create_time, // 消费时间
      // finish_time, // 完成时间
    } = form || {};

    // console.log(technicianItem, "technicianItem");

    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};

    if (order_no) {
      outerParams.order_no = order_no;
    }

    if (phone) {
      outerParams.phone = phone;
    }

    // if (technicianItem && technicianItem.id) {
    //   outerParams.artificerId = technicianItem.id;
    // }

    if (Array.isArray(create_time) && create_time.length === 2) {
      const [startDate, endDate] = create_time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      outerParams.start_date = startTime;

      outerParams.end_date = endTime;
    }

    const route = this.$route;

    const { query } = route;

    const { userId } = query;

    if (userId) {
      outerParams.userId = userId;
    }

    if (
      mode == "exportExcel" &&
      !(outerParams.start_date && outerParams.end_date)
    ) {
      this.$message.error("请选择消费时段导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      outerParams.__isUnique = !firstLoading;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

 

  // 后台返回的字段 转化为前端定义的字段
  private handleTableData(data: any): any {
    const { type: user_type } = data;
    const newData = {
      ...data,
      // device_name: orderDeviceType2ch[device],
      // pay_type_name: payType2ch[pay_type],
      user_level_type_name: userLevel2ch[user_type],
    };

    return newData;
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 订单字段名 文案对应关系
  private orderExcelList2ch: any = {
    phone: "手机号",
    user_name: "用户昵称",
    order_no: "订单编号",
    user_level_type_name: "会员等级",
    shop_name: "店铺名称",
    pool_fee: "折扣前台球费(元)",
    pool_discount_fee: "折扣后台球费(元)",
    coach_fee: "折扣前助教(元)",
    coach_discount_fee: "折扣后助教(元)",
    __goods_info_str: "商品信息",
    before_total_money: "折扣前消费总金额(元)",
    after_total_money: "折扣后消费总金额(元)",
    before_account_money: "变动前会员余额(元)",
    after_account_money: "变动后会员余额(元)",
    use_time: "消费时间",
  };
  // 订单Excel 表头的先后顺序
  private orderExcelSortList: string[] = [
    "phone",
    "user_name",
    "order_no",
    "user_level_type_name",
    "shop_name",
    "pool_fee",
    "pool_discount_fee",
    "coach_fee",
    "coach_discount_fee",
    "__goods_info_str",
    "before_total_money",
    "after_total_money",
    "before_account_money",
    "after_account_money",
    "use_time",
  ];

  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getMemberConsumeTableListParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.memberConsumeApi.exportExcelMemberConsumeList(
      params,
      (res: any) => {
        const {
          start_date,
          end_date,
          // over_start_time,
          // over_end_time,
        } = params || {};

        const create_time_duration_name =
          start_date && end_date ? `消费时间${start_date}到${end_date}` : "";

        // const finish_time_duration_name =
        //   over_start_time && over_end_time
        //     ? `完成时间${over_start_time}到${over_end_time}`
        //     : "";
        const filename = `会员消费记录列表 ${create_time_duration_name}.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const {
                type: user_type,
                goods_info,
                // price,
                // refunded_price,
                // device,
                // pay_type,
                // daytime_minute,
                // night_minute,
              } = item || {};

              let __goods_info_str = "";

              for (let i = 0; i < goods_info.length; i++) {
                const goods = goods_info[i];
                __goods_info_str += `${i + 1}、${goods.name}x${goods.num}  ¥${
                  goods.price
                }; 
                `;
              }

              const newItem = {
                ...(item || {}),
                // device_name: orderDeviceType2ch[device],
                // pay_type_name: payType2ch[pay_type],
                // total_miniute: daytime_minute + night_minute,
                // actual_price: price - refunded_price || "",
                __goods_info_str,
                user_level_type_name: userLevel2ch[user_type],
              };

              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const orderExcelSortList = this.orderExcelSortList;

          const orderExcelList2ch = this.orderExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            orderExcelSortList,
            orderExcelList2ch
          );

          // console.log(newList, "newList");

          // console.log(excelJSONData, "excelJSONData");

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        // const testData = [
        //   { 主页: "test1223424", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
        //   { 主页: "433", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
        //   { 名称: "22", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
        //   { 名称: "43", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
        //   { 店家: "43", 价格: "6800", 数量: "6800", 昵称: "广告主网", }
        // ]

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */

  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
    // this.form = new Util().clearObject(this.form);
    // this.$router.replace("/orderList");
    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import PandaMobileTable from "@/components/mobile-custom/panda-table.vue";

import PandaMobileTWFContainer from "@/components/mobile-custom/panda-table-with-filter-container.vue";

import { AxiosData } from "@/common/http/http";
import { Util, getPageAPIs } from "@/common/util";
import { DoorBlackListAPI } from "@/common/api/merchantManage/doorBlackList";

export const pageApiList = getPageAPIs(DoorBlackListAPI);


// 空调控制类型：1.空调伴侣P3 2.空调伴侣 3.空调温控器 4.开关 5.闪优红外空调 6闪优红外空调diy
// const allAirConfigCategoryList: any[] = [
//   {
//     label: '空调伴侣P3',
//     value: 1
//   },
//   {
//     label: '空调伴侣',
//     value: 2
//   },
//   {
//     label: '空调温控器',
//     value: 3
//   },
//   {
//     label: '开关',
//     value: 4
//   },
//   {
//     label: '闪优物联',
//     value: 5
//   },
//   {
//     label: '闪优物联学习版',
//     value: 6
//   }
// ]

@Component({
  filters: {
    // showAirConfigTypeName(type: number) {
    //   const categoryItem: any = allAirConfigCategoryList.find((item: any) => item.value == type)

    //   if (categoryItem) {
    //     return categoryItem.label
    //   } else {
    //     return '未知分类'
    //   }
    // }
  },
  components: {
    PandaMobileTable,
    PandaMobileTWFContainer,
  },
})
export default class MerchantsAudit extends Vue {
  /** 移动端相关属性 方法 */
  private allMobileChooseFields: any[] = [
    {
      label: '门禁ID',
      field: 'main_door'
    },
    {
      label: '操作人',
      field: 'opt_name'
    },
    {
      label: '操作时间',
      field: 'updated_at'
    }
  ];
  /** end 移动端相关属性 方法 */

  private merchantApi: any;

  constructor() {
    super();
    this.merchantApi = new DoorBlackListAPI();
  }

  // private allAirConfigCategoryList: any[] = [
  //   ...(allAirConfigCategoryList || [])
  // ]

  private changeDoorTips: string[] = [
    `请确认设备网络在线状态`
  ]

  private dialogFormVisible: boolean = false;

  private form: any = {
    shop_id: "",
  };
  private title: string = "";
  private forms: any = {
    shop_id: "",
    // air_key: ''
    main_door: '', // 门禁ID
  };

  private get shopId() {
    const route = this.$route

    const {
      query
    } = route || {}

    const {
      id
    } = query || {}

    return id || ''
  }
 
  // private get isShowSwitchButton() {
  //   const forms = this.forms

  //   const airCategoryType = forms.type

  //   const allAllowShowBtns: number[] = [4]

  //   return allAllowShowBtns.includes(airCategoryType)
  // }

  // private handleChangeAirType(value: number) {
  //   console.log(value, 'value')
  //   switch (value) {
  //     case 4:
  //       this.initSwitchResorce();
  //       break;
  //     case 5:
  //       this.initSanYouDevices()
  //       break;
  //     case 6: // 学习版
  //       this.initSanYouDevices()

  //       this.initSanYouStudyTypeList()
  //       break;
  //     default:
  //       this.device()
  //       break;
  //   }
  // }

  // private initSanYouDevices() {
  //   this.merchantApi.sanyouDevices(
  //     {
  //       shop_id: this.shopId,
  //     },
  //     (res: any) => {
  //       if (Array.isArray(res.data)) {
  //         this.dList = res.data.map((device: any) => {
  //           const {
  //             mcuid,
  //             mcuname
  //           } = device || {}

  //           return {
  //             did: mcuid,
  //             deviceName: mcuname
  //           }
  //         });
  //       } else {
  //         this.dList = [];
  //       }
  //     }
  //   );
  // }

  // private initSwitchResorce() {
  //   if (this.isShowSwitchButton) {
  //     this.device();
  //     this.resource();
  //   }
  // }

  // private sanYouStudyTypeList: any[] = []
  // private initSanYouStudyTypeList() {
  //   this.merchantApi.sanyouStudyDiyType(
  //     {},
  //     (res: any) => {
  //       const {
  //         list
  //       } = res.data || {}

  //       console.log(list, 'list')

  //       if (Array.isArray(list)) {
  //         this.sanYouStudyTypeList = list
  //       } else {
  //         this.sanYouStudyTypeList = []
  //       }
  //     },
  //     (err: any) => {} 
  //   )
  // }

  // private dList: any = [];
  // private device(): void {
  //   this.merchantApi.device(
  //     {
  //       aqara_id: this.$route.query.aqara_id,
  //     },
  //     (res: any) => {
  //       if (res.data.list) {
  //         this.dList = res.data.list;
  //       } else {
  //         this.dList = [];
  //       }
  //     }
  //   );
  // }
  // private rList: any = [];
  // private resource(): void {
  //   if (this.forms.subject_id && this.isShowSwitchButton) {
  //     this.merchantApi.resource(
  //       {
  //         aqara_id: this.$route.query.aqara_id,
  //         did: [this.forms.subject_id],
  //       },
  //       (res: any) => {
  //         this.rList = res.data;
  //       }
  //     );
  //   }
  // }

  private onclose(): void {
    this.forms = new Util().clearObject(this.forms);
    this.dialogFormVisible = false;
  }
  private submit(): void {
    if (this.title == "新增") {
      this.forms.shop_id = this.$route.query.id;
      this.merchantApi.shopAiradd(this.forms, (res: any) => {
        Util.showToast("新增成功");
        this.dialogFormVisible = false;
        this.forms = new Util().clearObject(this.forms);

        this.search();
      });
    } else {
      this.merchantApi.shopAiredit(this.forms, (res: any) => {
        Util.showToast("修改成功");
        this.dialogFormVisible = false;
        this.forms = new Util().clearObject(this.forms);
        this.search();
      });
    }
  }
  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private activated(): void {
    if (this.$route.query.id) {
      this.form.shop_id = this.$route.query.id;
    }
    this.search();
    // this.device();
  }

  private getTable(): void {
    this.merchantApi.shopAir(
      {
        ...this.form,
        page: this.tablePage.pageNo,
        limit: this.tablePage.pageSize,
      },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.totalCount = res.data.total;
      }
    );
  }

  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private view(row: any): void {
    const {
      id,
      main_door,
      shop_id
    } = row || {}

    Object.assign(this.forms, {
      id,
      main_door,
      shop_id
    })

    this.dialogFormVisible = true;
  }

  private deviceDel(id: string): void {
    this.$confirm("删除时，请确保设备是在线状态 方可操作,\n此操作不可恢复，请谨慎处理！", "删除", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      // type: "warning",
    })
      .then(() => {
        this.merchantApi.shopAirdel(
          {
            id: id,
          },
          () => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.search();
          }
        );
      })
      .catch(() => {
        // this.$message({
        //   type: "info",
        //   message: "已取消删除",
        // });
      });
  }
  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
  private deviceAdd(type: any): void {
    if (type == "add") {
      this.title = "新增";
      this.dialogFormVisible = true;
    } else {
      this.title = "编辑";
      this.view(type);
    }
  }
}

import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class DayDataStatisticAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['dayDataStatisticBusiness', {
            url: '/admin/data_board/daily',
            name: 'list',
            label: '列表'
        }],
    
        ['exportExcelDayDataStatisticList', {
            url: '/admin/data_board/exportDaily',
            name: 'export-excel',
            label: '导出'
        }]
    ])

    // 每月营收列表
    public dayDataStatisticBusiness(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
      const apiUrls = this.apiUrls

      const url = apiUrls.get('dayDataStatisticBusiness').url

      this.BasicPost(url, {
          ...params
      }, false, false, false, successCb, failCb, showLoading)
    }

    // 导出每月营收列表
    public exportExcelDayDataStatisticList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('exportExcelDayDataStatisticList').url
  
        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getPageAPIs } from "@/common/util";
// import { screen } from "@/common/screen/screen";
import FeedbackApi from "@/common/api/auditManage/feedback";
import { CommonApi } from "@/common/api/common";
import { excuteDownloadExport2Excel } from "@/common/xlsx";
import { utils } from "xlsx";

import gallery from "@/components/gallery.vue";

export const pageApiList: any = getPageAPIs(FeedbackApi)

const problemList: any[] = [
  {
    label: '支付问题',
    value: 1
  },
  {
    label: '易用性问题',
    value: 2
  },
  {
    label: 'APP问题',
    value: 3
  },
  {
    label: '服务问题',
    value: 4
  }
]

const TypeText: any = {}

problemList.forEach(({ label, value }) => {
  TypeText[value] = label
})

@Component({
  components: {
    gallery,
  },
  filters: {
    feedBackTypeText(type: number): string {
      if (typeof type != 'number') return ''

      return TypeText[type]
    },
    feedBackStatusText(statusNum: any): any {
      const num2Text: any = {
        0: '未处理',
        1: '已处理'
      }

      return num2Text[statusNum] || '未知状态'
    }
  }
})
export default class FeedbacksList extends Vue {
  private form: any = {
    type:  0, // 问题类型
    is_status: -1, // 状态 
    time: [], // 时段选择
  };
  private feedbackApi: any = new FeedbackApi();
  private created(): void {
    // this.getProblemTypes();
    this.search();
  }
  private getTable(): void {
    const form = this.form

    const {
      time,
      type
    } = form

    const startTime = time && time[0] ? Util.dateTimeUtilnohms(this.form.time[0]) : "";
    const endTime =  time && time[1] ? Util.dateTimeUtilnohms(this.form.time[1]) : "";
    this.feedbackApi.getList(
      {
        ...this.form,
        start_date: startTime,
        end_date: endTime,
        page: this.tablePage.pageNo,
        limit: this.tablePage.pageSize,
      },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.pageNo = res.data.page;
        this.tablePage.pageSize = res.data.prepage;
        this.tablePage.totalCount = res.data.count;
      }
    );
  }

  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }
  private problemList: any[] = [
    ...problemList
  ];

  private statusList: any[] = [
    {
      label: '审核中',
      value: 0
    },
    {
      label: '已处理',
      value: 1
    }
  ]

  private systemTypaName: any[] = [
    '未知',
    '安卓',
    'IOS',
    '小程序'
  ]
  // private getProblemTypes(): void {
  //   this.feedbackApi.getProblemTypes({}, (res: any) => {
  //     this.problemList = res
  //       ? res.data
  //         ? Array.isArray(res.data.list)
  //           ? res.data.list
  //           : []
  //         : []
  //       : [];
  //   });
  // }

  // 更新状态
  private handleUpdateFeedBackStatus(row: any) {
    const tableData = this.tableData

    const existRowIndex = tableData.indexOf(row)

    if (existRowIndex > -1) {
      row.__updateFeedbackStatusLoading = true

      tableData.splice(existRowIndex, 1, row)

      this.feedbackApi.updateFeedBackStatus({
        id: row.id,
        // status: 1
      }, () => {
        row.__updateFeedbackStatusLoading = false

        tableData.splice(existRowIndex, 1, row)

        this.getTable();
      }, () => {
        row.__updateFeedbackStatusLoading = false

        tableData.splice(existRowIndex, 1, row)
      })
    }
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private proofImageUrls(row: any): any[] {
    const {
      image_count,
      images,
      video_count,
      videos
    } = row

    const urls: any[] = []

    if (image_count > 0) {
      urls.push(...images)
    }


    if (video_count > 0) {
      urls.push(...videos.map((video: string) => {
        return `${video}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`
      }))
    }

    return urls
  }

  private images: any = [];
  private handlePreviewMedia(row: any) {
    const {
      image_count,
      images,
      video_count,
      videos
    } = row

    const urls: any[] = []

    if (image_count > 0) {
      urls.push(...images.map((img: string) => {
        return {
          title: "",
          type: "image/jpeg",
          href: img,
          thumbnail: img,
        }
      }))
    }

    if (video_count > 0) {
      urls.push(...videos.map((video: string) => {
        return {
          title: "",
          type: "video/mp4",
          href: video,
          poster: `${video}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`
        }
      }))
    }

    this.images = urls
  }

  /** 导出excel */
  // private downloadExcelLoading: boolean = false;

  // // channel渠道字段名 文案对应关系
  // private merchantExcelList2ch: any = {
  //   channelId: '渠道id',
  //   channelName: "渠道名称",
  //   date: "日期",
  //   followNum: "扫码关注数量",
  //   registerNum: "注册数量",
  //   LoginNum: "登录用户数",
  //   completeOrderNum: '完成订单数',
  //   completeOrderAmount: '完成订单金额',
  //   completeOrderPeopleNum: '完成订单人数',
  //   notCompleteNum: '未完成订单数',
  //   notCompleteAmount: '未完成订单金额',
  //   notCompletePeopleNum: '未完成订单数人数',
  //   deviceNum: '新增设备数',
  //   newUserCompleteOrderNum: '新用户完成订单数',
  //   newUserCompleteOrderAmount: '新用户完成订单支付金额',
  //   newUserCompleteOrderPeopleNum: '新用户完成订单人数',
  //   newUserNotCompleteNum: '新用户未完成订单数',
  //   newUserNotCompleteAmount: '新用户未完成订单金额',
  //   newUserNotCompletePeopleNum: '新用户未完成订单人数',
  //   timeoutOrderNum: '超时取消订单数',
  //   timeoutNotPayAmount: '超时未支付金额',
  //   timeoutPeopleNum: '超时取消订单人数',
  //   activeNum: '活跃人数',
  //   retentionPeopleNum: '次日留存人数',
  //   retentionRate: '次日留存率',
  //   totalPaymentPeopleNum: '总付费人数',
  //   totalPaymentRate: '总付费率',
  //   averagePrice: '平均单价',
  //   arpu: 'ARPU',
  //   arppu: 'ARPPU'
  // };
  // // 商户Excel 表头的先后顺序
  // private merchantExcelSortList: string[] = [
  //   "channelId",
  //   "channelName",
  //   "date",
  //   "followNum",
  //   "registerNum",
  //   "LoginNum",
  //   "completeOrderNum",
  //   "completeOrderAmount",
  //   "completeOrderPeopleNum",
  //   "notCompleteNum",
  //   "notCompleteAmount",
  //   "notCompletePeopleNum",
  //   "deviceNum",
  //   "newUserCompleteOrderNum",
  //   "newUserCompleteOrderAmount",
  //   "newUserCompleteOrderPeopleNum",
  //   "newUserNotCompleteNum",
  //   "newUserNotCompleteAmount",
  //   "newUserNotCompletePeopleNum",
  //   "timeoutOrderNum",
  //   "timeoutNotPayAmount",
  //   "timeoutPeopleNum",
  //   "activeNum",
  //   "retentionPeopleNum",
  //   "retentionRate",
  //   "totalPaymentPeopleNum",
  //   "totalPaymentRate",
  //   "averagePrice",
  //   "arpu",
  //   "arppu"
  // ];

  // // 点击导出excel 表格
  // private handleExportExcelChannelList() {
  //   const form = this.form
  //   const {
  //     time,
  //     channelId
  //   } = form

  //   const params: any = {
  //     channelId, 
  //   };
    
  //   if (Array.isArray(time) && time.length === 2) {
  //     params.startDate = Util.dateTimeUtilnohms(this.form.time[0])
  //     params.endDate = Util.dateTimeUtilnohms(this.form.time[1])
  //   } else {
  //     this.$message.error("请选择时段")

  //     return
  //   }

  //   if (params === null) {
  //     return false;
  //   }

  //   this.downloadExcelLoading = true;
  //   this.feedbackApi.exportExcelChannelList(
  //     params,
  //     (res: any) => {
  //       const { startDate, endDate } = params || {};

  //       const filename = `商户列表 ${startDate}到${endDate}.xlsx`;
  //       const { data: originList } = res || {};

  //       // const {
  //       //   list: originList
  //       // } = data || {}

  //       const newList = Array.isArray(originList)
  //         ? originList.map((item) => {
  //             // const { orderNum } = item || {};
  //             const newItem = {
  //               ...(item || {}),
  //             };

  //             for (let key in item) {
  //               if (typeof item[key] == 'number') {
  //                 newItem[key] = item[key].toString()
  //               }
  //             }

  //             // newItem.orderNum =
  //             //   typeof orderNum == "number"
  //             //     ? orderNum.toString()
  //             //     : orderNum || "";

  //             return newItem;
  //           })
  //         : [];

  //       if (newList.length > 0) {
  //         const merchantExcelSortList = this.merchantExcelSortList;

  //         const merchantExcelList2ch = this.merchantExcelList2ch;

  //         const excelJSONData = getExcelJSONDataByList(
  //           newList,
  //           merchantExcelSortList,
  //           merchantExcelList2ch
  //         );

  //         excuteDownloadExport2Excel(excelJSONData, filename);
  //       } else {
  //         this.$message.info("选中时段没有可导出的信息");
  //       }

  //       // const testData = [
  //       //   { 主页: "test1223424", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
  //       //   { 主页: "433", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
  //       //   { 名称: "22", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
  //       //   { 名称: "43", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
  //       //   { 店家: "43", 价格: "6800", 数量: "6800", 昵称: "广告主网", }
  //       // ]

  //       this.downloadExcelLoading = false;
  //     },
  //     () => {
  //       this.downloadExcelLoading = false;
  //     }
  //   );
  // }

  /** end 导出excel */
}


import { Component, Model, Prop, Vue, Watch } from "vue-property-decorator";

import pandaSvga from "@/components/pandaSvga.vue";

@Component({
 
  components: {
    pandaSvga,
  },
})
export default class PandaSVGA extends Vue {
  @Prop({
    type: Array,
    default: () => [],
  })
  private effects!: Array<any>;

  @Prop({
    type: Number,
    default: 0,
  })
  private current!: number;

  private show: boolean = false;

  private showIndex: number = 0;

  @Watch("effects")
  $effectsChange(newValue: any, oldValue: any) {
    if (Array.isArray(newValue) && newValue.length) {
      this.show = true;

      this.showIndex = this.current
    } else {
      this.show = false;
    }
  }

  @Watch("current")
  $currentChange(newValue: number, oldValue: number) {
    this.showIndex = newValue;
  }

  private handleSwitchShowIndex(mode: string) {
    switch (mode) {
      case "add":
        ++this.showIndex;
        break;
      case "sub":
        --this.showIndex;
        break;
    }
  }

  constructor() {
    super();
  }

  private created() {}

  private mounted() {}
}

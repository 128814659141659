
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import DepartmentAPI from "@/common/api/systemManage/departmentList";

import {
  getConfigMapList,
  // getRightMenuConfig
} from '@/common/asideMenu'

import { excuteDownloadExport2Excel } from "@/common/xlsx";

import {
  shareConfirmOptions
} from '@/common/config'

export const pageApiList = getPageAPIs(DepartmentAPI)


const defaultForm: any = {
  department_name: ''
}

const departDialogTitles: any = {
  'add': '新增部门',
  'edit': '编辑部门'
}

@Component({})
export default class UserCouponList extends Vue {
  private departmentApi: any;

  private deepLoopUtil: any;

  private form: any;

  constructor() {
    super();
    this.departmentApi = new DepartmentAPI();

    this.deepLoopUtil = new DeepLoop();


    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm)
    }

  }

  private firstLoading: boolean = true;
  // private chooseTechnicianItem : any = null;

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };


  // private showtimecard: boolean = false;
  private activated(): void {
    this.getTable();

  }

  // private mounted(): void {

  // }
  private getTable(): void {
    const params = this.getDepartmentTableListParams()
    this.departmentApi.getDepartmentTableList(
      params,
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        const { list, count: total, page, prepage } = data || {};

        const newTableList = Array.isArray(list)
          ? list.map(this.handleTableData)
          : [];

        this.$set(this.tablePage, "totalCount", total);
        this.$set(this.tablePage, "pageNo", page || params.page);
        this.$set(this.tablePage, "pageSize", prepage || params.limit);

        this.tableData = newTableList;

        this.firstLoading = false;
      },
      () => {
        this.firstLoading = false;
      },
      false
    );
  }

  private getDepartmentTableListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel

    const tablePage = this.tablePage;

    const firstLoading = this.firstLoading;


    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};


    // if (
    //   mode == "exportExcel" &&
    //   !(outerParams.created_start_time && outerParams.created_end_time)
    // ) {
    //   this.$message.error("请选择消费时段导出数据");
    //   return null;
    // }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      outerParams.__isUnique = !firstLoading;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  
  // 后台返回的字段 转化为前端定义的字段
  private handleTableData(data: any): any {
    const {
      // status,
    } = data;
    const newData = {
      ...data,
      
    };

    return newData;
  }

  private tableData: any[] = [];

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private departmentDialogVisible: boolean = false

  private editDepartmentLoading: boolean = false

  private departMode: string = ''

  private departDialogTitle: string = ''

  // 新增部门 唤起
  private handleAddDepartment() {
    const departMode = 'add'

    this.departMode = departMode

    this.departDialogTitle = departDialogTitles[departMode]

    this.initDepartmentform()

    this.departmentDialogVisible = true
  }

  //编辑部门 唤起
  private handleEditDepartment(departItem: any) {
    console.log(departItem, 'departItem')
    const departMode = 'edit'
    this.departMode = departMode

    this.departDialogTitle = departDialogTitles[departMode]

    this.initDepartmentform(departItem)

    this.departmentDialogVisible = true
  }

  // 提交部门信息
  private handleSubmitEditDepartment() {
    const departMode = this.departMode

    const params = this.getDepartmentParams(departMode)
  
    if (typeof params != 'object') return

    this.editDepartmentLoading = true

    switch (departMode) {
      case 'add':
        this.departmentApi.addDepart(
          params,
          (res: any) => {
            this.editDepartmentLoading = false

            this.departmentDialogVisible = false

            this.getTable()
          },
          () => {
            this.editDepartmentLoading = false
          }
        )
        break;
      case 'edit':
        this.departmentApi.editDepart(
          params,
          (res: any) => {
            this.editDepartmentLoading = false

            this.departmentDialogVisible = false

            this.getTable()
          },
          () => {
            this.editDepartmentLoading = false
          }
        )
        break;
    }
  }

  // 提交部门参数
  private getDepartmentParams(departMode: string) {
    const form = this.form

    const {
      id,
      department_name
    } = form || {}

    const params: any = {

    }

    if (department_name) {
      params.department_name = department_name
    } else {
      this.$message.error('请输入部门名称')
      return false
    }

    if (departMode == 'edit') {
      params.id = id
    }

    return params
  }

  // 初始化 部门弹框
  private initDepartmentform(departItem: any = null) {
    const {
      id,
      department_name
    } = departItem || {}

    const form: any = {
      ...this.deepLoopUtil.deepCopy(defaultForm)
    }

    if (department_name) {
      form.department_name = department_name
    }

    if (id) {
      form.id = id
    }

    this.form = form
  } 

  // 删除部门
  private handleDeleteDepartment(departItem: any) {
    console.log(departItem, 'departItem')

    this.$confirm('确定删除该部门？', '', {
      ...shareConfirmOptions
    }).then(() => {
      this.departmentApi.deleteDepart({
        id: departItem.id
      },
      () => {
        this.getTable()
      }
      )
    })
  }

  // 拉起 部门权限配置弹框
  private handleWakeDepartMentSettingMenuDialog(departItem: any) {
    this.curChooseDepartmentItem = departItem

    this.initAllMenusConfigList()

    this.departSettingRightsDialogVisible = true
  }

  /** 部门权限配置 */
  private curChooseDepartmentItem: any = {
    // department_name: '测试部门'
  }

  private departSettingRightsDialogVisible: boolean = false

  private allMenusCheck: boolean = false

  private rightMenuSettingHeaders: any[] = [
    {
      label: '',
      name: 'select',
    },
    {
      label: '菜单名称',
      name: 'menu-name',
    },
    {
      label: '菜单功能',
      name: 'menu-children',
    }
  ]

  private rightMenuFlexTables = [
    1,
    5,
    7
  ]

  private editDepartmentMenuSettingLoading: boolean = false

  private curRightMenusOriginList: any[] = []
  private curRightMenusList: any[] = []

  private curRightMenusMapList: Map<string, any> = new Map()

  private curChooseRightMenuIdsList: any[] = []

  private curIdMapParentsIds: Map<string, any[]> = new Map()


  private bfcMenuList(list: any[], handle: Function, mode: string = 'every') {
    const queue: any[] = [
      ...(list || [])
    ]

    let result: any = 1

    while (queue.length) {
      const item = queue.shift()

      switch (mode) {
        case 'every':
          result &= handle(item)
          break;
        case 'some':
          result = handle(item)
          if (result) {
            return result
          }
          break;
      }

      if (Array.isArray(item.child)) {
        queue.push(
          ...(item.child)
        )
      }
    }

    switch (mode) {
      case 'every':

        return result
      case 'some':

        return false
    }
  }

  private bfcMenuMapList(list: Map<string, any[]>, handle: Function) {
    const queue: any[] = []

    for (const [_, item] of list) {
      queue.push(item)
    }

    // console.log(queue, 'queue')

    while (queue.length) {
      const mapItem = queue.shift()

      handle(mapItem)

      if (mapItem.children && mapItem.children.size > 0) {
        for (const [_, child] of mapItem.children) {
          queue.push(child)
        }
      }
    }
  }

  private refreshCurChooseRightMenuIdsList(list: any[]) {
    const curChooseRightMenuIdsList: any[] = []

    this.bfcMenuList(list, (item: any) => {
      if (item.is_selected == 1) {
        curChooseRightMenuIdsList.push(item.id) 
      }
    })

    this.curChooseRightMenuIdsList = curChooseRightMenuIdsList
  }

  private async initAllMenusConfigList() {
    const curChooseDepartmentItem = this.curChooseDepartmentItem

    if (!curChooseDepartmentItem.id) {
      this.$message.error('未获取到部门信息，请重试')
      return 
    }
    this.departmentApi.getDepartRightMenuList({
      department_id: curChooseDepartmentItem.id
    }, (res: any) => {
      const {
        list
      } = res.data || {}

      const mapList = getConfigMapList(list, 'exist-id')

      // 是否选中 0否 1是
      const allMenusCheck = !this.bfcMenuList(list, (item: any) => item.is_selected == 0, 'some')

      this.curRightMenusOriginList = this.deepLoopUtil.deepCopy(list)

      this.allMenusCheck = allMenusCheck

      const curChooseRightMenuIdsList: any[] = []

      this.bfcMenuList(list, (item: any) => {
        item.__is_expend = true

        if (item.is_selected == 1) {
          curChooseRightMenuIdsList.push(item.id) 
        }
      })

      // console.log(mapList, 'mapList')

      // console.log(curChooseRightMenuIdsList, 'curChooseRightMenuIdsList')

      this.bfcMenuMapList(mapList, (mapItem: any) => {
        // console.log(mapItem, 'mapItem')
        // console.log(mapItem.__parent_ids, 'mapItem.__parent_ids')
        this.curIdMapParentsIds.set(mapItem.id, mapItem.__parent_ids)
      })

      // console.log(this.curIdMapParentsIds, 'curIdMapParentsIds')

      // this.refreshCurChooseRightMenuIdsList(list)

      this.curChooseRightMenuIdsList = curChooseRightMenuIdsList

      this.curRightMenusList = list

      this.curRightMenusMapList = mapList
    })
  }

  private handleChangeExpend(menuCategoryItem: any) {
    const curRightMenusList = this.curRightMenusList
    const existCategoryIndex = curRightMenusList.findIndex((item: any) => item.id == menuCategoryItem.id)

    if (existCategoryIndex > -1) {
      curRightMenusList[existCategoryIndex].__is_expend = !curRightMenusList[existCategoryIndex].__is_expend

    }
  }

  // 改变所有menu 选中状态
  private handleChangeAllChooseMenus(check_status: boolean) {
    if (check_status) {
      const curRightMenusList = this.curRightMenusList

      const chooseMenuIds: any[] = []
      this.bfcMenuList(curRightMenusList, (item: any) => {
        item.is_selected = 1

        chooseMenuIds.push(item.id)
      })

      console.log(chooseMenuIds, 'chooseMenuIds')

      this.curChooseRightMenuIdsList = chooseMenuIds
    } else {
      const curRightMenusList = this.curRightMenusList

      this.bfcMenuList(curRightMenusList, (item: any) => {
        item.is_selected = 0
      })
      this.curChooseRightMenuIdsList = []
    }
  }

  // 改变某一个menu 选中状态
  private handleChangeChooseMenu(check_status: boolean, menuItem: any) {
    const curIdMapParentsIds = this.curIdMapParentsIds

    const curRightMenusMapList = this.curRightMenusMapList

    const idsList: any[] = []

    const allParentsIds = [
      ...(curIdMapParentsIds.get(menuItem.id) || []),
    ]

    function getMenuLevelMapList(menuItem: any, curRightMenusMapList: Map<string, any>) {
      const parent_ids = curIdMapParentsIds.get(menuItem.id) || []

      function getCurMapListByParentMapItem(parent_ids: any[], mapList: Map<string, any>, level: number = 0): Map<string, any> {
        if (parent_ids.length == 0) {
          return mapList
        }

        // console.log(parent_ids, level, 'parent_ids, level')
        // console.log(parent_ids[level], 'parent_ids[level]')
        const parentMapItem = mapList.get(parent_ids[level])

        if (level < parent_ids.length - 1) {
          return getCurMapListByParentMapItem(parent_ids, parentMapItem.children, level + 1)
        }

        // console.log(parentMapItem, 'parentMapItem')
        // console.log(level, 'level')

        return parentMapItem.children
      }

      return getCurMapListByParentMapItem(parent_ids, curRightMenusMapList)
    }
    function getMenuChildrenIds(menuItem: any, mapList: Map<string, any>) {
      // console.log(mapList, 'mapList')
      const menuMapItem = mapList.get(menuItem.id) || {}

      const idsList: any[] = []

      if (menuMapItem.children && menuMapItem.children.size > 0) {
        for (const [childId, child] of menuMapItem.children) {
          idsList.push(childId)
          idsList.push(
            ...getMenuChildrenIds(child, menuMapItem.children)
          )
        }
      }

      // console.log(idsList, 'idsList')

      return idsList
    }

    function getChildIdsByCurId(menuId: any) {
      const mapList = getMenuLevelMapList({ id: menuId }, curRightMenusMapList)

      // console.log(mapList, 'mapList')

      return getMenuChildrenIds({ id: menuId }, mapList)
    }

    


    idsList.push(
      ...(allParentsIds || []),
      menuItem.id,
    )

    // console.log(menuItem, 'menuItem')
    switch (menuItem.level) {
      case 1:
        idsList.push(
          ...getMenuChildrenIds(menuItem, curRightMenusMapList)
        )
        break;
      case 2:
        idsList.push(
          ...getMenuChildrenIds(menuItem, getMenuLevelMapList(menuItem, curRightMenusMapList))
        )
        break;
      case 3:
      default:
    }

    console.log(idsList, 'idsList')

    if (check_status) {
      const curChooseRightMenuIdsList = Array.from(new Set([
        ...(this.curChooseRightMenuIdsList),
        ...idsList
      ]))
      
      this.curChooseRightMenuIdsList = curChooseRightMenuIdsList
    } else {
      const curChooseRightMenuIdsList = this.curChooseRightMenuIdsList

      const hadSpliceIds: any[] = []

      for (let len = curChooseRightMenuIdsList.length, i = len - 1; i >= 0; --i) {
        const id = curChooseRightMenuIdsList[i]

        const childrenIds = getChildIdsByCurId(id)

        // if (id == 7) {
        //   console.log(childrenIds, 'childrenIds')
        //   console.log(curChooseRightMenuIdsList, 'curChooseRightMenuIdsList')
        // }
        if (idsList.includes(id) && !childrenIds.some((id) => curChooseRightMenuIdsList.includes(id)) && !hadSpliceIds.includes(id)) {
          
          curChooseRightMenuIdsList.splice(i, 1)
          hadSpliceIds.push(id)
        }
      }

    }
  }

  // 获取已经去重的menuids
  private getChooseUnqiueMenuIds() {
    const curChooseRightMenuIdsList = this.curChooseRightMenuIdsList

    // console.log(curChooseRightMenuIdsList, 'curChooseRightMenuIdsList')

    const resultList: any[] = Array.from(new Set([
      ...curChooseRightMenuIdsList
    ])).filter((id: any) => !!id)

    // console.log(resultList, 'resultList')

    return resultList
  }

  private getDepartmentMenuSettingParams() {
    const curChooseDepartmentItem = this.curChooseDepartmentItem

    const params = {
      department_id: curChooseDepartmentItem.id,
      menu_ids: this.getChooseUnqiueMenuIds().join(',')
    }

    if (!params.menu_ids) {
      this.$message.error("请至少选中一项权限")
      return false
    }

    // console.log(params, 'params')

    return params
    // return false
  }

  // 提交部门权限配置
  handleSubmitEditDepartmentMenuSetting() {
    const params = this.getDepartmentMenuSettingParams()

    if (typeof params != 'object') return 

    this.editDepartmentMenuSettingLoading = true
    this.departmentApi.editDepartRightmenus(params, () => {
      this.editDepartmentMenuSettingLoading = false

      this.departSettingRightsDialogVisible = false

      this.getTable()
    }, () => {
      this.editDepartmentMenuSettingLoading = false
    })
  }



  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}

import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

import { CommonApi } from "../common";

export class MerchantWithdrawConsumeAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getMerchantWithdrawRecordList', {
      url: '/admin/shopWithdraw/lists',
      name: 'list',
      label: '列表'
    }],
    ['exportExcelMerchantWithdrawList', {
      url: '/admin/shopWithdraw/export',
      name: 'export-excel',
      label: '导出'
    }],
    ['customDecreaseMerchantWithdraw', {
      url: '/admin/shopWithdraw/deduction',
      name: 'custom-decrease-merchant-withdraw-consume',
      label: '自定义商家提现余额扣款'
    }],

    ['searchVenueList', {
      url: '/admin/coupons/shop/list',
      name: 'shop-list',
      label: '店铺列表',
      // hidden: true
    }],
  ])
  // 订单api 方法
  // 商家提现余额扣款记录列表
  public getMerchantWithdrawRecordList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getMerchantWithdrawRecordList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

 
  // 导出商家提现余额扣款记录列表
  public exportExcelMerchantWithdrawList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('exportExcelMerchantWithdrawList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 自定义商家提现余额扣款
  public customDecreaseMerchantWithdraw(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('customDecreaseMerchantWithdraw').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 搜索店铺列表
  public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('searchVenueList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
}
}

import { render, staticRenderFns } from "./pandaWebsiteDynamicManage.vue?vue&type=template&id=f2bd5260&scoped=true"
import script from "./pandaWebsiteDynamicManage.vue?vue&type=script&lang=ts"
export * from "./pandaWebsiteDynamicManage.vue?vue&type=script&lang=ts"
import style0 from "./pandaWebsiteDynamicManage.vue?vue&type=style&index=0&id=f2bd5260&prod&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2bd5260",
  null
  
)

export default component.exports
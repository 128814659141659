
import { Component, Vue } from "vue-property-decorator";
import { Login } from "@/common/login/login";
import getMenuList from "@/common/asideMenu";
import { DeepLoop } from "@/common/deepLoop/deepLoop";

const aes = require("@/common/bin/jsencrypt.js");

@Component
export default class App extends Vue {

  private get appDomHeightCalc() {
    console.log(this.$store.getters.isMobile, 'this.$store.getters.isMobile')
    if (this.$store.getters.isMobile) {
      return '100%';
    } else {
      const appHeight = this.appHeight;
      return `calc(${appHeight}px + env(safe-area-inset-bottom))`;
    }
  }

  private appHeight: number = 0;

  private created() {
    this.appHeight = parseInt(localStorage.getItem("clientHeight")!);

    getMenuList().then((menuList: any[]) => {
      console.log(menuList, 'menuList')
      localStorage.setItem("menus", JSON.stringify(menuList));
    }, (err: any) => {
      console.log(err, 'err')
    })


    // if(performance.navigation.type === 1) {
    //     this.$store.commit('setIsTimer');
    //     const refreshToken: Login = new Login();
    //     refreshToken.setRefreshToken();
    // }
    sessionStorage.clear();
    // localStorage.removeItem("selectMchId");
    // if (localStorage.getItem("menus")) {
    //   const menus = JSON.parse(localStorage.getItem("menus")!);

    //   const resourceCodes = new DeepLoop().getResources(menus);
    //   console.log(resourceCodes);
    //   this.$store.commit("setResourceCodes", resourceCodes);
    // }
  }

  private metaDom: any 
  private mounted() {
    if (!this.metaDom && this.$store.getters.isMobile) {
      const meta = document.createElement('meta')

      meta.setAttribute('name', 'viewport');

      // meta.name = 'viewport';

      meta.setAttribute('content', `width=device-width,initial-scale=1.0`);

      // meta.content = 'width=device-width,initial-scale=1.0';

      (document.querySelector('head') as any).append(meta)

      this.metaDom = meta
    }
  }
}

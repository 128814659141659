
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import { UserApi } from "@/common/api/userManage/userList";
import { CommonApi } from "@/common/api/common";

import { excuteDownloadExport2Excel } from "@/common/xlsx";
export const pageApiList = getPageAPIs(UserApi);

import {
  transferUserLevel2ch,
  defaultUserMemberList,
} from "@/common/config";

@Component({})
export default class UserList extends Vue {
  private utilInstance: any;
  private userApi: any;

  private commonApi: any;

  private currentWillingForbiddenAcccount: any;

  private changeForbiddenLoading: boolean = false;

  private all_forbidden_time_list: any[] = [];

  private userStatuLevelList: any[] = [
    ...Array(11)
        .fill(0)
        .map((_, index: number) => {
          const item = {
            label: `${index}档`,
            value: index,
          };

          return item;
        }),
  ];

  private userAuthList: any[] = [
    {
      label: '普通用户',
      value: 0,
    },
    {
      label: '公司员工',
      value: 1
    }
  ]

  // private forbiddenVisible: boolean = false;

  // private fobiddenTypesList: any[] = [
  //   {
  //     label: "普通封禁",
  //     value: "normal",
  //   },
  //   {
  //     label: "永久封禁",
  //     value: "forever",
  //   },
  // ];

  // private forbiddenForm: any = {
  //   mode: "normal", // normal 普通封禁 forever 永久封禁
  //   duration: 0, // 封禁时长 ，只在mode = normal 有效
  //   reason: "", // 原因
  // };

  private transferUserLevel2ch: any = transferUserLevel2ch;

  constructor() {
    super();
    this.userApi = new UserApi();

    this.commonApi = new CommonApi();

    this.utilInstance = new Util();

    // 封禁时间列表
    this.all_forbidden_time_list = Util.getFoibiddenTimeList();
  }

  private firstLoading: boolean = true;

  private form: any = {
    // searchKw: "", // 搜索用户昵称/用户ID/手机号
    userId: "", // 用户ID
    userName: "", // 用户昵称
    mobile: "", // 手机号
    invite_code: "",
    // gender: 0, // 性别
    // userType: 0, //用户类型
    userLevel: -1, // 会员等级
    time: [], // 时间选择
    // activeStatus: 0, // 活跃状态
  };

  private transferGender2ch: any[] = ["未知", "女", "男"];

  private transferUserAccountStatus2ch: any[] = ["", "正常", "封号", "注销"];

  // 会员类型:1黄金, 2钻石,3星耀,4王者
  private userLevelList: any[] = [...(defaultUserMemberList || [])];

  // 用户状态列表
  private userStatusTypeList: any[] = [
    {
      label: "良好",
      value: 1,
    },
    {
      label: "正常",
      value: 2,
    },
    {
      label: "一般",
      value: 3,
    },
    {
      label: "恶劣",
      value: 4,
    },
  ];

  private activated(): void {
    this.getTable();
  }
  private getTable(): void {
    this.userApi.getUserTableList(
      this.getUserTableListParams(),
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        const { list, count, page, prepage } = data || {};

        const newTableList = Array.isArray(list)
          ? list.map(this.handleTableData)
          : [];

        this.$set(this.tablePage, "totalCount", count);
        this.$set(this.tablePage, "pageNo", page);
        this.$set(this.tablePage, "pageSize", prepage);

        this.tableData = newTableList;

        this.firstLoading = false;
      },
      () => {
        this.firstLoading = false;
      }
    );
  }

  private getUserTableListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const firstLoading = this.firstLoading;

    const {
      userId, // 用户ID
      userName, // 用户昵称
      mobile, // 手机号
      userLevel, // 会员等级
      invite_code, // 邀请码
      time, // 时间选择
    } = form || {};

    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};

    if (userId) {
      outerParams.id = userId;
    }

    if (userName) {
      outerParams.user_name = userName;
    }

    if (mobile) {
      outerParams.phone = mobile;
    }

    if (invite_code) {
      outerParams.invite_code = invite_code;
    }

    if (userLevel != -1 && typeof userLevel == "number") {
      outerParams.type = userLevel;
    }

    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      outerParams.created_start_time = startTime;

      outerParams.created_end_time = endTime;
    }

    if (
      mode == "exportExcel" &&
      !(outerParams.created_start_time && outerParams.created_end_time)
    ) {
      this.$message.error("请选择时段导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      outerParams.__isUnique = !firstLoading;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  // 后台返回的字段 转化为前端定义的字段
  private handleTableData(data: any): any {
    const {} = data;

    const newData = {
      ...data,
    };

    return newData;
  }

  // 上传头像
  private userAvatarUploading: boolean = false;
  private handleUploadUserAvatar(file: any, fileList: any[]) {
    console.log(file, fileList, "file", "fileList");

    this.userAvatarUploading = true;
    this.commonApi.getOss(
      file instanceof File ? file : file.raw,
      (res: any) => {
        console.log(res, "res 图片上传成功");

        const { data } = res;

        const { data: callbackData } = data;

        const { item: uploadImgObj } = callbackData || {};

        const { id, url } = uploadImgObj;

        this.$set(this.editUserForm, "avatar_id", id);
        this.$set(this.editUserForm, "avatar_path", url);

        this.userAvatarUploading = false;
      },
      () => {
        this.$message.error("图片上传失败");

        this.userAvatarUploading = false;
      }
    );
  }

  private editUserInfoDialogVisible: boolean = false;

  private editUserInfoLoading: boolean = false;

  private editUserForm: any = {
    id: "", // 用户id
    user_name: "", // 用户名
    avatar_path: "", // 头像
    phone: "", // 手机号
    birthday: "", // 生日
    gender: 0, // 性别
    // is_merchant: "", // 是否是商家
    status: "", // 最后登录
    created_at: "",
    // isSuperRecommander: false, // 超级推荐员
    // superRecommanderLevelId: "", // 超级推广员等级id
    type: 2, // 会员类型:0未开通，1黄金, 2钻石,3星耀,4王者
    level: 0, // 0~10 档
    user_type: 0 // 用户类型 0普通用户 1内部员工
  };

  // 编辑用户弹框
  private handleWakeEditUserInfoDialog(userItem: any) {
    console.log(userItem, "userItem");

    const { id: user_id } = userItem;

    set(this, this.tableData, userItem, "__userInfoLoading", true);

    this.userApi.getUserDetail(
      {
        id: user_id,
      },
      (res: any) => {
        console.log(res, "res");

        const { data } = res || {};

        const editUserForm = {
          ...data,
        };

        this.editUserForm = editUserForm;

        this.editUserInfoDialogVisible = true;

        set(this, this.tableData, userItem, "__userInfoLoading", false);
      },
      () => {
        set(this, this.tableData, userItem, "__userInfoLoading", false);
      }
    );

    function set<T>(vm: any, list: T[], item: T, key: string, val: any) {
      const existIndex = list.findIndex((eachItem) => eachItem == item);

      if (existIndex > -1) {
        if (item && typeof item == "object" && !Array.isArray(item))
          vm.$set(list[existIndex], key, val);
      }
    }
  }

  private handleSubmitEditUserInfo() {
    const params = this.getEditUserInfoParams();

    if (!params) {
      return;
    }

    this.editUserInfoLoading = true;
    this.userApi.editUserInfo(
      params,
      () => {
        this.getTable();

        this.editUserInfoLoading = false;

        this.editUserInfoDialogVisible = false;
      },
      () => {
        this.editUserInfoLoading = false;
      }
    );
  }

  private getEditUserInfoParams(): any {
    const editUserForm = this.editUserForm;

    const { id, user_name, phone, avatar_id, level, user_type } = editUserForm;

    const params: any = {
      id,
      level,
      user_type
    };

    const reg = new RegExp(
      "^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\\d{8}$"
    );

    if (user_name) {
      params.user_name = user_name;
    }

    if (phone) {
      if (!reg.test(phone)) {
        this.$message.error("请输入有效手机号");
        return;
      }

      params.phone = phone;
    }

    if (avatar_id) {
      params.avatar = avatar_id;
    }

    // params.isSuperInvite = isSuperRecommander ? '1' : '0'

    return params;
  }

  // 限制电话输入数字
  private handleLimitMobileIntegetInput(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        this.$set(this.editUserForm, "phone", new_val);
      },
      (new_val: string) => {
        this.$set(this.editUserForm, "phone", new_val);
      }
    );
  }

  // 初始化封禁表单信息
  // private initForbiddenFormData() {
  //   const initForbiddenForm = this.utilInstance.clearObject(this.forbiddenForm);

  //   initForbiddenForm.mode = "normal";

  //   this.forbiddenForm = initForbiddenForm;
  // }

  // 清除封禁表单校验信息
  // private clearFobiddenFormValidate() {
  //   const refs: any = this.$refs || {};

  //   const { forbiddenForm: forbiddenFormInstance } = refs || {};

  //   const { clearValidate } = forbiddenFormInstance || {};

  //   if (typeof clearValidate === "function") {
  //     clearValidate();
  //   }
  // }

  // 提交封禁账户信息
  // private handleSubmitForbiddenAccount() {
  //   const refs: any = this.$refs || {};

  //   const { forbiddenForm: forbiddenFormInstance } = refs || {};

  //   const { validate } = forbiddenFormInstance || {};

  //   if (typeof validate == "function") {
  //     validate((valid: boolean) => {
  //       if (valid) {
  //         const currentWillingForbiddenAcccount =
  //           this.currentWillingForbiddenAcccount;

  //         const { userId } = currentWillingForbiddenAcccount || {};

  //         const params = this.getForbiddenAccountParams();

  //         params.userId = userId;

  //         this.changeForbiddenLoading = true;
  //         this.userApi.forbideenUserAccount(
  //           {
  //             ...params,
  //           },
  //           () => {
  //             // this.forbiddenVisible = false;

  //             this.$message.success("封禁用户成功");

  //             this.changeForbiddenLoading = false;

  //             this.getTable();
  //           },
  //           () => {
  //             this.changeForbiddenLoading = false;
  //           }
  //         );
  //       } else {
  //         this.$message({
  //           message: "带* 为必填项",
  //           type: "error",
  //         });
  //       }
  //     });
  //   }
  // }

  // 获取封禁账户传入参数
  // private getForbiddenAccountParams() {
  //   const forbiddenForm = this.forbiddenForm;

  //   const { mode, duration, desc } = forbiddenForm;

  //   const outerParams: any = {
  //     isBlock: 1,
  //     desc,
  //   };

  //   switch (mode) {
  //     case "normal": // 普通封禁
  //       outerParams.type = 1;

  //       outerParams.time = duration; // 单位秒
  //       break;
  //     case "forever": // 永久封禁
  //       outerParams.type = 2;

  //       break;
  //   }

  //   return outerParams;
  // }

  // 查看用户订单
  private handleGoUserOrderList(userItem: any): void {
    console.log(userItem, "userItem");

    const { id: userId } = userItem || {};

    if (userId) {
      this.$router.push({
        path: "/orderList",
        query: {
          userId,
        },
      });
    }
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 用户字段名 文案对应关系
  private userExcelList2ch: any = {
    id: "用户ID",
    user_name: "用户昵称",
    phone: "用户手机号",
    gender_name: "性别",
    invite_code: "邀请码",
    birthday: "生日",
    user_level_name: "会员等级",
    created_at: "注册时间",
    account_status_name: "账户状态",
  };
  // 用户Excel 表头的先后顺序
  private userExcelSortList: string[] = [
    "id",
    "user_name",
    "phone",
    "gender_name",
    "invite_code",
    "birthday",
    "user_level_name",
    "created_at",
    "account_status_name",
  ];

  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getUserTableListParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.userApi.exportExcelUserList(
      params,
      (res: any) => {
        const { created_start_time, created_end_time } = params || {};

        const filename = `用户列表 ${created_start_time}到${created_end_time}.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const { gender, type, status } = item;
              const newItem = {
                ...(item || {}),
              };

              // if (!forbidTime) {
              newItem.gender_name = this.transferGender2ch[gender];

              newItem.user_level_name = this.transferUserLevel2ch[type];

              newItem.account_status_name =
                this.transferUserAccountStatus2ch[status];
              // }

              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const userExcelSortList = this.userExcelSortList;

          const userExcelList2ch = this.userExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            userExcelSortList,
            userExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */

  private restForm(): void {
    this.form = this.utilInstance.clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}


import { Component, Model, Prop, Vue, Watch } from "vue-property-decorator";

import PandaServiceAPI from "@/common/api/utils/pandaService";

import chatTalkItem from "@/components/panda-service/chatTalkItem.vue";

import chatDialog from "@/components/panda-service/chatDialog.vue";

import { shareConfirmOptions } from "@/common/config";

import {
  clearNotification
} from '@/common/system-notification';

import {
  setServingChat,
  getServingChat,
  delServingChat,
  batchDelExpriedChats,
  setServUserInfo,
} from "@/utils/index";

// eslint-disable-next-line
import PandaServiceWorker from "@/common/panda-service.share-worker.js";

import createPandasocket, {
  NORMAL_CLOSE_WS_CODE,
  CLOSE_WS_BEFORE_RECONNECT_CODE,
} from "@/common/websocket";
import { clear } from "xe-utils/methods";
// import { Deep } from '../com/util/deepCopy';
function isDef(val: any): boolean {
  return val !== null && val !== undefined;
}

const allServiceStatus2ch: string[] = ["离线", "在线"];

const defaultPageOptions: any = {
  page: 0,
  limit: 20,
};

@Component({
  filters: {
    showContentLimit(content: string, len: number = 32) {
      if (typeof content !== "string") return content;

      if (content.length > len) {
        return `${content.slice(0, len)}...`;
      }

      return content;
    },
  },
  components: {
    "chat-talk-item": chatTalkItem,
    "chat-dialog": chatDialog,
  },
})
export default class SharedService extends Vue {
  // @Prop({
  //   type: Array,
  //   validator: function (val: any[]): boolean {
  //     // console.log(val, 'val')
  //     const pureVals = val.map((item) => item.value);

  //     // console.log(pureVals, 'pureVals')

  //     // 不可有相同value item 项
  //     return Array.from(new Set(pureVals)).length === pureVals.length;
  //   },
  //   default: (): any[] => [
  //     {
  //       name: "test1",
  //       value: 1,
  //     },
  //     {
  //       name: "test2",
  //       value: 2,
  //     },
  //   ],
  // })
  // private list: any;

  // @Model("chooseVal", {
  //   type: Array,
  //   default: () => [],
  // })
  // private value: any;

  // private cutValue: any[] = [];

  private pandaServiceApi: any;

  constructor() {
    super();

    // this.cutValue = []

    this.pandaServiceApi = new PandaServiceAPI();
  }

  // 客服功能入口
  private userLineStatus: number = 1; // 0 1

  private handleRefreshUserLineStatus(curStatus: number) {
    switch (curStatus) {
      case 0:
        this.pandaServiceApi.offlinePandaSer(
          {},
          () => {
            this.$message.success("当前已离线");
          },
          () => {}
        );
        break;
      case 1:
        this.pandaServiceApi.onlinePandaSer(
          {},
          () => {
            this.$message.success("当前已在线");
          },
          () => {}
        );
        break;
    }
  }

  private get userLineStatusName() {
    const status = this.userLineStatus;

    return allServiceStatus2ch[status];
  }

  private showServiceCount = 0;

  private dialogImChatVisible: Boolean = false;
  
  /** 通知相关逻辑 属性/方法 */

  private isDisabeldSystemNotification: Boolean = false

  private isFocusWindow: Boolean = true

  @Watch("dialogImChatVisible", { immediate: false })
  private $effectChangeNotificationEnabled(newVal: Boolean) {
    this.handleCommonSystemNotificationStatus()
  }

  private handleCommonSystemNotificationStatus() {
    const pageVisible = document.visibilityState

    const isFocusWindow = this.isFocusWindow

    // const pageHidden = document.hidden

    console.log(isFocusWindow, 'isFocusWindow')

    const dialogImChatVisible = this.dialogImChatVisible

    // console.log(pageHidden, 'pageHidden')

    const isDisabeldSystemNotification: Boolean = (pageVisible === 'visible' && dialogImChatVisible && isFocusWindow)

    if (isDisabeldSystemNotification) {
      this.handleClearImNotifyMessage()

      this.isShowMarqueeTopNotification = false
    }

    this.isDisabeldSystemNotification = isDisabeldSystemNotification

    this.handleChangeSystemNotification(isDisabeldSystemNotification)
  }

  private handleFocusWindowStatus() {
    this.isFocusWindow = true

    this.handleCommonSystemNotificationStatus()
  }

  private handleBlurWindowStatus() {
    this.isFocusWindow = false

    this.handleCommonSystemNotificationStatus()
  }

  private handleChangeSystemNotification(enabled: Boolean) {
    const isSharedWorkerMode = this.isSharedWorkerMode

    if (isSharedWorkerMode) {
      const worker = this.shareWorker

      worker.port.postMessage({
        type: 'change-enable-notification',
        data: enabled
      })
    } else {
      this.wsSock.__disabledNotify = enabled
    }
  }

  private handleClearImNotifyMessage() {
    const isSharedWorkerMode = this.isSharedWorkerMode

    if (isSharedWorkerMode) {
      const worker = this.shareWorker

      worker.port.postMessage({
        type: 'clear-notification',
        data: null
      })
    } else {
      clearNotification()
    }
  }

  private isShowMarqueeTopNotification: Boolean = false

  private handleShowMarqueeTopNotification() {
    this.isShowMarqueeTopNotification = true
  }

  // 页面顶部跑马灯通知 唤醒
  private handleWakeDocumentTopNotification() {
    const isDisabeldSystemNotification = this.isDisabeldSystemNotification

    if (isDisabeldSystemNotification) return

    this.handleShowMarqueeTopNotification()
  }

  /** end 通知相关逻辑 属性/方法 */

  private handleWakeImChatDialog() {
    this.initServiceInfo();

    this.handleClearImNotifyMessage();

    this.isShowMarqueeTopNotification = false

    this.dialogImChatVisible = true;
  }


  private servicerInfo: any = {};

  private refreshPandaSerEntryInfo() {
    this.pandaServiceApi.getPandaSerInfo(
      {},
      (res: any) => {
        const { item: serInfo } = res.data || {};

        if (serInfo) {
          setServUserInfo(serInfo);

          const { is_online, servingCount } = serInfo || {};

          this.showServiceCount = servingCount;

          this.userLineStatus = is_online;

          this.servicerInfo = serInfo;
        }
      },
      () => {}
    );
  }

  private initServiceInfo() {
    this.initServiceList();
  }

  private clearCurSerMerchantInfo() {
    this.pandaSerMerchantInfo = {};

    this.curSerTalkingDurationTime = "";

    clearTimeout(this.curSerTalkingTimer);
  }

  private onClosedPandaServingChat() {
    this.curSerActiveName = "talking-service";

    this.clearCurSerMerchantInfo();
  }

  private get pandaChatDialog(): any {
    const refs = this.$refs;

    const { pandaChatDialog } = refs || {};

    return pandaChatDialog;
  }

  private get isDisbaledPandaChat() {
    const activeName = this.curSerActiveName;

    if (activeName !== "talking-service") return "all";

    return "none";
  }

  private isSameSwoId(swo_id: any, cur_swo_id: any) {
    return !!(swo_id && swo_id == cur_swo_id);
  }

  // 重新刷新当前聊天记录
  private handleRefreshPandaMerchantChatList() {
    const dialogImChatVisible = this.dialogImChatVisible;

    // 对话框是否已打开
    if (!dialogImChatVisible) return;

    const pandaSerMerchantInfo = this.pandaSerMerchantInfo;

    // 当前是未结束会话 才刷新
    if (pandaSerMerchantInfo.end_time !== 0) return;

    const pandaChatDialog = this.pandaChatDialog;

    console.log(pandaChatDialog, "pandaChatDialog");

    const chatListSwoId = pandaChatDialog.serId;

    // 是同一个选中的工单
    if (this.isSameSwoId(pandaSerMerchantInfo.id, chatListSwoId)) {
      pandaChatDialog.initPandataChatList();
    }
  }

  private handleManualPushChatDialog(replyItem: any) {
    const pandaChatDialog = this.pandaChatDialog;

    console.log(pandaChatDialog, "pandaChatDialog");

    const chatItem = pandaChatDialog.createManualPushChatItem(
      replyItem.content,
      1
    );

    pandaChatDialog.addManualPushChat([chatItem]);

    pandaChatDialog.sendMessageByWs(chatItem);
  }

  private handleManualAddMoreChats(chats: any[]) {
    const pandaChatDialog = this.pandaChatDialog;

    console.log(pandaChatDialog, "pandaChatDialog");

    const chatListSwoId = pandaChatDialog.serId;

    const addChats: any[] = [];

    chats.forEach((chat) => {
      // 工单id相同才能加入聊天列表
      if (this.isSameSwoId(chat.swo_id, chatListSwoId)) {
        chat.id = chat.chat_id;

        addChats.push(chat);
      }
    });

    if (addChats.length) pandaChatDialog.addManualPushChat(addChats);
  }

  private handleManualDelLocalChat(chatItem: any) {
    const pandaChatDialog = this.pandaChatDialog;

    console.log(pandaChatDialog, "pandaChatDialog");

    const chatListSwoId = pandaChatDialog.serId;

    const { swo_id, unique_id } = chatItem || {};

    const isSame = this.isSameSwoId(swo_id, chatListSwoId);

    console.log(isSame, "isSame");
    console.log(chatItem, "isSame chatItem");

    if (isSame) {
      pandaChatDialog.delLocalChat(unique_id);
    }
  }

  private handleManualUpdateLocalChat(chatItem: any) {
    const pandaChatDialog = this.pandaChatDialog;

    console.log(pandaChatDialog, "pandaChatDialog");

    const chatListSwoId = pandaChatDialog.serId;

    const { swo_id, unique_id, chat_id } = chatItem || {};

    if (this.isSameSwoId(swo_id, chatListSwoId)) {
      pandaChatDialog.updateChatItems(swo_id, unique_id, "cur", {
        id: chat_id,
      });
    }
  }

  /** 侧边栏列表 逻辑 */
  private chatServiceList: any[] = [
    {
      label: "会话中",
      name: "talking-service",
    },
    {
      label: "已结束",
      name: "finished-service",
    },
  ];

  // 监听会话中红点本地存储的变化
  private servingChats: any = getServingChat();
  private addServingChatInfo(chat_id: number, ts: number | null) {
    setServingChat(chat_id, ts);

    this.servingChats = getServingChat();

    console.log(this.servingChats, "servingChats");
  }

  private delServingChatInfo(chat_id: number) {
    delServingChat(chat_id);

    this.servingChats = getServingChat();
  }

  private getServingDotStatus(chatItem: any) {
    const pandaSerMerchantInfo = this.pandaSerMerchantInfo;
    const { id, content } = chatItem || {};

    const cur_swo_id = pandaSerMerchantInfo.id || "";

    if (cur_swo_id && cur_swo_id == id) return false;

    const { send_time } = content || {};

    const servingChats = this.servingChats || {};

    if (id in servingChats) {
      const storeTs = servingChats[id];

      // console.log(storeTs, 'storeTs')
      // console.log(send_time, 'send_time')

      if (!storeTs) {
        return !!send_time;
      } else {
        return storeTs < send_time;
      }
    } else {
      return true;
    }
  }

  private curSerActiveName: string = "talking-service";

  private handleChangeChatAccordion(activeName: string) {
    console.log("activename", activeName);

    switch (activeName) {
      case "talking-service":
      case "finished-service":
        this.initServiceList();
        break;
    }
  }

  private serActiveList: any[] = [];

  private serActiveTotal: number = 0;

  private servicePageOption: any = {
    ...defaultPageOptions,
  };

  // 会话列表
  private initServiceList() {
    this.servicePageOption = {
      ...defaultPageOptions,
    };

    this.serActiveTotal = 0;

    this.serActiveList = [];

    this.clearCurSerMerchantInfo();

    this.refreshServiceList(true);
  }

  private refreshServiceItemContent(id: number, content: any) {
    if (!id || !content) return;

    const activeName = this.curSerActiveName;

    if (activeName !== "talking-service") return;

    const serActiveList = this.serActiveList;

    const existSerIndex: number = serActiveList.findIndex(
      (serItem: any) => serItem.id == id
    );

    if (existSerIndex == -1) return;

    serActiveList[existSerIndex].content = content;

    this.serActiveList = serActiveList;
  }

  private getSerApi(params: any) {
    const activeName = this.curSerActiveName;

    let sendApi: Function = () => Promise.resolve();

    switch (activeName) {
      case "talking-service":
        sendApi = this.pandaServiceApi.getTalkingList;
        break;
      case "finished-service":
        sendApi = this.pandaServiceApi.getFinishTalkList;
        break;
    }

    return new Promise((resolve, reject) => {
      sendApi.call(
        this.pandaServiceApi,
        params,
        (res: any) => resolve(res),
        (err: any) => reject(err)
      );
    });
  }

  private async refreshServiceList(fresh: boolean = false) {
    const activeName = this.curSerActiveName;

    const pageOption = this.servicePageOption;

    const params: any = {};

    switch (activeName) {
      case "talking-service":
        break;
      case "finished-service":
        pageOption.page++;
        Object.assign(params, {
          ...pageOption,
        });

        if (fresh) {
          params.page = 1;
        }
        break;
    }

    const { list, count, page, prepage } = await this.getSerApi(params).then(
      (res: any) => res.data || {}
    );

    this.serActiveList = fresh ? list : this.serActiveList.concat(list);

    switch (activeName) {
      case "talking-service":
        this.serActiveTotal = list.length;

        this.showServiceCount = this.serActiveTotal;
        if (fresh) {
          const pandaSerMerchantInfo = this.pandaSerMerchantInfo;

          if (!(pandaSerMerchantInfo && pandaSerMerchantInfo.id)) {
            this.initServingMerchantInfo({
              ...(list[0] || {}),
            });
          }
        }

        // 清除无效会话信息
        {
          const storeSwoIds: number[] = this.serActiveList.map(
            (item: any) => item.id
          );

          batchDelExpriedChats(storeSwoIds);
        }
        break;
      case "finished-service":
        this.servicePageOption.page = page || params.page;

        this.servicePageOption.limit = prepage || params.limit;

        this.serActiveTotal = count;
        break;
    }
  }

  private addMoreSerActive() {
    const total = this.serActiveTotal;

    const list = this.serActiveList;

    if (total > list.length) {
      this.refreshServiceList();
    }
  }

  private onClickChatMerchant({ item }: any) {
    console.log(item, "item");

    const preChatItem = this.pandaSerMerchantInfo || {};

    if (preChatItem.id === item.id) return;

    this.initServingMerchantInfo(item);
  }

  /** end 侧边栏列表 逻辑 */

  /** 对话框顶部 逻辑 */
  private pandaSerMerchantInfo: any = {}; // 当前选中的商户信息

  private serviceOptionList: any[] = [
    {
      label: "会话转移",
      icon: require("@/assets/imService/transfer-icon.png"),
      name: "transfer-ser",
      disabled: true,
    },
    {
      label: "快捷回复",
      icon: require("@/assets/imService/quick-reply-icon.png"),
      name: "quick-reply",
      disabled: true,
    },
    {
      label: "结束会话",
      icon: require("@/assets/imService/close-service-icon.png"),
      name: "close-service",
      disabled: true,
    },
  ];

  private handleClickSerOption(optionItem: any) {
    const { name, disabled } = optionItem || {};

    if (disabled) return;

    const pandaSerMerchantInfo = this.pandaSerMerchantInfo;

    const cur_swo_id = pandaSerMerchantInfo.id;

    switch (name) {
      case "transfer-ser": // 转移客服
        if (this.curMainChatAsideMode == "transferSer") {
          this.curMainChatAsideMode = "timeline";
          return;
        }
        this.curMainChatAsideMode = "transferSer";

        this.refreshAvailabelTransferSerList(true);
        break;
      case "quick-reply": // 快捷回复
        if (this.curMainChatAsideMode == "quickReply") {
          this.curMainChatAsideMode = "timeline";
          return;
        }
        this.curMainChatAsideMode = "quickReply";

        this.refreshQuickReplyModuleList(true);
        break;
      case "close-service": // 结束客服
        this.$confirm(`确定结束会话?`, "", {
          ...shareConfirmOptions,
        }).then(() => {
          this.pandaServiceApi.finishTalkService(
            {
              swo_id: cur_swo_id,
            },
            () => {
              this.$message.success("当前会话已结束");
              this.initServiceList();
            },
            () => {}
          );
        });
        break;
    }
  }

  // 顶部右侧的操作项状态 刷新
  private refreshServiceOptionInfo(serItem: any) {
    const { id } = serItem || {};

    const optionList = this.serviceOptionList;

    const activeName = this.curSerActiveName;

    console.log(activeName, "activeName refreshServiceOptionInfo");

    optionList.forEach((item) => {
      switch (activeName) {
        case "finished-service":
          item.disabled = true;
          break;
        case "talking-service":
        default:
          item.disabled = !id;
          break;
      }
    });

    this.serviceOptionList = optionList;
  }

  // 当前会话定时功能
  private curSerTalkingTimer: any = null;

  private curSerTalkingDurationTime: string = "";
  private initCurSerTimer(ts: number) {
    // console.log(ts, 'ts initCurSerTimer')
    if (typeof ts !== "number") return;

    const getShowDurationTime = (diffTs: number) => {
      const padZero = (num: number) => (num >= 10 ? num : "0" + num);
      const restSecs = diffTs % 60;

      const overSecs = Math.floor(diffTs / 60);

      if (overSecs > 0) {
        const restMins = overSecs % 60;

        const overMins = Math.floor(overSecs / 60);

        if (overMins > 0) {
          return `${padZero(overMins)}:${padZero(restMins)}:${padZero(
            restSecs
          )}`;
        } else {
          return `${padZero(restMins)}:${padZero(restSecs)}`;
        }
      } else {
        return `${padZero(restSecs)}`;
      }
    };

    const startTalkingTimer = (ts: number) => {
      const nowTs = Math.floor(Date.now() / 1000);

      if (ts > nowTs) return;

      const diffTs = nowTs - ts;

      this.curSerTalkingDurationTime = getShowDurationTime(diffTs);

      this.curSerTalkingTimer = setTimeout(() => {
        startTalkingTimer(ts);
      }, 1000);
    };

    startTalkingTimer(ts);
  }

  private resetCurSetTimer() {
    this.curSerTalkingDurationTime = "";

    clearTimeout(this.curSerTalkingTimer);
  }

  private initServingMerchantInfo(merchantSerItem: any) {
    this.pandaSerMerchantInfo = merchantSerItem || {};

    const { id, start_time, content } = merchantSerItem || {};

    const { send_time = null } = content || {};

    console.log(merchantSerItem, "merchantSerItem");

    this.addServingChatInfo(id, send_time);

    this.refreshServiceOptionInfo(merchantSerItem);

    console.log(start_time, "start_time");
    console.log(typeof start_time, "start_time");

    this.resetCurSetTimer();
    const activeName = this.curSerActiveName;

    if (activeName == "talking-service") {
      this.initCurSerTimer(start_time);
    }

    this.curMainChatAsideMode = "timeline";

    this.refreshSerTimelineRecordList();
  }

  /**end 对话框顶部 逻辑 */

  /* 聊天框右侧 逻辑 */
  private curMainChatAsideMode: string = "timeline"; // timeline | transferSer | quickReply

  private serTimelineRecords: any[] = [];

  private refreshSerTimelineRecordList() {
    const pandaSerMerchantInfo = this.pandaSerMerchantInfo;

    const { id: swo_id } = pandaSerMerchantInfo || {};

    if (!swo_id) return;

    this.pandaServiceApi.getServiceTimelineRecord(
      {
        swo_id,
      },
      (res: any) => {
        const { list = [] } = res.data || {};

        this.serTimelineRecords = list;
      },
      (err: any) => {}
    );
  }

  // 可以转移的客服列表
  private curChooseTransferSerId: number = -1;
  private allAvailableTransferServicerList: any[] = [];
  private refreshAvailabelTransferSerList(fresh: boolean = false) {
    this.pandaServiceApi.getAvailableTransferService(
      {},
      (res: any) => {
        const { list = [] } = res.data || {};

        if (fresh) {
          this.curChooseTransferSerId = list.length > 0 ? list[0].id : -1;
        }

        this.allAvailableTransferServicerList = list;
      },
      (err: any) => {}
    );
  }

  private handleCancelTranserSer() {
    this.curMainChatAsideMode = "timeline";
  }

  private handleConfirmTransferSer() {
    const pandaSerMerchantInfo = this.pandaSerMerchantInfo;

    const params = {
      swo_id: pandaSerMerchantInfo.id,
      waiter_id: this.curChooseTransferSerId,
    };

    this.pandaServiceApi.transferTalkService(
      params,
      (res: any) => {
        this.$message.success("转移客服成功");

        // 删除该商户的红点记录
        this.delServingChatInfo(pandaSerMerchantInfo.id);

        this.initServiceList();
      },
      () => {}
    );
  }

  // 快捷回复列表
  private allChooseQuickReplyList: any[] = [];
  private refreshQuickReplyModuleList(fresh: boolean = false) {
    this.pandaServiceApi.getQuickReplyList(
      {},
      (res: any) => {
        const { list = [] } = res.data || {};

        this.allChooseQuickReplyList = list;
      },
      () => {}
    );
  }

  private handleSendQuickReply(replyItem: any) {
    // 发送消息
    const pandaSerMerchantInfo = this.pandaSerMerchantInfo;

    if (!pandaSerMerchantInfo.id) return;

    // 手动上屏推送
    this.handleManualPushChatDialog(replyItem);
    // this.sendSocketMessage({
    //   type: 1,
    //   to: pandaSerMerchantInfo.id,
    //   content: replyItem.content
    // })
  }

  /* end 聊天框右侧 逻辑 */

  private onChatDialogSendMessage({ chatItem }: any) {
    const { type, swo_id, id, content: contentObj } = chatItem || {};

    const { content, url } = contentObj || {};

    const params: any = {
      type,
      to: swo_id,
      unique_id: id,
    };

    switch (type) {
      case 1:
        params.content = content;
        break;
      case 2:
      case 3:
        params.url = url;
        break;
    }

    // 收到返回后  记得更新当前工单 发送最新时间戳

    this.sendSocketMessage(params);
  }

  private created() {
    this.refreshPandaSerEntryInfo();

    this.initPandaSocket();

    // setTimeout(() => {
    //   this.sendSocketMessage({
    //     type: 'barrage',
    //     id: 113,
    //     second: 2
    //   })
    // }, 3000)

    document.addEventListener("visibilitychange", this.handleCommonSystemNotificationStatus);

    window.addEventListener('blur', this.handleBlurWindowStatus);
      
    window.addEventListener('focus', this.handleFocusWindowStatus);
  }

  private destroyed() {
    this.disconnectPandaSocket();

    document.removeEventListener("visibilitychange", this.handleCommonSystemNotificationStatus);

    window.removeEventListener('blur', this.handleBlurWindowStatus);
      
    window.removeEventListener('focus', this.handleFocusWindowStatus);
  }

  // SharedWorker 结合 websocket 功能
  private wsSock: any = null;

  private shareWorker: any = null;

  private isSharedWorkerMode: Boolean = typeof SharedWorker == "function";

  private sendSocketMessage(params: any) {
    // 1 普通文字消息 2 图片消息 3 视频消息
    const { type, to, unique_id, ...others } = params || {};
    const wsParams = {
      type,
      to,
      unique_id,
      content: others,
    };

    if (this.isSharedWorkerMode) {
      // const workParams = {
      //   type: 'message',
      //   data: JSON.stringify(params)
      // }
      // console.log(workParams, 'workParams')
      // console.log(this.shareWorker.port.postMessage, 'this.shareWorker.port.postMessage')
      console.log(wsParams, "wsParams");
      this.shareWorker.port.postMessage(JSON.stringify(wsParams));
    } else {
      this.wsSock.send(JSON.stringify(wsParams));
    }
  }

  private handleReceivedBySendMsg(chatItem: any) {
    const {
      code, // 200成功 400没有传工单id 401工单已结束 402发送的消息类型错误 403找不到对应的工单

      swo_id,
      // chat_id,
      content,
      // unique_id,
    } = chatItem || {};

    const { send_time = null } = Array.isArray(content) ? {} : content || {};

    const pandaSerMerchantInfo = this.pandaSerMerchantInfo;

    const cur_swo_id = pandaSerMerchantInfo.id || "";

    console.log(chatItem, "chatItem receviced ");
    console.log(cur_swo_id, "cur_swo_id receviced ");

    switch (code) {
      case 200:
        if (cur_swo_id == swo_id) {
          this.addServingChatInfo(swo_id, send_time);
        }

        this.refreshServiceItemContent(swo_id, content);

        this.handleManualUpdateLocalChat(chatItem);
        break;
      case 400:
        this.$message.error("会话信息缺失，请刷新页面重试");
        break;
      case 401:
        this.$message.error("会话已结束");
        break;
      case 402:
        this.$message.error("发送消息类型错误");
        break;
      case 403:
        this.$message.error("暂无该会话信息");
        break;
    }

    if (code !== 200) {
      this.handleManualDelLocalChat(chatItem);
    }
  }

  private receiveSocketMessage(evt: any) {
    console.log(evt, "evt receive");
    const { data: originData } = evt || {};

    const chatItem = originData ? JSON.parse(originData) : {};

    const pandaSerMerchantInfo = this.pandaSerMerchantInfo;

    const cur_swo_id = pandaSerMerchantInfo.id || "";

    const { type, swo_id, content } = chatItem || {};

    const { send_time } = content || {};

    // 消息类型 -1消息发送反馈 1 普通文字消息 2 图片消息 3 视频消息 4 消息模板 5 问题库消息 6 接入消息 7 退出消息 8 选择机器人选项回复

    switch (type) {
      case -1: // type -1 接收到消息反馈（仅限本人发送的）
        this.handleReceivedBySendMsg(chatItem);
        break;
      case 1:
      case 8:
      // break;
      case 2:
      // break;
      case 3:
        this.handleManualAddMoreChats([chatItem]);

        if (cur_swo_id == swo_id) {
          this.addServingChatInfo(swo_id, send_time);
        }

        this.refreshServiceItemContent(swo_id, content);
        break;
      case 7:
        this.$message.success("用户已结束当前会话");
      case 6:
        this.initServiceList();

        this.handleWakeDocumentTopNotification()
        break;
      // 暂时不会触发
      case 4:
      case 5:
      case 8:
        break;
    }
  }

  private initPandaSocket() {
    const isSharedWorkerMode = this.isSharedWorkerMode;

    console.log(isSharedWorkerMode, "isSharedWorkerMode");
    if (isSharedWorkerMode) {
      // this.clearSharedWorkerData()
      this.clearSharedWorker();
      const worker = new PandaServiceWorker();

      console.log(worker, "worker");

      // worker.onerror = function(err) {
      //   console.log('shared worker err', err)
      // }

      function sendReconnectMsg() {
        worker.port.postMessage({
          type: "reconnect-ws",
          data: localStorage.getItem("token"),
        });
      }

      worker.port.onmessage = (e: any) => {
        console.log(e, "worker e onmessage");

        const { type, data } = e.data;

        switch (type) {
          case "open":
            if (data._isReconnect) {
              this.$message.success("重连成功");
              this.handleRefreshPandaMerchantChatList();
            }
            break;
          case "message":
            this.receiveSocketMessage({
              data,
            });
            break;
          case "reconnect":
            sendReconnectMsg();
            break;
          case "err-tip":
            this.$message.error(data);
            break;
          case "close":
            switch (data.code) {
              case CLOSE_WS_BEFORE_RECONNECT_CODE:
                this.$message.info("网络不稳定，正在尝试重连");
                break;
              case NORMAL_CLOSE_WS_CODE:
                break;
              default:
                this.$message.info("连接意外断开，正在尝试重连");
                break;
            }
            break;
        }
      };

      worker.port.postMessage({
        type: "init",
        data: localStorage.getItem("token"),
      });

      worker.port.start();

      this.shareWorker = worker;

      // setTimeout(() => {
      //   worker.port.postMessage('test')

      //   this.shareWorker.port.postMessage('test1')
      // }, 2000)
    } else {
      this.wsSock = createPandasocket({
        token: localStorage.getItem("token"),
        onOpen: (evt: any) => {
          if (evt._isReconnect) {
            this.$message.success("重连成功");
          }
        },
        onMessage: (evt: any) => {
          console.log(evt, "evt");
          this.receiveSocketMessage(evt);
        },
        onClose: (evt: any) => {
          switch (evt.code) {
            case CLOSE_WS_BEFORE_RECONNECT_CODE:
              this.$message.info("网络不稳定，正在尝试重连");
              break;
            case NORMAL_CLOSE_WS_CODE:
              break;
            default:
              this.$message.success("连接意外断开，正在尝试重连");
              break;
          }
        },
        onReconnect: ({ sockIns }: any) => {
          if (sockIns instanceof WebSocket) {
            this.wsSock = sockIns;
          }
        },
      });
    }
  }

  // private clearSharedWorkerData() {
  //   const worker = this.shareWorker;

  //   if (worker instanceof SharedWorker) {
  //     worker.port.postMessage('clear-init')
  //   }
  // }

  private clearSharedWorker() {
    const worker = this.shareWorker;
    if (worker instanceof SharedWorker) {
      worker.port.close();
    }
  }

  private disconnectPandaSocket() {
    if (this.isSharedWorkerMode) {
      // const workParams = {
      //   type: 'close',
      //   data: null
      // }

      this.shareWorker.port.close();

      // this.shareWorker.port.postMessage()

      // this.shareWorker.terminate()
    } else {
      this.wsSock.close();
    }
  }
}

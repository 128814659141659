import { BasicsClass } from '@/common/BasicsClass';

export default class CoachBanList extends BasicsClass {

    public apiUrls: any = new Map([
        ['list', {
            url: '/admin/coachBanned/index',
            name: 'list',
            label: '助教封禁列表'
        }],
        ['add', {
            url: '/admin/coachBanned/add',
            name: 'add',
            label: '助教封禁'
        }],
        ['unseal', {
            url: '/admin/coachBanned/unseal',
            name: 'unseal',
            label: '助教封禁'
        }],

    ])
    // 封禁列表
    public getList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {

        params = {
            ...(params || {})
        }

        const apiUrls = this.apiUrls

        const url = apiUrls.get('list').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 添加封禁
    public addBan(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('add').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    // 解除封禁
    public freeBan(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('unseal').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

}

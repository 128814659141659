import { BasicsClass } from '@/common/BasicsClass';
export class CashierClientUpdaterAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getCashierClientUpdater', {
            url: '/admin/cashier/client',
            name: 'list',
            label: '列表'
        }],
        ['addCashierClientUpdater', {
            url: '/admin/cashier/client/update',
            name: 'add',
            label: '添加'
        }],
        // ['updateCashierClientUpdater', {
        //     url: '/admin/couponsPlatform/edit',
        //     name: 'edit',
        //     label: '编辑'
        // }],
        // ['delteHourCouponItem', {
        //     url: '/admin/couponsPlatform/delete',
        //     name: 'delete',
        //     label: '删除'
        // }],
        // ['poolTableCategory', {
        //     url: '/admin/poolTableCategory/list',
        //     name: 'pool-category',
        //     label: '台桌/包厢类型',
        //     // hidden: true
        // }],
    ])


    // 列表
    public getCashierClientUpdater(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getCashierClientUpdater').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 新增
    public addCashierClientUpdater(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('addCashierClientUpdater').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 修改
    // public updateCashierClientUpdater(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    //     const apiUrls = this.apiUrls

    //     const url = apiUrls.get('updateCashierClientUpdater').url

    //     this.BasicPost(url, {
    //         ...params
    //     }, false, false, false, successCb, failCb, showLoading)
    // }



    // 删除
    // public delteHourCouponItem(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    //     const apiUrls = this.apiUrls

    //     const url = apiUrls.get('delteHourCouponItem').url

    //     this.BasicPost(url, {
    //         ...params
    //     }, false, false, false, successCb, failCb, showLoading)
    // }



    //球桌
    // public poolTableCategory(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    //     const apiUrls = this.apiUrls

    //     const url = apiUrls.get('poolTableCategory').url

    //     this.BasicPost(url, {
    //         ...params
    //     }, false, false, false, successCb, failCb, showLoading)
    // }
}

import { Component, Vue } from "vue-property-decorator";
import { Util, getPageAPIs } from "@/common/util";
import BrilliantShowAPI  from "@/common/api/operationManage/brilliantShowList";
import gallery from "@/components/gallery.vue";
import { DeepLoop } from "@/common/deepLoop/deepLoop";

import {
  shareConfirmOptions,
} from "@/common/config";
export const pageApiList = getPageAPIs(BrilliantShowAPI);

@Component({
  filters: {
    showPicOrThumbnail(row: any) {
      const { video_url } = row || {};
      if(!video_url) return ''
      return `${video_url}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`
    }
  },
  components: {
    gallery,
  },
})
export default class CommercialCollegesManage extends Vue {
  private form: any = {
    phone: '', // 手机号码
    nickname: '', //  0全部 1正常(上架) 2下架
    time: []
  };
  // private time = []
  private tableData: any[] = [];
  private commentTableData: any[] = [];
  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  private commentTableParams = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  }
  private dialogTitle = ''
  private currentEditId = ''
  // private statusArrays = [
  //   {value:0,label:'全部'},
  //   {value:1,label:'上架'},
  //   {value:2,label:'下架'},
  // ]
  private deepLoopUtil: any = {};

  private brilliantShowApi = new BrilliantShowAPI();

  private dialogVisible: boolean = false;

  constructor() {
    super();
    this.deepLoopUtil = new DeepLoop();
  }


  private created(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    console.log('created')
    this.getBrilliantShowList()
  }

  private activated() {
    // if(this.$route && this.$route.query && this.$route.query.phone){
    //   this.form.phone = this.$route.query.phone
    // }

    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    console.log('activated')
    this.getBrilliantShowList()
  }


  private restForm(): void {
    this.form = new Util().clearObject(this.form);
    // this.time = []
    // if(this.$route.query.phone){
    //   this.$router.replace({
    //     path: "/dynamicManage",
    //     query: {}
    //   })
    // }
    this.getBrilliantShowList();
  }
  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    
    this.getBrilliantShowList();
  }


  // 查看视频/图片
  private handleBeforePreviewMedia(row: any) {
    const video_url = row || {};
    // if(!medium.length) return
    const previewItem: any = {};
    const type: number = 2

    const medium = [video_url]

    switch (type) {
      // case 1: // 图片
      //   previewItem.image_count = medium.length;
      //   previewItem.images = medium.map((pic: any) => pic.url);
      //   break;
      case 2: // 视频
        previewItem.video_count = medium.length;

        previewItem.videos = medium.map((pic: any) => pic.url);
        break;
    }

    this.handlePreviewMedia(previewItem);
  }

  private images: any = [];

  private handlePreviewMedia(row: any) {
    const { image_count, images, video_count, videos } = row || {};

    const urls: any[] = [];

    if (image_count > 0) {
      urls.push(
          ...images.map((img: string) => {
            return {
              title: "",
              type: "image/jpeg",
              href: img,
              thumbnail: img,
            };
          })
      );
    }

    if (video_count > 0) {
      urls.push(
          ...videos.map((video: string) => {
            return {
              title: "",
              type: "video/mp4",
              href: video,
              poster: `${video}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`,
            };
          })
      );
    }
    this.images = urls;
  }


  //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getBrilliantShowList();
  }

  private handlePageChangeComment(val: any) {
    this.commentTableParams.page = val.currentPage;
    this.commentTableParams.prepage = val.pageSize;
    this.getBrilliantShowCommentList(this.currentEditId)
  }


  private openCommentDialog( row:any ){
    this.commentTableData = []
    this.dialogVisible = true
    this.dialogTitle = `${row.nickname}-评论列表`
    this.currentEditId = row.id
    this.getBrilliantShowCommentList(row.id)
  }

  private updateBrilliantShowState(row: any) {
    this.brilliantShowApi.updateBrilliantShowState({id: row.id,},
        () => {
          this.$message.success('修改成功')
          this.getBrilliantShowList();
        },
        () => {}
    )
  }

  private handleDeleteBrilliantShow(row: any) {
    this.$confirm("确定删除该精彩球吗?", "", {
      ...shareConfirmOptions,
    }).then(() => {
      this.brilliantShowApi.deleteBrilliantShow({id: row.id,},
          () => {
            this.$message.success('删除成功')
            this.getBrilliantShowList();
          },
          () => {}
      );
    });
  }

  private handleDeleteBrilliantShowComment(row: any) {
    this.$confirm("确定删除该评论吗?", "", {
      ...shareConfirmOptions,
    }).then(() => {
      this.brilliantShowApi.deleteBrilliantShowComment(
          {
            del_id:row.id
          },
          () => {
            this.$message.success('删除成功')
            this.getBrilliantShowCommentList(this.currentEditId)
          },
          () => {}
      );
    });
  }

  //获取精彩球列表
  private getBrilliantShowList(): void {
    this.brilliantShowApi.getBrilliantShowTableList(
        {
          ...this.getBrilliantShowParams()
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.page = res.data.page;
          this.tablePage.prepage = res.data.prepage;
          this.tablePage.count = res.data.count;
        },
        () => {}
    );
  }

  private getBrilliantShowParams() {
    const params: any = {}

    const form = this.form

    const tablePage = this.tablePage

    const {
      phone,
      nickname,
      time
    } = form || {}

    const {
      page,
      prepage
    } = tablePage || {}

    if (phone) {
      params.phone = phone
    }

    if (nickname) {
      params.nickname = nickname
    }

    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      params.start_date = startTime;
      params.end_date = endTime;
    }

    Object.assign(params, {
      page,
      limit: prepage
    })


    return params
  }

  //获取评论列表
  private getBrilliantShowCommentList(id:number|string): void {
    this.brilliantShowApi.getBrilliantShowCommentTableList(
        {
          id,
          page: this.commentTableParams.page,
          limit: this.commentTableParams.prepage,
        },
        (res: any) => {
          this.commentTableData = res.data.list;
          this.commentTableParams.page = res.data.page;
          this.commentTableParams.prepage = res.data.prepage;
          this.commentTableParams.count = res.data.count;
        },
        () => {}
    );
  }

}

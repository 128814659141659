import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class TechnicalServiceShopIncomeAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getTechnicalServiceShopIncomeTableList', {
            url: '/admin/platformIncome/shop/income',
            name: 'list',
            label: '列表'
        }],
        ['exportExcelTechnicalServiceShopIncomeTableList', {
            url: '/admin/platformIncome/shop/exportIncome',
            name: 'export-excel',
            label: '导出'
        }],
       
    ])

    // 开台时长列表
    public getTechnicalServiceShopIncomeTableList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getTechnicalServiceShopIncomeTableList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // // 导出开台时长记录
    public exportExcelTechnicalServiceShopIncomeTableList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('exportExcelTechnicalServiceShopIncomeTableList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    
}
import { BasicsClass } from '@/common/BasicsClass';

export default class SummerActivityShieldStore extends BasicsClass {

    public apiUrls: any = new Map([
        ['list', {
            url: '/admin/summer/shieldShop/list',
            name: 'list',
            label: '夏日活动屏蔽店铺列表'
        }],
        ['add', {
            url: '/admin/summer/shieldShop/add',
            name: 'add',
            label: '屏蔽店铺夏日活动'
        }],
        ['delete', {
            url: '/admin/summer/shieldShop/delete',
            name: 'delete',
            label: '删除夏日活动屏蔽店铺'
        }],
        ['searchVenueList', {
            url: '/admin/coupons/shop/list',
            name: 'shop-list',
            label: '店铺列表',
        }],
    ])
    public getList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {

        params = {
            ...(params || {})
        }

        const apiUrls = this.apiUrls

        const url = apiUrls.get('list').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    public add(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {

        params = {
            ...(params || {})
        }

        const apiUrls = this.apiUrls

        const url = apiUrls.get('add').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    public delete(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {

        params = {
            ...(params || {})
        }

        const apiUrls = this.apiUrls

        const url = apiUrls.get('delete').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('searchVenueList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

}

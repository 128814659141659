import { BasicsClass } from '@/common/BasicsClass';

export default class SummerActivityHorse extends BasicsClass {

    public apiUrls: any = new Map([
        ['list', {
            url: '/admin/summer/rewardFalseList',
            name: 'list',
            label: '中奖记录列表'
        }],
        ['add', {
            url: '/admin/summer/rewardFalseAdd',
            name: 'add',
            label: '添加跑马灯内容'
        }],
        ['edit', {
            url: '/admin/summer/rewardFalseUpdate',
            name: 'edit',
            label: '修改内容'
        }],
        ['delete', {
            url: '/admin/summer/rewardFalseDelete',
            name: 'delete',
            label: '删除内容'
        }],
    ])
    public getList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {

        params = {
            ...(params || {})
        }

        const apiUrls = this.apiUrls

        const url = apiUrls.get('list').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    public add(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {

        params = {
            ...(params || {})
        }

        const apiUrls = this.apiUrls

        const url = apiUrls.get('add').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    public edit(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {

        params = {
            ...(params || {})
        }

        const apiUrls = this.apiUrls

        const url = apiUrls.get('edit').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    public delete(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {

        params = {
            ...(params || {})
        }

        const apiUrls = this.apiUrls

        const url = apiUrls.get('delete').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }



}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
// import { CouponsManageAPI } from "@/common/api/couponList";
import OperationPartnerAmountAdjustRecordAPI from "@/common/api/competitionManage/operationPartnerAmountAdjustRecord";
import { excuteDownloadExport2Excel } from "@/common/xlsx";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import ZlSelect from "@/components/third-custom-components/select";

import ZlOption from "@/components/third-custom-components/option";

import { shareConfirmOptions, getCommonList } from "@/common/config";

export const pageApiList = getPageAPIs(OperationPartnerAmountAdjustRecordAPI);


// 操作类型：1增加 2扣除
const changeType2ch: string[] = ['', '增加', '扣除']

const defaultForms = {
  phone: "", // 手机号
  type: 1, // 操作类型
  venue_id: "",
  venue: {
    id: "",
    name: "",
  },
  price: "", // 金额
  reason: "", // 原因

  league_level: "", // 积分类型
  score_direction_type: 1, // 积分方向类型:1正数2负数
  operate_name: "", // 运营
  // venue_id: "",
  // venues: [],
  // singleMaxCountType: '0',
  // shop_id: "",
  // maxLimitCount: ''
};

const defaultSearchVenuePageOption = {
  page: 1,
  limit: 10,
  isRearchBottom: false,
};

const businessReminderDialogTitle2ch: any = {
  "new-partner-amount": "新增",
  "edit-partner-amount": "编辑",
};

// 最大有效期
// const maxExpiredDays = 9999;

// 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;

const maxTotalMoney = 9999999;

@Component({
  components: {
    ZlSelect,
    ZlOption,
  },
  filters: {
    showMutipleShopNames(tipRow: any) {
      const { shop_name = "", assistant_shop_name = "" } = tipRow || {};

      const names: string[] = [shop_name, assistant_shop_name].filter(
        (name: string) => !!name
      );

      return names.join("、");
    },
  },
})
export default class OperationPartnerAmountList extends Vue {
  private form: any = {
    // storageLockerId: "",
    phone: "",
    // time: []
    // shop_id: '',
    // time: [],
    // startDate: "",
    // endDate: "",
  };


  private forms: any = {
    // maxType: "0",
    // ...defaultForms
  };

  private deepLoopUtil: any = {};

  // private merchantApi: any;

  private partnerChangeAmountTypeList: any[] = getCommonList(changeType2ch, 1)

  private operationPartnerAmountAdjustRecordApi = new OperationPartnerAmountAdjustRecordAPI();

  // private storageLockerTimeDurationAPI = new OperationPartnerAmountTimeDurationManageAPI();
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    // this.searchVenuePageOption = {
    //   ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    // };

    // this.merchantApi = new MerchantApi();
  }

  private created(): void {
    this.getOperationPartnerAmountRecordList();

    // this.refreshOperationPartnerAmountTimeDurations();

    // this.refreshTableClass();
  }
  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getOperationPartnerAmountRecordList();
  }
  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getOperationPartnerAmountRecordList();
  }

  private tableData: any[] = [];

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";

  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getOperationPartnerAmountRecordList();
  }

  private addOrUpdateOperationPartnerAmountTitle: string = "新增";

  private dialogFormVisible: boolean = false;

  // 拉起运营提醒弹框
  private handleWakeOperationPartnerAmountDialog(mode: string) {
    // if (mode == 'new-partner-amount') { // 新增运营提醒

    // }
    this.storageLockerDialogMode = mode;
    // this.addOrUpdateOperationPartnerAmountTitle =
    //   businessReminderDialogTitle2ch[mode] || "运营提醒";

    this.initOperationPartnerAmountForm(mode);

    this.dialogFormVisible = true;
  }

  private currentOperationPartnerAmountItem: any = null;

  private storageLockerDialogMode: string = "";

  private async initOperationPartnerAmountForm(mode: string) {
    const forms: any = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    // if (mode == "edit-partner-amount") {
    //   // 编辑
    //   // await this.getMoreVenueList(true);

    //   // await this.refreshOperationPartnerAmountTimeDurations();

    //   // await this.refreshTableClass();

    //   const currentOperationPartnerAmountItem = this.currentOperationPartnerAmountItem;

    //   const {
    //     id,
    //     total_money,
    //     remark,
    //     operate_name,
    
    //   } = currentOperationPartnerAmountItem || {};

    //   if (total_money) {
    //     forms.total_money = total_money;
    //   }

    //   if (remark) {
    //     forms.remark = remark;
    //   }

    //   if (operate_name) {
    //     forms.operate_name = operate_name;
    //   }

    //   const venues: any[] = [];

    //   // const handleAddExtraVenue = (venues: any[], id: any, name: any) => {
    //   //   if (name && typeof id == "number") {
    //   //     let existVenue = this.venueList.find((venue) => venue.id == id);

    //   //     if (!existVenue) {
    //   //       existVenue = {
    //   //         id,
    //   //         name,
    //   //       };
    //   //       this.venueList.push(existVenue);
    //   //     }

    //   //     venues.push(existVenue);
    //   //   }
    //   // };

    //   // handleAddExtraVenue(venues, shop_id, shop_name);
    //   // handleAddExtraVenue(venues, assistant_shop_id, assistant_shop_name);

    //   // if (shop_name && typeof shop_id == "number") {
    //   //   console.log(shop_id, "shop_id");

    //   //   console.log(this.venueList, "this.venueList");
    //   //   let existVenue = this.venueList.find((venue) => venue.id == shop_id);

    //   //   if (!existVenue) {
    //   //     existVenue = {
    //   //       id: shop_id,
    //   //       name: shop_name,
    //   //     };
    //   //     this.venueList.push(existVenue);
    //   //   }

    //   //   forms.venue = existVenue;
    //   // }

    //   forms.venues = venues;

    //   forms.id = id;
    // }

    console.log(forms, "forms");

    // console.log(this.venueList, "this.venueList");

    this.forms = forms;
  }

  // private handleWakeEditOperationPartnerAmountDialog(storageLockerItem: any) {
  //   this.currentOperationPartnerAmountItem = storageLockerItem;

  //   this.handleWakeOperationPartnerAmountDialog("edit-partner-amount");
  // }

  // private handleDelteOperationPartnerAmountDialog(storageLockerItem: any) {
  //   this.$confirm("确定删除该运营提醒?", "", {
  //     ...shareConfirmOptions,
  //   }).then(() => {
  //     this.operationPartnerAmountAdjustRecordApi.deleteOperationPartnerAmount(
  //       {
  //         id: storageLockerItem.id,
  //       },
  //       () => {
  //         this.getOperationPartnerAmountRecordList();
  //       },
  //       () => {}
  //     );
  //   });
  // }

  /** 远程搜索店铺 */
  private venueList: any[] = [
    // {
    //   id: 1,
    //   label: '测试'
    // },
    // {
    //   id: 2,
    //   label: '测试2'
    // }
  ];

  private searchVenueLoading: boolean = false;

  private remoteGetVenueQueryString: string = "";

  private remoteSearchVenueTimer: any = null;

  private searchVenuePageOption: any = {};

  private initRemoteGetVenuePageOption() {
    this.searchVenuePageOption = {
      ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    };
  }

  private getSearchVenueParams() {
    const searchVenuePageOption = this.searchVenuePageOption;

    const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

    const { page, limit } = searchVenuePageOption;

    const {
      phone
    } = this.forms || {}

    const params: any = {
      page,
      limit,
    };

    if (phone) {
      params.phone = phone
    } else {
      this.$message.error('请先输入手机号')
      return false
    }

    if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
      params.shop_name = remoteGetVenueQueryString.trim();
    }

    return params;
  }

  private getMoreVenueList(refresh: boolean = true) {
    return new Promise((resolve: Function, reject: Function) => {
      if (refresh) {
        this.initRemoteGetVenuePageOption();
      }

      const params = this.getSearchVenueParams();

      if (typeof params != 'object') return

      this.operationPartnerAmountAdjustRecordApi.searchVenueList(
        params,
        (res: any) => {
          console.log(res, "res");

          const {
            list
          } = res.data || {}

          const venueList: any[] = list || [];

          this.venueList = refresh
            ? venueList
            : this.venueList.concat(venueList);

          if (Array.isArray(list) && list.length == 0) {
            this.searchVenuePageOption.isRearchBottom = true;
          }
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          resolve();
        },
        () => {
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          reject();
        }
      );
    });
  }

  // 第一次搜索 无关键字
  private handleFirstGetVenueList() {
    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 清空搜索
  private handleClearGetVenueList() {
    this.remoteGetVenueQueryString = "";

    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 远程搜索
  private remoteGetVenueMethod(query: string) {
    query = query.trim ? query.trim() : "";

    if (query !== "") {
      this.remoteGetVenueQueryString = query;
      clearTimeout(this.remoteSearchVenueTimer);

      this.remoteSearchVenueTimer = setTimeout(() => {
        this.searchVenueLoading = true;

        this.getMoreVenueList();
        // searchStu((res) => {
        //   const {
        //     students,
        //     total_count
        //   } = res || {}

        //   this.remoteAllStudents = [
        //     ...(students || [])
        //   ]

        //   this.remoteStudentTotalCount = total_count || 0

        //   this.remoteGetStudentLoading = false
        // }, {
        //   kw: query,
        //   ...this.getRemoteGetStudentPageOptionParams()
        // }, () => {
        //   this.remoteGetStudentLoading = false
        // })
      }, 300);
      // setTimeout(() => {
      //   this.remoteGetStudentLoading = false;
      //   this.options = this.list
      // }, 200);
    } else {
      this.remoteGetVenueQueryString = "";
      this.venueList = [];
    }
  }

  private addVenueListLoading: boolean = false;

  private remoteAddVenueMethod() {
    if (this.addVenueListLoading) return;
    const searchVenuePageOption = this.searchVenuePageOption;

    const { isRearchBottom } = searchVenuePageOption;

    if (isRearchBottom) return;

    this.searchVenuePageOption.page++;

    this.addVenueListLoading = true;

    this.getMoreVenueList(false);
  }
  /** end 远程搜索店铺 */

  // 流水
  // private handleLimitTotalMoney(val: string) {
  //   Util.limitIntegetInput(
  //     val,
  //     (new_val: string) => {
  //       const limit_val = Math.min(Number(new_val), maxTotalMoney).toString();

  //       if (maxTotalMoney == Number(limit_val)) {
  //         this.$set(this.forms, "total_money", limit_val);
  //       } else {
  //         this.$set(this.forms, "total_money", new_val);
  //       }
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "total_money", new_val);
  //     }
  //   );
  // }

  // private handleLimitSingleMaxCount(val: string) {
  //   Util.limitIntegetInput(
  //     val,
  //     (new_val: string) => {
  //       this.$set(this.forms, "maxLimitCount", new_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "maxLimitCount", new_val);
  //     }
  //   );
  // }

  // 金额
  private hanleLimitChangeAmount(val: string) {
    Util.limitDigitalnput(
      val,
      (new_val: string) => {
        this.$set(this.forms, "price", new_val);
      },
      (new_val: string) => {
        this.$set(this.forms, "price", new_val);
      },
      2
    );
  }

  private sumbitOperationPartnerAmountLoading: boolean = false;

  // 提交运营提醒配置
  private handleSumbitOperationPartnerAmount() {
    const storageLockerDialogMode = this.storageLockerDialogMode;

    const params = this.getOperationPartnerAmountFormsParams(storageLockerDialogMode);

    console.log(params, "params");
    if (params === false) return;

    this.sumbitOperationPartnerAmountLoading = true;
    switch (storageLockerDialogMode) {
      case "new-partner-amount":
        this.operationPartnerAmountAdjustRecordApi.changePartnerAmount(
          params,
          () => {
            this.getOperationPartnerAmountRecordList();
            this.dialogFormVisible = false;
            this.sumbitOperationPartnerAmountLoading = false;
          },
          () => {
            this.sumbitOperationPartnerAmountLoading = false;
          }
        );
        break;
      case "edit-partner-amount":
        // this.operationPartnerAmountAdjustRecordApi.updateOperationPartnerAmount(
        //   params,
        //   () => {
        //     this.getOperationPartnerAmountRecordList();
        //     this.dialogFormVisible = false;
        //     this.sumbitOperationPartnerAmountLoading = false;
        //   },
        //   () => {
        //     this.sumbitOperationPartnerAmountLoading = false;
        //   }
        // );
        break;
    }
  }

  private getOperationPartnerAmountFormsParams(mode: string) {
    const showErrorMessage = (msg: string) => {
      if (!!msg) {
        this.$message.error(msg);
      }
    };

    const forms = this.forms;

    const {
      id,
      // name,
      // expired_days,
      // meituanId,
      // timeDuration
      // machine_number,
      type,
      venue
      // remark,
      // operate_name,
      // venues,
    } = forms || {};

     const params: any = {
      type
    };

    // console.log(venues, 'venues')


    const keys = ["phone", "price", "reason"];

    const key2EndKey: any = {
      phone: "phone",
      price: "price",
      reason: "reason"
    };

    const key2ErrMsg: any = {
      phone: "手机号码不能为空",
      price: "金额不可为空",
      reason: "原因不可为空"
    };

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (forms[key]) {
        const errMsg = key2ErrMsg[`${key}_${forms[key]}`];
        if (errMsg) {
          showErrorMessage(errMsg);

          return false;
        }
        params[key2EndKey[key] || key] = forms[key];
      } else {
        showErrorMessage(key2ErrMsg[key]);

        return false;
      }
    }

    if (!(venue && venue.id)) {
      showErrorMessage('请选择店铺')
      return false
    }

    params.shop_id = venue.id

    if (mode == "edit-partner-amount") {
      params.id = id;
    }

    return params;
  }

  //获取运营提醒列表
  private getOperationPartnerAmountRecordList(): void {
    this.operationPartnerAmountAdjustRecordApi.getOperationPartnerAmountRecordList(
      { ...this.getOperationPartnerAmountParams() },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page;
        this.tablePage.prepage = res.data.prepage;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private getOperationPartnerAmountParams(mode = "all"): any {
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    const { phone  } = form;

    if (phone) {
      params.phone = phone;
    }

    

    // if (Array.isArray(time) && time.length === 2) {
    //   const [startDate, endDate] = time;

    //   const startTime = Util.dateTimeUtilnohms(startDate);
    //   const endTime = Util.dateTimeUtilnohms(endDate);

    //   params.start_date = startTime;

    //   params.end_date = endTime;
    // }

    if (mode == "exportExcel" && !(params.start_date && params.end_date)) {
      this.$message.error("请选择日期导出数据");
      return null;
    }

    if (mode === "all") {
      params.page = page;

      params.limit = prepage;

      // outerParams.__isUnique = !firstLoading;
    }

    return params;
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  /** 导出excel */
  // private downloadExcelLoading: boolean = false;

  // // 字段名 文案对应关系
  // private merchantExcelList2ch: any = {
  //   name: "店铺名称",
  //   shop_id: " 店铺ID",
  //   cabinet_id: "绑定设备ID",
  //   admin_name: "操作人",
  //   updated_at: "操作时间"
  // };
  // // 商户Excel 表头的先后顺序
  // private merchantExcelSortList: string[] = [
  //   "name",
  //   "shop_id",
  //   "cabinet_id",
  //   "admin_name",
  //   "updated_at"
  // ];

  // // 点击导出excel 表格
  // private handleExportExcelOperationPartnerAmountList() {
  //   const params = this.getOperationPartnerAmountParams('exportExcel')

  //   if (params === null) {
  //     return false;
  //   }

  //   this.downloadExcelLoading = true;
  //   this.operationPartnerAmountAdjustRecordApi.exportExcelStoragelockerList(
  //     params,
  //     (res: any) => {
  //       const { start_date, end_date } = params || {};

  //       const filename = `运营提醒管理列表 ${start_date}到${end_date}.xlsx`;
  //       const { data } = res || {};

  //       const {
  //         list: originList
  //       } = data || {}

  //       const newList = Array.isArray(originList)
  //         ? originList.map((item) => {
  //             const {
  //               // status,
  //               // back_goods_detail
  //              } = item || {};
  //             const newItem = {
  //               ...(item || {}),
  //               // __status_text: withdrawAccountAudit2Text[status],
  //               // __back_good_details: back_goods_detail.map((goods: any, index: number) => `${ goods.name }x${ goods.num }  ¥${ goods.price }`).join('; \t')
  //             };

  //             for (let key in item) {
  //               if (typeof item[key] == 'number') {
  //                 newItem[key] = item[key].toString()
  //               }
  //             }

  //             return newItem;
  //           })
  //         : [];

  //       if (newList.length > 0) {
  //         const merchantExcelSortList = this.merchantExcelSortList;

  //         const merchantExcelList2ch = this.merchantExcelList2ch;

  //         const excelJSONData = getExcelJSONDataByList(
  //           newList,
  //           merchantExcelSortList,
  //           merchantExcelList2ch
  //         );

  //         excuteDownloadExport2Excel(excelJSONData, filename);
  //       } else {
  //         this.$message.info("选中时段没有可导出的信息");
  //       }

  //       this.downloadExcelLoading = false;
  //     },
  //     () => {
  //       this.downloadExcelLoading = false;
  //     }
  //   );
  // }

  /** end 导出excel */

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };
}

import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

import { CommonApi } from "../common";

export class AllCompetitionPartnerListAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getAllCompetitionPartnerList', {
      url: '/admin/league/allContestant',
      name: 'list',
      label: '列表'
    }],
     
    ['exportExcelCompetitionPartnerList', {
        url: '/admin/league/exportAllContestant',
        name: 'export-excel',
        label: '导出'
    }],

    ['addCompetitionPartner', {
      url: '/admin/league/addContestant',
      name: 'add-pertner',
      label: '新增参赛选手'
  }]
  ])
  // api 方法
  // 全部参赛选手列表
  public getAllCompetitionPartnerList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getAllCompetitionPartnerList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 导出全部参赛选手列表
  public exportExcelCompetitionPartnerList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('exportExcelCompetitionPartnerList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 添加 参赛选手
  public addCompetitionPartner(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('addCompetitionPartner').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}

import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class DiamondRecordApi extends BasicsClass {
    public apiUrls: any = new Map([
        ['getDiamondExchangeRecordTableList', {
            url: '/admin/exchange/record',
            name: 'list',
            label: '列表'
        }],
        ['exportExcelDiamondExchangeList', {
            url: '/admin/exchange/exportRecord',
            name: 'export-excel',
            label: '导出'
        }]
    ])

    // 钻石兑换记录列表
    public getDiamondExchangeRecordTableList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getDiamondExchangeRecordTableList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // // 导出钻石兑换记录
    public exportExcelDiamondExchangeList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('exportExcelDiamondExchangeList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

}
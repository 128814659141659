
import dayjs from "@/plugins/dayjs";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import UserCouponApi from "@/common/api/operationManage/userCouponList";

import { excuteDownloadExport2Excel } from "@/common/xlsx";

import {
  shareConfirmOptions
} from '@/common/config'

export const pageApiList = getPageAPIs(UserCouponApi)

function getLastThreeMonthDate() {
  const lastEndDay = (dayjs().endOf('month') as any).format("YYYY-MM-DD");

  const lastStartDay = (
    dayjs().subtract(2, "month").startOf('month') as any
  ).format("YYYY-MM-DD");

  return [lastStartDay, lastEndDay];
}

const defaultForm = {
  status: 1, // 默认显示状态是可使用的劵
  phone: "", // 手机号
  receipt_code: "", // 券码
  // coach_id: '', // 助教ID
  // coach_mobile: '', // 助教手机号
  create_time: [
    ...getLastThreeMonthDate()
  ], // 消费时间
  // finish_time: [], // 完成时间
  // activeStatus: "", // 活跃状态
};

const couponStatus2ch: any = [
  '',
  '未使用',
  '已使用',
  '已过期'
]

const couponStatus2Label: string[] = [
  '时间',
  '获取时间',
  '使用时间',
  '过期时间'
]

@Component({})
export default class UserCouponList extends Vue {
  private userCouponApi: any;

  private deepLoopUtil: any;

  constructor() {
    super();
    this.userCouponApi = new UserCouponApi();

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
  }

  private form: any;

  private firstLoading: boolean = true;

  private couponStatus2Label: string[] = [
    ...couponStatus2Label
  ]
  // private chooseTechnicianItem : any = null;

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private couponStatusList: any[] = [
    {
      value: 1,
      label: "未使用",
    },
    {
      value: 2,
      label: "已使用",
    },
    {
      value: 3,
      label: "已过期",
    },
  ]

  // 所有可选技师
  private technicianList: any[] = [];

  // private showtimecard: boolean = false;
  private activated(): void {
    this.getTable();

  }

  // private mounted(): void {

  // }
  private getTable(): void {
    const params = this.getUserCouponTableListParams()
    this.userCouponApi.getUserCouponTableList(
      params,
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        /** ！！！！  后端 列表接口表示 总数的字段 统一 改为 count  再传total 打回去 重新改  ！！！！  */
        /** ！！！！  后端 列表接口表示 总数的字段 统一 改为 count  再传total 打回去 重新改  ！！！！  */
          // 该处是遗留页面，暂时不好修改  
        const { list, total, page, prepage } = data || {};
        /** ！！！！ end 后端 列表接口表示 总数的字段 统一 改为 count  再传total 打回去 重新改  ！！！！  */
        /** ！！！！ end 后端 列表接口表示 总数的字段 统一 改为 count  再传total 打回去 重新改  ！！！！  */

        const newTableList = Array.isArray(list)
          ? list.map(this.handleTableData)
          : [];

        this.$set(this.tablePage, "totalCount", total);
        this.$set(this.tablePage, "pageNo", page || params.page);
        this.$set(this.tablePage, "pageSize", prepage || params.limit);

        this.tableData = newTableList;

        this.firstLoading = false;
      },
      () => {
        this.firstLoading = false;
      },
      false
    );
  }

  private channelList: any[] = [];

  private getUserCouponTableListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const firstLoading = this.firstLoading;

    const {
      status, // 状态 1未使用 2已使用 3已过期
      phone,
      receipt_code,
      create_time, // 消费时间
      // finish_time, // 完成时间
    } = form || {};

    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};

    if (status) {
      outerParams.status = status;
    }

    if (phone) {
      outerParams.phone = phone;
    }

    if (receipt_code) {
      outerParams.receipt_code = receipt_code
    }


    if (Array.isArray(create_time) && create_time.length === 2) {
      const [startDate, endDate] = create_time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      outerParams.start_time = startTime;

      outerParams.end_time = endTime;
    }

    const route = this.$route;

    const { query } = route;

    const { userId } = query;

    if (userId) {
      outerParams.userId = userId;
    }

    if (
      mode == "exportExcel" &&
      !(outerParams.start_time && outerParams.end_time)
    ) {
      this.$message.error("请选择消费时段导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      outerParams.__isUnique = !firstLoading;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  
  // 后台返回的字段 转化为前端定义的字段
  private handleTableData(data: any): any {
    const { status, } = data;
    const newData = {
      ...data,
      // device_name: orderDeviceType2ch[device],
      // pay_type_name: payType2ch[pay_type],
      // user_level_type_name: userLevel2ch[user_type],
      __coupon_status_str: couponStatus2ch[status]
    };

    return newData;
  }

  // 使得卡券过期
  private handleExpireCoupon(couponItem: any) {
    this.$confirm("确定过期该优惠券?", "", {
      ...shareConfirmOptions,
    }).then(() => {
      this.userCouponApi.expireAvailableCoupon(
        {
          coupons_id: couponItem.id,
        },
        () => {
          this.getTable();
        },
        () => {}
      );
    });
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 订单字段名 文案对应关系
  private orderExcelList2ch: any = {
    "phone": "手机号",
    "user_name": "用户昵称",
    "created_at": "获得券的时间",
    "name": "卡券名称",
    "__coupon_status_str": "状态",
    "receipt_code": "券码",
    "valid_time": "到期时间",
    "use_time": "使用时间"
  };
  // 订单Excel 表头的先后顺序
  private orderExcelSortList: string[] = [
    "phone",
    "user_name",
    "created_at",
    "name",
    "__coupon_status_str",
    "receipt_code",
    "valid_time",
    "use_time"
  ];

  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getUserCouponTableListParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.userCouponApi.exportExcelUserCouponList(
      params,
      (res: any) => {
        const {
          start_time,
          end_time,
          status,
          // over_start_time,
          // over_end_time,
        } = params || {};

        const create_time_duration_name =
          start_time && end_time
            ? `${couponStatus2Label[status]}${start_time}到${end_time}`
            : "";

        // const finish_time_duration_name =
        //   over_start_time && over_end_time
        //     ? `完成时间${over_start_time}到${over_end_time}`
        //     : "";
        const filename = `优惠券列表 ${create_time_duration_name}.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const {
                status
              } = item || {};

              const newItem = {
                ...(item || {}),
                __coupon_status_str: couponStatus2ch[status]
              };

              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const orderExcelSortList = this.orderExcelSortList;

          const orderExcelList2ch = this.orderExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            orderExcelSortList,
            orderExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }


        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */

  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}

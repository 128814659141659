import AMapLoader from "@amap/amap-jsapi-loader";
window._AMapSecurityConfig = {
  serviceHost: "https://amap.pandaball.cc/_AMapService",
};
export function getAMap() {
  return AMapLoader.load({
    //注册开发者/创建应用，选择web端JS API（必须）
    key: "6ed21e83bcf578fe6385c7995f0ef6c1", //首次load必填
    version: "1.4.1",
    AMapUI: {
      version: "1.1",
      plugins: ["overlay/SimpleMarker"],
    },
    Loca: {
      version: "1.3.2",
    },
  });
}

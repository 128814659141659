import { render, staticRenderFns } from "./pandaSvga.vue?vue&type=template&id=6c0e27df&scoped=true"
import script from "./pandaSvga.vue?vue&type=script&lang=ts"
export * from "./pandaSvga.vue?vue&type=script&lang=ts"
import style0 from "./pandaSvga.vue?vue&type=style&index=0&id=6c0e27df&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c0e27df",
  null
  
)

export default component.exports

import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList,getPageAPIs } from "@/common/util";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import {CompetitionSnakeBroadcastScreenOptionAPI} from "@/common/api/competitionManage/competitionCNNineBallBroadcastScreenOption";

import { CommonApi } from "@/common/api/common";

import gallery from "@/components/gallery.vue";

// import ZlSelect from "@/components/third-custom-components/select";

// import ZlOption from "@/components/third-custom-components/option";


import { excuteDownloadExport2Excel } from "@/common/xlsx";
export const pageApiList = getPageAPIs(CompetitionSnakeBroadcastScreenOptionAPI);

import {
  // transferUserLevel2ch,
  // leagueLevelName2Text,
} from '@/common/config'


const MIN_SCORE_NUM = 0
const MAX_SCORE_NUM = 100000000000 // 一千个亿


// function getBtns(start: number, end: number) {
//   const btns: any[] = []

//   for (let i = start; i < end; i++) {
//     btns.push({
//       label: `+${i}`,
//       value: i
//     })
//   }

//   return btns
// }


const defaultForms = {
  images: [],
  user_name: '', // 昵称
  image_id: '', // 头像id
  avatar: '', // 头像链接

  // is_first: '0', // 是否开 1是 0否
}

const defaultUserInfoForm: any = {
  // yield_score: 1, // 让分
  // single_max_score: 1, // 单杆最大分
  // single_max_name: '', // 单杆最大分 名称
  // highest_gold: 1, // 最高连金
  user: {
    ...DeepLoop.deepClone(defaultForms, false)
  },
  // match_game: '', // 局数
  // match_score: '', // 比分
  score: '', // 分数
  score_name: '', // 分数文案

  // addBtns: [
  //   ...getBtns(1, 8)
  // ]
} 

const defaultForm = {
  is_show: '1', // 是否显示

  left: {
    ...DeepLoop.deepClone(defaultUserInfoForm, false)
  },
  right: {
    ...DeepLoop.deepClone(defaultUserInfoForm, false)
  },
  // match_game: '',
  // match_score: '',
  // start_time: '', // 开始时间
  // start_time_name: '', // 时间label
  // venue_id: "",
  // venue: {
  //   id: "",
  //   name: "",
  // },
}
// const defaultSearchVenuePageOption = {
//   page: 1,
//   limit: 10,
//   isRearchBottom: false,
// };

// const transfromGenders: any = [
//   '',
//   '女',
//   '男'
// ]


function getFormUser(options: any) {
  const keys: string[] = Object.keys(options)

  const left: any = {
    ...DeepLoop.deepClone(defaultUserInfoForm, false)
  }

  const right: any = {
    ...DeepLoop.deepClone(defaultUserInfoForm, false)
  }

  console.log(keys, 'keys')

  keys.forEach((key) => {
    if (key.startsWith('left_')) {
      const restKey = key.slice(5)

      if (key.endsWith('_user')) {
        console.log(options[key], 'options[key]')
        Object.assign(left.user, {
          ...options[key]
        })

        console.log(left, 'left')
      } else {
        left[restKey] = options[key]
      }


    }

    if (key.startsWith('right_')) {
      const restKey = key.slice(6)

      if (key.endsWith('_user')) {
        console.log(options[key], 'options[key]')
        Object.assign(right.user, {
          ...options[key]
        })

        console.log(right, 'right')
      } else {
        right[restKey] = options[key]
      }
    }
  })

  return {
    left,
    right
  }
}

// 状态：0等待导入 1成功导入 2导入失败
// const importFileStatus2text: string[] = [
//   '等待导入',
//   '成功导入',
//   '导入失败'
// ]

@Component({
  filters: {
    // showImportFileStatus(status: number) {
    //   return importFileStatus2text[status] || '未知'
    // },

    // showLeaguePointTypeName(league_level: number) {
    //   return leagueLevelName2Text[league_level] || '未知'
    // }
    // showUserMemberTypeName: function(type: number) {
    //   return transferUserLevel2ch[type] || ''
    // }
  },
  components: {
    // ZlSelect,
    // ZlOption,
    gallery,
  },
})
export default class SingleCardTransferRecord extends Vue {
  private competitionSnakeBroadcastScreenOptionApi: any;

  private deepLoopUtil: any;

  private MIN_SCORE_NUM: number = MIN_SCORE_NUM

  private MAX_SCORE_NUM: number = MAX_SCORE_NUM

  private utilIns: any;


  // private debounceRefreshSingleCardInfoFunc: any

  private commonApi: any;
  constructor() {
    super();
    this.competitionSnakeBroadcastScreenOptionApi = new CompetitionSnakeBroadcastScreenOptionAPI();

    this.deepLoopUtil = new DeepLoop();

    this.commonApi = new CommonApi();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    }

    this.utilIns = new Util()

    // this.debounceRefreshSingleCardInfoFunc = this.utilIns.debounce.call(
    //   this,
    //   this.refreshSingleCardAccountInfo,
    //   800
    // )
  }

  // private handleClickChangePoint(btnItem: any, mode: string) {
  //   const form = this.form 
  //   // changeMode: add | sub | clear
  //   function changePointKeys(target: any, mode: string, changeMode: string, keys: string[], point: number = 0) {
  //     keys.forEach((key) => {
  //       const cur_point = target[mode][key]

  //       if (!isNaN(cur_point)) {
  //         target[mode][key] = Number(cur_point) 

  //         switch (changeMode) {
  //           case 'add':
  //             target[mode][key] += point
  //             break;
  //           case 'sub':
  //             target[mode][key] -= point
  //             break;
  //           case 'clear':
  //             target[mode][key] = 0
  //             break;
  //         }
  //       }
  //     })
  //   }

  //   switch (mode) {
  //     case 'left':
  //       changePointKeys(form, mode, 'add', ['single_max_score', 'score'], btnItem.value)

  //       changePointKeys(form, 'right', 'clear', ['single_max_score'])
  //       break;
  //     case 'right':
  //       changePointKeys(form, mode, 'add', ['single_max_score', 'score'], btnItem.value)

  //       changePointKeys(form, 'left', 'clear', ['single_max_score'])
  //       break;
  //   }

  //   this.form = form
  // }

  private form: any;

  private forms: any;

  private firstLoading: boolean = true;
  // private chooseTechnicianItem : any = null;

  // private tablePage: any = {
  //   totalCount: 0,
  //   pageNo: 1,
  //   pageSize: 10,
  //   pageSizes: [10, 20, 50, 100, 200, 500],
  // };


  private activated(): void {
    this.getTable();
  }


  private getTable(): void {
    this.competitionSnakeBroadcastScreenOptionApi.getCompetitionCNNineBallOption(
      {},
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        const {
          is_show,
          // match_game = '',
          // match_score = '',
          // start_time = '',
          // start_time_name = '',
          ...others
        } = data || {};

        const form: any = {
          is_show,
          // match_game,
          // match_score,
          // start_time,
          // start_time_name,
          ...getFormUser(others)
        }

        console.log(form, 'form')
      
        this.form = form

        this.firstLoading = false;
      },
      () => {
        this.firstLoading = false;
      },
      false
    );
  }


  private dialogFormVisible: boolean = false

  private dialogFormMode: string = 'left'
  private handleWakeUserInfoDIalog(mode: string = 'left') {
    const form = this.form || {}

    this.dialogFormMode = mode

    const {
      user_name = '', // 昵称
      image_id = '', // 头像id
      avatar = '', // 头像链接
      // is_first
    } = form[mode].user || {}

    const forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
      user_name,
      image_id,
      avatar,
      // is_first
    }

    if (avatar) {
      const images: any = [
        {
          avatar,
          // fileId: image_id
        }
      ]

      forms.images = images
    }

    this.forms = forms

    console.log(this.forms, 'this.forms')

    this.$nextTick(() => {
      this.dialogFormVisible = true
    })
  }

  private setUserInfoLoading: boolean = false

  private handleSetUserAvatarInfo() {
    const mode = this.dialogFormMode

    const forms = this.forms

    const mode2Filed: any = {
      'left': 1,
      'right': 2
    }

    const {
      images = [],
      user_name,
      // image_id,
      // avatar: originAvatar,
      // is_first
    } = forms || {}

    console.log(forms, 'forms')

    if (!user_name) {
      this.$message.error('昵称不可为空')
      return false
    }

    if (images.length == 0) {
      this.$message.error('头像不可为空')
      return false
    }

    const {
      // fileId: image_id,
      avatar
    } = images[0] || {}

    const form = this.form || {}

    const userAvatarInfo = form[mode].user || {}

    const params: any = {
      user_name,
      // image_id,
      avatar,
      // is_first
    }

    Object.assign(userAvatarInfo, {
      ...params
    })

    params.user_type = mode2Filed[mode]

    this.setUserInfoLoading = true

    this.competitionSnakeBroadcastScreenOptionApi.setCompetitionCNNineBallUserInfoOption(params, () => {
      this.$message.success('用户信息修改成功')
      this.setUserInfoLoading = false
      this.dialogFormVisible = false
      
    }, () => {
      this.setUserInfoLoading = false

    })

   

  }

  // 上传媒体文件
  private handleUploadUserPhoto(file: any, fileList: any[]) {
    this.handleUploadUserMedia(file, fileList, "image");
  }

  private async handleUploadUserMedia(
    file: any,
    fileList: any[],
    mode: string
  ) {
    console.log(file, "file");

    function getVideoDuration(url: string): any {
      return new Promise((resolve, reject) => {
        var audio = new Audio(url);
        audio.muted = true;
        audio.play().then(() => audio.pause());
        // 元数据已加载

        const handleLoadedmetadata = function (e: any) {
          // let duration = audio.duration;
          console.log(e, "e audio");
          resolve({
            url,
            duration: audio.duration,
          });

          hanleClearListeners();
        };

        const hanleClearListeners = function () {
          audio.removeEventListener("loadedmetadata", handleLoadedmetadata);
          audio.onerror = null;
        };
        audio.addEventListener("loadedmetadata", handleLoadedmetadata);

        audio.onerror = function (err) {
          reject(err);
        };

        audio.muted = false;
      });
    }

    const editForm: any = this.forms;

    const optionList = mode == "image" ? editForm.images : editForm.videos;

    const sendMediaApi =
      mode == "image" ? this.commonApi.getOss : this.commonApi.getOssvideo;

    if (mode == "video" && !file.duration) {
      const { duration } = await getVideoDuration(file.url).catch(
        (err: any) => {
          this.$message("获取时长失败");
        }
      );

      file.duration = duration;
    }

    optionList.push(file);

    console.log(editForm, "editForm");

    this.$set(this.forms, `${mode}s`, optionList);

    this.changeFilePropByList(file, mode, { uploading: true });
    sendMediaApi.call(
      this.commonApi,
      file instanceof File ? file : file.raw,
      (res: any) => {
        console.log(res, "res 图片上传成功");

        const { data } = res;

        const { data: uploadFileObj } = data || {};

        const { item: uploadFile } = uploadFileObj || {};

        const { id, url } = uploadFile;

        // this.$set(this.forms, 'avatar_id', id)
        // this.$set(this.forms, 'avatar', url)
        const changeProps: any = {
          uploading: false,
          fileId: id,
          avatar: url,
        };

        if (file.duration) {
          changeProps.duration = file.duration.toString();
        }

        this.changeFilePropByList(file, mode, changeProps);
      },
      () => {
        this.$message.error("图片上传失败");

        this.changeFilePropByList(file, mode, {
          uploading: false,
          failed: true,
        });
      }
    );
  }

  private changeFilePropByList(file: any, mode: string, changes: any = {}) {
    const editForm: any = this.forms;

    const setList: any[] = mode == "image" ? editForm.images : editForm.videos;
    const existFileIndex = setList.findIndex(
      (each_file: any) => each_file.uid == file.uid
    );

    if (existFileIndex > -1) {
      const originFile = setList[existFileIndex];

      if (Array.isArray(setList)) {
        setList.splice(existFileIndex, 1, Object.assign(originFile, changes));
      }

      this.$set(this.forms, `${mode}s`, setList);
    }
  }

  // 删除媒体文件
  private handleFileRemove(file: any, mode: string) {
    const forms = this.forms;

    const images = mode == "image" ? forms.images : [];

    const videos = mode == "video" ? forms.videos : [];

    console.log(file, "file");

    const optionList = images.length > 0 ? images : videos;

    const existRemoveFileIndex = optionList.findIndex(
      (each_file: any) => each_file.uid == file.uid
    );

    if (existRemoveFileIndex > -1) {
      optionList.splice(existRemoveFileIndex, 1);

      this.$set(this.forms, `${mode}s`, optionList);
    }
  }

  // 预览媒体文件
  private previewImages: any[] = [];
  private handlePictureCardPreview(file: any, mode: string) {
    const forms = this.forms;

    const images = mode == "image" ? forms.images : [];

    const videos = mode == "video" ? forms.videos : [];

    console.log(file, "file");

    const urls = [
      ...images.map((img: any) => {
        return {
          title: "",
          type: "image/jpeg",
          href: img.url,
          thumbnail: img,
        };
      }),
      ...videos.map((video: any) => {
        return {
          title: "",
          type: "video/mp4",
          href: video.url,
          poster: `${video.url}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`,
        };
      }),
    ];

    this.previewImages = urls;
  }

  // private handleWakePreviewFileDialog() {
  //   const {
  //     // venue,
  //     importFiles,
     
  //   } = this.forms || {}

  //   console.log(this.forms, 'this.forms')
  //   console.log(importFiles, 'importFiles')

  //   const [file] = importFiles || []

  //   if (!(file && file.fileId)) {
  //     this.$message.error('请上传文件')
  //     return 
  //   }

  //   this.wakePreviewFileLoading = true

  //   this.competitionSnakeBroadcastScreenOptionApi.getPreviewFileTable({
  //     file_id: file.fileId
  //   }, (res: any) => {

  //     const {
  //       header,
  //       list
  //     } = res.data || {}

  //     // this.previewTableCols = header

  //     // this.previewTableData = list

  //     this.dialogFormVisible = false

  //     this.wakePreviewFileLoading = false
  //   }, () => {
  //     this.wakePreviewFileLoading = false
  //   })
  // }

  // // 拉起预览文件弹框
  // private dialogPreviewFileVisible: boolean = false

  // private previewTableCols: any[] = []

  // private previewTableData: any[] = [];


  // end 拉起预览文件弹框


  private sumbitCompetitionBoardcastScreenLoading: boolean = false
  // // 提交修改
  private handleSumbitCompetitionBoardcastScreen () {
    const form = this.form || {}

    const {
      // left,
      // right,
      is_show,
      // start_time,
      // start_time_name,
      // match_game,
      // match_score
    } = form

    const params: any = {
      is_show,
      // start_time,
      // start_time_name,
      // match_game,
      // match_score
    }

    const keys: string[] = ['score', 'score_name']

    const userKeys: string[] = ['left', 'right']

    userKeys.forEach((userPrefixKey: string) => {
      const userInfo: any = form[userPrefixKey]

      keys.forEach((key) => {
        const inputVal = userInfo[key]

        // if (inputVal) {
          
        // }

        params[`${userPrefixKey}_${key}`] = inputVal
      })
    })

    console.log(params, 'params')


    this.sumbitCompetitionBoardcastScreenLoading = true

    this.competitionSnakeBroadcastScreenOptionApi.setCompetitionCNNineBallOption({
      ...params
    }, () => {
      this.$message.success('保存成功')

      this.sumbitCompetitionBoardcastScreenLoading = false
      // this.dialogPreviewFileVisible = false

      this.getTable()
    }, (err: any) => {
      console.log(err, 'err')
      this.sumbitCompetitionBoardcastScreenLoading = false
    })
  }

  private curSingleCardInfo: any = {}

  private singleCardInfoLoading: boolean = false


  // 后台返回的字段 转化为前端定义的字段
  private handleTableData(data: any): any {
    // const { gender } = data;
    const newData = {
      ...data,
      // device_name: orderDeviceType2ch[device],
      // pay_type_name: payType2ch[pay_type],
      // user_level_type_name: userLevel2ch[user_type],
      // gender_sex: transfromGenders[gender]
    };

    return newData;
  }

  /** 远程搜索店铺 */
  // private venueList: any[] = [
  //   // {
  //   //   id: 1,
  //   //   label: '测试'
  //   // },
  //   // {
  //   //   id: 2,
  //   //   label: '测试2'
  //   // }
  // ];

  // private searchVenueLoading: boolean = false;

  // private remoteGetVenueQueryString: string = "";

  // private remoteSearchVenueTimer: any = null;

  // private searchVenuePageOption: any = {};

  // private initRemoteGetVenuePageOption() {
  //   this.searchVenuePageOption = {
  //     ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
  //   };
  // }

  // private getSearchVenueParams() {
  //   const searchVenuePageOption = this.searchVenuePageOption;

  //   const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

  //   const { page, limit } = searchVenuePageOption;

  //   const params: any = {
  //     page,
  //     limit,
  //   };

  //   if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
  //     params.user_name = remoteGetVenueQueryString.trim();
  //   }

  //   return params;
  // }

  // private getMoreVenueList(refresh: boolean = true) {
  //   return new Promise((resolve: Function, reject: Function) => {
  //     if (refresh) {
  //       this.initRemoteGetVenuePageOption();
  //     }

  //     const params = this.getSearchVenueParams();

  //     this.competitionSnakeBroadcastScreenOptionApi.searchVenueList(
  //       params,
  //       (res: any) => {
  //         console.log(res, "res");

  //         const venueList: any[] = res.data || [];

  //         this.venueList = refresh
  //           ? venueList
  //           : this.venueList.concat(venueList);

  //         if (Array.isArray(res.data) && res.data.length == 0) {
  //           this.searchVenuePageOption.isRearchBottom = true;
  //         }
  //         this.searchVenueLoading = false;

  //         this.addVenueListLoading = false;

  //         resolve();
  //       },
  //       () => {
  //         this.searchVenueLoading = false;

  //         this.addVenueListLoading = false;

  //         reject();
  //       }
  //     );
  //   });
  // }

  // // 第一次搜索 无关键字
  // private handleFirstGetVenueList() {
  //   this.searchVenueLoading = true;

  //   this.getMoreVenueList(true);
  // }

  // // 清空搜索
  // private handleClearGetVenueList() {
  //   this.remoteGetVenueQueryString = "";

  //   this.searchVenueLoading = true;

  //   this.getMoreVenueList(true);
  // }

  // // 远程搜索
  // private remoteGetVenueMethod(query: string) {
  //   query = query.trim ? query.trim() : "";

  //   if (query !== "") {
  //     this.remoteGetVenueQueryString = query;
  //     clearTimeout(this.remoteSearchVenueTimer);

  //     this.remoteSearchVenueTimer = setTimeout(() => {
  //       this.searchVenueLoading = true;

  //       this.getMoreVenueList();
  //       // searchStu((res) => {
  //       //   const {
  //       //     students,
  //       //     total_count
  //       //   } = res || {}

  //       //   this.remoteAllStudents = [
  //       //     ...(students || [])
  //       //   ]

  //       //   this.remoteStudentTotalCount = total_count || 0

  //       //   this.remoteGetStudentLoading = false
  //       // }, {
  //       //   kw: query,
  //       //   ...this.getRemoteGetStudentPageOptionParams()
  //       // }, () => {
  //       //   this.remoteGetStudentLoading = false
  //       // })
  //     }, 300);
  //     // setTimeout(() => {
  //     //   this.remoteGetStudentLoading = false;
  //     //   this.options = this.list
  //     // }, 200);
  //   } else {
  //     this.remoteGetVenueQueryString = "";
  //     this.venueList = [];
  //   }
  // }

  // private addVenueListLoading: boolean = false;

  // private remoteAddVenueMethod() {
  //   if (this.addVenueListLoading) return;
  //   const searchVenuePageOption = this.searchVenuePageOption;

  //   const { isRearchBottom } = searchVenuePageOption;

  //   if (isRearchBottom) return;

  //   this.searchVenuePageOption.page++;

  //   this.addVenueListLoading = true;

  //   this.getMoreVenueList(false);
  // }
  /** end 远程搜索店铺 */
  

  /** 导出excel */
  // private downloadExcelLoading: boolean = false;

  // // 订单字段名 文案对应关系
  // private orderExcelList2ch: any = {
  //   "phone": "手机号",
  //   "user_name": "用户昵称",
  //   "shop_name": "店铺",
  //   "decr_price": "扣款金额",
  //   "remark": "扣款原因",
  //   "admin_name": "操作人",
  //   "created_at": "操作时间"
  // };
  // // 订单Excel 表头的先后顺序
  // private orderExcelSortList: string[] = [
  //   "phone",
  //   "user_name",
  //   "shop_name",
  //   "decr_price",
  //   "remark",
  //   "admin_name",
  //   "created_at"
  // ];

  // // 点击导出excel 表格
  // private handleDownloadExcel() {
  //   const params = this.getCompetitionPointExcelRecordListParams("exportExcel");

  //   if (params === null) {
  //     return false;
  //   }

  //   this.downloadExcelLoading = true;
  //   this.competitionSnakeBroadcastScreenOptionApi.exportExcelSingleCardAccountCheckList(
  //     params,
  //     (res: any) => {
  //       const {
  //         start_date,
  //         end_date,
  //         // over_start_time,
  //         // over_start_time,
  //       } = params || {};

  //       const create_time_duration_name =
  //         start_date && end_date
  //           ? `时间${start_date}到${end_date}`
  //           : "";

  //       // const finish_time_duration_name =
  //       //   over_start_time && over_start_time
  //       //     ? `完成时间${over_start_time}到${over_start_time}`
  //       //     : "";
  //       const filename = `单店会员余额扣款记录列表 ${create_time_duration_name}.xlsx`;
  //       const { data } = res || {};

  //       const { list: originList } = data || {};

  //       const newList = Array.isArray(originList)
  //         ? originList.map((item) => {
  //             const {
  //               // type,
           
  //             } = item || {};

  //             const newItem = {
  //               ...(item || {}),
              
  //               // memberTypeName: transferUserLevel2ch[type]
  //             };

  //             return newItem;
  //           })
  //         : [];

  //       if (newList.length > 0) {
  //         const orderExcelSortList = this.orderExcelSortList;

  //         const orderExcelList2ch = this.orderExcelList2ch;

  //         const excelJSONData = getExcelJSONDataByList(
  //           newList,
  //           orderExcelSortList,
  //           orderExcelList2ch
  //         );


  //         excuteDownloadExport2Excel(excelJSONData, filename);
  //       } else {
  //         this.$message.info("选中时段没有可导出的信息");
  //       }

  //       this.downloadExcelLoading = false;
  //     },
  //     () => {
  //       this.downloadExcelLoading = false;
  //     }
  //   );
  // }

  /** end 导出excel */

  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };

    this.getTable();
  }

  private search(): void {
    // this.tablePage.pageNo = 1;
    // this.tableData = [];
    // this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private handlePageChange(val: any) {
    // this.tablePage.pageNo = val.currentPage;
    // this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}

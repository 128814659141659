import {
  WSS_DOMAIN
} from '@/common/env'

import {
  spawnPandaNotification,
} from './system-notification'

// import Sockjs from 'sockjs-client'

let token = ''

// const token = localStorage.getItem('token')

const defaultOptions = {
  url: `/ws/im/admin`,
  protocol: 'wss', // wss 或者 https
  onOpen: null,
  onMessage: null,
  onClose: null,

  useShareWork: false,
  // portList: []
}

var sockjs = null

// 系统通知处理过滤、判断逻辑
export function handleFilterPandaNotification(receivedData, useShareWork = false, errCallBack) {
  const chatData = receivedData ? JSON.parse(receivedData) : {};

  let isDisabledNotify = !!(sockjs.__disabledNotify)

  if (isDisabledNotify) return

  // 消息类型 -1消息发送反馈 1 普通文字消息 2 图片消息 3 视频消息 4 消息模板 5 问题库消息 6 接入消息 7 退出消息 8 选择机器人选项回复
  switch (chatData.type) {
    case 6:
      return spawnPandaNotification('新商户问询', '您有一条新消息，请及时回复', useShareWork, errCallBack);
  }
}

/* 目前取值在 4000~4999 之间 */
// 客户端主动断开
export const NORMAL_CLOSE_WS_CODE = 4001


// 重连websocket 之前断开链接
export const CLOSE_WS_BEFORE_RECONNECT_CODE = 4002

// export const defaultCloseCode = {
//   code: 1000,
//   reason: '正常关闭'
// }

// let reconnectTimer = null
const reconnectInterval = 3 // 单位秒

// function stopReconnect() {
//   clearTimeout(reconnectTimer)
// }


// 心跳检测
let pingTimer = null, waitPongTimer = null


let heartBeatStatus = 'waiting' // waiting | receviced | timeout
const pingInterval = 10 // 发送心跳包时间间隔 单位秒

const pongTimeoutLimit = 3
let pongTimeoutTime = 0
const pongTimeout = 2 // 判断链接是否正常 单位 秒

const wsCloseStateCodes = [
  WebSocket.CLOSING,
  WebSocket.CLOSED
]

function startPing() {
  pingTimer = setTimeout(() => {
    sockjs.send("ping")
    console.log(heartBeatStatus, 'heartBeatStatus')

    console.log(sockjs.readyState, 'sockjs.readyState')
    console.log(wsCloseStateCodes, 'wsCloseStateCodes')

    waitPong()
  }, pingInterval * 1000)
}



function waitPong() {
  waitPongTimer = setTimeout(() => {
    switch (heartBeatStatus) {
      case 'waiting':
        if (wsCloseStateCodes.includes(sockjs.readyState)) {
          heartBeatStatus = 'timeout'
        } else {
          if (pongTimeoutTime < pongTimeoutLimit) {
            ++pongTimeoutTime

            sockjs.send("ping")
          } else {
            heartBeatStatus = 'timeout'
          }
        }

        waitPong()
        break;
      case 'receviced':
        pongTimeoutTime = 0

        break;
      case 'timeout':
        // heartBeatStatus = 'waiting'

        pongTimeoutTime = 0

        reconnectWebSocket()
        break;
    }

  }, pongTimeout * 1000)
}

function stopWaitPong() {
  clearTimeout(waitPongTimer)
}

function stopPing() {
  clearTimeout(pingTimer)
}


function reconnectWebSocket(isNeedClose = true) {
  const options = sockjs._options

  options._isReconnect = true


  if (isNeedClose) {
    sockjs.close(CLOSE_WS_BEFORE_RECONNECT_CODE, '重连之前断开')

  }


  const {
    useShareWork,
    onReconnect
  } = options || {}


  sockjs = null

  if (useShareWork) {
    options.portList.forEach(port => {
      port.postMessage({
        type: 'reconnect',
      });
    })
  } else {
    if (typeof onReconnect == 'function') {
      onReconnect({
        sockIns: createPandasocket(options)
      })
    }
  }

  // createPandasocket(options)
}


export default function createPandasocket(options) {
  if (sockjs) return sockjs
  const commonOptions = Object.assign({}, defaultOptions)

  if (typeof options == 'string') {
    Object.assign(commonOptions, {
      url: options
    })
  } else if (typeof options == 'object'){
    Object.assign(commonOptions, options)
  }
  
  const {
    url,
    protocol,
    onOpen,
    onMessage,
    onClose,
    token,

    useShareWork,
    // portList = []
  } = commonOptions


  sockjs = sockjs || new WebSocket(protocol + WSS_DOMAIN + url + '?token=' + token)

  sockjs._options = options

  sockjs.onopen = function(evt) {
    console.log('open evt', evt)

    const isReconnect = options._isReconnect

    heartBeatStatus = 'waiting'
    startPing()
    if (useShareWork) {
      options.portList.forEach(port => {
        port.postMessage({
          type: 'open',
          data: {
            _isReconnect: isReconnect
          }
        });
      })
    } else {
      if (typeof onOpen == 'function') {
        onOpen({
          isReconnect,
          ...evt
        })
      }
    }

    options._isReconnect = false
  }

  sockjs.onmessage = function(evt) {
    console.log('message evt', evt)
    if (evt.data == 'pong') {
      heartBeatStatus = 'receviced'

      stopWaitPong()

      startPing()

      // 测试重连
      // heartBeatStatus = 'waiting'

      
    } else {
      handleFilterPandaNotification(evt.data, useShareWork, (errMsg) => {
        options.portList.forEach(port => {
          port.postMessage({
            type: 'err-tip',
            data: errMsg
          });
        })
      })

      if (useShareWork) {
        options.portList.forEach(port => {
          port.postMessage({
            type: 'message',
            data: evt.data
          });
        })
      } else {
        if (typeof onMessage == 'function') {
          onMessage(evt)
        }
      }
    }

    // if (useShareWork) {
    //   options.portList.forEach(port => {
    //     port.postMessage(evt.data);
    //   })
    // } else {
    //   if (typeof onMessage == 'function') {
    //     onMessage(evt)
    //   }
    // }
  }

  sockjs.onclose = function(evt) {
    console.log("close evt", evt)

    stopPing()
    if (useShareWork) {
      options.portList.forEach(port => {
        port.postMessage({
          type: 'close',
          data: {
            code: evt.code
          }
        });
      })
    } else {
      if (typeof onClose == 'function') {
        onClose(evt)
      }
    }

    // websocket code  https://www.webfunny.cn/blog/post/14
    /**
     * 0–999	 	保留段, 未使用.
      1000	CLOSE_NORMAL	正常关闭; 无论为何目的而创建, 该链接都已成功完成任务.
      1001	CLOSE_GOING_AWAY	终端离开, 可能因为服务端错误, 也可能因为浏览器正从打开连接的页面跳转离开.
      1002	CLOSE_PROTOCOL_ERROR	由于协议错误而中断连接.
      1003	CLOSE_UNSUPPORTED	由于接收到不允许的数据类型而断开连接 (如仅接收文本数据的终端接收到了二进制数据).
      1004	 	保留. 其意义可能会在未来定义.
      1005	CLOSE_NO_STATUS	保留. 表示没有收到预期的状态码.
      1006	CLOSE_ABNORMAL	保留. 用于期望收到状态码时连接非正常关闭 (也就是说, 没有发送关闭帧).
      1007	Unsupported Data	由于收到了格式不符的数据而断开连接 (如文本消息中包含了非 UTF-8 数据).
      1008	Policy Violation	由于收到不符合约定的数据而断开连接. 这是一个通用状态码, 用于不适合使用 1003 和 1009 状态码的场景.
      1009	CLOSE_TOO_LARGE	由于收到过大的数据帧而断开连接.
      1010	Missing Extension	客户端期望服务器商定一个或多个拓展, 但服务器没有处理, 因此客户端断开连接.
      1011	Internal Error	客户端由于遇到没有预料的情况阻止其完成请求, 因此服务端断开连接.
      1012	Service Restart	服务器由于重启而断开连接.
      1013	Try Again Later	服务器由于临时原因断开连接, 如服务器过载因此断开一部分客户端连接.
      1014	 	由 WebSocket标准保留以便未来使用.
      1015	TLS Handshake	保留. 表示连接由于无法完成 TLS 握手而关闭 (例如无法验证服务器证书).
      1016–1999	 	由 WebSocket标准保留以便未来使用.
      2000–2999	 	由 WebSocket拓展保留使用.
      3000–3999	 	可以由库或框架使用.? 不应由应用使用. 可以在 IANA 注册, 先到先得.
      4000–4999	 	可以由应用使用.
     */
    if (evt.code < 4000 && evt.code > 1000) {
      setTimeout(() => {
        reconnectWebSocket(false)
      }, reconnectInterval * 1000)
    }
  }


  return sockjs
}


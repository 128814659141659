
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getPageAPIs } from "@/common/util";
import { CommonApi } from "@/common/api/common";

import { SystemUserManageAPI } from "@/common/api/systemManage/systemUserMember";

// import { util } from "vue/types/umd";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
// import ZlSelect from "@/components/third-custom-components/select";

// import ZlOption from "@/components/third-custom-components/option";

import {
  shareConfirmOptions,
  // shareShopType2ch
} from '@/common/config'

// 暴露的API 列表
export const pageApiList = getPageAPIs(SystemUserManageAPI);

console.log(pageApiList, 'pageApiList')

const defaultForm = {
  phone: '', // 手机号
}

const defaultForms = {
  avatar_path: "", // 头像
  avatar_id: "", //头像id

  service_name: "", // 客服昵称

  name: '',
  phone: '',
  department: {
    name: '',
    id: ''
  },
  is_online: 0, // 是否在线 0否 1是
  // pool_table_category_ids: [],
  // systemUsers_time_id: "",
 
  // singleMaxCountType: '0',
  // shop_id: "",
  // maxLimitCount: ''
};


const systemUserDialogTitle2ch: any = {
  "new-system-user": "新增成员",
  "edit-system-user": "编辑成员",
};

// 最大有效期
const maxExpiredDays = 9999;

// 最大时长 单位分钟
const maxTimeDuration = 24 * 60;

// systemUser 卡券分属类别
// const systemUserShopType2ch: any = [
//   ...shareShopType2ch
// ]

// 是否在线 0否 1是
const online2ch: string[] = ['离线', '在线']

@Component({
  filters: {
    // showSystemUserShopTypeName(shop_type: number) {
    //   return systemUserShopType2ch[shop_type] || ''
    // }

    showOnlineStatus(is_online: number) {
      return online2ch[is_online]
    }
  },
  components: {
    // ZlSelect,
    // ZlOption,
  },
})
export default class SystemUserManage extends Vue {
  private form: any = {
   phone: '' // 手机号
  };
  private forms: any = {
    // maxType: "0",
    // ...defaultForms
  };

  private deepLoopUtil: any = {};

  // private merchantApi: any;

  private shopTypeList: any[] = [
    {
      label: '台球',
      value: 1
    },
    {
      label: '棋牌',
      value: 2
    }
  ]

  private systemUsersManageApi = new SystemUserManageAPI();
  // private systemUserTimeDurationAPI = new SystemUserTimeDurationManageAPI();

  private commonApi: any;
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.commonApi = new CommonApi();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };


    // this.merchantApi = new MerchantApi();
  }

  private created(): void {
    this.getSystemUsers();

    // this.refreshSystemUserTimeDurations();

    this.refreshDepartmentList();
  }
  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
  
    this.getSystemUsers();
  }

  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getSystemUsers();
  }

  private tableData: any[] = [];

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";
  // private options: any = [];

  // private clearObject(): void {
  //   this.dialogFormVisible = false;
  //   this.forms = new Util().clearObject(this.forms);
  // }
  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getSystemUsers();
  }

  private allDepartmentList: any[] = [];

  private refreshDepartmentList() {
    return new Promise((resolve, reject) => {
      this.systemUsersManageApi.getDepartmentList(
        {
          page: 0,
          limit: 9999
        },
        (res: any) => {
          const { list: allDepartmentList } = (res ? res.data : {}) as any;
          // this.tableData = res.data.list;
          // this.tablePage.totalCount = res.data.count;

          this.allDepartmentList = allDepartmentList;

          resolve(allDepartmentList);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  private addOrUpdateSystemUserTitle: string = "用户成员";

  private dialogFormVisible: boolean = false;

  // 拉起成员弹框
  private handleWakeSystemUserDialog(mode: string) {
    // if (mode == 'new-system-user') { // 新增卡券

    // }
    this.systemUserDialogMode = mode;
    this.addOrUpdateSystemUserTitle = systemUserDialogTitle2ch[mode] || "用户成员";

    this.initSystemUserForm(mode);

    this.dialogFormVisible = true;
  }

  // 上传头像
  private userAvatarUploading: boolean = false;
  private handleUploadUserAvatar(file: any, fileList: any[]) {
    console.log(file, fileList, "file", "fileList");

    this.userAvatarUploading = true;
    this.commonApi.getOss(
      file instanceof File ? file : file.raw,
      (res: any) => {
        console.log(res, "res 图片上传成功");

        const { data } = res;

        const { data: callbackData } = data;

        const { item: uploadImgObj } = callbackData || {};

        const { id, url } = uploadImgObj;

        this.$set(this.forms, "avatar_id", id);
        this.$set(this.forms, "avatar_path", url);

        this.userAvatarUploading = false;
      },
      () => {
        this.$message.error("图片上传失败");

        this.userAvatarUploading = false;
      }
    );
  }

  private currentSystemUserItem: any = null;

  private systemUserDialogMode: string = "";

  private async initSystemUserForm(mode: string) {
    const forms: any = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    if (mode == "edit-system-user") {
      // 编辑
      // await this.getMoreVenueList(true);

      // await this.refreshSystemUserTimeDurations();

      await this.refreshDepartmentList();

      const currentSystemUserItem = this.currentSystemUserItem;

      const {
        id,
        name,
        service_name,
        phone,
        department,
        department_id,
        is_online,
        avatar
      } = currentSystemUserItem || {};

      if (name) {
        forms.name = name;
      }

      if (phone) {
        forms.phone = phone.toString();
      }

      if (department && department_id) {
        forms.department = {
          name: department_id,
          id: department_id
        }
      }

      if (service_name) {
        forms.service_name = service_name
      }

      if (avatar) {
        forms.avatar_path = avatar
      }

      forms.is_online = typeof is_online == 'number' ? is_online : 0

      forms.id = id;
    }

    console.log(forms, "forms");


    this.forms = forms;
  }

  private handleWakeEditSystemUserDialog(systemUserItem: any) {
    this.currentSystemUserItem = systemUserItem;

    this.handleWakeSystemUserDialog("edit-system-user");
  }

  private handleDeleteSystemUser(systemUserItem: any) {
    this.$confirm("确认删除该成员？", '', {
      ...(shareConfirmOptions || {})
    }).then(() => {
      this.systemUsersManageApi.deleteSystemUserItem({
        id: systemUserItem.id
      }, () => {
        this.getSystemUsers();
      }, () => {

      })
    })
  }


  // 手机号
  private handleLimitPhoneInput(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        this.$set(this.forms, "phone", new_val);
      },
      (new_val: string) => {
        this.$set(this.forms, "phone", new_val);
      }
    );
  }


  private sumbitSystemUserLoading: boolean = false;

  // 提交卡券配置
  private handleSumbitSystemUser() {
    const systemUserDialogMode = this.systemUserDialogMode;

    const params = this.getSystemUserFormsParams(systemUserDialogMode);

    console.log(params, "params");
    if (params === false) return;

    this.sumbitSystemUserLoading = true;
    switch (systemUserDialogMode) {
      case "new-system-user":
        this.systemUsersManageApi.addSystemUsers(
          params,
          () => {
            this.getSystemUsers();
            this.dialogFormVisible = false;
            this.sumbitSystemUserLoading = false;
          },
          () => {
            this.sumbitSystemUserLoading = false;
          }
        );
        break;
      case "edit-system-user":
        this.systemUsersManageApi.updateSystemUsers(
          params,
          () => {
            this.getSystemUsers();
            this.dialogFormVisible = false;
            this.sumbitSystemUserLoading = false;
          },
          () => {
            this.sumbitSystemUserLoading = false;
          }
        );
        break;
    }
  }

  private getSystemUserFormsParams(mode: string) {
    const params: any = {};

    const showErrorMessage = (msg: string) => {
      if (!!msg) {
        this.$message.error(msg);
      }
    };

    const forms = this.forms;

    const {
      id,

      avatar_path,
      department,
      is_online
    } = forms || {};

    Object.assign(params, {
      is_online
    })

    const keys = ["name", "service_name", "phone"];

    const key2EndKey: any = {
      name: "name",
      service_name: "service_name",
      phone: "phone",
      // avatar_path: "avatar"
    };

    const key2ErrMsg: any = {
      name: "姓名不能为空",
      service_name: "客服昵称不能为空",
      phone: "手机号不能为空",
      // avatar_path: "头像不能为空"
    };

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (forms[key]) {
        const errMsg = key2ErrMsg[`${key}_${forms[key]}`];
        if (errMsg) {
          showErrorMessage(errMsg);

          return false;
        }
        params[key2EndKey[key] || key] = forms[key];
      } else {
        showErrorMessage(key2ErrMsg[key]);

        return false;
      }
    }


    if (avatar_path) {
      params.avatar_path = avatar_path
    }


    // if (
    //   Array.isArray(pool_table_category_ids) &&
    //   pool_table_category_ids.length
    // ) {
    //   params.pool_table_category_ids = pool_table_category_ids.join(",");
    // }

    if (department && department.id) {
      params.department_id = department.id;
    } else {
      showErrorMessage("请选择一个部门");
      return false;
    }

    if (mode == "edit-system-user") {
      params.id = id;
    }

    return params;

  }

  //获取优惠券列表
  private getSystemUsers(): void {
    // this.form.startDate = Util.handleStartTimeAndEndTime(
    //   this.form.time,
    //   false
    // ).startTime;
    // this.form.endDate = Util.handleStartTimeAndEndTime(
    //   this.form.time,
    //   false
    // ).endTime;
    this.systemUsersManageApi.getSystemUsers(
      { ...this.getSystemUserParams() },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page;
        this.tablePage.prepage = res.data.prepage;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private getSystemUserParams(): any {
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    const { phone } = form;

    if (phone) {
      params.phone = phone;
    }

    if (page) {
      params.page = page;
    }

    if (prepage) {
      params.limit = prepage;
    }

    return params;
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };

}

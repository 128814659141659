import dayjs from "@/plugins/dayjs";

function createTs() {
  return {
    // 今天 00:00:00 时间戳
    toTs: dayjs().startOf('day').unix(),
    // 昨天 00:00:00 时间戳
    yesTs: dayjs().subtract(1, 'day').startOf('day').unix(),
    // 前天 00:00:00 时间戳
    befYesTs: dayjs().subtract(2, 'day').startOf('day').unix(),
    // 今年第一天 00:00:00 时间戳
    nowYearTs: dayjs().startOf('year').unix()
  }
}



export function showChatTimeFormat(ts: number) {
  const tsMarks = createTs()

  const {
    toTs,
    yesTs,
    befYesTs,
    nowYearTs
  } = tsMarks || {}

  const curMillTs = ts * 1000


  if (ts >= toTs) {
    // 今天
    return dayjs(curMillTs).format('AHH:mm')
  } else if (ts >= yesTs) {
    // 昨天
    return `昨天 ${dayjs(curMillTs).format('AHH:mm')}`
  } else if (ts >= befYesTs) {
    // 前天
    return `${dayjs(curMillTs).format('星期dd AHH:mm')}`
  } else if (ts >= nowYearTs) {
    // 本年度其他日期
    return `${dayjs(curMillTs).format('MM-DD AHH:mm')}`
  } else {
    // 其他年度日期
    return `${dayjs(curMillTs).format('YYYY-MM-DD AHH:mm')}`
  }
}


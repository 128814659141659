import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class CashierOrderListAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getList', {
            url: '/admin/order/cashier',
            name: 'list',
            label: '列表'
        }],
        ['exportCashierOrderList', {
            url: '/admin/order/exportCashier',
            name: 'export-excel',
            label: '导出'
        }],
        ['searchVenueList', {
            url: '/admin/coupons/shop/list',
            name: 'shop-list',
            label: '店铺列表',
        }],
    ])
    
    // 收银台订单列表
    public getList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 导出收银台订单列表
    public exportCashierOrderList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('exportCashierOrderList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 搜索店铺列表
    public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('searchVenueList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}
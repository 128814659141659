
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import MemberChargeApi from "@/common/api/operationManage/memberCharge";
// import { CommonApi } from "@/common/api/common";

import { excuteDownloadExport2Excel } from "@/common/xlsx";

import {
  shareConfirmOptions,
  userLevel2ch
} from '@/common/config'

export const pageApiList = getPageAPIs(MemberChargeApi)



@Component({
  filters: {
    getMemberLevelName: function(level: number): string {
      return userLevel2ch[level] || ''
    }
  }
})
export default class MemberCharge extends Vue {
  private utilInstance: any;
  private memberChargeApi: any;

  // private commonApi: any;

  constructor() {
    super();
    this.memberChargeApi = new MemberChargeApi();

    // this.commonApi = new CommonApi();

    this.utilInstance = new Util();

    // 封禁时间列表
    // this.all_forbidden_time_list = Util.getFoibiddenTimeList();
  }

  private firstLoading: boolean = true;

  // private memberTypeList: any[] = [];
  private form: any = {
    // searchKw: "", // 搜索用户昵称/用户ID/手机号
    // type_id: "", // 会员等级
    mobile: "", // 手机号码
    // nickname: "", // 用户昵称
    // invite_code: "", // 邀请码
    // mobile: "", // 手机号
    time: [], // 时间选择
  };

  // private showtimecard: boolean = false;
  private activated(): void {
    this.getTable(true);

    // this.refreshMemberTypeList()

  }
  private getTable(noUnique = false): void {
    this.memberChargeApi.getMemberOpenList(
      this.getMemberOpenListParams("all", noUnique),
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        const { list, count, page, prepage } = data || {};

        const newTableList = Array.isArray(list)
          ? list.map(this.handleTableData)
          : [];

        // totalCount: 0,
        // pageNo: 1,
        // pageSize: 10,
        this.$set(this.tablePage, "totalCount", count);
        this.$set(this.tablePage, "pageNo", page);
        this.$set(this.tablePage, "pageSize", prepage);


        this.tableData = newTableList;

        this.firstLoading = false;
      },
      () => {
        this.firstLoading = false;
      }
    );
  }

  private getMemberOpenListParams(mode = "all", noUnique = false) {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const firstLoading = this.firstLoading;

    const {
      mobile, // 手机号码
      // nickname, // 用户昵称
      time // 时间
    } = form || {};

    // console.log(technicianItem, "technicianItem");

    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};

    if (mobile) {
      outerParams.mobile = mobile;
    }

    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      outerParams.start_date = startTime;

      outerParams.end_date = endTime;
    }

    if (
      mode == "exportExcel" &&
      !(outerParams.start_date && outerParams.end_date)
    ) {
      this.$message.error("请选择时段导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      outerParams.__isUnique = !firstLoading && !noUnique;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  // 后台返回的字段 转化为前端定义的字段
  private handleTableData(data: any): any {
    const {
     
    } = data;
    const newData = {
      ...data,
    
    };

    return newData;
  }

  private memberOpenDialogVisible: boolean = false

  private memberOpenLoading: boolean = false

  private memberOpenForm: any = {
    phone: '',
    type: '',
    remark: ''
  }

  private memberTypeList: any[] = []

  private refreshMemberTypeList() {
    return this.memberChargeApi.getMemberTypeList({}, (res: any) => {
      const {
        data
      } = res 
      const {
        list
      } = data || {}

      this.memberTypeList = list
    }, () => {

    })
  }

  private initMemberOpenForm() {
    this.memberOpenForm = {
      phone: '',
      type: '',
      remark: ''
    }
  }

  // 开通会员弹框
  private handleWakeMemberOpenDialog() {
    this.refreshMemberTypeList()

    this.initMemberOpenForm()

    this.memberOpenDialogVisible = true
  }

  private getMemberOpenParams() {
    const params: any = {}

    const memberOpenForm = this.memberOpenForm

    const {
      phone,
      type,
      remark
    } = memberOpenForm || {}

    const reg = new RegExp(
      "^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\\d{8}$"
    );

     if (phone) {
      if (!reg.test(phone)) {
        this.$message.error("请输入有效手机号");
        return null;
      }

      params.phone = phone;
    } else {
      this.$message.error("请输入手机号")

      return null
    }

    if (type) {
      params.type = type
    } else {
      this.$message.error("请选择要开通的会员")

      return null
    }

    if (remark) {
      params.remark = remark
    } else {
      this.$message.error("请填写备注")

      return null
    }

    return params
  }

  private handleSubmitMemberOpen() {
    const params = this.getMemberOpenParams()

    if (params === null) return

    this.memberOpenLoading = true
    this.memberChargeApi.memberOpenApply(params, (res: any) => {
      this.memberOpenLoading = false

      this.memberOpenDialogVisible = false

      this.getTable()
    }, () => {
      this.memberOpenLoading = false
    })
  }

  private memberChargeDialogVisible: boolean = false

  private memberChargeLoading: boolean = false

  private memberChargeForm: any = {
    phone: '',
    chargeAmount: '',
    remark: ''
  }

  private initMemberChargeForm() {
    this.memberOpenForm = {
      phone: '',
      chargeAmount: '',
      remark: ''
    }
  }

  // 开通会员弹框
  private handleWakeMemberChargeDialog() {
    this.initMemberChargeForm()

    this.memberChargeDialogVisible = true
  }


  private handleLimitChargeInput(val: string) {
    Util.limitDigitalnput(
      val,
      (new_val: string) => {
        this.$set(this.memberChargeForm, "chargeAmount", new_val);
      },
      (new_val: string) => {
        this.$set(this.memberChargeForm, "chargeAmount", new_val);
      },
      2
    );
  }

  private handleSubmitMemberCharge() {
    const params = this.getMemberChargeParams()

    if (params === null) return

    this.memberChargeLoading = true
    this.memberChargeApi.memberChargeApply(params, (res: any) => {
      this.memberChargeLoading = false

      this.memberChargeDialogVisible = false

      this.getTable()
    }, () => {
      this.memberChargeLoading = false
    })

  }

  private getMemberChargeParams() {
    const params: any = {}

    const memberChargeForm = this.memberChargeForm

    const {
      phone,
      chargeAmount,
      remark
    } = memberChargeForm || {}

    const reg = new RegExp(
      "^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\\d{8}$"
    );

    if (phone) {
      if (!reg.test(phone)) {
        this.$message.error("请输入有效手机号");
        return null;
      }

      params.phone = phone;
    } else {
      this.$message.error("请输入手机号")

      return null
    }

    if (chargeAmount) {
      params.price = parseFloat(chargeAmount)
    } else {
      this.$message.error("请输入要充值的金额")

      return null
    }

    if (remark) {
      params.remark = remark
    } else {
      this.$message.error("请填写备注")

      return null
    }

    return params
  }


  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 用户字段名 文案对应关系
  private userExcelList2ch: any = {
    user_name: "用户昵称",
    phone: "手机号码",
    user_level_type_name: "会员等级",
    price: "金额（元）",
    // inviter: "邀请人",
    remark: "备注",
    operation_name: "操作人",
    updated_at: "操作时间",
  };
  // 用户Excel 表头的先后顺序
  private userExcelSortList: string[] = [
    "user_name",
    "phone",
    "user_level_type_name",
    // "inviter",
    "price",
    "remark",
    "operation_name",
    "updated_at",
  ];

  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getMemberOpenListParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.memberChargeApi.exportMemberOpenList(
      params,
      (res: any) => {
        const { start_date, end_date } = params || {};

        const filename = `会员充值记录列表 ${start_date}到${end_date}.xlsx`;
        const { data: originList } = res || {};

        // const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const { type } = item;
              const newItem = {
                ...(item || {}),
              };

              newItem.user_level_type_name = userLevel2ch[type] || '未知'

              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const userExcelSortList = this.userExcelSortList;

          const userExcelList2ch = this.userExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            userExcelSortList,
            userExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */

  private restForm(): void {
    this.form = this.utilInstance.clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}

import { render, staticRenderFns } from "./merchantCreateCompetitionDetail.vue?vue&type=template&id=2fa6938a&scoped=true"
import script from "./merchantCreateCompetitionDetail.vue?vue&type=script&lang=ts"
export * from "./merchantCreateCompetitionDetail.vue?vue&type=script&lang=ts"
import style0 from "./merchantCreateCompetitionDetail.vue?vue&type=style&index=0&id=2fa6938a&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fa6938a",
  null
  
)

export default component.exports
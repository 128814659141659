import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class CommercialCollegeAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getCommercialCollegeList', {
      url: '/admin/businessSchool/list',
      name: 'list',
      label: '列表'
    }],
    ['updateCommercialCollege', {
      url: '/admin/businessSchool/edit',
      name: 'edit',
      label: '编辑商学院信息'
    }],
    ['addCommercialCollege', {
      url: '/admin/businessSchool/add',
      name: 'add',
      label: '添加商学院信息'
    }],
    ['deleteCommercialCollege', {
      url: '/admin/businessSchool/delete',
      name: 'delete',
      label: '删除商学院信息'
    }],

  ])
  // 商学院信息列表
  public getCommercialCollegeList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getCommercialCollegeList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 修改商学院信息
  public updateCommercialCollege(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('updateCommercialCollege').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 添加商学院信息
  public addCommercialCollege(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('addCommercialCollege').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 删除商学院信息
  public deleteCommercialCollege(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('deleteCommercialCollege').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}
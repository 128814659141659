import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class SaleMachineApi extends BasicsClass {
  public apiUrls: any = new Map([
    ['getSaleMachineList', {
      url: '/admin/sellerMachine/list',
      name: 'list',
      label: '列表'
    }],
    ['addSaleMachine', {
      url: '/admin/sellerMachine/add',
      name: 'add',
      label: '添加'
    }],
    ['updateSaleMachine', {
      url: '/admin/sellerMachine/edit',
      name: 'edit',
      label: '编辑'
    }],
    ['deleteSaleMachine', {
      url: '/admin/sellerMachine/delete',
      name: 'delete',
      label: '删除'
    }],
    ['searchVenueList', {
      url: '/admin/coupons/shop/list',
      name: 'shop-list',
      label: '店铺列表',
      // hidden: true
    }],
  ])

  // 售卖机列表
  public getSaleMachineList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getSaleMachineList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 修改售卖机
  public updateSaleMachine(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('updateSaleMachine').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 添加售卖机
  public addSaleMachine(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('addSaleMachine').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 删除售卖机
  public deleteSaleMachine(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('deleteSaleMachine').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 搜索店铺列表
  public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('searchVenueList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}

import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getPageAPIs } from "@/common/util";
import { RobotSettingAPI } from "@/common/api/customerService/robotSettingList";

import { CommonApi } from "@/common/api/common";

import gallery from "@/components/gallery.vue";

// import MerchantApi from "@/common/api/merchant";
// import { util } from "vue/types/umd";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
// import ZlSelect from "@/components/third-custom-components/select";

// import ZlOption from "@/components/third-custom-components/option";

import {
  shareConfirmOptions,
  getCommonList
} from "@/common/config";
export const pageApiList = getPageAPIs(RobotSettingAPI);


// 商家端店铺类型 0未知 1台球商家端  2棋牌商家端


const defaultForms = {
  // media_type 媒体类型:0图片,1视频
  media_list: [],
  title: "", // 标题
  content: "", // 内容
  sort: "", // 排序
  // singleMaxCountType: '0',
  // shop_id: "",
  // maxLimitCount: ''
};

// const defaultSearchVenuePageOption = {
//   page: 1,
//   limit: 10,
//   isRearchBottom: false,
// };

const competitionPointDialogTitle2ch: any = {
  "new-robot-setting": "新增机器人设置",
  "edit-robot-setting": "编辑机器人设置",
};

function createPicItem(id: number, url: string) {
  const item = {
    id,
    url,
    href: url,
    type: "image/png",
    types: "1",
  };

  return item;
}

function createVideoItem(id: number, url: string) {
  const item = {
    id,
    url,
    href: url,
    type: "video/mp4",
    types: "2",
    poster: `${url}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`,
  };

  return item;
}

// 最大有效期
// const maxExpiredDays = 9999;

// 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;

// const maxBenefitRate = 100



@Component({
  filters: {
    showPicOrThumbnail(row: any) {
      const { image_list, video_list } = row || {};

      if (Array.isArray(image_list) && image_list.length > 0) {
        return image_list[0].url || ""
      }

      if (Array.isArray(video_list) && video_list.length > 0) {
        return video_list[0] ? `${video_list[0].url}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto` ||
                ""
            : "";
      }

      return ""
    },




    showFilterText: Util.showFilterText
  },
  components: {
    gallery,
    // ZlSelect,
    // ZlOption,
  },
})
export default class RobotSettingsManage extends Vue {
  private form: any = {
    // competitionPointId: "",
    title: "", // 标题
    // shop_type: '', // 选择所属项目 
    // shop_id: '',
    // time: [],
    // startDate: "",
    // endDate: "",
  };
  private forms: any = {
    // maxType: "0",
    // ...defaultForms
  };

  private deepLoopUtil: any = {};

  // private merchantApi: any;

  private robotSettingApi = new RobotSettingAPI();


  private commonApi = new CommonApi();

  // private couponsManageAPI = new CouponsManageAPI();
  // private competitionPointTimeDurationAPI = new RobotSettingTimeDurationManageAPI();
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };


   
  }

  private created(): void {
    this.getRobotSettings();

    // this.refreshRobotSettingTimeDurations();

    // this.refreshTableClass();
  }
  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getRobotSettings();
  }
  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getRobotSettings();
  }

  private tableData: any[] = [];

  // 上传视频
  private userAvatarUploading: boolean = false;
  private handleUploadUserVideo(file: any, fileList: any[]) {
    console.log(file, fileList, "file", "fileList");
    this.userAvatarUploading = true;
    this.commonApi.getOssvideo(
      file instanceof File ? file : file.raw,
      (res: any) => {
        console.log(res, "res 视频上传成功");

        const { data } = res;

        const { data: callbackData } = data;

        const { item: uploadImgObj } = callbackData || {};

        const { id, url } = uploadImgObj;

        // this.$set(this.forms, "video_id", id);
        // this.$set(this.forms, "video_url", url);

        this.forms.study_video = [createVideoItem(id, url)];

        this.userAvatarUploading = false;
      },
      ({ errMsg }: any) => {
        if (errMsg == "out of limit duration") {
          this.$message.error("视频时长不可超过1小时");
        } else {
          this.$message.error("视频上传失败");
        }

        this.userAvatarUploading = false;
      },
      {
        limit_duration: 60 * 60, // 单位 秒  1h
      },
      file.url
    );
  }

  private handleRemoveStudyVideo() {
    this.forms.study_video = [];
  }

  private uploadMediaOss(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      if (file.type && file.type.startsWith('image/')) {
        this.commonApi.getOss(
          file,
          (res: any) => {
           resolve({
            _media_type: 'image', 
            ...res
           })
          },
          (err: any) => {
            reject(err)
          }
        );
      } else if (file.type && file.type.startsWith('video/')) {
        this.commonApi.getOssvideo(
        file,
        (res: any) => {
          resolve({
            _media_type: 'video', 
            ...res
          })
        },
        (err: any) => {
          reject(err)
        }
      );
      }
    })
  }

  private async handleChangeUploadPic(file: any, fileList: any[]) {
    console.log(file, fileList, "file", "fileList");

    const {
      _media_type: mediaType,
      data: uploadOriginData = {}
    } = await this.uploadMediaOss(
      file instanceof File ? file : file.raw,
    ).catch(() => {
      this.$message.error('媒体文件上传失败')
    }) ;


    const { data: uploadImgObjWrap } = uploadOriginData;

    const { item: uploadImgObj } = uploadImgObjWrap;

    const { id, url } = uploadImgObj;

    switch (mediaType) {
      case 'image':
        this.forms.media_list.push(createPicItem(id, url));
        break;
      case 'video':
        this.forms.media_list.push(createVideoItem(id, url));
        break;
    }

    
  }

  private handleRemoveStudyPicture(file: any) {
    const forms = this.forms || {};
    const existIndex = forms.media_list.findIndex(
      (picFile: any) => picFile == file
    );

    if (existIndex > -1) {
      forms.media_list.splice(existIndex, 1);
    }
  }

  // 查看视频/图片
  private handleBeforePreviewMedia(row: any) {
    const { image_list, video_list } = row || {};

    const previewItem: any = {};

    if (Array.isArray(image_list) && image_list.length > 0) {
      previewItem.image_count = image_list.length;

      previewItem.images = image_list.map((pic: any) => pic.url);
    }

    if (Array.isArray(video_list) && video_list.length > 0) {
      previewItem.video_count = video_list.length;

      previewItem.videos = video_list.map((pic: any) => pic.url);
    }
    
    this.handlePreviewMedia(previewItem);
  }

  private images: any = [];

  private handleBeforePreviewPicture(row: any) {
    const forms = this.forms

    const {
      media_list
    } = forms || {}
    

    const urls: any[] = [];

    media_list.forEach((item: any) => {
      switch (item.types) {
        case "1":
          urls.push({
            title: "",
            type: "image/jpeg",
            href: item.url,
            thumbnail: item.url,
          })
          break;
        case "2":
          urls.push({
            title: "",
            type: "video/mp4",
            href: item.url,
            poster: `${item.url}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`,
          })
          break;
      }
    })

    this.images = urls;
  }

  private handlePreviewMedia(row: any) {
    const { image_count, images, video_count, videos } = row || {};

    const urls: any[] = [];

    if (image_count > 0) {
      urls.push(
        ...images.map((img: string) => {
          return {
            title: "",
            type: "image/jpeg",
            href: img,
            thumbnail: img,
          };
        })
      );
    }

    if (video_count > 0) {
      urls.push(
        ...videos.map((video: string) => {
          return {
            title: "",
            type: "video/mp4",
            href: video,
            poster: `${video}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`,
          };
        })
      );
    }

    console.log(urls, 'urls')

    this.images = urls;
  }

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";

  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getRobotSettings();
  }

  private addOrUpdateRobotSettingTitle: string = "排名信息";

  private dialogFormVisible: boolean = false;


  // 拉起排名信息弹框
  private handleWakeRobotSettingDialog(mode: string) {
    // if (mode == 'new-robot-setting') { // 新增排名信息

    // }
    this.competitionPointDialogMode = mode;
    this.addOrUpdateRobotSettingTitle =
      competitionPointDialogTitle2ch[mode] || "排名信息";

    this.initRobotSettingForm(mode);

    this.dialogFormVisible = true;
  }

  private currentRobotSettingItem: any = null;

  private competitionPointDialogMode: string = "";

  private async initRobotSettingForm(mode: string) {
    const forms: any = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    if (mode == "edit-robot-setting") {
      const currentRobotSettingItem = this.currentRobotSettingItem;

      const {
        id,
        title,
        content,
        sort,
        image_list,
        video_list
      } = currentRobotSettingItem || {};

      Object.assign(forms, {
        title,
        content,
        sort,
      });

      if (Array.isArray(image_list) && image_list.length > 0) {
        forms.media_list.push(...image_list.map((item: any) => {
          const { url, id } = item || {};

          return createPicItem(id, url);
        }))
      }

      if (Array.isArray(video_list) && video_list.length > 0) {
        forms.media_list.push(...video_list.map((item: any) => {
          const { url, id } = item || {};

          return createVideoItem(id, url);
        }))
      }


      forms.id = id;
    }

    console.log(forms, "forms");

    this.forms = forms;
  }

  private handleWakeEditRobotSettingDialog(competitionPointItem: any) {
    this.currentRobotSettingItem = competitionPointItem;

    this.handleWakeRobotSettingDialog("edit-robot-setting");
  }

  private handleDelteRobotSettingDialog(competitionPointItem: any) {
    this.$confirm("确定删除该机器人设置?", "", {
      ...shareConfirmOptions,
    }).then(() => {
      this.robotSettingApi.deleteRobotSetting(
        {
          id: competitionPointItem.id,
        },
        () => {
          this.getRobotSettings();
        },
        () => {}
      );
    });
  }

  // 排序
  private handleLimitUserSort(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        // const limit_val = Math.min(Number(new_val), maxBenefitRate).toString();
        this.$set(this.forms, "sort", new_val);
      },
      (new_val: string) => {
        this.$set(this.forms, "sort", new_val);
      }
    );
  }

  private sumbitRobotSettingLoading: boolean = false;

  // 提交机器人设置配置
  private handleSumbitRobotSetting() {
    const competitionPointDialogMode = this.competitionPointDialogMode;

    const params = this.getRobotSettingFormsParams(
      competitionPointDialogMode
    );

    console.log(params, "params");
    if (params === false) return;

    this.sumbitRobotSettingLoading = true;
    switch (competitionPointDialogMode) {
      case "new-robot-setting":
        this.robotSettingApi.addRobotSetting(
          params,
          () => {
            this.getRobotSettings();
            this.dialogFormVisible = false;
            this.sumbitRobotSettingLoading = false;
          },
          () => {
            this.sumbitRobotSettingLoading = false;
          }
        );
        break;
      case "edit-robot-setting":
        this.robotSettingApi.updateRobotSetting(
          params,
          () => {
            this.getRobotSettings();
            this.dialogFormVisible = false;
            this.sumbitRobotSettingLoading = false;
          },
          () => {
            this.sumbitRobotSettingLoading = false;
          }
        );
        break;
    }
  }

  private getRobotSettingFormsParams(mode: string) {
    const params: any = {};

    const showErrorMessage = (msg: string) => {
      if (!!msg) {
        this.$message.error(msg);
      }
    };

    const forms = this.forms;

    const {
      id,
      // name,
      // expired_days,
      // meituanId,
      // timeDuration
      // machine_number,
      // phone,
      // rank

      media_list = [],
      content,
      sort,
    } = forms || {};

    const keys = ["title"];

    const key2EndKey: any = {
      title: "title",
      // content: "content",
    };

    const key2ErrMsg: any = {
      title: "标题不能为空",
      // content: "内容不能为空",
    };

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (forms[key]) {
        const errMsg = key2ErrMsg[`${key}_${forms[key]}`];
        if (errMsg) {
          showErrorMessage(errMsg);

          return false;
        }
        params[key2EndKey[key] || key] = forms[key];
      } else {
        showErrorMessage(key2ErrMsg[key]);

        return false;
      }
    }

    Object.assign(params, {
      sort,
    });


    if (media_list && media_list.length > 0 || content) {
      const image_ids: any[] = [], video_ids: any[] = [] 
      if (media_list.length > 0) {
        media_list.forEach((item: any) => {
          switch (item.types) {
            case "1":
              image_ids.push(item.id)
              break;
            case "2":
              video_ids.push(item.id)
              break;
          }
        })

        if (image_ids.length > 0) {
          params.image_id = image_ids.join(',')
        }

        if (video_ids.length > 0) {
          params.video_id = video_ids.join(',')
        }
      }

      if (content) {
        params.content = content
      }
    } else {
      showErrorMessage('请上传媒体文件或者输入内容')
      return false
    }

    if (mode == "edit-robot-setting") {
      params.id = id;
    }

    return params;
  }

  //获取机器人设置列表
  private getRobotSettings(): void {
    this.robotSettingApi.getRobotSettingList(
      { ...this.getRobotSettingParams() },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page;
        this.tablePage.prepage = res.data.prepage;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private getRobotSettingParams(): any {
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    const { title } = form;

    if (title) {
      params.title = title;
    }


    if (page) {
      params.page = page;
    }

    if (prepage) {
      params.limit = prepage;
    }

    return params;
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };
}

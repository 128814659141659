import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export  class MerchantsAuditByDayAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['business', {
            url: '/admin/shop/businessDetail',
            name: 'list',
            label: '列表'
        }],
        ['exportExcelMerchantAuditList',  {
            url: '/admin/shop/exportBusinessDetail',
            name: 'export-excel',
            label: '导出'
        }],
       
    ])
    
    // 台球每日营收列表
    public business(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('business').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 导出台球每日营收列表
    public exportExcelMerchantAuditList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('exportExcelMerchantAuditList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

}

import { Component, Prop, Vue } from "vue-property-decorator";
import { Login, login } from "@/common/login/login";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import getMenuList from "@/common/asideMenu";
import { Util } from "@/common/util";

import {
  clearServingChat
} from '@/utils/index'
const JSEncrypt = require("@/common/bin/jsencrypt.js");

const defaultCountDownNum: number = 60

@Component
export default class HelloWorld extends Vue {
  private param: login = {
    // username: "",
    // password: "",
    phone: '',
    code: ''
  };

  private loginF: Login;

  private deepLoopUtil: any;

  private curCountDown: number = 0;

  private isDisabledGetVertifyCodeBtn: boolean = false

  private isGetVertifyCodeBtnLoading: boolean = false


  private countDonwTimer: any

  constructor() {
    super();

    this.loginF = new Login();

    this.deepLoopUtil = new DeepLoop();

    getMenuList().then((menuList: any[]) => {
      localStorage.setItem("menus", JSON.stringify(menuList));
    })
  }

  private handleStartCountDown() {
    clearTimeout(this.countDonwTimer)
    const curCountDown = defaultCountDownNum - 1

    this.curCountDown = curCountDown

    const startCountDownTimeout = (count: number) => {
      if (count > 0) {
        this.countDonwTimer = setTimeout(() => {
          this.curCountDown = count - 1

          startCountDownTimeout(count - 1)
        }, 1000)
      } else {
        this.isDisabledGetVertifyCodeBtn = false
      }
    }

    startCountDownTimeout(curCountDown)

    this.isDisabledGetVertifyCodeBtn = true
  }

  private handleGetVertifyCode() {
    const param = this.param

    const params: any = {
    
    }

    if (!param.phone) {
      this.$message.error('请输入手机号')
      return
    }

    params.phone = param.phone

    this.isGetVertifyCodeBtnLoading = true

    this.loginF.getLoginVertifyCode(
      params,
      () => {
        this.$message.success('短信发送成功')

        this.isGetVertifyCodeBtnLoading = false

        this.handleStartCountDown()
      },
      () => {
        this.isGetVertifyCodeBtnLoading = false
      }
    )
  }

  private submitForm() {
    this.submitErrMsg = "";

    if (!this.param.phone) {
      this.submitErrMsg = "请输入手机号";
      return;
    } else if (!this.param.code) {
      this.submitErrMsg = "请输入验证码";
      return;
    }

    const param = this.deepLoopUtil.deepCopy(this.param);
    // const encrypt = new JSEncrypt.JSEncrypt();
    // encrypt.setPublicKey(
    //   "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDAfA20cRn7tWmU3R9rEA6UnH+1KzqoZAu0JJdDis+wgZTp8JKnkFBBMa8TsaI0muA0/J0dsY2wC6m3d5sT6lwXeLk9KOxasjtM3Y2lISLks6VRG6qE9CYKEynbMTf2WjygI08pxjiCDLTC5RRJ53fomE9JE0iXMFyYy9kKi1JcxwIDAQAB/8914nmEgq6wbH5Iuw5Ra6805doP14SEMbKEITlyloyc6b3l1EjRWe1HuwlGaHwlW2vBAOAKbu1EeBDaLjehmmH7gOB3Tr0OvR7smQIDAQAB"
    // );
    // param.username = encrypt.encrypt(this.param.username);
    // param.password = encrypt.encrypt(this.param.password);
    // // param.username = 'l45Kfg36GWMDGxBnrJDeAJ+sLHSPEJAx/R16DWw7+3CRDBJKI8yaWh/tXLCjykaC6kcNBHA11JySTrMQqQCmYvftmd4bQAIJHbRm1OqvEFbYN+O1zJ4H0CTLZnASk78soAJzdUD88W8iZwFxUa5JijafHFNz5du047QA1fj5VFE='
    // param.password = 'R+xEKP4hRogQgkYzwkoaMuQWoJlZQoNheufwqPTEfGeldyqnzBZ664UST78sdwVVAwNvE72MTe8O0fFGEnRdkW8zU8XPcFxcqWW49VxikL+o7ulNK5u3WJxk9WwxwIDAE4LzzMD4wAGTh1yIhP8m0PiR7+suCBRQ3UyuD1zAg08='

    //权限分类
    // const roleIdentity = ["admin"];
    //权限对应菜单
    // const roleAdmin = ["/addUser"];

    this.loginF.submitForm(
      param,
      async (res: any) => {
        // let iconArr = [
        //   {
        //     id: 1,
        //     icon: require("@/assets/home/shujufenxi@2x.png"),
        //   },
        //   {
        //     id: 2,
        //     icon: require("@/assets/home/pingtaiguanli@2x.png"),
        //   },
        //   {
        //     id: 3,
        //     icon: require("@/assets/home/yunying@2x.png"),
        //   },
        //   {
        //     id: 4,
        //     icon: require("@/assets/home/xitongshezhi@2x.png"),
        //   },
        //   {
        //     id: 5,
        //     icon: require("@/assets/home/xitongshezhi@2x.png"),
        //   },
        //   {
        //     id: 6,
        //     icon: require("@/assets/home/xitongshezhi@2x.png"),
        //   },
        //   {
        //     id: 999,
        //     icon: require("@/assets/home/xitongshezhi@2x.png"),
        //   },
        // ];
        // for (let i of res.memu) {
        //   for (let x of iconArr) {
        //     i.id = i.id.toString();
        //     if (i.id == x.id) {
        //       i.icon = x.icon;
        //     }
        //   }
        // }
        // // if (res.menu) {
        // //   localStorage.setItem("menus", JSON.stringify(res.memu));
        // // } else {
        // //   localStorage.setItem("menus", JSON.stringify(getMenuList()));
        // // }
        // try {
        //   this.$router.push(res.memu[0].children[0].url);
        // } catch (e: any) {
        //   Util.showToast("暂无任何权限，请联系管理员添加权限", "no");
        // }
        
        const menus = await getMenuList()
        console.log(menus, 'menus')
        
        localStorage.setItem("menus", JSON.stringify(menus));

        clearServingChat()

        this.$router.push(menus[0].children[0].url);
      },
      (msg: string) => {
        this.submitErrMsg = msg;
      }
    );
  }

  private submitErrMsg: string = "";

  private activated() {
    localStorage.clear();
  }

  private deactivated() {
    clearTimeout(this.countDonwTimer)
  }
}

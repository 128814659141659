
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import chatMessageItem from "@/components/panda-service/chatMessageItem.vue";

import { getServUserInfo } from "@/utils/index";

import { CommonApi } from "@/common/api/common";

import gallery from "@/components/gallery.vue";

import { Picker as emojiPicker } from "emoji-mart-vue";

import PandaServiceAPI from "@/common/api/utils/pandaService";

// import dayjs from "@/plugins/dayjs";

const defaultPageOption: any = {
  scan_id: 0,
  limit: 20,
};

const minScrollEventDistance = 3; // 最小滚动距离 被认定为滚动触发

// function handleTransferChatItem(chatItem: any) {
//   const {
//     content: originContent
//   } = chatItem || {}

//   const newChatItem: any = {
//     ...chatItem
//   }

//   newChatItem._originContent = originContent

//   const {
//     type,
//     content,
//     images,
//     video,
//     send_time
//   } = originContent || {}

//   if (type == 4) {
//     const list: any[] = []
//     if (content) {
//       list.push({
//         ...newChatItem,
//         content: {
//           type: 1,
//           content,
//           send_time
//         }
//       })
//     }

//     if (Array.isArray(images) && images.length > 0) {
//       list.push(images.map((image: string) => ({
//         ...newChatItem,
//         content: {
//           type: 1,
//           url: image,
//           send_time
//         }
//       })))
//     }

//     if (Array.isArray(video) && video.length > 0) {
//       list.push(video.map((vido: string) => ({
//         ...newChatItem,
//         content: {
//           type: 1,
//           content: vido,
//           send_time
//         }
//       })))
//     }

//     return list
//   }

//   return newChatItem
// }
let manualPushChatIndex: number = 1;

// const localMediaObjectURL: string[] = []

// function addLoclMediaURLs(urls: string[]) {
//   localMediaObjectURL.push(...urls)
// }

// function delLocalMedisUrls(url: string) {
//   const existUrlIndex: number = localMediaObjectURL.findIndex((eachUrl) => eachUrl == url)

//   if (existUrlIndex > -1) {
//     URL.revokeObjectURL(url)

//     localMediaObjectURL.splice(existUrlIndex, 1)
//   }
// }

// function clearMediaObjectURL() {
//   localMediaObjectURL.forEach((url) => {
//     URL.revokeObjectURL(url)
//   })

//   localMediaObjectURL.length = 0
// }

function getUniqueId(): number {
  const nowTs = Date.now();

  return nowTs + manualPushChatIndex++;
}
@Component({
  components: {
    "emoji-picker": emojiPicker,
    "chat-message-item": chatMessageItem,
    gallery,
  },
})
export default class PandaChatDialog extends Vue {
  @Prop({
    type: Number,
    default: 0,
  })
  private serId: any;

  @Prop({
    type: [String, Array],
    default: "none", // none | all | ['emoji', 'media', 'textarea']
  })
  private disabledInput: any;

  @Prop({
    type: Boolean,
    default: false,
  })
  private hiddenInput: any;

  @Watch("serId", { immediate: false })
  private $effectSerId(newVal: any, oldVal: any) {
    this.initPandataChatList();
  }

  private get chatScrollContainer(): any {
    const refs = this.$refs;

    return refs.chatScrollContainer;
  }

  private get disabledEmoji() {
    const disabledInput = this.disabledInput;

    if (disabledInput === "all") return true;

    if (Array.isArray(disabledInput) && disabledInput.includes("emoji"))
      return true;

    return false;
  }

  private get disabledMedia() {
    const disabledInput = this.disabledInput;

    if (disabledInput === "all") return true;

    if (Array.isArray(disabledInput) && disabledInput.includes("media"))
      return true;

    return false;
  }

  private get disabledTextarea() {
    const disabledInput = this.disabledInput;

    if (disabledInput === "all") return true;

    if (Array.isArray(disabledInput) && disabledInput.includes("textarea"))
      return true;

    return false;
  }

  // private get chatScrollConTop() {
  //   return this.chatScrollContainer.scrollTop
  // }
  /**
   * 
   * behavior smooth | instant | auto
   * 
   * smooth: scrolling should animate smoothly;
     instant: scrolling should happen instantly in a single jump;
     auto: scroll behavior is determined by the computed value of scroll-behavior
   */
  @Watch("disabledInput")
  private $effectChangeTextarea() {
    this.textarea = "";
  }

  private get pandaChatElTextarea() {
    const refs = this.$refs || {};

    const { pandaChatElTextarea } = refs || {};

    return pandaChatElTextarea;
  }

  private textarea: string = "";

  private insertEmoji({ native }: any) {
    // console.log(args, 'args')
    this.textarea += native;
  }

  private addEnterInTextareaElmCursor() {
    const pandaChatElTextarea: any = this.pandaChatElTextarea || {};

    console.log(pandaChatElTextarea, "pandaChatElTextarea");

    const textareaEl = pandaChatElTextarea.$el.querySelector("textarea");

    console.log(textareaEl, "textareaEl");

    const cursorPos = textareaEl.selectionStart;
    // const cursorPosEnd = textareaEl.selectionEnd

    // console.log(cursorPosStart, 'cursorPosStart')
    // console.log(cursorPosEnd, 'cursorPosEnd')

    const textarea = this.textarea;

    this.textarea =
      textarea.slice(0, cursorPos) + "\n" + textarea.slice(cursorPos);

    this.$nextTick(() => {
      textareaEl.selectionStart = cursorPos + 1;
      textareaEl.selectionEnd = cursorPos + 1;
    });
  }

  private handleSumbitTextMessage(e: any) {
    if (e.ctrlKey && e.key === "Enter") {
      // this.textarea += '\n'

      this.addEnterInTextareaElmCursor();

      return true;
    }

    if (e.key !== "Enter") return;
    e.preventDefault();

    this.handleConfirmSumbitTextMsg();
  }

  private handleConfirmSumbitTextMsg() {
    const textarea = this.textarea;
    if (!textarea.trim()) {
      this.$message.error("消息不能为空");
      return false;
    }

    this.textarea = "";

    const chatItem = this.createManualPushChatItem(textarea, 1);

    this.addManualPushChat([chatItem]);

    this.sendMessageByWs(chatItem);
  }

  private preScrollTop: number = 0;

  private scrollTimer: any = null;

  private scrollReachTopStatus: string = "finished"; // finished | loading

  private onChatScroll(ev: any) {
    // console.log(ev, 'ev')
    if (this.isManualScroll) {
      this.isManualScroll = false;
      this.preScrollTop = this.chatScrollContainer.scrollTop;
      return;
    }

    clearTimeout(this.scrollTimer);

    this.scrollTimer = setTimeout(() => {
      const el: HTMLElement = ev.target || ev.srcElment;

      const curScrollTop = el.scrollTop;

      const diffScrollTop = curScrollTop - this.preScrollTop;

      this.preScrollTop = curScrollTop;

      console.log("触发滚动 diffScrollTop", diffScrollTop);

      if (Math.abs(diffScrollTop) < minScrollEventDistance) return;

      if (diffScrollTop > 0) {
        // 向下滚动
      } else {
        // 向上滚动
        const reachTopDis: number = 10;

        console.log(this.chatScrollContainer.scrollTop, "chatScrollConTop");

        if (this.chatScrollContainer.scrollTop <= reachTopDis) {
          this.onScrollReachTop();
        }
      }
    }, 200);
  }

  private onScrollReachTop() {
    console.log("触发触顶函数");
    const restChatTotal = this.restChatTotal;

    const pageOption = this.pageOption;

    if (restChatTotal > 0 && pageOption.scan_id > -1) {
      this.scrollReachTopStatus = "loading";
      this.refreshPandaChatList();
    }
  }

  private isManualScroll: boolean = false;

  private handleGoScrollTo(top: number, behavior: any = "instant") {
    const scrollContainer = this.chatScrollContainer;

    if (scrollContainer instanceof HTMLElement) {
      this.isManualScroll = true;
      if (typeof scrollContainer.scrollTo == "function") {
        scrollContainer.scrollTo({
          top: top,
          left: 0,
          behavior: behavior,
        });
      } else {
        scrollContainer.scrollTop = top;
      }
    }
  }

  private handleGoScrollBottom(behavior: any = "instant") {
    const scrollContainer = this.chatScrollContainer;

    console.log(scrollContainer, "scrollContainer");

    if (scrollContainer instanceof HTMLElement) {
      console.log(scrollContainer.scrollHeight);
      console.log(scrollContainer.clientHeight);
      // console.log(scrollContainer.scrollTo)

      const reachBottomScrollTop =
        scrollContainer.scrollHeight - scrollContainer.clientHeight;

      console.log(reachBottomScrollTop, "reachBottomScrollTop");

      this.handleGoScrollTo(reachBottomScrollTop, behavior);
    }
  }

  private restChatTotal: number = 0;

  private chatList: any[] = [];

  // private showChatList: any[] = []

  private pageOption: any = {
    ...defaultPageOption,
  };

  // // 重连 刷新聊天信息
  // private refreshCurShowChatList() {
  //   const serId = this.serId
  // }

  private initPandataChatList() {
    this.pageOption = {
      ...defaultPageOption,
    };

    // 清空 chatList 也会触发滚动事件，同时触发触顶事件
    this.isManualScroll = true;

    this.chatList = [];

    const serId = this.serId;

    // 新的记录改为index
    manualPushChatIndex = 1;

    if (typeof serId == "number" && serId > 0) {
      this.refreshPandaChatList(true);
    }
  }

  private refreshPandaChatList(fresh = false) {
    const pageOption = this.pageOption;

    const params: any = {
      swo_id: this.serId,
      ...pageOption,
    };

    if (fresh) {
      params.scan_id = 0;
    }

    this.pandaServiceApi.getHistoryChat(
      params,
      (res: any) => {
        const { list = [], scan_id, seal_num, limit } = res.data || {};

        if (fresh) {
          this.chatList = list;
        } else {
          // overflow-anchor auto 要生效  scrollTop 不能为 0
          this.handleGoScrollTo(1);

          this.chatList.unshift(...list);
        }
        // debug
        // this.chatList.push({
        //   type: 7,
        //   swo_id: 1,
        //   chat_id: 100,
        //   content: {
        //     type: 7,
        //     aid: 1,
        //     nickname:"客服小七",
        //     send_time: Math.floor(Date.now() / 1000)
        //   }
        // }, {
        //   type: 2,
        //   swo_id: 1,
        //   chat_id: 200,
        //   from_type: 1,
        //   from_avatar: '',
        //   content: {
        //     type: 2,
        //     url: 'https://asset.test.pandaball.cc/upload/img/f8cecc69a06fb46112da77b39caa12fc.jpg',
        //     send_time: Math.floor(Date.now() / 1000)
        //   }
        // }, {
        //   type: 3,
        //   swo_id: 1,
        //   from_type: 1,
        //   from_avatar: '',
        //   chat_id: 300,
        //   content: {
        //     type: 3,
        //     url: 'https://asset.test.pandaball.cc/upload/video/0594add1ab9c559cbfde0a0f96bdd42e.mp4',
        //     send_time: Math.floor(Date.now() / 1000)
        //   }
        // }, {
        //   type: 5,
        //   swo_id: 1,
        //   chat_id: 400,
        //   from_type: 2,
        //   from_avatar: '',
        //   content: {
        //     type:5,
        //     before_content:"以下内容是否能帮助到您？您可以点击或者回复数字选择：", after_content:"您也可以参照以上描述说说您的问题哦！",
        //     list:[{"id":1,"title":"如何设置美团团购"},{"id":2,"title":"如何设置抖音团购"}],send_time: Math.floor(Date.now() / 1000)}
        // }, {
        //   type: 8,
        //   swo_id: 1,
        //   chat_id: 600,
        //   from_type: 1,
        //   from_avatar: '',
        //   content: {
        //     type:8,
        //     id: 1,
        //     send_time:Math.floor(Date.now() / 1000),
        //     content: "如何设置美团团购"
        //   }

        // }, {
        //   type: 4,
        //   swo_id: 1,
        //   chat_id: 500,
        //   from_type: 2,
        //   from_avatar: '',
        //   content: {
        //     type:4,
        //     content: "c测测S水电费接口数据发顺丰刷卡缴费后刷卡缴费后刷卡缴费后刷卡缴费后收款方黑色咖啡看 k sdf DK金凤凰是收代理费水电费",
        //     images: [
        //       'https://asset.test.pandaball.cc/upload/img/f8cecc69a06fb46112da77b39caa12fc.jpg',
        //       'https://asset.test.pandaball.cc/upload/img/f8cecc69a06fb46112da77b39caa12fc.jpg'
        //     ],
        //     video: [
        //       'https://asset.test.pandaball.cc/upload/video/0594add1ab9c559cbfde0a0f96bdd42e.mp4',
        //       'https://asset.test.pandaball.cc/upload/video/0594add1ab9c559cbfde0a0f96bdd42e.mp4'
        //     ]
        //   }
        // })

        // this.showChatList = fresh ? list.map(handleTransferChatItem).flat(1) : this.showChatList.concat(list.map(handleTransferChatItem).flat(1))

        console.log(this.chatList, "this.chatList");

        this.restChatTotal = seal_num;

        this.pageOption.scan_id = scan_id;

        this.pageOption.limit = limit;

        this.scrollReachTopStatus = "finished";

        if (fresh) {
          this.$nextTick(() => {
            this.handleGoScrollBottom();
          });
        }
      },
      (err: any) => {
        this.scrollReachTopStatus = "finished";
      }
    );
  }

  private pandaServiceApi: any;

  private manualPushChatIndex: number = 1;

  //  1 普通文字消息 2 图片消息 3 视频消息 媒体文件 额外有一个销毁函数（在本地链接不再需要时，销毁）
  private createManualPushChatItem(msg: string | File, type: number) {
    const uniqueId = `send${getUniqueId()}`;

    const serId = this.serId;

    const userInfo = getServUserInfo() || {};

    const createTs = Math.floor(Date.now() / 1000);

    const chatItem: any = {
      _is_local: true,
      type,
      swo_id: serId,
      id: uniqueId,
      from_avatar: userInfo.avatar || "",
      from_type: 2, // 发送人身份 1店铺用户 2管理后台用户
      content: {
        type,
        send_time: createTs,
      },
    };

    switch (type) {
      case 1:
        chatItem.content.content = msg;
        break;
      case 2:
      case 3:
        if (msg instanceof File) {
          const mediaUrl = URL.createObjectURL(msg);

          chatItem._mediaFileRaw = msg;

          chatItem._mediaUploadStatus = "uploading"; // uploading |  finished | failed

          // this.uploadChatMedia(msg, type).then(({
          //   // id,
          //   url
          // }: any) => {
          //   this.updateChatItems(serId, uniqueId, 'cur', {
          //     url,
          //     _mediaFileRaw: null,
          //     uploadStatus: 'finished'
          //   })
          // }, () => {
          //   this.updateChatItems(serId, uniqueId, 'cur', {
          //     uploadStatus: 'failed'
          //   })
          // })

          chatItem.content.url = mediaUrl;
        }
        break;
    }

    return chatItem;
  }

  private uploadChatMedia(file: File, mediaType: number) {
    return new Promise((resolve, reject) => {
      switch (mediaType) {
        case 2:
          this.commonApi.getOss(
            file,
            (res: any) => {
              resolve(res);
            },
            (err: any) => {
              reject(err);
            }
          );
          break;
        case 3:
          this.commonApi.getOssvideo(
            file,
            (res: any) => {
              resolve(res);
            },
            (err: any) => {
              reject(err);
            }
          );
          break;
        default:
          resolve({});
      }
    }).then((res: any) => {
      const { data } = res;

      const { data: callbackData } = data;

      const { item: uploadImgObj } = callbackData || {};

      return uploadImgObj;
    });
  }

  private mediaUploading: boolean = false;

  private uploadRefreshChatItem(chatItem: any) {
    const hiddenInput = this.hiddenInput;

    // 禁用上传逻辑
    if (hiddenInput) return Promise.reject("forbidden upload media");

    this.mediaUploading = true;
    return this.uploadChatMedia(chatItem._mediaFileRaw, chatItem.type).then(
      ({ url }: any) => {
        this.mediaUploading = false;
        const newChatItem = this.updateChatItems(
          this.serId,
          chatItem.id,
          "cur",
          {
            url,
            _mediaFileRaw: null,
            uploadStatus: "finished",
          }
        );

        return newChatItem;
      },
      () => {
        this.mediaUploading = false;
        return this.updateChatItems(this.serId, chatItem.id, "cur", {
          uploadStatus: "failed",
        });
      }
    );
  }

  // mode: cur(更新当前数据) | more-next(更新下面所有新数据)
  private updateChatItems(
    swo_id: any,
    chat_id: any,
    mode: string = "cur",
    options: {
      id?: number;
      msg?: string;
      url?: string;
      _mediaFileRaw?: File | null;
      __errMsg?: string;
      uploadStatus?: string;
    } = {}
  ) {
    if (swo_id !== this.serId) return;

    const chatList = this.chatList;

    const existChatIndex: number = chatList.findIndex(
      (chat: any) => chat.id === chat_id
    );

    if (existChatIndex == -1) return;

    const set = (
      target: any,
      options: any,
      prop: string,
      optionProp: string
    ) => {
      if (optionProp in options) {
        Object.assign(target, {
          [prop]: options[optionProp],
        });
      }
    };

    const {
      id,
      msg = "",
      url = "",
      // _mediaFileRaw
    } = options || {};

    const { content } = chatList[existChatIndex] || {};

    switch (mode) {
      case "cur":
        if ("id" in options) {
          if (msg) {
            content.content = msg;
          }

          if (url) {
            content.url = url;
          }
          Object.assign(chatList[existChatIndex], {
            _is_local: false,
            id,
            content: {
              ...content,
            },
          });
        } else if ("url" in options) {
          const originMediaUrl = content.url;

          // 销毁原有链接对象
          URL.revokeObjectURL(originMediaUrl);

          Object.assign(chatList[existChatIndex], {
            content: {
              ...content,
              url,
            },
          });
        }

        const keyList: string[][] = [
          ["uploadStatus", "_mediaUploadStatus"],
          ["_mediaFileRaw", "_mediaFileRaw"],
          ["__errMsg", "__errMsg"],
        ];

        keyList.forEach(([optionProp, prop]) => {
          set(chatList[existChatIndex], options, prop, optionProp);
        });

        this.chatList = chatList;

        return chatList[existChatIndex];
      case "more-next": // 暂时不做，当多张媒体上传时，无法保证更新逻辑。
        // （目前实现的暂时只能有一张媒体处于上传阶段， 为了不添加复杂度）

        break;
    }
  }

  private delLocalChat(chat_id: any) {
    const chatList = this.chatList;

    const existChatIndex: number = chatList.findIndex(
      (chat: any) => chat.id === chat_id
    );

    const chatItem: any = chatList[existChatIndex];

    if (existChatIndex > -1 && chatItem._is_local) {
      chatList.splice(existChatIndex, 1);
    }

    this.chatList = chatList;
  }

  // 选中媒体文件
  private chooseMedia(file: any, fileList: any[]) {
    const fileRaw: File = file.raw;

    const addChats: any[] = [];

    if (fileRaw.type.startsWith("image/")) {
      addChats.push(this.createManualPushChatItem(fileRaw, 2));
    } else if (fileRaw.type.includes("video/")) {
      addChats.push(this.createManualPushChatItem(fileRaw, 3));
    }

    this.addManualPushChat(addChats);

    const chatItem: any = addChats[0];

    return this.uploadRefreshChatItem(chatItem).then((newChatItem: any) => {
      this.sendMessageByWs(newChatItem);
    });
  }

  // 手动推上屏
  private addManualPushChat(chats: any[]) {
    const hiddenInput = this.hiddenInput;

    // 禁用上屏逻辑
    if (hiddenInput) return;

    this.chatList.push(...chats);

    this.$nextTick(() => {
      this.handleGoScrollBottom();
    });
  }

  // 推送websocket消息
  private sendMessageByWs(chatItem: any) {
    const hiddenInput = this.hiddenInput;

    // 禁用推送信息逻辑
    if (hiddenInput) return;

    this.$emit("send-ws", {
      chatItem,
    });
  }

  private created() {
    // manualPushChatIndex = 1
  }

  private commonApi = new CommonApi();

  constructor() {
    super();

    this.pandaServiceApi = new PandaServiceAPI();
  }
  // @Prop({
  //   type: String,
  //   default: '你好，测试'
  // })
  // private nextMsg: any;

  // 预览媒体文件
  private previewImages: any[] = [];
  private onPreviewMedia({ url, mode }: any) {
    const medias: any[] = [];
    switch (mode) {
      case "img":
        medias.push({
          title: "",
          type: "image/jpeg",
          href: url,
          thumbnail: url,
        });
        break;
      case "video":
        medias.push({
          title: "",
          type: "video/mp4",
          href: url,
          poster: `${url}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`,
        });
        break;
    }

    this.previewImages = medias;
  }
}

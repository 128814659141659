const servingChatKey = "servingIds";

let servingChats: any = null;

let isInited: boolean = false;

export function setServingChat(chat_id: number, ts: number | null = null) {
  if (!isInited) {
    getServingChat();
  }

  if (servingChats) {
    servingChats[chat_id] = ts;
  } else {
    servingChats = {
      [chat_id]: ts,
    };
  }

  localStorage.setItem(servingChatKey, JSON.stringify(servingChats));
}

export function getServingChat() {
  if (isInited) {
    return servingChats;
  } else {
    isInited = true;
    const originServingChats = localStorage.getItem(servingChatKey);
    return (servingChats = originServingChats
      ? JSON.parse(originServingChats)
      : null);
  }
}


export function delServingChat(chat_id: number) {
  if (!isInited) {
    getServingChat();
  }

  if (!servingChats) return

  if (chat_id in servingChats) {
    delete servingChats[chat_id]
  }

  localStorage.setItem(servingChatKey, JSON.stringify(servingChats))
}

export function batchDelExpriedChats(servingChatIds: number[]) {
  if (!isInited) {
    getServingChat();
  }

  if (!servingChats) return

  let isChanged: boolean = false

  const storeIds = Object.keys(servingChats)

  storeIds.forEach((storeId: string) => {
    const storeIdNum: number = Number(storeId)

    if (!servingChatIds.includes(storeIdNum)) {
      isChanged = true
      delete servingChats[storeId]
    }
  })

  if (isChanged) {
    localStorage.setItem(servingChatKey, JSON.stringify(servingChats))
  }
}


export function clearServingChat() {
  servingChats = null

  localStorage.removeItem(servingChatKey)
}



const servUserKey = 'serving-user-info'

let servUserInfo: any = null

let isServUserInited: boolean = false

// 登录客服信息
export function setServUserInfo(userInfo: any = null) {
  if (!isServUserInited) {
    getServUserInfo()
  }

  servUserInfo = userInfo

  localStorage.setItem(servUserKey, JSON.stringify(userInfo));
}

export function getServUserInfo() {
  if (isServUserInited) {
    return servUserInfo
  } else {
    isServUserInited = true

    const originServUserInfo = localStorage.getItem(servUserKey);

    return (servUserInfo = originServUserInfo ? JSON.parse(originServUserInfo) : null)
  }
}


import { BasicsClass } from '@/common/BasicsClass';

export default class BrilliantShowAPI extends BasicsClass {

    public apiUrls: any = new Map([
        ['getBrilliantShowTableList', {
            url: '/admin/handpickBall/lists',
            name: 'lists',
            label: '精彩球列表'
        }],
        ['deleteBrilliantShow', {
            url: '/admin/handpickBall/delete',
            name: 'delete',
            label: '删除精彩球'
        }],
        ['updateBrilliantShowState', {
            url: '/admin/handpickBall/state',
            name: 'state',
            label: '修改精彩球状态（上下架）'
        }],
        ['getBrilliantShowCommentTableList', {
            url: '/admin/handpickBall/commentList',
            name: 'comment-lists',
            label: '评论列表'
        }],
        ['deleteBrilliantShowComment', {
            url: '/admin/handpickBall/deleteComment',
            name: 'comment-delete',
            label: '删除评论'
        }]

    ])
    // 精彩球列表
    public getBrilliantShowTableList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        params = {...(params || {}),}

        const apiUrls = this.apiUrls

        const url = apiUrls.get('getBrilliantShowTableList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 删除精彩球
    public deleteBrilliantShow(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('deleteBrilliantShow').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 修改精彩球状态（上下架）
    public updateBrilliantShowState(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('updateBrilliantShowState').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 评论列表
    public getBrilliantShowCommentTableList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getBrilliantShowCommentTableList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    // 删除评论
    public deleteBrilliantShowComment(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('deleteBrilliantShowComment').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}

import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class SingleCardConsumeRecordAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getList', {
            url: '/admin/mtVourcher/receiptList',
            name: 'list',
            label: '列表'
        }],
        ['exportSingleCardConsumeList', {
            url: '/admin/mtVourcher/exportReceiptList',
            name: 'export-excel',
            label: '导出'
        }],
        ['refundMtVourcherRecord', {
            url: '/admin/mtVourcher/refund',
            name: 'single-card-refund',
            label: '单店卡退款'
        }],
    ])
    
    // 美团单店卡核销记录列表
    public getList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 导出美团单店卡核销记录列表 
    public exportSingleCardConsumeList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('exportSingleCardConsumeList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 退款 美团单店卡核销记录
    public refundMtVourcherRecord(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('refundMtVourcherRecord').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}
import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class MemberCouponUsedRecordAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getMemberCouponUsedList', {
            url: '/admin/member_card/list',
            name: 'list',
            label: '列表'
        }],
    ])

    // 会员管理列表 后台充值列表
    public getMemberCouponUsedList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getMemberCouponUsedList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }


    

}
/** 注意： meta  key 一旦确定不可修改 且 同级唯一。 是作为识别这个对象的唯一标识！！！   */

// 数据总览
export const dataWholeBoardRoutes = [
  {
    path: "/dataStatistic",
    name: "dataStatistic",
    meta: {
      title: "数据看板",
      key: 'data-total-board', // 同级唯一
      pageApisPrm: import("@/views/dataWholeBoard/dataStatistic.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
    /* webpackChunkName: "dataWholeBoard" */ "@/views/dataWholeBoard/dataStatistic.vue"
      ),
  },
  {
    path: "/dayDataStatistic",
    name: "dayDataStatistic",
    meta: {
      title: "每日数据统计",
      key: 'day-data-statistic', // 同级唯一
      pageApisPrm: import("@/views/dataWholeBoard/dayDataStatistic.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
    /* webpackChunkName: "dataWholeBoard" */ "@/views/dataWholeBoard/dayDataStatistic.vue"
      ),
  },
  {
    path: "/monthIncomeAudit",
    name: "monthIncomeAudit",
    meta: {
      title: "每月营收",
      key: 'month-income-summar', // 同级唯一
      pageApisPrm: import("@/views/dataWholeBoard/monthIncomeAudit.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
    /* webpackChunkName: "dataWholeBoard" */ "@/views/dataWholeBoard/monthIncomeAudit.vue"
      ),
  },
  {
    path: "/merchantsAudit",
    name: "merchantsAudit",
    meta: {
      title: "台球营收详情",
      key: 'ball-income-summar',
      pageApisPrm: import("@/views/dataWholeBoard/merchantsAudit.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
    /* webpackChunkName: "dataWholeBoard" */ "@/views/dataWholeBoard/merchantsAudit.vue"
      ),
  },
  {
    path: "/merchantsAuditByDay",
    name: "merchantsAuditByDay",
    meta: {
      title: "台球每日营收详情",
      key: 'ball-day-income-summar',
      asideHidden: true, // 侧边栏不显示
      pageApisPrm: import("@/views/dataWholeBoard/merchantsAuditByDay.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
    /* webpackChunkName: "dataWholeBoard" */ "@/views/dataWholeBoard/merchantsAuditByDay.vue"
      ),
  },
  {
    path: "/merchantsGloryAudit",
    name: "merchantsGloryAudit",
    meta: {
      title: "棋牌营收详情",
      key: 'glory-income-summar',
      pageApisPrm: import("@/views/dataWholeBoard/merchantsGloryAudit.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
    /* webpackChunkName: "dataWholeBoard" */ "@/views/dataWholeBoard/merchantsGloryAudit.vue"
      ),
  },
  {
    path: "/merchantsGloryAuditByDay",
    name: "merchantsGloryAuditByDay",
    meta: {
      title: "棋牌每日营收详情",
      key: 'glory-day-income-summar',
      asideHidden: true, // 侧边栏不显示
      pageApisPrm: import("@/views/dataWholeBoard/merchantsGloryAuditByDay.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
    /* webpackChunkName: "dataWholeBoard" */ "@/views/dataWholeBoard/merchantsGloryAuditByDay.vue"
      ),
  },
  {
    path: "/shopIncomeChart",
    name: "shopIncomeChart",
    meta: {
      title: "店铺营收详情图表",
      key: 'shop-income-chart',
      pageApisPrm: import("@/views/dataWholeBoard/shopIncomeChart.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
    /* webpackChunkName: "dataWholeBoard" */ "@/views/dataWholeBoard/shopIncomeChart.vue"
      ),
  },
  {
    path: '/userManualConsumeRecord',
    name: 'userManualConsumeRecord',
    meta: {
      title: "用户台球消费记录（自助下单）",
      key: 'user-ball-consume-self-record',
      pageApisPrm: import("@/views/dataWholeBoard/userManualConsumeRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "dataWholeBoard" */ "@/views/dataWholeBoard/userManualConsumeRecord.vue"
      ),
  },
  {
    path: '/userCasherConsumeRecord',
    name: 'userCasherConsumeRecord',
    meta: {
      title: "用户台球消费记录（收银台）",
      key: 'user-ball-consume-cashier-record',
      pageApisPrm: import("@/views/dataWholeBoard/userCasherConsumeRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "dataWholeBoard" */ "@/views/dataWholeBoard/userCasherConsumeRecord.vue"
      ),
  },
  {
    path: '/coachPreviewStatistic',
    name: 'coachPreviewStatistic',
    meta: {
      title: "助教被浏览数据统计",
      key: 'coach-preview-statistic',
      pageApisPrm: import("@/views/dataWholeBoard/coachPreviewStatistic.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "dataWholeBoard" */ "@/views/dataWholeBoard/coachPreviewStatistic.vue"
      ),
  },
  {
    path: '/coachPreviewStatisticOneDay',
    name: 'coachPreviewStatisticOneDay',
    meta: {
      title: "(每日)助教被浏览数据统计",
      key: 'coach-preview-statistic-one-day',
      asideHidden: true, // 侧边栏不显示
      pageApisPrm: import("@/views/dataWholeBoard/coachPreviewStatisticOneDay.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "dataWholeBoard" */ "@/views/dataWholeBoard/coachPreviewStatisticOneDay.vue"
      ),
  },
  // lvmiLampOptionLog
  {
    path: "/lvmiLampOptionLog",
    name: "lvmiLampOptionLog",
    meta: {
      title: "绿米灯控数据查询",
      key: 'lvmi-lamp-option-log', // 同级唯一
      pageApisPrm: import("@/views/dataWholeBoard/lvmiLampOptionLog.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
    /* webpackChunkName: "dataWholeBoard" */ "@/views/dataWholeBoard/lvmiLampOptionLog.vue"
      ),
  },
  // coachTaxiCostStatistic
  {
    path: "/coachTaxiCostStatistic",
    name: "coachTaxiCostStatistic",
    meta: {
      title: "助教车费数据统计",
      key: 'coach-taxi-cost-statistic', // 同级唯一
      pageApisPrm: import("@/views/dataWholeBoard/coachTaxiCostStatistic.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
    /* webpackChunkName: "dataWholeBoard" */ "@/views/dataWholeBoard/coachTaxiCostStatistic.vue"
      ),
  },
]
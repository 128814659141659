import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class OpenTimeLevelRewardRecordAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getOpenTimeLevelRewardRecordList', {
      url: '/admin/officialWeekReward/recordList',
      name: 'list',
      label: '列表'
    }],
    
    ['exportExcelOpenTimeLevelRewardRecordList', {
      url: '/admin/officialWeekReward/exportRecordList',
      name: 'export-excel',
      label: '导出'
    }],
   
  ])

  // 单店卡充值列表
  public getOpenTimeLevelRewardRecordList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getOpenTimeLevelRewardRecordList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

   // 导出单店卡充值列表
   public exportExcelOpenTimeLevelRewardRecordList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('exportExcelOpenTimeLevelRewardRecordList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}
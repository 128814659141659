
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getPageAPIs } from "@/common/util";
import { BannersManageAPI } from "@/common/api/operationManage/bannerList";
// import { util } from "vue/types/umd";
import { DeepLoop } from "@/common/deepLoop/deepLoop";

import { CommonApi } from "@/common/api/common";

import {
  brandType2Ch,
  getCommonList,
  shareConfirmOptions,
  shareShopType2ch,
  chooseShopTypeList,
} from "@/common/config";

export const pageApiList = getPageAPIs(BannersManageAPI);

const defaultForms = {
  title: "",
  // origin_price: "",
  // sale_price: "",
  shop_type: 1, // 默认所属 台球项目
  jump_type: 0, // 跳转类型，
  brand_type: 1, // 1 熊猫； 2 众享（麦力士）

  value_object: "", // 跳转地址

  avatar: "", // 头像
  avatar_id: "", // 头像id

  sort: "", // 排序
  is_enable: 1, // 状态
  // singleMaxCountType: '0',
  // shop_id: "",
  // maxLimitCount: ''
};

const bannerDialogTitle2ch: any = {
  "new-banner": "轮播图添加",
  "edit-banner": "轮播图编辑",
};

// 跳转类型 0不跳转 1打开url 2跳转到店铺详情 3跳转小程序(大学生赛) 4会员中心 5邀请好友 6跳转小程序(会员赛) 7招募合伙人 8跳转到上周周榜奖励页面 9夏日活动 10有奖竞猜 11新年活动
const jumpType2ch: any = [
  "不跳转",
  "打开URL",
  "跳转店铺详情",
  "比赛活动页",
  "会员中心",
  "邀请好友",
  "跳转“熊猫杯”精英会员比赛活动",
  "招募合伙人",
  "跳转周榜奖励列表",
  "跳转夏日活动",
  "跳转有奖竞猜",
  "跳转春节活动"
];

const bannerStatus2ch: any = ["下架", "上架"];

// // 最大有效期
// const maxExpiredDays = 9999;

// // 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;

const bannerShopType: any = [...shareShopType2ch];

@Component({
  filters: {
    showJumpTypeName(jump_type: number) {
      return jumpType2ch[jump_type] || "未知";
    },
    showBannerStatusName(is_enable: number) {
      return bannerStatus2ch[is_enable] || "未知";
    },

    belongToProjectName(shop_type: number) {
      return bannerShopType[shop_type] || "";
    },
  },
})
export default class BannersManage extends Vue {
  private form: any = {
    // couponId: "",
    shop_name: "",
    // time: [],
    // startDate: "",
    // endDate: "",
  };
  private forms: any = {
    // maxType: "0",
    // ...defaultForms
  };

  private commonApi: any;

  private deepLoopUtil: any = {};

  private allJumpTypeList: any[] = [];

  private get showJumpTypeList() {
    const allJumpTypeList = this.allJumpTypeList;

    const forms = this.forms;

    const { shop_type } = forms || {};

    const belongToBall = [3, 4, 5, 6, 7, 8, 9, 10, 11]; // 仅属于台球项目、APP 8号玩家

    const belogToMerchants = [0, 1]; // 属于商家端 包含 球社 以及 国粹馆

    if (shop_type == 2) {
      // 属于国粹馆
      return allJumpTypeList.filter(
        (item) => !belongToBall.includes(item.value)
      );
    }

    return (shop_type === 1 || shop_type === 5)
      ? allJumpTypeList
      : allJumpTypeList.filter((item) => belogToMerchants.includes(item.value));
  }

  private refreshJumpTypeInfo() {
    this.$set(this.forms, "jump_type", "");
  }

  private bannersManageApi = new BannersManageAPI();


  private chooseBrandTypeList: any[] = [
    ...getCommonList(brandType2Ch, 1)
  ]

  private get isHasBrandType() {
    const forms = this.forms

    const {
      shop_type, // 5(8号玩家)
      jump_type // 2跳转到店铺详情
    } = forms || {}

    return jump_type == 2 && shop_type == 5
  }
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.commonApi = new CommonApi();

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    // this.searchVenuePageOption = {
    //   ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    // };

    /** 暂时屏蔽 6 类型的 跳转  */
    this.allJumpTypeList = jumpType2ch
      .map((item: string, index: number) => {
        return {
          label: item,
          value: index,
        };
      })
      .filter((item: any) => item.value !== 6);
  }

  private belongShopTypeList: any[] = [
    // {
    //   label: '熊猫台球',
    //   value: 1
    // },
    // {
    //   label: '熊猫国粹馆',
    //   value: 2
    // },
    // {
    //   label: '熊猫球社商家端',
    //   value: 3
    // },
    // {
    //   label: '熊猫国粹馆商家端',
    //   value: 4
    // }
    ...chooseShopTypeList,
  ];

  private created(): void {
    this.getBanners();
  }
  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getBanners();
  }

  private tableData: any[] = [];

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";

  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getBanners();
  }

  private addOrUpdateBannerTitle: string = "轮播图";

  private dialogFormVisible: boolean = false;

  // 拉起banner弹框
  private handleWakeBannerDialog(mode: string) {
    // if (mode == 'new-banner') { // 新增卡券

    // }
    this.bannerDialogMode = mode;
    this.addOrUpdateBannerTitle = bannerDialogTitle2ch[mode] || "轮播图";

    this.initBannerForm(mode);

    this.dialogFormVisible = true;
  }

  // 删除 banner
  handleDeleteBanner(row: any) {
    this.$confirm("确定删除该轮播图项?", "", {
      ...shareConfirmOptions,
    }).then(() => {
      this.bannersManageApi.deleteBanners(
        {
          id: row.id,
        },
        () => {
          this.getBanners();
        },
        () => {}
      );
    });
  }

  private currentBannerItem: any = null;

  private bannerDialogMode: string = "";

  private async initBannerForm(mode: string) {
    const forms: any = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    if (mode == "edit-banner") {
      // 编辑
      // await this.getMoreVenueList(true)

      const currentBannerItem = this.currentBannerItem;

      const {
        id,
        title,
        jump_type,
        shop_type,
        brand_type,
        value_object,
        image_url: avatar,
        image_id: avatar_id,
        sort,
        is_enable,
      } = currentBannerItem || {};

      if (title) {
        forms.title = title;
      }

      if (typeof jump_type == "number") {
        forms.jump_type = jump_type;
      }

      if (shop_type) {
        forms.shop_type = shop_type;
      }

      if (brand_type) {
        forms.brand_type = brand_type
      }

      if (value_object) {
        forms.value_object = value_object.toString();
      }

      if (typeof sort == "number") {
        forms.sort = sort.toString();
      }

      if (typeof is_enable == "number") {
        forms.is_enable = is_enable;
      }

      if (typeof avatar_id == "number") {
        forms.avatar = avatar;

        forms.avatar_id = avatar_id;
      }

      forms.id = id;
    }

    console.log(forms, "forms");

    // console.log(this.venueList, "this.venueList");

    this.forms = forms;
  }

  private handleWakeEditBannerDialog(couponItem: any) {
    this.currentBannerItem = couponItem;

    this.handleWakeBannerDialog("edit-banner");
  }

  // 有效期
  private handleLimitValueObject(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        this.$set(this.forms, "value_object", new_val);
      },
      (new_val: string) => {
        this.$set(this.forms, "value_object", new_val);
      }
    );
  }

  // 上传头像
  private userAvatarUploading: boolean = false;
  private handleUploadUserAvatar(file: any, fileList: any[]) {
    console.log(file, fileList, "file", "fileList");

    this.userAvatarUploading = true;
    this.commonApi.getOss(
      file instanceof File ? file : file.raw,
      (res: any) => {
        console.log(res, "res 图片上传成功");

        const { data } = res;

        const { data: uploadImgObjWrap } = data;

        const { item: uploadImgObj } = uploadImgObjWrap;

        const { id, url } = uploadImgObj;

        this.$set(this.forms, "avatar_id", id);
        this.$set(this.forms, "avatar", url);

        this.userAvatarUploading = false;
      },
      () => {
        this.$message.error("图片上传失败");

        this.userAvatarUploading = false;
      }
    );
  }

  // 排序
  private handleLimitSort(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        // const limit_val = Math.min(Number(new_val), maxTimeDuration).toString();
        this.$set(this.forms, "sort", new_val);
      },
      (new_val: string) => {
        this.$set(this.forms, "sort", new_val);
      }
    );
  }

  private handleLimitSingleMaxCount(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        this.$set(this.forms, "maxLimitCount", new_val);
      },
      (new_val: string) => {
        this.$set(this.forms, "maxLimitCount", new_val);
      }
    );
  }

  private sumbitBannerLoading: boolean = false;

  // 提交卡券配置
  private handleSumbitBanner() {
    const bannerDialogMode = this.bannerDialogMode;

    const params = this.getBannerFormsParams(bannerDialogMode);

    console.log(params, "params");
    if (params === false) return;

    this.sumbitBannerLoading = true;
    switch (bannerDialogMode) {
      case "new-banner":
        this.bannersManageApi.addBanners(
          params,
          () => {
            this.getBanners();
            this.dialogFormVisible = false;
            this.sumbitBannerLoading = false;
          },
          () => {
            this.sumbitBannerLoading = false;
          }
        );
        break;
      case "edit-banner":
        this.bannersManageApi.updateBanners(
          params,
          () => {
            this.getBanners();
            this.dialogFormVisible = false;
            this.sumbitBannerLoading = false;
          },
          () => {
            this.sumbitBannerLoading = false;
          }
        );
        break;
    }
  }

  private getBannerFormsParams(mode: string) {
    const params: any = {};

    const showErrorMessage = (msg: string) => {
      if (!!msg) {
        this.$message.error(msg);
      }
    };

    const forms = this.forms;

    const {
      id,
      // name,
      // expired_days,
      // meituanId,
      // timeDuration
      shop_type,
      value_object,
      sort,
      brand_type,
    } = forms || {};

    const keys = ["title", "shop_type", "jump_type", "avatar_id", "is_enable"];

    const key2EndKey: any = {
      title: "title",
      shop_type: "shop_type",
      jump_type: "jump_type",
      avatar_id: "image_id",
      is_enable: "is_enable",
    };

    const key2ErrMsg: any = {
      title: "轮播图标题不能为空",
      shop_type: "所属项目不能为空",
      jump_type: "跳转类型必选",
      avatar_id: "banner图不能为空",
    };

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (
        forms[key] !== "" &&
        forms[key] !== undefined &&
        forms[key] !== null
      ) {
        const errMsg = key2ErrMsg[`${key}_${forms[key]}`];
        if (errMsg) {
          showErrorMessage(errMsg);

          return false;
        }
        params[key2EndKey[key] || key] = forms[key];
      } else {
        showErrorMessage(key2ErrMsg[key]);

        return false;
      }
    }

    // 允许设置 value_object 跳转类型
    const allowJumptypes: number[] = [1, 2, 3]

    if (allowJumptypes.includes(forms.jump_type) && value_object) {
      params.value_object = value_object;
    }

    if (typeof sort == "string") {
      params.sort = Number(sort);
    }

    if (this.isHasBrandType) {
      params.brand_type = brand_type
    }

    console.log(id, "id");

    if (typeof id == "number") {
      params.id = id;
    }

    return params;
  }

  //获取优惠券列表
  private getBanners(): void {
    this.bannersManageApi.getBanners(
      { ...this.getBannerParams() },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page;
        this.tablePage.prepage = res.data.prepage;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private getBannerParams(): any {
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    const { shop_name } = form;

    if (shop_name) {
      params.name = shop_name;
    }

    if (page) {
      params.page = page;
    }

    if (prepage) {
      params.limit = prepage;
    }

    return params;
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };
}

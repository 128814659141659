import { BasicsClass } from '@/common/BasicsClass';
export class MembersManageAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getMembers', {
            url: '/admin/member/lists',
            name: 'list',
            label: '列表'
        }],
        ['updateMembers',  {
            url: '/admin/member/save',
            name: 'edit',
            label: '编辑'
        }],
        ['deleteMembers',  {
            url: '/admin/member/delete',
            name: 'delete',
            label: '删除'
        }],
    ])

    //修改会员
    public updateMembers(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('updateMembers').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    //删除会员
    public deleteMembers(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('deleteMembers').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 会员列表
    public getMembers(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getMembers').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }


}
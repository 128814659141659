import { BasicsClass } from '@/common/BasicsClass';

export class AfterSalesAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getAfterSalesList', {
      url: '/admin/afterSales/list',
      name: 'list',
      label: '售后服务列表'
    }],
    ['handleAfterSales', {
      url: '/admin/afterSales/handle',
      name: 'handle',
      label: '处理售后申请'
    }]
  ])
  // 售后服务列表
  public getAfterSalesList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getAfterSalesList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 售后服务列表
  public handleAfterSales(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('handleAfterSales').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }


}

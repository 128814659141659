
import {Component, Vue} from "vue-property-decorator";
import {Util, getPageAPIs} from "@/common/util";
import {RecordExamineAPIClass} from "@/common/api/operationManage/dynamicReportExamine";
import gallery from "@/components/gallery.vue";

import {
  shareConfirmOptions
} from '@/common/config';


export const pageApiList = getPageAPIs(RecordExamineAPIClass);
@Component({
  components: {
    gallery,
  },
})
export default class PandaCoinRecordList extends Vue {
  private recordExamineApi: any;
  private form: any = {
    phone: '',
    report_phone: '',
    is_status:null,
    start_date:null,
    end_date:null,
  };
  private time = []
  private statusArrays = [
    { value:0,label:'全部'},
    {value:1,label:'待审核'},
    {value:2,label:'已处理，不提交为全部'},
  ]


  constructor() {
    super();
    this.recordExamineApi = new RecordExamineAPIClass();
  }

  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    this.recordExamineApi.getRecordExamineTableList(
        {
          ...this.form,
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
        }
    );
  }

  private restForm(): void {
    this.form.phone = undefined
    this.form.report_phone = undefined
  }

  private toDynamic(row:any){
    this.$router.push({
      path: "/dynamicManage",
      query: {
        phone: row.report_phone,
      },
    });
  }

  // 删除
  private clickDeletePandaCoin(item: any) {
    this.$confirm(`确定处理被举报人${item.report_user_name}？`, '', {
      ...shareConfirmOptions
    }).then(() => {
      this.recordExamineApi.handleReport({
            id: item.id
          },
          () => {
            Util.showToast("处理成功");
            this.search();
          }
      )
    })
  }


  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    if (Array.isArray(this.time) && this.time.length === 2) {
      const [startDate, endDate] = this.time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      this.form.start_date = startTime;
      this.form.end_date = endTime;
    }
    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }
}

import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

import { CommonApi } from '../common';

export default class OpenCityManageAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getOpenCitiesConfigList', {
            url: '/admin/city/lists',
            name: 'list',
            label: '列表'
        }],
        ['getChooseCityList', {
            url: '/admin/city/citys',
            name: 'all-city-list',
            label: '所有城市列表',
            // hidden: true,
        }],
        ['createOpenCityConfig', {
            url: '/admin/city/add',
            name: 'add',
            label: '添加'
        }],
        ['updateOpenCityConfig', {
            url: '/admin/city/save',
            name: 'edit',
            label: '编辑'
        }]
    ])

    // momo达到家-城市列表
    public getOpenCitiesConfigList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getOpenCitiesConfigList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 可选择的城市列表
    public getChooseCityList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getChooseCityList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 添加城市
    public createOpenCityConfig(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('createOpenCityConfig').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 修改城市
    public updateOpenCityConfig(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('updateOpenCityConfig').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}
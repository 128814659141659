<template>
  <div class="download-qrcode-container">
    <a :href="picUrl" :download="picName">
      <el-button
       class="setBtn"
       size="small"
      @click="handleDownloadQrCode">下载二维码</el-button>
    </a>
  </div>
</template>

<script>
import { qrcanvas } from 'qrcanvas';

const defaultCvsOptions = {
  size: 600
}

export default {
  props: {
    qrContent: {
      type: String,
      default: 'test qrcode'
    },
    fileName: {
      type: String,
      default: '测试二维码'
    },
    options: {
      type: Object,
      default: () => ({
       
      })
    }
  },
  data() {
    return {
      canvasEl: null,

      picUrl: '',
      picName: ''
    };
  },
  mounted() {
    // this.initCompInfo()
  },
  methods: {
    handleDownloadQrCode() {
      const picUrl = this.picUrl

      const picName = this.picName

      if (!(picName && picUrl)) {
        this.initCompInfo()
      }
    },

    initCompInfo() {
      const qrContent = this.qrContent

      const options = Object.assign({}, {
        ...defaultCvsOptions,
        data: qrContent
      }, this.options)

      console.log(options, 'options')


      const canvas = qrcanvas(options)

      this.canvasEl = canvas

      // document.body.append(canvas)

      this.getDownloadPicInfo(canvas)

      // this.$on("hock:beforeDestroy", () => {
      //   document.body.remove(canvas)
      // })
    },

    getDownloadPicInfo(canvas) {
      const fileName = this.fileName
      this.picUrl = canvas.toDataURL()

      this.picName = `${fileName}.png`
      },

      refreshCompInfo() {
        // if (this.canvasEl) {
        //   document.body.remove(this.canvasEl)
        // }

        this.initCompInfo()
      }
  },
  watch: {
    options: {
      handler: function() {
        this.refreshCompInfo()
      },
      deep: true
    },

    qrContent: 'refreshCompInfo'
  },
};
</script>

<style>

</style>

import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class CompetitionLiveSettingAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getCompetitionLiveSettingList', {
      url: '/admin/leagueLive/detailList',
      name: 'list',
      label: '列表'
    }],
    ['updateCompetitionLiveSetting', {
      url: '/admin/leagueLive/detailEdit',
      name: 'edit',
      label: '编辑赛事直播设置'
    }],
    ['getCompetitionLiveSettingDetail', {
      url: '/admin/leagueLive/detailInfo',
      name: 'setting-detail',
      label: '直播设备详情'
    }],

    ['getCompetitionLiveDeviceList', {
      url: '/admin/showShopMonitor/index',
      name: 'live-device-list',
      label: '监控设备列表'
    }],

    ['updateLiveDeviceBind', {
      url: '/admin/leagueLive/bind',
      name: 'update-device-bind',
      label: '更新设备绑定'
    }],

    ['addCompetitionLiveSetting', {
      url: '/admin/leagueLive/detailAdd',
      name: 'add',
      label: '添加赛事直播设置'
    }],
    ['deleteCompetitionLiveSetting', {
      url: '/admin/leagueLive/detailDelete',
      name: 'delete',
      label: '删除赛事直播设置'
    }],

    ['searchVenueList', {
      url: '/admin/coupons/shop/list',
      name: 'shop-list',
      label: '店铺列表',
      // hidden: true
    }],

  ])
  // 赛事直播设置列表
  public getCompetitionLiveSettingList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getCompetitionLiveSettingList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 修改赛事直播设置
  public updateCompetitionLiveSetting(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('updateCompetitionLiveSetting').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 监控设备列表
  public getCompetitionLiveDeviceList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getCompetitionLiveDeviceList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

   // 更新设备绑定
   public updateLiveDeviceBind(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('updateLiveDeviceBind').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

   // 赛事直播设置详情
   public getCompetitionLiveSettingDetail(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getCompetitionLiveSettingDetail').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 添加赛事直播设置
  public addCompetitionLiveSetting(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('addCompetitionLiveSetting').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 删除赛事直播设置
  public deleteCompetitionLiveSetting(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('deleteCompetitionLiveSetting').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 店铺列表
  public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('searchVenueList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}
import { BasicsClass } from '@/common/BasicsClass';

export default class OperationsPersonnelAPI extends BasicsClass {

    public apiUrls: any = new Map([
        ['list', {
            url: '/admin/praise/user',
            name: 'list',
            label: '列表'
        }],
        ['add', {
            url: '/admin/praise/userAdd',
            name: 'add',
            label: '添加'
        }],
        ['edit', {
            url: '/admin/praise/userEdit',
            name: 'edit',
            label: '修改'
        }],
        ['delete', {
            url: '/admin/praise/userDel',
            name: 'delete',
            label: '删除'
        }],
        ['department', {
            url: '/admin/admin_department/list',
            name: 'department',
            label: '部门列表'
        }],
    ])
    public getList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {

        params = {
            ...(params || {})
        }

        const apiUrls = this.apiUrls

        const url = apiUrls.get('list').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    public add(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('add').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    public edit(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('edit').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    public delete(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('delete').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    public getDepartmentList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {

        params = {
            ...(params || {})
        }

        const apiUrls = this.apiUrls

        const url = apiUrls.get('department').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}

import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

import { CommonApi } from '../common';

export default class OpenTimeRewardSettingAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getOpenTimeRewardSettingList', {
            url: '/admin/officialWeekReward/list',
            name: 'list',
            label: '列表'
        }],
        ['getOpenTimeSettingInfo', {
            url: '/admin/officialWeekReward/configInfo',
            name: 'open-time-setting-info',
            label: '周榜奖励开关',
            // hidden: true,
        }],
        ['editOpenTimeRewardConfig', {
            url: '/admin/officialWeekReward/configEdit',
            name: 'reward-conf-edit',
            label: '编辑周榜奖励配置'
        }],
        ['updateOpenTimeRewardConfig', {
            url: '/admin/officialWeekReward/edit',
            name: 'reward-edit',
            label: '编辑周榜信息'
        }]
    ])

    // 周榜奖励列表
    public getOpenTimeRewardSettingList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getOpenTimeRewardSettingList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 周榜奖励开关配置
    public getOpenTimeSettingInfo(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getOpenTimeSettingInfo').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

 

    // 编辑周榜奖励配置
    public editOpenTimeRewardConfig(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('editOpenTimeRewardConfig').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 编辑周榜信息
    public updateOpenTimeRewardConfig(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('updateOpenTimeRewardConfig').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}
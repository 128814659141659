
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import { OpenShopOrderListAPI } from "@/common/api/marketManage/openShopOrderList";

// import { util } from "vue/types/umd";
import { DeepLoop } from "@/common/deepLoop/deepLoop";

import { CommonApi } from "@/common/api/common";

import ZlSelect from "@/components/third-custom-components/select";

import ZlOption from "@/components/third-custom-components/option";

import { excuteDownloadExport2Excel } from "@/common/xlsx";

import {
  shareConfirmOptions,
  shareShopType2ch,
  toChineseNumber,
  // defaultWeekList,
  getCommonList,
} from "@/common/config";

const defaultPoolItem = {
  pool_type_id: "", // 球桌型号id
  pool_single_price: "", // 球桌单价
  pool_num: "", // 球桌数量
};


// function getPayLabel(index: number): string {
//   const chNum: string = toChineseNumber(index + 1);

//   return chNum === "一" ? `首款` : `第${chNum}笔`;
// }

function createPoolItemForm(originItem: any = null, index: number): any {
  const { index: idx } = originItem || {};

  const newIdx = typeof idx == "number" ? idx : index;

  const newOriginItem: any = {

  }

  Object.keys(defaultPoolItem).forEach((key: string) => {
    newOriginItem[key] = originItem ? originItem[key] : ''
  })

  const item = {
    ...DeepLoop.deepClone(defaultPoolItem, false),
    // __label: getPayLabel(newIdx),
    ...(newOriginItem ? DeepLoop.deepClone(newOriginItem, false) : {}),
  };

  return item;
}

// function createPaymentInfoTxt(pool_type_list: any[]): string {
//   if (!Array.isArray(pool_type_list)) return "";
//   return pool_type_list.map(createPayTxt).join("\n");
// }

// function createPayTxt(item: any, index: number) {
//   const { index: idx, __label, price, payment_date: date } = item || {};

//   const newIdx = typeof idx == "number" ? idx : index;

//   return `${__label || getPayLabel(newIdx)}: ${price} ${
//     date ? "时间：" + date : ""
//   }`;
// }

// 暴露的API 列表
export const pageApiList = getPageAPIs(OpenShopOrderListAPI);

console.log(pageApiList, "pageApiList");


const hdTopPerfix: string[] = ['应收金额', '已收金额', '待收金额', '总订单', '已发货', '待发货', '待发货球桌',]

function createTopTotal(row: any = null) {
  const {
    receivable_price = 0,
    received_price = 0,
    await_receive_price = 0,
    count = 0,
    delivery_all_num = 0,
    delivery_await_num = 0,
    delivery_await_pool_num = 0
  } = row || {}

  const list: any[] = [
    receivable_price,
    received_price,
    await_receive_price,
    count,
    delivery_all_num,
    delivery_await_num,
    delivery_await_pool_num
  ].map((num: number, idx: number) => {
    const item = {
      label: hdTopPerfix[idx],
      num
    }

    return item
  })

  return list
}

const defaultForm = {
  shop_name: "", // 店铺/客户名称

  contract_person: "", // 签约人

  shop_status: 0, // 店铺状态:0全部1分类选址中2装修中3已开业4已暂停

  delivery_status: 0, // 发货状态:0全部1待发货2部分已发货3全部已发货

  sale_way: 0, // 销售方式:0全部1熊猫加盟2器材销售3众享加盟

  finance_status: 0, // 财务状态:0全部1未付款2部分付款3已付清

  await_receive_price_type: 0, // 0全部 待收金额类型:(1:0元以上,2:5000元以上,3:10000元以上,4:20000元以上)

  progress_type: 1, // 0全部 进度时间类型:(1签约—选址 30天以内,2:签约—选址 30-60天,3:签约—选址 60天以上,4:签约—结清  30天以上,5:签约—结清 45天以上,6:签约—结清 60天以上,7:选址—开业  30天以上,8:选址—开业 45天以上,9:选址—开业 60天以上,10:签约—开业  60天以上,11:签约—开业  90天以上,12:签约—开业  120天以上)

  sign_time: [], // 签约日期

  address_time: [], // 选址日期

  bussiness_time: [], // 开业日期


  // customer_name: "", // 客户名称

  // phone: "", // 电话

  // market_source_ids: [], // 线索来源id
};

// 店铺状态分类:0全部1选址中2装修中3已开业4已暂停
const shopStatus2Ch: string[] = ["", "选址中", "装修中", "已开业", "已暂停"];

// 发货状态:0全部1待发货2部分已发货3全部已发货
const deliveryStatus2Ch: string[] = ["", "待发货", "部分已发货", "全部已发货"]

// 销售方式:0全部1熊猫加盟2器材销售3众享加盟
const saleWay2Ch: string[] = ["", "熊猫加盟", "器材销售", "众享加盟"]

// 财务状态:0全部1未付款2部分付款3已付清
const financeStatus2Ch: string[] = ["", "未付款", "部分付款", "已付清"]

// 待收金额类型:(1:0元以上,2:5000元以上,3:10000元以上,4:20000元以上)
const awaitReceivePriceType2Ch: string[] = ["", "0元以上", "5000元以上", "10000元以上", "20000元以上"]

/**
 * 进度时间类型:(
 * 1签约—选址 30天以内;
 * 2:签约—选址 30-60天;
 * 3:签约—选址 60天以上;
 * 4:签约—结清  30天以上;
 * 5:签约—结清 45天以上;
 * 6:签约—结清 60天以上;
 * 7:选址—开业  30天以上;
 * 8:选址—开业 45天以上;
 * 9:选址—开业 60天以上;
 * 10:签约—开业  60天以上;
 * 11:签约—开业  90天以上;
 * 12:签约—开业  120天以上
 */
const processType2Ch: string[] = [
  "",
  "签约—选址 30天以内",
  "签约—选址 30-60天",
  "签约—选址 60天以上",
  "签约—结清 30天以上",
  "签约—结清 45天以上",
  "签约—结清 60天以上",
  "选址—开业 30天以上",
  "选址—开业 45天以上",
  "选址—开业 60天以上",
  "签约—开业 60天以上",
  "签约—开业 90天以上",
  "签约—开业 120天以上"
]

const processPerfix: string[] = ['（签约—选址）', '（选址—开业）', '（签约—结清）', '（签约—开业）']

function showProcessInfos(row: any) {
  const {
    contract_to_selection_day = 0, // 签约到选址天数
    selection_to_start_day = 0, // 选址到开业天数
    contract_to_over_day = 0, // 签约到结清天数
    contract_to_start_day = 0, // 签约到开业天数
  } = row || {}

  const list: any[] = [contract_to_selection_day, selection_to_start_day, contract_to_over_day, contract_to_start_day].map((day: number, index: number) => {
    return `${processPerfix[index]}${day}`
  })

  // console.log(list, 'list')

  return list
}

const defaultForms = {
  contract_time: "", // 签约日期
  site_selection_time: "", // 选址日期
  start_business_time: "", // 开业日期
  pool_delivery_time: "", // 球桌发货时间
  accessory_delivery_time: "", // 配件发货时间
  shop_name: "", // 店铺/客户名称
  sale_way: "", // 销售方式
  shop_status: "", // 店铺状态
  province: "", // 省份
  city: "", // 城市
  customer_address: "", // 客户详细地址
  customer_name: "", // 客户姓名
  phone: "", // 手机号
  team: "", // 所属团队

  contract_person: "", // 签约人

  pool_type_list: [createPoolItemForm(null, 0)],

  gift_pack_price: "", // 大礼包金额

  chess_single_price: "", // 棋牌室单价

  chess_num: "", // 棋牌室数量

  discount_price: "", // 折扣价格

  remark: "", // 备注

  // dataStatistics_time_id: "",
  // singleMaxCountType: '0',
  // shop_id: "",
  // maxLimitCount: ''
};

const defaultUploadImportForm = {
  importFiles: [],
}

// const defaultSearchVenuePageOption = {
//   page: 1,
//   limit: 10,
//   isRearchBottom: false,
// };

const dataStatisticDialogTitle2ch: any = {
  "new-open-shop-order": "新增",
  "edit-open-shop-order": "编辑",
};

// 最大有效期
// const maxExpiredDays = 9999;

// // 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;

// // dataStatistic 开店订单分属类别
// const dataStatisticShopType2ch: any = [
//   ...shareShopType2ch
// ]

@Component({
  filters: {
    showShopStatusName(status: number) {
      return shopStatus2Ch[status] || "";
    },

    showDeliveryStatusName(status: number) {
      return deliveryStatus2Ch[status] || "未知"
    },

    showSaleWayName(way: number) {
      return saleWay2Ch[way] || '未知'
    },

    showFinaceStatusName(status: number) {
      return financeStatus2Ch[status] || '未知'
    },

    showAwaitReceivePriceTypeName(await_re_pr_type: number) {
      return awaitReceivePriceType2Ch[await_re_pr_type] || '未知'
    },

    // showProcessInfoTxt(row: any) {
    //   return showProcessInfos(row).join('\n')
    // }

    // showProcessInfos,

    // createPaymentInfoTxt,
  },
  components: {
    ZlSelect,
    ZlOption,
  },
})
export default class OpenSOrderListManage extends Vue {
  private form: any = {
    // dataStatisticId: "",
    shop_name: "",
    // time: [],
    // startDate: "",
    // endDate: "",
  }
  private forms: any = {
    // maxType: "0",
    // ...defaultForms
  }

  // private showProcessInfos(...args: any[]) {
  //   console.log(args, '...args')
  //   showProcessInfos(args[0])
  // }

  private deepLoopUtil: any = {};

  // private merchantApi: any;

  // private shopTypeList: any[] = [
  //   {
  //     label: "台球",
  //     value: 1,
  //   },
  //   {
  //     label: "棋牌",
  //     value: 2,
  //   },
  // ];

  private openShopOrderListApi = new OpenShopOrderListAPI();
  // private dataStatisticTimeDurationAPI = new OpenSOrderListTimeDurationManageAPI();

  // private chooseWeekDayList: any[] = []

  private shopStatusList: any[] = [
    // {
    //   label: '全部',
    //   value: '',
    // },
    ...getCommonList(shopStatus2Ch, 1),
  ];

  private deliveryStatusList: any[] = [
    ...getCommonList(deliveryStatus2Ch, 1)
  ]

  private saleWayList: any[] = [
    ...getCommonList(saleWay2Ch, 1)
  ]

  private financeStatusList: any[] = [
    ...getCommonList(financeStatus2Ch, 1)
  ]

  private awaitReceivePriceTypeList: any[] = [
    ...getCommonList(awaitReceivePriceType2Ch, 1)
  ]

  private progressTypeList: any[] = [
    ...getCommonList(processType2Ch, 1)
  ]

  // private chooseClueSources: any[] = [];

  // private refreshClueSourceList() {
  //   this.openShopOrderListApi.getClueSourceList(
  //     {},
  //     (res: any) => {
  //       const { list } = res.data || {};

  //       if (Array.isArray(list)) {
  //         this.chooseClueSources = list;
  //       } else {
  //         this.chooseClueSources = [];
  //       }
  //     },
  //     (err: any) => {}
  //   );
  // }
  private commonApi: any;
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.commonApi = new CommonApi();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    this.uploadImportForm = {
      ...this.deepLoopUtil.deepCopy(defaultUploadImportForm, false)
    }

    // this.searchVenuePageOption = {
    //   ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    // };

    // this.chooseWeekDayList = [
    //   ...this.deepLoopUtil.deepCopy(defaultWeekList, false)
    // ]

    // this.merchantApi = new MerchantApi();
  }

  private created(): void {
    // this.refreshClueSourceList();

    this.getOpenSOrderList();

    // this.refreshOpenSOrderListTimeDurations();

    // this.refreshTableClass();
  }
  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
    // this.form = new Util().clearObject(this.form);
    // this.$router.replace("/orderList");
    this.getOpenSOrderList();
  }

  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getOpenSOrderList();
  }

  private tableData: any[] = [];

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";
  // private options: any = [];

  // private clearObject(): void {
  //   this.dialogFormVisible = false;
  //   this.forms = new Util().clearObject(this.forms);
  // }
  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getOpenSOrderList();
  }

  // private allOpenSOrderListTimeDurations: any[] = [];

  // private refreshOpenSOrderListTimeDurations() {
  //   return new Promise((resolve, reject) => {
  //     this.openShopOrderListApi.getOpenSOrderListTimeDurations(
  //       {
  //         page: 1,
  //         limit: 999999,
  //       },
  //       (res: any) => {
  //         const { data: allOpenSOrderListTimeDurationList } = (
  //           res ? res.data : {}
  //         ) as any;

  //         this.allOpenSOrderListTimeDurations = allOpenSOrderListTimeDurationList || [];

  //         resolve(allOpenSOrderListTimeDurationList);
  //       },
  //       (err: any) => {
  //         reject(err);
  //       }
  //     );
  //   });
  // }

  // private allTableCategoryList: any[] = [];

  // private refreshTableClass() {
  //   return new Promise((resolve, reject) => {
  //     this.openShopOrderListApi.poolTableCategory(
  //       {},
  //       (res: any) => {
  //         const { list: allTableCategoryList } = (res ? res.data : {}) as any;
  //         // this.tableData = res.data.list;
  //         // this.tablePage.totalCount = res.data.count;

  //         this.allTableCategoryList = allTableCategoryList;

  //         resolve(allTableCategoryList);
  //       },
  //       (err: any) => {
  //         reject(err);
  //       }
  //     );
  //   });
  // }

  private removeLastPayItem() {
    const forms = this.forms;

    if (Array.isArray(forms.pool_type_list) && forms.pool_type_list.length > 0) {
      forms.pool_type_list.pop();
    }
  }

  private addPayItem(index: number) {
    const forms = this.forms;

    if (Array.isArray(forms.pool_type_list)) {
      forms.pool_type_list.push(createPoolItemForm(null, index + 1));
    }
  }

  private addOrUpdateOpenSOrderListTitle: string = "开店订单";

  private dialogFormVisible: boolean = false;

  // 拉起开店订单弹框
  private async handleWakeOpenSOrderListDialog(mode: string) {
    // if (mode == 'new-open-shop-order') { // 新增开店订单

    // }
    await this.refreshPoolTypeList()
    this.dataStatisticDialogMode = mode;
    this.addOrUpdateOpenSOrderListTitle =
      dataStatisticDialogTitle2ch[mode] || "开店订单";

    this.initOpenSOrderListForm(mode);

    this.dialogFormVisible = true;
  }

  private onGoOpenShopOrderFinance(openShopOrderItem: any) {
    this.$router.push({
      path: '/openShopFinanceList',
      query: {
        id: openShopOrderItem.id
      }
    })
  }

  private currentOpenSOrderListItem: any = null;

  private dataStatisticDialogMode: string = "";

  private async initOpenSOrderListForm(mode: string) {
    const forms: any = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    if (mode == "edit-open-shop-order") {
      // 编辑
      // await this.getMoreVenueList(true);

      // await this.refreshOpenSOrderListTimeDurations();

      // await this.refreshTableClass();

      const currentOpenSOrderListItem =
        this.currentOpenSOrderListItem;

      const {
        id,
        // market_source_id = "",
        contract_time = "",
        site_selection_time = "",
        start_business_time = "",
        pool_delivery_time = "",
        accessory_delivery_time = "",
        shop_name = "",
        sale_way = "",
        shop_status = "",
        province = "",
        city = "",
        customer_address = "",
        customer_name = "",
        phone = "",
        team = "",
        contract_person = "",
        pool_type_list = [],
        gift_pack_price = "",
        chess_single_price = "",
        chess_num = "",
        discount_price = "",
        remark = "",
        // dataStatistics_time_id,
        // first_price = "",
        // second_price = "",
        // third_price = "",
      } = currentOpenSOrderListItem || {};

      Object.assign(forms, {
        // market_source_id: market_source_id
        //   ? Number(market_source_id)
        //   : market_source_id || "",
        contract_time,
        site_selection_time,
        start_business_time,
        pool_delivery_time,
        accessory_delivery_time,
        shop_name,
        sale_way: sale_way ? Number(sale_way) : '',
        shop_status: shop_status ? Number(shop_status) : '',
        province,
        city,
        customer_address,
        customer_name,
        phone,
        team,
        contract_person,
        pool_type_list: pool_type_list.map((item: any, index: number) =>
          createPoolItemForm(item, index)
        ),
        gift_pack_price,
        chess_single_price,
        chess_num,
        discount_price,
        remark,
        // dataStatistics_time_id,
        // first_price,
        // second_price,
        // third_price,
      });

      forms.id = id;
    }

    console.log(forms, "forms");

    // console.log(this.venueList, "this.venueList");

    this.forms = forms;
  }

  private choosePoolList: any[] = []

  private refreshPoolTypeList() {
    return new Promise((resolve, reject) => {
      this.openShopOrderListApi.getPoolTypeList({}, (res: any) => {
        const {
          list = []
        } = res.data || {}

        this.choosePoolList = list
        resolve(res)
      }, (err: any) => {
        reject(err)
      })
    })
  }

  private handleWakeEditOpenSOrderListDialog(dataStatisticItem: any) {
    this.currentOpenSOrderListItem = dataStatisticItem;

    this.handleWakeOpenSOrderListDialog("edit-open-shop-order");
  }

  // private handleDeleteOpenSOrderList(dataStatisticItem: any) {
  //   this.$confirm("确认删除该开店订单？", '', {
  //     ...(shareConfirmOptions || {})
  //   }).then(() => {
  //     this.openShopOrderListApi.deleteOpenSOrderListItem({
  //       id: dataStatisticItem.id
  //     }, () => {
  //       this.getOpenSOrderList();
  //     }, () => {

  //     })
  //   })
  // }

  // private venueList: any[] = [
  //   // {
  //   //   id: 1,
  //   //   label: '测试'
  //   // },
  //   // {
  //   //   id: 2,
  //   //   label: '测试2'
  //   // }
  // ];

  // private searchVenueLoading: boolean = false;

  // private remoteGetVenueQueryString: string = "";

  // private remoteSearchVenueTimer: any = null;

  // private searchVenuePageOption: any = {};

  // private initRemoteGetVenuePageOption() {
  //   this.searchVenuePageOption = {
  //     ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
  //   };
  // }

  // private getSearchVenueParams() {
  //   const searchVenuePageOption = this.searchVenuePageOption;

  //   const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

  //   const { page, limit } = searchVenuePageOption;

  //   const params: any = {
  //     page,
  //     limit,
  //   };

  //   if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
  //     params.name = remoteGetVenueQueryString.trim();
  //   }

  //   return params;
  // }

  // private getMoreVenueList(refresh: boolean = true) {
  //   return new Promise((resolve: Function, reject: Function) => {
  //     if (refresh) {
  //       this.initRemoteGetVenuePageOption();
  //     }

  //     const params = this.getSearchVenueParams();

  //     this.openShopOrderListApi.searchVenueList(
  //       params,
  //       (res: any) => {
  //         console.log(res, "res");

  //         const venueList: any[] = res.data || [];

  //         this.venueList = refresh
  //           ? venueList
  //           : this.venueList.concat(venueList);

  //         if (Array.isArray(res.data) && res.data.length == 0) {
  //           this.searchVenuePageOption.isRearchBottom = true;
  //         }
  //         this.searchVenueLoading = false;

  //         this.addVenueListLoading = false;

  //         resolve();
  //       },
  //       () => {
  //         this.searchVenueLoading = false;

  //         this.addVenueListLoading = false;

  //         reject();
  //       }
  //     );
  //   });
  // }

  // // 第一次搜索 无关键字
  // private handleFirstGetVenueList() {
  //   this.searchVenueLoading = true;

  //   this.getMoreVenueList(true);
  // }

  // // 清空搜索
  // private handleClearGetVenueList() {
  //   this.remoteGetVenueQueryString = "";

  //   this.searchVenueLoading = true;

  //   this.getMoreVenueList(true);
  // }

  // // 远程搜索
  // private remoteGetVenueMethod(query: string) {
  //   query = query.trim ? query.trim() : "";

  //   if (query !== "") {
  //     this.remoteGetVenueQueryString = query;
  //     clearTimeout(this.remoteSearchVenueTimer);

  //     this.remoteSearchVenueTimer = setTimeout(() => {
  //       this.searchVenueLoading = true;

  //       this.getMoreVenueList();
  //       // searchStu((res) => {
  //       //   const {
  //       //     students,
  //       //     total_count
  //       //   } = res || {}

  //       //   this.remoteAllStudents = [
  //       //     ...(students || [])
  //       //   ]

  //       //   this.remoteStudentTotalCount = total_count || 0

  //       //   this.remoteGetStudentLoading = false
  //       // }, {
  //       //   kw: query,
  //       //   ...this.getRemoteGetStudentPageOptionParams()
  //       // }, () => {
  //       //   this.remoteGetStudentLoading = false
  //       // })
  //     }, 300);
  //     // setTimeout(() => {
  //     //   this.remoteGetStudentLoading = false;
  //     //   this.options = this.list
  //     // }, 200);
  //   } else {
  //     this.remoteGetVenueQueryString = "";
  //     this.venueList = [];
  //   }
  // }

  // private addVenueListLoading: boolean = false;

  // private remoteAddVenueMethod() {
  //   if (this.addVenueListLoading) return;
  //   const searchVenuePageOption = this.searchVenuePageOption;

  //   const { isRearchBottom } = searchVenuePageOption;

  //   if (isRearchBottom) return;

  //   this.searchVenuePageOption.page++;

  //   this.addVenueListLoading = true;

  //   this.getMoreVenueList(false);
  // }

  // // 原价限制
  // private handleLimitOriginPrice(val: string) {
  //   Util.limitDigitalnput(
  //     val,
  //     (new_val: string) => {
  //       this.$set(this.forms, "origin_price", new_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "origin_price", new_val);
  //     },
  //     2
  //   );
  // }

  // // 售价限制
  // private handleLimitSlaePrice(val: string) {
  //   Util.limitDigitalnput(
  //     val,
  //     (new_val: string) => {
  //       this.$set(this.forms, "sale_price", new_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "sale_price", new_val);
  //     },
  //     2
  //   );

  // }

  // 有效期
  // private handleLimitExpiredDays(val: string) {
  //   Util.limitIntegetInput(
  //     val,
  //     (new_val: string) => {
  //       const limit_val = Math.min(Number(new_val), maxExpiredDays).toString();
  //       this.$set(this.forms, "expired_days", limit_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "expired_days", new_val);
  //     }
  //   );
  // }

  // // 时长
  // private handleLimitTimeDuration(val: string) {
  //   Util.limitIntegetInput(
  //     val,
  //     (new_val: string) => {
  //       const limit_val = Math.min(Number(new_val), maxTimeDuration).toString();
  //       this.$set(this.forms, "timeDuration", limit_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "timeDuration", new_val);
  //     }
  //   );
  // }

  // private handleLimitSingleMaxCount(val: string) {
  //   Util.limitIntegetInput(
  //     val,
  //     (new_val: string) => {
  //       this.$set(this.forms, "maxLimitCount", new_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "maxLimitCount", new_val);
  //     }
  //   );
  // }

  private sumbitOpenSOrderListLoading: boolean = false;

  // 提交开店订单配置
  private handleSumbitOpenSOrderList() {
    const dataStatisticDialogMode = this.dataStatisticDialogMode;

    const params = this.getOpenSOrderListFormsParams(
      dataStatisticDialogMode
    );

    console.log(params, "params");
    if (params === false) return;

    this.sumbitOpenSOrderListLoading = true;
    switch (dataStatisticDialogMode) {
      case "new-open-shop-order":
        this.openShopOrderListApi.addOpenSOrderList(
          params,
          () => {
            this.getOpenSOrderList();
            this.dialogFormVisible = false;
            this.sumbitOpenSOrderListLoading = false;
          },
          () => {
            this.sumbitOpenSOrderListLoading = false;
          }
        );
        break;
      case "edit-open-shop-order":
        this.openShopOrderListApi.updateOpenSOrderList(
          params,
          () => {
            this.getOpenSOrderList();
            this.dialogFormVisible = false;
            this.sumbitOpenSOrderListLoading = false;
          },
          () => {
            this.sumbitOpenSOrderListLoading = false;
          }
        );
        break;
    }
  }

  private getOpenSOrderListFormsParams(mode: string) {
    const params: any = {};

    const showErrorMessage = (msg: string) => {
      if (!!msg) {
        this.$message.error(msg);
      }

      return !!msg
    };

    const forms = this.forms;

    const {
      id,
      // name,
      // expired_days,
      // meituanId,
      // timeDuration
      // dataStatistics_time_id,
      // type,
      // market_source_id,

      pool_type_list,
    } = forms || {};

    const keys = [
      "contract_time",
      "site_selection_time",
      "start_business_time",
      "pool_delivery_time",
      "accessory_delivery_time",
      "shop_name",
      "sale_way",
      "shop_status",
      "province",
      "city",
      "customer_address",
      "customer_name",
      "phone",
      "team",
      "contract_person",
      "gift_pack_price",
      "chess_single_price",
      "chess_num",
      "discount_price",
      "remark",
      // "first_price",
      // "second_price",
      // "third_price",
    ];

    const key2EndKey: any = {
      contract_time: "contract_time",
      site_selection_time: "site_selection_time",
      start_business_time: "start_business_time",
      pool_delivery_time: "pool_delivery_time",
      accessory_delivery_time: "accessory_delivery_time",
      shop_name: "shop_name",
      sale_way: "sale_way",
      shop_status: "shop_status",
      province: "province",
      city: "city",
      customer_address: "customer_address",
      customer_name: "customer_name",
      phone: "phone",
      team: "team",
      contract_person: "contract_person",
      gift_pack_price: "gift_pack_price",
      chess_single_price: "chess_single_price",
      chess_num: "chess_num",
      discount_price: "discount_price",
      remark: "remark"
      // first_price: "first_price",
      // second_price: "second_price",
      // third_price: "third_price",
    };

    const key2ErrMsg: any = {
      contract_time: "签约日期不能为空",
      // expired_days: "有效期天数不能为空",
      // timeDuration: "时长不能为空",
      // shop_type: "开店订单类型不能为空",
      // expired_days_0: "有效天数不能为零",
      // timeDuration_0: "时长不能为零",
      // meituanId: "美团ID不能为空",
      // quantity: "团购核验次数不能为空",
    };

    Object.assign(params, {
      // type,
      pool_type_list: pool_type_list,
    });


    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (forms[key]) {
        // const errMsg = key2ErrMsg[`${key}_${forms[key]}`];
        // if (errMsg) {
        //   showErrorMessage(errMsg);

        //   return false;
        // }
        params[key2EndKey[key] || key] = forms[key];
      } else {
        if (showErrorMessage(key2ErrMsg[key])) {
          return false;
        }
      }
    }

    // if (typeof dataStatistics_time_id == "number") {
    //   params.dataStatistics_time_id = dataStatistics_time_id;
    // }

    if (mode == "edit-open-shop-order") {
      params.id = id;
    }

    return params;

    // if (name) {
    //   params.name = name
    // } else {
    //   showErrorMessage('名称不能为空')
    // }

    // if (expired_days) {
    //   params.valid_time = expired_days
    // }

    // if (timeDuration) {
    //   params.value = timeDuration
    // }

    // if (meituanId) {

    // }
  }

  // 统计
  private topTotals: any[] = [
    ...createTopTotal()
  ];


  // 模板链接
  private downloadFileUrl: string = ''
  private onDownloadEmptyOpenShopOrder() {
    const downloadFileUrl = this.downloadFileUrl
    
    // console.log(downloadFileUrl, 'downloadFileUrl')

    Util.downloadOtherFlieByNetworkLink(downloadFileUrl)
  }

  private dialogUploadImportVisible: boolean = false
  private uploadImportForm: any = {
    
  }
  private onWakeOpenShopOrderUploadFile() {
    this.uploadImportForm = {
      ...this.deepLoopUtil.deepCopy(defaultUploadImportForm, false)
    }

    this.$nextTick(() => {
      this.dialogUploadImportVisible = true
    })
  }

  private handleUploadCustomFile(file: any, fileList: any[]) {
    this.handleUploadMedia(file, fileList, "file");
  }

  // 删除媒体文件
  private handleFileRemove(file: any, mode: string) {
    const forms = this.uploadImportForm;

    const optionList = forms.importFiles;

    const existRemoveFileIndex = optionList.findIndex(
      (each_file: any) => each_file.uid == file.uid
    );

    if (existRemoveFileIndex > -1) {
      optionList.splice(existRemoveFileIndex, 1);

      this.$set(this.uploadImportForm, `importFiles`, optionList);
    }
  }

  private async handleUploadMedia(
    file: any,
    fileList: any[],
    mode: string
  ) {
    console.log(file, "file");

    const forms: any = this.uploadImportForm;

    const optionList = forms.importFiles;

    const sendMediaApi = this.commonApi.getOssFile

    optionList.push(file);

    console.log(forms, "forms");

    this.$set(this.uploadImportForm, `importFiles`, optionList);

    this.changeFilePropByList(file, mode, { uploading: true });
    sendMediaApi.call(
      this.commonApi,
      file instanceof File ? file : file.raw,
      (res: any) => {
        console.log(res, "res 文件上传成功");

        const { data } = res;

        const { data: uploadFileObj } = data || {};

        const { item: uploadFile } = uploadFileObj || {};

        const { id, url } = uploadFile;

        // this.$set(this.editUserForm, 'avatar_id', id)
        // this.$set(this.editUserForm, 'avatar', url)
        const changeProps: any = {
          uploading: false,
          fileId: id,
          uploadUrl: url,
        };


        this.changeFilePropByList(file, mode, changeProps);
      },
      () => {
        this.$message.error("文件上传成功");

        this.changeFilePropByList(file, mode, {
          uploading: false,
          failed: true,
        });
      },
      {
        name: file.name
      }
    );
  }

  private changeFilePropByList(file: any, mode: string, changes: any = {}) {
    const forms: any = this.uploadImportForm;

    const setList: any[] = forms.importFiles;
    const existFileIndex = setList.findIndex(
      (each_file: any) => each_file.uid == file.uid
    );

    if (existFileIndex > -1) {
      const originFile = setList[existFileIndex];

      if (Array.isArray(setList)) {
        setList.splice(existFileIndex, 1, Object.assign(originFile, changes));
      }

      this.$set(this.uploadImportForm, `importFiles`, setList);
    }
  }

  private uploadImportFileLoading: boolean = false

  private onSumbitUploadImportFile() {
    const uploadImportForm = this.uploadImportForm

    const {
      // venue,
      importFiles
    } = uploadImportForm || {}

    console.log(importFiles, 'importFiles')

    const [file] = importFiles || []

    if (!(file && file.fileId)) {
      this.$message.error('请上传文件')
      return 
    }

    this.uploadImportFileLoading = true
    
    this.openShopOrderListApi.importFileInfo({
      file_id: file.fileId,
    }, () => {
      this.uploadImportFileLoading = false

      this.dialogUploadImportVisible = false

      this.getOpenSOrderList()
    }, () => {
      this.uploadImportFileLoading = false
    })
    
  }

  
  //获取优惠券列表
  private getOpenSOrderList(): void {
    // this.form.startDate = Util.handleStartTimeAndEndTime(
    //   this.form.time,
    //   false
    // ).startTime;
    // this.form.endDate = Util.handleStartTimeAndEndTime(
    //   this.form.time,
    //   false
    // ).endTime;
    this.openShopOrderListApi.getOpenSOrderList(
      { ...this.getOpenSOrderListParams() },
      (res: any) => {
        // res.data.list
        const {
          list = [],
          template_url: downloadFileUrl = ''
        }: any = res.data || {}
        this.tableData = list.map((item: any) => {
          const newItem = {
            ...item
          } 

          newItem._process_infos = showProcessInfos(item)

         return newItem
        });

        this.downloadFileUrl = downloadFileUrl

        // this.stat_price = res.data.stat_price;
        this.tablePage.page = res.data.page;
        this.tablePage.prepage = res.data.prepage;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;

        this.topTotals = [
          ...createTopTotal(res.data)
        ]
      },
      () => {}
    );
  }

  private getOpenSOrderListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    const {
      shop_name,
      contract_person,
      shop_status,
      delivery_status,
      sale_way,
      finance_status,
      await_receive_price_type,
      progress_type,
      sign_time,
      address_time,
      bussiness_time,
     
    } = form;

    if (shop_name) {
      params.shop_name = shop_name;
    }

    if (contract_person) {
      params.contract_person = contract_person
    }

    if (typeof shop_status == 'number') {
      params.shop_status = shop_status;
    }

    if (typeof delivery_status == 'number') {
      params.delivery_status = delivery_status;
    }

    if (typeof sale_way == 'number') {
      params.sale_way = sale_way
    }

    if (typeof finance_status == 'number') {
      params.finance_status = finance_status
    }

    if (typeof await_receive_price_type == 'number') {
      params.await_receive_price_type = await_receive_price_type
    }

    if (typeof progress_type == 'number') {
      params.progress_type = progress_type
    }

    if (Array.isArray(address_time) && address_time.length === 2) {
      const [startDate, endDate] = address_time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      params.site_selection_start_time = startTime;

      params.site_selection_end_time = endTime;
    }

    if (Array.isArray(sign_time) && sign_time.length === 2) {
      const [startDate, endDate] = sign_time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      params.contract_start_time = startTime;

      params.contract_end_time = endTime;
    }

    if (Array.isArray(bussiness_time) && bussiness_time.length === 2) {
      const [startDate, endDate] = bussiness_time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      params.start_business_start_time = startTime;

      params.start_business_end_time = endTime;
    }

    // if (
    //   mode == "exportExcel" &&
    //   !(params.gain_date_start && params.gain_date_end)
    // ) {
    //   this.$message.error("请选择日期导出数据");
    //   return null;
    // }

    if (mode === "all") {
      params.page = page;

      params.limit = prepage;
    }

    return params;
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 订单字段名 文案对应关系
  private orderExcelList2ch: any = {
    contract_time: "签约日期",
    site_selection_time: "选址日期",
    start_business_time: "开业日期",
    shop_name: "店铺/客户",
    _sale_way_name: "销售方式",
    _shop_status_name: "店铺状态",
    _process_info: "进度时间",
    _delivery_status_name: "发货状态",
    _finance_status_name: "财务状态",
    pool_delivery_time: "球桌发货时间",
    accessory_delivery_time: "配件发货时间",
    pool_num: "球桌数量",
    pool_price: "球桌金额",
    gift_pack_price: "大礼包",
    chess_price: "棋牌室金额",
    discount_price: "折扣金额",
    receivable_price: "应收金额",
    received_price: "实收金额",
    await_receive_price: "待收金额",
    contract_person: "签约人",
    remark: "备注"
  };
  // 订单Excel 表头的先后顺序
  private orderExcelSortList: string[] = [
    "contract_time",
    "site_selection_time",
    "start_business_time",
    "shop_name",
    "_sale_way_name",
    "_shop_status_name",
    "_process_info",
    "_delivery_status_name",
    "_finance_status_name",
    "pool_delivery_time",
    "accessory_delivery_time",
    "pool_num",
    "pool_price",
    "gift_pack_price",
    "chess_price",
    "discount_price",
    "receivable_price",
    "received_price",
    "await_receive_price",
    "contract_person",
    "remark"
  ];

  // // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getOpenSOrderListParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.openShopOrderListApi.exportExcelOpenSOrderList(
      params,
      (res: any) => {
        const {
          site_selection_start_time,
          site_selection_end_time,
          contract_start_time,
          contract_end_time,
          start_business_start_time,
          start_business_end_time,
        } = params || {};

        const address_time_duration_name =
          site_selection_start_time && site_selection_end_time
            ? ` 选址时间${site_selection_start_time}到${site_selection_end_time}`
            : "";

        const signing_time_duration_name =
          contract_start_time && contract_end_time
            ? ` 签约时间${contract_start_time}到${contract_end_time}`
            : "";

        const bussiness_time_duration_name =
          start_business_start_time && start_business_end_time
            ? ` 开业时间${start_business_start_time}到${start_business_end_time}`
            : "";
        const filename = `开店订单列表${address_time_duration_name}${signing_time_duration_name}${bussiness_time_duration_name}.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const { 
                sale_way,
                shop_status,
                delivery_status,
                finance_status
              } = item || {};

              const newItem = {
                ...(item || {}),

                _sale_way_name: saleWay2Ch[sale_way] || '',

                _shop_status_name: shopStatus2Ch[shop_status] || '',

                _process_info: sale_way !== 2 ? showProcessInfos(item).join('\n') : '',

                _delivery_status_name: deliveryStatus2Ch[delivery_status] || '',

                _finance_status_name: financeStatus2Ch[finance_status] || ''


                // _bussinessName: shopStatus2Ch[type],
              };

              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const orderExcelSortList = this.orderExcelSortList;

          const orderExcelList2ch = this.orderExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            orderExcelSortList,
            orderExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */
}

import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class AllShopQRChargeAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getAllShopDetailQRList', {
        url: '/admin/shopQrcode/list',
        name: 'list',
        label: '列表'
    }],

    ['downloadQRcodePicUrl', {
      url: '/admin/shopQrcode/download',
      name: 'qrcode-pic-url',
      label: '二维码图片链接'
  }],
])

  // 店铺详情二维码列表
  public getAllShopDetailQRList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getAllShopDetailQRList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 店铺详情二维码图片链接
  public downloadQRcodePicUrl(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('downloadQRcodePicUrl').url

    this.BasicPost(url, {
        ...params
    }, false, false, {
      responseType: 'blob',
    }, successCb, failCb, showLoading)
  }

}
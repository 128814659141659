
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
// import { CouponsManageAPI } from "@/common/api/couponList";
import GuessingGamePraizesRecordAPI from '@/common/api/operationManage/guessingGamePraizesRecord';
 
import { excuteDownloadExport2Excel } from "@/common/xlsx";

import { DeepLoop } from "@/common/deepLoop/deepLoop";

import {
  shareConfirmOptions
} from '@/common/config'

export const pageApiList = getPageAPIs(GuessingGamePraizesRecordAPI)



// const defaultSearchVenuePageOption = {
//   page: 1,
//   limit: 10,
//   isRearchBottom: false,
// };

// const gloryShopQRChargeDialogTitle2ch: any = {
//   "new-sale-machine": "新增售卖机",
//   "edit-sale-machine": "编辑售卖机",
// };

// 最大有效期
// const maxExpiredDays = 9999;

// 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;
// 状态：1提交订单 2已付款 3已取消 4已退款 5退款中
const orderStatus2Ch = [
  '',
  '提交订单',
  '已付款',
  '已取消',
  '已退款',
  '退款中'
]

const maxBenefitRate = 100

@Component({
  components: {},
  filters: {
    getOrderStatusText(status: number) {
      return orderStatus2Ch[status] || ''
    }
  }
})
export default class GuessingGamePraizesRecordManageChargesManage extends Vue {
  private form: any = {
    phone: '',
    chance_rate_type: '', // 胜率
    time: []
  };

  // 胜率 1=60-80  2=80-100 3=100
  private allWinRateList: any[] = [
    {
      label: '60%到80%之间',
      value: 1
    },
    {
      label: '80%到100%之间',
      value: 2
    },
    {
      label: '100%',
      value: 3
    }
  ]

  

  private deepLoopUtil: any = {};


  private guessingGamePraizesRecordApi = new GuessingGamePraizesRecordAPI();

  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

  }

  private created(): void {
    this.getGuessingGamePraizesRecordManageList();
  }
  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getGuessingGamePraizesRecordManageList();
  }
  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getGuessingGamePraizesRecordManageList();
  }

  private tableData: any[] = [];

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";
  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getGuessingGamePraizesRecordManageList();
  }
  
  //获取有奖竞猜列表
  private getGuessingGamePraizesRecordManageList(): void {
    const params: any = this.getGuessingGamePraizesRecordManageParams()
    this.guessingGamePraizesRecordApi.getGuessingGamePraizesRecordList(
      { ...params },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page || params.page;
        this.tablePage.prepage = res.data.prepage || params.limit;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private getGuessingGamePraizesRecordManageParams(mode = "all"): any {
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    const { chance_rate_type, phone, time } = form;

    if (typeof chance_rate_type == 'number') {
      params.chance_rate_type = chance_rate_type;
    }

    if (phone) {
      params.phone = phone
    }

    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      params.start_date = startTime;

      params.end_date = endTime;
    }

    if (
      mode == "exportExcel" &&
      !(params.start_date && params.end_date)
    ) {
      this.$message.error("请选择日期导出数据");
      return null;
    }

    if (mode === "all") {
      if (page) {
        params.page = page;
      }

      if (prepage) {
        params.limit = prepage;
      }

    }

    

    return params;
  }

  // 查看详情
  handleGoGuessingGamePersonalDetail(userInfoItem: any) {
    console.log(userInfoItem, 'userInfoItem')

    this.$router.push({
      path: '/guessingGamePersonalDetail',
      query: {
        user_id: userInfoItem.user_id
      }
    })
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 用户字段名 文案对应关系
  private userExcelList2ch: any = {
    phone: "手机号码",
    nickname: "用户昵称",
    guess_num: "竞猜次数",
    guess_correctly_num: "猜中次数",
    chance_rate: "竞猜胜率（%）",
    address: "地址",
    created_at: "日期",
    
  };
  // 用户Excel 表头的先后顺序
  private userExcelSortList: string[] = [
    "phone",
    "nickname",
    "guess_num",
    "guess_correctly_num",
    "chance_rate",
    "address",
    "created_at"
  ];

  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getGuessingGamePraizesRecordManageParams('exportExcel');

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.guessingGamePraizesRecordApi.exportExcelGuessingGamePraizesRecordTableList(
      params,
      (res: any) => {
        const { start_date, end_date } = params || {};

        const filename = `有奖竞猜记录${start_date}至${end_date}列表.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const {  } = item;
              const newItem = {
                ...(item || {}),
              };


              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const userExcelSortList = this.userExcelSortList;

          const userExcelList2ch = this.userExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            userExcelSortList,
            userExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */
}

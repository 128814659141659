import { BasicsClass } from '@/common/BasicsClass';
export class SummerActRewardPoolAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getSummerActRewardPools', {
            url: '/admin/summer/activityRewardList',
            name: 'list',
            label: '列表'
        }],
        ['addSummerActRewardPools',  {
            url: '/admin/summer/activityRewardAdd',
            name: 'add',
            label: '添加'
        }],
        ['updateSummerActRewardPools',  {
            url: '/admin/summer/activityRewardEdit',
            name: 'edit',
            label: '编辑'
        }],
        ['deleteSummerActRewardPools', {
            url: '/admin/summer/activityRewardDelete',
            name: 'delete',
            label: '删除'
        }],
        ['activityRewardDefault', {
            url: '/admin/summer/activityRewardDefault',
            name: 'activityRewardDefault',
            label: '默认奖励'
        }],
        ['activityRewardDefaultInfo', {
            url: '/admin/summer/activityRewardDefaultInfo',
            name: 'activityRewardDefaultInfo',
            label: '默认奖励详情'
        }],
        ['couponsPlatformList', {
            url: '/admin/couponsPlatform/list',
            name: 'couponsPlatformList',
            label: '平台小时券'
        }]
    ])


    //新增banner
    public addSummerActRewardPools(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('addSummerActRewardPools').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    //修改banner
    public updateSummerActRewardPools(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('updateSummerActRewardPools').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    //删除banner
    public deleteSummerActRewardPools(params: any, successCb: Function, failCb: Function, showLoading: boolean = false): void {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('deleteSummerActRewardPools').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    //优惠券列表
    public getSummerActRewardPools(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getSummerActRewardPools').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    //默认奖励
    public setDefaultReward(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('activityRewardDefault').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }


    //默认奖励详情展示
    public getDefaultRewardInfo(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('activityRewardDefaultInfo').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }


    //平台小时券
    public couponsPlatformList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('couponsPlatformList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }


}
import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class QuickReplyManageAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getQuickReplyManageList', {
      url: '/admin/quickReply/list',
      name: 'list',
      label: '列表'
    }],
    ['updateQuickReplyManage', {
      url: '/admin/quickReply/edit',
      name: 'edit',
      label: '编辑快捷回复'
    }],
    ['addQuickReplyManage', {
      url: '/admin/quickReply/add',
      name: 'add',
      label: '添加快捷回复'
    }],
    ['deleteQuickReplyManage', {
      url: '/admin/quickReply/delete',
      name: 'delete',
      label: '删除快捷回复'
    }],

  ])
  // 快捷回复列表
  public getQuickReplyManageList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getQuickReplyManageList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 修改快捷回复
  public updateQuickReplyManage(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('updateQuickReplyManage').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 添加快捷回复
  public addQuickReplyManage(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('addQuickReplyManage').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 删除快捷回复
  public deleteQuickReplyManage(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('deleteQuickReplyManage').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}
import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class CoachInfoManageApi extends BasicsClass {

    public apiUrls: any = new Map([
        ['getCoachTableList', {
            url: '/admin/coach/manage/list',
            name: 'list',
            label: '列表'
        }],
        ['getCoachMerchantTableList', {
            url: '/admin/coach/manage/shopList',
            name: 'bind-merchant-list',
            label: '绑定商户列表'
        }],
        ['updateCoachInfo', {
            url: '/admin/coach/manage/edit',
            name: 'edit',
            label: '修改助教信息'
        }],
    ])

    // 助教列表
    public getCoachTableList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
      const apiUrls = this.apiUrls

      const url = apiUrls.get('getCoachTableList').url

      this.BasicPost(url, {
          ...params
      }, false, false, false, successCb, failCb, showLoading)
    }

    // 绑定商户列表
    public getCoachMerchantTableList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
      const apiUrls = this.apiUrls

      const url = apiUrls.get('getCoachMerchantTableList').url

      this.BasicPost(url, {
          ...params
      }, false, false, false, successCb, failCb, showLoading)
    }

    // 修改助教信息
    public updateCoachInfo(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
      const apiUrls = this.apiUrls

      const url = apiUrls.get('updateCoachInfo').url

      this.BasicPost(url, {
          ...params
      }, false, false, false, successCb, failCb, showLoading)
    }

}
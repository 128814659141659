import Vue from 'vue';
import Vuex from 'vuex';

import {
  mobile,
  isIOS
} from '@/common/env'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isTimer: false,
    menusButton: '',
    resourceCodes: '',
    changeMenus: '',
    childrenList: '',
    isMobile: mobile,
    isIos: isIOS
  },
  getters: {
    isMobile: (state) => state.isMobile,
    isIos: (state) => state.isIos
  },
  mutations: {
    setIsTimer(state) {
      state.isTimer = true;
    },
    setMenusButton(state, states) {
      state.menusButton = states;
    },
    setResourceCodes(state, states) {
        state.resourceCodes = states;
    },
    setChangeMenus(state, states) {
      console.log(states)
      state.changeMenus = states
    },
    setChildrenList(state, states) {
      console.log(states)
      state.childrenList = states
    }
  },
  actions: {
  },
  modules: {
  },
});


export const inBrowser = typeof window !== "undefined";
export const UA = inBrowser && window.navigator.userAgent.toLowerCase();

export const isAndroid = UA && UA.indexOf('android') > 0
export const isIOS = UA && /iphone|ipad|ipod|ios/.test(UA)

export const WSS_DOMAIN = process.env.VUE_APP_WSS_DOMAIN

export const API_DOMAIN = process.env.NODE_ENV == 'development' ? process.env.VUE_APP_PROXY_URL : process.env.VUE_APP_BASE_API


const devDocumentHost = 'asset.test.pandaball.cc'

const prodDocumentHost = 'asset.pandaball.cc'

export const ASEETS_DOAMIN = `https://${process.env.NODE_ENV == 'development' ? devDocumentHost : prodDocumentHost}`



// This is woefully incomplete. Suggestions for alternative methods welcome.
export const mobile = isIOS || isAndroid || /weboS|blackberry|opera mini|opera mobi|iemobile/.test(UA)
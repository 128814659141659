
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getPageAPIs } from "@/common/util";
// import { CouponsManageAPI } from "@/common/api/couponList";
import GloryShopQRChargeApi from '@/common/api/operationManage/gloryShopQRCharge';
// 
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import ZlSelect from "@/components/third-custom-components/select";

import ZlOption from "@/components/third-custom-components/option";

import {
  shareConfirmOptions
} from '@/common/config'

export const pageApiList = getPageAPIs(GloryShopQRChargeApi)

const defaultForms = {
  machine_number: '', // 设备编码
  fencheng_rate: '', // 分成比例
  venue_id: "",
  venue: {
    id: "",
    name: "",
  },
};

// const defaultSearchVenuePageOption = {
//   page: 1,
//   limit: 10,
//   isRearchBottom: false,
// };

// const gloryShopQRChargeDialogTitle2ch: any = {
//   "new-sale-machine": "新增售卖机",
//   "edit-sale-machine": "编辑售卖机",
// };

// 最大有效期
// const maxExpiredDays = 9999;

// 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;
// 状态：1提交订单 2已付款 3已取消 4已退款 5退款中
const orderStatus2Ch = [
  '',
  '提交订单',
  '已付款',
  '已取消',
  '已退款',
  '退款中'
]

const maxBenefitRate = 100

@Component({
  components: {
    ZlSelect,
    ZlOption,
  },
  filters: {
    getOrderStatusText(status: number) {
      return orderStatus2Ch[status] || ''
    }
  }
})
export default class GloryShopQRChargesManage extends Vue {
  private form: any = {
    // gloryShopQRChargeId: "",
    name: "",
   
    // time: [],
    // startDate: "",
    // endDate: "",
  };

  // private orderStatusList: any[] = [
  //   {
  //     label: '提交订单',
  //     value: 1,
  //   },
  //   {
  //     label: '已付款',
  //     value: 2
  //   },
  //   {
  //     label: '已取消',
  //     value: 3
  //   },
  //   {
  //     label: '已退款',
  //     value: 4
  //   },
  //   {
  //     label: '退款中',
  //     value: 5
  //   }
  // ]

  // private forms: any = {
  //   // maxType: "0",
  //   // ...defaultForms
  // };

  private deepLoopUtil: any = {};

  // private merchantApi: any;

  private gloryShopQRChargeApi = new GloryShopQRChargeApi();

  // private gloryShopQRChargeTimeDurationAPI = new GloryShopQRChargeTimeDurationManageAPI();
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    // this.forms = {
    //   ...this.deepLoopUtil.deepCopy(defaultForms, false),
    // };

    // this.searchVenuePageOption = {
    //   ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    // };

    // this.merchantApi = new MerchantApi();
  }

  private created(): void {
    this.getGloryShopQRList();
  }
  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getGloryShopQRList();
  }
  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getGloryShopQRList();
  }

  private tableData: any[] = [];

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";
  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getGloryShopQRList();
  }


  // private venueList: any[] = [
  //   // {
  //   //   id: 1,
  //   //   label: '测试'
  //   // },
  //   // {
  //   //   id: 2,
  //   //   label: '测试2'
  //   // }
  // ];

  // private searchVenueLoading: boolean = false;

  // private remoteGetVenueQueryString: string = "";

  // private remoteSearchVenueTimer: any = null;

  // private searchVenuePageOption: any = {};

  // private initRemoteGetVenuePageOption() {
  //   this.searchVenuePageOption = {
  //     ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
  //   };
  // }

  // private getSearchVenueParams() {
  //   const searchVenuePageOption = this.searchVenuePageOption;

  //   const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

  //   const { page, limit } = searchVenuePageOption;

  //   const params: any = {
  //     page,
  //     limit,
  //   };

  //   if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
  //     params.name = remoteGetVenueQueryString.trim();
  //   }

  //   return params;
  // }

  // private getMoreVenueList(refresh: boolean = true) {
  //   return new Promise((resolve: Function, reject: Function) => {
  //     if (refresh) {
  //       this.initRemoteGetVenuePageOption();
  //     }

  //     const params = this.getSearchVenueParams();

  //     this.gloryShopQRChargeApi.searchVenueList(
  //       params,
  //       (res: any) => {
  //         console.log(res, "res");

  //         const venueList: any[] = res.data || [];

  //         this.venueList = refresh
  //           ? venueList
  //           : this.venueList.concat(venueList);

  //         if (Array.isArray(res.data) && res.data.length == 0) {
  //           this.searchVenuePageOption.isRearchBottom = true;
  //         }
  //         this.searchVenueLoading = false;

  //         this.addVenueListLoading = false;

  //         resolve();
  //       },
  //       () => {
  //         this.searchVenueLoading = false;

  //         this.addVenueListLoading = false;

  //         reject();
  //       }
  //     );
  //   });
  // }

  // 第一次搜索 无关键字
  // private handleFirstGetVenueList() {
  //   this.searchVenueLoading = true;

  //   this.getMoreVenueList(true);
  // }

  // // 清空搜索
  // private handleClearGetVenueList() {
  //   this.remoteGetVenueQueryString = "";

  //   this.searchVenueLoading = true;

  //   this.getMoreVenueList(true);
  // }

  // // 远程搜索
  // private remoteGetVenueMethod(query: string) {
  //   query = query.trim ? query.trim() : "";

  //   if (query !== "") {
  //     this.remoteGetVenueQueryString = query;
  //     clearTimeout(this.remoteSearchVenueTimer);

  //     this.remoteSearchVenueTimer = setTimeout(() => {
  //       this.searchVenueLoading = true;

  //       this.getMoreVenueList();
       
  //     }, 300);
  //   } else {
  //     this.remoteGetVenueQueryString = "";
  //     this.venueList = [];
  //   }
  // }

  // private addVenueListLoading: boolean = false;

  // private remoteAddVenueMethod() {
  //   if (this.addVenueListLoading) return;
  //   const searchVenuePageOption = this.searchVenuePageOption;

  //   const { isRearchBottom } = searchVenuePageOption;

  //   if (isRearchBottom) return;

  //   this.searchVenuePageOption.page++;

  //   this.addVenueListLoading = true;

  //   this.getMoreVenueList(false);
  // }

  
  //获取棋牌店铺二维码列表
  private getGloryShopQRList(): void {
    const params: any = this.getGloryShopQRParams()
    this.gloryShopQRChargeApi.getGloryShopQRList(
      { ...params },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page || params.page;
        this.tablePage.prepage = res.data.prepage || params.limit;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.total;
      },
      () => {}
    );
  }

  private getGloryShopQRParams(): any {
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    const { name } = form;

    if (name) {
      params.name = name;
    }


    if (page) {
      params.page = page;
    }

    if (prepage) {
      params.limit = prepage;
    }

    return params;
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };
}

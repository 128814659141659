
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Util, getExcelJSONDataByList, getPageAPIs} from "@/common/util";
import {RecordExamineAPIClass} from "@/common/api/operationManage/reportExamine";
import gallery from "@/components/gallery.vue";

import {
  chooseShopTagList, shareConfirmOptions
} from '@/common/config';


export const pageApiList = getPageAPIs(RecordExamineAPIClass);
@Component({
  components: {
    gallery,
  },
})
export default class PandaCoinRecordList extends Vue {
  private recordExamineApi: any;
  private form: any = {
    phone:'',
    report_phone:''
  };
private report_type_object = {
  1:'该用户可能是未成年',
  2:'涉嫌对未成年人实施违法行为',
  3:'传播危害未成年人身心健康内容',
  4:'广告及垃圾信息',
  5:'色情污秽或者低俗内容',
  6:'涉及政治',
  7:'语言侮辱、脏话',
  8:'疑似机器人',
  9:'恶意误导',
  10:'其他',
}
  constructor() {
    super();
    this.recordExamineApi = new RecordExamineAPIClass();
  }

  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    this.recordExamineApi.getRecordExamineTableList(
        {
          ...this.form,
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
        }
    );
  }

  private restForm(): void {
    this.form.phone = undefined
    this.form.report_phone = undefined
  }

  // 删除
  private clickDeletePandaCoin(item: any) {
    this.$confirm(`确定处理举报人${item.report_user_name}？`, '', {
      ...shareConfirmOptions
    }).then(() => {
      this.recordExamineApi.handleReport({
            id: item.id
          },
          () => {
            Util.showToast("处理成功");
            this.search();
          }
      )
    })
  }



  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getPageAPIs } from "@/common/util";
import { DataStatisticRecordAPI } from "@/common/api/dataWholeBoard/dataStatistic";
// import { util } from "vue/types/umd";
import { DeepLoop } from "@/common/deepLoop/deepLoop";

import { CommonApi } from "@/common/api/common";


import {
  shareConfirmOptions,
  shareShopType2ch,
  dataBoardList,
  getBoardListData
} from '@/common/config'

export const pageApiList = getPageAPIs(DataStatisticRecordAPI)


@Component({
  filters: {
    showIncreaseNumber(value: any) {
      if (typeof Number(value) == 'number') {
        const showNumber = Number(value)

        return showNumber >= 0 ? `+${showNumber}` : showNumber
      } else {
        return `+0`
      }
    }
  }
})
export default class DataStatisticRecord extends Vue {

  private dataStatisticApi: any;
  
  constructor() {
    super();

    this.dataStatisticApi = new DataStatisticRecordAPI()
  }

  private dataBoardList: any[] = [
    // ...(dataBoardList || [])
  ]

  private refreshBoardInfo() {
    this.dataStatisticApi.getDataTotalRecords({}, (res: any) => {
      const {
        today,
        yesterday,
        month,
        total
      } = res.data || {}

      this.dataBoardList = getBoardListData(total, today, yesterday, month, dataBoardList)
    }, () => {

    })
  }

  private created(): void {

  }

  private activated() {
    this.refreshBoardInfo()
  }
}


import dayjs from "@/plugins/dayjs";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";

import PandaMobileTable from "@/components/mobile-custom/panda-table.vue";

import PandaMobileTWFContainer from "@/components/mobile-custom/panda-table-with-filter-container.vue";

import { CoachTaxiCostStatisticAPI } from "@/common/api/dataWholeBoard/coachTaxiCostStatistic";

import {
  getCommonList
} from '@/common/config'

import { excuteDownloadExport2Excel } from "@/common/xlsx";



// function getCurrentMonthDate() {
//   const currentDay = (dayjs() as any).format("YYYY-MM");

//   const curMonthStartDay = (dayjs().startOf("month") as any).format("YYYY-MM");

//   return [curMonthStartDay, currentDay];
// }

// function getLastMonthDate() {
//   const lastEndDay = (dayjs().startOf("month") as any).format("YYYY-MM");

//   const lastStartDay = (
//     dayjs().subtract(1, "month").endOf("month") as any
//   ).format("YYYY-MM");

//   return [lastStartDay, lastEndDay];
// }

// console.log(getLastMonthDate(), 'getLastMonthDate')
export const pageApiList = getPageAPIs(CoachTaxiCostStatisticAPI);

@Component({
  components: {
    PandaMobileTable,
    PandaMobileTWFContainer,
  },
  filters: {
    
  },
})
export default class MerchantsAudit extends Vue {
  /** 移动端相关属性 方法 */
  private allMobileChooseFields: any[] = [
    {
      label: "日期",
      field: "taxi_date",
    },
    {
      label: "车费免费（人）",
      field: "taxi_free_person_num",
    },
    {
      label: "车费收费（人）",
      field: "taxi_charge_person_num",
    },
    {
      label: "免车费订单",
      field: "taxi_free_order_num",
    },
    {
      label: "收车费订单",
      field: "taxi_charge_order_num",
    },
    {
      label: "免车费完成订单",
      field: "taxi_free_finish_order_num",
    },
    {
      label: "收车费完成订单",
      field: "taxi_charge_finish_order_num",
    },
  ];
  /** end 移动端相关属性 方法 */

  private chooseFields: string[] = []

  private coachTaxiCostStatisticApi: any;

  constructor() {
    super();
    this.coachTaxiCostStatisticApi = new CoachTaxiCostStatisticAPI();

    const chooseFields: string[] = [];

    const coachTaxiCostExcelList2ch: any = {};

    for (const { field, label } of this.allMobileChooseFields) {
      chooseFields.push(field);

      coachTaxiCostExcelList2ch[field] = label;
    }

    this.coachTaxiCostExcelList2ch = coachTaxiCostExcelList2ch

    this.chooseFields = chooseFields;
  }

  private get showTableFields() {
    const confirmChooseFields = this.chooseFields;

    const showListFields: any[] =
      this.allMobileChooseFields.filter(({ field }) => {
        return confirmChooseFields.includes(field);
      }) || [];

    return showListFields;
  }

  private form: any = {
    // shop_name: '',
    // pool_id: '', // 球桌ID
    // resource_id: '', // 按钮ID
    // subject_id: '', // 开关ID
    time: [
      // ...getLastMonthDate()
    ],
  };

  // private pickerOptions: any = {
  //   shortcuts: [
  //     // {
  //     //   text: '本月',
  //     //   onClick(picker: any) {

  //     //     picker.$emit('pick', getCurrentMonthDate());
  //     //   }
  //     // },
  //     // {
  //     //   text: "上月",
  //     //   onClick(picker: any) {
  //     //     picker.$emit("pick", getLastMonthDate());
  //     //   },
  //     // },
  //   ],
  // };

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private activated(): void {
    // if (this.$route.query.name) {
    //   this.form.shop_name = this.$route.query.name;
    // }
    this.search();
  }

  private getTable(): void {
    this.coachTaxiCostStatisticApi.taxiCostList(
      {
        ...this.getCoachTaxiCostTableListParams(),
      },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.totalCount = res.data.count;
      }
    );
  }

  private getCoachTaxiCostTableListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const {
      // shop_name, // 店铺名称
      // pool_id,
      // resource_id,
      // subject_id,
      time,
    } = form || {};

    // console.log(technicianItem, "technicianItem");

    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};

    // if (shop_name) {
    //   outerParams.shop_name = shop_name;
    // }

    // if (pool_id) {
    //   outerParams.pool_id = pool_id
    // }

    // if (resource_id) {
    //   outerParams.resource_id = resource_id
    // }

    // if (subject_id) {
    //   outerParams.subject_id = subject_id
    // }

    if (Array.isArray(time) && time.length === 2) {
      const [start_time, end_time] = time;

      outerParams.start_date = start_time;

      outerParams.end_date = end_time;
    }

    if (
      mode == "exportExcel" &&
      !(outerParams.start_date && outerParams.end_date)
    ) {
      this.$message.error("请选择日期时段导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;
    } else if (mode == "exportExcel") {
      // outerParams.is_export = 1
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 商户字段名 文案对应关系
  private coachTaxiCostExcelList2ch: any = {
    // date: "日期",
    // member_recharge: "会员充值金额",
    // member_consume: "会员消费金额",
    // shop_member_price: "单店卡充值",
    // shop_member_consume: "单店卡消费",
    // member_money: "会员账户余额",
    // member_recharge_income: "会员充值分佣金额",
    // merchant_income: "商家最终收益",
    // entry_fee: "报名费",
    // product_sales_price: "商品销售",
    // merchant_account_balance: "商家账户余额",
    // merchant_withdrawal_money: "商家提现金额",
    // voucher_income: "优惠券销售",
    // meituan_price: "美团券核销金额",
    // douyin_price: "抖音券核销金额",
    // kuaishou_price: "快手券核销金额",
    // sport_price: "体育券核销金额",
    // meituan_shop_member_price: "美团单店卡",
    // coach_income: "本月教练收益",
    // assistant_income: "线下助教收益",
    // wechat_price: "微信充值",
    // alipay_price: "支付宝充值",
    // billiards_consume_price: "台球消费",
    // chess_consume_price: "棋牌消费",
    // assistant_consume_price: "助教消费",
  };
  // 商户Excel 表头的先后顺序
  private coachTaxiCostExcelSortList: string[] = [
    // "date",
    // "member_recharge",
    // "member_consume",
    // "shop_member_price",
    // "shop_member_consume",
    // "member_money",
    // "member_recharge_income",
    // "merchant_income",
    // "entry_fee",
    // "product_sales_price",
    // "merchant_account_balance",
    // "merchant_withdrawal_money",
    // "voucher_income",
    // "meituan_price",
    // "douyin_price",
    // "kuaishou_price",
    // "sport_price",
    // "meituan_shop_member_price",
    // "coach_income",
    // "assistant_income",
    // "wechat_price",
    // "alipay_price",
    // "billiards_consume_price",
    // "chess_consume_price",
    // "assistant_consume_price",
  ];

  // 点击导出excel 表格
  private handleExportExcelCoachTaxiCostList() {
    this.getTable();
    const params: any = this.getCoachTaxiCostTableListParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.coachTaxiCostExcelSortList = [
      ...(this.chooseFields || [])
    ]

    this.downloadExcelLoading = true;
    this.coachTaxiCostStatisticApi.exportExcelCoachTaxiCostList(
      params,
      (res: any) => {
        const { start_date, end_date } = params || {};

        const filename = `助教车费数据统计列表 ${start_date}到${end_date}.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const { orderNum } = item || {};
              const newItem = {
                ...(item || {}),
              };

              newItem.orderNum =
                typeof orderNum == "number"
                  ? orderNum.toString()
                  : orderNum || "";

              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const coachTaxiCostExcelSortList = this.coachTaxiCostExcelSortList;

          const coachTaxiCostExcelList2ch = this.coachTaxiCostExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            coachTaxiCostExcelSortList,
            coachTaxiCostExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        // const testData = [
        //   { 主页: "test1223424", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
        //   { 主页: "433", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
        //   { 名称: "22", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
        //   { 名称: "43", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
        //   { 店家: "43", 价格: "6800", 数量: "6800", 昵称: "广告主网", }
        // ]

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */

  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}

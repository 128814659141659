import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

import { CommonApi } from "../common";

export class MemberConsumeApi extends BasicsClass {
  public apiUrls: any = new Map([
    ['getMemberConsumeRecordList', {
      url: '/admin/member/decreaseMemberList',
      name: 'list',
      label: '列表'
    }],
    ['exportExcelMemberConsumeList', {
      url: '/admin/member/exportDecreaseMemberList',
      name: 'export-excel',
      label: '导出'
    }],
    ['customDecreaseMemberConsume', {
      url: '/admin/member/decreaseMember',
      name: 'custom-decrease-member-consume',
      label: '自定义会员扣款'
    }],
  ])
  // 订单api 方法
  // 全国会员扣款记录列表
  public getMemberConsumeRecordList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getMemberConsumeRecordList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

 
  // 导出全国会员扣款记录列表
  public exportExcelMemberConsumeList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('exportExcelMemberConsumeList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 自定义全国会员扣款
  public customDecreaseMemberConsume(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('customDecreaseMemberConsume').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}

/** 注意： meta  key 一旦确定不可修改 且 同级唯一。 是作为识别这个对象的唯一标识！！！   */

// 官网管理
export const officialWebManageRoutes = [
  // marketDepartDataStatistic
  {
    path: "/shopInformationCollect",
    name: "shopInformationCollect",
    meta: {
      title: "店铺信息收集",
      key: 'shop-information-collect',
      pageApisPrm: import("@/views/officialWebManage/shopInformationCollect.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "officialWebManage" */ "@/views/officialWebManage/shopInformationCollect.vue"
      ),
  },
  // recruitPartnerContactList
  {
    path: "/recruitPartnerContactList",
    name: "recruitPartnerContactList",
    meta: {
      title: "招募合伙人联系信息",
      key: 'recruit-partner-contact-list',
      pageApisPrm: import("@/views/officialWebManage/recruitPartnerContactList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "officialWebManage" */ "@/views/officialWebManage/recruitPartnerContactList.vue"),
  },
  // websitePandaContactList
  {
    path: "/websitePandaContactList",
    name: "websitePandaContactList",
    meta: {
      title: "熊猫官网联系方式",
      key: 'panda-website-contact-list',
      pageApisPrm: import("@/views/officialWebManage/websitePandaContactList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "officialWebManage" */ "@/views/officialWebManage/websitePandaContactList.vue"),
  },
  // websiteMilexContactList
  {
    path: "/websiteMilexContactList",
    name: "websiteMilexContactList",
    meta: {
      title: "麦力士官网联系方式",
      key: 'milex-website-contact-list',
      pageApisPrm: import("@/views/officialWebManage/websiteMilexContactList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "officialWebManage" */ "@/views/officialWebManage/websiteMilexContactList.vue"),
  },
]


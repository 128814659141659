/** 注意： meta  key 一旦确定不可修改 且 同级唯一。 是作为识别这个对象的唯一标识！！！   */

// 商家管理
export const merchantManageRoutes = [
  {
    path: "/merchantsList",
    name: "merchantsList",
    meta: {
      title: "商户列表",
      key: 'merchant-list',
      pageApisPrm: import("@/views/merchantManage/merchantsList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
    /* webpackChunkName: "merchantManage" */ "@/views/merchantManage/merchantsList.vue"
      ),
  },
  {
    path: "/molisMerchantList",
    name: "molisMerchantList",
    meta: {
      title: "麦力士店铺",
      key: 'molis-merchant-list',
      pageApisPrm: import("@/views/merchantManage/molisMerchantList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
    /* webpackChunkName: "merchantManage" */ "@/views/merchantManage/molisMerchantList.vue"
      ),
  },
  {
    path: "/rechargeRecord",
    name: "rechargeRecord",
    meta: {
      title: "店铺信息",
      key: 'venue-info-list',
      pageApisPrm: import("@/views/merchantManage/rechargeRecord.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "merchantManage" */ "@/views/merchantManage/rechargeRecord.vue"
      ),
  },
  {
    path: "/mutipleShopApplySameAuth",
    name: "mutipleShopApplySameAuth",
    meta: {
      title: "美团多店铺授权",
      key: 'mutiple-shop-apply-same-auth',
      pageApisPrm: import("@/views/merchantManage/mutipleShopApplySameAuth.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "merchantManage" */ "@/views/merchantManage/mutipleShopApplySameAuth.vue"
      ),
  },
  // mutipleAuthSubShopList
  {
    path: "/mutipleAuthSubShopList",
    name: "mutipleAuthSubShopList",
    meta: {
      title: "美团绑定授权子店铺列表",
      asideHidden: true, // 侧边栏不显示
      key: 'mutiple-auth-sub-shop-list',
      pageApisPrm: import("@/views/merchantManage/mutipleAuthSubShopList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "merchantManage" */ "@/views/merchantManage/mutipleAuthSubShopList.vue"
      ),
  },
  {
    path: "/withhold",
    name: "withhold",
    meta: {
      title: "球桌信息",
      asideHidden: true, // 侧边栏不显示
      key: 'ball-table-info-list',
      pageApisPrm: import("@/views/merchantManage/withhold.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "merchantManage" */ "@/views/merchantManage/withhold.vue"
      ),
  },
  // doorBlackList
  {
    path: "/doorBlackList",
    name: "doorBlackList",
    meta: {
      title: "门禁黑名单",
      asideHidden: true, // 侧边栏不显示
      key: 'door-black-list',
      pageApisPrm: import("@/views/merchantManage/doorBlackList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "merchantManage" */ "@/views/merchantManage/doorBlackList.vue"
      ),
  },
  // merchantOperationerList
  {
    path: "/merchantOperationerList",
    name: "merchantOperationerList",
    meta: {
      title: "绑定运营",
      asideHidden: true, // 侧边栏不显示
      key: 'merchant-operationer-list',
      pageApisPrm: import("@/views/merchantManage/merchantOperationerList.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "merchantManage" */ "@/views/merchantManage/merchantOperationerList.vue"
      ),
  },
  {
    path: "/signboardLightManage",
    name: "signboardLightManage",
    meta: {
      title: "招牌灯",
      asideHidden: true, // 侧边栏不显示
      key: 'sign-board-light-list',
      pageApisPrm: import("@/views/merchantManage/signboardLightManage.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "merchantManage" */ "@/views/merchantManage/signboardLightManage.vue"
      ),
  },
  {
    path: "/withholdChess",
    name: "withholdChess",
    meta: {
      title: "包厢信息",
      asideHidden: true, // 侧边栏不显示
      key: 'cage-info-list',
      pageApisPrm: import("@/views/merchantManage/withholdChess.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "merchantManage" */ "@/views/merchantManage/withholdChess.vue"
      ),
  },
  {
    path: "/device",
    name: "device",
    meta: {
      title: "其他设备",
      asideHidden: true, // 侧边栏不显示
      key: 'other-device-info-list',
      pageApisPrm: import("@/views/merchantManage/device.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "merchantManage" */ "@/views/merchantManage/device.vue"
      ),
  },
  {
    path: "/air_config",
    name: "air_config",
    meta: {
      title: "空调",
      asideHidden: true, // 侧边栏不显示
      key: 'air-config-info-list',
      pageApisPrm: import("@/views/merchantManage/airConfig.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "merchantManage" */ "@/views/merchantManage/airConfig.vue"
      ),
  },
  {
    path: "/air_config_set",
    name: "air_config_set",
    meta: {
      title: "空调列表",
      asideHidden: true, // 侧边栏不显示
      key: 'air-config-set-info-list',
      pageApisPrm: import("@/views/merchantManage/airConfigSet.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(
        /* webpackChunkName: "merchantManage" */ "@/views/merchantManage/airConfigSet.vue"
      ),
  },
]
/** 注意： meta  key 一旦确定不可修改 且 同级唯一。 是作为识别这个对象的唯一标识！！！   */
// 审核管理
export const auditManageRoutes = [
  // gloryOrderCageTypeAudit
  {
    path: "/gloryOrderCageTypeAudit",
    name: "gloryOrderCageTypeAudit",
    meta: {
      title: "棋牌分类审核",
      key: 'glory-order-cage-type-audit',
      pageApisPrm: import("@/views/auditManage/gloryOrderCageTypeAudit.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "auditManage" */ "@/views/auditManage/gloryOrderCageTypeAudit.vue"),
  },
  {
    path: "/goodsRefundAudit",
    name: "goodsRefundAudit",
    meta: {
      title: "商品退款审核",
      key: 'goods-refund-audit',
      pageApisPrm: import("@/views/auditManage/goodsRefundAudit.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "auditManage" */ "@/views/auditManage/goodsRefundAudit.vue"),
  },
  {
    path: "/coachActualPersonAudit",
    name: "coachActualPersonAudit",
    meta: {
      title: "助教真人审核",
      key: 'coach-actual-person-info-audit',
      pageApisPrm: import("@/views/auditManage/coachActualPersonAudit.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "auditManage" */ "@/views/auditManage/coachActualPersonAudit.vue"),
  },
  {
    path: "/coachRegisterInfoAudit",
    name: "coachRegisterInfoAudit",
    meta: {
      title: "助教入驻审核",
      key: 'coach-register-info-audit',
      pageApisPrm: import("@/views/auditManage/coachRegisterInfoAudit.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "auditManage" */ "@/views/auditManage/coachRegisterInfoAudit.vue"),
  },
  {
    path: "/coachBaseInfoAudit",
    name: "coachBaseInfoAudit",
    meta: {
      title: "助教基本资料审核",
      key: 'coach-base-info-audit',
      pageApisPrm: import("@/views/auditManage/coachBaseInfoAudit.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "auditManage" */ "@/views/auditManage/coachBaseInfoAudit.vue"),
  },
  {
    path: "/coachUploadMediaAudit",
    name: "coachUploadMediaAudit",
    meta: {
      title: "照片视频审核",
      key: 'coach-upload-media-audit',
      pageApisPrm: import("@/views/auditManage/coachUploadMediaAudit.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "auditManage" */ "@/views/auditManage/coachUploadMediaAudit.vue"),
  },
  {
    path: "/feedback",
    name: "feedback",
    meta: {
      title: "意见反馈",
      key: 'feedback-manage',
      pageApisPrm: import("@/views/auditManage/feedback.vue").then(({ pageApiList }: any) => pageApiList)
    },
    component: () =>
      import(/* webpackChunkName: "auditManage" */ "@/views/auditManage/feedback.vue"),
  },
]


import { Component, Model, Prop, Vue, Watch } from "vue-property-decorator";

import { CommonApi } from "@/common/api/common";
import RecruitPartnerContactAPI from "@/common/api/officialWebManage/recruitPartnerContactList";

const recruitPartnerInterval = 10 * 1000 // 10秒间隔

@Component
export default class LinkSelect extends Vue {
  // @Prop({
  //   type: Array,
  //   validator: function (val: any[]): boolean {
  //     // console.log(val, 'val')
  //     const pureVals = val.map((item) => item.value);

  //     // console.log(pureVals, 'pureVals')

  //     // 不可有相同value item 项
  //     return Array.from(new Set(pureVals)).length === pureVals.length;
  //   },
  //   default: (): any[] => [
  //     {
  //       name: "test1",
  //       value: 1,
  //     },
  //     {
  //       name: "test2",
  //       value: 2,
  //     },
  //   ],
  // })
  // private list: any;

  // @Model("chooseVal", {
  //   type: Array,
  //   default: () => [],
  // })
  // private value: any;

  // private cutValue: any[] = [];

  private notifyNum: number = 0;

  private commonApi: any;

  private recruitPartnerContactApi: any;

  private availableInvestment: boolean = false

  private recruitPartnerList: any[] = []

  private recruitPartnerPopover: boolean = false

  constructor() {
    super()

    this.commonApi = new CommonApi();

    this.recruitPartnerContactApi = new RecruitPartnerContactAPI();

    // this.cutValue = []
  }

  private created() {
    this.commonApi.getRecruitPartnerAuth({}, (res: any) => {
      const {
        investment
      } = res.data || {}

      this.availableInvestment = !!investment

      if (investment) {
        this.refreshInvestment()
      }
    }, () => {

    })
  }

  private mounted() {
    const handlePopoverClick = () => {
      this.recruitPartnerPopover = false
    }

    document.documentElement.addEventListener('click', handlePopoverClick)

    this.$once('hook:beforeDestroy', () => {
      document.documentElement.removeEventListener('click', handlePopoverClick)
    })
  }

  private refreshInvestment() {
    this.recruitPartnerContactApi.getRecruitPartnerContactList({
      page: 1,
      limit: 10,
      status: 0, // 状态：1已联系 0未联系
    }, (res: any) => {
      const {
        list,
        count
      } = res.data

      if (Array.isArray(list)) {
        this.recruitPartnerList = list

        this.notifyNum = count
      } else {
        this.recruitPartnerList = []
        this.notifyNum = 0
      }


      setTimeout(() => {
        this.refreshInvestment()
      }, recruitPartnerInterval)
    }, () => {

    })
  }

  private handleClickCell(recruiteNotifyItem: any) {
    this.recruitPartnerPopover = false
    this.$router.push({
      path: '/recruitPartnerContactList'
    })
  }

 
}


import SVGA, { Player, Parser
 } from "svgaplayerweb";

import { Component, Model, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class PandaSVGA extends Vue {
  @Prop({
    type: String,
    default: "",
  })
  private src!: string;

  @Prop({
    type: Number,
    default: 0,
  })
  private loops!: number;

  @Prop({
    type: String,
    default: "Forward", // optional Forward / Backward，fillMode = Forward，Animation will pause on last frame while finished，fillMode = Backward , Animation will pause on first frame.
  })
  private fillMode!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  private clearsAfterStop!: boolean;

  // @Model("chooseVal", {
  //   type: Array,
  //   default: () => [],
  // })
  // private value: any;

  private get pandaSvgaCvsEl() {
    return this.$refs.pandaSvgaCvs;
  }

  constructor() {
    super();
  }

  private created() {}

  private mounted() {
    this.initSvgaIns();
  }

  @Watch("src")
  $srcChange(newValue: string, oldValue: string) {
    this.initSvgaIns();
  }

  private player: any = null;

  private parser: any = null;

  private refreshSvgaIns() {
    this.player = new Player(this.pandaSvgaCvsEl as HTMLDivElement);
    this.parser = new Parser();

    const svgaSrc = this.src;

    // console.log(svgaSrc, "svgaSrc");
    // console.log(SVGA, 'SVGA')
    // console.log(this.parser, "this.parser");

    this.parser.load(svgaSrc, (videoItem: any) => {
      this.player.setVideoItem(videoItem);
      this.player.startAnimation();

      console.log(this.parser, "this.parser");
      this.$emit("load", {
        player: this.player,
        parser: this.parser,
        videoItem,
      });
    });
  }

  private initSvgaIns() {
    this.refreshSvgaIns();
  }
}

import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class CoachLeaderManageAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getCoachLeaderManageList', {
      url: '/admin/coachLeader/index',
      name: 'list',
      label: '列表'
    }],
    ['addCoachLeaderManage', {
      url: '/admin/coachLeader/add',
      name: 'add-leader',
      label: '添加助教队长'
    }],
    ['updateCoachLeaderManage', {
      url: '/admin/coachLeader/edit',
      name: 'edit-leader',
      label: '编辑助教队长'
    }],
    ['deleteCoachLeaderManage', {
      url: '/admin/coachLeader/del',
      name: 'delete-leader',
      label: '删除助教队长'
    }],
  
   
  ])

  // 运营提醒列表
  public getCoachLeaderManageList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getCoachLeaderManageList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 修改运营提醒
  public updateCoachLeaderManage(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('updateCoachLeaderManage').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 添加运营提醒
  public addCoachLeaderManage(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('addCoachLeaderManage').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 删除台球柜
  public deleteCoachLeaderManage(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('deleteCoachLeaderManage').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  
}

import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
// import { CouponsManageAPI } from "@/common/api/couponList";
import AllianceBusinessListAPI from '@/common/api/userManage/allianceBusinessList';
import { excuteDownloadExport2Excel } from "@/common/xlsx";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import ZlSelect from "@/components/third-custom-components/select";

import ZlOption from "@/components/third-custom-components/option";

import {
  shareConfirmOptions
} from '@/common/config'

export const pageApiList = getPageAPIs(AllianceBusinessListAPI)

const defaultForms = {
  cabinet_id: '', // 设备编码
  venue_id: "",
  venue: {
    id: "",
    name: "",
  },
  // singleMaxCountType: '0',
  // shop_id: "",
  // maxLimitCount: ''
};

const defaultSearchVenuePageOption = {
  page: 1,
  limit: 10,
  isRearchBottom: false,
};

const allianceBusinessDialogTitle2ch: any = {
  "new-storage-locker": "新增",
  "edit-storage-locker": "编辑",
};

// 最大有效期
// const maxExpiredDays = 9999;

// 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;

const maxBenefitRate = 100

@Component({
  components: {
    ZlSelect,
    ZlOption,
  },
})
export default class AllianceBusinesssManage extends Vue {
  private form: any = {
    // allianceBusinessId: "",
    shop_name: "",
    time: []
    // shop_id: '',
    // time: [],
    // startDate: "",
    // endDate: "",
  };
  private forms: any = {
    // maxType: "0",
    // ...defaultForms
  };

  private deepLoopUtil: any = {};

  // private merchantApi: any;

  private allianceBusinessListApi = new AllianceBusinessListAPI();

  private get currentUserId() {
    const route = this.$route

    const {
      query
    } = route || {}

    const {
      user_id
    } = query || {}

    return user_id || ''
  }

  // private allianceBusinessTimeDurationAPI = new AllianceBusinessTimeDurationManageAPI();
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    this.searchVenuePageOption = {
      ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    };

    // this.merchantApi = new MerchantApi();
  }

  
  private created(): void {

    // this.refreshAllianceBusinessTimeDurations();

    // this.refreshTableClass();
  }

  private activated() {
    this.getAllianceBusinesss();
  }
  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getAllianceBusinesss();
  }
  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getAllianceBusinesss();
  }

  private tableData: any[] = [];

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";
  
  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getAllianceBusinesss();
  }

  private addOrUpdateAllianceBusinessTitle: string = "绑定加盟商";

  private dialogFormVisible: boolean = false;

  // 拉起加盟商
  private handleWakeAllianceBusinessDialog(mode: string) {
    // if (mode == 'new-storage-locker') { // 新增球杆柜

    // }
    // this.allianceBusinessDialogMode = mode;
    // this.addOrUpdateAllianceBusinessTitle = allianceBusinessDialogTitle2ch[mode] || "球杆柜";

    this.initAllianceBusinessForm(mode);

    this.dialogFormVisible = true;
  }

  // private currentAllianceBusinessItem: any = null;

  // private allianceBusinessDialogMode: string = "";

  private async initAllianceBusinessForm(mode: string) {
    const forms: any = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };


    console.log(forms, "forms");

    // console.log(this.venueList, "this.venueList");

    this.forms = forms;
  }

  // private handleWakeEditAllianceBusinessDialog(allianceBusinessItem: any) {
    // this.currentAllianceBusinessItem = allianceBusinessItem;

  //   this.handleWakeAllianceBusinessDialog("edit-storage-locker");
  // }

  private handleUnbindAllianceBusinessDialog(allianceBusinessItem: any) {
    this.$confirm("确定解绑该加盟商?", "", {
      ...shareConfirmOptions,
    }).then(() => {
      this.allianceBusinessListApi.unbindAllianceBusinessShop({
        id: allianceBusinessItem.id
      }, () => {
        this.getAllianceBusinesss()
      }, () => {

      })
    });
  }

  // // 跳转球杆柜管理列表
  // private handleGoLockerManageList(allianceBusinessItem: any) {
  //   console.log(allianceBusinessItem, 'allianceBusinessItem')

  //   this.$router.push({
  //     path: '/lockerDoorList',
  //     query: {
  //       sbid: allianceBusinessItem.id
  //     }
  //   })
  // }


  /** 该页面独有参数 */
  private limitAllianceBusinessShopType: number = 1;
  /** end 该页面独有参数 */

  private venueList: any[] = [
    // {
    //   id: 1,
    //   label: '测试'
    // },
    // {
    //   id: 2,
    //   label: '测试2'
    // }
  ];

  private searchVenueLoading: boolean = false;

  private remoteGetVenueQueryString: string = "";

  private remoteSearchVenueTimer: any = null;

  private searchVenuePageOption: any = {};

  private initRemoteGetVenuePageOption() {
    this.searchVenuePageOption = {
      ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    };
  }

  private getSearchVenueParams() {
    const limitShopType = this.limitAllianceBusinessShopType;

    const searchVenuePageOption = this.searchVenuePageOption;

    const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

    const { page, limit } = searchVenuePageOption;

    const params: any = {
      page,
      limit,
    };

    if (typeof limitShopType == 'number') {
      params.shop_type = limitShopType
    }

    if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
      params.name = remoteGetVenueQueryString.trim();
    }

    return params;
  }

  private getMoreVenueList(refresh: boolean = true) {
    return new Promise((resolve: Function, reject: Function) => {
      if (refresh) {
        this.initRemoteGetVenuePageOption();
      }

      const params = this.getSearchVenueParams();

      this.allianceBusinessListApi.searchVenueList(
        params,
        (res: any) => {
          console.log(res, "res");

          const venueList: any[] = res.data || [];

          this.venueList = refresh
            ? venueList
            : this.venueList.concat(venueList);

          if (Array.isArray(res.data) && res.data.length == 0) {
            this.searchVenuePageOption.isRearchBottom = true;
          }
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          resolve();
        },
        () => {
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          reject();
        }
      );
    });
  }

  // 第一次搜索 无关键字
  private handleFirstGetVenueList() {
    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 清空搜索
  private handleClearGetVenueList() {
    this.remoteGetVenueQueryString = "";

    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 远程搜索
  private remoteGetVenueMethod(query: string) {
    query = query.trim ? query.trim() : "";

    if (query !== "") {
      this.remoteGetVenueQueryString = query;
      clearTimeout(this.remoteSearchVenueTimer);

      this.remoteSearchVenueTimer = setTimeout(() => {
        this.searchVenueLoading = true;

        this.getMoreVenueList();
        
      }, 300);
    } else {
      this.remoteGetVenueQueryString = "";
      this.venueList = [];
    }
  }

  private addVenueListLoading: boolean = false;

  private remoteAddVenueMethod() {
    if (this.addVenueListLoading) return;
    const searchVenuePageOption = this.searchVenuePageOption;

    const { isRearchBottom } = searchVenuePageOption;

    if (isRearchBottom) return;

    this.searchVenuePageOption.page++;

    this.addVenueListLoading = true;

    this.getMoreVenueList(false);
  }

  // 分成比例
  // private handleLimitBenefitRate(val: string) {
  //   Util.limitIntegetInput(
  //     val,
  //     (new_val: string) => {
  //       const limit_val = Math.min(Number(new_val), maxBenefitRate).toString();
  //       this.$set(this.forms, "fencheng_rate", limit_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "fencheng_rate", new_val);
  //     }
  //   );
  // }

  // private handleLimitSingleMaxCount(val: string) {
  //   Util.limitIntegetInput(
  //     val,
  //     (new_val: string) => {
  //       this.$set(this.forms, "maxLimitCount", new_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "maxLimitCount", new_val);
  //     }
  //   );
  // }

  private sumbitAllianceBusinessLoading: boolean = false;

  // 提交绑定 加盟商
  private handleSumbitAllianceBusiness() {
    // const allianceBusinessDialogMode = this.allianceBusinessDialogMode;

    const params = this.getAllianceBusinessFormsParams();

    console.log(params, "params");
    if (params === false) return;

    this.sumbitAllianceBusinessLoading = true;
    this.allianceBusinessListApi.bindAllianceBusinessShop(
      params,
      () => {
        this.getAllianceBusinesss();
        this.dialogFormVisible = false;
        this.sumbitAllianceBusinessLoading = false;
      },
      () => {
        this.sumbitAllianceBusinessLoading = false;
      }
    )
    // switch (allianceBusinessDialogMode) {
    //   case "new-storage-locker":
    //     this.allianceBusinessListApi.addAllianceBusiness(
    //       params,
    //       () => {
    //         this.getAllianceBusinesss();
    //         this.dialogFormVisible = false;
    //         this.sumbitAllianceBusinessLoading = false;
    //       },
    //       () => {
    //         this.sumbitAllianceBusinessLoading = false;
    //       }
    //     );
    //     break;
    //   case "edit-storage-locker":
    //     this.allianceBusinessListApi.updateAllianceBusiness(
    //       params,
    //       () => {
    //         this.getAllianceBusinesss();
    //         this.dialogFormVisible = false;
    //         this.sumbitAllianceBusinessLoading = false;
    //       },
    //       () => {
    //         this.sumbitAllianceBusinessLoading = false;
    //       }
    //     );
    //     break;
    // }
  }

  private getAllianceBusinessFormsParams() {
    const params: any = {
      user_id: this.currentUserId
    };

    const showErrorMessage = (msg: string) => {
      if (!!msg) {
        this.$message.error(msg);
      }
    };

    const forms = this.forms;

    const {
      // id,
      // name,
      // expired_days,
      // meituanId,
      // timeDuration
      // machine_number,
      // cabinet_id,
      venue,
    } = forms || {};

    if (venue && venue.id) {
      params.shop_id = venue.id;
    } else {
      showErrorMessage("请选择一个店铺");
      return false;
    }


    return params;

  }

  //获取球杆柜列表
  private getAllianceBusinesss(): void {
    this.allianceBusinessListApi.getAllianceBusinessList(
      { ...this.getAllianceBusinessParams() },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page;
        this.tablePage.prepage = res.data.prepage;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private getAllianceBusinessParams(mode = "all"): any {
    const params: any = {
      user_id: this.currentUserId
    };

    // const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    // const { shop_name, time } = form;

    // if (shop_name) {
    //   params.shop_name = shop_name;
    // }

    // if (Array.isArray(time) && time.length === 2) {
    //   const [startDate, endDate] = time;

    //   const startTime = Util.dateTimeUtilnohms(startDate);
    //   const endTime = Util.dateTimeUtilnohms(endDate);

    //   params.start_date = startTime;

    //   params.end_date = endTime;
    // }

     if (
      mode == "exportExcel" &&
      !(params.start_date && params.end_date)
    ) {
      this.$message.error("请选择日期导出数据");
      return null;
    }



    if (mode === "all") {
      params.page = page;

      params.limit = prepage;

      // outerParams.__isUnique = !firstLoading;
    }

    return params;
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  /** 导出excel */
  // private downloadExcelLoading: boolean = false;

  // // 字段名 文案对应关系
  // private merchantExcelList2ch: any = {
  //   name: "店铺名称",
  //   shop_id: " 店铺ID",
  //   cabinet_id: "绑定设备ID",
  //   admin_name: "操作人",
  //   updated_at: "操作时间"
  // };
  // // 商户Excel 表头的先后顺序
  // private merchantExcelSortList: string[] = [
  //   "name",
  //   "shop_id",
  //   "cabinet_id",
  //   "admin_name",
  //   "updated_at"
  // ];

  // // 点击导出excel 表格
  // private handleExportExcelAllianceBusinessList() {
  //   const params = this.getAllianceBusinessParams('exportExcel')

  //   if (params === null) {
  //     return false;
  //   }

  //   this.downloadExcelLoading = true;
  //   this.allianceBusinessListApi.exportExcelStoragelockerList(
  //     params,
  //     (res: any) => {
  //       const { start_date, end_date } = params || {};

  //       const filename = `球杆柜管理列表 ${start_date}到${end_date}.xlsx`;
  //       const { data } = res || {};

  //       const {
  //         list: originList
  //       } = data || {}

  //       const newList = Array.isArray(originList)
  //         ? originList.map((item) => {
  //             const { 
  //               // status,
  //               // back_goods_detail
  //              } = item || {};
  //             const newItem = {
  //               ...(item || {}),
  //               // __status_text: withdrawAccountAudit2Text[status],
  //               // __back_good_details: back_goods_detail.map((goods: any, index: number) => `${ goods.name }x${ goods.num }  ¥${ goods.price }`).join('; \t')
  //             };

  //             for (let key in item) {
  //               if (typeof item[key] == 'number') {
  //                 newItem[key] = item[key].toString()
  //               }
  //             }

             
  //             return newItem;
  //           })
  //         : [];

  //       if (newList.length > 0) {
  //         const merchantExcelSortList = this.merchantExcelSortList;

  //         const merchantExcelList2ch = this.merchantExcelList2ch;

  //         const excelJSONData = getExcelJSONDataByList(
  //           newList,
  //           merchantExcelSortList,
  //           merchantExcelList2ch
  //         );

  //         excuteDownloadExport2Excel(excelJSONData, filename);
  //       } else {
  //         this.$message.info("选中时段没有可导出的信息");
  //       }

  //       this.downloadExcelLoading = false;
  //     },
  //     () => {
  //       this.downloadExcelLoading = false;
  //     }
  //   );
  // }

  /** end 导出excel */

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };
}

import { BasicsClass } from '@/common/BasicsClass';
export class ShopIncomeChartAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getIncomeChartData', {
            url: '/admin/shop/bulletinBoard',
            name: 'chart-data',
            label: '图表数据'
        }],
       
        ['searchVenueList', {
            url: '/admin/coupons/shop/list',
            name: 'shop-list',
            label: '店铺列表',
            // hidden: true
        }],
        
    ])


  
    //优惠券列表
    public getIncomeChartData(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getIncomeChartData').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
  
    // 搜索店铺列表
    public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('searchVenueList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }


   
}

import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getExcelJSONDataByList,getPageAPIs } from "@/common/util";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import { MerchantWithdrawAddAPI } from "@/common/api/financeManage/merchantWithdrawAddRecord";
import ZlSelect from "@/components/third-custom-components/select";
import ZlOption from "@/components/third-custom-components/option";
import { excuteDownloadExport2Excel } from "@/common/xlsx";
export const pageApiList = getPageAPIs(MerchantWithdrawAddAPI);

const defaultForm = {
  name: "", // 店铺名称
};

const defaultAddForms = {
  venue_id: "",
  venue: {
    id: "",
    name: "",
  },
  money: '', // 增加金额
  remark: '', // 备注
}

const defaultSearchVenuePageOption = {
  page: 1,
  limit: 10,
  isRearchBottom: false,
};


@Component({
  filters: {
    // showUserMemberTypeName: function(type: number) {
    //   return transferUserLevel2ch[type] || ''
    // }
  },
  components: {
    ZlSelect,
    ZlOption,
  },
})
export default class MerchantWithdrawList extends Vue {
  private MerchantWithdrawAddAPI: any;

  private deepLoopUtil: any;

  constructor() {
    super();
    this.MerchantWithdrawAddAPI = new MerchantWithdrawAddAPI();

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultAddForms),
    }
  }

  private form: any;

  private forms: any;

  private firstLoading: boolean = true;

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };


  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    this.MerchantWithdrawAddAPI.getShopWithdrawAddLists(
      this.getMerchantWithdrawRecordListParams(),
      (res: any) => {
        const { data } = res;
        const { list, count, page, prepage } = data || {};
        const newTableList = Array.isArray(list)
          ? list.map(this.handleTableData)
          : [];
        this.$set(this.tablePage, "totalCount", count);
        this.$set(this.tablePage, "pageNo", page);
        this.$set(this.tablePage, "pageSize", prepage);
        this.tableData = newTableList;
        this.firstLoading = false;
      },
      () => {
        this.firstLoading = false;
      },
      false
    );
  }

  private getMerchantWithdrawRecordListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;
    const tablePage = this.tablePage;
    const firstLoading = this.firstLoading;
    const {
      name,
    } = form || {};

    const { pageNo, pageSize } = tablePage || {};
    const outerParams: any = {};
    if (name) {
      outerParams.name = name;
    }


    if (
      mode == "exportExcel" &&
      !(outerParams.start_date && outerParams.end_date)
    ) {
      this.$message.error("请选择日期导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      outerParams.__isUnique = !firstLoading;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  private dialogFormVisible: boolean = false

  private sumbitCustomMerchantWithdrawLoading: boolean = false
  // // 唤起会员增加弹框
  private handleWakeCustomMerchantWithdrawDialog() {
    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultAddForms),
    }

    this.dialogFormVisible = true

  }

  // 增加金额限制
  private handleLimitConsumePrice(val: string) {
    Util.limitDigitalnput(
      val,
      (new_val: string) => {
        this.$set(this.forms, "money", new_val);
      },
      (new_val: string) => {
        this.$set(this.forms, "money", new_val);
      },
      2
    );
  }

  // // 提交修改
  private handleSumbitCustomMerchantWithdraw () {
    const {
      venue,
      money,
      remark
    } = this.forms || {}

    if (!(venue && venue.id)) {
      this.$message.error('请选择店铺')
      return
    }

    if (!money) {
      this.$message.error('请输入增加金额')
      return
    }

    this.sumbitCustomMerchantWithdrawLoading = true

    this.MerchantWithdrawAddAPI.addShopWithdraw({
      shop_id: venue.id,
      money,
      remark
    }, () => {

      this.$message.success('增加成功')

      this.sumbitCustomMerchantWithdrawLoading = false
      this.dialogFormVisible = false

      this.getTable()
    }, () => {
      this.sumbitCustomMerchantWithdrawLoading = false
    })
  }

  // 后台返回的字段 转化为前端定义的字段
  private handleTableData(data: any): any {
    const newData = {
      ...data,
    };

    return newData;
  }

  /** 远程搜索店铺 */
  private venueList: any[] = [
    // {
    //   id: 1,
    //   label: '测试'
    // },
    // {
    //   id: 2,
    //   label: '测试2'
    // }
  ];

  private searchVenueLoading: boolean = false;

  private remoteGetVenueQueryString: string = "";

  private remoteSearchVenueTimer: any = null;

  private searchVenuePageOption: any = {};

  private initRemoteGetVenuePageOption() {
    this.searchVenuePageOption = {
      ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    };
  }

  private getSearchVenueParams() {
    const searchVenuePageOption = this.searchVenuePageOption;

    const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

    const { page, limit } = searchVenuePageOption;

    const params: any = {
      page,
      limit,
    };

    if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
      params.name = remoteGetVenueQueryString.trim();
    }

    return params;
  }

  private getMoreVenueList(refresh: boolean = true) {
    return new Promise((resolve: Function, reject: Function) => {
      if (refresh) {
        this.initRemoteGetVenuePageOption();
      }

      const params = this.getSearchVenueParams();

      this.MerchantWithdrawAddAPI.searchVenueList(
        params,
        (res: any) => {
          console.log(res, "res");

          const venueList: any[] = res.data || [];

          this.venueList = refresh
            ? venueList
            : this.venueList.concat(venueList);

          if (Array.isArray(res.data) && res.data.length == 0) {
            this.searchVenuePageOption.isRearchBottom = true;
          }
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          resolve();
        },
        () => {
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          reject();
        }
      );
    });
  }

  // 第一次搜索 无关键字
  private handleFirstGetVenueList() {
    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 清空搜索
  private handleClearGetVenueList() {
    this.remoteGetVenueQueryString = "";

    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 远程搜索
  private remoteGetVenueMethod(query: string) {
    query = query.trim ? query.trim() : "";

    if (query !== "") {
      this.remoteGetVenueQueryString = query;
      clearTimeout(this.remoteSearchVenueTimer);

      this.remoteSearchVenueTimer = setTimeout(() => {
        this.searchVenueLoading = true;
        this.getMoreVenueList();
      }, 300);
    } else {
      this.remoteGetVenueQueryString = "";
      this.venueList = [];
    }
  }

  private addVenueListLoading: boolean = false;

  private remoteAddVenueMethod() {
    if (this.addVenueListLoading) return;
    const searchVenuePageOption = this.searchVenuePageOption;

    const { isRearchBottom } = searchVenuePageOption;

    if (isRearchBottom) return;

    this.searchVenuePageOption.page++;

    this.addVenueListLoading = true;

    this.getMoreVenueList(false);
  }
  /** end 远程搜索店铺 */


  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 订单字段名 文案对应关系
  private orderExcelList2ch: any = {
    "name": "店铺",
    "money": "增加金额",
    "examine_time": "增加时间",
    "remark": "增加原因",
    "examine_user": "操作人",
    "updated_at": "操作时间",
  };
  // 订单Excel 表头的先后顺序
  private orderExcelSortList: string[] = [
    "name",
    "money",
    "examine_time",
    "remark",
    "examine_user",
    "updated_at",
  ];

  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}

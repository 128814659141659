import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

import { CommonApi } from "../common";

export class MemberConsumeApi extends BasicsClass {
  public apiUrls: any = new Map([
    ['getMemberConsumeTableList', {
        url: '/admin/member_fee/index',
        name: 'list',
        label: '列表'
    }],
    ['exportExcelMemberConsumeList', {
        url: '/admin/member_fee/exportList',
        name: 'export-excel',
        label: '导出'
    }],

])
  // 订单api 方法
  // momo达到家-订单列表
  public getMemberConsumeTableList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getMemberConsumeTableList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // momo达到家-导出订单列表
  public exportExcelMemberConsumeList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    const apiUrls = this.apiUrls

    const url = apiUrls.get('exportExcelMemberConsumeList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
   
  }


}

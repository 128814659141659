import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";


import {
  dataWholeBoardRoutes
} from './dataWholeBoardRoute'

import {
  merchantManageRoutes
} from './merchantManageRoute'

import {
  userManageRoutes
} from './userManageRoutes'

import {
  competitionManageRoutes
} from './competitionManageRoutes'

import {
  customerServiceRoutes
} from './customerServiceRoutes'

import {
  operationManageRoutes
} from './operationManageRoutes/index'

import {
  financeManageRoutes
} from './financeManageRoutes'

import {
  auditManageRoutes
} from './auditManageRoutes'

import {
  officialWebManageRoutes
} from './officialWebManageRoutes'

import {
  marketManageRoutes
} from './marketManageRoutes'

import {
  couponManageRoutes
} from './couponManageRoutes'

import {
  systemManageRoutes
} from './systemManageRoutes'

Vue.use(VueRouter);

/**
 *  之前项目的某一管理大类，
 * 当前项目暂时没有
 *
 */
function getMidLen() {
  const dbRoutesLen = dataWholeBoardRoutes.length
  console.log(dbRoutesLen, 'dbRoutesLen')

  const mMRoutesLen = merchantManageRoutes.length
  console.log(mMRoutesLen, 'mMRoutesLen')

  const uMRoutesLen = userManageRoutes.length
  console.log(uMRoutesLen, 'uMRoutesLen')

  const cpeteMRoutesLen = competitionManageRoutes.length
  console.log(cpeteMRoutesLen, 'cpeteMRoutesLen')

  const cSRoutesLen = customerServiceRoutes.length
  console.log(cSRoutesLen, 'cSRoutesLen')

  const oMRoutesLen = operationManageRoutes.length
  console.log(oMRoutesLen, 'oMRoutesLen')

  const fMRoutesLen = financeManageRoutes.length
  console.log(fMRoutesLen, 'fMRoutesLen')

  const aMRoutesLen = auditManageRoutes.length
  console.log(aMRoutesLen, 'aMRoutesLen')

  const oWMRoutesLen = officialWebManageRoutes.length
  console.log(oWMRoutesLen, 'oWMRoutesLen')

  const marMRoutesLen = marketManageRoutes.length
  console.log(marMRoutesLen, 'marMRoutesLen')

  const cMRoutesLen = couponManageRoutes.length
  console.log(cMRoutesLen, 'cMRoutesLen')

  const sOLRoutesLen = systemManageRoutes.length
  console.log(sOLRoutesLen, 'sOLRoutesLen')

  const totalLen = dbRoutesLen + mMRoutesLen + uMRoutesLen + cpeteMRoutesLen + cSRoutesLen + oMRoutesLen + fMRoutesLen + aMRoutesLen + oWMRoutesLen + marMRoutesLen + cMRoutesLen + sOLRoutesLen
  console.log(totalLen, 'totalLen')

  return totalLen / 2
}

console.log(getMidLen(), 'getMidLen')


const routes = [
  {
    path: "/",
    redirect: () => {
      const token = localStorage.getItem('token')

      if (token) {
        const menusStr = localStorage.getItem('menus')

        const menus: any = menusStr ? JSON.parse(menusStr) : []

        // console.log(menus, 'menus')

        const {
          children = []
        } = menus[0] || {}

        const firstChild = children[0] || {}
        return firstChild.url || "/login"
      } else {
        return "/login"
      }
    },
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "登录",
    },
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/",
    name: "home",
    component: Home,
    children: [
      ...dataWholeBoardRoutes,
      // ...dataStatisticRoutes,
      ...merchantManageRoutes,
      // ...accountManangeRoutes,
      ...officialWebManageRoutes,
      ...marketManageRoutes,

      ...userManageRoutes,
      ...competitionManageRoutes,
      ...customerServiceRoutes,
      ...operationManageRoutes,
      ...financeManageRoutes,
      ...auditManageRoutes,
      ...couponManageRoutes,
      ...systemManageRoutes
    ],
  },
];

const router = new VueRouter({
  routes,
});

export {
  dataWholeBoardRoutes,
      // ...dataStatisticRoutes,
  merchantManageRoutes,
      // ...accountManangeRoutes,
  officialWebManageRoutes,
  marketManageRoutes,

  userManageRoutes,
  competitionManageRoutes,
  customerServiceRoutes,
  operationManageRoutes,
  financeManageRoutes,
  auditManageRoutes,
  couponManageRoutes,
  systemManageRoutes
}

export default router;

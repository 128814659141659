
import {Component, Vue} from "vue-property-decorator";
import {getExcelJSONDataByList, getPageAPIs} from "@/common/util";
import {SpringFRewardRecordAPI} from "@/common/api/operationManage/springFRewardRecord";
import { excuteDownloadExport2Excel } from "@/common/xlsx";
import {DeepLoop} from '@/common/deepLoop/deepLoop';
import {shareConfirmOptions} from '@/common/config';

export const pageApiList = getPageAPIs(SpringFRewardRecordAPI);
const defaultSearchVenuePageOption = {
  page: 1,
  limit: 10,
  isRearchBottom: false,
};
const defaultForm = {
  phone:'',
  // type:null,
  reward_name:'',
  time: [],
}
@Component({
})
export default class SpringFRewardRecord extends Vue {
  constructor() {
    super();
    this.deepLoopUtil = new DeepLoop();
    this.classApi = new SpringFRewardRecordAPI();
    this.form = {...this.deepLoopUtil.deepCopy(defaultForm)}
  }
  private classApi: any;
 
  private form: any = {}
  private deepLoopUtil: any = {}
  private downloadExcelLoading: boolean = false;
  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    
   
    this.getTable();
  }


  // 用户字段名 文案对应关系
  private userExcelList2ch: any = {
    'phone': '手机号',
    'user_name':'昵称',
    'reward_name': '奖品名称',
    'created_at':'获得时间'
  };
  // 用户Excel 表头的先后顺序
  private userExcelSortList: string[] = [
    'phone',
    'user_name',
    'reward_name',
    'created_at'
  ]
  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    this.classApi.getList(
        {
          ...this.getSpringFRewardRecordParams()
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
        }
    );
  }

  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
    this.search()

  }

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;
    this.getTable();
  }
  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getSpringFRewardRecordParams("exportExcel");
    if (params === null) {
      return false;
    }
    this.downloadExcelLoading = true;
    this.classApi.exportList(params, (res: any) => {
          const { start_date, end_date } = params ;
          const timeDuration = start_date && end_date ? `${start_date}到${end_date}` : ''
          const filename = `春节中奖记录 ${timeDuration}.xlsx`;
          const { data } = res ;

          const { list: originList } = data ;
          const newList = Array.isArray(originList)
              ? originList.map((item) => {
                // item.type =  this.statusObject[item.type]
                return item;
              })
              : [];

          if (newList.length > 0) {
            const userExcelSortList = this.userExcelSortList;

            const userExcelList2ch = this.userExcelList2ch;

            const excelJSONData = getExcelJSONDataByList(
                newList,
                userExcelSortList,
                userExcelList2ch
            );

            excuteDownloadExport2Excel(excelJSONData, filename);
          } else {
            this.$message.info("选中时段没有可导出的信息");
          }

          this.downloadExcelLoading = false;
        },
        () => {
          this.downloadExcelLoading = false;
        }
    );
  }
  private getSpringFRewardRecordParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;


    const {
      phone, // 手机号
      reward_name,

      time
    } = form ;

    const { pageNo, pageSize } = tablePage ;

    const outerParams: any = {};


    if (phone) {
      outerParams.phone = phone;
    }

    if (reward_name) {
      outerParams.reward_name = reward_name;
    }


    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;
      outerParams.start_date = startDate;
      outerParams.end_date = endDate;
    }

    if (mode == "exportExcel" && !(outerParams.start_date && outerParams.end_date)) {
      this.$message.error("请选择时段导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

    }


    return outerParams;
  }
}

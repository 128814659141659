import OSS from "ali-oss";
import { Message } from 'element-ui'
import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

type videoParams = any

function getVideoDuration(url: string): any {
  return new Promise((resolve, reject) => {
    var audio = new Audio(url);
    audio.muted = true;
    audio.play().then(() => audio.pause());
    // 元数据已加载

    const handleLoadedmetadata = function (e: any) {
      // let duration = audio.duration;
      console.log(e, "e audio");
      resolve({
        url,
        duration: audio.duration,
      });

      hanleClearListeners();
    };

    const hanleClearListeners = function () {
      audio.removeEventListener("loadedmetadata", handleLoadedmetadata);
      audio.onerror = null;
    };
    audio.addEventListener("loadedmetadata", handleLoadedmetadata);

    audio.onerror = function (err) {
      reject(err);
    };

    audio.muted = false;
  });
}

const stsConfigInfo: any = {}

const stsTokenGapTs = 3 * 60 * 1000 // 单位毫秒


export class CommonApi extends BasicsClass {
  // 公共、辅助api接口

  // 图片上传-oss上传凭证
  public getOssConfig(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    // autoRefreshNoCacheOss true 时 不使用 stsConfig 缓存 直接重新获取
    if (!params.autoRefreshNoCacheOss) {
      const isEmptyConf: boolean = !(stsConfigInfo && stsConfigInfo.expiration)

      if (!isEmptyConf) {
        const {
          expiration
        } = stsConfigInfo || {}

        console.log(stsConfigInfo, 'stsConfigInfo')

        const expireTs = new Date(expiration).valueOf()
        const nowTs = new Date().valueOf()


        const isExpireConfig: boolean = expireTs < nowTs + stsTokenGapTs

        if (!isExpireConfig) {
          successCb({
            data: {
              ...(stsConfigInfo || {})
            }
          })
          return Promise.resolve({
            data: {
              ...(stsConfigInfo || {})
            }
          })
        }
      }
    }

    return new Promise((resolve, reject) => {
      this.BasicPost(
        "/admin/oss/sts",
        {
          ...params,
        },
        false,
        false,
        false,
        (res: any) => {
          const {
            data: ossConfig
          } = res || {}

          Object.assign(stsConfigInfo, {
            ...(ossConfig || {})
          })

          resolve(res)

          successCb(res)
        },
        (err: any) => {
          reject(err)

          failCb(err)
        },
        showLoading
      );
    })
  }

  //获取oss参数 上传文件
  public getOss(
    file: any,
    successCb: Function,
    failCb: Function,
    extraParams: videoParams = {}
  ) {
    // let obj = {
    //   width: 0,
    //   height: 0,
    // };
    // if (getOption) {
    //   let img = new Image();
    //   img.src = file.src;

    //   img.onload = () => {
    //     obj.width = img.width;
    //     obj.height = img.height;
    //   };
    // }

    this.getOssConfig(
      {},
      (res: any) => {
        const { data: ossConfig } = res || {};

        const {
          regionId,
          accessKeyId,
          accessKeySecret,
          securityToken,
          bucket,
          uploadDir,
          uploadCallbackUrl,
        } = ossConfig || {};

        const client = new OSS({
          region: regionId,
          secure: true, //*这句话很重要！！！！！！！！！
          accessKeyId,
          accessKeySecret,
          stsToken: securityToken,
          bucket,
          refreshSTSToken: async () => {
            // 向您搭建的STS服务获取临时访问凭证。
            const {
              res
            }: any = await this.getOssConfig({ autoRefreshNoCacheOss: true }, () => { }, () => { })

            stsConfigInfo.expiration = res.data.expiration
            return {
              accessKeyId: res.data.accessKeyId, // 自己账户的accessKeyId或临时秘钥
              accessKeySecret: res.data.accessKeySecret, // 自己账户的accessKeySecret或临时秘钥
              stsToken: res.data.securityToken, //  从STS服务获取的安全令牌（SecurityToken）。
            }
          },
          // 刷新临时访问凭证的时间间隔，单位为毫秒。  该间隔 比 getOssConfig 间隔更短，保证 外部 this.getOssConfig 一直缓存 
          refreshSTSTokenInterval: 60 * 60 * 1000 - stsTokenGapTs * 1.5
        });

        const {
          // type: fileType,
          name: fileName,
        } = file;

        const fileExtension = fileName.substring(fileName.lastIndexOf("."));

        const objectKey = uploadDir + "/" + Util.uuid() + fileExtension;

        const options = {
          callback: {
            // 设置回调请求的服务器地址，例如http://oss-demo.aliyuncs.com:23450。
            url: uploadCallbackUrl,
            //（可选）设置回调请求消息头中Host的值，即您的服务器配置Host的值。
            //host: 'yourCallbackHost',
            // 设置发起回调时请求body的值。
            body:
              "bucket=${bucket}&file=${object}&size=${size}&height=${imageInfo.height}&width=${imageInfo.width}&etag=${etag}" +
              "&upload_type=" +
              "image",
            // 设置发起回调请求的Content-Type。
            contentType: "application/x-www-form-urlencoded",
          },
        };

        client.put(objectKey, file, options).then(
          (result) => {
            successCb(result);
          },
          (err: any) => {
            failCb(err);
          }
        );
      },
      (err: any) => {
        failCb(err);
      }
    );
  }

  // 获取oss 参数  上传 其他类型文件
  public getOssFile(
    file: any,
    successCb: Function,
    failCb: Function,
    extraParams: any = {}
  ) {
    // let obj = {
    //   width: 0,
    //   height: 0,
    // };
    // if (getOption) {
    //   let img = new Image();
    //   img.src = file.src;

    //   img.onload = () => {
    //     obj.width = img.width;
    //     obj.height = img.height;
    //   };
    // }
    this.getOssConfig(
      {},
      (res: any) => {
        const { data: ossConfig } = res || {};

        const {
          regionId,
          accessKeyId,
          accessKeySecret,
          securityToken,
          bucket,
          uploadDir,
          uploadCallbackUrl,
        } = ossConfig || {};

        const client = new OSS({
          region: regionId,
          secure: true, //*这句话很重要！！！！！！！！！
          accessKeyId,
          accessKeySecret,
          stsToken: securityToken,
          bucket,
          refreshSTSToken: async () => {
            // 向您搭建的STS服务获取临时访问凭证。
            const {
              res
            }: any = await this.getOssConfig({ autoRefreshNoCacheOss: true }, () => { }, () => { })

            stsConfigInfo.expiration = res.data.expiration
            return {
              accessKeyId: res.data.accessKeyId, // 自己账户的accessKeyId或临时秘钥
              accessKeySecret: res.data.accessKeySecret, // 自己账户的accessKeySecret或临时秘钥
              stsToken: res.data.securityToken, //  从STS服务获取的安全令牌（SecurityToken）。
            }
          },
          // 刷新临时访问凭证的时间间隔，单位为毫秒。  该间隔 比 getOssConfig 间隔更短，保证 外部 this.getOssConfig 一直缓存 
          refreshSTSTokenInterval: 60 * 60 * 1000 - stsTokenGapTs * 1.5
        });

        const {
          // type: fileType,
          name: fileName,
        } = file;

        const fileExtension = fileName.substring(fileName.lastIndexOf("."));

        const objectKey = uploadDir + "/" + Util.uuid() + fileExtension;

        const lowerFileExtension = fileExtension.toLowerCase().slice(1)

        const allMineByExtensions = {
          'xls': 'application/vnd.ms-excel',
          'xlsx': 'xlsx'
        }

        const mime: string = allMineByExtensions[(lowerFileExtension as keyof typeof allMineByExtensions)]

        const options: any = {
          callback: {
            // 设置回调请求的服务器地址，例如http://oss-demo.aliyuncs.com:23450。
            url: uploadCallbackUrl,
            //（可选）设置回调请求消息头中Host的值，即您的服务器配置Host的值。
            //host: 'yourCallbackHost',
            // 设置发起回调时请求body的值。
            body:
              "bucket=${bucket}&file=${object}&size=${size}&etag=${etag}" +
              "&file_name=${x:name}&upload_type=" +
              "file",
            // 设置发起回调请求的Content-Type。
            contentType: "application/x-www-form-urlencoded",
          },
        };

        if (mime) {
          options.mime = mime
        }


        if (extraParams && typeof extraParams == 'object' && Object.entries(extraParams).length > 0) {
          const customValue: any = {}

          for (let key in extraParams) {
            const val: any = extraParams[key as keyof typeof extraParams]

            customValue[key] = JSON.stringify(val)
          }

          options.callback.customValue = customValue
        }

        console.log(objectKey, 'objectKey')
        console.log(options, 'options')

        client.put(objectKey, file, options).then(
          (result) => {
            successCb(result);
          },
          (err: any) => {
            failCb(err);
          }
        );
      },
      (err: any) => {
        failCb(err);
      }
    );

  }

  public async getOssvideo(file: any, successCb: Function, failCb: Function, extraParams: videoParams = {}, fileUrl: string = '') {
    // console.log(fileUrl, 'fileUrl')
    if (!(extraParams && extraParams.duration)) {
      if (!fileUrl) {
        fileUrl = window.URL.createObjectURL(file)
      }
      const { duration } = await getVideoDuration(fileUrl).catch(
        (err: any) => {
          // this.$message("获取时长失败");
          Message('获取时长失败')
        }
      );

      if (typeof duration != 'number') {

        return
      }

      if (typeof extraParams.limit_duration == 'number') {
        if (extraParams.limit_duration < duration) {
          failCb({
            errMsg: 'out of limit duration'
          })

          return
        }
      }

      // console.log(duration, 'duration')

      extraParams.duration = duration;

      window.URL.revokeObjectURL(fileUrl);
    }

    // console.log(extraParams, 'extraParams')

    this.getOssConfig(
      {},
      (res: any) => {
        const { data: ossConfig } = res || {};

        const {
          regionId,
          accessKeyId,
          accessKeySecret,
          securityToken,
          bucket,
          uploadDir,
          uploadCallbackUrl,
        } = ossConfig || {};

        const client = new OSS({
          region: regionId,
          secure: true, //*这句话很重要！！！！！！！！！
          accessKeyId,
          accessKeySecret,
          stsToken: securityToken,
          bucket,
          refreshSTSToken: async () => {
            // 向您搭建的STS服务获取临时访问凭证。
            const {
              res
            }: any = await this.getOssConfig({ autoRefreshNoCacheOss: true }, () => { }, () => { })

            stsConfigInfo.expiration = res.data.expiration

            return {
              accessKeyId: res.data.accessKeyId, // 自己账户的accessKeyId或临时秘钥
              accessKeySecret: res.data.accessKeySecret, // 自己账户的accessKeySecret或临时秘钥
              stsToken: res.data.securityToken, //  从STS服务获取的安全令牌（SecurityToken）。
            }
          },
          // 刷新临时访问凭证的时间间隔，单位为毫秒。该间隔 比 getOssConfig 间隔更短，保证 外部 this.getOssConfig 一直缓存 
          refreshSTSTokenInterval: 60 * 60 * 1000 - stsTokenGapTs * 1.5
        });

        const {
          // type: fileType,
          name: fileName,
        } = file;

        // const fileExtension = fileName.substring(fileName.lastIndexOf("."));
        const fileExtension = ".mp4";

        const objectKey = uploadDir + "/" + Util.uuid() + fileExtension;

        const options: any = {
          timeout: 10 * 60 * 1000, // 10分钟 单位 毫秒
          mime: "video/mp4",
          callback: {
            // 设置回调请求的服务器地址，例如http://oss-demo.aliyuncs.com:23450。
            url: uploadCallbackUrl,
            //（可选）设置回调请求消息头中Host的值，即您的服务器配置Host的值。
            //host: 'yourCallbackHost',
            // 设置发起回调时请求body的值。
            body:
              "bucket=${bucket}&file=${object}&size=${size}&height=${imageInfo.height}&width=${imageInfo.width}&etag=${etag}&duration=${x:duration}" +
              "&upload_type=" +
              "video",
            // 设置发起回调请求的Content-Type。
            contentType: "application/x-www-form-urlencoded",
          },
        };
        console.log(file)

        if (extraParams && typeof extraParams == 'object' && Object.entries(extraParams).length > 0) {
          const customValue: any = {}

          for (let key in extraParams) {
            const val: any = extraParams[key as keyof typeof extraParams]

            customValue[key] = JSON.stringify(val)
          }

          delete customValue.limit_duration

          options.callback.customValue = customValue
        }

        console.log(options, 'options')

        client.put(objectKey, file, options).then(
          (result) => {
            successCb(result);
          },
          (err: any) => {
            failCb(err);
          }
        );
      },
      (err: any) => {
        failCb(err);
      }
    );
  }

  // // 导出数据用于转为 excel 表格
  // public static getArrayJSONDataForExcel(url: string, params: any, basicPost: Function, successCb: Function, failCb: Function, showLoading: boolean = false) {
  //     // const {
  //     //     page, // 页码
  //     //     prepage // 没页条数
  //     // } = params
  //     console.log(url, 'url getArrayJSONDataForExcel')
  //     console.log(params, 'params getArrayJSONDataForExcel')
  //     basicPost(url, {
  //         ...params
  //     }, false, false, false, successCb, failCb, showLoading)
  // }

  // 获取文件Blob 结构的数据
  public getFileBlob(url: string) {
    return new Promise((resolve, reject) => {
      Util.sendApi(
        url,
        {},
        {
          repsonseType: "blob",
        },
        (res: any) => {
          resolve(res);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  // 获取招募合伙人是否有权限
  public getRecruitPartnerAuth(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    this.BasicPost('/admin/investment/depart', {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 根据筛选条件显示侧边栏
  public getFilterAsideMenus(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    this.BasicPost('/admin/permissions/menus', {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}

import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class CoachUploadMediaAuditApi extends BasicsClass {
    public apiUrls: any = new Map([
        ['getList', {
            url: '/admin/coachMediaExamine/list',
            name: 'list',
            label: '列表'
        }],
        ['updateUploadMediaAuditPassStatus',  {
            url: '/admin/coachMediaExamine/pass',
            name: 'pass-status',
            label: '审核通过'
        }],
        ['updateUploadMediaAuditRejectStatus',  {
            url: '/admin/coachMediaExamine/reject',
            name: 'reject-status',
            label: '审核拒绝'
        }]
    ])

    // momo达到家-助教基本信息审核列表
    public getList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    // 处理问题状态更新
    public updateUploadMediaAuditStatus(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const urlModes: any = {
            'agree': apiUrls.get('updateUploadMediaAuditPassStatus').url,
            'disagree': apiUrls.get('updateUploadMediaAuditRejectStatus').url
        }

        let url: string = urlModes[params.mode]

        delete params.mode

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading);
    }

}
import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class TechnicalServiceShopFeeListAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getList', {
            url: '/admin/platformIncome/detail',
            name: 'list',
            label: '列表'
        }],
        ['exportTechnicalServiceFeeList', {
            url: '/admin/platformIncome/exportDetail',
            name: 'export-excel',
            label: '导出'
        }],
    ])
    
    // 技术服务费店铺详情列表
    public getList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 导出技术服务费店铺详情列表 
    public exportTechnicalServiceFeeList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('exportTechnicalServiceFeeList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

}
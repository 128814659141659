import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class BallCoachQRChargeApi extends BasicsClass {
  public apiUrls: any = new Map([
    ['getBallCoachQRList', {
        url: '/admin/shopQrCode/ball',
        name: 'list',
        label: '列表'
    }],
])

  // 助教二维码列表
  public getBallCoachQRList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getBallCoachQRList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

}
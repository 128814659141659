
import { Component, Vue } from "vue-property-decorator";
import { Util, getPageAPIs } from "@/common/util";
import RecordManageAPIClass  from "@/common/api/operationManage/dynamicManage";
import gallery from "@/components/gallery.vue";
import { DeepLoop } from "@/common/deepLoop/deepLoop";

import {
  shareConfirmOptions,
} from "@/common/config";
export const pageApiList = getPageAPIs(RecordManageAPIClass);

@Component({
  filters: {
    showPicOrThumbnail(row: any) {
      const { medium } = row || {};
      if(!medium.length) return ''
      const type = medium[0].type
      switch (type) {
        case 1: // 图片
          return  medium[0].url;
        case 2: // 视频
          return `${medium[0].url}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`
        default:
          return "";
      }
    }
  },
  components: {
    gallery,
  },
})
export default class CommercialCollegesManage extends Vue {
  private form: any = {
    phone: null, // 手机号码
    is_status: null, //  0全部 1正常(上架) 2下架
    start_date: null, // 开始时间
    end_date: null, // 结束时间
  };
  private time = []
  private tableData: any[] = [];
  private commentTableData: any[] = [];
  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  private commentTableParams = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  }
  private dialogTitle = ''
  private currentEditId = ''
  private statusArrays = [
    {value:0,label:'全部'},
    {value:1,label:'上架'},
    {value:2,label:'下架'},
  ]
  private deepLoopUtil: any = {};

  private RecordManageAPIClass = new RecordManageAPIClass();

  private dialogVisible: boolean = false;

  constructor() {
    super();
    this.deepLoopUtil = new DeepLoop();
  }


  private created(): void {
    // this.getDynamicList();
  }

  private activated() {
    if(this.$route.query.phone){
      this.form.phone = this.$route.query.phone
    }
    this.getDynamicList()
  }


  private restForm(): void {
    this.form = new Util().clearObject(this.form);
    this.time = []
    if(this.$route.query.phone){
      this.$router.replace({
        path: "/dynamicManage",
        query: {}
      })
    }
    this.getDynamicList();
  }
  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    if (Array.isArray(this.time) && this.time.length === 2) {
      const [startDate, endDate] = this.time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      this.form.start_date = startTime;
      this.form.end_date = endTime;
    }
    this.getDynamicList();
  }


  // 查看视频/图片
  private handleBeforePreviewMedia(row: any) {
    const { medium } = row || {};
    if(!medium.length) return
    const previewItem: any = {};
    const type = medium[0].type
    switch (type) {
      case 1: // 图片
        previewItem.image_count = medium.length;
        previewItem.images = medium.map((pic: any) => pic.url);
        break;
      case 2: // 视频
        previewItem.video_count = medium.length;

        previewItem.videos = medium.map((pic: any) => pic.url);
        break;
    }

    this.handlePreviewMedia(previewItem);
  }

  private images: any = [];

  private handlePreviewMedia(row: any) {
    const { image_count, images, video_count, videos } = row || {};

    const urls: any[] = [];

    if (image_count > 0) {
      urls.push(
          ...images.map((img: string) => {
            return {
              title: "",
              type: "image/jpeg",
              href: img,
              thumbnail: img,
            };
          })
      );
    }

    if (video_count > 0) {
      urls.push(
          ...videos.map((video: string) => {
            return {
              title: "",
              type: "video/mp4",
              href: video,
              poster: `${video}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`,
            };
          })
      );
    }
    this.images = urls;
  }


  //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getDynamicList();
  }

  private handlePageChangeComment(val: any) {
    this.commentTableParams.page = val.currentPage;
    this.commentTableParams.prepage = val.pageSize;
    this.getDynamicCommentList(this.currentEditId)
  }


  private openCommentDialog( row:any ){
    this.commentTableData = []
    this.dialogVisible = true
    this.dialogTitle = `${row.nickname}-评论列表`
    this.currentEditId = row.id
    this.getDynamicCommentList(row.id)
  }

  private updateDynamicsState(row: any) {
    this.RecordManageAPIClass.updateDynamicsState({id: row.id,},
        () => {
          this.$message.success('修改成功')
          this.getDynamicList();
        },
        () => {}
    )
  }

  private handleDeleteDynamic(row: any) {
    this.$confirm("确定删除该动态吗?", "", {
      ...shareConfirmOptions,
    }).then(() => {
      this.RecordManageAPIClass.deleteDynamics({id: row.id,},
          () => {
            this.$message.success('删除成功')
            this.getDynamicList();
          },
          () => {}
      );
    });
  }

  private handleDeleteDynamicComment(row: any) {
    this.$confirm("确定删除该评论吗?", "", {
      ...shareConfirmOptions,
    }).then(() => {
      this.RecordManageAPIClass.deleteDynamicsComment(
          {
            dynamics_id:this.currentEditId,
            comment_id:row.id
          },
          () => {
            this.$message.success('删除成功')
            this.getDynamicCommentList(this.currentEditId)
          },
          () => {}
      );
    });
  }

  //获取动态列表
  private getDynamicList(): void {
    this.RecordManageAPIClass.getDynamicsTableList(
        {
          ...this.form,
          page: this.tablePage.page,
          limit: this.tablePage.prepage,
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.page = res.data.page;
          this.tablePage.prepage = res.data.prepage;
          this.tablePage.count = res.data.count;
        },
        () => {}
    );
  }

  //获取评论列表
  private getDynamicCommentList(id:number|string): void {
    this.RecordManageAPIClass.getDynamicsCommentTableList(
        {
          dynamics_id:id,
          page: this.commentTableParams.page,
          limit: this.commentTableParams.prepage,
        },
        (res: any) => {
          this.commentTableData = res.data.list;
          this.commentTableParams.page = res.data.page;
          this.commentTableParams.prepage = res.data.prepage;
          this.commentTableParams.count = res.data.count;
        },
        () => {}
    );
  }

}


import dayjs from "@/plugins/dayjs";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import { SystemOptionLogAPI } from "@/common/api/systemManage/systemOptionLog";
import { excuteDownloadExport2Excel } from "@/common/xlsx";
export const pageApiList = getPageAPIs(SystemOptionLogAPI);

@Component({})
export default class SystemOptionLog extends Vue {
  private systemOptionLogApi: any;

  constructor() {
    super();
    this.systemOptionLogApi = new SystemOptionLogAPI();
  }

  private form: any = {
    time: [],
  };

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private activated(): void {
    
    this.search();
  }

  private pickerOptions: any = {
    // shortcuts: [
    //   {
    //     text: "本月",
    //     onClick(picker: any) {
    //       picker.$emit("pick", getCurrentMonthDate());
    //     },
    //   },
    //   {
    //     text: "上月",
    //     onClick(picker: any) {
    //       picker.$emit("pick", getLastMonthDate());
    //     },
    //   },
    // ],
  };

  private getTable(): void {
    this.systemOptionLogApi.getLogList(
      {
       ...this.getSystemOptionLogTableListParams()
      },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.totalCount = res.data.count;
        this.tablePage.pageNo = res.data.page;
        this.tablePage.pageSize = res.data.prepage;
      }
    );
  }

  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 商户字段名 文案对应关系
  private merchantExcelList2ch: any = {
    name: "姓名",
    phone: "手机号",
    updated_at: "操作时间",
    ip: "IP地址",
    __option_record: "操作记录"
  };
  // 商户Excel 表头的先后顺序
  private merchantExcelSortList: string[] = [
    "name",
    "phone",
    "updated_at",
    "ip",
    "__option_record"
  ];

  private getSystemOptionLogTableListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const {
      name, // 姓名
      phone, // 手机号

      time,
    } = form || {};

    // console.log(technicianItem, "technicianItem");

    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};

    if (name) {
      outerParams.name = name;
    }

    if (phone) {
      outerParams.phone = phone
    }

    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      outerParams.start_date = startTime;

      outerParams.end_date = endTime;
    }

    if (
      mode == "exportExcel" &&
      !(outerParams.start_date && outerParams.end_date)
    ) {
      this.$message.error("请选择创建时段导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;
    } else if (mode == "exportExcel") {}

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  // 点击导出excel 表格
  private handleExportExcelMerchantAuditList() {
    this.getTable();
    const params: any = this.getSystemOptionLogTableListParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.systemOptionLogApi.exportExcelOptionLogList(
      params,
      (res: any) => {
        const { start_date, end_date } = params || {};

        const filename = `系统操作记录列表 ${start_date}到${end_date}.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const {
                menu_name,
                up_data
              } = item || {};
              const newItem = {
                ...(item || {}),
              };

              newItem.__option_record = `${menu_name}: ${up_data}`

              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const merchantExcelSortList = this.merchantExcelSortList;

          const merchantExcelList2ch = this.merchantExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            merchantExcelSortList,
            merchantExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        // const testData = [
        //   { 主页: "test1223424", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
        //   { 主页: "433", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
        //   { 名称: "22", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
        //   { 名称: "43", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
        //   { 店家: "43", 价格: "6800", 数量: "6800", 昵称: "广告主网", }
        // ]

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */
}

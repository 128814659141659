import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class MutipleAuthSubShopListAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getMutipleAuthSubShopList', {
      url: '/admin/mtShopBind/subList',
      name: 'list',
      label: '列表'
    }],
    
    ['addMutipleAuthSubShop', {
      url: '/admin/mtShopBind/subAdd',
      name: 'add-mt-auth-sub-shop',
      label: '添加美团绑定店铺'
    }],

    ['deleteMutipleAuthSubShop', {
      url: '/admin/mtShopBind/subDelete',
      name: 'delete-mt-auth-sub-shop',
      label: '删除美团绑定店铺'
    }],
   
   
    ['searchVenueList', {
      url: '/admin/coupons/shop/list',
      name: 'shop-list',
      label: '店铺列表',
      // hidden: true
    }],
  ])

  // 美团绑定子店铺列表
  public getMutipleAuthSubShopList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getMutipleAuthSubShopList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 添加美团绑定店铺
  public addMutipleAuthSubShop(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('addMutipleAuthSubShop').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 删除美团绑定店铺
  public deleteMutipleAuthSubShop(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('deleteMutipleAuthSubShop').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }


  // 搜索店铺列表
  public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('searchVenueList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}
import Vue from 'vue';
import { UserApi } from "@/common/api/userManage/userList"
// 使用 Event Bus
const bus = new Vue();
const userApi = new UserApi();

export default bus;

export class Socket {
    public getNewOrder(e: any): void {
        // userApi.notice({}, (res: any) => {
        //     if (res.data.count != 0) {
        //         e.$notify.info({
        //             title: "新订单",
        //             message: "有一个新的订单，请及时通知技师接单！",
        //             duration: 0,
        //         });
        //     }
        // }, () => { })

    }
}

import { BasicsClass } from '@/common/BasicsClass';

export default class SpringFActivityShieldStoreAPI extends BasicsClass {

  public apiUrls: any = new Map([
    ['list', {
      url: '/admin/newYearActivity/shieldShop/list',
      name: 'list',
      label: '春节活动屏蔽店铺列表'
    }],
    ['add', {
      url: '/admin/newYearActivity/shieldShop/add',
      name: 'add',
      label: '屏蔽店铺春节活动'
    }],
    ['delete', {
      url: '/admin/newYearActivity/shieldShop/delete',
      name: 'delete',
      label: '删除春节活动屏蔽店铺'
    }],
    ['searchVenueList', {
      url: '/admin/coupons/shop/list',
      name: 'shop-list',
      label: '店铺列表',
    }],
  ])
  public getList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {

    params = {
      ...(params || {})
    }

    const apiUrls = this.apiUrls

    const url = apiUrls.get('list').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
  public add(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {

    params = {
      ...(params || {})
    }

    const apiUrls = this.apiUrls

    const url = apiUrls.get('add').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  public delete(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {

    params = {
      ...(params || {})
    }

    const apiUrls = this.apiUrls

    const url = apiUrls.get('delete').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('searchVenueList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

}

import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class FinanceLeaseTakeRateAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getFinanceLeaseTakeRateTableList', {
            url: '/admin/finance_lease/detail',
            name: 'list',
            label: '列表'
        }],
     
    ])

    // 列表
    public getFinanceLeaseTakeRateTableList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getFinanceLeaseTakeRateTableList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
   
 
}
import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class FinanceLeaseListAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getFinanceLeaseList', {
      url: '/admin/finance_lease/list',
      name: 'list',
      label: '列表'
    }],
    ['addFinanceLease', {
      url: '/admin/finance_lease/add',
      name: 'add',
      label: '添加'
    }],
    ['updateFinanceLease', {
      url: '/admin/finance_lease/edit',
      name: 'edit',
      label: '编辑'
    }],
    ['deleteFinanceLease', {
      url: '/admin/finance_lease/delete',
      name: 'delete',
      label: '删除'
    }],
    ['searchVenueList', {
      url: '/admin/coupons/shop/list',
      name: 'shop-list',
      label: '店铺列表',
      // hidden: true
    }],
   
  ])

  // 融资租赁列表
  public getFinanceLeaseList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getFinanceLeaseList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 修改融资租赁
  public updateFinanceLease(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('updateFinanceLease').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 添加融资租赁
  public addFinanceLease(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('addFinanceLease').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 删除融资租赁
  public deleteFinanceLease(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('deleteFinanceLease').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 搜索店铺列表
  public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('searchVenueList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  
}
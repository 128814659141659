import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class GloryShopQRChargeApi extends BasicsClass {
  public apiUrls: any = new Map([
    ['getGloryShopQRList', {
        url: '/admin/shopQrCode/glory',
        name: 'list',
        label: '列表'
    }],
])

  // 单店卡充值列表
  public getGloryShopQRList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getGloryShopQRList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

}

import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import { ServicedRecordAPI } from "@/common/api/customerService/servicedRecord";

import { CommonApi } from "@/common/api/common";

import gallery from "@/components/gallery.vue";

import chatDialog from "@/components/panda-service/chatDialog.vue";

// import MerchantApi from "@/common/api/merchant";
// import { util } from "vue/types/umd";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import ZlSelect from "@/components/third-custom-components/select";

import ZlOption from "@/components/third-custom-components/option";

import { excuteDownloadExport2Excel } from "@/common/xlsx";

// import {
//   shareConfirmOptions,
//   getCommonList
// } from "@/common/config";
export const pageApiList = getPageAPIs(ServicedRecordAPI);


// 商家端店铺类型 0未知 1台球商家端  2棋牌商家端


// const defaultForms = {
//   // media_type 媒体类型:0图片,1视频
//   media_list: [],
//   title: "", // 标题
//   content: "", // 内容
//   sort: "", // 排序
//   // singleMaxCountType: '0',
//   // shop_id: "",
//   // maxLimitCount: ''
// };

const defaultForm = {
  venue_id: "",
  venue: {
    id: "",
    name: "",
  },

  docking_id: 0, // 0 全部

  time: [
    
  ], // 日期
}

const defaultSearchVenuePageOption = {
  page: 1,
  limit: 10,
  isRearchBottom: false,
};

// const competitionPointDialogTitle2ch: any = {
//   "new-quick-reply": "新增快捷回复",
//   "edit-quick-reply": "编辑快捷回复",
// };

// function createPicItem(id: number, url: string) {
//   const item = {
//     id,
//     url,
//     href: url,
//     type: "image/png",
//     types: "1",
//   };

//   return item;
// }

// function createVideoItem(id: number, url: string) {
//   const item = {
//     id,
//     url,
//     href: url,
//     type: "video/mp4",
//     types: "2",
//     poster: `${url}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`,
//   };

//   return item;
// }

// 最大有效期
// const maxExpiredDays = 9999;

// 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;

// const maxBenefitRate = 100

// 状态 0进行中 1未解决  2已解决
const serRecordStatus2Ch: string[] = ['进行中', '未解决', '已解决']

const getShowDurationTime = (diffTs: number, isPadZero: boolean = false) => {
  const padZero = (num: number, isPadZero: boolean = false) => isPadZero ? (num >= 10 ? num : "0" + num) : num;
  const restSecs = diffTs % 60;

  const overSecs = Math.floor(diffTs / 60);

  if (overSecs > 0) {
    const restMins = overSecs % 60;

    const overMins = Math.floor(overSecs / 60);

    if (overMins > 0) {
      return `${padZero(overMins, isPadZero)}小时${padZero(restMins, isPadZero)}分钟${padZero(
        restSecs, isPadZero)}秒`;
    } else {
      return `${padZero(restMins, isPadZero)}分钟${padZero(restSecs, isPadZero)}秒`;
    }
  } else {
    return `${padZero(restSecs)}秒`;
  }
};


@Component({
  filters: {
    // showPicOrThumbnail(row: any) {
    //   const { image_list, video_list } = row || {};

    //   if (Array.isArray(image_list) && image_list.length > 0) {
    //     return image_list[0].url || ""
    //   }

    //   if (Array.isArray(video_list) && video_list.length > 0) {
    //     return video_list[0] ? `${video_list[0].url}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto` ||
    //             ""
    //         : "";
    //   }

    //   return ""
    // },

    showSerRecordStatusName(status: number) {
      return serRecordStatus2Ch[status] || '未知状态'
    },

    getShowDurationTime
  },
  components: {
    gallery,
    ZlSelect,
    ZlOption,
    "chat-dialog": chatDialog,
  },
})
export default class quickRepliesManage extends Vue {
  private form: any = {
    
  };
  // private forms: any = {
  //   // maxType: "0",
  //   // ...defaultForms
  // };

  private deepLoopUtil: any = {};

  // private merchantApi: any;

  private servicedRecordApi = new ServicedRecordAPI();


  private commonApi = new CommonApi();


  // private couponsManageAPI = new CouponsManageAPI();
  // private competitionPointTimeDurationAPI = new quickReplyManageTimeDurationManageAPI();
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    // this.forms = {
    //   ...this.deepLoopUtil.deepCopy(defaultForms, false),
    // };

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm, false),
    }


   
  }

  private servingWaiterList: any[] = [];

  private refreshServingWaiterList() {
    this.servicedRecordApi.getServicedReceiverList({}, (res: any) => {
      const {
        list
      } = res.data || {}

      if (Array.isArray(list) && list.length) {
        this.servingWaiterList = list
      } else {
        this.servingWaiterList = []
      }
    }, (err: any) => {})
  }

  private created(): void {
    this.getServicedRecords();

    // this.refreshServicedRecordTimeDurations();

    // this.refreshTableClass();
  }

  private activated(): void {
    this.refreshServingWaiterList()
  }


  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getServicedRecords();
  }
  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getServicedRecords();
  }

  private tableData: any[] = [];

  // 上传视频
  // private userAvatarUploading: boolean = false;
  // private handleUploadUserVideo(file: any, fileList: any[]) {
  //   console.log(file, fileList, "file", "fileList");
  //   this.userAvatarUploading = true;
  //   this.commonApi.getOssvideo(
  //     file instanceof File ? file : file.raw,
  //     (res: any) => {
  //       console.log(res, "res 视频上传成功");

  //       const { data } = res;

  //       const { data: callbackData } = data;

  //       const { item: uploadImgObj } = callbackData || {};

  //       const { id, url } = uploadImgObj;

  //       // this.$set(this.forms, "video_id", id);
  //       // this.$set(this.forms, "video_url", url);

  //       this.forms.study_video = [createVideoItem(id, url)];

  //       this.userAvatarUploading = false;
  //     },
  //     ({ errMsg }: any) => {
  //       if (errMsg == "out of limit duration") {
  //         this.$message.error("视频时长不可超过1小时");
  //       } else {
  //         this.$message.error("视频上传失败");
  //       }

  //       this.userAvatarUploading = false;
  //     },
  //     {
  //       limit_duration: 60 * 60, // 单位 秒  1h
  //     },
  //     file.url
  //   );
  // }

  // private handleRemoveStudyVideo() {
  //   this.forms.study_video = [];
  // }

  // private uploadMediaOss(file: File): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     if (file.type && file.type.startsWith('image/')) {
  //       this.commonApi.getOss(
  //         file,
  //         (res: any) => {
  //          resolve({
  //           _media_type: 'image', 
  //           ...res
  //          })
  //         },
  //         (err: any) => {
  //           reject(err)
  //         }
  //       );
  //     } else if (file.type && file.type.startsWith('video/')) {
  //       this.commonApi.getOssvideo(
  //       file,
  //       (res: any) => {
  //         resolve({
  //           _media_type: 'video', 
  //           ...res
  //         })
  //       },
  //       (err: any) => {
  //         reject(err)
  //       }
  //     );
  //     }
  //   })
  // }

  // private async handleChangeUploadPic(file: any, fileList: any[]) {
  //   console.log(file, fileList, "file", "fileList");

  //   const {
  //     _media_type: mediaType,
  //     data: uploadOriginData = {}
  //   } = await this.uploadMediaOss(
  //     file instanceof File ? file : file.raw,
  //   ).catch(() => {
  //     this.$message.error('媒体文件上传失败')
  //   }) ;


  //   const { data: uploadImgObjWrap } = uploadOriginData;

  //   const { item: uploadImgObj } = uploadImgObjWrap;

  //   const { id, url } = uploadImgObj;

  //   switch (mediaType) {
  //     case 'image':
  //       this.forms.media_list.push(createPicItem(id, url));
  //       break;
  //     case 'video':
  //       this.forms.media_list.push(createVideoItem(id, url));
  //       break;
  //   }

    
  // }

  // private handleRemoveStudyPicture(file: any) {
  //   const forms = this.forms || {};
  //   const existIndex = forms.media_list.findIndex(
  //     (picFile: any) => picFile == file
  //   );

  //   if (existIndex > -1) {
  //     forms.media_list.splice(existIndex, 1);
  //   }
  // }

  // // 查看视频/图片
  // private handleBeforePreviewMedia(row: any) {
  //   const { image_list, video_list } = row || {};

  //   const previewItem: any = {};

  //   if (Array.isArray(image_list) && image_list.length > 0) {
  //     previewItem.image_count = image_list.length;

  //     previewItem.images = image_list.map((pic: any) => pic.url);
  //   }

  //   if (Array.isArray(video_list) && video_list.length > 0) {
  //     previewItem.video_count = video_list.length;

  //     previewItem.videos = video_list.map((pic: any) => pic.url);
  //   }
    
  //   this.handlePreviewMedia(previewItem);
  // }

  // private images: any = [];

  // private handleBeforePreviewPicture(row: any) {
  //   const forms = this.forms

  //   const {
  //     media_list
  //   } = forms || {}
    

  //   const urls: any[] = [];

  //   media_list.forEach((item: any) => {
  //     switch (item.types) {
  //       case "1":
  //         urls.push({
  //           title: "",
  //           type: "image/jpeg",
  //           href: item.url,
  //           thumbnail: item.url,
  //         })
  //         break;
  //       case "2":
  //         urls.push({
  //           title: "",
  //           type: "video/mp4",
  //           href: item.url,
  //           poster: `${item.url}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`,
  //         })
  //         break;
  //     }
  //   })

  //   this.images = urls;
  // }

  // private handlePreviewMedia(row: any) {
  //   const { image_count, images, video_count, videos } = row || {};

  //   const urls: any[] = [];

  //   if (image_count > 0) {
  //     urls.push(
  //       ...images.map((img: string) => {
  //         return {
  //           title: "",
  //           type: "image/jpeg",
  //           href: img,
  //           thumbnail: img,
  //         };
  //       })
  //     );
  //   }

  //   if (video_count > 0) {
  //     urls.push(
  //       ...videos.map((video: string) => {
  //         return {
  //           title: "",
  //           type: "video/mp4",
  //           href: video,
  //           poster: `${video}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`,
  //         };
  //       })
  //     );
  //   }

  //   console.log(urls, 'urls')

  //   this.images = urls;
  // }

  //dialog
  // private dialogFormVisible: boolean = false;

  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getServicedRecords();
  }

  private dialogHistoryChatVisible: boolean = false

  private curHistoryChatItem: any = {};

  private dialogFirstIns: boolean = true

  // 拉起聊天记录弹框 
  private handleWakeHistoryTalkDialog(serChatItem: any) {
    this.curHistoryChatItem = serChatItem
    
    this.dialogHistoryChatVisible = true

    if (this.dialogFirstIns) {
      this.dialogFirstIns = false
      this.$nextTick(() => {
        const pandaChatDialogIns: any = this.$refs.pandaChatDialog || {}

        const {
          initPandataChatList
        } = pandaChatDialogIns

        if (typeof initPandataChatList == 'function') {
          initPandataChatList()
        }
      })
    }
  }


  //获取快捷回复列表
  private getServicedRecords(): void {
    this.servicedRecordApi.getServicedRecordList(
      { ...this.getServicedRecordParams() },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page;
        this.tablePage.prepage = res.data.prepage;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private getServicedRecordParams(mode: string = "all"): any {
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    const { venue, docking_id, time } = form;

    if (venue && venue.id) {
      params.shop_id = venue.id;
    }

    if (typeof docking_id == 'number') {
      params.docking_id = docking_id
    }

    if (Array.isArray(time) && time.length === 2) {
      const [startTime, endTime] = time;

      // const startTime = Util.dateTimeUtilnohms(startDate);
      // const endTime = Util.dateTimeUtilnohms(endDate);

      params.start_date = startTime;

      params.end_date = endTime;
    }

    if (
      mode == "exportExcel" &&
      !(params.start_date && params.end_date)
    ) {
      this.$message.error("请选择时段导出数据");
      return null;
    }

    
    if (mode === "all") {
      params.page = page;

      params.limit = prepage;
    }

    return params;
  }

  private venueList: any[] = [
    // {
    //   id: 1,
    //   label: '测试'
    // },
    // {
    //   id: 2,
    //   label: '测试2'
    // }
  ];

  private searchVenueLoading: boolean = false;

  private remoteGetVenueQueryString: string = "";

  private remoteSearchVenueTimer: any = null;

  private searchVenuePageOption: any = {};

  private initRemoteGetVenuePageOption() {
    this.searchVenuePageOption = {
      ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    };
  }

  private getSearchVenueParams() {
    const searchVenuePageOption = this.searchVenuePageOption;

    const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

    const { page, limit } = searchVenuePageOption;

    const params: any = {
      page,
      limit,
    };

    if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
      params.name = remoteGetVenueQueryString.trim();
    }

    return params;
  }

  private getMoreVenueList(refresh: boolean = true) {
    return new Promise((resolve: Function, reject: Function) => {
      if (refresh) {
        this.initRemoteGetVenuePageOption();
      }

      const params = this.getSearchVenueParams();

      this.servicedRecordApi.searchVenueList(
        params,
        (res: any) => {
          console.log(res, "res");

          const venueList: any[] = res.data || [];

          this.venueList = refresh
            ? venueList
            : this.venueList.concat(venueList);

          if (Array.isArray(res.data) && res.data.length == 0) {
            this.searchVenuePageOption.isRearchBottom = true;
          }
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          resolve();
        },
        () => {
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          reject();
        }
      );
    });
  }

  // 第一次搜索 无关键字
  private handleFirstGetVenueList() {
    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 清空搜索
  private handleClearGetVenueList() {
    this.remoteGetVenueQueryString = "";

    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 远程搜索
  private remoteGetVenueMethod(query: string) {
    query = query.trim ? query.trim() : "";

    if (query !== "") {
      this.remoteGetVenueQueryString = query;
      clearTimeout(this.remoteSearchVenueTimer);

      this.remoteSearchVenueTimer = setTimeout(() => {
        this.searchVenueLoading = true;

        this.getMoreVenueList();
        // searchStu((res) => {
        //   const {
        //     students,
        //     total_count
        //   } = res || {}

        //   this.remoteAllStudents = [
        //     ...(students || [])
        //   ]

        //   this.remoteStudentTotalCount = total_count || 0

        //   this.remoteGetStudentLoading = false
        // }, {
        //   kw: query,
        //   ...this.getRemoteGetStudentPageOptionParams()
        // }, () => {
        //   this.remoteGetStudentLoading = false
        // })
      }, 300);
      // setTimeout(() => {
      //   this.remoteGetStudentLoading = false;
      //   this.options = this.list
      // }, 200);
    } else {
      this.remoteGetVenueQueryString = "";
      this.venueList = [];
    }
  }

  private addVenueListLoading: boolean = false;

  private remoteAddVenueMethod() {
    if (this.addVenueListLoading) return;
    const searchVenuePageOption = this.searchVenuePageOption;

    const { isRearchBottom } = searchVenuePageOption;

    if (isRearchBottom) return;

    this.searchVenuePageOption.page++;

    this.addVenueListLoading = true;

    this.getMoreVenueList(false);
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 用户字段名 文案对应关系
  private userExcelList2ch: any = {
    "shop_name": "店铺名称",
    "mobile": "手机号",
    "start_time": "咨询时间",
    "end_time": "咨询结束时间",
    "__show_duration": "咨询时长",
    "__show_receptionist": "对接人",
    "__ser_status": "状态"
   
  };
  // 用户Excel 表头的先后顺序
  private userExcelSortList: string[] = [
    "shop_name",
    "mobile",
    "start_time",
    "end_time",
    "__show_duration",
    "__show_receptionist",
    "__ser_status"
  ];

  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getServicedRecordParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.servicedRecordApi.exportExcelServicedRecordList(
      params,
      (res: any) => {
        const { start_date, end_date } = params || {};

        const filename = `客服服务记录列表 ${start_date}到${end_date}.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const {
                // name,
                // pay_mode

                duration,
                receptionist,
                is_status
                
              } = item;
              const newItem = {
                ...(item || {}),
                // __goods_info: `${name}x1`,
                // __pay_name: payMode2ch[pay_mode] || ''

                __show_duration: getShowDurationTime(duration),
                __show_receptionist: receptionist.map((recepter: any) => `${recepter.service_name}(${ recepter.realname })`).join('\n'),

                __ser_status: serRecordStatus2Ch[is_status] || '未知状态'
              };


              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const userExcelSortList = this.userExcelSortList;

          const userExcelList2ch = this.userExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            userExcelSortList,
            userExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };
}

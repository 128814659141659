import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class ChatRecordQueryAPIClass extends BasicsClass {

    public apiUrls: any = new Map([
        ['getIMChatRecordList', {
            url: '/admin/im/chat',
            name: 'list',
            label: '聊天记录'
        }]

    ])
    // 聊天记录
    public getIMChatRecordList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {

        params = {
            ...(params || {})
        }

        const apiUrls = this.apiUrls

        const url = apiUrls.get('getIMChatRecordList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

}

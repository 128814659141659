import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

import { CommonApi } from "../common";

export class ChessorderApi extends BasicsClass {

  public apiUrls: any = new Map([
    ['getOrderTableList', {
        url: '/admin/gloryOrder/list',
        name: 'list',
        label: '列表'
    }],
    // debugger changeExport;
    ['exportExcelOrderList', {
        url: '/admin/gloryOrder/exportList',
        name: 'export-excel',
        label: '导出'
    }],

])
  // 订单api 方法
  // momo达到家-订单列表
  public getOrderTableList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getOrderTableList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // momo达到家-导出订单列表
  public exportExcelOrderList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('exportExcelOrderList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

}


import {Component, Vue} from "vue-property-decorator";
import {Util, getPageAPIs} from "@/common/util";
import SpringFActivityHorseAPI from "@/common/api/operationManage/springFActivityHorse";
import {shareConfirmOptions} from '@/common/config';
interface InterfaceAddForms  {
  content: string,
  id?:string
}
const default_add_forms:InterfaceAddForms = {
  content: '',
}
export const pageApiList = getPageAPIs(SpringFActivityHorseAPI)

@Component({})
export default class SpringFActivityHorse extends Vue {
  private springFActivityHorseApi: any;
  private title: string = "新增";
  private is_show: boolean = false;
  private tableData: any[] = [];
  private operateType:string = 'add'

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };
  private departmentList:any = []
  private add_forms: any = {
    ...default_add_forms
  };
  constructor() {
    super();
    this.springFActivityHorseApi = new SpringFActivityHorseAPI();
  }

  private addDialog(type:string='add',row:any): void {
    this.operateType = type
    this.is_show = true;
    this.$nextTick(()=>{
      if(this.operateType==='edit'){
        this.add_forms = {
          content: row.remark,
          id:row.id
        }
      }else{
        this.add_forms = {
          ...default_add_forms
        }
      }
    })


  }

  private submit(): void {
    console.log('this.add_forms', this.add_forms)
    if(this.operateType==='edit'){
      this.springFActivityHorseApi.edit(this.add_forms, () => {
        this.is_show = false;
        Util.showToast("编辑成功");
        this.search();
      });
    }else{
      this.springFActivityHorseApi.add(this.add_forms, () => {
        this.is_show = false;
        Util.showToast("新增成功");
        this.search();
      });
    }
  }

  private secure(row: any) {
    this.$confirm(`确认删除${row.remark}吗？`, '', {
      ...(shareConfirmOptions || {})
    }).then(() => {
      this.springFActivityHorseApi.delete({id: row.id},
          () => {
            this.is_show = false;
            Util.showToast("删除成功");
            this.search();
          }
      );
    })
  }

  private activated(): void {
    this.search();
  }


  private getTable(): void {
    this.springFActivityHorseApi.getList(
        {
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
        }
    );
  }



  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getTable();
  }

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;
    this.getTable();
  }

}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getPageAPIs } from "@/common/util";
import { CoachInfoManageApi } from "@/common/api/userManage/coachInfoManage";
import { CommonApi } from "@/common/api/common";

import gallery from "@/components/gallery.vue";

import { excuteDownloadExport2Excel } from "@/common/xlsx";

import { shareConfirmOptions } from "@/common/config";
export const pageApiList = getPageAPIs(CoachInfoManageApi);

const getCoachExpirenceTimes = (start: number = 1, end: number = 30): any[] => {
  const list: any[] = [];

  for (let i = start; i <= end; ++i) {
    list.push({
      label: `${i}年`,
      value: i,
    });
  }

  return list;
};

// 助教等级 1初级助教 2中级助教 3高级助教 4金牌助教 5初级教练 6中级教练 7高级教练 8金牌教练
const coachLevelType2ch = [
  "",
  "初级助教",
  "中级助教",
  "高级助教",
  "金牌助教",
  "初级教练",
  "中级教练",
  "高级教练",
  "金牌教练",
];

// 是否真人认证 0否 1是
const showCertifiedActualPerson2ch: string[] = ['未认证', '已认证']

@Component({
  components: {
    gallery,
  },
  filters: {
    getCoachStatus(is_show: number) {
      const statusType2ch: any = ["", "上架", "下架"];

      return statusType2ch[is_show] || "";
    },
    getCoachLevelName(level: number) {
      return coachLevelType2ch[level] || "";
    },
    showVideoPoster(videoList: any[]): string {
      const firstVideo: any = videoList[0] || {};

      if (firstVideo.url) {
        return `${firstVideo.url}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`;
      }

      return "";
    },

    showActualPersonCertifiedName(is_certified: number) {

      return showCertifiedActualPerson2ch[is_certified] || ''
    }
  },
})
export default class CommentManage extends Vue {
  private utilInstance: any;
  private coachInfoManageApi: any;

  private commonApi: any;

  constructor() {
    super();
    this.coachInfoManageApi = new CoachInfoManageApi();

    this.commonApi = new CommonApi();

    this.utilInstance = new Util();

  }

  private firstLoading: boolean = true;

  private form: any = {
    // searchKw: "", // 搜索用户昵称/用户ID/手机号
    user_id: "", // 助教ID
    nickname: "", // 助教昵称
    is_show: -1, // 助教状态 全部
    phone: "", // 手机号
    time: [], // 时间选择
  };

  private coachStatusList: any[] = [
    {
      label: "全部",
      value: -1,
    },
    {
      label: "上架",
      value: 1,
    },
    {
      label: "下架",
      value: 2,
    },
  ];


  // private showtimecard: boolean = false;
  private activated(): void {
    this.getTable();
  }
  private getTable(noUnique = false): void {
    this.coachInfoManageApi.getCoachTableList(
      this.getCoachManageTableListParams("all", noUnique),
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        const { list, count, page, prepage } = data || {};

        const newTableList = Array.isArray(list)
          ? list.map(this.handleTableData)
          : [];
        this.$set(this.tablePage, "totalCount", count);
        this.$set(this.tablePage, "pageNo", page);
        this.$set(this.tablePage, "pageSize", prepage);

        this.tableData = newTableList;

        this.firstLoading = false;
      },
      () => {
        this.firstLoading = false;
      }
    );
  }

  // 预览列表媒体文件
  private handlePreviewMedia(row: any, mode: string) {
    const medias: any[] = [];
    if (mode == "image") {
      medias.push(
        ...(row.pictureList || []).map((pic: any) => {
          return {
            title: "",
            type: "image/jpeg",
            href: pic.url,
            thumbnail: pic.url,
          };
        })
      );
    } else if (mode == "video") {
      medias.push(
        ...(row.videoList || []).map((video: any) => {
          return {
            title: "",
            type: "video/mp4",
            href: video.url,
            poster: `${video.url}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`,
          };
        })
      );
    }

    this.previewImages = medias;
  }

  private getCoachManageTableListParams(mode = "all", noUnique = false) {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const firstLoading = this.firstLoading;

    const {
      user_id, // 助教ID
      nickname, // 助教昵称
      is_show, // 助教状态 全部
      phone, // 手机号
      time, // 时间选择
    } = form || {};

    // console.log(technicianItem, "technicianItem");

    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};

    if (user_id) {
      outerParams.user_id = user_id - 0;
    }

    if (nickname) {
      outerParams.nickname = nickname;
    }

    if (phone) {
      outerParams.phone = phone;
    }

    if (typeof is_show == "number" && is_show != -1) {
      outerParams.is_show = is_show;
    }

    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      outerParams.start_date = startTime;

      outerParams.end_date = endTime;
    }

    if (
      mode == "exportExcel" &&
      !(outerParams.startDate && outerParams.endDate)
    ) {
      this.$message.error("请选择时段导出数据");
      return null;
    }


    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      outerParams.__isUnique = !firstLoading && !noUnique;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  // 后台返回的字段 转化为前端定义的字段
  private handleTableData(data: any): any {
    const {
      
    } = data;
    const newData = {
      ...data,
     
    };


    return newData;
  }

  private coachMerchantListVisible: boolean = false;

  private currentCoachMerchantManageItem: any = {};

  private coachMerchantList: any = [];

  private coachMerchantListPage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handleTechnicianCommentPageChange(val: any) {
    this.coachMerchantListPage.pageNo = val.currentPage;
    this.coachMerchantListPage.pageSize = val.pageSize;

    this.getCoachMerchantList();
  }

  private getCoachMerchantListParams(): any {
    const coachMerchantListPage = this.coachMerchantListPage;

    const currentCoachMerchantManageItem = this.currentCoachMerchantManageItem;

    console.log(
      currentCoachMerchantManageItem,
      "currentCoachMerchantManageItem"
    );

    const { pageNo, pageSize } = coachMerchantListPage || {};

    const outerParams: any = {
      user_id: currentCoachMerchantManageItem.user_id,
    };

    outerParams.page = pageNo;

    outerParams.limit = pageSize;

    return outerParams;
  }

  private getCoachMerchantList() {
    this.coachInfoManageApi.getCoachMerchantTableList(
      this.getCoachMerchantListParams(),
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        const { list, count, page, prepage } = data || {};

        const newTableList = Array.isArray(list)
          ? list.map(this.handleCoachMerchantTableData)
          : [];

        // totalCount: 0,
        // pageNo: 1,
        // pageSize: 10,
        this.$set(this.coachMerchantListPage, "totalCount", count);
        this.$set(this.coachMerchantListPage, "pageNo", page);
        this.$set(this.coachMerchantListPage, "pageSize", prepage);

        this.coachMerchantList = newTableList;

        this.firstLoading = false;
      },
      () => {
        this.firstLoading = false;
      }
    );
  }

  private handleCoachMerchantTableData(data: any): any {
    const {
     
      inviteTotal,
    } = data;
    const newData = {
      ...data,
     
    };

    return newData;
  }

  // 跳转加盟商页面
  private handleGoAllianceManage(coachItem: any) {
    this.$router.push({
      path: '/allianceBusinessList',
      query: {
        user_id: coachItem.user_id || ''
      }
    })
  }

  private handleWakeCoachMerchantListDialog(coachItem: any) {
    // console.log(technicianItem, "technicianItem");

    this.coachMerchantListPage.pageNo = 1;
    this.coachMerchantList = [];
    this.coachMerchantListPage.totalCount = 0;

    this.currentCoachMerchantManageItem = {
      ...coachItem,
    };

    this.getCoachMerchantList();

    this.coachMerchantListVisible = true;
  }


  private currentEditCoachItem: any = null;

  private handleWakeEditTechnicianDialog(coachItem: any, mode: string) {
    console.log(coachItem, "coachItem");
    this.currentEditCoachItem = {
      ...coachItem,
    };

    this.initEditCoachForm();

    this.editUserInfoDialogVisible = true;

    this.$nextTick(() => {
      this.clearvaliateEditUserForm();
    });
  }

  private initEditCoachForm() {
    const currentEditCoachItem = this.currentEditCoachItem;

    const {
      id,
      nickname,
      user_id,
      avatar,
      pictureList,
      videoList,
      // order_num,
      level,
      // income,
      number_id_avatar,
      number_id_avatar_behind,
      is_show,
      career,
      field,
    } = currentEditCoachItem || {};

    const idCardImgs = [];

    if (number_id_avatar) {
      idCardImgs.push(number_id_avatar);
    }

    if (number_id_avatar_behind) {
      idCardImgs.push(number_id_avatar_behind);
    }

    const editUserForm = {
      id,
      coachId: user_id,
      avatar,
      advantages: field,
      nickname,
      expirenceTime: career ? career - 0 : "",
      images: [...pictureList],
      videos: [
        ...videoList.map((item: any) => {
          const newItem = {
            ...item,
          };

          newItem.poster = `${item.url}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`;

          return newItem;
        }),
      ],
      idCardImgs: idCardImgs,
      level,
      is_show: is_show,
    };

    this.editUserForm = editUserForm;
  }


  // 清空表单的校验
  private clearvaliateEditUserForm() {
    const refs: any = this.$refs;

    const { editUserForm } = refs;

    const { clearValidate } = editUserForm || {};

    if (typeof clearValidate == "function") {
      clearValidate();
    }
  }


  // 上传媒体文件
  private handleUploadUserPhoto(file: any, fileList: any[]) {
    this.handleUploadUserMedia(file, fileList, "image");
  }

  private handleUploadUserVideo(file: any, fileList: any[]) {
    this.handleUploadUserMedia(file, fileList, "video");
  }
  private async handleUploadUserMedia(
    file: any,
    fileList: any[],
    mode: string
  ) {
    console.log(file, "file");

    function getVideoDuration(url: string): any {
      return new Promise((resolve, reject) => {
        var audio = new Audio(url);
        audio.muted = true;
        audio.play().then(() => audio.pause());
        // 元数据已加载

        const handleLoadedmetadata = function (e: any) {
          // let duration = audio.duration;
          console.log(e, "e audio");
          resolve({
            url,
            duration: audio.duration,
          });

          hanleClearListeners();
        };

        const hanleClearListeners = function () {
          audio.removeEventListener("loadedmetadata", handleLoadedmetadata);
          audio.onerror = null;
        };
        audio.addEventListener("loadedmetadata", handleLoadedmetadata);

        audio.onerror = function (err) {
          reject(err);
        };

        audio.muted = false;
      });
    }

    const editForm: any = this.editUserForm;

    const optionList = mode == "image" ? editForm.images : editForm.videos;

    const sendMediaApi =
      mode == "image" ? this.commonApi.getOss : this.commonApi.getOssvideo;

    if (mode == "video" && !file.duration) {
      const { duration } = await getVideoDuration(file.url).catch(
        (err: any) => {
          this.$message("获取时长失败");
        }
      );

      file.duration = duration;
    }

    optionList.push(file);

    console.log(editForm, "editForm");

    this.$set(this.editUserForm, `${mode}s`, optionList);

    this.changeFilePropByList(file, mode, { uploading: true });
    sendMediaApi.call(
      this.commonApi,
      file instanceof File ? file : file.raw,
      (res: any) => {
        console.log(res, "res 图片上传成功");

        const { data } = res;

        const { data: uploadFileObj } = data || {};

        const { item: uploadFile } = uploadFileObj || {};

        const { id, url } = uploadFile;

        // this.$set(this.editUserForm, 'avatar_id', id)
        // this.$set(this.editUserForm, 'avatar', url)
        const changeProps: any = {
          uploading: false,
          fileId: id,
          uploadUrl: url,
        };

        if (file.duration) {
          changeProps.duration = file.duration.toString();
        }

        this.changeFilePropByList(file, mode, changeProps);
      },
      () => {
        this.$message.error("图片上传失败");

        this.changeFilePropByList(file, mode, {
          uploading: false,
          failed: true,
        });
      }
    );
  }

  private changeFilePropByList(file: any, mode: string, changes: any = {}) {
    const editForm: any = this.editUserForm;

    const setList: any[] = mode == "image" ? editForm.images : editForm.videos;
    const existFileIndex = setList.findIndex(
      (each_file: any) => each_file.uid == file.uid
    );

    if (existFileIndex > -1) {
      const originFile = setList[existFileIndex];

      if (Array.isArray(setList)) {
        setList.splice(existFileIndex, 1, Object.assign(originFile, changes));
      }

      this.$set(this.editUserForm, `${mode}s`, setList);
    }
  }

  // 删除媒体文件
  private handleFileRemove(file: any, mode: string) {
    const editUserForm = this.editUserForm;

    const images = mode == "image" ? editUserForm.images : [];

    const videos = mode == "video" ? editUserForm.videos : [];

    console.log(file, "file");

    const optionList = images.length > 0 ? images : videos;

    const existRemoveFileIndex = optionList.findIndex(
      (each_file: any) => each_file.uid == file.uid
    );

    if (existRemoveFileIndex > -1) {
      optionList.splice(existRemoveFileIndex, 1);

      this.$set(this.editUserForm, `${mode}s`, optionList);
    }
  }

  // 预览媒体文件
  private previewImages: any[] = [];
  private handlePictureCardPreview(file: any, mode: string) {
    const editUserForm = this.editUserForm;

    const images = mode == "image" ? editUserForm.images : [];

    const videos = mode == "video" ? editUserForm.videos : [];

    console.log(file, "file");

    const urls = [
      ...images.map((img: any) => {
        return {
          title: "",
          type: "image/jpeg",
          href: img.url,
          thumbnail: img,
        };
      }),
      ...videos.map((video: any) => {
        return {
          title: "",
          type: "video/mp4",
          href: video.url,
          poster: `${video.url}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`,
        };
      }),
    ];

    this.previewImages = urls;
  }

  private editUserInfoDialogVisible: boolean = false;

  private editUserInfoLoading: boolean = false;

  private coachLevelList: any[] = coachLevelType2ch
    .map((name, index) => {
      const item = {
        label: name,
        value: index,
      };

      return item;
    })
    .filter((item) => !!item.label);

  private editUserForm: any = {
    coachId: "", // 助教ID
    // realname: "", // 助教真实姓名
    nickname: "", // 助教昵称
    avatar: "", // 助教头像

    advantages: "", // 个人标签

    // 可修改字段
    expirenceTime: "", // 从业年龄
    images: [], // 生活照
    videos: [], // 视频
    idCardImgs: [], // 身份证照片
    level: "", // 助教等级
    is_show: 1, // 上架状态 1 上架 ；  2 下架
  };

  private allExpirenceList: any[] = getCoachExpirenceTimes();


  private handleSubmitEditUserInfo() {
    const params = this.getEditUserInfoParams();

    if (!params) {
      return;
    }

    console.log(params, "params");

    this.editUserInfoLoading = true;

    this.coachInfoManageApi.updateCoachInfo(
      params,
      () => {
        this.editUserInfoLoading = false;
        this.editUserInfoDialogVisible = false;

        this.getTable();
      },
      () => {
        this.editUserInfoLoading = false;
      }
    );
  }

  private getEditUserInfoParams(): any {
    const editUserForm = this.editUserForm;

    const params: any = {};

    const {
      id,
      coachId,
      nickname,
      level,
      images,
      videos,
      expirenceTime,
      is_show,
    } = editUserForm;

    params.id = id;

    params.user_id = coachId;

    if (nickname) {
      params.nickname = nickname;
    } else {
      this.$message("助教昵称不可为空");

      return false;
    }

    if (expirenceTime) {
      params.career = expirenceTime - 0;
    } else {
      this.$message("从业时间不可为空");

      return false;
    }

    if (level) {
      params.level = level || 1;
    } else {
      this.$message("助教等级不可为空");

      return false;
    }

    if (Array.isArray(images) && images.length) {
      if (images.some((image) => image.uploading || image.failed)) {
        this.$message("有正在上传或者上传失败的文件，请稍等或重新上传");
        return false;
      }
      params.picture_list = images.map((item) => {
        return {
          url: item.uploadUrl || item.url,
        };
      });
    }

    if (Array.isArray(videos) && videos.length) {
      if (videos.some((video) => video.uploading || video.failed)) {
        this.$message("有正在上传或者上传失败的文件，请稍等或重新上传");
        return false;
      }
      params.video_list = videos.map((item) => {
        return {
          url: item.uploadUrl || item.url,
          duration: item.duration,
        };
      });
    }

    console.log(is_show, "is_show");

    params.is_show = is_show;

    return params;
  }

  private restForm(): void {
    this.form = this.utilInstance.clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}

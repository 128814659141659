import Vue from 'vue';
import router from '@/router/index';
import store from '@/store/index';

import VueRouter from 'vue-router';
import { Store } from 'vuex';
import { Axios, AxiosData, Header } from '@/common/http/http';
import { Util } from '@/common/util';
import { AxiosResponse } from 'axios';

export enum CardType {
    '学生卡' = 1,
    '校徽卡',
    '教师卡'
}

export interface CardInterFace<T> {
    id: T;
    mchId: T;
    schoolId: T;
    number: T;
    type: T;
    isBind: T;
    createTime: T;
    updateTime: T;
    createUserId: T;
    createUsername: T;
    building_number?: T;
}

export class BasicsClass {
    protected vue: Vue;
    protected $router: VueRouter;
    protected $store: Store<any>;
    protected $axios: Axios;
    protected $headers: Header;

    constructor() {
        this.vue = new Vue();
        this.$router = router;
        this.$store = store;
        this.$axios = new Axios();
        this.$headers = this.$axios.headers;
    }


    public BasicGet(url: string, params: any, showSuccessMessage = true, showMessage = false, successCallBack?: Function, errCallBack?: Function, setLoading: boolean = false) {
        if (url == '/animal/web/newInsuranceLargeScreen/leaveIllegallyCountToo') {
            console.log(params)

        }
        this.$axios.get(url, {
            ...params,
        }, setLoading).then(data => {
            console.log(data);
            // showSuccessMessage && Util.showToast(data.msg);
            successCallBack && successCallBack(data);
        }).catch(err => {
            console.log(err)

            // if (err.code) {
            //     Util.showToast(err.msg, 'no');
            // }
            errCallBack && errCallBack()
        });
    }

    public BasicPost(url: string, params: any, showSuccessMessage = false, showMessage = false, json: any = false, successCallBack?: Function, errCallBack?: Function, setLoading: boolean = false) {
        this.$axios.post(url, {
            ...params,
        }, json, setLoading).then(data => {
            // Util.showToast('3521432511352');
            // showSuccessMessage && Util.showToast(data.msg);
            successCallBack && successCallBack(data);

        }).catch((err) => {
            console.log(err)
            // if (err.code) {
            //     Util.showToast(err.message, 'no');
            // }

            errCallBack && errCallBack(err);

            // if (!sessionStorage.getItem('showToast')) {
            //     sessionStorage.setItem('showToast', '123');
            // showMessage && Util.showToast(err.message, 'no');
            //     errCallBack && errCallBack(err);
            //     setTimeout(() => {
            //         sessionStorage.removeItem('showToast');
            //     }, 3000);
            // }

        });
    }


    public async proxyHttp<T>(url: string, param: object) {

        try {
            const data = await this.$axios.get<T>(url, param);
            return data.data;
        } catch (e:any) {
            this.vue.$message.error(e.msg);
        }

    }


    public async checkCanDel(id: string, url: string) {

        try {
            const data = await this.$axios.get<{ hasData: boolean }>(url, {
                id: id,
            });
            if (data.data.hasData) {
                return data.data.hasData;
            } else {
                await this.vue.$confirm('需先删除关联的年级、班级、学生信息，才可删除学校。', '操作提醒', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                });
            }
        } catch (e:any) {
            this.vue.$message.error(e.msg);
        }
    }

    public delHttp(id: string, checkUrl: string, delUrl: string, callback: Function): void {
        if (checkUrl) {
            this.checkCanDel(id, checkUrl).then((data: boolean | undefined) => {
                if (data) {
                    this.delPost(id, delUrl, callback);
                }
            });
        } else {
            this.delPost(id, delUrl, callback);
        }
    }


    private delPost(id: string, delUrl: string, callback: Function): void {
        this.$axios.post(delUrl, {
            id: id,
        }).then(data => {
            this.vue.$message.success(data.message);
            callback();
        }).catch(e => {
            this.vue.$message.error(e.msg);
        });
    }

    public getTableList<T, V>(form: V, tablePage: any, url: string, callback: Function) {
        this.$axios.get<T>(url, {
            ...form,
            ...tablePage
        }).then(data => {
            callback(data);
        }).catch(err => {
            this.vue.$message.error(err.msg);
        });
    }

    public AddOrUpdate<T>(id: string | null, form: T, updateUrl: string, addUrl: string, callBack: Function, errCallBack?: Function): void {
        const resultId = new Util().getId(id);
        const url: string = this.submitUrl(id, updateUrl, addUrl);

        this.$axios.post(url, {
            ...form,
            id: resultId,
        }, true).then(data => {
            this.vue.$message.success(data.message);
            callBack();
        }).catch(e => {
            if (errCallBack) {
                errCallBack(e);
            }
            this.vue.$message.error(e.msg);
        });

    }

    public AddOrUpdate2<T>(id: string | null, modelId: string | null, form: T, updateUrl: string, addUrl: string, callBack: Function, errCallBack?: Function): void {
        const resultId = new Util().getId(id);
        const url: string = this.submitUrl(id, updateUrl, addUrl);

        this.$axios.post(url, {
            ...form,
            id: resultId,
        }, true).then(data => {
            this.vue.$message.success(data.message);
            callBack();
        }).catch(e => {
            if (errCallBack) {
                errCallBack(e);
            }
            this.vue.$message.error(e.msg);
        });

    }

    private submitUrl(id: string | null | undefined, updateUrl: string, addUrl: string): string {
        return id ? updateUrl : addUrl;
    }

    public getSomeOneDetail<V>(from: object, url: string, callback: Function): void {
        this.$axios.get<V>(url, {
            ...from
        }).then((data: AxiosData<V>) => {
            callback(data);
        }).catch(e => {
            callback('err');
            this.vue.$message.error(e.msg);
        });

    }

    public getCardList(type: number | string, num: string | number, handlePage: any, url: string, callback: Function): void {
        this.$axios.get<CardInterFace<string>>(url, {
            type: type,
            number: num,
            ...handlePage
        }).then((data) => {
            callback(data);
        }).catch((e) => {
            this.vue.$message.error(e.message);
        });
    }

    public getFile(url: string, form?: any, callback?: Function) {
        if (!form.groupCode) {
            this.$axios.fileWithGet(url, {
                ...form,
            });
        } else if (form.groupCode.length > 0) {
            this.$axios.fileWithGet(url, {
                ...form,
                groupCode: form.groupCode[form.groupCode.length - 1],
            });
        } else {
            this.$axios.fileWithGet(url, {
                ...form,
            });
        }



        if (callback) {
            callback();
        }
    }

    public getFile2(url: string, form1: any, form2: any, callback?: Function) {
        this.$axios.fileWithGet(url, {
            form1,
            form2,
        });
        if (callback) {
            callback();
        }
    }


    public postWithNoMessage<T>(form: object, url: string, callback?: Function, showSuccessMessage: boolean = false): void {
        this.$axios.post<T>(url, {
            ...form
        }, true)
            .then((data: AxiosData<T>) => {
                if (showSuccessMessage) {
                    this.vue.$message.success(data.message);
                }
                if (callback) {
                    callback(data);
                }
            }).catch(err => {
                this.vue.$message.error(err.message);
            });
    }

    public ImportFile<T, K>(url: string, file: FileList, fileKey: string, Form?: T, callBack?: Function): void {
        const fData = new FormData();
        console.log(fileKey, file[0])
        if (file[0]) {
            fData.append(fileKey, file[0]);
        }
        if (Form) {
            for (const i in Form) {
                fData.append(i, (Form[i] as any));
            }
        }

        this.$axios.fileImport<K>(url, fData, (data: AxiosData<K>) => {
            if (data.code === 200) {
                this.vue.$message.success(data.message);

                if (callBack) {
                    callBack(data);
                }

            } else {

                // this.vue.$message.error(data.message);
                if (callBack) {
                    callBack(data);
                }

            }
        })
    }
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getPageAPIs } from "@/common/util";
import { AfterSalesAPI } from "@/common/api/operationManage/afterSales";
import { CommonApi } from "@/common/api/common";
import gallery from "@/components/gallery.vue";
import { DeepLoop } from "@/common/deepLoop/deepLoop";

import {
  shareConfirmOptions,
  chooseShopTypeList as defaultChooseShopTypeList,
} from "@/common/config";
export const pageApiList = getPageAPIs(AfterSalesAPI);

const defaultForms = {
  // media_type 媒体类型:0图片,1视频
  study_picture: [],
  study_video: [],
  shop_type: "", // 所属项目
  title: "", // 标题
  category: "", // 分类
  article: "", // 内容
  sort: "", // 排序
  status: 0, // 0 上架；1 下架

};




@Component({
  filters: {
    showPicOrThumbnail(row: any) {
      const { media_type, media_arr } = row || {};

      switch (media_type) {
        case 1: // 图片
          return media_arr.length > 0 ? media_arr[0] : "";
        case 2: // 视频
          return media_arr.length > 0
            ? `${media_arr[0]}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto` ||
                ""
            : "";
        default:
          return "";
      }
    }
  },
  components: {
    gallery,
  },
})
export default class CommercialCollegesManage extends Vue {
  private form: any = {
    shop_type: null, // 店铺类型
    shop_name: '', // 店铺名称
    mobile: '', // 电话
    status: null, // 状态
    start_date: null, // 开始时间
    end_date: null, // 结束时间
  };
  private addForm:any = {
    id:null,
    reply:''
  }
  private time = []
  private tableData: any[] = [];
  private dialogVisible = false
  private typeObject = {
    1:'球桌问题',
    2:'智能设备',
    3:'空调伴侣',
    4:'台泥问题',
    5:'其他(棋牌)'
  }
  private statusObject = {
    1:'未处理',
    2:'已处理',
    3:'取消申请',
  }
  private statusArrays = [
    {value:0,label:'全部'},
    {value:1,label:'未处理'},
    {value:2,label:'已处理'},
  ]
  private shopTypeArrays = [
    {value:1,label:'台球'},
    {value:2,label:'棋牌'},
  ]
  private deepLoopUtil: any = {};

  private AfterSalesAPI = new AfterSalesAPI();

  private chooseShopTypeList: any[] = [];


  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    const onlyMerchantIncludes: number[] = [3, 4]

    this.chooseShopTypeList = [
      ...this.deepLoopUtil.deepCopy(defaultChooseShopTypeList),
    ].filter((item: any) => {
      return onlyMerchantIncludes.includes(item.value)
    });

  }

  private created(): void {
    this.getAfterSales();
  }
  private restForm(): void {
    this.form = new Util().clearObject(this.form);
    this.time = []
    this.getAfterSales();
  }
  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    if (Array.isArray(this.time) && this.time.length === 2) {
      const [startDate, endDate] = this.time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      this.form.start_date = startTime;
      this.form.end_date = endTime;
    }
    this.getAfterSales();
  }



  // 查看视频/图片
  private handleBeforePreviewMedia(row: any) {
    const { media_type, media_arr } = row || {};

    const previewItem: any = {};

    switch (media_type) {
      case 1: // 图片
        previewItem.image_count = media_arr.length;

        previewItem.images = media_arr.map((pic: any) => pic);
        break;
      case 2: // 视频
        previewItem.video_count = media_arr.length;

        previewItem.videos = media_arr.map((pic: any) => pic);
        break;
    }

    this.handlePreviewMedia(previewItem);
  }

  private images: any = [];

  private handlePreviewMedia(row: any) {
    const { image_count, images, video_count, videos } = row || {};

    const urls: any[] = [];

    if (image_count > 0) {
      urls.push(
        ...images.map((img: string) => {
          return {
            title: "",
            type: "image/jpeg",
            href: img,
            thumbnail: img,
          };
        })
      );
    }

    if (video_count > 0) {
      urls.push(
        ...videos.map((video: string) => {
          return {
            title: "",
            type: "video/mp4",
            href: video,
            poster: `${video}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`,
          };
        })
      );
    }
    this.images = urls;
  }


  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getAfterSales();
  }

  private handleAfterSales(row: any) {
    this.dialogVisible  = true
    this.addForm = {
      id:row.id,
      reply:''
    }
  }

  private handleComfirm() {
    this.AfterSalesAPI.handleAfterSales(this.addForm,
        () => {
          this.$message.success('处理成功');
          this.dialogVisible = false;
          this.getAfterSales();
        },
        () => {
        }
    );
  }

  //获取商学院列表
  private getAfterSales(): void {
    this.AfterSalesAPI.getAfterSalesList(
      {
        ...this.form,
        page: this.tablePage.page,
        limit: this.tablePage.prepage,
      },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page;
        this.tablePage.prepage = res.data.prepage;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }
}

import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class SingleCardAccountListAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getSingleCardAccountList', {
      url: '/admin/shopMember/accountList',
      name: 'list',
      label: '列表'
    }],
    
    ['exportExcelSingleCardAccountList', {
      url: '/admin/shopMember/exportAccountList',
      name: 'export-excel',
      label: '导出'
    }],
   
    ['searchVenueList', {
      url: '/admin/coupons/shop/list',
      name: 'shop-list',
      label: '店铺列表',
      // hidden: true
    }],
  ])

  // 单店卡余额列表
  public getSingleCardAccountList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getSingleCardAccountList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 导出单店卡余额列表
  public exportExcelSingleCardAccountList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('exportExcelSingleCardAccountList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 搜索店铺列表
  public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('searchVenueList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}
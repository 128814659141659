
import {Component, Vue} from "vue-property-decorator";
import {Util, getExcelJSONDataByList, getPageAPIs} from "@/common/util";
import {OperationalServiceRatingAPI} from "@/common/api/operationManage/operationalServiceRating";
import {shareConfirmOptions} from '@/common/config';
import { excuteDownloadExport2Excel } from "@/common/xlsx";
import {DeepLoop} from '@/common/deepLoop/deepLoop';
import ZlSelect from "@/components/third-custom-components/select";
import ZlOption from "@/components/third-custom-components/option";
export const pageApiList = getPageAPIs(OperationalServiceRatingAPI);
const defaultSearchVenuePageOption = {
  page: 1,
  limit: 10,
  isRearchBottom: false,
};
const defaultForm = {
  shop_type:0,
  shop_id:null,
  praise_user_id:null,
  start_date:null,
  end_date:null,
}
@Component({
  components: {
    ZlSelect,
    ZlOption,
  },
})
export default class operationalServiceRating extends Vue {
  constructor() {
    super();
    this.deepLoopUtil = new DeepLoop();
    this.classApi = new OperationalServiceRatingAPI();
    this.form = {...this.deepLoopUtil.deepCopy(defaultForm)}
    for(const item of this.typeArray){
      this.statusObject[item.value] = item.label
    }

  }
  private classApi: any;
  private typeArray = [
    {value:0,label:'全部'},
    {value:1,label:'台球'},
    {value:2,label:'棋牌'},
  ]
  private statusObject:any = {}
  private time = []
  private shopValue:any = {}
  private form: any = {}
  private venueList: any[] = []
  private deepLoopUtil: any = {}
  private operationalUserList:any = []
  private downloadExcelLoading: boolean = false;
  private addVenueListLoading: boolean = false;
  private searchVenueLoading: boolean = false;
  private remoteGetVenueQueryString: string = "";
  private searchVenuePageOption: any = {};
  private remoteSearchVenueTimer: any = null;
  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    if(this.shopValue.id){
      this.form.shop_id = this.shopValue.id
    }
    if (Array.isArray(this.time) && this.time.length === 2) {
      const [startDate, endDate] = this.time;
      this.form.start_date = startDate;
      this.form.end_date = endDate;
    }
    this.getTable();
  }

  private remoteGetVenueMethod(query: string) {
    query = query.trim ? query.trim() : "";
    if (query !== "") {
      this.remoteGetVenueQueryString = query;
      this.remoteSearchVenueTimer = setTimeout(() => {
        this.searchVenueLoading = true;
        this.getMoreVenueList();
      }, 300);
    } else {
      this.remoteGetVenueQueryString = "";
      this.venueList = [];
    }
  }
  private remoteAddVenueMethod() {
    if (this.addVenueListLoading) return;
    const searchVenuePageOption = this.searchVenuePageOption;

    const { isRearchBottom } = searchVenuePageOption;

    if (isRearchBottom) return;

    this.searchVenuePageOption.page++;

    this.addVenueListLoading = true;

    this.getMoreVenueList(false);
  }

  // 第一次搜索 无关键字
  private handleFirstGetVenueList() {
    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 清空搜索
  private handleClearGetVenueList() {
    this.remoteGetVenueQueryString = "";

    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  private initRemoteGetVenuePageOption() {
    this.searchVenuePageOption = {
      ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    };
  }

  private getSearchVenueParams() {
    const searchVenuePageOption = this.searchVenuePageOption;

    const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

    const { page, limit } = searchVenuePageOption;

    const params: any = {
      page,
      limit,
    };

    if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
      params.name = remoteGetVenueQueryString.trim();
    }

    return params;
  }

  private getMoreVenueList(refresh: boolean = true) {
    return new Promise((resolve: Function, reject: Function) => {
      if (refresh) {
        this.initRemoteGetVenuePageOption();
      }

      const params = this.getSearchVenueParams();

      this.classApi.searchVenueList(
          params,
          (res: any) => {
            console.log(res, "res");

            const venueList: any[] = res.data || [];

            this.venueList = refresh
                ? venueList
                : this.venueList.concat(venueList);

            if (Array.isArray(res.data) && res.data.length == 0) {
              this.searchVenuePageOption.isRearchBottom = true;
            }
            this.searchVenueLoading = false;

            this.addVenueListLoading = false;

            resolve();
          },
          () => {
            this.searchVenueLoading = false;

            this.addVenueListLoading = false;

            reject();
          }
      );
    });
  }


  // 用户字段名 文案对应关系
  private userExcelList2ch: any = {
    'shop_name': '店铺名称',
    'shop_type':'类型',
    'praise_user_name': '运营人员',
    'praise_score': '评分',
    'average_score': '综合评分',
    'remark': '内容补充',
    'created_at':'评价时间'
  };
  // 用户Excel 表头的先后顺序
  private userExcelSortList: string[] = Object.keys(this.userExcelList2ch)
  private activated(): void {
    this.getTable();
    this.getOperationalUser()
  }

  private getTable(): void {
    this.classApi.getList(
        {
          ...this.form,
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
        }
    );
  }
  private getOperationalUser(): void {
    this.classApi.getOperationalUser({}, (res: any) => {
          this.operationalUserList = res.data.list;
        }
    );
  }


  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
    this.shopValue = ''
    this.time = []
    this.search()

  }


  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }
  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getUserTableListParams("exportExcel");
    if (params === null) {
      return false;
    }
    this.downloadExcelLoading = true;
    this.classApi.exportList(params, (res: any) => {
          const { start_date, end_date } = params ;
          const filename = `客服服务评价 ${start_date}到${end_date}.xlsx`;
          const { data } = res ;

          const { list: originList } = data ;
          const newList = Array.isArray(originList)
              ? originList.map((item) => {
                 item.shop_type =  this.statusObject[item.shop_type]
                return item;
              })
              : [];

          if (newList.length > 0) {
            const userExcelSortList = this.userExcelSortList;

            const userExcelList2ch = this.userExcelList2ch;

            const excelJSONData = getExcelJSONDataByList(
                newList,
                userExcelSortList,
                userExcelList2ch
            );

            excuteDownloadExport2Excel(excelJSONData, filename);
          } else {
            this.$message.info("选中时段没有可导出的信息");
          }

          this.downloadExcelLoading = false;
        },
        () => {
          this.downloadExcelLoading = false;
        }
    );
  }
  private getUserTableListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const {
      shop_type,
      shop_id,
      praise_user_id,
      start_date, // 开始时间
      end_date, // 结束时间
    } = form ;

    const { pageNo, pageSize } = tablePage ;

    const outerParams: any = {};

    if (start_date) {
      outerParams.start_date = start_date;
    }

    if (end_date) {
      outerParams.end_date = end_date;
    }

    if (shop_id) {
      outerParams.shop_id = shop_id;
    }

    if (praise_user_id) {
      outerParams.praise_user_id = praise_user_id;
    }
    outerParams.shop_type = shop_type;

    if (Array.isArray(this.time) && this.time.length === 2) {
      const [startDate, endDate] = this.time;
      outerParams.start_date = startDate;
      outerParams.end_date = endDate;
    }

    if (mode == "exportExcel" && !(outerParams.start_date && outerParams.end_date)) {
      this.$message.error("请选择时段导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }
}


import { Component, Vue } from "vue-property-decorator";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";

import { excuteDownloadExport2Excel } from "@/common/xlsx";
import SingleCardAccountListAPI from '@/common/api/operationManage/singleCardGiveList';

import { DeepLoop } from "@/common/deepLoop/deepLoop";
import ZlSelect from "@/components/third-custom-components/select";

import ZlOption from "@/components/third-custom-components/option";


export const pageApiList = getPageAPIs(SingleCardAccountListAPI)


const defaultSearchVenuePageOption = {
  page: 1,
  limit: 10,
  isRearchBottom: false,
};

const maxBenefitRate = 100

@Component({
  components: {
    ZlSelect,
    ZlOption,
  },
  filters: {
  }
})
export default class SingleCardAccountsManage extends Vue {
  private form: any = {
    venue_id: "",
    venue: {
      id: "",
      name: "",
    },
    mobile: "",

  };



  private deepLoopUtil: any = {};


  private singleCardAccountApi = new SingleCardAccountListAPI();

  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

  }

  private created(): void {
    this.getSingleCardAccounts();
  }
  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getSingleCardAccounts();
  }
  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getSingleCardAccounts();
  }

  private tableData: any[] = [];

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";
  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getSingleCardAccounts();
  }



  private venueList: any[] = [

  ];

  private searchVenueLoading: boolean = false;

  private remoteGetVenueQueryString: string = "";

  private remoteSearchVenueTimer: any = null;

  private searchVenuePageOption: any = {};

  private initRemoteGetVenuePageOption() {
    this.searchVenuePageOption = {
      ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    };
  }

  private getSearchVenueParams() {
    const searchVenuePageOption = this.searchVenuePageOption;

    const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

    const { page, limit } = searchVenuePageOption;

    const params: any = {
      page,
      limit,
    };

    if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
      params.name = remoteGetVenueQueryString.trim();
    }

    return params;
  }

  private getMoreVenueList(refresh: boolean = true) {
    return new Promise((resolve: Function, reject: Function) => {
      if (refresh) {
        this.initRemoteGetVenuePageOption();
      }

      const params = this.getSearchVenueParams();

      this.singleCardAccountApi.searchVenueList(
        params,
        (res: any) => {
          console.log(res, "res");

          const venueList: any[] = res.data || [];

          this.venueList = refresh
            ? venueList
            : this.venueList.concat(venueList);

          if (Array.isArray(res.data) && res.data.length == 0) {
            this.searchVenuePageOption.isRearchBottom = true;
          }
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          resolve();
        },
        () => {
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          reject();
        }
      );
    });
  }

  // 第一次搜索 无关键字
  private handleFirstGetVenueList() {
    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 清空搜索
  private handleClearGetVenueList() {
    this.remoteGetVenueQueryString = "";

    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 远程搜索
  private remoteGetVenueMethod(query: string) {
    query = query.trim ? query.trim() : "";

    if (query !== "") {
      this.remoteGetVenueQueryString = query;
      clearTimeout(this.remoteSearchVenueTimer);

      this.remoteSearchVenueTimer = setTimeout(() => {
        this.searchVenueLoading = true;

        this.getMoreVenueList();
       
      }, 300);
    } else {
      this.remoteGetVenueQueryString = "";
      this.venueList = [];
    }
  }

  private addVenueListLoading: boolean = false;

  private remoteAddVenueMethod() {
    if (this.addVenueListLoading) return;
    const searchVenuePageOption = this.searchVenuePageOption;

    const { isRearchBottom } = searchVenuePageOption;

    if (isRearchBottom) return;

    this.searchVenuePageOption.page++;

    this.addVenueListLoading = true;

    this.getMoreVenueList(false);
  }


  private getSingleCardAccounts(): void {
    const params: any = this.getSingleCardAccountParams()
    this.singleCardAccountApi.getList(
      { ...params },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page || params.page;
        this.tablePage.prepage = res.data.prepage || params.limit;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private getSingleCardAccountParams(mode = "all"): any {
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    const { venue, mobile, time } = form;

    
    if ((venue && venue.id)) {
      params.shop_id = venue.id
    }

    if (mobile) {
      params.mobile = mobile
    }
    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      params.start_date = startDate;

      params.end_date = endDate;
    }

    if (
        mode == "exportExcel" &&
        !(params.start_date && params.end_date)
    ) {
      this.$message.error("请选择创建时段导出数据");
      return null;
    }


    if (mode === "all") {
      params.page = page;

      params.limit = prepage;

    }


    return params;
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 用户字段名 文案对应关系
  private userExcelList2ch: any = {
    mobile: "用户手机号",
    user_name: "昵称",
    recharge: "充值金额",
    price: "赠送金额",
    shop_name: "店铺名称",
    admin_mobile: "操作人手机号",
    created_at: "时间",
  };
  // 用户Excel 表头的先后顺序
  private userExcelSortList: string[] = Object.keys(this.userExcelList2ch)

  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getSingleCardAccountParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.singleCardAccountApi.exportList(
      params,
      (res: any) => {
        // const { start_date, end_date } = params || {};

        const filename = `单店卡赠送记录.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const { 
                // rank
              } = item;
              const newItem = {
                ...(item || {}),
              };


              return newItem;
            })
          : [];

        // console.log(newList, 'newList')

        if (newList.length > 0) {
          const userExcelSortList = this.userExcelSortList;

          const userExcelList2ch = this.userExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            userExcelSortList,
            userExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }
        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

}

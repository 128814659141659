import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class CompetitionLiveAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getCompetitionLiveList', {
      url: '/admin/leagueLive/list',
      name: 'list',
      label: '列表'
    }],
    ['updateCompetitionLive', {
      url: '/admin/leagueLive/edit',
      name: 'edit',
      label: '编辑赛事直播'
    }],
    ['addCompetitionLive', {
      url: '/admin/leagueLive/add',
      name: 'add',
      label: '添加赛事直播'
    }],
    ['deleteCompetitionLive', {
      url: '/admin/leagueLive/delete',
      name: 'delete',
      label: '删除赛事直播'
    }],

  ])
  // 赛事直播列表
  public getCompetitionLiveList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getCompetitionLiveList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 修改赛事直播
  public updateCompetitionLive(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('updateCompetitionLive').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 添加赛事直播
  public addCompetitionLive(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('addCompetitionLive').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 删除赛事直播
  public deleteCompetitionLive(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('deleteCompetitionLive').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}

import {Component, Vue} from "vue-property-decorator";
import {Util, getPageAPIs} from "@/common/util";
import {ChatRecordQueryAPIClass} from "@/common/api/operationManage/chatRecordQuery";
import pandaSvga from "@/components/pandaSvga.vue";
import pandaSvgaPreview from "@/components/pandaSvgaPreview.vue";

export const pageApiList = getPageAPIs(ChatRecordQueryAPIClass)

@Component({
  components: {
    "panda-svga": pandaSvga,
    "panda-svga-preview": pandaSvgaPreview,
  }
})
export default class MerchantsAudit extends Vue {
  private poolCategoryApi: any;
  private forms: any = {
    time: null,
    imgurl: ''
  };

  constructor() {
    super();
    this.poolCategoryApi = new ChatRecordQueryAPIClass();
  }

  private form: any = {
    from: '',
    to: '',
    start_date: null,
    end_date: null,
  };
  private time = []
  private tableData: any[] = [];
  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };
  // 预览媒体文件
  private previewEffects: any[] = [];
  private coachOrderType = {
    2:'已接单',
    3:'已拒单',
    4:'退款成功',
    5:'部分退款成功',
    6:'超时未接单取消',
    7:'订单已取消',
    8:'订单已完成',
  }
  private activated(): void {
    this.search();
  }

  private getTable(): void {
    this.poolCategoryApi.getIMChatRecordList(
        {
          ...this.form,
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          console.log('9999999999999999999', res.data.list)
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
        }
    );
  }

  private restForm(): void {
    this.form = new Util().clearObject(this.form);
    this.time = []
    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    if (Array.isArray(this.time) && this.time.length === 2) {
      const [startDate, endDate] = this.time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      this.form.start_date = startTime;
      this.form.end_date = endTime;
    }
    this.getTable();
  }

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private formatContent(strObject) {
    return JSON.parse(strObject)
  }

  private handleTableCellEffect(url) {
    this.previewEffects = [
      {
        title: "",
        type: "svga",
        href: url,
      },
    ];
  }
}

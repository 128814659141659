
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {AxiosData} from "@/common/http/http";
import { CommonApi } from "@/common/api/common";
import {Util, getPageAPIs} from "@/common/util";
import PoolCategoryAPI from "@/common/api/operationManage/poolCategory";

export const pageApiList = getPageAPIs(PoolCategoryAPI)

@Component({})
export default class MerchantsAudit extends Vue {
  private poolCategoryApi: any;
  private title: string = "";
  private is_show: boolean = false;
  private commonApi = new CommonApi();
  private hideUpload:boolean = false
  private forms: any = {
    time: null,
    imgurl: ''
  };
  constructor() {
    super();
    this.poolCategoryApi = new PoolCategoryAPI();
  }



  private addtype(): void {
    this.restForm();
    this.title = "新增";
    this.is_show = true;
  }

  private editortype(row: any) {
    this.restForm();
    this.forms = row;
    this.title = "编辑";
    this.is_show = true;
  }

  private fileChange(file: any, fileList: any[]): void {
    this.hideUpload = fileList.length >= 1;
    // this.getOss(file.raw, (res: any) => {
    //   console.log(res);
    //   this.mcinfo.front_card = res.data.data.item.id;
    // });
  }

  private getOss(file: File, cb: Function) {
    console.log(file);
    if (file.type == "image/jpeg" || file.type == "image/png") {
      this.commonApi.getOss(
          file,
          (res: any) => {
            cb(res);
          },
          () => {}
      );
    } else {
      this.commonApi.getOssvideo(
          file,
          (res: any) => {
            cb(res);
          },
          () => {}
      );
    }
  }

  // private handlePictureCardPreview(file) {
  //   this.dialogImageUrl = file.url;
  //   this.dialogVisible = true;
  // }

  private deletetype(id: string) {
    this.poolCategoryApi.deletetype(
        {
          id: id,
        },
        () => {
          this.is_show = false;
          Util.showToast("删除成功");
          this.search();
        }
    );
  }

  private submit(): void {
    if (this.title == "新增") {
      this.poolCategoryApi.addtype(this.forms, () => {
        this.is_show = false;
        Util.showToast("新增成功");
        this.search();
      });
    } else {
      this.poolCategoryApi.editortype(this.forms, () => {
        this.is_show = false;
        Util.showToast("修改成功");
        this.search();
      });
    }
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private activated(): void {
    this.search();
  }

  private getTable(): void {
    this.poolCategoryApi.poolTableCategory(
        {
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
        }
    );
  }

  private restForm(): void {
    this.forms = new Util().clearObject(this.forms);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}

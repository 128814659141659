

import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

import { CommonApi } from "../common";

export class ActivityCEBPayRecordApi extends BasicsClass {
  // 订单api 方法
  public apiUrls: any = new Map([
    ['getUserSignupPayTableList', {
        url: '/admin/access_ticket/buyList',
        name: 'list',
        label: '列表'
    }],
    ['exportExcelUserSignupPayList', {
        url: '/admin/access_ticket/exportBuyList',
        name: 'export-excel',
        label: '导出'
    }],

])
  // 用户报名缴费记录列表
  public getUserSignupPayTableList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getUserSignupPayTableList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 导出 用户报名缴费记录列表
  public exportExcelUserSignupPayList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('exportExcelUserSignupPayList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}

import {Component, Vue} from "vue-property-decorator";
import {Util, getPageAPIs} from "@/common/util";
import SummerActivityShieldStore from "@/common/api/operationManage/summerActivityShieldStore";
import {shareConfirmOptions} from '@/common/config';
import {DeepLoop} from '@/common/deepLoop/deepLoop';
import ZlSelect from "@/components/third-custom-components/select";
import ZlOption from "@/components/third-custom-components/option";
export const pageApiList = getPageAPIs(SummerActivityShieldStore);
const defaultSearchVenuePageOption = {
  page: 1,
  limit: 10,
  isRearchBottom: false,
};
const defaultForm = {
  name:'',
}

@Component({
  components: {
    ZlSelect,
    ZlOption,
  },
})
export default class summerActivityShieldStore extends Vue {
  constructor() {
    super();
    this.deepLoopUtil = new DeepLoop();
    this.classApi = new SummerActivityShieldStore();
    this.form = {...this.deepLoopUtil.deepCopy(defaultForm)}
  }
  private classApi: any;
  private visible:boolean = false
  private time = []
  private shopValue:any = {}
  private form: any = {}
  private venueList: any[] = []
  private deepLoopUtil: any = {}
  private addVenueListLoading: boolean = false;
  private searchVenueLoading: boolean = false;
  private remoteGetVenueQueryString: string = "";
  private searchVenuePageOption: any = {};
  private remoteSearchVenueTimer: any = null;
  private tableData: any[] = [];
  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    if (Array.isArray(this.time) && this.time.length === 2) {
      const [startDate, endDate] = this.time;
      this.form.start_date = startDate;
      this.form.end_date = endDate;
    }
    this.getTable();
  }

  private remoteGetVenueMethod(query: string) {
    query = query.trim ? query.trim() : "";
    if (query !== "") {
      this.remoteGetVenueQueryString = query;
      this.remoteSearchVenueTimer = setTimeout(() => {
        this.searchVenueLoading = true;
        this.getMoreVenueList();
      }, 300);
    } else {
      this.remoteGetVenueQueryString = "";
      this.venueList = [];
    }
  }
  private remoteAddVenueMethod() {
    if (this.addVenueListLoading) return;
    const searchVenuePageOption = this.searchVenuePageOption;

    const { isRearchBottom } = searchVenuePageOption;

    if (isRearchBottom) return;

    this.searchVenuePageOption.page++;

    this.addVenueListLoading = true;

    this.getMoreVenueList(false);
  }

  // 第一次搜索 无关键字
  private handleFirstGetVenueList() {
    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 清空搜索
  private handleClearGetVenueList() {
    this.remoteGetVenueQueryString = "";

    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  private initRemoteGetVenuePageOption() {
    this.searchVenuePageOption = {
      ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    };
  }

  private getSearchVenueParams() {
    const searchVenuePageOption = this.searchVenuePageOption;

    const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

    const { page, limit } = searchVenuePageOption;

    const params: any = {
      page,
      limit,
    };

    if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
      params.name = remoteGetVenueQueryString.trim();
    }

    return params;
  }

  private getMoreVenueList(refresh: boolean = true) {
    return new Promise((resolve: Function, reject: Function) => {
      if (refresh) {
        this.initRemoteGetVenuePageOption();
      }

      const params = this.getSearchVenueParams();

      this.classApi.searchVenueList(
          params,
          (res: any) => {
            console.log(res, "res");

            const venueList: any[] = res.data || [];

            this.venueList = refresh
                ? venueList
                : this.venueList.concat(venueList);

            if (Array.isArray(res.data) && res.data.length == 0) {
              this.searchVenuePageOption.isRearchBottom = true;
            }
            this.searchVenueLoading = false;

            this.addVenueListLoading = false;

            resolve();
          },
          () => {
            this.searchVenueLoading = false;

            this.addVenueListLoading = false;

            reject();
          }
      );
    });
  }

  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    this.classApi.getList(
        {
          ...this.form,
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
        }
    );
  }


  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
    this.shopValue = ''
    this.time = []
    this.search()
  }

  private onAdd():void{
    this.visible=true
    this.shopValue = ''
  }

  private onSubmit(): void {
    console.log(this.shopValue)
    this.classApi.add({shop_id:this.shopValue.id}, () => {
      this.visible = false;
      Util.showToast("新增成功");
      this.search();
    });
  }

  private onDelete(item: any):void {
    this.$confirm(`确定删除${item.name}吗？`, '提示', {
      ...shareConfirmOptions
    }).then(() => {
      this.classApi.delete({
            id: item.id
          },
          () => {
            Util.showToast("删除成功");
            this.search();
          }
      )
    })
  }

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }
  private getUserTableListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const firstLoading = this.firstLoading;

    const {
      phone, // 手机号
      status, // 状态
      start_date, // 开始时间
      end_date, // 结束时间
    } = form ;

    const { pageNo, pageSize } = tablePage ;

    const outerParams: any = {};

    if (start_date) {
      outerParams.start_date = start_date;
    }

    if (end_date) {
      outerParams.end_date = end_date;
    }

    if (phone) {
      outerParams.phone = phone;
    }

    if (status) {
      outerParams.status = status;
    }


    if (Array.isArray(this.time) && this.time.length === 2) {
      const [startDate, endDate] = this.time;
      outerParams.start_date = startDate;
      outerParams.end_date = endDate;
    }

    if (mode == "exportExcel" && !(outerParams.start_date && outerParams.end_date)) {
      this.$message.error("请选择时段导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      outerParams.__isUnique = !firstLoading;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }
}

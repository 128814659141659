import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class VantPointLoanRecordAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getVantPointRecordList', {
      url: '/admin/shopLoan/index',
      name: 'list',
      label: '列表'
    }],
    ['addVantPointLoan', {
      url: '/admin/shopLoan/add',
      name: 'add-point-loan',
      label: '添加积分贷款'
    }],

    ['deleteVantPointLoan', {
      url: '/admin/shopLoan/del',
      name: 'delete-point-loan',
      label: '删除积分贷款'
    }],

    ['searchVenueList', {
      url: '/admin/coupons/shop/list',
      name: 'shop-list',
      label: '店铺列表',
      // hidden: true
    }],
   
  ])

  // 有赞积分贷款列表
  public getVantPointRecordList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getVantPointRecordList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }


  // 添加积分贷款
  public addVantPointLoan(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('addVantPointLoan').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 删除积分贷款
  public deleteVantPointLoan(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('deleteVantPointLoan').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }


  // 搜索店铺列表
  public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('searchVenueList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}
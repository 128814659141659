import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class ShopAddressAPIClass extends BasicsClass {

    public apiUrls: any = new Map([
        ['getShopAddressTableList', {
            url: '/admin/shop/offline/lists',
            name: 'list',
            label: '列表'
        }],
        ['addShopAddress', {
            url: '/admin/shop/offline/add',
            name: 'add-merchant',
            label: '添加'
        }],
        ['updateShopAddress', {
            url: '/admin/shop/offline/save',
            name: 'edit',
            label: '修改'
        }],
        ['deleteShopAddress', {
            url: '/admin/shop/offline/delete',
            name: 'info',
            label: '删除',
        }],
        ['getShopAddressView', {
            url: '/admin/shop/offline/view',
            name: 'info',
            label: '详情',
        }],

    ])
    // 店铺列表
    public getShopAddressTableList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const start_date = Util.handleStartTimeAndEndTime(params.time).startTime;
        const end_date = Util.handleStartTimeAndEndTime(params.time).endTime;

        params = {
            ...(params || {}),
            start_date: start_date,
            end_date: end_date
        }

        const apiUrls = this.apiUrls

        const url = apiUrls.get('getShopAddressTableList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 添加店铺地址
    public addShopAddress(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('addShopAddress').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 修改店铺地址
    public updateShopAddress(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('updateShopAddress').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    // 删除店铺地址
    public deleteShopAddress(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('deleteShopAddress').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 店铺地址详情
    public getShopAddressView(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getShopAddressView').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

}

import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export class SignboardLightManageAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['device', {
            url: '/admin/aqara/device',
            name: 'device-list',
            label: '授权设备列表',
            // hidden: true
        }],
        ['resource', {
            url: '/admin/aqara/resource',
            name: 'device-resource',
            label: '设备开关资源',
            // hidden: true
        }],
        ['signboardLightList', {
            url: '/admin/shopSign/lists',
            name: 'list',
            label: '店铺招牌灯列表'
        }],
        ['signboardLightAdd', {
            url: '/admin/shopSign/add',
            name: 'add',
            label: '新增招牌灯'
        }],
        ['signboardLightEdit', {
            url: '/admin/shopSign/edit',
            name: 'edit',
            label: '编辑招牌灯'
        }],
        ['signboardLightDel', {
            url: '/admin/shopSign/del',
            name: 'delete',
            label: '删除招牌灯'
        }],
    ])
    // 设备列表
    public device(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('device').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 设备资源名
    public resource(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('resource').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    // 店铺招牌灯列表
    public signboardLightList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('signboardLightList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 新增店铺招牌
    public signboardLightAdd(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('signboardLightAdd').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 编辑店铺招牌
    public signboardLightEdit(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('signboardLightEdit').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 删除店铺招牌
    public signboardLightDel(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('signboardLightDel').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}
import { BasicsClass } from '@/common/BasicsClass';
export class SystemUserManageAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getSystemUsers', {
            url: '/admin/admin/list',
            name: 'list',
            label: '列表'
        }],
        ['addSystemUsers', {
            url: '/admin/admin/add',
            name: 'add',
            label: '添加'
        }],
        ['updateSystemUsers', {
            url: '/admin/admin/edit',
            name: 'edit',
            label: '编辑'
        }],
        ['deleteSystemUserItem', {
            url: '/admin/admin/delete',
            name: 'delete',
            label: '删除'
        }],
    
        ['getDepartmentList', {
            url: '/admin/admin_department/list',
            name: 'get-department-list',
            label: '获取部门列表',
            // hidden: true
        }],
    ])


    //新增优惠券
    public addSystemUsers(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('addSystemUsers').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    //修改优惠券
    public updateSystemUsers(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('updateSystemUsers').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    //优惠券列表
    public getSystemUsers(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getSystemUsers').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
    // 删除 优惠券
    public deleteSystemUserItem(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('deleteSystemUserItem').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }


    // 部门列表
    public getDepartmentList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getDepartmentList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}
import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

import { CommonApi } from "../common";

export class CompetitionPartnerPrePkSetListAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getCompetitionPartnerPrePkSetList', {
      url: '/admin/league/contestant',
      name: 'list',
      label: '列表'
    }],
     
    ['exportExcelCompetitionPartnerPrePkSetList', {
        url: '/admin/league/exportContestant',
        name: 'export-excel',
        label: '导出'
    }],

    ['retireCompetitionPartnerPrePkSet', {
      url: '/admin/league/retireContestant',
      name: 'retire-partner',
      label: '退赛用户'
  }],
  ])
  // api 方法
  // 全部参赛选手列表
  public getCompetitionPartnerPrePkSetList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getCompetitionPartnerPrePkSetList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 导出全部参赛选手列表
  public exportExcelCompetitionPartnerPrePkSetList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('exportExcelCompetitionPartnerPrePkSetList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  //退赛用户
  public retireCompetitionPartnerPrePkSet(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('retireCompetitionPartnerPrePkSet').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}

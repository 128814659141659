import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class MemberChargeApi extends BasicsClass {
    public apiUrls: any = new Map([
        ['getMemberStatisticList', {
            url: '/admin/member/manage',
            name: 'list',
            label: '列表'
        }],
        ['exportMemberStatisticList', {
            url: '/admin/member/exportManage',
            name: 'export-excel',
            label: '导出'
        }]
    ])

    // 会员管理列表 后台充值列表
    public getMemberStatisticList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getMemberStatisticList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }


    // // 导出会员管理列表 后台充值列表
    public exportMemberStatisticList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('exportMemberStatisticList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

}
import { render, staticRenderFns } from "./competitionPointExcelRecord.vue?vue&type=template&id=0d03641a&scoped=true"
import script from "./competitionPointExcelRecord.vue?vue&type=script&lang=ts"
export * from "./competitionPointExcelRecord.vue?vue&type=script&lang=ts"
import style0 from "./competitionPointExcelRecord.vue?vue&type=style&index=0&id=0d03641a&prod&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d03641a",
  null
  
)

export default component.exports
import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class GuessingGamePraizesRecordAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getGuessingGamePraizesRecordList', {
        url: '/admin/guess/user',
        name: 'list',
        label: '列表'
    }],
    ['exportExcelGuessingGamePraizesRecordTableList', {
      url: '/admin/guess/exportUser',
      name: 'export-excel',
      label: '导出'
  }],
])

  // 有奖竞猜记录列表
  public getGuessingGamePraizesRecordList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getGuessingGamePraizesRecordList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 导出有奖竞猜记录列表
  public exportExcelGuessingGamePraizesRecordTableList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('exportExcelGuessingGamePraizesRecordTableList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

}
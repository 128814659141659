
import dayjs from "@/plugins/dayjs";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import { CouponVertifyCodeRecordAPI } from "@/common/api/couponManage/couponVertifyCodeRecord";

import { excuteDownloadExport2Excel } from "@/common/xlsx";

// import { util } from "vue/types/umd";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
// import ZlSelect from "@/components/third-custom-components/select";

// import ZlOption from "@/components/third-custom-components/option";

import {
  shareConfirmOptions,
  shareShopType2ch
} from '@/common/config'

// 暴露的API 列表
export const pageApiList = getPageAPIs(CouponVertifyCodeRecordAPI);

console.log(pageApiList, 'pageApiList')

function getLastThreeMonthDate() {
  const lastEndDay = (dayjs().endOf('month') as any).format("YYYY-MM-DD");

  const lastStartDay = (
    dayjs().subtract(2, "month").startOf('month') as any
  ).format("YYYY-MM-DD");

  return [lastStartDay, lastEndDay];
}

const defaultForm = {
  phone: "", // 手机号

  receipt_code: "", // 券码

  shop_name: "", // 店铺名称

  receipt_order_id: "", // 订单编号

  is_type: '', // 类型

  time: [
    ...getLastThreeMonthDate()
  ]
}

const defaultForms = {
  // name: "",
  // origin_price: "",
  // sale_price: "",
  // shop_type: 1,
  // expired_days: "",
  // meituanId: "",
  // timeDuration: "",
  // pool_table_category_ids: [],
  // coupons_time_id: "",
  // venue_id: "",
  // venue: {
  //   id: "",
  //   name: "",
  // },
  // singleMaxCountType: '0',
  // shop_id: "",
  // maxLimitCount: ''
};


// const couponDialogTitle2ch: any = {
//   "new-coupon": "新增时长卡",
//   "edit-coupon": "编辑时长卡",
// };

// 最大有效期
// const maxExpiredDays = 9999;

// // 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;

// // coupon 卡券分属类别
// const couponShopType2ch: any = [
//   ...shareShopType2ch
// ]

// 3为美团7为抖音11为快手 12体育
const useCouponTypeName2ch: string[] = ['', '', '', '美团', '', '', '', '抖音', '', '', '', '快手', '体育']


@Component({
  filters: {
    // showCouponShopTypeName(shop_type: number) {
    //   return couponShopType2ch[shop_type] || ''
    // }
    showUseCouponType2ch(source: number) {
      return useCouponTypeName2ch[source] || '未知'
    }
  },
  components: {
    // ZlSelect,
    // ZlOption,
  },
})
export default class CouponsManage extends Vue {
  // 3为美团7为抖音11为快手12体育
  private couponTypeList: any[] = [
    {
      label: '美团',
      value: '3'
    },
    {
      label: '抖音',
      value: '7'
    },
    {
      label: '快手',
      value: '11'
    },
    {
      label: '体育',
      value: '12'
    }
  ]

  private form: any = {
    // couponId: "",
    // shop_name: "",
    // time: [],
    // startDate: "",
    // endDate: "",
  };
  private forms: any = {
    // maxType: "0",
    // ...defaultForms
  };

  private deepLoopUtil: any = {};

  // private merchantApi: any;

  private couponVertifyCodeRecordApi = new CouponVertifyCodeRecordAPI();
  // private couponTimeDurationAPI = new CouponTimeDurationManageAPI();
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    // this.searchVenuePageOption = {
    //   ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    // };

    // this.merchantApi = new MerchantApi();
  }

  private created(): void {
    this.getCouponVertifyCodeRecords();

    // this.refreshCouponTimeDurations();

    // this.refreshTableClass();
  }
  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
    // this.form = new Util().clearObject(this.form);
    // this.$router.replace("/orderList");
    this.getCouponVertifyCodeRecords();
  }

  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getCouponVertifyCodeRecords();
  }

  private tableData: any[] = [];


  private formLabelWidth: string = "100px";

  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getCouponVertifyCodeRecords();
  }

  //获取优惠券列表
  private getCouponVertifyCodeRecords(): void {
    this.couponVertifyCodeRecordApi.getCouponVertifyCodeRecords(
      { ...this.getCouponVertifyCodeRecordParams() },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page;
        this.tablePage.prepage = res.data.prepage;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private getCouponVertifyCodeRecordParams(mode = "all"): any {
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    const {
      phone,
      receipt_code,
      shop_name,
      receipt_order_id,
      is_type,
      time
    } = form;

    if (phone) {
      params.phone = phone
    }

    if (receipt_code) {
      params.receipt_code = receipt_code
    }

    if (shop_name) {
      params.shop_name = shop_name
    }

    if (receipt_order_id) {
      params.receipt_order_id = receipt_order_id
    }

    if (is_type) {
      params.source = is_type
    }

    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      params.start_date = startTime;

      params.end_date = endTime;
    }

    if (
      mode == "exportExcel" &&
      !(params.start_date && params.end_date)
    ) {
      this.$message.error("请选择核销时段导出数据");
      return null;
    }

    if (mode === "all") {
      params.page = page;

      params.limit = prepage;

    }

    return params;
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 订单字段名 文案对应关系
  private orderExcelList2ch: any = {
    receipt_order_id: "订单编号",
    phone: "手机号",
    name: "卡券名称",
    divide_price: "金额",
    coupon_amount: "优惠券金额",
    actual_amount: "实付金额",
    receipt_code: "券码",
    __useCouponType: "类型",
    shop_name: "消费店铺",
    created_at: "核销时间"
  };
  // 订单Excel 表头的先后顺序
  private orderExcelSortList: string[] = [
    "receipt_order_id",
    "phone",
    "name",
    "divide_price",
    "coupon_amount",
    "actual_amount",
    "receipt_code",
    "__useCouponType",
    "shop_name",
    "created_at"
  ];

  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getCouponVertifyCodeRecordParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.couponVertifyCodeRecordApi.exportCouponVertifyCodeRecords(
      params,
      (res: any) => {
        const {
          start_date,
          end_date,
        } = params || {};

        const filename = `团购券核销记录列表 ${start_date}到${end_date}.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const {
                // user_type,
                // price,
                // refunded_price,
                // device,
                // pay_type,
                // daytime_minute,
                // night_minute,
                // goods_detail,
                // status
                source
              } = item || {};

              const newItem = {
                ...(item || {}),
                // device_name: orderDeviceType2ch[device],
                // pay_type_name: payType2ch[pay_type],
                // total_miniute: daytime_minute + night_minute,
                // actual_price: price - refunded_price || "",
                // user_level_type_name: userLevel2ch[user_type],
                // __status_name: orderStatus2Ch[status],
                // __good_details: goods_detail.map((goods: any, index: number) => `${index + 1}、${ goods.name }x${ goods.num }  ¥${ goods.price }`).join('; \t')
                __useCouponType: useCouponTypeName2ch[source]
              };

              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const orderExcelSortList = this.orderExcelSortList;

          const orderExcelList2ch = this.orderExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            orderExcelSortList,
            orderExcelList2ch
          );

          // console.log(newList, "newList");

          // console.log(excelJSONData, "excelJSONData");

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

    

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getPageAPIs } from "@/common/util";
import { CommercialCollegeAPI } from "@/common/api/operationManage/commercialCollege";

import { CommonApi } from "@/common/api/common";

import gallery from "@/components/gallery.vue";

// import MerchantApi from "@/common/api/merchant";
// import { util } from "vue/types/umd";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
// import ZlSelect from "@/components/third-custom-components/select";

// import ZlOption from "@/components/third-custom-components/option";

import {
  shareConfirmOptions,
  getCommonList
} from "@/common/config";
export const pageApiList = getPageAPIs(CommercialCollegeAPI);


// 商家端店铺类型 0未知 1台球商家端  2棋牌商家端
const merchantShopType2Ch: string[] = ['', "熊猫球社商家端", "熊猫国粹馆商家端"]


const defaultChooseShopTypeList = getCommonList(merchantShopType2Ch, 1)

const defaultForms = {
  // media_type 媒体类型:0图片,1视频
  study_picture: [],
  study_video: [],
  shop_type: "", // 所属项目
  title: "", // 标题
  category: "", // 分类
  article: "", // 内容
  sort: "", // 排序
  status: 0, // 0 上架；1 下架
  // singleMaxCountType: '0',
  // shop_id: "",
  // maxLimitCount: ''
};

// const defaultSearchVenuePageOption = {
//   page: 1,
//   limit: 10,
//   isRearchBottom: false,
// };

const competitionPointDialogTitle2ch: any = {
  "new-commercial-college": "新增学院信息",
  "edit-commercial-college": "编辑学院信息",
};

function createPicItem(id: number, url: string) {
  const item = {
    id,
    url,
    href: url,
    type: "image/png",
    types: "1",
  };

  return item;
}

function createVideoItem(id: number, url: string) {
  const item = {
    id,
    url,
    href: url,
    type: "video/mp4",
    types: "2",
    poster: `${url}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`,
  };

  return item;
}

// 最大有效期
// const maxExpiredDays = 9999;

// 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;

// const maxBenefitRate = 100

const defaultCategoryList: any[] = [
  {
    label: "运营",
    value: 0,
  },
  {
    label: "运维",
    value: 1,
  },
  {
    label: "其他",
    value: 2,
  },
];

const allStudyStatus2ch: string[] = ["上架", "下架"];

@Component({
  filters: {
    showPicOrThumbnail(row: any) {
      const { media_type, media_list } = row || {};

      switch (media_type) {
        case 0: // 图片
          return media_list.length > 0 ? media_list[0].url || "" : "";
        case 1: // 视频
          return media_list.length > 0
            ? `${media_list[0].url}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto` ||
                ""
            : "";
        default:
          return "";
      }
    },

    showCategoryName(row: any) {
      const existCategoryItem: any = {
        ...(defaultCategoryList.find((item) => item.value == row.category) ||
          {}),
      };

      return existCategoryItem.label || "";
    },

    showShopTypeName(row: any) {
      const existShopTypeItem: any = {
        ...(defaultChooseShopTypeList.find(
          (item) => item.value == row.shop_type
        ) || {}),
      };

      return existShopTypeItem.label || "";
    },

    showStudyStatusText(row: any) {
      return allStudyStatus2ch[row.status] || "未知";
    },

    showFilterText: Util.showFilterText
  },
  components: {
    gallery,
    // ZlSelect,
    // ZlOption,
  },
})
export default class CommercialCollegesManage extends Vue {
  private form: any = {
    // competitionPointId: "",
    title: "", // 标题
    shop_type: '', // 选择所属项目 
    // shop_id: '',
    // time: [],
    // startDate: "",
    // endDate: "",
  };
  private forms: any = {
    // maxType: "0",
    // ...defaultForms
  };

  private deepLoopUtil: any = {};

  // private merchantApi: any;

  private commercialCollegeApi = new CommercialCollegeAPI();

  private chooseShopTypeList: any[] = [];

  private chooseCategoryList: any[] = [];

  private commonApi = new CommonApi();

  // private couponsManageAPI = new CouponsManageAPI();
  // private competitionPointTimeDurationAPI = new CommercialCollegeTimeDurationManageAPI();
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    this.chooseShopTypeList = [
      ...this.deepLoopUtil.deepCopy(defaultChooseShopTypeList),
    ]

    this.chooseCategoryList = [
      ...this.deepLoopUtil.deepCopy(defaultCategoryList),
    ];

    // this.searchVenuePageOption = {
    //   ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    // };

    // this.merchantApi = new MerchantApi();
  }

  private created(): void {
    this.getCommercialColleges();

    // this.refreshCommercialCollegeTimeDurations();

    // this.refreshTableClass();
  }
  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getCommercialColleges();
  }
  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getCommercialColleges();
  }

  private tableData: any[] = [];

  // 上传视频
  private userAvatarUploading: boolean = false;
  private handleUploadUserVideo(file: any, fileList: any[]) {
    console.log(file, fileList, "file", "fileList");
    this.userAvatarUploading = true;
    this.commonApi.getOssvideo(
      file instanceof File ? file : file.raw,
      (res: any) => {
        console.log(res, "res 视频上传成功");

        const { data } = res;

        const { data: callbackData } = data;

        const { item: uploadImgObj } = callbackData || {};

        const { id, url } = uploadImgObj;

        // this.$set(this.forms, "video_id", id);
        // this.$set(this.forms, "video_url", url);

        this.forms.study_video = [createVideoItem(id, url)];

        this.userAvatarUploading = false;
      },
      ({ errMsg }: any) => {
        if (errMsg == "out of limit duration") {
          this.$message.error("视频时长不可超过1小时");
        } else {
          this.$message.error("视频上传失败");
        }

        this.userAvatarUploading = false;
      },
      {
        limit_duration: 60 * 60, // 单位 秒  1h
      },
      file.url
    );
  }

  private handleRemoveStudyVideo() {
    this.forms.study_video = [];
  }

  private handleChangeUploadPic(file: any, fileList: any[]) {
    console.log(file, fileList, "file", "fileList");

    this.commonApi.getOss(
      file instanceof File ? file : file.raw,
      (res: any) => {
        console.log(res, "res 图片上传成功");

        const { data } = res;

        const { data: uploadImgObjWrap } = data;

        const { item: uploadImgObj } = uploadImgObjWrap;

        const { id, url } = uploadImgObj;

        this.forms.study_picture.push(createPicItem(id, url));
      },
      () => {
        this.$message.error("图片上传失败");
      }
    );
  }

  private handleRemoveStudyPicture(file: any) {
    const forms = this.forms || {};
    const existIndex = forms.study_picture.findIndex(
      (picFile: any) => picFile == file
    );

    if (existIndex > -1) {
      forms.study_picture.splice(existIndex, 1);
    }
  }

  // 查看视频/图片
  private handleBeforePreviewMedia(row: any) {
    const { media_type, media_list } = row || {};

    const previewItem: any = {};

    switch (media_type) {
      case 0: // 图片
        previewItem.image_count = media_list.length;

        previewItem.images = media_list.map((pic: any) => pic.url);
        break;
      case 1: // 视频
        previewItem.video_count = media_list.length;

        previewItem.videos = media_list.map((pic: any) => pic.url);
        break;
    }

    this.handlePreviewMedia(previewItem);
  }

  private images: any = [];

  private handleBeforePreviewPicture(row: any) {
    const forms = this.forms

    const {
      study_picture
    } = forms || {}

    const previewItem: any = {
      images: study_picture.map((pic: any) => pic.url),
      image_count: study_picture.length
    }

    this.handlePreviewMedia(previewItem)
  }

  private handlePreviewMedia(row: any) {
    const { image_count, images, video_count, videos } = row || {};

    const urls: any[] = [];

    if (image_count > 0) {
      urls.push(
        ...images.map((img: string) => {
          return {
            title: "",
            type: "image/jpeg",
            href: img,
            thumbnail: img,
          };
        })
      );
    }

    if (video_count > 0) {
      urls.push(
        ...videos.map((video: string) => {
          return {
            title: "",
            type: "video/mp4",
            href: video,
            poster: `${video}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`,
          };
        })
      );
    }

    console.log(urls, 'urls')

    this.images = urls;
  }

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";

  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getCommercialColleges();
  }

  private addOrUpdateCommercialCollegeTitle: string = "排名信息";

  private dialogFormVisible: boolean = false;

  private handleChangeHiddenText({ row, rowIndex, column, columnIndex, $event }: any) {
    const allowColumnIndexs: number[] = [1, 2]

    if (allowColumnIndexs.includes(columnIndex)) {
      // console.log(row, rowIndex, $event, "row, rowIndex, $event");

      const allControlKeys = [
        '',
        '__is_show_whole_title',
        "_is_show_whole_article"
      ]

      const controlKey = allControlKeys[columnIndex]

      // console.log(column, 'column')
      const {
        type: eventType
      } = $event || {}

      switch (eventType) {
        case 'mouseleave':
          // row[controlKey] = false

          this.$set(this.tableData[rowIndex], controlKey, false)

          break;
        case 'mouseenter':
          // row[controlKey] = true

          this.$set(this.tableData[rowIndex], controlKey, true)

          break;
        case 'click':
          // this.$set(this.tableData[rowIndex], controlKey, true)
          break;
      }
    }
    
  }

  // 拉起排名信息弹框
  private handleWakeCommercialCollegeDialog(mode: string) {
    // if (mode == 'new-commercial-college') { // 新增排名信息

    // }
    this.competitionPointDialogMode = mode;
    this.addOrUpdateCommercialCollegeTitle =
      competitionPointDialogTitle2ch[mode] || "排名信息";

    this.initCommercialCollegeForm(mode);

    this.dialogFormVisible = true;
  }

  private currentCommercialCollegeItem: any = null;

  private competitionPointDialogMode: string = "";

  private async initCommercialCollegeForm(mode: string) {
    const forms: any = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    if (mode == "edit-commercial-college") {
      const currentCommercialCollegeItem = this.currentCommercialCollegeItem;

      const {
        id,
        title,
        article,
        sort,
        category,
        shop_type,
        status,
        media_type,
        media_list,
      } = currentCommercialCollegeItem || {};

      Object.assign(forms, {
        title,
        article,
        sort,
        category,
        shop_type,
        status,
      });

      switch (media_type) {
        case 0: // 图片
          forms.study_picture = media_list.map((item: any) => {
            const { url, id } = item || {};

            return createPicItem(id, url);
          });
          break;
        case 1: // 视频
          forms.study_video = media_list.map((item: any) => {
            const { url, id } = item || {};

            return createVideoItem(id, url);
          });
          break;
      }

      forms.id = id;
    }

    console.log(forms, "forms");

    this.forms = forms;
  }

  private handleWakeEditCommercialCollegeDialog(competitionPointItem: any) {
    this.currentCommercialCollegeItem = competitionPointItem;

    this.handleWakeCommercialCollegeDialog("edit-commercial-college");
  }

  private handleDelteCommercialCollegeDialog(competitionPointItem: any) {
    this.$confirm("确定删除该商学院信息?", "", {
      ...shareConfirmOptions,
    }).then(() => {
      this.commercialCollegeApi.deleteCommercialCollege(
        {
          id: competitionPointItem.id,
        },
        () => {
          this.getCommercialColleges();
        },
        () => {}
      );
    });
  }

  // 排序
  private handleLimitUserSort(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        // const limit_val = Math.min(Number(new_val), maxBenefitRate).toString();
        this.$set(this.forms, "sort", new_val);
      },
      (new_val: string) => {
        this.$set(this.forms, "sort", new_val);
      }
    );
  }

  private sumbitCommercialCollegeLoading: boolean = false;

  // 提交商学院信息配置
  private handleSumbitCommercialCollege() {
    const competitionPointDialogMode = this.competitionPointDialogMode;

    const params = this.getCommercialCollegeFormsParams(
      competitionPointDialogMode
    );

    console.log(params, "params");
    if (params === false) return;

    this.sumbitCommercialCollegeLoading = true;
    switch (competitionPointDialogMode) {
      case "new-commercial-college":
        this.commercialCollegeApi.addCommercialCollege(
          params,
          () => {
            this.getCommercialColleges();
            this.dialogFormVisible = false;
            this.sumbitCommercialCollegeLoading = false;
          },
          () => {
            this.sumbitCommercialCollegeLoading = false;
          }
        );
        break;
      case "edit-commercial-college":
        this.commercialCollegeApi.updateCommercialCollege(
          params,
          () => {
            this.getCommercialColleges();
            this.dialogFormVisible = false;
            this.sumbitCommercialCollegeLoading = false;
          },
          () => {
            this.sumbitCommercialCollegeLoading = false;
          }
        );
        break;
    }
  }

  private getCommercialCollegeFormsParams(mode: string) {
    const params: any = {};

    const showErrorMessage = (msg: string) => {
      if (!!msg) {
        this.$message.error(msg);
      }
    };

    const forms = this.forms;

    const {
      id,
      // name,
      // expired_days,
      // meituanId,
      // timeDuration
      // machine_number,
      // phone,
      // rank

      shop_type,
      category,
      sort,
      status,

      study_picture,
      study_video,
    } = forms || {};

    const keys = ["title", "article"];

    const key2EndKey: any = {
      title: "title",
      article: "article",
    };

    const key2ErrMsg: any = {
      title: "标题不能为空",
      article: "内容不能为空",
    };

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (forms[key]) {
        const errMsg = key2ErrMsg[`${key}_${forms[key]}`];
        if (errMsg) {
          showErrorMessage(errMsg);

          return false;
        }
        params[key2EndKey[key] || key] = forms[key];
      } else {
        showErrorMessage(key2ErrMsg[key]);

        return false;
      }
    }

    Object.assign(params, {
      shop_type,
      category,
      sort,
      status,
    });

    console.log(study_picture, "study_picture");

    if (study_picture && study_picture.length > 0) {
      params.media_type = 0;

      params.media_id = study_picture.map((pic: any) => pic.id).join(",");
    } else if (study_video && study_video.length > 0) {
      params.media_type = 1;

      params.media_id = study_video[0].id;
    }

    if (mode == "edit-commercial-college") {
      params.id = id;
    }

    return params;
  }

  //获取商学院列表
  private getCommercialColleges(): void {
    this.commercialCollegeApi.getCommercialCollegeList(
      { ...this.getCommercialCollegeParams() },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page;
        this.tablePage.prepage = res.data.prepage;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private getCommercialCollegeParams(): any {
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    const { title, shop_type } = form;

    if (title) {
      params.title = title;
    }

    if (shop_type) {
      params.shop_type = shop_type
    }

    if (page) {
      params.page = page;
    }

    if (prepage) {
      params.limit = prepage;
    }

    return params;
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };
}

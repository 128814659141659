import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

import { CommonApi } from "../common";

export  class UserManualConsumeApi extends BasicsClass {
  public apiUrls: any = new Map([
    ['getUserManualConsumeTableList', {
        url: '/admin/userSpend/index',
        name: 'list',
        label: '列表'
    }],
    ['exportExcelUserManualConsumeList', {
        url: '/admin/userSpend/exportIndex',
        name: 'export-excel',
        label: '导出'
    }],

])
  // 订单api 方法
  // momo达到家-订单列表
  public getUserManualConsumeTableList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getUserManualConsumeTableList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  public exportExcelUserManualConsumeList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('exportExcelUserManualConsumeList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }


}

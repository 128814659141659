
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
// import { CouponsManageAPI } from "@/common/api/couponList";
import GuessingGamePraizesSettingAPI from '@/common/api/operationManage/guessingGamePraizesSetting';
import { excuteDownloadExport2Excel } from "@/common/xlsx";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import ZlSelect from "@/components/third-custom-components/select";

import ZlOption from "@/components/third-custom-components/option";

import {
  shareConfirmOptions
} from '@/common/config'

export const pageApiList = getPageAPIs(GuessingGamePraizesSettingAPI)



function createSelectItem(label: any = '') {
  const defaultSelectionForm = {
    label,
    value: ''
  }

  return defaultSelectionForm
}

const defaultForms = {
  title: '', // 问题
  sub_title: '', // 副问题
  selection: [
    createSelectItem(1),
    createSelectItem(2),
  ], //
  start_time: '', // 开始时间
  end_time: '', // 结束时间
  is_show: 1 // 是否显示 0否 1是
 
  // singleMaxCountType: '0',
  // shop_id: "",
  // maxLimitCount: ''
};

// 是否显示 0隐藏 1显示
const questionStatus2Ch: string[] = ['隐藏', '显示']

// const defaultSearchVenuePageOption = {
//   page: 1,
//   limit: 10,
//   isRearchBottom: false,
// };

const guessingGamePraizesDialogTitle2ch: any = {
  "new-guessing-game-praizes": "新增",
  "edit-guessing-game-praizes": "编辑",
};

// 最大有效期
// const maxExpiredDays = 9999;

// 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;

const maxTotalMoney = 9999999

@Component({
  components: {
    ZlSelect,
    ZlOption,
  },
  filters: {
    showQuestionRes(answer: number, list: any[]) {
      if (answer === 0) return '未知'


      const res = list.find((item: any) => item.label == answer) || {}

      return `${res.label}、${res.value}`; 
    },
    getQuestionStatusName(is_show: number) {
      return questionStatus2Ch[is_show] || ''
    }
  }
})
export default class GuessingGamePraizesSettingList extends Vue {
  private form: any = {
    // guessingGamePraizesId: "",
    // phone: "",
    // time: []
    // shop_id: '',
    // time: [],
    // startDate: "",
    // endDate: "",
  };
  private forms: any = {
    // maxType: "0",
    // ...defaultForms
  };

  private coachLeaderStatusList: any[] = [
    {
      label: '下架',
      value: 0
    },
    {
      label: '上架',
      value: 1
    }
  ]

  private deepLoopUtil: any = {};

  // private merchantApi: any;

  private guessingGamePraizesSettingApi = new GuessingGamePraizesSettingAPI();

  // private guessingGamePraizesTimeDurationAPI = new GuessingGamePraizesSettingTimeDurationManageAPI();
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    // this.searchVenuePageOption = {
    //   ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    // };

    // this.merchantApi = new MerchantApi();
  }

  
  private created(): void {
    this.getGuessingGamePraizesSettingList();

    // this.refreshGuessingGamePraizesSettingTimeDurations();

    // this.refreshTableClass();
  }
  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getGuessingGamePraizesSettingList();
  }
  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getGuessingGamePraizesSettingList();
  }

  private tableData: any[] = [];


 

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";
  
  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getGuessingGamePraizesSettingList();
  }

  // 正确答案弹框
  private answerFormsTips: string[] = [
    `竞猜结果设置之后无法修改`,
    `竞猜结果设置十分钟后系统计算用户竞猜结果`
  ]

  private answerforms: any = {
    answer: ''
  }

  private dialogAnswerVisible: boolean = false;

  private async onSetQuestionAnswer(settingItem: any) {
    this.currentGuessingGamePraizesSettingItem = settingItem

    // 编辑
    const currentSettingItem: any = await this.refreshGuessingGamePraizeDetail();


    const answerforms = {
      answer: (currentSettingItem.answer || '').toString()
    };

    this.answerforms = answerforms


    this.dialogAnswerVisible = true
  }

  private sumbitQuestionAnswerLoading: boolean = false

  private onSumbitQuestionAnswer() {
    const currentGuessingGamePraizesSettingItem = this.currentGuessingGamePraizesSettingItem

    const answerforms = this.answerforms

    const {
      answer 
    } = answerforms || {}

    const params = {
      id: currentGuessingGamePraizesSettingItem.id,
      selection: answer
    }

    this.sumbitQuestionAnswerLoading = true

    this.guessingGamePraizesSettingApi.changeGuessingGameAnswer(params, () => {
      this.sumbitQuestionAnswerLoading = false

      this.dialogAnswerVisible = false

      this.getGuessingGamePraizesSettingList()
    }, () => {
      this.sumbitQuestionAnswerLoading = false
    })
  }

  // 设置弹框
  private addOrUpdateGuessingGamePraizesSettingTitle: string = "有奖竞猜";

  private dialogFormVisible: boolean = false;

  // 拉起有奖竞猜弹框
  private handleWakeGuessingGamePraizesSettingDialog(mode: string) {
    // if (mode == 'new-guessing-game-praizes') { // 新增有奖竞猜

    // }
    this.guessingGamePraizesDialogMode = mode;
    this.addOrUpdateGuessingGamePraizesSettingTitle = guessingGamePraizesDialogTitle2ch[mode] || "有奖竞猜";

    this.initGuessingGamePraizesSettingForm(mode);

    this.dialogFormVisible = true;
  }

  private currentGuessingGamePraizesSettingItem: any = { selection: [] };

  private guessingGamePraizesDialogMode: string = "";

  private async initGuessingGamePraizesSettingForm(mode: string) {
    const forms: any = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    if (mode == "edit-guessing-game-praizes") {
      // 编辑
      const currentGuessingGamePraizesSettingItem: any = await this.refreshGuessingGamePraizeDetail();

      const {
        id,
        title = '',
        sub_title = '',
        selection = [],
        // answer,
        start_time = '',
        end_time = '',
        is_show
      } = currentGuessingGamePraizesSettingItem || {};

      Object.assign(forms, {
        title,
        sub_title,
        selection,
        start_time,
        end_time
      })


      if (typeof is_show ==  'number') {
        forms.is_show = is_show
      }

      // const venues: any[] = []

      // const handleAddExtraVenue = (venues: any[], id: any, name: any) => {
      //   if (name && typeof id == 'number') {
      //     let existVenue = this.venueList.find((venue) => venue.id == id);

      //     if (!existVenue) {
      //       existVenue = {
      //         id,
      //         name,
      //       };
      //       this.venueList.push(existVenue);
      //     }

      //     venues.push(existVenue)
      //   }
      // }

      // handleAddExtraVenue(venues, shop_id, shop_name)
      // handleAddExtraVenue(venues, assistant_shop_id, assistant_shop_name)

      // if (shop_name && typeof shop_id == "number") {
      //   console.log(shop_id, "shop_id");

      //   console.log(this.venueList, "this.venueList");
      //   let existVenue = this.venueList.find((venue) => venue.id == shop_id);

      //   if (!existVenue) {
      //     existVenue = {
      //       id: shop_id,
      //       name: shop_name,
      //     };
      //     this.venueList.push(existVenue);
      //   }

      //   forms.venue = existVenue;
      // }

      // forms.venues = venues;

      forms.id = id;
    }

    console.log(forms, "forms");

    // console.log(this.venueList, "this.venueList");

    this.forms = forms;
  }

  private handleWakeEditGuessingGamePraizesSettingDialog(guessingGamePraizesItem: any) {
    this.currentGuessingGamePraizesSettingItem = guessingGamePraizesItem;

    this.handleWakeGuessingGamePraizesSettingDialog("edit-guessing-game-praizes");
  }

  private refreshGuessingGamePraizeDetail() {
    const item = this.currentGuessingGamePraizesSettingItem

    return new Promise((resolve, reject) => {
      this.guessingGamePraizesSettingApi.refreshGuessingGamePraizesDetail({
        id: item.id
      },
      (res: any) => {
        const {
          item = {}
        } = res.data || {}
        resolve(item)
      },
      (err: any) => {
        reject(err)
      })
    })
    
  }

 


  

  // 流水
  // private handleLimitTotalMoney(val: string) {
  //   Util.limitIntegetInput(
  //     val,
  //     (new_val: string) => {
  //       const limit_val = Math.min(Number(new_val), maxTotalMoney).toString();

  //       if (maxTotalMoney == Number(limit_val)) {
  //         this.$set(this.forms, "total_money", limit_val);
  //       } else {
  //         this.$set(this.forms, "total_money", new_val);
  //       }
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "total_money", new_val);
  //     }
  //   );
  // }

  // private handleLimitSingleMaxCount(val: string) {
  //   Util.limitIntegetInput(
  //     val,
  //     (new_val: string) => {
  //       this.$set(this.forms, "maxLimitCount", new_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "maxLimitCount", new_val);
  //     }
  //   );
  // }

  private sumbitGuessingGamePraizesSettingLoading: boolean = false;

  // 提交有奖竞猜配置
  private handleSumbitGuessingGamePraizesSetting() {
    const guessingGamePraizesDialogMode = this.guessingGamePraizesDialogMode;

    const params = this.getGuessingGamePraizesSettingFormsParams(guessingGamePraizesDialogMode);

    console.log(params, "params");
    if (params === false) return;

    this.sumbitGuessingGamePraizesSettingLoading = true;
    switch (guessingGamePraizesDialogMode) {
      case "new-guessing-game-praizes":
        this.guessingGamePraizesSettingApi.addGuessingGamePraizesSetting(
          params,
          () => {
            this.getGuessingGamePraizesSettingList();
            this.dialogFormVisible = false;
            this.sumbitGuessingGamePraizesSettingLoading = false;
          },
          () => {
            this.sumbitGuessingGamePraizesSettingLoading = false;
          }
        );
        break;
      case "edit-guessing-game-praizes":
        this.guessingGamePraizesSettingApi.updateGuessingGamePraizesSetting(
          params,
          () => {
            this.getGuessingGamePraizesSettingList();
            this.dialogFormVisible = false;
            this.sumbitGuessingGamePraizesSettingLoading = false;
          },
          () => {
            this.sumbitGuessingGamePraizesSettingLoading = false;
          }
        );
        break;
    }
  }

  private getGuessingGamePraizesSettingFormsParams(mode: string) {
    const params: any = {};

    const showErrorMessage = (msg: string) => {
      if (!!msg) {
        this.$message.error(msg);
      }
    };

    const forms = this.forms;

    const {
      id,
      // name,
      // expired_days,
      // meituanId,
      // timeDuration
      // machine_number,
      // total_money,
      // remark,
      // operate_name,
      selection = [],
      sub_title = '',
      is_show
    } = forms || {};

    // console.log(venues, 'venues')

    Object.assign(params, {
      is_show,
      sub_title
    })

   

    const keys = ["title", "start_time", "end_time"];

    const key2EndKey: any = {
      title: "title",
      start_time: "start_time",
      end_time: "end_time"
    };

    const key2ErrMsg: any = {
      title: "问题不能为空",
      start_time: "开始时间不可为空",
      end_time: "结束时间不可为空"
    };

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (forms[key]) {
        const errMsg = key2ErrMsg[`${key}_${forms[key]}`];
        if (errMsg) {
          showErrorMessage(errMsg);

          return false;
        }
        params[key2EndKey[key] || key] = forms[key];
      } else {
        showErrorMessage(key2ErrMsg[key]);

        return false;
      }
    }

    const index2Props: any = ['selection_one', 'selection_two']

    function setParamsListProp(target: any, prop: string, value: any) {
      if (value) {
        target[prop] = value
      }
    }

    selection.forEach((item: any, index: number) => {
      setParamsListProp(params, index2Props[index], item.value)
    })
    

    if (mode == "edit-guessing-game-praizes") {
      params.id = id;
    }

    return params;

  }

  //获取有奖竞猜列表
  private getGuessingGamePraizesSettingList(): void {
    this.guessingGamePraizesSettingApi.getGuessingGamePraizesSettingList(
      { ...this.getGuessingGamePraizesSettingParams() },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page;
        this.tablePage.prepage = res.data.prepage;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private getGuessingGamePraizesSettingParams(mode = "all"): any {
    const params: any = {};

    // const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    // const { phone } = form;

    // if (phone) {
    //   params.phone = phone;
    // }

    // if (Array.isArray(time) && time.length === 2) {
    //   const [startDate, endDate] = time;

    //   const startTime = Util.dateTimeUtilnohms(startDate);
    //   const endTime = Util.dateTimeUtilnohms(endDate);

    //   params.start_date = startTime;

    //   params.end_date = endTime;
    // }

     if (
      mode == "exportExcel" &&
      !(params.start_date && params.end_date)
    ) {
      this.$message.error("请选择日期导出数据");
      return null;
    }



    if (mode === "all") {
      params.page = page;

      params.limit = prepage;

      // outerParams.__isUnique = !firstLoading;
    }

    return params;
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  /** 导出excel */
  // private downloadExcelLoading: boolean = false;

  // // 字段名 文案对应关系
  // private merchantExcelList2ch: any = {
  //   name: "店铺名称",
  //   shop_id: " 店铺ID",
  //   cabinet_id: "绑定设备ID",
  //   admin_name: "操作人",
  //   updated_at: "操作时间"
  // };
  // // 商户Excel 表头的先后顺序
  // private merchantExcelSortList: string[] = [
  //   "name",
  //   "shop_id",
  //   "cabinet_id",
  //   "admin_name",
  //   "updated_at"
  // ];

  // // 点击导出excel 表格
  // private handleExportExcelGuessingGamePraizesSettingList() {
  //   const params = this.getGuessingGamePraizesSettingParams('exportExcel')

  //   if (params === null) {
  //     return false;
  //   }

  //   this.downloadExcelLoading = true;
  //   this.guessingGamePraizesSettingApi.exportExcelStoragelockerList(
  //     params,
  //     (res: any) => {
  //       const { start_date, end_date } = params || {};

  //       const filename = `有奖竞猜管理列表 ${start_date}到${end_date}.xlsx`;
  //       const { data } = res || {};

  //       const {
  //         list: originList
  //       } = data || {}

  //       const newList = Array.isArray(originList)
  //         ? originList.map((item) => {
  //             const { 
  //               // status,
  //               // back_goods_detail
  //              } = item || {};
  //             const newItem = {
  //               ...(item || {}),
  //               // __status_text: withdrawAccountAudit2Text[status],
  //               // __back_good_details: back_goods_detail.map((goods: any, index: number) => `${ goods.name }x${ goods.num }  ¥${ goods.price }`).join('; \t')
  //             };

  //             for (let key in item) {
  //               if (typeof item[key] == 'number') {
  //                 newItem[key] = item[key].toString()
  //               }
  //             }

             
  //             return newItem;
  //           })
  //         : [];

  //       if (newList.length > 0) {
  //         const merchantExcelSortList = this.merchantExcelSortList;

  //         const merchantExcelList2ch = this.merchantExcelList2ch;

  //         const excelJSONData = getExcelJSONDataByList(
  //           newList,
  //           merchantExcelSortList,
  //           merchantExcelList2ch
  //         );

  //         excuteDownloadExport2Excel(excelJSONData, filename);
  //       } else {
  //         this.$message.info("选中时段没有可导出的信息");
  //       }

  //       this.downloadExcelLoading = false;
  //     },
  //     () => {
  //       this.downloadExcelLoading = false;
  //     }
  //   );
  // }

  /** end 导出excel */

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };
}

import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/util";

import { CommonApi } from "../common";

export class CompetitionPointExcelRecordAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getCompetitionPointExcelRecordList', {
      url: '/admin/leagueScoreImport/index',
      name: 'list',
      label: '列表'
    }],

    // ['searchVenueList', {
    //   url: '/admin/coupons/shop/list',
    //   name: 'shop-list',
    //   label: '店铺列表',
    //   // hidden: true
    // }],

    ['getPreviewFileTable', {
      url: '/admin/leagueScoreImport/preview',
      name: 'upload-file-preview',
      label: '预览上传文档',
      // hidden: true
    }],

    ['importFileInfo', {
      url: '/admin/leagueScoreImport/import',
      name: 'import-file-info',
      label: '导入上传文件',
      // hidden: true
    }],
    
  ])
  // 订单api 方法
  // 会员卡移植记录列表
  public getCompetitionPointExcelRecordList(
    params: any,
    successCb: Function,
    failCb: Function,
    showLoading: boolean = false
  ) {

    console.log(params, "params");

    const apiUrls = this.apiUrls

    const url = apiUrls.get('getCompetitionPointExcelRecordList').url

    this.BasicPost(url, {
      ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 搜索店铺列表
  // public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
  //   const apiUrls = this.apiUrls

  //   const url = apiUrls.get('searchVenueList').url

  //   this.BasicPost(url, {
  //       ...params
  //   }, false, false, false, successCb, failCb, showLoading)
  // }

  // 导入上传文件
  public getPreviewFileTable(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getPreviewFileTable').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // importFileInfo
  public importFileInfo(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('importFileInfo').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }
}

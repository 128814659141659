
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
// import { CouponsManageAPI } from "@/common/api/couponList";

import { excuteDownloadExport2Excel } from "@/common/xlsx";


import SingleCardAccountListAPI from '@/common/api/operationManage/singleCardAccountList';

import { DeepLoop } from "@/common/deepLoop/deepLoop";
import ZlSelect from "@/components/third-custom-components/select";

import ZlOption from "@/components/third-custom-components/option";

import {
  shareConfirmOptions,
  chineseNum2Ch
} from '@/common/config'

export const pageApiList = getPageAPIs(SingleCardAccountListAPI)

// const defaultForms = {
//   machine_number: '', // 设备编码
//   fencheng_rate: '', // 分成比例
//   venue_id: "",
//   venue: {
//     id: "",
//     name: "",
//   },
// };

const defaultSearchVenuePageOption = {
  page: 1,
  limit: 10,
  isRearchBottom: false,
};

// const openTimeLevelRewardRecordDialogTitle2ch: any = {
//   "new-single-card-charge": "新增",
//   "edit-single-card-charge": "编辑",
// };

// 最大有效期
// const maxExpiredDays = 9999;

// 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;

// 类型：1会员2非会员3全部用户
// const sendTyep2Ch: string[] = ['', '会员', '非会员', '全部用户']

const maxBenefitRate = 100

@Component({
  components: {
    ZlSelect,
    ZlOption,
  },
  filters: {
    // showSendTypeName(type: number) {
    //   return sendTyep2Ch[type] || '未知类型'
    // }
    // showRankName(rank: number) {
    //   const rankName = chineseNum2Ch[rank]
    //   return rankName ? `第${rankName}名` : '未知'
    // }
  }
})
export default class SingleCardAccountsManage extends Vue {
  private form: any = {
    // openTimeLevelRewardRecordId: "",
    // shop_name: "",
    venue_id: "",
    venue: {
      id: "",
      name: "",
    },
    phone: "",
    // order_no: '',
    // status: '',  // 状态：1提交订单 2已付款 3已取消 4已退款
    // time: [],
    // startDate: "",
    // endDate: "",
  };

  // private orderStatusList: any[] = [
  //   {
  //     label: '提交订单',
  //     value: 1,
  //   },
  //   {
  //     label: '已付款',
  //     value: 2
  //   },
  //   {
  //     label: '已取消',
  //     value: 3
  //   },
  //   {
  //     label: '已退款',
  //     value: 4
  //   },
  //   {
  //     label: '退款中',
  //     value: 5
  //   }
  // ]

  private forms: any = {
    // maxType: "0",
    // ...defaultForms
  };

  private deepLoopUtil: any = {};

  // private merchantApi: any;

  private singleCardAccountApi = new SingleCardAccountListAPI();

  // private openTimeLevelRewardRecordTimeDurationAPI = new SingleCardAccountTimeDurationManageAPI();
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    // this.forms = {
    //   ...this.deepLoopUtil.deepCopy(defaultForms, false),
    // };

    // this.searchVenuePageOption = {
    //   ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    // };

    // this.merchantApi = new MerchantApi();
  }

  private created(): void {
    this.getSingleCardAccounts();
  }
  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getSingleCardAccounts();
  }
  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getSingleCardAccounts();
  }

  private tableData: any[] = [];

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";
  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getSingleCardAccounts();
  }

  // private handleRefundSingleCardAccountDialog(openTimeLevelRewardRecordItem: any) {
  //   const set = Util.setListProp

  //   console.log(openTimeLevelRewardRecordItem, 'openTimeLevelRewardRecordItem')
  //   this.$confirm("确定退款该充值?", "", {
  //     ...shareConfirmOptions,
  //   }).then(() => {
  //     set(
  //       this,
  //       this.tableData,
  //       openTimeLevelRewardRecordItem,
  //       "__refundMoneyLoading",
  //       true
  //     );
  //     this.singleCardAccountApi.refundSingleCardAccount({
  //       id: openTimeLevelRewardRecordItem.id
  //     }, () => {
  //       set(
  //         this,
  //         this.tableData,
  //         openTimeLevelRewardRecordItem,
  //         "__refundMoneyLoading",
  //         false
  //       );
  //       this.getSingleCardAccounts()
  //     }, () => {
  //       set(
  //         this,
  //         this.tableData,
  //         openTimeLevelRewardRecordItem,
  //         "__refundMoneyLoading",
  //         false
  //       );
  //     })
  //   });
  // }

  private venueList: any[] = [
    // {
    //   id: 1,
    //   label: '测试'
    // },
    // {
    //   id: 2,
    //   label: '测试2'
    // }
  ];

  private searchVenueLoading: boolean = false;

  private remoteGetVenueQueryString: string = "";

  private remoteSearchVenueTimer: any = null;

  private searchVenuePageOption: any = {};

  private initRemoteGetVenuePageOption() {
    this.searchVenuePageOption = {
      ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    };
  }

  private getSearchVenueParams() {
    const searchVenuePageOption = this.searchVenuePageOption;

    const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

    const { page, limit } = searchVenuePageOption;

    const params: any = {
      page,
      limit,
    };

    if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
      params.name = remoteGetVenueQueryString.trim();
    }

    return params;
  }

  private getMoreVenueList(refresh: boolean = true) {
    return new Promise((resolve: Function, reject: Function) => {
      if (refresh) {
        this.initRemoteGetVenuePageOption();
      }

      const params = this.getSearchVenueParams();

      this.singleCardAccountApi.searchVenueList(
        params,
        (res: any) => {
          console.log(res, "res");

          const venueList: any[] = res.data || [];

          this.venueList = refresh
            ? venueList
            : this.venueList.concat(venueList);

          if (Array.isArray(res.data) && res.data.length == 0) {
            this.searchVenuePageOption.isRearchBottom = true;
          }
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          resolve();
        },
        () => {
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          reject();
        }
      );
    });
  }

  // 第一次搜索 无关键字
  private handleFirstGetVenueList() {
    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 清空搜索
  private handleClearGetVenueList() {
    this.remoteGetVenueQueryString = "";

    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 远程搜索
  private remoteGetVenueMethod(query: string) {
    query = query.trim ? query.trim() : "";

    if (query !== "") {
      this.remoteGetVenueQueryString = query;
      clearTimeout(this.remoteSearchVenueTimer);

      this.remoteSearchVenueTimer = setTimeout(() => {
        this.searchVenueLoading = true;

        this.getMoreVenueList();
       
      }, 300);
    } else {
      this.remoteGetVenueQueryString = "";
      this.venueList = [];
    }
  }

  private addVenueListLoading: boolean = false;

  private remoteAddVenueMethod() {
    if (this.addVenueListLoading) return;
    const searchVenuePageOption = this.searchVenuePageOption;

    const { isRearchBottom } = searchVenuePageOption;

    if (isRearchBottom) return;

    this.searchVenuePageOption.page++;

    this.addVenueListLoading = true;

    this.getMoreVenueList(false);
  }

  
  //获取充值列表
  private getSingleCardAccounts(): void {
    const params: any = this.getSingleCardAccountParams()
    this.singleCardAccountApi.getSingleCardAccountList(
      { ...params },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page || params.page;
        this.tablePage.prepage = res.data.prepage || params.limit;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private getSingleCardAccountParams(mode = "all"): any {
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    const { venue, phone } = form;

    
    if ((venue && venue.id)) {
      params.shop_id = venue.id
    }

    if (phone) {
      params.phone = phone
    }

    // if (Array.isArray(time) && time.length === 2) {
    //   const [startDate, endDate] = time;

    //   const startTime = Util.dateTimeUtilnohms(startDate);
    //   const endTime = Util.dateTimeUtilnohms(endDate);

    //   params.start_date = startTime;

    //   params.end_date = endTime;
    // }

    // if (
    //   mode == "exportExcel" &&
    //   !(params.start_date && params.end_date)
    // ) {
    //   this.$message.error("请选择时段导出数据");
    //   return null;
    // }

    if (mode === "all") {
      params.page = page;

      params.limit = prepage;

    }


    return params;
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 用户字段名 文案对应关系
  private userExcelList2ch: any = {
    shop_name: "店铺名称",
    phone: "手机号",
    user_name: "用户昵称",
    money: "单店卡余额",
  };
  // 用户Excel 表头的先后顺序
  private userExcelSortList: string[] = [
    "shop_name",
    "phone",
    "user_name",
    "money",
  ];

  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getSingleCardAccountParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.singleCardAccountApi.exportExcelSingleCardAccountList(
      params,
      (res: any) => {
        // const { start_date, end_date } = params || {};

        const filename = `单店卡余额管理.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const { 
                // rank
              } = item;
              const newItem = {
                ...(item || {}),
              };

             

              return newItem;
            })
          : [];

        // console.log(newList, 'newList')

        if (newList.length > 0) {
          const userExcelSortList = this.userExcelSortList;

          const userExcelList2ch = this.userExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            userExcelSortList,
            userExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }
        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };
}

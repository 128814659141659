import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class LeagueCourserListAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getLeagueCourserList', {
        url: '/admin/league_courser/user',
        name: 'list',
        label: '列表'
    }],
    ['exportExcelLeagueCourserList', {
      url: '/admin/league_courser/exportUser',
      name: 'export-excel',
      label: '导出'
  }],
])

  // 参赛选手列表
  public getLeagueCourserList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('getLeagueCourserList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

  // 导出参赛选手列表
  public exportExcelLeagueCourserList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls

    const url = apiUrls.get('exportExcelLeagueCourserList').url

    this.BasicPost(url, {
        ...params
    }, false, false, false, successCb, failCb, showLoading)
  }

}
import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class WithdrawDepositApi extends BasicsClass {
    public apiUrls: any = new Map([
        ['getWithdrawTableList', {
            url: '/admin/withdraw/lists',
            name: 'list',
            label: '列表'
        }],
        ['getAuditAgree',  {
            url: '/admin/withdraw/approved',
            name: 'approved-audit',
            label: '同意'
        }],
        ['getAuditDisagree',  {
            url: '/admin/withdraw/reject',
            name: 'reject-audit',
            label: '拒绝'
        }],
        ['exportExcelWithdrawList', {
            url: '/admin/withdraw/export',
            name: 'export-excel',
            label: '导出'
        }],

        ['getAutoAuditSetting', {
            url: '/admin/withdraw/switchState',
            name: 'auto-setting-info',
            label: '自动审核配置信息'
        }],
        ['updateAutoAuditSetting', {
            url: '/admin/withdraw/switch',
            name: 'update-auto-setting',
            label: '更新自动审核配置'
        }],


        ['getWithdrawQueryInfo', {
            url: '/admin/withdraw/query',
            name: 'withdraw-query',
            label: '查询提现'
        }],
        ['withdrawManualSetStatus', {
            url: '/admin/withdraw/mark',
            name: 'withdraw-manual-set',
            label: '手动设置提现状态'
        }],
    ])

    // 提现列表
    public getWithdrawTableList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getWithdrawTableList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 导出用户列表
    public exportExcelWithdrawList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('exportExcelWithdrawList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 审核通过
    public getAuditAgree(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getAuditAgree').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 驳回
    public getAuditDisagree(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getAuditDisagree').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 自动审核配置信息
    public getAutoAuditSetting(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getAutoAuditSetting').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 更新自动审核配置
    public updateAutoAuditSetting(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('updateAutoAuditSetting').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 提现查询
    public getWithdrawQueryInfo(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getWithdrawQueryInfo').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 提现查询手动标记
    public withdrawManualSetStatus(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('withdrawManualSetStatus').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}